import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import RtypeOne from "../StudentTestWorkflow/RtypeOne";
import RtypeTwo from "../StudentTestWorkflow/RtypeTwo";
import RtypeThree from "../StudentTestWorkflow/RtypeThree";
import RtypeFour from "../StudentTestWorkflow/RtypeFour";
import RtypeFive from "../StudentTestWorkflow/RtypeFive";
import RtypeSix from "../StudentTestWorkflow/RtypeSix";
import RtypeSeven from "../StudentTestWorkflow/RtypeSeven";
import RtypeEight from "../StudentTestWorkflow/RtypeEight";
import RtypeNine from "../StudentTestWorkflow/RtypeNine";
import RtypeTen from "../StudentTestWorkflow/RtypeTen";
import Rtypepara from "../StudentTestWorkflow/Rtypepara";
import RtypeParaOption from "../StudentTestWorkflow/RtypeParaOption";


export default function ViewQuestionPaper() {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location);
  const { id, status, eventName, bachId } = location.state;
  const [fdata, setFdata] = useState([]);
  const [listData, setListData] = useState([]);
  const [displayType, setDisplayType] = useState([]);
  // difficulty_type:
  // 0→easy, 1→very_easy, 2→hard, 3→very_hard

  useEffect(() => {
    AxiosInstance.post("api/test/view/question", {
      batch_id: parseInt(bachId),
      event_id: parseInt(id),
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          let el = res.data.data.paper_info;
          const transformedData = el.map((item) => {
            const { subject, no_of_ques, difficulty_type } = item;
            const difficulty = difficulty_type[0];
            return {
              subject,
              no_ques: no_of_ques,
              very_easy_ques: difficulty.hasOwnProperty("1")
                ? difficulty["1"]
                : "",
              easy_ques: difficulty.hasOwnProperty("0") ? difficulty["0"] : "",
              medium_ques: difficulty.hasOwnProperty("4") ? difficulty["4"] : "",
              hard_ques: difficulty.hasOwnProperty("2") ? difficulty["2"] : "",
              very_hard_ques: difficulty.hasOwnProperty("3")
                ? difficulty["3"]
                : "",
              medium: subject,
            };
          });
          setFdata(transformedData);
          let ut = res.data.data.question_papers;
          let elementToAdd = null;
          ut.forEach((el, index) => {
            if (el.display_type === 10 && el.para_que === 1) {
              elementToAdd = el;
            }
          });

          // Add the elementToAdd to all elements with display_type === 10 and para_que === 2
          ut.forEach((el, index) => {
            if (el.display_type === 10 && el.para_que === 2) {
              el.easy = elementToAdd;
            }
          });

          const bb = ut.filter((el) => el.para_que !== 1);
          setListData(bb);
          // setDisplayType(display_TypeTen);



        }
      })
      .catch((error) => {
        return error;
      });
  }, [bachId]);

  const [ques, setQues] = useState([
    {
      question_en:
        "The only block that contains all three types of elements in the periodic table.",
      question_tm:
        "பெரியோடிக் டேபிளின் அடிப்படையில் அனைத்து வகைகளும் இருக்கும் உருவான இடம் யாது?",
      options_en: {
        option_one: "s-block",
        option_two: "p-block",
        option_three: "d-block",
        option_four: "f-block",
        option_five: "e-block",
      },
      options_tm: {
        option_one: "s - தொகுதி",
        option_two: "p - தொகுதி",
        option_three: "d - தொகுதி",
        option_four: "f- தொகுதி",
        option_five: "e - தொகுதி",
      },
      crt_answer_tm: "p - தொகுதி",
      crt_answer_en: "p-block",
    },
  ]);

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  const Upload_Question = () => {
    navigate(`/UploadQuestionFile`, {
      state: {
        id,
        status,
      },
    });
  };
  // console.log(fdata,"aafaf");
  return (
    <div className="BathDet">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading mt-3 d-flex justify-content-between ">
              <h6 className="Tit"> View Question Paper</h6>
              <Link
                to="javascript:void(0)"
                onClick={navigateToPreviousScreen}
                className="CancelButton"
              >
                Back to View Test
              </Link>
              <button
                type="button"
                className="detailEventButton"
                onClick={Upload_Question}
              >
                {" "}
                <span class="material-icons me-1">upload</span>
                <a>Upload New Question Paper</a>
              </button>
            </div>
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Question Paper Details</p>
                {fdata.length > 0 &&
                  fdata.map((el, index) => (
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">Subject</h5>
                          <span className="ReviewLabel">
                            {el.subject !==null && el.subject !==""
                              ? el.subject
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Total Number of Questions
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.no_ques !==null && el.no_ques !==""
                              ? el.no_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Very Easy Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.very_easy_ques !==null &&
                            el.very_easy_ques !==""
                              ? el.very_easy_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Easy Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.easy_ques !==null && el.easy_ques !==""
                              ? el.easy_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Medium Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.medium_ques !==null && el.medium_ques !==""
                              ? el.medium_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Hard Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.hard_ques !==null && el.hard_ques !==""
                              ? el.hard_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Very Hard Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.very_Hard_ques !==null &&
                            el.very_Hard_ques !==""
                              ? el.very_Hard_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>

          <section className="StepOne">
            {listData.length > 0 &&
              listData.map((el, index) => {
                // Calculate the effective step based on the loop count
                if (el.display_type === 0) {
                  return (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>
                        <div className="row">
                          <RtypeOne type={1} index={index} list={el} />
                        </div>
                      </div>
                    </div>
                  );
                } else if (el.display_type === 1) {
                  return (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeTwo type={1} index={index} list={el} />
                        </div>
                      </div>
                    </div>
                  );
                } else if (el.display_type === 2) {
                  return (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>
                        <div className="row">
                          <RtypeThree type={1} index={index} list={el} />
                        </div>
                      </div>
                    </div>
                  );
                } else if (el.display_type === 3) {
                  return (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeFour type={1} index={index} list={el} />
                        </div>
                      </div>
                    </div>
                  );
                } else if (el.display_type === 4) {
                  return (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeFive type={1} index={index} list={el} />
                        </div>
                      </div>
                    </div>
                  );
                } else if (el.display_type === 5) {
                  return (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeSix type={1} index={index} list={el} />
                        </div>
                      </div>
                    </div>
                  );
                } else if (el.display_type === 6) {
                  return (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeSeven type={1} index={index} list={el} />
                        </div>
                      </div>
                    </div>
                  );
                } else if (el.display_type === 7) {
                  return (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeEight type={1} index={index} list={el} />
                        </div>
                      </div>
                    </div>
                  );
                } else if (el.display_type === 8) {
                  return (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeNine type={1} index={index} list={el} />
                        </div>
                      </div>
                    </div>
                  );
                } else if (el.display_type === 9) {
                  return (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>
                        <div className="row">
                          <RtypeTen type={1} index={index} list={el} />
                        </div>
                      </div>
                    </div>
                  );
                }
                // else if (el.display_type === 10 && el.para_que === 1) {
                //   return (
                //     <div className="row box">
                //       <div className="col-md-12">
                //         <div className="row">
                //         <Rtypepara type={1} index={index} list={el} />
                //         </div>
                //       </div>
                //     </div>
                //   );
                // }
                else if (el.display_type === 10 && el.para_que === 2) {
                  if(el.easy!=null){
                    return (
                      <>
                      <div className="row box">
                        <div className="col-md-12">
                          <div className="row">
                          <Rtypepara type={1} index={index} list={el.easy} />
                          </div>
                        </div>
                      </div>
                        <div className="row box">
                        <div className="col-md-12">
                          <div className="row">
                          <p className="StepTitle">
                            {(index + 1).toString().padStart(2, "0")}
                          </p>
                          <RtypeParaOption type={1} index={index} list={el} />
                          </div>
                        </div>
                      </div>
</>
                    );
                  }else{
                    return (
                      <div className="row box">
                        <div className="col-md-12">
                          <div className="row">
                          <p className="StepTitle">
                            {(index + 1).toString().padStart(2, "0")}
                          </p>
                          <RtypeParaOption type={1} index={index} list={el} />
                          </div>
                        </div>
                      </div>
                    );
                  }
                  
                }
                // else  if(el.display_type === 10) {
                //   const groupedItems = [];
                //   let sectionItems = []; // To store items for the current section
                  
                //   el.data.forEach((ell, indexel) => {
                //     if (ell.para_que === 1) {
                //       sectionItems.push(
                //         <div className="row" key={ell.pk}>
                //           <div className="col-md-12">
                //             <p className="StepTitle">{(index + 1).toString().padStart(2, "0")}</p>
                //             <div className="row">
                //               <Rtypepara type={1} index={index} list={ell} />
                //             </div>
                //           </div>
                //         </div>
                //       );
                //     } else if (ell.para_que === 2 && sectionItems.length > 0) {
                //       sectionItems.push(
                //         <div className="row" key={ell.pk}>
                //           <RtypeParaOption type={1} index={index} list={ell} />
                //         </div>
                //       );
                //     }
                //   });
                //   // Check if there are items in the sectionItems and add them to the groupedItems array
                //   if (sectionItems.length > 0) {
                //     groupedItems.push(
                //       <div className="row box" key={listData[0].pk}>
                //         <div className="col-md-12">
                //           <p className="StepTitle">{(listData.length - sectionItems.length + 1).toString().padStart(2, "0")}</p>
                //           {sectionItems} {/* Show the section items */}
                //         </div>
                //       </div>
                //     );
                //   }
                //   return groupedItems;

                  
                         


                // }
            
                return null; // Return null for non-display_type 10 items
              })}
          </section>
        </div>
      </div>
    </div>
  );
}
