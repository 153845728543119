import React from "react";
import "./Question.css";

//display type 5 means -   mcq design
// sample design
// Which pair of district and waterfall is not correctly paired?
// (A) Dharmapuri - Hogenakkal
// (B) Namakkal - Agayagangai
// (C) Theni - Kumbakkarai
// (D) Coimbatore - Kumbakkarai

function Qtypesix(props) {
  const { index } = props;

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  const options_en_1_word = props.list.options_en_1.split(" - ");
  const options_en_2_word = props.list.options_en_2.split(" - ");
  const options_en_3_word = props.list.options_en_3.split(" - ");
  const options_en_4_word = props.list.options_en_4.split(" - ");

  const options_tm_1_word = props.list.options_tm_1.split(" - ");
  const options_tm_2_word = props.list.options_tm_2.split(" - ");
  const options_tm_3_word = props.list.options_tm_3.split(" - ");
  const options_tm_4_word = props.list.options_tm_4.split(" - ");

  return (
    <div className="Question_temp">
      {props.list.en_question !==null && props.list.en_question !=="" && (
        <div className="row">
          <div className="col-md-12">
            <span className="question_labels">{props.list.en_question}</span>
          </div>
          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="forms_input">
                      <input
                        type="radio"
                        className="radioInput"
                        checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_1)}
                        name={`q_answer_${index}`}
                        value={props.list.options_en_1}
                        // disabled = {props.formData[0].lang === 0 ? true : false}
                        onChange={(e) =>
                          props.handleOptionChange(
                            e,
                            props.list.pk,
                            props.list.batch_id,
                            props.list.event_pk,
                            userid,
                            1
                          )
                        } // related to parent
                      />{" "}
                      <span className=" answer_label opone ms-1">
                        (A) {options_en_1_word[0]}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="forms_input">
                      <input
                        type="radio"
                        checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_2)}
                        className="radioInput"
                        name={`q_answer_${index}`}
                        value={props.list.options_en_2}
                        // disabled = {props.formData[0].lang === 0 ? true : false}
                        onChange={(e) =>
                          props.handleOptionChange(
                            e,
                            props.list.pk,
                            props.list.batch_id,
                            props.list.event_pk,
                            userid,
                            1
                          )
                        } // related to parent
                      />{" "}
                      <span className=" answer_label optwo ms-1">
                        (B) {options_en_2_word[0]}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="forms_input">
                      <input
                        type="radio"
                        className="radioInput"
                        checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_3)}
                        name={`q_answer_${index}`}
                        value={props.list.options_en_3}
                        // disabled = {props.formData[0].lang === 0 ? true : false}
                        onChange={(e) =>
                          props.handleOptionChange(
                            e,
                            props.list.pk,
                            props.list.batch_id,
                            props.list.event_pk,
                            userid,
                            1
                          )
                        } // related to parent
                      />{" "}
                      <span className=" answer_label opthree ms-1">
                        (C) {options_en_3_word[0]}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="forms_input">
                      <input
                        type="radio"
                        className="radioInput"
                        checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_4)}
                        name={`q_answer_${index}`}
                        value={props.list.options_en_4}
                        // disabled = {props.formData[0].lang === 0 ? true : false}
                        onChange={(e) =>
                          props.handleOptionChange(
                            e,
                            props.list.pk,
                            props.list.batch_id,
                            props.list.event_pk,
                            userid,
                            1
                          )
                        } // related to parent
                      />{" "}
                      <span className=" answer_label opfour ms-1">
                        (D) {options_en_4_word[0]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                  <div className="forms_input">
                      <span className="answer_label">-</span>{" "}
                      <span className=" answer_label ms-2">{options_en_1_word[1]}</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                  <div className="forms_input">
                      <span className=" answer_label">-</span>{" "}
                      <span className="answer_label ms-2">{options_en_2_word[1]}</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                  <div className="forms_input">
                      <span className="answer_label">-</span>{" "}
                      <span className="answer_label ms-2">{options_en_3_word[1]}</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                  <div className="forms_input">
                      <span className="answer_label">-</span>{" "}
                      <span className="answer_label ms-2">{options_en_4_word[1]}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.list.tm_question !==null && props.list.tm_question !=="" && (
        <div className="row tamilQues">
          <div className="col-md-12">
          <span className="question_labels">{props.list.tm_question}</span>
          </div>
          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="forms_input">
                      <input
                        type="radio"
                        className="radioInput"
                        name={`q_answer_${index}`}
                        checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_1)}
                        value={props.list.options_tm_1}
                        // disabled = {props.formData[0].lang === 0 ? true : false}
                        onChange={(e) =>
                          props.handleOptionChange(
                            e,
                            props.list.pk,
                            props.list.batch_id,
                            props.list.event_pk,
                            userid,
                            0
                          )
                        } // related to parent
                      />{" "}
                      <span className=" answer_label opone ms-1">
                        (A) {options_tm_4_word[0]}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="forms_input">
                      <input
                        type="radio"
                        className="radioInput"
                        name={`q_answer_${index}`}
                        value={props.list.options_tm_2}
                        checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_2)}
                        // disabled = {props.formData[0].lang === 0 ? true : false}
                        onChange={(e) =>
                          props.handleOptionChange(
                            e,
                            props.list.pk,
                            props.list.batch_id,
                            props.list.event_pk,
                            userid,
                           0
                          )
                        } // related to parent
                      />{" "}
                      <span className="answer_label optwo ms-1">
                        (B) {options_tm_2_word[0]}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="forms_input">
                      <input
                        type="radio"
                        className="radioInput"
                        name={`q_answer_${index}`}
                        value={props.list.options_tm_3}
                        checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_3)}
                        // disabled = {props.formData[0].lang === 0 ? true : false}
                        onChange={(e) =>
                          props.handleOptionChange(
                            e,
                            props.list.pk,
                            props.list.batch_id,
                            props.list.event_pk,
                            userid,
                            0
                          )
                        } // related to parent
                      />{" "}
                      <span className="answer_label opthree ms-1">
                        (C) {options_tm_3_word[0]}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="forms_input">
                      <input
                        type="radio"
                        className="radioInput"
                        name={`q_answer_${index}`}
                        checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_4)}
                        value={props.list.options_tm_4}
                        // disabled = {props.formData[0].lang === 0 ? true : false}
                        onChange={(e) =>
                          props.handleOptionChange(
                            e,
                            props.list.pk,
                            props.list.batch_id,
                            props.list.event_pk,
                            userid,
                           0
                          )
                        } // related to parent
                      />{" "}
                      <span className="answer_label opfour ms-1">
                        (D) {options_tm_4_word[0]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                  <div className="forms_input">
                      <span className="answer_label">-</span>{" "}
                      <span className="answer_label ms-2">{options_tm_1_word[1]}</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                  <div className="forms_input">
                      <span className="answer_label">-</span>{" "}
                      <span className="answer_label ms-2">{options_tm_2_word[1]}</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                  <div className="forms_input">
                      <span className="answer_label">-</span>{" "}
                      <span className="answer_label ms-2">{options_tm_3_word[1]}</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                  <div className="forms_input">
                      <span className="answer_label">-</span>{" "}
                      <span className="answer_label ms-2">{options_tm_4_word[1]}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Qtypesix;
