import { validateFEvent } from "./validateEFocus";

export const validateEvent = (data, sDate, type, useref_validation , subTopic) => {
  const errors = {};
  //  console.log(type,"type");
  if (data.name == "") {
    errors.name = "Please enter name.";
  }
  if (!data.Method_type && type === "1") {
    errors.Method_type = "Please choose Method of test.";
  }
    if (!data.testType && type === "1" ) {
    errors.testType = "Please choose test type.";
   }
  if (data.eventDate == "" && sDate == "") {
    errors.eventDate = "Please select event Date.";
  }
  if (data.startTime == "") {
    errors.startTime = "Please select startTime.";
  }
  if (data.endTime == "") {
    errors.endTime = "Please select event Date.";
  }
  if (data.subject == "" && type != "1") {
    errors.subject = "Please choose subject.";
  }
  if (data.staff == "" && type != "1") {
    errors.staff = "Please choose staff.";
  }
  if (!data.no_question && type === "1") {
    errors.no_question = "Please enter no question.";
  }
  if (!data.total_marks && type === "1") {
    errors.total_marks = "Please enter total marks.";
  }
  if (data.desc == "") {
    errors.desc = "Please enter description for student.";
  }
  if (data.desc_staff == "") {
    errors.desc_staff = "Please enter description for faculty.";
  }
  if (!data.qsyllpath) {
    errors.qsyllpath = "Please choose Syllabus file.";
  }
  if (!data.qpath && type === "1") {
    errors.qpath = "Please choose question file.";
  }
 

  validateFEvent(data, sDate, type, useref_validation);
  return errors;
};
