import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import Swal from "sweetalert2";

export default function ViewEventDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, type } = location.state;
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [id]);
  // console.log(type);
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {    console.log("User Id cannot available in localstorge");
  }
  //   const navigateToPreviousScreen = () => {
  //     navigate(-1); // Navigate back by one step in the history
  //   };

  useEffect(() => {
    // console.log(id, "id");
    AxiosInstance.post("api/event/findById", {
      id: id,
      staff_id: "",
      event_type:type,
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          let el = res.data.data;
          setFdata((prevFdata) => ({
            ...prevFdata,
            title: el.name,
            sDate:
              el.start_date !==null
                ? moment(el.start_date).format("YYYY-MM-DD")
                : "",
            startTime: el.start_time,
            endTime: el.end_time,
            subject: el.subject,
            staff_name: el.staff_name,
            description: el.description,
            batchName: el.batch_name,
            category: el.category,
            course: el.category_class,
            classMode: el.class_mode,
            medium: el.medium,
            location: el.location,
            teams_url: el.teams_url,
            batch_id: el.batch_id,
            event_id: el.id,
            test_num: el.test_no,
            test_topic: el.test_topic,
            tol_mark: el.tot_marks,
            no_ques: el.no_of_questions,
            qpath: el.batch_id !==null ? el.que_paper_filepath : null,
          }));
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);

  const [fdata, setFdata] = useState({
    title: "",
    sDate: "",
    startTime: "",
    endTime: "",
    subject: "",
    staff_name: "",
    description: "",
    batchName: "",
    category: "",
    course: "",
    classMode: "",
    medium: "",
    location: "",
    teams_url: "",
    batch_id: "",
    event_id: "",
    test_num: "",
    test_topic: "",
    tol_mark: "",
    no_ques: "",
    qpath: null,
  });

  // // button disabled based on timing 

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const currentTime = moment();
  const startTime = moment(fdata.startTime, 'HH:mm:ss');
  const endTime = moment(fdata.endTime, 'HH:mm:ss');
  // console.log(currentTime,"currentTime");
  useEffect(() => {
    const interval = setInterval(() => {
      const updatedButtonDisabled = moment().isBefore(startTime) || moment().isAfter(endTime);
      setIsButtonDisabled(updatedButtonDisabled);
    }, 1000); // Update the disabled state every second
  
    return () => clearInterval(interval); // Clean up the interval when the component unmounts
  
  }, [startTime, endTime]);



  const handleMeeting = () => {
    window.open(fdata.teams_url, "_blank");
  };

  const handleQuiz = () => {
    let dt = {
      batch_id: parseInt(fdata.batch_id),
      event_id: parseInt(fdata.event_id),
      user_id: userid,
    };
    // console.log(dt,"dt");
    AxiosInstance.post("api/test/start", dt)
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          let startTime = res.data.data[0].start_time !=null ? res.data.data[0].start_time : "";
          let endTime = res.data.data[0].end_time !=null ?  res.data.data[0].end_time : "";
          let batch_id = res.data.data[0].batch_id;
          let event_id = res.data.data[0].event_id;
          let no_ques = res.data.data[0].no_of_questions;
          let tol_mark = res.data.data[0].tot_marks;
          navigate(res.data.data[0].teams_url, {
            state: {
              startTime,
              endTime,
              batch_id,
              event_id,
              no_ques,
              tol_mark,
            },
          });
        } else {
          Swal.fire({
            title: "failure",
            text: res.data.message,
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };


  return (
    <div className=" BathDet">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading  mt-3 d-flex justify-content-between ">
              <h6 className="Title_width"> View Event Details</h6>
              <Link to="javascript:void(0)" onClick={navigateToPreviousScreen} className="CancelButton">
              Back to Schedule
              </Link>
               <button
                  type="button"
                  className="EditEventButtons"
                  onClick={fdata.teams_url !==null ? handleMeeting : ""}
                  disabled={fdata.teams_url !==null ? false : true}
                >
                  {" "}
                  <span class="material-icons">event_available</span>{" "}
                  Join Event
                </button>
            </div>
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Event Details</p>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Event Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.title !==null ? fdata.title : "⎯"}
                      </span>
                    </div>
                  </div>
               
                  
                
                
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Batch Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.batchName !==null ? fdata.batchName : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Category
                      </label>
                      <span className="ReviewLabel">
                        {fdata.category !==null ? fdata.category : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Course
                      </label>
                      <span className="ReviewLabel">
                        {fdata.course !==null ? fdata.course : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Class Mode
                      </label>
                      <span className="ReviewLabel">
                        {fdata.classMode !==null ? fdata.classMode : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Medium
                      </label>
                      <span className="ReviewLabel">
                        {fdata.medium !==null ? fdata.medium : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted"
                      >
                        Location
                      </label>
                      <span className="ReviewLabel">
                        {fdata.location !==null && fdata.location !==""
                          ? fdata.location
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Date
                      </label>
                      <span className="ReviewLabel">
                        {fdata.sDate !==null ? fdata.sDate : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Start Timing
                      </label>
                      <span className="ReviewLabel">
                        {fdata.startTime !==null
                          ? moment(fdata.startTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        End Timing
                      </label>
                      <span className="ReviewLabel">
                        {fdata.endTime !==null
                          ? moment(fdata.endTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Subject
                      </label>
                      <span className="ReviewLabel">
                        {fdata.subject !==null ? fdata.subject : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Faculty Name
                        </label>
                        <span className="ReviewLabel">
                          {fdata.staff_name !==null ? fdata.staff_name : "⎯"}
                        </span>
                      </div>
                    </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Description
                      </label>
                      <span className="ReviewLabel">
                        {fdata.description !==null ? fdata.description : "⎯"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

