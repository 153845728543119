import React from "react";
import "./Question.css";


//display type 7 means - multiple option  mcq design 
// sample design 
// பொருத்தமான விடையைத் தேர்க. 
// a) எதிர் மறுத்துக் கூறல்	1. உற்றது உரைத்தல் விடை 
// b) உடன்பட்டுக் கூறல்	2. இனமொழி விடை 
// c) நேர்ந்ததைக் கூறல்	3. நேர்விடை 
// d) இனமானதைக் கூறல்	4. மறைவிடை 
// 	a	b	c	d
// A	4	1	2	3
// B	4	3	1	2
// C	4	2	3	1
// D	4	3	2	1



function Qtypeeight(props) {
  const { index } = props;

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  const optionList_1 = props.list.options_en_1!="" && props.list.options_en_1!=null ? props.list.options_en_1.split("\n") : [];
  const optionList_2 = props.list.options_en_2!="" && props.list.options_en_2!=null ? props.list.options_en_2.split("\n") : [];
  const optionList_3 = props.list.options_en_3!="" && props.list.options_en_3!=null ? props.list.options_en_3.split("\n") : [];
  const optionList_4 = props.list.options_en_4!="" && props.list.options_en_4!=null ? props.list.options_en_4.split("\n") : [];

  const optionList_tm_1 = props.list.options_tm_1!="" && props.list.options_tm_1!=null ? props.list.options_tm_1.split("\n") : [];
  const optionList_tm_2 = props.list.options_tm_2!="" && props.list.options_tm_2!=null ? props.list.options_tm_2.split("\n") : [];
  const optionList_tm_3 = props.list.options_tm_3!="" && props.list.options_tm_3!=null ? props.list.options_tm_3.split("\n") : [];
  const optionList_tm_4 = props.list.options_tm_4!="" && props.list.options_tm_4!=null ? props.list.options_tm_4.split("\n") : [];
 

 
  return (
    <div className="Question_temp">
{props.list.en_question !=null && props.list.en_question !="" && ( 

<div className="row">
<div className="col-md-12">
<span className="question_labels">
              {EsplitLines.length > 0 ? EsplitLines[0] : ""}
            </span>
</div>

{EsplitLines.map((line, index) => {
  if (index !== 0 &&  line.trim() !== "") {
    return (
      <div className="col-md-6 mt-2" key={index}>
         <span className="question_labels">{line}</span>
      </div>
    );
  }
})}

<div className="col-md-8">
<div class="row">
    <div class="col-md-12 mt-2">
      <div class="row">
      <div class="col-md-2">
          <span className="abcd_label"></span>
        </div>
        <div class="col-md-1">
          <span className="abcd_label">a</span>
        </div>
        <div class="col-md-1">
          <span className="abcd_label">b</span>
        </div>
        <div class="col-md-1">
          <span className="abcd_label">c</span>
        </div>
        <div class="col-md-1">
          <span className="abcd_label">d</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2">
    <div class="row">
      <div class="col-md-2">
      <input
              type="radio"
              className="radioInput"
              name={`q_answer_${index}_en`}
              value={props.list.options_en_1}
              defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_1)}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1
                )
              }
            /> <span className="answer_label">{"A)"}</span>
        </div>
        {optionList_1.map((line, index) => (
            <div className="col-md-1" key={index}>
              <span className="answer_label">{line}</span>
            </div>
          ))}
        
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="row">
      <div class="col-md-2">
      <input
            type="radio"
            className="radioInput"
            defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_2)}
            name={`q_answer_${index}_en`}
            value={props.list.options_en_2}
            onChange={(e) =>
              props.handleOptionChange(
                e,
                props.list.pk,
                props.list.batch_id,
                props.list.event_pk,
                userid,
                1
              )
            }
          /> <span className="answer_label">{"B)"}</span>
        </div>
        {optionList_2.map((line, index) => (
            <div className="col-md-1" key={index}>
              <span className="answer_label">{line}</span>
            </div>
          ))}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-2">
 
      <div class="row">
      <div class="col-md-2">
      <input
            type="radio"
            className="radioInput"
            defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_3)}
            name={`q_answer_${index}_en`}
            value={props.list.options_en_3}
            onChange={(e) =>
              props.handleOptionChange(
                e,
                props.list.pk,
                props.list.batch_id,
                props.list.event_pk,
                userid,
                1
              )
            }
          /> <span className="answer_label">{"C)"}</span>
        </div>
        {optionList_3.map((line, index) => (
            <div className="col-md-1" key={index}>
              <span className="answer_label">{line}</span>
            </div>
          ))}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="row">
      <div class="col-md-2">
      <input
              type="radio"
              className="radioInput"
              defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_4)}
              name={`q_answer_${index}_en`}
              value={props.list.options_en_4}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1
                )
              }
            /> <span className="answer_label">{"D)"}</span>
        </div>
        {optionList_4.map((line, index) => (
            <div className="col-md-1" key={index}>
              <span className="answer_label">{line}</span>
            </div>
          ))}
        
      </div>
    </div>
  </div>

</div>
</div>

          )}

          {props.list.tm_question !=null && props.list.tm_question !="" && ( 
<div className="row tamilQues">
<div className="col-md-12">
<span className="question_labels">
              {splitLines.length > 0 ? splitLines[0] : ""}
            </span>
</div>
{splitLines.map((line, index) => {
  if (index !== 0 && line.trim() !=="") {
    return (
      <div className="col-md-6 mt-2" key={index}>
         <span className="question_labels">{line}</span>
      </div>
    );
  }
})}

<div className="col-md-8">

  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="row">
      <div class="col-md-2">
          <span className="abcd_label"></span>
        </div>
        <div class="col-md-1">
          <span className="abcd_label">a</span>
        </div>
        <div class="col-md-1">
          <span className="abcd_label">b</span>
        </div>
        <div class="col-md-1">
          <span className="abcd_label">c</span>
        </div>
        <div class="col-md-1">
          <span className="abcd_label">d</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-2">
    <div class="row">
      <div class="col-md-2">
      <input
       className="radioInput"
              type="radio"
              name={`q_answer_${index}_tm`}
              defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === optionList_tm_1)}
              value={props.list.options_tm_1}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0
                )
              }
            /> <span className="answer_label">{"A)"}</span>
        </div>
        {optionList_tm_1.map((line, index) => (
            <div className="col-md-1" key={index}>
              <span className="answer_label">{line}</span>
            </div>
          ))}
        
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="row">
      <div class="col-md-2">
      <input
            type="radio"
            className="radioInput"
            defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_2)}
            name={`q_answer_${index}_tm`}
            value={props.list.options_tm_2}
            onChange={(e) =>
              props.handleOptionChange(
                e,
                props.list.pk,
                props.list.batch_id,
                props.list.event_pk,
                userid,
                0
              )
            }
          /> <span className="answer_label" >{"B)"}</span>
        </div>
        {optionList_tm_2.map((line, index) => (
            <div className="col-md-1" key={index}>
              <span className="answer_label">{line}</span>
            </div>
          ))}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-2">
 
      <div class="row">
      <div class="col-md-2">
      <input
            type="radio"
            className="radioInput"
            name={`q_answer_${index}_tm`}
            defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_3)}
            value={props.list.options_tm_3}
            onChange={(e) =>
              props.handleOptionChange(
                e,
                props.list.pk,
                props.list.batch_id,
                props.list.event_pk,
                userid,
                0
              )
            }
          /> <span  className="answer_label">{"C)"}</span>
        </div>
        {optionList_tm_3.map((line, index) => (
            <div className="col-md-1" key={index}>
              <span className="answer_label">{line}</span>
            </div>
          ))}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="row">
      <div class="col-md-2">
      <input
              type="radio"
              className="radioInput"
              name={`q_answer_${index}_tm`}
              defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_4)}
              value={props.list.options_tm_4}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0
                )
              }
            /> <span  className="answer_label">{"D)"}</span>
        </div>
        {optionList_tm_4.map((line, index) => (
            <div className="col-md-1" key={index}>
              <span className="answer_label">{line}</span>
            </div>
          ))}
        
      </div>
    </div>
  </div>


</div>


</div>

          )}
    
    </div>
  );
}

export default Qtypeeight;
