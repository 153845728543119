import "./ModelExamForm.css";
import "../payment/Payment.css";
import Razorpay from "react-razorpay";
import intlTelInput from "intl-tel-input";
import {
  Alarm,
  ConstructionOutlined,
  CurrencyRupee,
  Download,
  Visibility,
  LaptopChromebook,
  Today,
} from "@mui/icons-material";

import React, { useState, useEffect, useRef, useContext } from "react";
import $ from "jquery";
/// import files

import { AxiosInstance } from "../../../axios/index";
import { validateFields } from "./Validation";
import { TechQualification } from "../../../pages/TechnicalQualification";
import {
  ImageUrlPrefix,
  LBL_BASIC_DETAILS,
  LBL_CONTACT_DETAILS,
  LBL_ID_PROOFS,
  PaymentSKey,
} from "../../../labels/index.js";
import Subnav from "../../../features/Core/Header/Subnav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SpecialPrefrence from "../../../pages/dashboard/SpecialPrefrence";
import MultiSelect from "multiselect-react-dropdown";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import { object } from "yup";
import facebook from "../../../img/socialicons/facebook.png";
import insta from "../../../img/socialicons/insta.png";
import linkedin from "../../../img/socialicons/linkedin.png";
import twitter from "../../../img/socialicons/twitter.png";
import whatsapp from "../../../img/socialicons/whastapp.png";
import youtube from "../../../img/socialicons/youtube.png";
// import { PictureAsPdfIcon } from '@mui/icons-material'
import { toWords } from "number-to-words";
import { ToWords } from "to-words";
import Select, { components, useStateManager } from "react-select";
import { customStyles } from "../../../pages/customStyles";
import DatePickerSample from "../DatePicker";
import ModelComForP from "../../../pages/ModelComForP.js";
import { TechModelExam } from "./TechModelExam.js";
import { multiCusutomStyle } from "../../../pages/Multicustomstyles.js";
import { CommonOption } from "../../../labels/Lableoption.js";
import { UserContext } from "../../../context/UserDetails.js";

// function start

function ReviewModelexam() {
  //useState for all dropdown , input fields

  //fields use current fields focus
  const navigate = useNavigate();
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });
  const { user, logout } = useContext(UserContext);
  const { edit, user_id, admission_id } = useParams();
  const txtAdReply_student = useRef(null);
  const txtAdFname = useRef(null);
  const txtAdLname = useRef(null);
  const txtAdFath = useRef(null); // New ADDED
  const txtAdAge = useRef(null);
  const txtAdAno = useRef(null);
  const txtAdMStatus = useRef(null); //New
  const txtAdCno = useRef(null); // New ADDED
  const txtAdACno = useRef(null);
  const txtAdcMode = useRef(null); //New Added
  const txtAdMDob = useRef(null);
  const txtAdMedium = useRef(null); // New
  const txtAdGender = useRef(null);
  const txtAdPhno = useRef(null); //New
  const txtAdPsname = useRef(null); //New
  const txtAdParea = useRef(null); //New
  const txtAdPland = useRef(null); // New ADDED
  const txtAdPpost = useRef(null); // New ADDED
  const txtAdPtaluk = useRef(null); // New ADDED
  const txtAdPpincode = useRef(null); // New ADDED
  const txtAdPcity = useRef(null); // New ADDED
  const txtAdPstate = useRef(null); // New ADDED
  const txtAdPdistrict = useRef(null); // New ADDED
  const txtSignpath = useRef(null);
  const txtAdApath = useRef(null);
  const txthallExamPhoto = useRef(null);
  const txtTechCourse = useRef(null);
  const txtAdPerhno = useRef(null); // New ADDED
  const txtAdPersname = useRef(null); // New ADDED
  const txtAdPerarea = useRef(null); // New ADDED
  const txtAdPerland = useRef(null); // New ADDED
  const txtAdPererpost = useRef(null); // New ADDED
  const txtAdPertaluk = useRef(null); // New ADDED
  const txtAdPerpincode = useRef(null); // New ADDED
  const txtAdPercity = useRef(null); // New ADDED
  const txtAdPerstate = useRef(null); // New ADDED
  const txtAdPerdistrict = useRef(null); // New ADDED
  const txtAdPSize = useRef(null);
  const txtAdPno = useRef(null);
  const txtAdWno = useRef(null);
  const txtAdFpo = useRef(null);
  const txtAdEmail = useRef(null);
  const txtbatchDet = useRef(null);
  const txtAdCate = useRef(null);
  const txtAdCourse = useRef(null);
  const txtAdDistrict = useRef(null);
  const txtAdExamReg = useRef(null);
  const txtAdayear = useRef(null);
  const txtAdacate = useRef(null);
  const txtadVerifyHere = useRef(null);
  const txtAdacourse = useRef(null);
  const txtAdaIdnum = useRef(null);
  const txtAdafile = useRef(null);

  const [pgDegree, setPGDegree] = useState([]);
  const [ipgDegree, setIPGDegree] = useState([]);
  const [readbatch, setReadbatch] = useState([]);
  const [readSpecialpre, setReadSpecialpre] = useState([]);
  const [step, setStep] = useState(1);
  const [cOption, setCOption] = useState([]);
  const [catOption, setCatOption] = useState([]);
  const [teachMedium, setTeachMedium] = useState([]);
  const [department, setDepartment] = useState([]);
  const [examination, setExamination] = useState([]);
  const [previousexam, setPreviousexam] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [technicalOption, setTechnicalOption] = useState([]);
  const [pcDegree, setPCDegree] = useState([]);
  const [diplomaDegree, setDiplomaDegree] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [appcategoryOption, setAppCategoryOption] = useState([]);
  const [appcategoryOptionAll, setAppCategoryOptionAll] = useState([]);
  const [coursesCate, setCoursesCate] = useState([]);
  const [dropdown, setDropDown] = useState({
    Cast: null,
    AppCast: null,
    CourseCate: null,
  });
  const [offSpl, setOffSpl] = useState(0);
  const [offSplDisplayType, setOffSplDisplayType] = useState(0);
  const [appCastLable, setAppCastLable] = useState([]);
  const [city, setCity] = useState([]);
  const [batchId, setBatchId] = useState(null);
  const [major, setMajor] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [specialPrefrence, setSpecialPrefrence] = useState([]);
  const [ugDegree, setUGDegree] = useState([]);
  const [listErrors, setListErrors] = useState({});
  const [batchDetails, setBatchDetails] = useState([]);
  const [courseAias, setCourseAias] = useState([]);
  const [district, setDistrict] = useState([]);
  const [examCenter, setExamCenter] = useState([]);
  const [dis, setDis] = useState(null);
  const [batchMessage, setBatchMessage] = useState(null);
  //admission id  after the step 4 api call
  const [admissionID, setAdmissionID] = useState({
    adId: 0,
    is_paid: null,
    batch_id: null,
  });
  let ActualFees = 0; // for exam fees selected panel maintain step 7
  const [couponApplied, setCouponApplied] = useState({
    coupon_status: false,
    amount: "",
    coupon_code: "",
  });
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [step]);
  //education useState

  const [educationQualification, setEducationQualification] = useState([
    "SSLC",
  ]);
  const [sslcCerficates, setSslcCerficates] = useState([
    {
      name: "sslc",
      SSLC_CNo: "",
      SSLC_Year: "",
      SSLC_Marks: "",
      SSLC_Eboard: "",
      SSLC_Scl: "",
      SSLC_MI: "",
      SSLC_Path: null,
      original_file_path: null,
    },
    {
      name: "hsc",
      HSC_CNo: "",
      HSC_Year: "",
      HSC_Marks: "",
      HSC_Ebaord: "",
      HSC_Scl: "",
      HSC_MI: "",
      HSC_Path: null,
      original_file_path: null,
    },
    {
      name: "UG",
      UGCertificateNumber: "",
      UGYearpassing: "",
      UGDegree: "",
      UGMajor: "",
      UGMajor_Other: "",
      UGMarksPercentage: "",
      UGClassName: "",
      UGNameInstitute: "",
      UG_Path: null,
      original_file_path: null,
    },
    {
      name: "PG",
      PGCertificateNumber: "",
      PGYearpassing: "",
      PGDegree: "",
      PGMajor: "",
      PGMajor_Other: "",
      PGMarksPercentage: "",
      PGClassName: "",
      PGNameInstitute: "",
      PG_Path: null,
      original_file_path: null,
    },
    {
      name: "Integrated PG",
      IPCertificateNumber: "",
      IPYearpassing: "",
      IPDegree: "",
      IPMajor: "",
      IPMajor_Other: "",
      IPMarksPercentage: "",
      IPClassName: "",
      IPNameInstitute: "",
      IP_Path: null,
      original_file_path: null,
    },
    {
      name: "Diploma",
      DCertificateNumber: "",
      DYearpassing: "",
      DDegree: "",
      DMajor: "",
      DMajor_other: "",
      DMarksPercentage: "",
      DClassName: "",
      DNameInstitute: "",
      D_Path: null,
      original_file_path: null,
    },
    {
      name: "Professional Courses",
      PCCertificateNumber: "",
      PCYearpassing: "",
      PCDegree: "",
      PCMajor: "",
      PCMajor_Other: "",
      PCMarksPercentage: "",
      PCClassName: "",
      PCNameInstitute: "",
      PC_Path: null,
      original_file_path: null,
    },
    {
      name: "Others",
      OtCertificateNumber: "",
      OtYearpassing: "",
      OtDegree: "",
      OtMajor: "",
      OtMarksPercentage: "",
      OtClassName: "",
      OtNameInstitute: "",
      Ot_Path: null,
      original_file_path: null,
    },
  ]);

  //specialPreference

  const [specialAdmissionPre, setSpecialAdmissionPre] = useState([
    // {
    //   id: 1,
    //   name: "",
    //   file: "",
    // },
  ]);
  //technical qualification
  const [techQualification, setTechQualification] = useState([
    // {
    //   id: 1,
    //   TQ: "",
    //   name: "",
    //   level: "",
    //   lang: "",
    //   error_tq: "",
    //   error_name: "",
    //   error_lang: "",
    //   error_level: "",
    // },
  ]);
  //list of inputs
  const [list, setList] = useState({
    FirstName: "",
    LastName: "",
    Fathername: "",
    MotherName: "",
    Profilephoto: null,
    DOB: "",
    age: 0,
    gender: "Male",
    MaritalStatus: "Single/Unmarried",
    aadharNo: "",
    aadharCardphoto: null,
    signaturePhoto: null, //New Added
    original_sign_path: null, //New Added
    original_aadhar_path: null,
    original_profile_path: null,
    original_caste_path: null,
    Mobile: "",
    MobileCode: "",
    WhatsAppNumberCode: "",
    ParentMobileNoCode: "",
    WhatsAppNumber: "",
    ParentMobileNo: "",
    ContactNumber: false,
    SamePresentAddress: false,
    emailaddress: "",
    BuildingNumberPresent: "",
    BuildingNumberPresent: "",
    StreetNamePresent: "",
    AreaPresent: "",
    LandmarkPresent: "",
    districtPresent: "",
    CityPresent: "",
    statePresent: "",
    PostPresent: "",
    TalukPresent: "",
    PincodePresent: "",
    BuildingNumberPermanent: "",
    StreetNamePermanent: "",
    AreaPermanent: "",
    LandmarkPermanent: "",
    CityPermanent: "",
    districtPermanent: "",
    statePermanent: "",
    PincodePermanent: "",
    PostPermanent: "",
    TalukPermanent: "",
    PresentAddress: false,
    AppCast: "",
    Cast: "",
    CastFile: null,
    category: "",
    SPCcategory: "",
    classMode: "",
    BatchType: "",
    location: "2",
    CompetitiveExamination: "Yes",
    CompetitiveExamName: "",
    Institute: "",
    ExamPreviously: "Yes",
    ExamDetailsRes: "",
    ExamWrite: "",
    workingRes: "Yes",
    Designation: "",
    Department: "",
    JoinGovt: "",
    AIASAStudent: "Yes",
    Year: "",
    CategoryAias: "",
    CourseAias: "",
    IDPhoto: null,
    original_aiasa_id_path: null,
    IDNumber: "",
    ClassType: "",
    CourseCate: "",
    CouresName: "",
    CourseSubCate: "",
    TeachMedium: "",
    Verify: false,
    admission_status: "",
    techQualificationOne: "",
    techQualificationLevel: "",
    techQualificationLang: "",
    location_name: "",
    PPayment: "",
    PPaymentMode: "",
    districts_name: "",
  });

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot available in localstorge");
  }

  //coupon code

  // const [couCode, setCouCode] = useState([]);

  //get user from localstorge

  // multiselect drodown
  // const [selectedOptions, setSelectedOptions] = useState([]);
  //  const onSelect = (selectedList, selectedItem) => {
  //   setSelectedOptions(selectedList);
  // }
  // const onRemove = (selectedList, removedItem) => {
  //   setSelectedOptions(selectedList);
  // }

  //when course category select batch details fill with ui

  const [commentSec, setCommentSec] = useState([
    // {
    //   user_type:"",
    //   updated_dt: null,
    //   comment:null,
    //   admission_id: "",
    // }
  ]);

  //   useEffect(() => {
  //     const userJSON = localStorage.getItem("userDetails");
  //     if (userJSON) {
  //       const user = JSON.parse(userJSON);
  //       if (user && user.user_id) {
  //         const userid = user.user_id; // Correct variable name
  //         AxiosInstance.post("api/course/admission/chat/comm/list", {
  //           user_id: user_id, // Use user_id
  //           admission_id: parseInt(admission_id),
  //           type: 4 // model exam
  //         }).then((res) => {
  //           console.log(res);
  //           if (res.data.status === true) {
  //             const comSec = res.data.data.map((el) => ({
  //               user_type: el.user_type,
  //               admission_id: el.admission_id,
  //               comment: el.comment,
  //               updated_dt: el.updated_dt,
  //             }));
  //             setCommentSec(comSec);
  //           }
  //         }).catch((error) => {
  //           console.error("Error fetching comments:", error);
  //           // Handle the error or set an error state
  //         });
  //       }
  //     }
  //   }, [edit]); // If edit is necessary as a dependency
  // admission form read api for all steps

  useEffect(() => {
    const userJSON = localStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;
        AxiosInstance.post("api/modelexam/view", {
          user_id: user_id,
          admission_id: admission_id,
          type: 4, // model exam
        })
          .then((res) => {
            // console.log(res.data.data);
            const userinfo = res.data.data;
            // --------Updated---------
            const PersonDetails = {
              FirstName: userinfo.first_name,
              LastName: userinfo.last_name,
              Fathername: userinfo.father_name,
              MotherName: userinfo.mother_name,
              Profilephoto:
                userinfo.profile_path !== null ? userinfo.profile_path : null,
              original_profile_path:
                userinfo.original_profile_path !== null
                  ? userinfo.original_profile_path
                  : null,
              signaturePhoto:
                userinfo.specimen_signature_path !== null
                  ? userinfo.specimen_signature_path
                  : null,
              original_sign_path:
                userinfo.original_specimen_signature_path !== null
                  ? userinfo.original_specimen_signature_path
                  : null,
              aadharCardphoto:
                userinfo.aadhar_path !== null ? userinfo.aadhar_path : null,
              original_aadhar_path:
                userinfo.original_aadhar_path !== null
                  ? userinfo.original_aadhar_path
                  : null,
              original_caste_path:
                userinfo.original_caste_path !== null
                  ? userinfo.original_caste_path
                  : null,
              DOB:
                userinfo.dob !== null && userinfo.dob !== ""
                  ? dobCon(userinfo.dob)
                  : "",
              //  DOB: "2000-07-29",
              age: userinfo.age,
              gender: userinfo.gender,
              MaritalStatus: userinfo.marital_status,
              aadharNo: userinfo.aadhar_no,

              Cast: userinfo.community,
              CastFile:
                userinfo.community_path !== null
                  ? userinfo.community_path
                  : null,
              Mobile: userinfo.phone_no,
              WhatsAppNumber: userinfo.whatsapp_no,
              ContactNumber: userinfo.contact_type === 0 ? false : true,
              ParentMobileNo: userinfo.parent_mob_no,
              // MobileCode:
              //   userinfo.country_code !== null ? userinfo.country_code : "+91",
              // WhatsAppNumberCode:
              //   userinfo.country_code !== null ? userinfo.country_code : "+91",
              // ParentMobileNoCode:
              //   userinfo.country_code !== null ? userinfo.country_code : "+91",
              emailaddress: userinfo.email,
              reply_student: "",
            };

            if (userinfo.community !== null) {
              setDropDown((prevState) => ({
                ...prevState,
                ["Cast"]: {
                  value: userinfo.community, // Set to null if userinfo.community is falsy
                  label: userinfo.community, // Set a default label if userinfo.community is falsy
                },
              }));
            }

            if (userinfo.scp.length > 0) {
              const Spc = userinfo.scp.map((el) => ({
                value: el,
                label: el,
              }));
              setAppCastLable(Spc);
            } else {
              setAppCastLable([]);
            }

            let teach =
              userinfo.tech_qualification.length > 0
                ? userinfo.tech_qualification
                    .filter((item) => item.qualification_type === "THQ")
                    .map((el, index) => {
                      const Teachqu = {};
                      Teachqu.id = index === 0 ? 1 : index + 1;
                      Teachqu.TQ = el.name !== "" ? "0" : "1";
                      Teachqu.name = el.name;
                      Teachqu.level = el.level;
                      Teachqu.lang = el.edu_medium;
                      Teachqu.error_tq = "";
                      Teachqu.error_name = "";
                      Teachqu.error_level = "";
                      Teachqu.error_lang = "";
                      return Teachqu;
                    })
                : [
                    {
                      id: 1,
                      TQ: "",
                      name: "",
                      level: "",
                      lang: "",
                      error_tq: "",
                      error_name: "",
                      error_lang: "",
                      error_level: "",
                    },
                  ];
            setTechQualification(teach);

            userinfo.address.map((obj) => {
              if (obj.address_type === 1) {
                PersonDetails.SamePresentAddress =
                  obj.same_address === 0 ? true : false;
                PersonDetails.BuildingNumberPresent = obj.address1;
                PersonDetails.StreetNamePresent = obj.address2;
                PersonDetails.AreaPresent = obj.address3;
                PersonDetails.LandmarkPresent = obj.landmark;
                PersonDetails.districtPresent = obj.district_name;
                PersonDetails.CityPresent = obj.city;
                PersonDetails.statePresent = obj.state_name;
                PersonDetails.PostPresent = obj.post_name;
                PersonDetails.TalukPresent = obj.taluk;
                PersonDetails.PincodePresent = obj.postal_code;
              } else {
                PersonDetails.SamePresentAddress =
                  obj.same_address === 0 ? true : false;
                PersonDetails.BuildingNumberPermanent = obj.address1;
                PersonDetails.StreetNamePermanent = obj.address2;
                PersonDetails.AreaPermanent = obj.address3;
                PersonDetails.LandmarkPermanent = obj.landmark;
                PersonDetails.districtPermanent = obj.district_name;
                PersonDetails.CityPermanent = obj.city;
                PersonDetails.statePermanent = obj.state_name;
                PersonDetails.PostPermanent = obj.post_name;
                PersonDetails.TalukPermanent = obj.taluk;
                PersonDetails.PincodePermanent = obj.postal_code;
              }
            });

            // if(userinfo.Courses.length > 0){
            let el = userinfo.Courses[0]
              ? userinfo.Courses[0]
              : userinfo.Courses;

            PersonDetails.AIASAStudent = el.aiasa_student === 1 ? "Yes" : "NO";
            PersonDetails.Year = el.aiasa_year;
            PersonDetails.CategoryAias = el.aiasa_category;
            PersonDetails.CourseAias = el.aiasa_course;
            PersonDetails.IDNumber = el.aiasa_id_number;
            PersonDetails.IDPhoto = el.aiasa_id_path;
            PersonDetails.original_aiasa_id_path = el.original_aiasa_id_path;
            PersonDetails.admission_status = el.admission_status;
            PersonDetails.CourseCate = el.passed_exam_name;
            PersonDetails.hallticket = el.hall_ticket === 1 ? "Yes" : "No";
            PersonDetails.hallExamPhoto = el.mainexam_hallticket_filepath;
            PersonDetails.original_hallExamPhoto =
              el.original_mainexam_hallticket_filepath;
            PersonDetails.ExamRegNo = el.exam_reg_no;

            if (el.batch_details !== null) {
              PersonDetails.category = el.batch_details.category;
              PersonDetails.CourseCate = el.batch_details.course_id;
              PersonDetails.CouresName = el.batch_details.course_name;
              PersonDetails.TeachMedium = el.batch_details.batch_medium;
              PersonDetails.classMode = el.batch_details.class_mode;
              PersonDetails.districts = el.batch_details.district;
              PersonDetails.districts_name = el.batch_details.district_name;
              setDropDown((prevState) => ({
                ...prevState,
                ["CourseCate"]: {
                  value: el.batch_details.course_name,
                  label: el.batch_details.course_name,
                },
              }));
            } else {
              PersonDetails.category = "";
              PersonDetails.CourseCate = "";
              PersonDetails.CouresName = "";
              PersonDetails.TeachMedium = "";
              PersonDetails.districts = "";
            }

            setList(PersonDetails);

            const bath_id =
              el.batch_details !== null
                ? {
                    batch_id: el.batch_details.batch_id,
                  }
                : null;

            const admiId = userinfo.Courses[0]
              ? {
                  adId:
                    userinfo.Courses[0].batch_details !== null &&
                    userinfo.Courses[0].batch_details.ID !== null
                      ? userinfo.Courses[0].batch_details.ID
                      : null,
                  is_paid: userinfo.Courses[0].is_paid,
                  batch_id:
                    userinfo.Courses[0].batch_details !== null
                      ? userinfo.Courses[0].batch_details.batch_id
                      : null,
                }
              : {
                  adId:
                    userinfo.Courses.batch_details !== null &&
                    userinfo.Courses.batch_details.ID !== null
                      ? userinfo.Courses.batch_details.ID
                      : null,
                  is_paid: userinfo.Courses.is_paid,
                  batch_id:
                    userinfo.Courses.batch_details !== null
                      ? userinfo.Courses.batch_details.batch_id
                      : null,
                };
            setAdmissionID(admiId);

            // }

            setReadbatch([]);

            // console.log(userinfo.Courses, "userinfo.Courses");

            const batchDe =
              userinfo.Courses.batch_details !== null
                ? {
                    batch_id: userinfo.Courses.batch_details.batch_id,
                    name: userinfo.Courses.batch_details.batch_name,
                    id: userinfo.Courses.batch_details.batch_id,
                    paper_type: userinfo.Courses.batch_details.paper_type
                      ? userinfo.Courses.batch_details.paper_type
                      : 0,
                    is_selected_all:
                      userinfo.Courses.batch_details.exam_details.length ===
                      userinfo.Courses.batch_details.exam_details.filter(
                        (el) => el.is_selected_exam
                      ).length
                        ? true
                        : false,
                    img_url: userinfo.Courses.batch_details.img_url,
                    course_name: userinfo.Courses.batch_details.course_name,
                    course_id: userinfo.Courses.batch_details.course_id,
                    batch_details: userinfo.Courses.batch_details.batch_details,
                    class_mode: userinfo.Courses.batch_details.class_mode,
                    start_on: dobCon(userinfo.Courses.batch_details.start_on),
                    duration: userinfo.Courses.batch_details.duration,
                    act_c_fee: userinfo.Courses.batch_details.act_c_fee,
                    offer_spl: userinfo.Courses.batch_details.offer_spl,
                    dis_amt: userinfo.Courses.batch_details.dis_amt,
                    medium: userinfo.Courses.batch_details.medium,
                    is_selected: userinfo.Courses.batch_details.is_selected,
                    no_of_test: userinfo.Courses.batch_details.no_of_test,
                    batch_name: userinfo.Courses.batch_details.batch_name,
                    partial_payment:
                      userinfo.Courses.batch_details.partial_payment,
                    pay_1_perc: userinfo.Courses.batch_details.pay_1_perc,
                    pay_2_perc: userinfo.Courses.batch_details.pay_2_perc,
                    pay_3_perc: userinfo.Courses.batch_details.pay_3_perc,
                    pay_2_duedate: userinfo.Courses.batch_details.pay_2_duedate,
                    pay_3_duedate: userinfo.Courses.batch_details.pay_3_duedate,
                    is_selected: userinfo.Courses.batch_details.is_selected,
                    exam_details:
                      Object.keys(userinfo.Courses.batch_details.exam_details)
                        .length > 0
                        ? userinfo.Courses.batch_details.exam_details
                            .exam_details
                        : [],
                    exam_fees:
                      Object.keys(userinfo.Courses.batch_details.exam_details)
                        .length > 0
                        ? userinfo.Courses.batch_details.exam_details.exam_fees
                        : [],
                  }
                : null;

            let ExamObj = [];
            batchDe.exam_details.map((el) => {
              ExamObj.push({
                pk: el.exam_detail_id,
                batch_id: el.batch_id,
                exam_title: el.exam_title,
                exam_title_id: el.exam_detail_id,
                started_dt: el.started_dt,
                batch_time: el.batch_time,
                duration_hrs: el.duration,
                syllabus_url: el.syllabus_url,
                last_date_apply: el.last_dt_apply,
                no_of_paper: el.no_of_paper,
                is_selected_exam: el.is_selected_exam === 1 ? true : false,
                papers: el.papers,
              });
            });
            batchDe.exam_details = ExamObj;

            setReadbatch([batchDe]);

            setBatchDetails([batchDe]);
          })
          .catch((error) => {
            return error;
          });
      }
    }
  }, []);

  useEffect(() => {
    if (step === 2) {
      // Initialize intl-tel-input on component mount
      const iti = intlTelInput(txtAdPno.current, {
        // Add options as needed, refer to the intl-tel-input documentation
        initialCountry: "in",
        separateDialCode: true,
      });

      // You can listen for changes if needed
      txtAdPno.current.addEventListener("countrychange", () => {
        // console.log('Country changed:', iti.getSelectedCountryData());
        const selectedCountryData = iti.getSelectedCountryData();
        setList((prevFields) => ({
          ...prevFields,
          MobileCode: `+${selectedCountryData.dialCode}`, // Update phone number with the new country code
        }));
      });
      // -----------------Updated ------------------
      const iti_Father = intlTelInput(txtAdFpo.current, {
        // Add options as needed, refer to the intl-tel-input documentation
        initialCountry: "in",
        separateDialCode: true,
      });
      txtAdFpo.current.addEventListener("countrychange", () => {
        // console.log('Country changed:', iti.getSelectedCountryData());
        const selectedCountryData = iti_Father.getSelectedCountryData();
        setList((prevFields) => ({
          ...prevFields,
          MobileCode: `+${selectedCountryData.dialCode}`, // Update phone number with the new country code
        }));
      });

      const iti_wha = intlTelInput(txtAdWno.current, {
        // Add options as needed, refer to the intl-tel-input documentation
        initialCountry: "in",
        separateDialCode: true,
      });
      // You can listen for changes if needed
      txtAdWno.current.addEventListener("countrychange", () => {
        // console.log('Country changed:', iti_wha.getSelectedCountryData());
        const selectedCountryData = iti_wha.getSelectedCountryData();
        setList((prevFields) => ({
          ...prevFields,
          WhatsAppNumberCode: `+${selectedCountryData.dialCode}`, // Update phone number with the new country code
        }));
      });

      // Clean up on component unmount
      return () => {
        iti.destroy();
        iti_wha.destroy();
      };
    }
  }, [step]);

  //date and time convert to normal data
  const dobCon = (userdate) => {
    if (!userdate) {
      return ""; // Handle empty input
    }
    const date = new Date(userdate);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Handle invalid date
    }
    // Extract the year, month, and day components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const [swalMsg, setSwalMsg] = useState({
    show: false,
    status: "",
    message: "",
    title: "",
    BottomOkBtn: "",
    BottomCancelBtn: "",
    BottomOkClose: "",
  });

  const getDisocount = (batchId) => {
    // alert(batchId);
    const df = {
      user_id: userid,
      // type: 4,
      batch_id: batchId,
    };
    AxiosInstance.post("api/all/getOldStudDisc", df)
      .then((res) => {
        // console.log(res, "discount batch");
        if (res.data.status === true) {
          let discount = res.data.old_stud_disc;
          // console.log(discount, "discount");
          setDis(discount);
        } else {
          setDis(0); // or set a default value for no discount
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getSpeciOfferDis = (batchId) => {
    const df = {
      user_id: userid,
      type: 4,
      batch_id: batchId,
    };

    // discount_type → 0:stud_enrolled 1:ongoing_batch 2:n_num_stud 3:none
    AxiosInstance.post("api/all/getSpcOfferDisc", df)
      .then((res) => {
        if (res.data.status === true) {
          let SpeciaOffer = res.data.data;
          setOffSpl(SpeciaOffer.offer_perc);
          // 0:stud_enrolled 1:ongoing_batch 2:n_num_stud 3:none
          let speType = 0;
          if (SpeciaOffer.discount_type === 0) {
            speType = 4;
          } else if (SpeciaOffer.discount_type === 1) {
            speType = 1;
          } else if (SpeciaOffer.discount_type === 2) {
            speType = 2;
          } else if (SpeciaOffer.discount_type === 3) {
            speType = 3;
          }
          // 4:stud_enrolled 1:ongoing_batch 2:n_num_stud 3: none 0:Nil

          setOffSplDisplayType(speType);
          // setOffSpl(2); // or set a default value for no discount
        } else {
          setOffSpl(0); // or set a default value for no discount
          setOffSplDisplayType(0);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    AxiosInstance.post("api/examcenter/district", {
      category_course_id: parseInt(list.CourseCate),
    })
      .then((res) => {
        let district = res.data.data.map((item, index) => (
          <option key={index} title={item.exam_center_district} value={item.id}>
            {item.exam_center_district}
          </option>
        ));
        setDistrict(district);
      })
      .catch((error) => {
        return error;
      });
  }, [list.CourseCate]);

  useEffect(() => {
    if (list.districts !== "") {
      AxiosInstance.post("api/examcenter/name", {
        district: list.district,
      })
        .then((res) => {
          let examCenter = res.data.data.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ));
          setExamCenter(examCenter);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [list.districts]);

  // get course list

  useEffect(() => {
    if (list.category !== "") {
      AxiosInstance.get(`api/all/getCourses/${list.category}/4`)
        .then((res) => {
          const coursesCate = res.data.data.map((item, index) => ({
            value: item.id,
            label: item.name,
          }));
          setCoursesCate(coursesCate);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [list.category]);

  // get batch details
  useEffect(() => {
    if (list.category !== "" && list.CourseCate !== "") {
      setBatchDetails([]);
      AxiosInstance.post("api/all/getCourseBatchesList/4", {
        medium: list.TeachMedium !== "" ? list.TeachMedium : "",
        class_mode: list.classMode !== "" ? list.classMode : "",
        location: "",
        batch_type: "",
        preferred_date: [],
        preferred_dept: [],
        category_course_id: parseInt(list.CourseCate),
        passed_exam_name: "",
        district: list.districts !== null ? list.districts : "",
        subject: "",
      })
        .then((res) => {
          let batchss = res.data.data;
          if (res.data.data.length > 0) {
            if (readbatch.length > 0 && readbatch[0] !== null) {
              const batc_Sss = batchss.map((batchObj) => {
                const matchingReadBatch = readbatch.find(
                  (readBatchObj) => readBatchObj.id == batchObj.id
                );

                console.log(matchingReadBatch, "matchingReadBatch");

                if (matchingReadBatch) {
                  batchObj.is_selected = matchingReadBatch.is_selected;

                  if (matchingReadBatch.paper_type === 1) {
                    batchObj.is_selected_all =
                      matchingReadBatch.exam_details.length ===
                      matchingReadBatch.exam_details.filter(
                        (el) => el.is_selected_exam
                      ).length
                        ? true
                        : false;
                    if (
                      matchingReadBatch.exam_details.length ===
                      matchingReadBatch.exam_details.filter(
                        (el) => el.is_selected_exam
                      ).length
                    ) {
                      getSpeciOfferDis(batchObj.id);
                      getDisocount(batchObj.id);
                      batchObj.is_selected_all = true;
                    }
                  } else {
                    batchObj.is_selected_all =
                      matchingReadBatch.exam_details.length ===
                      matchingReadBatch.exam_details.filter(
                        (el) => el.is_selected_exam
                      ).length
                        ? true
                        : false;

                    if (
                      matchingReadBatch.exam_details.length ===
                      matchingReadBatch.exam_details.filter(
                        (el) => el.is_selected_exam
                      ).length
                    ) {
                      getSpeciOfferDis(batchObj.id);
                      getDisocount(batchObj.id);
                      batchObj.is_selected_all = true;
                    }
                  }

                  if (batchObj.paper_type === 1) {
                    console.log(batchObj.exam_details, "matchingReadBatch");
                    batchObj.exam_details =
                      batchObj.exam_details &&
                      batchObj.exam_details.length === 0
                        ? batchObj.exam_details // Return the same exam_details if its length is 0
                        : batchObj.exam_details.map((panel) => {
                            // console.log(
                            //   panel,
                            //   "panel", matchingReadBatch.exam_details
                            // );
                            const matchingPanelReadBatch =
                              matchingReadBatch.exam_details.find(
                                (readPanel) => readPanel.pk == panel.pk
                              );
                            // console.log(
                            //   matchingPanelReadBatch,
                            //   "matchingPanelReadBatch"
                            // );
                            if (matchingPanelReadBatch) {
                              panel.is_selected_exam =
                                matchingPanelReadBatch.is_selected_exam;
                            }
                            return panel;
                          });
                  } else {
                    console.log(matchingReadBatch, "batchObj.exam_details");
                    batchObj.exam_details =
                      batchObj.exam_details &&
                      batchObj.exam_details.length === 0
                        ? batchObj.exam_details // Return the same exam_details if its length is 0
                        : batchObj.exam_details.map((panel) => {
                            const matchingPanelReadBatch =
                              matchingReadBatch.exam_details.find(
                                (readPanel) => readPanel.pk == panel.pk
                              );

                            if (matchingPanelReadBatch) {
                              panel.is_selected_exam =
                                matchingPanelReadBatch.is_selected_exam;
                            }

                            return panel;
                          });
                  }
                }

                return batchObj;
              });
              console.log(batc_Sss, "batc_Sss.batc_Sss");
              setBatchDetails(batc_Sss);
            } else {
              setBatchDetails(batchss);
              // getDisocount(batchss[0].id);
              // getSpeciOfferDis(batchss[0].id);
            }
          } else {
            setBatchDetails(batchss);
            // getDisocount(batchss[0].id);
            // getSpeciOfferDis(batchss[0].id);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [
    list.CourseCate,
    list.category,
    list.districts,
    list.classMode,
    list.TeachMedium,
  ]);
  //Aiasa student (yes | no ) coures

  useEffect(() => {
    if (list.CategoryAias) {
      AxiosInstance.get(`api/all/getCourses/${list.CategoryAias}/4`)
        .then((res) => {
          const courseAias = res.data.data.map((item, index) => ({
            value: item.name,
            label: item.name,
          }));
          setCourseAias(courseAias);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [list.CategoryAias]);

  //when pincode enter city dropdown , state , district  fill with ui

  useEffect(() => {
    if (list.PincodePresent !== "") {
      AxiosInstance.get(`api/all/getAddress/${list.PincodePresent}`)
        .then((res) => {
          let code = res.data.data;
          // const city = code.map((item, index) => (
          //   <option key={index} value={item.city}>
          //     {item.city}
          //   </option>
          // ));
          const city = code.map((item, index) => ({
            value: item.city,
            label: item.city,
          }));
          setCity(city);
          list.statePresent = code[0].state;
          list.districtPresent = code[0].district;
        })
        .catch((error) => {
          return error;
        });
    }
  }, [list.PincodePresent]);
  useEffect(() => {
    if (list.PincodePermanent !== "") {
      AxiosInstance.get(`api/all/getAddress/${list.PincodePermanent}`)
        .then((res) => {
          let code = res.data.data;
          const city = code.map((item, index) => ({
            value: item.city,
            label: item.city,
          }));
          setCity(city);
          list.statePermanent = code[0].state;
          list.districtPermanent = code[0].district;
        })
        .catch((error) => {
          return error;
        });
    }
  }, [list.PincodePermanent]);

  //major,designation , department , exam details , degree  dropdown
  useEffect(() => {
    AxiosInstance.get("api/all/getDegreeMajor/M.A")
      .then((res) => {
        const uniqueMajorData = res.data.data.filter(
          (item, index, self) =>
            self.findIndex((i) => i.name === item.name) === index
        );
        const majorOptions = uniqueMajorData.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setMajor(majorOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDesignation/2")
      .then((res) => {
        const designation = res.data.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          ));
        setDesignation(designation);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDepartment/2")
      .then((res) => {
        const department = res.data.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item, index) => ({
            value: item.name,
            label: item.name,
          }));
        setDepartment(department);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.post("api/all/getPreviousExam")
      .then((res) => {
        const previousexam = res.data.data.map((item, index) => (
          // { key: index, value: item.name }
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setPreviousexam(previousexam);

        const examination = res.data.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));

        setExamination(examination);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/UG")
      .then((res) => {
        const ugDegree = res.data.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setUGDegree(ugDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/PG")
      .then((res) => {
        const pgDegree = res.data.data.map((item, index) => (
          <option key={index} value={item.name}>
            {item.name}
          </option>
        ));
        setPGDegree(pgDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/IPG")
      .then((res) => {
        const ipgDegree = res.data.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setIPGDegree(ipgDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/diploma")
      .then((res) => {
        const diplomaDegree = res.data.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setDiplomaDegree(diplomaDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/PC")
      .then((res) => {
        const pcDegree = res.data.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setPCDegree(pcDegree);
      })
      .catch((error) => {
        return error;
      });

    // console.log(readSpecialpre,"sjdhsjfh");
  }, []);

  // const handlePaymentSuccess = (paymentDetails) => {
  //   setPaymentSuccess(true);
  // };

  //special preference handle change

  const specialAdmissionHandleChange = (e, id, spename) => {
    const { name, value, files, checked } = e.target;
    if (name === "file" && files && files.length > 0) {
      const pref_path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("pref_path", pref_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialAdmissionPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      file: res.data.path,
                      original_pref_path: res.data.originalname,
                    };
                  }

                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "path_sslc" && files && files.length > 0) {
      // console.log("sdsf");
      const scp_path_sslc = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("scp_path_sslc", scp_path_sslc);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialAdmissionPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      path_sslc: res.data.path,
                      original_path_sslc: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "path_hsc" && files && files.length > 0) {
      const scp_path_hsc = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("scp_path_hsc", scp_path_hsc);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialAdmissionPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      path_hsc: res.data.path,
                      original_path_hsc: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "path_ug" && files && files.length > 0) {
      const scp_path_ug = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("scp_path_ug", scp_path_ug);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialAdmissionPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      path_ug: res.data.path,
                      original_path_ug: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "path_pg" && files && files.length > 0) {
      const scp_path_pg = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("scp_path_pg", scp_path_pg);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialAdmissionPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      path_pg: res.data.path,
                      original_path_pg: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "pstm_type") {
      // console.log(value, name , checked);
      if (checked) {
        const psctm = [];
        setSpecialAdmissionPre((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              // console.log(value, "0 type");
              if (value == 0) {
                psctm.push = value;
                // console.log("0 type", psctm);
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: [0, ...q.pstm_type],
                  error_path_sslc: "",
                };
              } else if (value == 1) {
                if (q.pstm_type.includes(0)) {
                  psctm.push = value;
                  return {
                    ...q,
                    [name]: psctm,
                    pstm_type: [1, ...q.pstm_type],
                    error_path_sslc: "",
                  };
                }
                return {
                  ...q,
                  [name]: psctm,
                  error_path_sslc: "Please select sslc",
                };
              } else if (value == 2) {
                if (q.pstm_type.includes(0, 1)) {
                  psctm.push = value;
                  return {
                    ...q,
                    [name]: psctm,
                    pstm_type: [2, ...q.pstm_type],
                    error_path_sslc: "",
                  };
                }
                return {
                  ...q,
                  [name]: psctm,
                  error_path_sslc: "Please select sslc , hsc",
                };
              } else if (value == 3) {
                // console.log(q.pstm_type,"q.pstm_type");
                const valueTocheck = [0, 1, 2];
                if (
                  valueTocheck.every((values) => q.pstm_type.includes(values))
                ) {
                  psctm.push = value;
                  return {
                    ...q,
                    [name]: psctm,
                    pstm_type: [3, ...q.pstm_type],
                    error_path_sslc: "",
                  };
                }
                return {
                  ...q,
                  [name]: psctm,
                  error_path_sslc: "Please select sslc , hsc , ug",
                };
              }
            }
            return q;
          });
        });
      } else {
        const psctm = [];
        psctm.push = value;
        setSpecialAdmissionPre((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              if (value == 0) {
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: [],
                };
              } else if (value == 1) {
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: q.pstm_type.filter(
                    (item) => item !== 2 && item !== 1 && item !== 3
                  ),
                };
              } else if (value == 2) {
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: q.pstm_type.filter(
                    (item) => item !== 2 && item !== 3
                  ),
                };
              } else {
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: q.pstm_type.filter(
                    (item) => item !== parseInt(value)
                  ),
                };
              }
            }
            return q;
          });
        });
      }
    } else {
      setSpecialAdmissionPre((prevState) => {
        // Check if name already exists in prevState
        const isNameExists = prevState.some(
          (q) => q.id !== id && q.name === value
        );
        if (isNameExists) {
          alert("Name already exists");
          return prevState;
        }
        // Update the name if it doesn't already exist
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value };
          }
          return q;
        });
      });
      // setListErrors({
      //   ...listErrors,aaf
      //   ["spc"]:"",
      // });
    }
  };
  //special preference delete function
  const DeleteAdmissionspre = (id) => {
    const val = specialAdmissionPre.filter((item) => {
      return item.id != id;
    });

    setSpecialAdmissionPre(val);
  };

  //special preference Add function

  const AddPrefrence = () => {
    if (specialPrefrence.length > specialAdmissionPre.length) {
      setSpecialAdmissionPre((prevState) => [
        ...prevState,
        {
          id: prevState.length > 0 ? prevState.length + 1 : 1,
          name: "",
          scp_pk: "",
          file: "",
          path_sslc: "",
          path_hsc: "",
          path_ug: "",
          path_pg: "",
          original_pref_path: null,
          original_path_sslc: null,
          original_path_hsc: null,
          original_path_ug: null,
          original_path_pg: null,
          error_name: "",
          error_path_sslc: "",
          error_path_hsc: "",
          error_path_ug: "",
          error_path_pg: "",
          pstm_type: [],
        },
      ]);
    } else {
      Swal.fire({
        title: "Warning",
        text: "you reach more Special Preferences",
        icon: "Warning",
        confirmButtonText: "Ok",
      });
    }
  };

  //dob convert to age function

  const DobCovertAge = (dob) => {
    var ageInYears = moment().diff(dob, "years", false);

    // const dobDate = new Date(dob);
    // const now = new Date();
    // const ageInMs = now - dobDate;
    // const ageInYears = Math.floor(ageInMs / 31536000000); // 1 year ≈ 31536000000 ms

    return ageInYears;
  };

  //handlechange event

  const handleChangeDate = (date, name) => {
    const dateDOB = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateDOB);
    const age = DobCovertAge(date);
    if (age >= 18 && age <= 60) {
      setList({
        ...list,
        [name]: dateDOB,
        ["age"]: age,
      });
      setListErrors({
        ...listErrors,
        [name]: "",
        ["age"]: "",
      });
    } else {
      const err =
        "Please select a DOB for autofill with an age between 18 and 60 years";
      setList({
        ...list,
        age: "",
      });
      setListErrors({
        ...listErrors,
        DOB: err,
      });
    }
  };

  //batch details handle change and store state

  // const handleBatchDetails = (id,examTitleId ,e) => {
  //   const { name, value, files, checked } = e.target;
  //   // console.log(name);
  //   setBatchDetails((prevState) => {
  //     return prevState.map((q) => {
  //       if (q.id === id) {
  //         return { ...q, [name]: checked };
  //       }
  //       return q;
  //     });
  //   });
  // };
  const handleBatchDetails = (id, examTitleId, e) => {
    console.log(id, examTitleId);
    const { checked } = e.target;
    let showAlert = false; // Flag to track if the alert has been shown
    setBatchDetails((prevState) => {
      const isAnyBatchSelected = prevState.some(
        (batch) => batch.is_selected && batch.id !== id
      );
      if (isAnyBatchSelected && checked) {
        showAlert = true;
      }
      if (showAlert) {
        alert("Already batch selected");
        // e.target.checked = false; // Uncheck the checkbox
        // e.target.disabled = true; // Disable the checkbox
        return prevState; // Return the previous state without any changes
      }
      const updatedBatchDetails = prevState.map((batch) => {
        if (batch.id === id) {
          const updatedExamDetails = batch.exam_details.map((exam) => {
            if (exam.pk === examTitleId) {
              console.log(exam.pk, examTitleId);
              return { ...exam, is_selected_exam: checked };
            }
            return exam;
          });
          const isBatchSelected = updatedExamDetails.some(
            (exam) => exam.is_selected_exam
          );

          const isBatchSelected_all =
            batch.exam_details.length ===
            updatedExamDetails.filter((el) => el.is_selected_exam === true)
              .length
              ? true
              : false;

          if (isBatchSelected_all) {
            getSpeciOfferDis(id);
            getDisocount(id);
          } else {
            setOffSpl(0); // or set a default value for no discount
            setDis(0); // or set a default value for no discount
            setOffSplDisplayType(0);
          }

          return {
            ...batch,
            is_selected: isBatchSelected,
            is_selected_all: isBatchSelected_all,
            exam_details: updatedExamDetails,
          };
        }
        return batch;
      });
      return updatedBatchDetails;
    });
    setListErrors({
      ...listErrors,
      batchDetails: "",
    });
  };

  const handleBatchDetailsSelectAll = (id, e) => {
    const { checked } = e.target;
    let showAlert = false; // Flag to track if the alert has been shown
    setBatchDetails((prevState) => {
      const isAnyBatchSelected = prevState.some(
        (batch) => batch.is_selected && batch.id !== id
      );
      if (isAnyBatchSelected && checked) {
        showAlert = true;
      }
      if (showAlert) {
        alert("Already batch selected");
        // e.target.checked = false; // Uncheck the checkbox
        // e.target.disabled = true; // Disable the checkbox
        return prevState; // Return the previous state without any changes
      }
      const updatedBatchDetails = prevState.map((batch) => {
        if (batch.id === id) {
          const updatedExamDetails = batch.exam_details.map((exam) => {
            return { ...exam, is_selected_exam: checked };
          });
          const isBatchSelected = updatedExamDetails.some(
            (exam) => exam.is_selected_exam
          );

          if (isBatchSelected) {
            getSpeciOfferDis(id);
            getDisocount(id);
          } else {
            setOffSpl(0); // or set a default value for no discount
            setDis(0); // or set a default value for no discount
            setOffSplDisplayType(0);
          }
          return {
            ...batch,
            is_selected_all: checked,
            is_selected: isBatchSelected,
            exam_details: updatedExamDetails,
          };
        }
        return batch;
      });
      return updatedBatchDetails;
    });
    setListErrors({
      ...listErrors,
      batchDetails: "",
    });
  };

  //get all payments

  // useEffect(() => {
  //   axios.get('http://localhost:3001/api/payments')
  //     .then(response => {
  //       console.log(response.data.items,"payments");
  //
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // }, [])
  // handle submit function
  // fees,amount,gst,cname,Imgurl

  // handlesubmit function
  const handleSubmit = (
    batchId,
    oldstudentAmount,
    damount,
    AmountPay,
    cname,
    cId
  ) => {
    // console.log(AmountPay);
    const userJSON = localStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;
        const CouresAmount = AmountPay;
        const CouresName = cname;
        const gstrate = 18;
        const discountAmount = damount;
        const batch_id = batchId;
        const course_id = cId;
        const receipt = "Order_" + Math.floor(Math.random() * 900 + 100);
        let order = {
          amount: Math.ceil(CouresAmount) * 100,
          currency: "INR",
          receipt: receipt,
        };
        // console.log(CouresAmount, "sdmafbasfj");
        // console.log(order, "order");
        AxiosInstance.post("api/payemnt/create_order", {
          amount: CouresAmount * 100,
          currency: "INR",
          receipt: receipt,
        })
          .then((res) => {
            if (res) {
              const { data } = res;
              const options = {
                key: PaymentSKey,
                amount: data.amount,
                currency: "INR",
                name: CouresName,
                prefill: {
                  name:
                    list.FirstName != null && list.FirstName !== ""
                      ? list.FirstName
                      : "Anna iasa",
                  email:
                    list.email != null && list.email !== ""
                      ? list.email
                      : "sample@annaiasacademy.com",
                  contact:
                    list.Mobile != null && list.Mobile !== ""
                      ? list.Mobile
                      : "1234567890",
                },
                description: "For Coures Admission ",
                image: "B",
                order_id: data.id,
                handler: function (response) {
                  // console.log(response);
                  if (response) {
                    const dat = {
                      step: 6,
                      type: 4,
                      subject: [],
                      category: list.SPCcategory
                        ? list.SPCcategory
                        : list.category
                        ? list.category
                        : "",
                      location_id: parseInt(list.location),
                      Isverify: list.Verify || false,
                      AdmissionID: admissionID.adId ? admissionID.adId : 0,
                      CouresName: CouresName,
                      discountAmount: discountAmount,
                      gst: gstrate,
                      batch_id: batch_id,
                      coures_id: course_id,
                      user_id: userid,
                      old_stud_disc_perc: dis,
                      old_stud_disc: oldstudentAmount,
                      iscoupon: couponApplied.status === true ? true : false,
                      couponAmount:
                        couponApplied.amount !== "" ? couponApplied.amount : 0,
                      couponcode: couponApplied.coupon_code,
                      Netamount: data.amount / 100,
                      status: data.status,
                      transaction_id: response.razorpay_payment_id,
                      razorpay_order_id: response.razorpay_order_id,
                      razorpay_signature: response.razorpay_signature,
                      created_at: moment
                        .unix(data.created_at)
                        .format("YYYY-MM-DD HH:mm:ss"),
                    };

                    AxiosInstance.post("api/course/admission/register", dat)
                      .then((res) => {
                        // console.log(res);
                        if (res.data.status === true) {
                          const message = res.data.data.batch_message;
                          setBatchMessage([message]);
                          const admId = res.data.data.id;
                          // console.log(res.data.data.transaction_id, "step6");
                          AxiosInstance.get(
                            `api/payments/${res.data.data.transaction_id}`
                          )
                            .then((res) => {
                              // console.log(res);
                              if (res.status === 200) {
                                const payInfo = res.data;
                                const paymentHistory = {
                                  id: payInfo.id,
                                  admission_history_id: admId,
                                  entity: payInfo.entity,
                                  amount: payInfo.amount / 100,
                                  currency: payInfo.currency,
                                  status: payInfo.status,
                                  order_id: payInfo.order_id,
                                  invoice_id: payInfo.invoice_id,
                                  international: payInfo.international,
                                  method: payInfo.method,
                                  amount_refunded: payInfo.amount_refunded,
                                  refund_status: payInfo.refund_status,
                                  captured: payInfo.captured,
                                  description: payInfo.description,
                                  card_id: payInfo.card_id,
                                  bank: payInfo.bank,
                                  wallet: payInfo.wallet,
                                  vpa: payInfo.vpa,
                                  email: payInfo.email,
                                  contact: payInfo.contact,
                                  notes:
                                    payInfo.notes.length > 0
                                      ? payInfo.notes.toString()
                                      : null,
                                  fee: payInfo.fee,
                                  tax: payInfo.tax,
                                  error_code: payInfo.error_code,
                                  error_description: payInfo.error_description,
                                  error_source: payInfo.error_source,
                                  error_step: payInfo.error_step,
                                  error_reason: payInfo.error_reason,
                                  acquirer_data: {
                                    rrn: payInfo.acquirer_data.rrn,
                                    upi_transaction_id:
                                      payInfo.acquirer_data.upi_transaction_id,
                                  },
                                  created_at: moment
                                    .unix(payInfo.created_at)
                                    .format("YYYY-MM-DD HH:mm:ss"),
                                  created_by: userid,
                                  payment_type: 1, //1 → online ,0 → offline // we use online now
                                };
                                // console.log(paymentHistory, "paymentHistory");
                                AxiosInstance.post(
                                  "api/course/admission/paid_info",
                                  paymentHistory
                                )
                                  .then((res) => {
                                    if (res.data.status === true) {
                                      const resuserid = userid;
                                      const email = list.emailaddress;

                                      const html_text = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <link rel="stylesheet" href=" https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
</head>
<style>
    @import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

    body {
        background-color: rgb(197, 191, 191);
    }

    .contain {
        top: 10%;
        left: 17%;
        background-color: #FFFFFF !important;
        width: 700px;
        height: 1035px;
        position: absolute;
        /* text-align: center; */
    }

    .contain header {
        position: absolute;
        top: 29px;
        left: 36%;
        width: 200px;
        height: 87px;

        text-align: center;
        /* font: normal normal bold 28px/24px Roboto; */
        letter-spacing: 2.6px;
        color: #C1262E;
        opacity: 1;
        font-family: roboto;
        font-size: 26px;
        font-weight: bold;

    }

    .contain header .head {
        font-size: 65%;
        font-weight: bold;
        font-family: roboto;
        position: absolute;
        left: 7%;
        margin-left: 47px;


    }

    .alter {
        left: 119px;
        position: absolute;
        margin-left: -61%;
        width: 52px;
        height: 48px;
        top: 4px;
    }

    .bc {
        background-color: #ECF3FF !important;
        position: absolute;
        top: 88px;
        height: 735px;
        width: 660px;
        left: 18px;
        opacity: 0.3;
    }

    .first {
        position: relative;
        left: 39px;
        top: 114px;
        font-family: roboto;
    }

    .ul {

        border: 1px solid #C1262E;
        position: relative;
        top: 119px;
        opacity: 0.8;
        left: 41px;
        width: 606px;
    }

    .para1 {
        position: relative;
        top: 132px;
        left: 38px;
        width: 614px;
        font-size: 14px;
        font-family: roboto;
        font-weight: 500;
        color: black;
        text-align: justify;
    }

    .sec {
        position: relative;
        left: 40px;
        top: 130px;
        font-family: roboto;
    }

    .add {
        position: relative;
        top: 125px;
        left: 39px;
        color: black;
        letter-spacing: 0;
        opacity: 1;
        font-family: roboto;
        font-size: 13px;
        height: 10px;
    }

    .inside {
        position: relative;
        font-weight: 500;
        font-size: 12px;
    }

    .third {
        position: relative;
        left: 40px;
        top: 160px;
        font-family: roboto;
        font-size: 14px;
    }

    .four {
        position: relative;
        left: 40px;
        top: 155px;
        font-family: roboto;
        font-size: 14px;
    }

    .bg {
        background-color: #000B28;
        position: relative;
        top: 40px;
        width: 400px;
        left: 4px;
        height: 50px;
    }

    .bg {
        background-color: #000B28;
        position: relative;
        top: 203px;
        width: 700px;
        left: 0px;
        height: 270px;
    }

    .ig {
        position: relative;
        top: 25px;      
    }

    .ig i {
        margin-left: 5px;
        margin-right: 20px;
    }

    .b {
        border: 1px solid #FFFFFF;
        position: relative;
        top: 51px;
        width: 652px;
        opacity: 0.1;
        left: 25px;
    }

    .web {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        text-decoration: underline;
        opacity: 1;
        top: 43px;
        left: 220px;
        width: 118px;
        height: 7px;
    }

    .web1 {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        text-decoration: underline;
        opacity: 1;
        top: -10px;
        left: 347px;
        list-style-type: none;
        /* display: flex; */
    }

    .web2 {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        text-decoration: underline;
        opacity: 1;
        top: -10px;
        left: 356px;
        list-style-type: none;
        /* display: flex; */
    }

    .b1 {
        border: 1px solid #FFFFFF;
        position: relative;
        top: -6px;
        width: 652px;
        opacity: 0.1;
        left: 25px;
    }

    .last {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        opacity: 1;
        top: -9px;
        left: 140px;
        height: 14px;
    }

    .last1 {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        opacity: 1;
        top: -9px;
        left: 186px;
        height: 16px;
    }

    .li1 {
        position: relative;
        color: #C1393D;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        opacity: 1;
    }

    .last2 {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        opacity: 1;
        top: -9px;
        left: 64px;
        height: 16px;
    }

    .last3 {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        opacity: 1;
        top: -9px;
        left: 203px;
        height: 16px;
    }

    .point {
        border: 1px solid white;
        position: relative;
        top: 24px;
        height: 4px;
        left: 345px;
        opacity: 0.1;
    }

    .do {
        display: flex;
        position: relative;
        left: -36px;
    }

    .point1 {
        border: 1px solid white;
        position: relative;       
        left: 351px;
        opacity: 0.1;
    }
    
    .flex3 {
        display: flex;
        justify-content: space-between;
        margin-left: 6em;
        flex-direction: row;
    }

    .flex-items {
        margin-left: 3em !important;
    }

    .privacy_policy {
        margin-top: 3.7em;
        text-align: center;
        margin-bottom: 1.3em;
    }

    .privacy_policy a {
        color: white !important;
        font-family: Roboto, Medium !important;
        font-size: 12px;
    }

    .vertical-line {
        color: #FFFFFF;
        margin-left: 0.5em;
        margin-right: 0.5em;

    }
</style>

<body>
    <div class="contain">
        <header>ANNA
            <div class="head">
                IAS <span>ACADAMY</span>
            </div>
            <img src='https://i1.wp.com/aiasabook.com/wp-content/uploads/2021/10/cropped-logo1.png?fit=512%2C512&ssl=1' alt="logo" class="alter">
        </header>
        <div class="bc"></div>
        <div>
            <h6 class="first">Hi ${
              res.data.data.fullname
            }, Welcome to ANNA IAS ACADAMY!</h6>
        </div>
        <div class="ul"></div>

        <div>
            <p class="para1">Congratulations on successfully completing the payment for your admission to Anna IAS
                Academy. We are thrilled to have you as part of our institute's community and look forward to an
                exciting learning journey together.</p>
        </div>
        <div>
            <p class="para1">To ensure a smooth start, we are providing you with important details regarding your
                admission and payment. Additionally, you can find the attached receipt for your payment details
                mentioned below.</p>
        </div>
        <div>
            <h4 class="sec">Admission Details:</h4>
        </div>
        <div>
            <p class="add"> Batch Name:<span class="inside">&nbsp;${CouresName}</span></p>
            <p class="add">Start Date: <span class="inside"> &nbsp;${
              res.data.data.started_dt
            }</span> </p>
            <p class="add"> Time:<span class="inside">&nbsp;${
              res.data.data.batch_time
            }</span> </p>
            <p class="add"> Duration:<span class="inside">&nbsp;${
              res.data.data.duration + " Months"
            }</span> </p>
            <p class="add">Class Mode: <span class="inside">&nbsp;${
              res.data.data.class_mode
            }</span></p>
            <p class="add"> Location: <span class="inside">&nbsp;${
              res.data.data.location
            }</span></p>
        </div>
        <div>
            <h4 class="sec">Payment Details:</h4>
        </div>
        <div>
            <p class="add">
                Total Amount Paid:<span class="inside">&nbsp;${
                  res.data.data.amount
                } </span></p>
            <p class="add"> Payment Method:<span class="inside">&nbsp;${
              res.data.data.method
            }</span></p>
            <p class="add"> Transaction ID:<span class="inside"> &nbsp;${
              res.data.data.id
            } </span></p>
            <p class="add"> Date and Time of Payment:<span class="inside">&nbsp;${
              res.data.data.created_at
            }</span>
            </p>
        </div>
        <div>
            <p class="para1">Our dedicated Student Support team is available to assist you throughout your academic
                journey. If you have any questions or require further information, please don't hesitate to reach out to
                them at support@annaiasacademy.com.</p>
        </div>
        <div>
            <p class="para1">Once again, congratulations on securing your admission, and we look forward to welcoming
                you to Anna IAS Academy.</p>
        </div>
        <div>
            <h5 class="third" style="margin-bottom:0em;">Thanks,</h5>
        </div>
        <div>
            <h4 class="four">Anna IAS Academy</h4>
        </div>
        <section>
               <footer>
                <div class="bg">
                    <div class="ig">

                        <div  style=" display: flex !important;
        justify-content: space-between !important;
        margin-left: 6em !important;
        flex-direction: row !important;">

                            <img class="flex-items" style=" margin-left: 3em;"
                                src='${facebook}'
                                alt="facebook" width="20" height="20">
                            <img class="flex-items" style=" margin-left: 3em;"
                                src='${twitter}'
                                alt="twitter" width="20" height="20">
                            <img class="flex-items" style=" margin-left: 3em;"
                                src='${linkedin}'
                                alt="linkedin" width="20" height="20">
                            <img class="flex-items" style=" margin-left: 3em;"
                                src='${insta}'
                                alt="insta" width="20" height="20">
                            <img class="flex-items" style=" margin-left: 3em;"
                                src='${whatsapp}'
                                alt="whatsapp" width="20" height="20">
                            <img class="flex-items" style=" margin-left: 3em;"
                                src='${youtube}'
                                alt="youtube" width="20" height="20">
                        </div>

                    </div>
                    <div class="b"></div>
                    <div class="privacy_policy">
                        <a href="#">annaiasacademy.com</a>
                        <span class="vertical-line">|</span>

                        <a href="#">Privacy</a>
                        <span class="vertical-line">|</span>

                        <a href="#">Terms of Use</a>

                    </div>
                    <div class="b1"></div>
                    <div>
                        <p class="last">This email is from Anna IAS Academy - 48 , Vanigar Street, Kanchipuram - 631501.
                        </p>
                    </div>
                    <div class="b1"></div>
                    <div>
                        <p class="last1">
                            If you have any questions please contact us <span class="li1">@annaiasacademy.com</span>
                        </p>
                    </div>
                    <div class="b1"></div>
                    <div>
                        <p class="last2">To ensure delivery to inbox, add <span class="li1">
                                support@annaiasacademy.com</span> to your address book and make it whitelisted</p>
                    </div>
                    <div class="b1"></div>
                    <div>
                        <p class="last3">Copyright © 2023 Anna IAS Academy. All rights reserved</p>
                    </div>
                </div>
            </footer>
        </section>
    </div>


</body>

</html>`;

                                      const data = {
                                        trans_id: res.data.data.id,
                                        type: 4,
                                      };
                                      // console.log(data, "transcation id");
                                      AxiosInstance.post(
                                        "api/purchase/payment/download",
                                        data
                                      )
                                        .then((res) => {
                                          // console.log(res);
                                          // const html_text = `<!DOCTYPE html>
                                          // <html>
                                          // <body>

                                          // <h1>The a download attribute</h1>

                                          // <p>Click on the image to download it:<p>
                                          // <a href="/images/myw3schoolsimage.jpg" download>
                                          //   <img src="/images/myw3schoolsimage.jpg" alt="W3Schools" width="104" height="142">
                                          // </a>

                                          // <p><b>Note:</b> The download attribute is not supported in IE or Edge (prior version 18), or in Safari (prior version 10.1).</p>

                                          // </body>
                                          // </html>`;
                                          const html_text_rec = `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
   
      <title>Document</title>
  </head>
  <style>
  @import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
  @font-face {
    font-family: "Roboto-Regular";  
    src: local("Roboto-Regular"), url(${`../../../../font/font/Roboto/Roboto-Regular.ttf`}) format("truetype");
  }
  @font-face {
    font-family: "Roboto-Medium";  
    src: local("Roboto-Medium"), url(${`../../../../font/font/Roboto/Roboto-Medium.ttf`}) format("truetype");
  }
  @font-face {
    font-family: "Roboto-Bold";  
    src: local("Roboto-Bold"), url(${`../../../../font/font/Roboto/Roboto-Bold.ttf`}) format("truetype");
  }
      .contain
      {
          
          background: #FFFFFF 0% 0% no-repeat padding-box;
          width: 2480px;
      height: 3508px;
          
          opacity: 1;
         
      }
      .contain header
      {
          
  
          width: 202px;
      height: 87px;
      text-align: center;
      /* font: normal normal bold 66px/57px Roboto; */
      letter-spacing: 6.6px;
      color: #C1262E;
      opacity: 1;
      margin-top: 100px;
      margin-left: 47%;
      font-size: 66px;
      font-family:Roboto-Bold ;
  
      }
      .contain header .head{
      
      text-align: center;
      /* font: normal normal bold 51px/55px Roboto; */
      letter-spacing: 0px;
      color: #C1262E;
      opacity: 1;
      height: 46px;
      width: 423px;
      margin-top: 18px;
      margin-left: -37px;
      font-family: Roboto-Bold;
      font-size: 51px;
      line-height: 55px;
  
  
      }
      
      .logo
      {
      margin-top: -163px;
      margin-left: -368px;
      width: 138px;
      height: 138px;
      opacity: 1;
      margin-bottom: 56px;
      }
      
  .topic
  {
      text-align: left;
      /* font: normal normal bold 32px/24px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      height: 43px;
      width: 270px;
      margin-left: 100px;
      margin-top: 194px;
      font-family: Roboto-Bold;
      font-size: 32px;
  }
  .main
  {
      flex-direction: column;
      width: auto;
  }
  .mainone
  {
      text-align: left;
      /* font: normal normal medium 32px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      height: 52px;
      width: 582px;
      font-size: 32px;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      margin-left: 97px;
  }
  .style{
      text-align: left;
      /* font: normal normal bold 32px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      height: 43px;
      width: 392px;
         margin-left: -2px;
      margin-top: -39px;
      font-size: 32px;
      font-family: Roboto-Bold;
  }
  .topicone
  {
      text-align: left;
      /* font: normal normal bold 32px/24px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      height: 43px;
      width: 244px;
      margin-left: 100px;
      margin-top: 112px;
      font-size: 32px;
      font-family: Roboto-Bold;
  }
  .maintwo
  {
      text-align: left;
      /* font: normal normal medium 32px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
       font-size: 32px;
      font-family: Roboto-Medium;
      font-weight: 500;
      height: 52px;
      /* width: 386px; */
      margin-left: 94px;
  }
  .styleone{
      text-align: left;
      /* font: normal normal bold 32px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      font-size: 32px;
      font-family: Roboto-Bold;
      /* margin-left: 60px; */
  }
  .mains
  {
      width: auto;
      flex-direction: column;
  }
  .mainTopicTwo{
      display: flex;
      justify-content: center;
  }
  .mainTopicThree
  {
      display: flex;
      justify-content: left;
  }
  .topictwo
  {
      text-align: left;
      /* font: normal normal bold 40px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      height: 43px;
      width: 400px;
      /* margin-left: 1066px; */
     
      font-size: 40px;
      font-family: Roboto-Bold;
  }
  .paragraph
  {
      /* text-align: left;
  
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      height: 93px;
      font-size: 32px;
      font-family: Roboto-Medium;
      
      margin-left: 100px;
         width: 2238px; */
         text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      height: 93px;
      font-size: 32px;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      margin-left: 100px;
      margin-right: 100px;
      width: 2280px;
      line-height: 50px;
  }
  .mainss
  {
      /* gap: 60px;
      display: flex;
      width: auto; */
      margin-top: 40px;
  }
  .mainthree
  {
      text-align: right;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      /* width: 284px; */
      margin-left: 100px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  .mainthree1
  {
      text-align: right;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      /* height: 43px;
      width: 246px; */
      margin-left: 246px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  
  }
  .mainly
  {
      margin-top: 30px;
      /* gap: 18px;
      display: flex;
      width: auto; */
  }
  .mainfour
  {
      text-align: left;
      /* font: normal normal medium 28px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family:Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 100px;
      margin-top: 25px;
  }
  .mainfour1
  {
      text-align: left;
      /* font: normal normal medium 28px/50px Roboto; */
      letter-spacing: 0px;
      color: #C1262E;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: bold; */
      font-size: 28px;
         margin-left: 89px;
      margin-top: 25px;
  }
  .mainfour7
  {
          text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 127px;
      margin-top: 25px;
  }
  .mainfour2
  {
          text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 362px;
      margin-top: 25px;
  }
  .mainfour3
  {
          text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 217px;
      margin-top: 25px;
  }
  .mainfour4
  {
          text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 355px;
      margin-top: 25px;
  }
  .mainfour5
  {
          text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 142px;
      margin-top: 25px;
  }
  .mainfour6
  {
          text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 77px;
      margin-top: 25px;
  }
  .topicthree
  {
      text-align: left;
      /* font: normal normal bold 40px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      text-transform: uppercase;
      opacity: 1;
      height: 53px;
      width: 1314px;
      margin-left: 95px;
      margin-top: 90px;
      font-family: Roboto-Bold;
      font-size: 40px;
      /* font-weight: bold; */
  }
  .mainfive
  {
      text-align: left;
      /* font: normal normal medium 32px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-size: 32px;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      margin-left: 100px;
      width: 535px;
      height: 52px;
      /* margin-top: 100px; */
  }
  .mainfive1{
      text-align: left;
      /* font: normal normal medium 32px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-size: 32px;
      font-family:Roboto-Medium;
      /* font-weight: 500; */
      margin-left: 100px;
      width: 535px;
      height: 52px;
      margin-top: 60px;
     
  }
  .final
  {
      opacity: 1;
      text-align: center;
      /* font: normal normal normal 32px/43px Roboto; */
      letter-spacing: 0.03px;
      color: #041134;
      margin-left: 100px;
      margin-top: 60px;
      font-size: 32px;
      font-family: Roboto-Regular;
      /* font-weight: normal; */
  }
  .final a{
      text-align: center;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0.03px;
      color: #C1262E;
      text-decoration: none;
      font-size: 32px;
      font-family: Roboto-Bold;
      /* font-weight: bold; */
  }
  .finalone
  {
      margin-top: 70px;
  }
  .finaltwo
  {
      text-align: center;
      /* font: normal normal bold 32px/40px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      /* margin-left: 890px; */
      font-size: 32px;
      font-family: Roboto-Bold;
      line-height: 40px;
      /* font-weight: bold; */
  }
  .finalthree
  {
      margin-top: 550px;
      display: flex;
      justify-content: center;
  }
  .mained
  {
          margin-top: 130px;
  }
  .mainthree2
  {
      text-align: right;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      width: 329px;
      margin-left: 100px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  .mainthree3
  {
      text-align: right;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      width: 188px;
      margin-left: 100px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  .mainthree4
  {
      text-align: right;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      width: 304;
      margin-left: 100px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  .mainthree5
  {
      text-align: right;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      width: 120px;
      margin-left: 100px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  .mainthree6
  {
      text-align: right;
  
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      width: 61px;
      margin-left: 97px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  .mainthree7
  {
      text-align: right;
     
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      width: 71px;
      margin-left: 100px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  </style>
  <body>
        <div class="contain">
          <header >ANNA 
              <div class="head">
                  IAS ACADAMY
              </div>
              <img src="https://i1.wp.com/aiasabook.com/wp-content/uploads/2021/10/cropped-logo1.png?fit=512%2C512&ssl=1" alt="logo" class="logo">
           </header>
           <div>
              <h1 class="topic">Admission Details:</h1>
           </div>
           <div class="main">
              <div class="mainone">Batch Name: <span class="style">${
                res.data.data[0].batch_name
              }</span></div>
              <div class="mainone">  Start Date:<span class="style">${
                res.data.data[0].batch_start_dt
              }</span></div>
              <div class="mainone">Time:<span class="style">${
                res.data.data[0].batch_timing
              }</span>  </div>
              <div class="mainone">Duration:<span class="style">${
                res.data.data[0].duration
              }</span></div>
              <div class="mainone">Class Mode:<span class="style">${
                res.data.data[0].class_mode
              }</span></div>
              <div class="mainone">Branch Location: <span class="style">${
                res.data.data[0].location
              }</span></div>
           </div>
           <div>
              <h1 class="topicone">Payment Details:</h1>
           </div>
           <div class="mains">
              <div class="maintwo">Total Amount Paid: <span class="styleone">${
                res.data.data[0].trans_amount
              }</span></div>
          
              <div class="maintwo">Payment Method:<span class="styleone"> ${
                res.data.data[0].method
              } </span></div>
              <div class="maintwo">Transaction ID:<span class="styleone"> ${
                res.data.data[0].trans_id
              } </span></div>
              <div class="maintwo">Date and Time of Payment:<span class="styleone"> ${
                res.data.data[0].transaction_date
              }</span></div>
              
           </div>
           <div class="mainTopicTwo">
              <h1 class="topictwo">PAYMENT RECEIPT</h1>
           </div>
           <div class="mainTopicThree">
           <p class="paragraph">Received Rupees ${res.data.data[0].trans_amount}
             (${toWords(
               res.data.data[0].trans_amount
             )}) through payment gateway over the internet from ${
                                            res.data.data[0].fullname
                                          } towards the following</p>
           </div>
           <div class="mainss">
          
              <span class="mainthree">Batch Name</span>
              <span class="mainthree1">Actual Fee</span>
              <span class="mainthree2">Coupon Code Discount</span>
              <span class="mainthree3">Special Offer</span>
              <span class="mainthree4">Old Student Discount</span>
              <span class="mainthree5">Subtotal</span>
              <span class="mainthree6">GST</span>
               <span class="mainthree7">Total</span>
           
          </div>
          <div class="mainly">
              <span class="mainfour">${res.data.data[0].batch_name}</span>
              <span class="mainfour7">${res.data.data[0].actual_amt}</span>
              <span class="mainfour2">${res.data.data[0].coupon_amount}</span>
              <span class="mainfour3">${res.data.data[0].special_offer}</span>
              <span class="mainfour4">${res.data.data[0].old_stud_disc}</span>
              <span class="mainfour5">${
                res.data.data[0].actual_amt -
                res.data.data[0].coupon_amount -
                res.data.data[0].special_offer -
                res.data.data.old_stud_disc
              }</span>
              <span class="mainfour6">${res.data.data[0].gst_percent}</span>
              <span class="mainfour1">${res.data.data[0].trans_amount}</span>
             
             </div>
             <div>
             <h1 class="topicthree">(${toWords(
               res.data.data[0].trans_amount
             )})</h1>
             </div>
             <div class="mained">
          <div class="mainfive">${res.data.data[0].fullname}</div>
          <div class="mainfive">${res.data.data[0].address1}${
                                            res.data.data[0].landmark
                                          }</div>
          <div class="mainfive">${res.data.data[0].state_name}${
                                            res.data.data[0].district_name
                                          }</div>
          <div class="mainfive"> ${res.data.data[0].location} - ${
                                            res.data.data[0].postal_code
                                          }</div>
          <div class="mainfive1"> 
              ${res.data.data[0].email} </div>
          <div class="mainfive"> ${res.data.data[0].phone_no}</div>
            </div>
        
        <div class="finalone">
          <span class="final">If you have any payment related queries, please contact us 
              <a href="#">support@annaiasacademy.com</a>
          </span>
        </div >
        <div class="finalthree">
          <span class="finaltwo">48 , Vanigar Street, Kanchipuram,Tamil Nadu - 631501.</span>
        </div>
        
   </div>
  </body>
  </html>`;

                                          // const actual_amt =
                                          //   res.data.data[0].actual_amt;
                                          // const coupon_amt =
                                          //   res.data.data[0].coupon_amt;
                                          // const special_offer =
                                          //   res.data.data[0].special_offer;
                                          // const old_stud_disc =
                                          //   res.data.data[0].old_stud_disc;
                                          // const gst_percent =
                                          //   res.data.data[0].gst_percent;

                                          // const subtotal =
                                          //   actual_amt -
                                          //   coupon_amt -
                                          //   special_offer -
                                          //   old_stud_disc;
                                          // const gst_amt =
                                          //   (subtotal * gst_percent) / 100;
                                          // const tot_amt = subtotal + gst_amt;
                                          // const email_data = {
                                          //   first_name: list.FirstName,
                                          //   last_name: list.LastName,
                                          //   email: res.data.data[0].email,
                                          //   batch_name:
                                          //     res.data.data[0].batch_name,
                                          //   start_date: moment(
                                          //     res.data.data[0].batch_start_dt
                                          //   ).format("DD-MMM-YYYY"),
                                          //   time: res.data.data[0].batch_timing,
                                          //   duration: res.data.data[0].duration,
                                          //   class_mode:
                                          //     res.data.data[0].class_mode,
                                          //   location: res.data.data[0].location,
                                          //   amount_paid:
                                          //     res.data.data[0].trans_amount,
                                          //   amount_paid_words: toWords(
                                          //     res.data.data[0].trans_amount
                                          //   ),
                                          //   payment_method:
                                          //     res.data.data[0].method,
                                          //   transaction_id:
                                          //     res.data.data[0].trans_id,
                                          //   payment_time:
                                          //     res.data.data[0].transaction_date,
                                          //   actual_amt: actual_amt,
                                          //   coupon_amt: coupon_amt,
                                          //   special_offer: special_offer,
                                          //   old_stud_disc: old_stud_disc,
                                          //   subtotal: subtotal,
                                          //   gst_amt: gst_amt,
                                          //   tot_amt: tot_amt,
                                          //   parentage:
                                          //     list.gender === "Female"
                                          //       ? "D/O"
                                          //       : "S/O",
                                          //   father_name:
                                          //     res.data.data[0].father_name,
                                          //   address1: res.data.data[0].address1,
                                          //   address2: res.data.data[0].address2,
                                          //   address3: res.data.data[0].address3,
                                          //   city: res.data.data[0].city,
                                          //   pincode:
                                          //     res.data.data[0].postal_code,
                                          //   mob_no: res.data.data[0].phone_no,
                                          //   district:
                                          //     res.data.data[0].district_name,
                                          //   state: res.data.data[0].state_name,
                                          // };

                                          AxiosInstance.post(
                                            "api/all/sentPaymentInvoice",
                                            {
                                              user_id: resuserid,
                                              email: email,
                                              subject: "Payment Invoice",
                                              subject_rec: "Payment Receipt",
                                              html_text: html_text,
                                              html_text_rec: html_text_rec,
                                            }
                                          )
                                            .then((res) => {
                                              // console.log(res, "invoice");
                                              if (res.data[0].status === true) {
                                                setStep(step + 1);
                                              }
                                            })
                                            .catch((error) => {
                                              return error;
                                            });
                                        })
                                        .catch((error) => {
                                          return error;
                                        });
                                    }
                                  })
                                  .catch((error) => {
                                    return error;
                                  });
                              }
                            })
                            .catch((error) => {
                              return error;
                            });
                        }
                      })
                      .catch((error) => {
                        return error;
                      });
                  }
                },
              };
              const rzp1 = new window.Razorpay(options);
              rzp1.open();
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Warning",
              text: error,
              icon: "Warning",
              confirmButtonText: "Ok",
            });

            return error;
          });
      }
    } else {
      console.log("User Id cannot available in localstorge");
    }
  };

  // store localstorge

  function appendToStorage(name, data) {
    localStorage.setItem(name, data);
  }

  //handlebar function

  const handleblur = (event) => {
    const { name, value } = event.target;

    setList({
      ...list,
      [name]: value,
    });
  };

  //educational checkbox handlechange

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;
    if (checked) {
      setEducationQualification([...educationQualification, value]);
      setListErrors({
        ...listErrors,
        EducationQualification: "",
      });
    } else {
      setEducationQualification(
        educationQualification.filter((item) => item !== value)
      );
    }
  };

  const setMaxMonthYear = (value, names) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based

    // Format the current month and year as "yyyy-MM"
    const maxMonthYear = `${currentYear}-${currentMonth
      .toString()
      .padStart(2, "0")}`;

    // Parse the provided value into a JavaScript Date object
    const selectedDate = new Date(value);

    if (selectedDate > currentDate) {
      setListErrors({
        ...listErrors,
        [names]: "Year of passing should be in past",
      });
    } else {
      // Return the value because it's not in the future
      return value;
    }
  };

  // education cerficate handle change

  const handleEdu = (eduname, event) => {
    const names = event.target.name;
    const value = event.target.value;
    const files = event.target.files;

    if (names === "SSLC_Path" && files && files.length > 0) {
      const SSLC_Path = files[0];
      if (files[0].type == "application/pdf") {
        const formData = new FormData();
        formData.append("SSLC_Path", SSLC_Path);
        AxiosInstance.post("api/upload/", formData)
          .then((res) => {
            setSslcCerficates((prevState) => {
              return prevState.map((q) => {
                if (q.name === eduname) {
                  return {
                    ...q,
                    [names]: res.data.path,
                    original_file_path: res.data.originalname,
                  };
                }
                return q;
              });
            });
          })
          .catch((error) => {
            return error;
          });
      }
    } else if (names === "HSC_Path" && files && files.length > 0) {
      const HSC_Path = files[0];
      if (files[0].type == "application/pdf") {
        const formData = new FormData();
        formData.append("HSC_Path", HSC_Path);
        AxiosInstance.post("api/upload/", formData)
          .then((res) => {
            setSslcCerficates((prevState) => {
              return prevState.map((q) => {
                if (q.name === eduname) {
                  return {
                    ...q,
                    [names]: res.data.path,
                    original_file_path: res.data.originalname,
                  };
                }
                return q;
              });
            });
          })
          .catch((error) => {
            return error;
          });
      }
    } else if (names === "UG_Path" && files && files.length > 0) {
      const UG_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("UG_Path", UG_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            UG_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            UG_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          UG_Path: err,
        });
      }
    } else if (names === "PG_Path" && files && files.length > 0) {
      const PG_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("PG_Path", PG_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            PG_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            PG_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          PG_Path: err,
        });
      }
    } else if (names === "IP_Path" && files && files.length > 0) {
      const IP_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("IP_Path", IP_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            IP_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            IP_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          IP_Path: err,
        });
      }
    } else if (names === "PC_Path" && files && files.length > 0) {
      const PC_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("PC_Path", PC_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            PC_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            PC_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          PC_Path: err,
        });
      }
    } else if (names === "D_Path" && files && files.length > 0) {
      const D_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("D_Path", D_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            D_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            D_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          D_Path: err,
        });
      }
    } else if (names === "Ot_Path" && files && files.length > 0) {
      const Ot_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("Ot_Path", Ot_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            Ot_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            Ot_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          Ot_Path: err,
        });
      }
    } else if (names === "PG") {
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === eduname) {
            return { ...q, [names]: value };
          }
          return q;
        });
      });
      setListErrors({
        ...listErrors,
        [names]: "",
      });
    } else if (names === "SSLC_Marks") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "HSC_Marks") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "PGMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "UGMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "IPMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "DMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "PCMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "OtMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (
      names === "SSLC_Year" ||
      names === "HSC_Year" ||
      names === "UGYearpassing" ||
      names === "PGYearpassing" ||
      names === "IPYearpassing" ||
      names === "DYearpassing" ||
      names === "PCYearpassing" ||
      names === "OtYearpassing"
    ) {
      if (setMaxMonthYear(value, names)) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else {
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === eduname) {
            return { ...q, [names]: value };
          }
          return q;
        });
      });
      setListErrors({
        ...listErrors,
        [names]: "",
      });
    }
  };

  const handleSelectMulti = (option, name, dname) => {
    if (name === "CourseCate") {
      setList((prevState) => ({
        ...prevState,
        ["CourseCate"]: option.value,
        ["CouresName"]: option.course_name,
      }));
      setDropDown((prevState) => ({
        ...prevState,
        [dname]: option,
      }));
      setListErrors({
        ...listErrors,
        [dname]: "",
      });
    } else if (name === "AppCast") {
      if (option) {
        const selectedApp = option.map((zx) => ({
          label: zx.label,
          value: zx.value,
        }));
        const Hio = selectedApp.find((el) => el.label === "Nil");
        if (Hio) {
          setAppCategoryOption([]);
          const Hiso = selectedApp.filter((el) => el.label === "Nil");
          setAppCastLable(Hiso);
        } else {
          setAppCategoryOption(appcategoryOptionAll);
          setAppCastLable(selectedApp);
        }

        setListErrors({
          ...listErrors,
          [dname]: "",
        });
      } else {
        setAppCastLable([]);
      }
    } else if (name === "Cast") {
      setList((prevState) => ({
        ...prevState,
        ["Cast"]: option.value,
      }));
      setDropDown((prevState) => ({
        ...prevState,
        ["Cast"]: option,
      }));
      setListErrors({
        ...listErrors,
        ["Cast"]: "",
      });
    } else {
      setList((prevState) => ({
        ...prevState,
        [dname]: option.value,
      }));
      setDropDown((prevState) => ({
        ...prevState,
        [dname]: option,
      }));
      setListErrors({
        ...listErrors,
        [dname]: "",
      });
    }
  };
  const handleSelectCH = (option, dname) => {
    // console.log(option,dname);
    setList((prevState) => ({
      ...prevState,
      [dname]: option.value,
    }));

    setListErrors({
      ...listErrors,
      [dname]: "",
    });
  };
  //handlechange function

  const handleChange = (event) => {
    const { name, value, files, checked } = event.target;

    if (name === "DOB") {
      DobCovertAge(value);
      setList({
        ...list,
        [name]: value,
      });
    } else if (name === "Mobile") {
      if (value.length === 10 || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["Mobile"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["Mobile"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    } else if (name === "WhatsAppNumber") {
      if (value.length === 10 || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["WhatsAppNumber"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["WhatsAppNumber"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    } else if (name === "ParentMobileNo") {
      if (value.length === 10 || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["ParentMobileNo"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["ParentMobileNo"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    } else if (name == "aadharNo") {
      if (value.length === 12 || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["aadharNo"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["aadharNo"]: "Please Enter valid 12 digit number",
        });
        return;
      }
    } else if (name == "ContactNumber") {
      if (checked) {
        setList((prevList) => ({
          ...prevList,
          [name]: checked,
        }));
        setListErrors({
          ...listErrors,
          ["WhatsAppNumber"]: "",
        });
      } else {
        setList((prevList) => ({
          ...prevList,
          ["WhatsAppNumber"]: "",
          [name]: checked,
        }));
        setListErrors({
          ...listErrors,
          ["WhatsAppNumber"]: "",
        });
      }
    } else if (name == "SamePresentAddress") {
      setList((prevList) => ({
        ...prevList,
        [name]: checked,
      }));
    } else if (name === "Profilephoto" && files && files.length > 0) {
      const profile_path = files[0];
      if (
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 40 * 1024) {
          const formData = new FormData();
          formData.append("profile_path", profile_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                Profilephoto: res.data.path,
                original_profile_path: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                Profilephoto: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 40KB.";
          setListErrors({
            ...listErrors,
            Profilephoto: err,
          });
          setList({
            ...list,
            Profilephoto: "",
            original_profile_path: "",
          });
        }
      } else {
        let err = "(Png , jpg ,jpeg )images only allowed";
        setListErrors({
          ...listErrors,
          Profilephoto: err,
        });
      }
    }
    // New Added (signature)---------------
    else if (name === "signaturePhoto" && files && files.length > 0) {
      const sign_path = files[0];
      if (
        // files[0].type == "application/pdf" ||
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
        // files[0].type.startsWith("image/")
      ) {
        if (files[0].size < 30 * 1024) {
          const formData = new FormData();
          // Need TO Change (aadhar_path)
          formData.append("signature_file", sign_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                signaturePhoto: res.data.path,
                original_sign_path: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                signaturePhoto: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 30KB.";
          setListErrors({
            ...listErrors,
            signaturePhoto: err,
          });
          setList({
            ...list,
            signaturePhoto: "",
            original_sign_path: "",
          });
        }
      } else {
        let err = "PDF, PNG, JPG, and JPEG files only allowed";
        setListErrors({
          ...listErrors,
          signaturePhoto: err,
        });
      }
    } else if (name === "aadharCardphoto" && files && files.length > 0) {
      const aadhar_path = files[0];
      console.log("kumar", listErrors?.aadharCardphoto);
      if (
        files[0].type == "application/pdf" ||
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("aadhar_path", aadhar_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                aadharCardphoto: res.data.path,
                original_aadhar_path: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                aadharCardphoto: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            aadharCardphoto: err,
          });
          setList({
            ...list,
            aadharCardphoto: "",
            original_aadhar_path: "",
          });
        }
      } else {
        let err = "PDF, PNG, JPG, and JPEG files only allowed";
        setListErrors({
          ...listErrors,
          aadharCardphoto: err,
        });
      }
    } else if (name === "CastFile" && files && files.length > 0) {
      const community_path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("community_path", community_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                CastFile: res.data.path,
                original_caste_path: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                CastFile: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            CastFile: err,
          });
          setList({
            ...list,
            CastFile: "",
            original_caste_path: "",
          });
        }
      } else {
        let err = "pdf files only allowed";
        setListErrors({
          ...listErrors,
          CastFile: err,
        });
      }
    } else if (name === "category") {
      setList({
        ...list,
        [name]: value,
        ["CourseCate"]: "",
        ["CouresName"]: "",
        ["CourseSubCate"]: "",
      });

      setListErrors({
        ...listErrors,
        category: "",
      });
    } else if (name === "CourseCate") {
      // alert(value);
      const selectedOption = event.target.options[event.target.selectedIndex];
      const selectedOptionId = selectedOption.id;
      setList({
        ...list,
        [name]: value,
        CouresName: selectedOptionId,
      });
      setListErrors({
        ...listErrors,
        CourseCate: "",
      });
    } else if (name === "ExamDetailsRes") {
      const selectedOptions = Array.from(
        event.target.selectedOptions,
        (option) => option.value
      );
      setList((prevList) => ({ ...prevList, ExamDetailsRes: selectedOptions }));
      setListErrors({
        ...listErrors,
        ExamDetailsRes: "",
      });
    } else if (name === "IDPhoto" && files && files.length > 0) {
      const ID_photo_Path = files[0];
      if (
        files[0].type == "application/pdf" ||
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("ID_photo_Path", ID_photo_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                IDPhoto: res.data.path,
                original_aiasa_id_path: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                IDPhoto: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            IDPhoto: err,
          });
          setList({
            ...list,
            IDPhoto: "",
            original_aiasa_id_path: "",
          });
        }
      } else {
        let err = "PDF, PNG, JPG, and JPEG files only allowed";
        setListErrors({
          ...listErrors,
          IDPhoto: err,
        });
      }
    } else if (name == "Verify") {
      setList((prevList) => ({
        ...prevList,
        [name]: checked,
      }));
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else if (name == "CouponCode") {
      setCouponApplied((prevState) => ({
        ...prevState,
        coupon_code: value,
      }));
      if (value == "") {
        // alert("90");
        setCouponApplied({
          status: false,
          amount: 0,
          coupon_code: "",
        });
      }
      // setListErrors({
      //   ...listErrors,
      //   [name]: "",
      // });
    } else if (name === "hallExamPhoto" && files && files.length > 0) {
      const hallExamPhotos = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("hall_ticket", hallExamPhotos);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                hallExamPhoto: res.data.path,
                original_hallExamPhoto: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                hallExamPhoto: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            hallExamPhoto: err,
          });
          setList({
            ...list,
            hallExamPhoto: "",
            original_hallExamPhoto: "",
          });
        }
      } else {
        let err = "pdf files only allowed";
        setListErrors({
          ...listErrors,
          hallExamPhoto: err,
        });
      }
    } else if (name === "districts") {
      const selectedOptions = event.target.selectedOptions;
      const selectedOption = Array.from(selectedOptions).find(
        (option) => option.selected
      );
      const selectedTitle = selectedOption
        ? selectedOption.getAttribute("title")
        : "";
      console.log(selectedTitle, "selectedTitle");
      setList({
        ...list,
        ["districts"]: value,
        ["districts_name"]: selectedTitle,
      });
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else if (name === "PincodePresent") {
      setList({
        ...list,
        [name]: value,
        ["CityPresent"]: "",
      });
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else if (name === "PincodePermanent") {
      setList({
        ...list,
        [name]: value,
        ["CityPermanent"]: "",
      });
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else {
      setList({
        ...list,
        [name]: value,
      });
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    }
  };

  //handle Coupon code
  const handleCouponCode = (e, dAmount, id) => {
    let code = couponApplied.coupon_code;
    // let discountAmount = dAmount;
    // let batch_id = id;

    // sample code = AIASA_1000$

    AxiosInstance.get(`api/all/getCoupon/${code}`)
      .then((res) => {
        if (res.data[0].status === true) {
          setCouponApplied({
            status: true,
            amount: res.data[0].data.dis_amt,
            coupon_code: res.data[0].data.id,
          });
        } else {
          Swal.fire({
            title: "Warning",
            text: "coupon code is not avaliable ",
            icon: "Warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        return error;
      });

    // if (code) {
    //   discountedPrice -= 1000;
    //   const gstAmount = discountedPrice * gstRate;
    //   const finalPrice = discountedPrice + gstAmount;
    //   alert(finalPrice);
    // } else {
    //   alert("Please Enter Coupon Code");
    // }
  };

  //dropdown api call
  useEffect(() => {
    // get response from api to fill dropdowns

    AxiosInstance.get("api/codevalues?codetype=EDQ")
      .then((res) => {
        const educationOptions = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setEducationOptions(educationOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=THQ")
      .then((res) => {
        const technicalOption = res.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        // Sort the options alphabetically, ignoring "Nil"
        const sortedOptions = technicalOption
          .filter((item) => item.value !== "Nil")
          .sort((a, b) => a.value.localeCompare(b.value));
        const NilOption = technicalOption
          .filter((item) => item.value === "Nil")
          .sort((a, b) => a.value.localeCompare(b.value));
        // Set the sorted list to the state
        sortedOptions.push(...NilOption);
        setTechnicalOption(sortedOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=CTGY")
      .then((res) => {
        const categoryOption = res.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        console.log(categoryOption, "categoryOption");
        // Sort the options alphabetically, ignoring "Nil"
        const sortedOptions = categoryOption
          .filter((item) => item.value !== "Nil")
          .sort((a, b) => a.value.localeCompare(b.value));
        const NilOption = categoryOption
          .filter((item) => item.value === "Nil")
          .sort((a, b) => a.value.localeCompare(b.value));
        // Set the sorted list to the state
        sortedOptions.push(...NilOption);
        setCategoryOption(sortedOptions);
      })
      .catch((error) => {
        return error;
      });

    // New Added
    AxiosInstance.get("api/codevalues?type=4")
      .then((res) => {
        const appcategoryOption = res.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));

        console.log(appcategoryOption, "appcategoryOption");
        // Sort the options alphabetically, ignoring "Nil"
        const sortedOptions = appcategoryOption
          .filter((item) => item.value !== "Nil")
          .sort((a, b) => a.value.localeCompare(b.value));
        const NilOption = appcategoryOption
          .filter((item) => item.value === "Nil")
          .sort((a, b) => a.value.localeCompare(b.value));
        // Set the sorted list to the state
        sortedOptions.push(...NilOption);
        setAppCategoryOption(sortedOptions);
        // setAppCategoryOptionAll(sortedOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=CURS")
      .then((res) => {
        const cOption = res.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setCOption(cOption);
        setCatOption(res.data);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=MDP")
      .then((res) => {
        const teachMedium = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setTeachMedium(teachMedium);
      })
      .catch((error) => {
        return error;
      });
    // AxiosInstance.get("api/all/district/31")
    //   .then((res) => {
    //     const district = res.data.map((item) => (
    //       <option key={item.name} value={item.name}>
    //         {item.name}
    //       </option>
    //     ));
    //     setDistrict(district);
    //   })
    //   .catch((error) => {
    //     return error;
    //   });
  }, []);

  useEffect(() => {
    if (list.SPCcategory !== "") {
      let ValueAsp = "";
      if (list.SPCcategory === "UPSC") {
        ValueAsp = "UPSC_ASP";
      } else if (list.SPCcategory === "TNPSC") {
        ValueAsp = "TNPSC_ASP";
      } else if (list.SPCcategory === "TRB") {
        ValueAsp = "TRB_ASP";
      } else if (list.SPCcategory === "TET") {
        ValueAsp = "TET_ASP";
      } else if (list.SPCcategory === "TNUSRB") {
        ValueAsp = "TNUSRB_ASP";
      } else if (list.SPCcategory === "SSC") {
        ValueAsp = "SSC_ASP";
      } else if (list.SPCcategory === "Banking") {
        ValueAsp = "Banking_ASP";
      } else if (list.SPCcategory === "Others") {
        ValueAsp = "OTHERS_ASP";
      } else if (list.SPCcategory === "RRB") {
        ValueAsp = "RRB_ASP";
      }

      AxiosInstance.get(`api/codevalues?codetype=${ValueAsp}`)
        .then((res) => {
          const specialPrefrence = res.data.map((item, index) => ({
            value: item.name,
            label: item.name,
          }));
          // Sort the options alphabetically, ignoring "Nil"
          const sortedOptions = specialPrefrence
            .filter((item) => item.value !== "Nil")
            .sort((a, b) => a.value.localeCompare(b.value));
          const NilOption = specialPrefrence
            .filter((item) => item.value === "Nil")
            .sort((a, b) => a.value.localeCompare(b.value));
          // Set the sorted list to the state
          sortedOptions.push(...NilOption);
          setSpecialPrefrence(sortedOptions);
        })
        .catch((error) => {
          return error;
        });
      // setList({
      //   ...list,
      //   ["category"]: list.SPCcategory,
      // });
    }
  }, [list.SPCcategory]);

  const handleStepPrevious = (count) => {
    setStep(count);
  };
  // AIASA-000128 v1 - issues update code by lalitha
  const validateReplyComment = (data) => {
    let error = {};
    if (!data.reply_student) {
      error.reply_student = "Please enter any comment";
      txtAdReply_student.current.focus();
    }
    return error;
  };

  const validateThq = (techQualification, setTechQualification) => {
    if (techQualification && techQualification.length > 0) {
      const updateTHQ = techQualification.map((OB) => {
        let updatedOB = { ...OB };
        // console.log(techQualification, "techQualification");

        if (!OB.TQ) {
          updatedOB.error_tq = "Choose Yes or No";
        }
        if (!OB.name && OB.TQ === "0") {
          updatedOB.error_name = "Name is required";
        }
        if (OB.name === "Nil" && OB.TQ === "0") {
          updatedOB.error_name = "";
          updatedOB.error_level = "";
          updatedOB.error_lang = "";
        }
        if (!OB.level && OB.name !== "Nil" && OB.TQ === "0") {
          updatedOB.error_level = "Level is required";
        }

        if (!OB.lang && OB.name !== "Nil" && OB.TQ === "0") {
          updatedOB.error_lang = "Lang is required";
        }
        return updatedOB;
      });
      setTechQualification(updateTHQ);
      return updateTHQ;
    }
  };

  const handleNexts = () => {
    const userJSON = localStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;
        const role_id = user.role_id;
        const user_type = user.user_type;
        const errors = validateReplyComment(list);
        // console.log(errors , batchDetails);
        if (Object.keys(errors).length === 0) {
          if (list.Verify === true) {
            // setStep(step + 1);
            let bb = {
              user_id: user_id,
              admission_id: admission_id,
              type: 4, //always
              admission_status: list.admission_status,
              batch_id: batchId,
              comment: list.reply_student,
              user_type: user_type === 1 ? 0 : 1,
              read_status: 1, // send always 1
              created_by: userid,
              updated_by: userid,
            };
            // console.log(bb);
            AxiosInstance.post("api/course/admission/chat/comm", bb)
              .then((res) => {
                if (res.data.status === true) {
                  Swal.fire({
                    title: "Success",
                    text: res.data.message,
                    icon: "Success",
                    confirmButtonText: "Ok",
                  });

                  navigate("/TransactionHistory");
                } else {
                  Swal.fire({
                    title: "Warning",
                    text: res.data.message,
                    icon: "Warning",
                    confirmButtonText: "Ok",
                  });
                }
              })
              .catch((error) => {
                return error;
              });
          } else {
            let err = "Please look and verify the Terms and Conditions.";
            txtadVerifyHere.current.focus();
            setListErrors({
              ...listErrors,
              Verify: err,
            });
          }
        } else {
          setListErrors(errors);
        }
      }
    } else {
      console.log("User Id cannot available in localstorge");
    }
  };

  const AddQualification = () => {
    setTechQualification((prevState) => [
      ...prevState,
      {
        id: prevState.length > 0 ? prevState.length + 1 : 1,
        TQ: "",
        name: "",
        level: "",
        lang: "",
        error_tq: "",
        error_name: "",
        error_lang: "",
        error_level: "",
      },
    ]);
  };
  //technical qualification delete function
  const DeleteQual = (id, name) => {
    const userJSON = localStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;
        AxiosInstance.post("api/course/admission/delete/qualifications", {
          user_id: userid,
          name: name,
          qualification_type: "THQ",
          id: id,
        })
          .then((res) => {
            if (res.status === 200) {
              const val = techQualification
                .filter((item) => item.id !== id)
                .map((item, index) => ({ ...item, id: index + 1 }));
              setTechQualification(val);
            }
          })
          .catch((error) => {
            return error;
          });
      }
    } else {
      console.log("User Id cannot avaliable in localstorge");
    }
  };
  const TechhandleChange = (e, id, danme) => {
    const { name, value, files, checked } = e.target;
    if (name === "level") {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value, ["error_level"]: "" };
          }
          return q;
        });
      });
    } else if (name === "lang") {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value, ["error_lang"]: "" };
          }
          return q;
        });
      });
      // setTechQualification((prevState) => {
      //   // Check for potential duplicate "lang" or "name" entries
      //   const existingNames = prevState.map((q) => q.name);
      //   const existingLangs = prevState.map((q) => q.lang);
      //   const hasDuplicateName = name === "name" && existingNames.includes(value);
      //   const hasDuplicateLang = name === "lang" && existingLangs.includes(value);

      //   return prevState.map((q) => {
      //     if (q.id === id) {
      //       const newEntry = { ...q, [name]: value };
      //       // Set errors based on duplicate checks
      //       if (hasDuplicateName) {
      //         newEntry.error_name = "This name already exists.";
      //         newEntry.name = "";
      //       } else {
      //         newEntry.error_name = "";
      //       }

      //       if (hasDuplicateLang) {
      //         newEntry.error_lang = "This language already exists.";
      //         newEntry.lang = "";
      //       } else {
      //         newEntry.error_lang = "";
      //       }

      //       return newEntry;
      //     }
      //     return q;
      //   });
      // });
    } else if (danme === "TQ") {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, ["TQ"]: value, ["error_tq"]: "" };
          }
          return q;
        });
      });
    } else {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value };
          }
          return q;
        });
      });
    }
  };
  const TechhandleChanges = (option, id) => {
    setTechQualification((prevState) => {
      const isNameExists = prevState.some(
        (q) => q.id !== id && q.name === option.value
      );
      if (isNameExists) {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, ["name"]: "", ["error_name"]: "Name Already exist" };
          }
          return q;
        });
      } else {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, ["name"]: option.value, ["error_name"]: "" };
          }
          return q;
        });
      }
    });
  };
  //step handle prev button click
  const handlePrevious = () => {
    setStep(step - 1);
  };
  // const handleNexts = () => {
  //   setStep(step + 1);
  // };

  //step handle next button click
  const handleNext = () => {
    // console.log(batchDetails, "after update ");
    const userJSON = localStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;

        const AdmissionRef_validation = {
          firstName: txtAdFname,
          lastName: txtAdLname,
          age: txtAdAge,
          mdob: txtAdMDob,
          ano: txtAdAno, //New
          apath: txtAdApath, //New
          signpath: txtSignpath,
          father: txtAdFath, // New Added
          mStatus: txtAdMStatus, // New Added
          cno: txtAdCno, //New Added
          phno: txtAdPhno, //New Added (Present Address)
          psname: txtAdPsname, //New Added
          parea: txtAdParea, //New Added
          pland: txtAdPland, //New Added
          ppost: txtAdPpost, //New Added
          ptaluk: txtAdPtaluk, //New Added
          ppincode: txtAdPpincode, //New Added
          pcity: txtAdPcity, //New Added
          pstate: txtAdPstate, //New Added
          pdistrict: txtAdPdistrict, //New Added
          classMode: txtAdcMode, //New Added
          perhno: txtAdPerhno, //New Added (Permanent Address )
          persname: txtAdPersname, //New Added
          perarea: txtAdPerarea, //New Added
          perland: txtAdPerland, //New Added
          pererpost: txtAdPererpost, //New Added
          pertaluk: txtAdPertaluk, //New Added
          perpincode: txtAdPerpincode, //New Added
          percity: txtAdPercity, //New Added
          perstate: txtAdPerstate, //New Added
          perdistrict: txtAdPerdistrict, //New Added
          techcourse: txtTechCourse, //New Added
          medium: txtAdMedium, //New Added
          gender: txtAdGender,
          pSize: txtAdPSize,
          pno: txtAdPno,
          wno: txtAdWno,
          fpo: txtAdFpo,
          email: txtAdEmail,
          category: txtAdCate,
          AppCast: txtAdACno,
          course: txtAdCourse,
          district: txtAdDistrict,
          examRegno: txtAdExamReg.current,
          batchDetails: txtbatchDet,
          aiasayear: txtAdayear,
          aiasacate: txtAdacate,
          aiasacourse: txtAdacourse,
          aiasaIdnum: txtAdaIdnum,
          aiasafile: txtAdafile,
          hallExamPhoto: txthallExamPhoto,
        };

        validateThq(techQualification, setTechQualification);

        const errors_thq_quali =
          techQualification.length > 0 &&
          techQualification.every(
            (detail) =>
              detail.TQ === "1" ||
              (detail.TQ === "0" &&
                detail.name !== "" &&
                (detail.lang !== "" || detail.name === "Others") &&
                detail.level !== "")
          );
        const errors = validateFields(
          list,
          sslcCerficates,
          educationQualification,
          step,
          AdmissionRef_validation,
          batchDetails,
          techQualification,
          appCastLable
        );
        if (
          Object.keys(errors).length === 0 &&
          ((errors_thq_quali && step === 1) || step !== 1)
        ) {
          if (step === 1) {
            const TQQ = techQualification.map((item) => ({
              id: item.id,
              name: item.name,
              level: item.level,
              lang: item.lang,
            }));
            const SteponeData = {
              step: 1,
              type: 4,
              id: admissionID.adId ? admissionID.adId : 0,
              user_id: userid,
              first_name: list.FirstName,
              last_name: list.LastName,
              father_name: list.Fathername,
              dob: list.DOB,
              age: list.age,
              gender: list.gender,
              scp:
                appCastLable.length > 0
                  ? appCastLable.map((el) => el.label)
                  : [],
              marital_status: list.MaritalStatus,
              profile_path: list.Profilephoto,
              original_profile_path: list.original_profile_path,
              aadhar_no: list.aadharNo,
              aadhar_path: list.aadharCardphoto,
              original_aadhar_path: list.original_aadhar_path,
              community_name: list.Cast,
              specimen_signature: list.signaturePhoto,
              original_specimen_signature: list.original_sign_path,
              tech_qualification: TQQ,
              created_by: userid,
            };
            // console.log(SteponeData);
            AxiosInstance.post("api/course/admission/register", SteponeData)
              .then((res) => {
                if (res.data.status === true) {
                  setStep(step + 1);
                  sessionStorage.removeItem("stateRefreshs_model");
                  // setSpecialAdmissionPre()
                } else {
                  Swal.fire({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    confirmButtonText: "Ok",
                  });
                  // setSwalMsg({
                  //   show: true,
                  //   status: "Warning",
                  //   message: res.data.message,
                  //   title: "Warning",
                  //   BottomOkClose: "Ok",
                  // });
                }
              })
              .catch((error) => {
                return error;
              });
          } else if (step === 2) {
            const address = [
              {
                address1: list.BuildingNumberPresent,
                address2: list.StreetNamePresent,
                address3: list.AreaPresent,
                landmark: list.LandmarkPresent,
                post_name: list.PostPresent,
                taluk: list.TalukPresent,
                postal_code: list.PincodePresent,
                city: list.CityPresent,
                district_name: list.districtPresent,
                state_name: list.statePresent,
                same_address: list.SamePresentAddress == true ? "0" : "1",
                address_type: "1",
              },
              {
                address1:
                  list.SamePresentAddress == true
                    ? list.BuildingNumberPresent
                    : list.BuildingNumberPermanent,
                address2:
                  list.SamePresentAddress == true
                    ? list.StreetNamePresent
                    : list.StreetNamePermanent,
                address3:
                  list.SamePresentAddress == true
                    ? list.AreaPresent
                    : list.AreaPermanent,
                landmark:
                  list.SamePresentAddress == true
                    ? list.LandmarkPresent
                    : list.LandmarkPermanent,
                post_name:
                  list.SamePresentAddress == true
                    ? list.PostPresent
                    : list.PostPermanent,
                taluk:
                  list.SamePresentAddress == true
                    ? list.TalukPresent
                    : list.TalukPermanent,
                postal_code:
                  list.SamePresentAddress == true
                    ? list.PincodePresent
                    : list.PincodePermanent,
                city:
                  list.SamePresentAddress == true
                    ? list.CityPresent
                    : list.CityPermanent,
                district_name:
                  list.SamePresentAddress == true
                    ? list.districtPresent
                    : list.districtPermanent,
                state_name:
                  list.SamePresentAddress == true
                    ? list.statePresent
                    : list.statePermanent,
                same_address: list.SamePresentAddress == true ? "0" : "1",
                address_type: "2",
              },
            ];

            // console.log(address,"list.address",step);
            const StepTwoData = {
              user_id: userid,
              step: 2,
              phone_no: list.Mobile,
              country_code: list.MobileCode,
              whatsapp_no:
                list.ContactNumber === true ? list.Mobile : list.WhatsAppNumber,
              contact_type: list.ContactNumber === true ? "1" : "0",
              parent_mob_no: list.ParentMobileNo,
              email: list.emailaddress,
              address: address,
            };

            // console.log(StepTwoData);
            AxiosInstance.post("api/course/admission/register", StepTwoData)
              .then((res) => {
                if (res.data.status === true) {
                  setStep(step + 1);
                  // console.log(specialAdmissionPre, "specialAdmissionPre");
                  sessionStorage.removeItem("stateRefreshs");
                } else {
                  Swal.fire({
                    title: "Warning",
                    text: res.data.message,
                    icon: "Warning",
                    confirmButtonText: "Ok",
                  });
                }
              })
              .catch((error) => {
                return error;
              });
          } else if (step === 3) {
            const BatchDetails = batchDetails.filter((itm) => {
              return itm.is_selected === true;
            });
            const StepTwoData = {
              user_id: userid,
              step: 4,
              type: 4,
              location: 2,
              id: admissionID.adId ? admissionID.adId : 0,
              Category: list.category,
              Courses: parseInt(list.CourseCate),
              BatchDetails: BatchDetails.length > 0 ? BatchDetails[0] : null,
              Exam: 0,
              PrevExam: 0,
              GoveEmp: 0,
              hall_ticket: list.hallticket === "Yes" ? 1 : 0,
              exam_reg_no: list.hallticket === "Yes" ? list.ExamRegNo : "",
              hall_ticketpath:
                list.hallticket === "Yes" ? list.hallExamPhoto : "",
              original_hall_ticketpath:
                list.hallticket === "Yes" ? list.original_hallExamPhoto : "",
              AIASAStudent: list.AIASAStudent === "Yes" ? 1 : 0,
              Year: list.AIASAStudent === "Yes" ? list.Year : null,
              Aiasa_category:
                list.AIASAStudent === "Yes" ? list.CategoryAias : null,
              Aiasa_course:
                list.AIASAStudent === "Yes" ? list.CourseAias : null,
              IDNumber: list.AIASAStudent === "Yes" ? list.IDNumber : null,
              original_aiasa_id_path:
                list.AIASAStudent === "Yes"
                  ? list.original_aiasa_id_path
                  : null,
              IdCardPath: list.AIASAStudent === "Yes" ? list.IDPhoto : null,
              district: list.districts !== "" ? list.districts : null,
              updated_by: userid,
            };

            AxiosInstance.post("api/course/admission/register", StepTwoData)
              .then((res) => {
                // console.log(res, "smdksajhfkjg");
                if (res.data.status === true) {
                  if (res.data.data) {
                    setAdmissionID({
                      adId: res.data.data,
                    });
                  }
                  setStep(step + 1);
                  sessionStorage.removeItem("stateRefreshs_model");
                } else {
                  Swal.fire({
                    title: "Warning",
                    text: res.data.message,
                    icon: "Warning",
                    confirmButtonText: "Ok",
                  });
                }
              })
              .catch((error) => {
                return error;
              });
          } else if (step === 4) {
            if (list.Verify === true) {
              setStep(step + 1);
            } else {
              let err = "Please look and verify the Terms and Conditions.";
              txtadVerifyHere.current.focus();
              setListErrors({
                ...listErrors,
                Verify: err,
              });
            }
          }
        } else {
          setListErrors(errors);
        }
      }
    } else {
      console.log("User Id cannot available in localstorge");
    }
  };

  const handleCloseModels_Succ = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleCloseModel = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  console.log(list.category, "category");
  //  front end
  return (
    <div className="AdmissionForm_section ModelExamForm">
      <h4 className="Title">Model Exam Admission</h4>

      {swalMsg.show === true && (
        <ModelComForP
          show={swalMsg.show}
          message={swalMsg.message}
          status={swalMsg.status}
          title={swalMsg.title}
          BottomOkBtn={swalMsg.BottomOkBtn}
          BottomCancelBtn={swalMsg.BottomCancelBtn}
          handleCloseModels={handleCloseModel}
          // handleClose_yes={handleSPayfee}
          BottomOkClose={swalMsg.BottomOkClose}
          handleCloseModels_Succ={handleCloseModels_Succ}
        />
      )}

      {/* progressTitle */}

      <form>
        <div className="row progressTitle">
          <div className={`col-md-2 col-2 col-sm-2 col_progress `}>
            <div
              className={`${
                step === 1 ||
                step === 2 ||
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6
                  ? "Card"
                  : "  Cards"
              }`}
            >
              <p
                className="circle"
                onClick={
                  step === 2 ||
                  step === 3 ||
                  step === 4 ||
                  step === 5 ||
                  step === 6
                    ? () => handleStepPrevious(1) // Wrap the call in an arrow function
                    : handleNext
                }
              >
                {step === 2 ||
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6
                  ? "✓"
                  : "1"}
              </p>
              <p className="cTitle fw-semibold">Personal Details</p>
              <p className="cSubtitle">Define Your Identity</p>
              <span
                className={`${
                  step === 2 || step === 3 || step === 4 || step === 5
                    ? "Stepborders"
                    : "Stepborder"
                } display_none_Stepborder`}
              ></span>
            </div>
          </div>
          <div className="col-md-1 col-1 col-sm-1"></div>
          <div className={`col-md-2 col-2 col-sm-2 col_progress `}>
            <div
              className={`${
                step === 2 ||
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6
                  ? "Card"
                  : "  Cards"
              }`}
            >
              <p
                className="circle"
                onClick={
                  step === 3 || step === 4 || step === 5 || step === 6
                    ? () => handleStepPrevious(2) // Wrap the call in an arrow function
                    : handleNext
                }
              >
                {step === 3 || step === 4 || step === 5 || step === 6
                  ? "✓"
                  : "2"}
              </p>
              <p className="cTitle ">Communication</p>
              <p className="cSubtitle ">Stay Connected Always</p>
              <span
                className={`${
                  step === 3 || step === 4 || step === 5 || step === 6
                    ? "Stepborders"
                    : "Stepborder"
                } display_none_Stepborder`}
              ></span>
            </div>
          </div>

          <div className="col-md-1 col-1 col-sm-1"></div>
          <div className={`col-md-2 col-2 col-sm-2 col_progress`}>
            <div
              className={`${
                step === 3 || step === 4 || step === 5 || step === 6
                  ? "Card"
                  : "  Cards"
              }`}
            >
              <p
                className="circle"
                onClick={
                  step === 4 || step === 5 || step === 6
                    ? () => handleStepPrevious(3) // Wrap the call in an arrow function
                    : handleNext
                }
              >
                {step === 4 || step === 5 || step === 6 ? "✓" : "3"}
              </p>
              <p className="cTitle ">Model Exam Details</p>
              <p className="cSubtitle ">Choose Your Path</p>
              <span
                className={`${
                  step === 4 || step === 5 || step === 6
                    ? "Stepborders"
                    : "Stepborder"
                } display_none_Stepborder`}
              ></span>
            </div>
          </div>

          <div className={`col-md-2 col-2 col-sm-2 col_end_progress `}>
            <div
              className={`${
                step === 4 || step === 5 || step === 6 ? "Card" : "  Cards"
              }`}
            >
              <p
                className="circle"
                onClick={
                  step === 5 || step === 6
                    ? () => handleStepPrevious(4) // Wrap the call in an arrow function
                    : handleNext
                }
              >
                {step === 5 || step === 6 ? "✓" : "4"}
              </p>
              <p className="cTitle ">Review and Confirm</p>
              <p className="cSubtitle ">Review Your Details</p>
              {/* <span
                className={`${
                  step === 5 || step === 6 ? "Stepborders" : "Stepborder"
                } display_none_Stepborder`}
              ></span> */}
            </div>
          </div>
        </div>
        {/* {step === 1} */}
        {step === 1 && (
          <fieldset>
            <div className="row">
              <div className="col-md-12">
                <section className=" StepOne ">
                  <div className="heading  mt-3 d-flex justify-content-end ">
                    {/* <Link to="/" className="CancelButton">
                            Back to Home
                          </Link> */}

                    <button
                      type="button"
                      className="SaveButtons"
                      onClick={handleNext}
                    >
                      <a href="javascript:void(0)">
                        Save and Continue to Communication
                      </a>
                    </button>
                  </div>
                  {/* ----------Updated -------*/}

                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">{LBL_BASIC_DETAILS}</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="FirstName">First Name</label>
                            <input
                              ref={txtAdFname}
                              id="FirstName"
                              type="text"
                              name="FirstName"
                              className="Inputs"
                              onChange={handleChange}
                              placeholder="First Name"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={list.FirstName}
                              autoComplete="FirstName"
                              minLength={5}
                              maxLength={15}
                              required
                            />
                            {listErrors.FirstName && (
                              <p className="errors">{listErrors.FirstName}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="LastName">Last Name</label>
                            <input
                              ref={txtAdLname}
                              id="LastName"
                              type="text"
                              name="LastName"
                              placeholder=" Last Name"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              minLength={5}
                              maxLength={15}
                              onChange={handleChange}
                              value={list.LastName}
                              autoComplete="LastName"
                              required
                            />
                            {listErrors.LastName && (
                              <p className="errors">{listErrors.LastName}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* New Added */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="Fathername">
                              Father's Name/Guardian's Name
                            </label>
                            <input
                              ref={txtAdFath}
                              id="Fathername"
                              type="text"
                              name="Fathername"
                              placeholder="Father's Name/Guardian's Name"
                              onChange={handleChange}
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={list.Fathername}
                              className="Inputs"
                              minLength={5}
                              maxLength={15}
                              autoComplete="Fathername"
                              required
                            />
                            {listErrors.Fathername && (
                              <p className="errors">{listErrors.Fathername}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms row  ">
                            <label htmlFor="Gender">Gender</label>
                            <div
                              className="d-flex  col-md-12"
                              id="radiobuttons"
                            >
                              <div className="form-check form-check-inline">
                                <input
                                  ref={txtAdGender}
                                  className="checkbox_radio"
                                  type="radio"
                                  name="gender"
                                  onChange={handleChange}
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  checked={
                                    list.gender === "Male" ? true : false
                                  }
                                  id="Male"
                                  value="Male"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Male"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="gender"
                                  id="Female"
                                  onChange={handleChange}
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  checked={
                                    list.gender === "Female" ? true : false
                                  }
                                  value="Female"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Female"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  name="gender"
                                  id="Transgender"
                                  onChange={handleChange}
                                  checked={
                                    list.gender === "Transgender" ? true : false
                                  }
                                  value="Transgender"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Transgender"
                                >
                                  Transgender
                                </label>
                              </div>
                            </div>
                          </div>
                          {listErrors.gender && (
                            <small className="errors fw-semibold">
                              {listErrors.gender}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="DOB">Date of Birth</label>
                            {/* <input
                                  ref={txtAdMDob}
                                  id="DOB"
                                  type="date"
                                  name="DOB"
                                  onChange={handleChange}
                                  value={list.DOB}
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  className="Inputs"
                                  autoComplete="DOB"
                                  required
                                /> */}
                            <DatePickerSample
                              disabledProperty={
                                list.admission_status === 1 ? true : false
                              }
                              txtAdMDob={txtAdMDob}
                              name={"DOB"}
                              handleChange={handleChangeDate}
                              fieldInput={list.DOB}
                            />
                            {listErrors.DOB && (
                              <p className="errors">{listErrors.DOB}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="age">Age</label>
                            <input
                              ref={txtAdAge}
                              id="age"
                              type="text"
                              readOnly
                              name="age"
                              onChange={handleChange}
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={list.age}
                              className="Inputs"
                              placeholder="Age"
                              autoComplete="age"
                              required
                            />
                            {listErrors.age && (
                              <p className="errors">{listErrors.age}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-md-6">
                          <div className="forms row  ">
                            <label htmlFor="MaritalStatus">
                              Marital Status
                            </label>
                            <div
                              className="d-flex ms-4  col-md-12"
                              id="radiobuttons"
                            >
                              <div className="form-checks form-check-inline">
                                <input
                                  ref={txtAdMStatus}
                                  className="checkbox_radio"
                                  type="radio"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  name="MaritalStatus"
                                  onChange={handleChange}
                                  checked={
                                    list.MaritalStatus === "Single/Unmarried"
                                      ? true
                                      : false
                                  }
                                  id="Single/Unmarried"
                                  value="Single/Unmarried"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Single/Unmarried"
                                >
                                  Single/Unmarried
                                </label>
                              </div>
                              <div className="form-checks form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  name="MaritalStatus"
                                  id="Married"
                                  onChange={handleChange}
                                  checked={
                                    list.MaritalStatus === "Married"
                                      ? true
                                      : false
                                  }
                                  value="Married"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Married"
                                >
                                  Married
                                </label>
                              </div>

                              <div className="form-checks form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="MaritalStatus"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  id="Widowed"
                                  onChange={handleChange}
                                  checked={
                                    list.MaritalStatus === "Widowed"
                                      ? true
                                      : false
                                  }
                                  value="Widowed"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Widowed"
                                >
                                  Widowed
                                </label>
                              </div>
                            </div>
                          </div>
                          {listErrors.MaritalStatus && (
                            <small className="errors fw-semibold">
                              {listErrors.MaritalStatus}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className=" sectionTwo ">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Passport Size Photograph</p>
                      <div className="row">
                        <div className="col-md-6 d-flex  justify-content-center">
                          <div className="forms">
                            <img
                              className="ProfilePhoto  bg-opacity-75"
                              src={`${ImageUrlPrefix}/${list.Profilephoto}`}
                              alt="profile"
                            />

                            {!list.Profilephoto && (
                              <span id="text">Profile Picture</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="Profilephoto">
                              Upload Passport Size Photograph
                            </label>
                            <input
                              ref={txtAdPSize}
                              id="Profilephoto"
                              type="file"
                              name="Profilephoto"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={handleChange}
                              className="InputsFile"
                              autoComplete="Profilephoto"
                              required
                            />
                            {listErrors.Profilephoto && (
                              <small className="errors fw-semibold">
                                {listErrors.Profilephoto}
                              </small>
                            )}
                          </div>

                          <div className="forms mt-4 ms-md-3 mb-4">
                            <h6 className="profileText">Note:</h6>
                            <p className="warningOne my-2">
                              Image format: The recommended image format for
                              profile pictures on Anna IAS Academy is JPEG or
                              PNG.
                            </p>
                            <h6 className="warningTwo">
                              <span className="fw-semibold">Image size</span>:
                              Profile pictures should be a minimum of 400x400
                              pixels and a maximum of 1024x1024 pixels. The file
                              size should not exceed 2 MB.
                            </h6>
                            <h6 className="warningTwo">
                              <span className="fw-semibold">
                                Image quality and content
                              </span>
                              : Choose a clear and high-quality image for your
                              profile picture. Your profile picture should be
                              appropriate and professional.
                            </h6>
                            <h6 className="warningTwo">
                              <span className="fw-semibold">
                                Image orientation
                              </span>
                              :Profile pictures should be in a square format, as
                              they will be displayed as a circle on the
                              platform. Make sure your image is not stretched or
                              distorted.
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* New Added ----------(Specimen Signature) */}
                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Specimen Signature</p>
                      <div className="row mb-3">
                        <div className="forms ">
                          <label htmlFor="signaturePhoto">
                            Upload Specimen Signature
                            {list.signaturePhoto !== "" &&
                              list.signaturePhoto !== null && (
                                <a
                                  href={`${ImageUrlPrefix}/${list.signaturePhoto}`}
                                  target="_blank"
                                  download
                                >
                                  <span className="material-icons align-middle download_icon">
                                    file_download
                                  </span>
                                </a>
                              )}
                          </label>
                          <div className="row">
                            <div
                              className={
                                list.signaturePhoto !== "" &&
                                list.signaturePhoto !== null
                                  ? "col-md-3 "
                                  : "col-md-12"
                              }
                            >
                              <input
                                ref={txtSignpath}
                                id="signaturePhoto"
                                type="file"
                                name="signaturePhoto"
                                placeholder="No File Choose"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className={
                                  list.signaturePhoto !== "" &&
                                  list.signaturePhoto !== null
                                    ? "InputsFile nn_upload_width"
                                    : "InputsFile  vv_upload_width"
                                }
                                onChange={handleChange}
                                autoComplete="signaturePhoto"
                                required
                              />
                            </div>
                            {list.signaturePhoto !== "" &&
                              list.signaturePhoto !== null && (
                                <div
                                  className={
                                    "col-md-9 d-flex align-content-center p-0"
                                  }
                                >
                                  <span className="nn_upload_file">
                                    {list.signaturePhoto !== "" &&
                                    list.signaturePhoto !== null
                                      ? list.original_sign_path
                                      : ""}
                                  </span>
                                  <span className="material-icons align-middle text-danger verified_upload">
                                    verified
                                  </span>
                                </div>
                              )}
                          </div>
                          {listErrors.signaturePhoto && (
                            <p className="errors">
                              {listErrors.signaturePhoto}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* New Added----------( ID Proofs) */}

                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">ID Proofs</p>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="aadharNo">
                              AADHAR Number
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            <input
                              ref={txtAdAno}
                              id="aadharNo"
                              type="number"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="aadharNo"
                              onChange={handleChange}
                              value={list.aadharNo < 0 ? 0 : list.aadharNo}
                              className="Inputs"
                              placeholder="Aadhar Number"
                              autoComplete="aadharNo"
                              required
                              minLength={12}
                            />
                            {listErrors.aadharNo && (
                              <p className="errors">{listErrors.aadharNo}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="forms ">
                            <label htmlFor="aadharCardphoto">
                              Upload AADHAR Card
                              {list.aadharCardphoto !== "" &&
                                list.aadharCardphoto !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${list.aadharCardphoto}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                            <div className="row">
                              <div
                                className={
                                  list.aadharCardphoto !== "" &&
                                  list.aadharCardphoto !== null
                                    ? "col-md-3 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  // ref={txtAdApath}
                                  id="aadharCardphoto"
                                  type="file"
                                  name="aadharCardphoto"
                                  placeholder="Upload AADHAR Card"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  className={
                                    list.aadharCardphoto !== "" &&
                                    list.aadharCardphoto !== null
                                      ? "InputsFile nn_upload_width"
                                      : "InputsFile  vv_upload_width"
                                  }
                                  onChange={handleChange}
                                  autoComplete="aadharCardphoto"
                                  required
                                />
                              </div>
                              {list.aadharCardphoto !== "" &&
                                list.aadharCardphoto !== null && (
                                  <div
                                    className={
                                      "col-md-9 d-flex align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_file">
                                      {list.aadharCardphoto !== "" &&
                                      list.aadharCardphoto !== null
                                        ? list.original_aadhar_path
                                        : ""}
                                    </span>
                                    <span className="material-icons align-middle text-danger verified_upload">
                                      verified
                                    </span>
                                  </div>
                                )}
                            </div>
                            {listErrors.aadharCardphoto && (
                              <p className="errors">
                                {listErrors.aadharCardphoto}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* New Added ------(Communal Reservation) */}
                <section className=" sectionTwo">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Communal Reservation Category</p>

                      <div className="row mb-4">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="Cast">Category</label>
                            <Select
                              ref={txtAdCno}
                              id="Cast"
                              name="Cast"
                              placeholder="Select an option"
                              isDisabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={dropdown.Cast}
                              onChange={(option) =>
                                handleSelectMulti(option, "Cast", "Cast")
                              }
                              options={categoryOption} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />
                            {/* ------------------- */}
                            {listErrors.Cast && (
                              <small className="errors fw-semibold">
                                {listErrors.Cast}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* New Added ------(Applicable Special) */}
                <section className=" sectionTwo">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Applicable Special Category Preferences
                      </p>

                      <div className="row mb-4">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="AppCast">Category</label>
                            <Select
                              ref={txtAdACno}
                              id="AppCast"
                              name="AppCast"
                              isMulti
                              placeholder="Select an option"
                              isDisabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={appCastLable.map((value) => ({
                                value: value.value,
                                label: value.label,
                              }))}
                              onChange={(option) =>
                                handleSelectMulti(option, "AppCast", "AppCast")
                              }
                              options={appcategoryOption} // Sort options alphabetically by label
                              // options={appcategoryOption.sort((a, b) =>
                              //   a.label.localeCompare(b.label)
                              // )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              components={{
                                Option: CommonOption, // Correct the syntax here
                              }}
                              styles={multiCusutomStyle} // Apply custom styles her
                            />
                            {listErrors.AppCast && (
                              <small className="errors fw-semibold">
                                {listErrors.AppCast}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className=" sectionSix">
                  {techQualification.map((item, index) => (
                    <TechModelExam
                      key={item.id}
                      admission_status={list.admission_status === 1 ? 1 : 0}
                      length={techQualification.length}
                      AddQualification={AddQualification}
                      DeleteQual={DeleteQual}
                      technicalOption={technicalOption}
                      TechhandleChange={TechhandleChange}
                      TechhandleChanges={TechhandleChanges}
                      record={item}
                      index={index}
                    />
                  ))}
                  <div className="heading mb-5 d-flex justify-content-end ">
                    <button
                      type="button"
                      className="SaveButtons"
                      onClick={handleNext}
                    >
                      <a href="javascript:void(0)">
                        Save and Continue to Communication
                      </a>
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </fieldset>
        )}
        {/* {step === 2  -----(New Added)--} */}
        {step === 2 && (
          <fieldset>
            <div className="row">
              <div className="col-md-12">
                <section className=" sectionThree">
                  <div className="heading  dflexbetww ">
                    <div className="">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)">Go to Personal Details</a>
                      </button>
                    </div>
                    <div className="d-flex smButton mobiledown">
                      <Link to="/" className="CancelButton">
                        Back to Home
                      </Link>
                      <button
                        type="button"
                        className="SaveButtons"
                        onClick={handleNext}
                      >
                        <a href="javascript:void(0)">
                          Save and Continue to Model Exam Details
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">{LBL_CONTACT_DETAILS}</p>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="Mobile">Phone Number</label>
                            <input
                              ref={txtAdPno}
                              id="Mobile"
                              type="tel"
                              name="Mobile"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={handleChange}
                              value={list.Mobile}
                              className="Inputs_Phone"
                              placeholder="Phone Number"
                              autoComplete="Mobile"
                              required
                              minLength={10}
                              maxLength={10}
                              inputMode="tel"
                            />
                            {listErrors.Mobile && (
                              <p className="errors">{listErrors.Mobile}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="WhatsAppNumber ">
                                WhatsApp Number
                              </label>
                              <label>
                                <input
                                  className=" form-check-input me-2 align-items-center"
                                  type="checkbox"
                                  id="ContactNumber"
                                  name="ContactNumber"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  onChange={handleChange}
                                  value={list.ContactNumber}
                                  checked={
                                    list.ContactNumber === true ? true : false
                                  }
                                />
                                <span
                                  className="form-check-label"
                                  htmlFor="ContactNumber"
                                >
                                  Same as Contact Number
                                </span>
                              </label>
                            </div>
                            <input
                              ref={txtAdWno}
                              id="WhatsAppNumber"
                              type="tel"
                              name="WhatsAppNumber"
                              placeholder="WhatsApp Number"
                              minLength={10}
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              maxLength={10}
                              className="Inputs_Phone"
                              onBlur={handleblur}
                              onChange={handleChange}
                              value={
                                list.ContactNumber !== true
                                  ? list.WhatsAppNumber
                                  : list.Mobile
                              }
                              autoComplete="WhatsAppNumber"
                              required
                              inputMode="tel"
                            />
                            {listErrors.WhatsAppNumber && (
                              <p className="errors">
                                {listErrors.WhatsAppNumber}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="ParentMobileNo">
                              {" "}
                              Father/Guardian Phone Number
                            </label>
                            <input
                              ref={txtAdFpo}
                              id="ParentMobileNo"
                              type="tel"
                              name="ParentMobileNo"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={handleChange}
                              value={list.ParentMobileNo}
                              className="Inputs_Phone"
                              placeholder="Phone Number"
                              autoComplete="ParentMobileNo"
                              required
                              minLength={10}
                              maxLength={10}
                              inputMode="tel"
                            />
                            {listErrors.ParentMobileNo && (
                              <p className="errors">
                                {listErrors.ParentMobileNo}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="emailaddress">Email ID</label>
                            <input
                              ref={txtAdEmail}
                              id="emailaddress"
                              readOnly
                              type="email"
                              name="emailaddress"
                              className="Inputs"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={handleChange}
                              value={list.emailaddress}
                              placeholder=" Email ID"
                              autoComplete="emailaddress"
                              required
                            />
                            {listErrors.emailaddress && (
                              <p className="errors">
                                {listErrors.emailaddress}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="sectionFour">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Present Address</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="BuildingNumberPresent">
                              House/Flat Number & Name
                            </label>
                            <input
                              ref={txtAdPhno}
                              id="BuildingNumberPresent"
                              type="text"
                              name="BuildingNumberPresent"
                              className="Inputs"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={handleChange}
                              value={list.BuildingNumberPresent}
                              placeholder=" House/Building Number"
                              autoComplete="BuildingNumberPresent"
                              required
                              // minLength={20}
                              // maxLength={20}
                            />
                            {listErrors.BuildingNumberPresent && (
                              <p className="errors">
                                {listErrors.BuildingNumberPresent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <div className="forms ">
                              <label htmlFor="StreetNamePresent">
                                Street Name
                              </label>
                              <input
                                ref={txtAdPsname}
                                id="StreetNamePresent"
                                type="text"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="StreetNamePresent"
                                className="Inputs"
                                onChange={handleChange}
                                value={list.StreetNamePresent}
                                placeholder="Street Name"
                                autoComplete="StreetNamePresent"
                                required
                              />
                              {listErrors.StreetNamePresent && (
                                <p className="errors">
                                  {listErrors.StreetNamePresent}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="AreaPresent">Locality/Area</label>
                            <input
                              ref={txtAdParea}
                              id="AreaPresent"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="AreaPresent"
                              className="Inputs"
                              onChange={handleChange}
                              value={list.AreaPresent}
                              placeholder="Locality/Area"
                              autoComplete="AreaPresent"
                              required
                            />
                            {listErrors.AreaPresent && (
                              <p className="errors">{listErrors.AreaPresent}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="LandmarkPresent">Landmark</label>
                            <input
                              ref={txtAdPland}
                              id="LandmarkPresent"
                              type="text"
                              name="LandmarkPresent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={handleChange}
                              value={list.LandmarkPresent}
                              placeholder=" Landmark"
                              autoComplete="LandmarkPresent"
                              required
                            />
                            {listErrors.LandmarkPresent && (
                              <p className="errors">
                                {listErrors.LandmarkPresent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="PostPresent">Post</label>
                            <input
                              ref={txtAdPpost}
                              id="PostPresent"
                              type="text"
                              name="PostPresent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={handleChange}
                              value={list.PostPresent}
                              placeholder=" Post"
                              autoComplete="PostPresent"
                              required
                            />

                            {listErrors.PostPresent && (
                              <p className="errors">{listErrors.PostPresent}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="TalukPresent">Taluk</label>
                            <input
                              ref={txtAdPtaluk}
                              id="TalukPresent"
                              type="text"
                              name="TalukPresent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={handleChange}
                              value={list.TalukPresent}
                              placeholder=" Taluk"
                              autoComplete="TalukPresent"
                              required
                            />
                            {listErrors.TalukPresent && (
                              <p className="errors">
                                {listErrors.TalukPresent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="PincodePresent">
                              Pin/Postal Code
                            </label>
                            <input
                              ref={txtAdPpincode}
                              id="PincodePresent"
                              type="text"
                              onChange={handleChange}
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={list.PincodePresent}
                              name="PincodePresent"
                              className="Inputs"
                              placeholder="Pin/Postal Code"
                              autoComplete="PincodePresent"
                              required
                            />
                            {listErrors.PincodePresent && (
                              <p className="errors">
                                {listErrors.PincodePresent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="CityPresent">
                              City/Town/Village
                            </label>
                            <Select
                              ref={txtAdPcity}
                              id="CityPresent"
                              name="CityPresent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={
                                list.CityPresent !== null &&
                                list.CityPresent !== ""
                                  ? {
                                      value: list.CityPresent,
                                      label: list.CityPresent,
                                    }
                                  : null
                              }
                              onChange={(option) =>
                                handleSelectCH(option, "CityPresent")
                              }
                              options={
                                city
                                  .filter((item) => item && item.label) // Filter out null and undefined elements
                                  .sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  ) // Sort options alphabetically by label
                              }
                              isSearchable={true}
                              placeholder="Select an option"
                              maxMenuHeight={200}
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              }
                              styles={customStyles}
                            />

                            {/* <select
                                  ref={txtAdPcity}
                                  id="CityPresent"
                                  name="CityPresent"
                                  className="Inputs"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  onChange={handleChange}
                                  defaultValue={list.CityPresent}
                                  required
                                >
                                  <option value="" disabled>
                                    Please select
                                  </option>
                                  {city}
                                </select> */}

                            {listErrors.CityPresent && (
                              <p className="errors">{listErrors.CityPresent}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row  mb-4">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="districtPresent" id="hio">
                              District{" "}
                            </label>
                            <input
                              ref={txtAdPdistrict}
                              id="districtPresent"
                              type="text"
                              value={list.districtPresent}
                              name="districtPresent"
                              className="Inputs"
                              placeholder="district"
                              disabled
                              required
                            />

                            {listErrors.districtPresent && (
                              <p className="errors">
                                {listErrors.districtPresent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="statePresent" id="hio">
                              State/Union Territory
                            </label>
                            <input
                              ref={txtAdPstate}
                              id="statePresent"
                              type="text"
                              value={list.statePresent}
                              name="statePresent"
                              className="Inputs"
                              placeholder=" State/Union Territory"
                              disabled
                              required
                            />
                            {/* <select
                          id="statePresent"
                          name="statePresent"
                          className="Inputs"
                          onChange={handleChange}
                          defaultValue={fieldInput.statePresent}
                          required
                        >
                          <option value="" disabled selected>
                            
                          </option>
                        </select> */}
                            {listErrors.statePresent && (
                              <p className="errors">
                                {listErrors.statePresent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className=" sectionFour ">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="final">Permanent Address</p>

                      <div className="row row mt-4 ms-2">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-between">
                            <label>
                              <input
                                className="me-2 form-check-input align-items-center"
                                type="checkbox"
                                id="SamePresentAddress"
                                name="SamePresentAddress"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                onChange={handleChange}
                                value={list.SamePresentAddress}
                                checked={
                                  list.SamePresentAddress === true
                                    ? true
                                    : false
                                }
                              />
                              <span
                                className="form-check-label"
                                htmlFor="SamePresentAddress"
                              >
                                Same as Present Address
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="BuildingNumberPermanent">
                              House/Flat Number & Name
                            </label>
                            <input
                              ref={txtAdPerhno}
                              id="BuildingNumberPermanent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              type="text"
                              name="BuildingNumberPermanent"
                              className="Inputs"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.BuildingNumberPermanent
                                  : list.BuildingNumberPresent
                              }
                              placeholder=" House/Building Number"
                              autoComplete="BuildingNumberPermanent"
                              required
                            />
                            {listErrors.BuildingNumberPermanent && (
                              <p className="errors">
                                {listErrors.BuildingNumberPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <div className="forms ">
                              <label htmlFor="StreetNamePermanent">
                                Street Name
                              </label>
                              <input
                                ref={txtAdPersname}
                                id="StreetNamePermanent"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                type="text"
                                name="StreetNamePermanent"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.SamePresentAddress !== true
                                    ? list.StreetNamePermanent
                                    : list.StreetNamePresent
                                }
                                placeholder="Street Name"
                                autoComplete="StreetNamePermanent"
                                required
                              />
                              {listErrors.StreetNamePermanent && (
                                <p className="errors">
                                  {listErrors.StreetNamePermanent}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="AreaPermanent">Locality/Area</label>
                            <input
                              ref={txtAdPerarea}
                              id="AreaPermanent"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="AreaPermanent"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.AreaPermanent
                                  : list.AreaPresent
                              }
                              className="Inputs"
                              placeholder="Locality/Area"
                              autoComplete="AreaPermanent"
                              required
                            />
                            {listErrors.AreaPermanent && (
                              <p className="errors">
                                {listErrors.AreaPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="LandmarkPermanent">Landmark</label>
                            <input
                              ref={txtAdPerland}
                              id="LandmarkPermanent"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="LandmarkPermanent"
                              className="Inputs"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.LandmarkPermanent
                                  : list.LandmarkPresent
                              }
                              placeholder=" City"
                              autoComplete="LandmarkPermanent"
                              required
                            />
                            {listErrors.LandmarkPermanent && (
                              <p className="errors">
                                {listErrors.LandmarkPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="PostPermanent">Post</label>
                            <input
                              ref={txtAdPererpost}
                              id="PostPermanent"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="PostPermanent"
                              className="Inputs"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.PostPermanent
                                  : list.PostPresent
                              }
                              placeholder=" Post"
                              autoComplete="PostPermanent"
                              required
                            />

                            {listErrors.PostPermanent && (
                              <p className="errors">
                                {listErrors.PostPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="TalukPermanent">Taluk</label>
                            <input
                              ref={txtAdPertaluk}
                              id="TalukPermanent"
                              type="text"
                              name="TalukPermanent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.TalukPermanent
                                  : list.TalukPresent
                              }
                              placeholder=" Taluk"
                              autoComplete="TalukPermanent"
                              required
                            />
                            {listErrors.TalukPermanent && (
                              <p className="errors">
                                {listErrors.TalukPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="PincodePermanent">
                              Pin/Postal Code
                            </label>
                            <input
                              ref={txtAdPerpincode}
                              id="PincodePermanent"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.PincodePermanent
                                  : list.PincodePresent
                              }
                              name="PincodePermanent"
                              className="Inputs"
                              placeholder="Pin/Postal Code"
                              autoComplete="PincodePermanent"
                              required
                            />
                            {listErrors.PincodePermanent && (
                              <p className="errors">
                                {listErrors.PincodePermanent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="CityPermanent">
                              City/Town/Village
                            </label>

                            {list.SamePresentAddress !== true ? (
                              <Select
                                ref={txtAdPercity}
                                id="CityPermanent"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="CityPermanent"
                                value={
                                  list.CityPermanent !== null &&
                                  list.CityPermanent !== ""
                                    ? {
                                        value: list.CityPermanent,
                                        label: list.CityPermanent,
                                      }
                                    : null
                                }
                                onChange={(option) =>
                                  handleSelectCH(option, "CityPermanent")
                                }
                                options={
                                  city
                                    .filter((item) => item && item.label) // Filter out null and undefined elements
                                    .sort((a, b) =>
                                      a.label.localeCompare(b.label)
                                    ) // Sort options alphabetically by label
                                }
                                isSearchable={true}
                                placeholder="Select an option"
                                maxMenuHeight={200}
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                }
                                styles={customStyles}
                              />
                            ) : (
                              <input
                                ref={txtAdPercity}
                                id="CityPermanent"
                                type="text"
                                onChange={handleChange}
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={
                                  list.SamePresentAddress === true
                                    ? list.CityPresent
                                    : list.CityPermanent
                                }
                                name="CityPermanent"
                                className="Inputs"
                                placeholder="City"
                                autoComplete="CityPermanent"
                                required
                              />
                            )}

                            {listErrors.CityPermanent && (
                              <p className="errors">
                                {listErrors.CityPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row  mb-4">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="districtPermanent" id="hio">
                              District
                            </label>
                            <input
                              ref={txtAdPerdistrict}
                              id="districtPermanent"
                              type="text"
                              onChange={handleChange}
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={
                                list.SamePresentAddress !== true
                                  ? list.districtPermanent
                                  : list.districtPresent
                              }
                              name="districtPermanent"
                              className="Inputs"
                              placeholder="district"
                              autoComplete="districtPermanent"
                              required
                            />

                            {listErrors.districtPermanent && (
                              <p className="errors">
                                {listErrors.districtPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="statePermanent" id="hio">
                              State/Union Territory
                            </label>
                            <input
                              ref={txtAdPerstate}
                              id="statePermanent"
                              type="text"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.statePermanent
                                  : list.statePresent
                              }
                              name="statePermanent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              placeholder="State/Union Territory"
                              autoComplete="statePermanent"
                              required
                            />

                            {listErrors.statePermanent && (
                              <p className="errors">
                                {listErrors.statePermanent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="heading  mb-4 dflexbetww ">
                    <div className="">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)">Go to Personal Details</a>
                      </button>
                    </div>
                    <div className="d-flex smButton mobiledown">
                      <Link to="/" className="CancelButton">
                        Back to Home
                      </Link>
                      <button
                        type="button"
                        className="SaveButtons"
                        onClick={handleNext}
                      >
                        <a href="javascript:void(0)">
                          Save and Continue to Model Exam Details
                        </a>
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </fieldset>
        )}
        {/* {step === 3} */}
        {step === 3 && (
          <fieldset>
            <div className="row">
              <div className="col-md-12">
                <section className="  sectionSix">
                  <div className="heading  dflexbetww ">
                    <div className="">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)">Go to Communication</a>
                      </button>
                    </div>
                    <div className="d-flex smButton mobiledown">
                      <Link to="/" className="CancelButton">
                        Back to Home
                      </Link>
                      <button
                        type="button"
                        className="SaveButtons"
                        onClick={handleNext}
                      >
                        <a href="javascript:void(0)">
                          Save and Continue to Review and Confirm
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Model Exam Enrollment</p>
                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <div className="forms row  ">
                            <label htmlFor="Category">Category</label>
                            <div
                              className="d-flex flex-lg-row flex-md-row flex-column"
                              id="radiobuttons"
                            >
                              <div className="form-check form-check-inline">
                                <input
                                  ref={txtAdCate}
                                  className="checkbox_radio"
                                  type="radio"
                                  name="category"
                                  disabled
                                  id="UPSC"
                                  value="UPSC"
                                  onChange={handleChange}
                                  checked={list.category === "UPSC"}
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="UPSC"
                                >
                                  UPSC
                                </label>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="category"
                                  id="TNPSC"
                                  disabled
                                  value="TNPSC"
                                  checked={list.category === "TNPSC"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="TNPSC"
                                >
                                  TNPSC
                                </label>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="category"
                                  disabled
                                  id="TRB"
                                  value="TRB"
                                  checked={list.category === "TRB"}
                                  onChange={handleChange}
                                />
                                <label className="checkbox-label" htmlFor="TRB">
                                  TRB
                                </label>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="category"
                                  id="TET"
                                  disabled
                                  value="TET"
                                  checked={list.category === "TET"}
                                  onChange={handleChange}
                                />
                                <label className="checkbox-label" htmlFor="TET">
                                  TET
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  disabled
                                  name="category"
                                  id="TNUSRB"
                                  value="TNUSRB"
                                  checked={list.category === "TNUSRB"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="TNUSRB"
                                >
                                  TNUSRB
                                </label>
                              </div>
                            </div>
                            <div
                              className="d-flex flex-lg-row flex-md-row flex-column"
                              id="radiobuttons"
                            >
                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="category"
                                  disabled
                                  id="RRB"
                                  value="RRB"
                                  checked={list.category === "RRB"}
                                  onChange={handleChange}
                                />
                                <label className="checkbox-label" htmlFor="RRB">
                                  RRB
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="category"
                                  id="SSC"
                                  disabled
                                  value="SSC"
                                  checked={list.category === "SSC"}
                                  onChange={handleChange}
                                />
                                <label className="checkbox-label" htmlFor="SSC">
                                  SSC
                                </label>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="category"
                                  disabled
                                  id="Banking"
                                  value="Banking"
                                  checked={list.category === "Banking"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Banking"
                                >
                                  Banking
                                </label>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  disabled
                                  name="category"
                                  id="Others"
                                  value="Others"
                                  checked={list.category === "Others"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Others"
                                >
                                  Others
                                </label>
                              </div>
                            </div>
                          </div>
                          {listErrors.category && (
                            <small className="errors fw-semibold">
                              {listErrors.category}
                            </small>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="CourseCate">Course</label>
                            <Select
                              ref={txtAdCourse}
                              id="CourseCate"
                              name="CourseCate"
                              isDisabled={edit ? true : false}
                              value={dropdown.CourseCate}
                              onChange={(option) =>
                                handleSelectMulti(
                                  option,
                                  "CourseCate",
                                  "CourseCate"
                                )
                              }
                              options={
                                coursesCate
                                  .filter((item) => item && item.label) // Filter out null and undefined elements
                                  .sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  ) // Sort options alphabetically by label
                              }
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />
                            {listErrors.CourseCate && (
                              <p className="errors">{listErrors.CourseCate}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* ----New Added--- */}
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="TeachMedium">
                              Medium of Instruction
                            </label>
                            <select
                              ref={txtAdMedium}
                              id="TeachMedium"
                              disabled={edit ? true : false}
                              name="TeachMedium"
                              className="Inputs"
                              onChange={handleChange}
                              value={list.TeachMedium}
                              required
                            >
                              <option value="" disabled selected>
                                Please select
                              </option>
                              <option value="Tamil">Tamil</option>
                              <option value="English">English</option>
                              <option value="Both">Both</option>
                            </select>
                            {listErrors.TeachMedium && (
                              <p className="errors">{listErrors.TeachMedium}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="districts">
                              Preferred Exam District
                            </label>
                            <select
                              ref={txtAdDistrict}
                              id="districts"
                              name="districts"
                              disabled={edit ? true : false}
                              className="Inputs"
                              onChange={handleChange}
                              value={list.districts}
                              required
                            >
                              <option value="" selected>
                                Please select
                              </option>

                              {district}
                            </select>
                            {listErrors.districts && (
                              <p className="errors">{listErrors.districts}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="forms ">
                            <label for="classMode">Mode of Exam</label>

                            <div
                              className="d-flex  col-md-12"
                              id="radiobuttons"
                            >
                              <div className="form-check form-check-inline">
                                <input
                                  ref={txtAdcMode}
                                  className="checkbox_radio"
                                  type="radio"
                                  name="classMode"
                                  id="Online"
                                  disabled={edit ? true : false}
                                  value="Online"
                                  defaultChecked={list.classMode === "Online"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Online"
                                >
                                  Online
                                </label>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="classMode"
                                  id="ClassRoom"
                                  value="ClassRoom"
                                  disabled={edit ? true : false}
                                  defaultChecked={
                                    list.classMode === "ClassRoom"
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="ClassRoom"
                                >
                                  Offline
                                </label>
                              </div>
                            </div>
                            {listErrors.classMode && (
                              <small className="errors">
                                {listErrors.classMode}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {batchDetails[0] !== null &&
                Object.keys(batchDetails).length > 0 ? (
                  <section className=" sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Exam Details</p>
                        {batchDetails.map((el, index) => (
                          <div className="row" key={index}>
                            {el.paper_type === 0 && (
                              <>
                                <div className="row my-3">
                                  <div className="col-md-12 col-lg-12 mt-2 mb-2">
                                    <div className="forms ">
                                      <span className="ReviewLabel_note alert_info">
                                        <strong> NOTE: </strong> You can{" "}
                                        <strong>
                                          select any combination of model exams
                                        </strong>{" "}
                                        from the given list, and there will be
                                        separate hall tickets for each model
                                        exam. Once the hall ticket is generated,
                                        you will be notified via email. You can
                                        download the hall ticket for each exam
                                        from the{" "}
                                        <strong>'Download Hall Ticket'</strong>{" "}
                                        page.
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-3 mb-3">
                                  <div className="card  text-dark">
                                    <div className="card-body BatchImage">
                                      <div className="row">
                                        {/* <div className="col-lg-2 col-md-6 col-sm-12 "> */}
                                        <div className="col-lg-2 col-md-6 col-sm-12 ">
                                          <img
                                            title={el.img_url}
                                            src={`${ImageUrlPrefix}/${el.img_url}`}
                                            id={el.id}
                                            alt="Imge"
                                          />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column  justify-content-center">
                                          <p
                                            className="title  m-0 "
                                            style={{ width: "initial" }}
                                          >
                                            {el.course_name || ""} {"-"}{" "}
                                            {el.name || ""}
                                          </p>
                                        </div>

                                        <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column  justify-content-center ">
                                          <li className="IconsImage">
                                            <span className="material-icons">
                                              currency_rupee
                                            </span>
                                            <div className="d-flex flex-column">
                                              <p className="st_confirm_label ms-1">
                                                Fee Details
                                              </p>
                                              <p className="st_confirm_value ms-1">
                                                {el.exam_fees
                                                  .filter(
                                                    (fee) =>
                                                      fee.fees_count ===
                                                      el.exam_details.filter(
                                                        (elll) =>
                                                          elll.is_selected_exam ===
                                                          true
                                                      ).length
                                                  )
                                                  .map((fee, ikl) => (
                                                    <React.Fragment key={ikl}>
                                                      <span className="batch_fees_text">
                                                        {fee.fees_count} - Exam{" "}
                                                        -{" "}
                                                        {fee.fees
                                                          ? Number(
                                                              fee.fees
                                                            ).toFixed(2)
                                                          : "_"}
                                                      </span>
                                                      {(ikl + 1) % 2 === 0 && (
                                                        <br />
                                                      )}
                                                    </React.Fragment>
                                                  ))}
                                              </p>
                                            </div>
                                          </li>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 d-flex flex-column align-items-end justify-content-center">
                                          <p className="title m-0 ">
                                            You've selected{" "}
                                            {el.exam_fees
                                              .filter(
                                                (fee) =>
                                                  fee.fees_count ===
                                                  el.exam_details.filter(
                                                    (el) => el.is_selected_exam
                                                  ).length
                                              )
                                              .map(
                                                (fee) => fee.fees_count
                                              )}{" "}
                                            exam, and the fee is Rs.
                                            {el.exam_fees
                                              .filter(
                                                (fee) =>
                                                  fee.fees_count ===
                                                  el.exam_details.filter(
                                                    (el) => el.is_selected_exam
                                                  ).length
                                              )
                                              .map((fee) => fee.fees).length > 0
                                              ? el.exam_fees
                                                  .filter(
                                                    (fee) =>
                                                      fee.fees_count ===
                                                      el.exam_details.filter(
                                                        (el) =>
                                                          el.is_selected_exam
                                                      ).length
                                                  )
                                                  .map((fee) => fee.fees)
                                              : 0}
                                          </p>
                                          <div style={{ textAlign: "end" }}>
                                            <input
                                              class="checkbox_batch_add mt-1"
                                              type="checkbox"
                                              name="is_selected_all"
                                              id="is_selected_all"
                                              value={el.is_selected_all}
                                              onChange={(e) =>
                                                handleBatchDetailsSelectAll(
                                                  el.id,
                                                  e
                                                )
                                              }
                                              disabled={edit ? true : false}
                                              checked={
                                                el.is_selected_all === true
                                                  ? true
                                                  : false
                                              }
                                            />{" "}
                                            <span className="st_confirm_check">
                                              {" "}
                                              Select All Model Exams
                                            </span>
                                          </div>
                                        </div>

                                        {el.exam_details.length > 0 &&
                                          el.exam_details
                                            .filter(
                                              (eol) =>
                                                eol.is_selected_exam === true
                                            )
                                            ?.sort(
                                              (a, b) =>
                                                new Date(a.started_dt) -
                                                new Date(b.started_dt)
                                            )
                                            ?.map((obh, index) => (
                                              <>
                                                <div className="d-flex justify-content-center align-items-center my-2">
                                                  <div
                                                    className="d-none d-lg-block"
                                                    style={{ maxWidth: "50px" }}
                                                  >
                                                    <input
                                                      ref={txtbatchDet}
                                                      className="checkbox_batch"
                                                      type="checkbox"
                                                      name="is_selected"
                                                      id="is_selected_batch"
                                                      onChange={(e) =>
                                                        handleBatchDetails(
                                                          el.id,
                                                          obh.pk,
                                                          e
                                                        )
                                                      }
                                                      checked={
                                                        obh.is_selected_exam ===
                                                        true
                                                          ? true
                                                          : false
                                                      }
                                                      disabled={
                                                        edit ? true : false
                                                      }
                                                      value={
                                                        obh.is_selected_exam
                                                      }
                                                    />
                                                  </div>
                                                  <div
                                                    className=""
                                                    style={{ width: "100%" }}
                                                  >
                                                    <div
                                                      className="row ms-lg-2"
                                                      style={{
                                                        padding: "10px",
                                                        border:
                                                          "2px solid #0000004D",
                                                        borderRadius: "8px",
                                                      }}
                                                    >
                                                      <div className="col-md-12 d-block d-lg-none">
                                                        <div
                                                          className=""
                                                          style={{
                                                            maxWidth: "50px",
                                                          }}
                                                        >
                                                          <input
                                                            ref={txtbatchDet}
                                                            className="checkbox_batch"
                                                            type="checkbox"
                                                            name="is_selected"
                                                            id="is_selected_batch"
                                                            onChange={(e) =>
                                                              handleBatchDetails(
                                                                el.id,
                                                                obh.pk,
                                                                e
                                                              )
                                                            }
                                                            checked={
                                                              obh.is_selected_exam ===
                                                              true
                                                                ? true
                                                                : false
                                                            }
                                                            value={
                                                              obh.is_selected_exam
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-md-6 col-lg-4 col-xl-3">
                                                        <li className="IconsImage">
                                                          <span className="material-icons">
                                                            format_shapes
                                                          </span>
                                                          <div className="d-flex flex-column">
                                                            <p className="st_confirm_label ms-1">
                                                              Exam Title
                                                            </p>
                                                            <p className="st_confirm_value ms-1">
                                                              {obh.exam_title
                                                                ? obh.exam_title
                                                                : "_"}
                                                            </p>
                                                          </div>
                                                        </li>
                                                      </div>
                                                      {/* New added */}
                                                      <div className="col-md-6 col-lg-4 col-xl-2">
                                                        <li className="IconsImage">
                                                          <span className="material-icons">
                                                            library_books
                                                          </span>
                                                          <div className="d-flex flex-column">
                                                            <p className="st_confirm_label ms-1 ">
                                                              Syllabus
                                                            </p>
                                                            <p className="subtitle ms-1">
                                                              <a
                                                                target="_blank"
                                                                href={`${ImageUrlPrefix}/${obh.syllabus_url}`}
                                                                className="subtitle_testpdf "
                                                              >
                                                                Click here to
                                                                view syllabus
                                                              </a>
                                                            </p>
                                                          </div>
                                                        </li>
                                                      </div>
                                                      <div className="col-md-6 col-lg-4 col-xl-1">
                                                        <li className="IconsImage">
                                                          <span className="material-icons-outlined">
                                                            timer
                                                          </span>
                                                          <div className="d-flex flex-column">
                                                            <p className="st_confirm_label ms-1">
                                                              Duration
                                                            </p>
                                                            <p className="st_confirm_value ms-1">
                                                              {obh.duration_hrs}
                                                              hours
                                                            </p>
                                                          </div>
                                                        </li>
                                                      </div>

                                                      <div className="col-md-6 col-lg-4 col-xl-2">
                                                        <li className="IconsImage">
                                                          <span className="material-icons">
                                                            event
                                                          </span>
                                                          <div className="d-flex flex-column ">
                                                            <p className="st_confirm_label ms-1">
                                                              Date
                                                            </p>
                                                            <p className="st_confirm_value ms-1">
                                                              {obh.started_dt
                                                                ? moment
                                                                    .utc(
                                                                      obh.started_dt
                                                                    )
                                                                    .format(
                                                                      "Do MMM YYYY"
                                                                    )
                                                                : "_"}
                                                            </p>
                                                          </div>
                                                        </li>
                                                      </div>

                                                      <div className="col-md-6 col-lg-4 col-xl-2">
                                                        <li className="IconsImage">
                                                          <span className="material-icons-outlined">
                                                            timer
                                                          </span>
                                                          <div className="d-flex flex-column">
                                                            <p className="st_confirm_label ms-1">
                                                              Time
                                                            </p>
                                                            <p className="st_confirm_value ms-1">
                                                              {obh.batch_time}
                                                            </p>
                                                          </div>
                                                        </li>
                                                      </div>
                                                      <div className="col-md-6 col-lg-4 col-xl-2">
                                                        <li className="IconsImage">
                                                          <span className="material-icons">
                                                            event
                                                          </span>
                                                          <div className="d-flex flex-column">
                                                            <p className="st_confirm_label ms-1">
                                                              Last Date to Apply
                                                            </p>
                                                            <p className="st_confirm_value ms-1">
                                                              {obh.last_dt_apply
                                                                ? moment(
                                                                    obh.last_dt_apply
                                                                  ).format(
                                                                    "Do MMM YYYY"
                                                                  )
                                                                : "_"}
                                                            </p>
                                                          </div>
                                                        </li>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* col-md-1 */}
                                              </>
                                            ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {el.paper_type === 1 && (
                              <>
                                <div className="row my-3">
                                  <div className="col-md-12 col-lg-12 mt-2 mb-2">
                                    <div className="forms ">
                                      <span className="ReviewLabel_note alert_info">
                                        <strong> NOTE: </strong> You can{" "}
                                        <strong>
                                          select any combination of model exams
                                        </strong>{" "}
                                        from the given list, and there will be
                                        separate hall tickets for each model
                                        exam. Once the hall ticket is generated,
                                        you will be notified via email. You can
                                        download the hall ticket for each exam
                                        from the{" "}
                                        <strong>'Download Hall Ticket'</strong>{" "}
                                        page.
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mt-3 mb-3">
                                  <div className="card  text-dark">
                                    <div className="card-body BatchImage">
                                      <div className="row">
                                        {/* <div className="col-lg-2 col-md-6 col-sm-12 "> */}
                                        <div className="col-lg-2 col-md-6 col-sm-12 ">
                                          <img
                                            title={el.img_url}
                                            src={`${ImageUrlPrefix}/${el.img_url}`}
                                            id={el.id}
                                            alt="Imge"
                                          />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column  justify-content-center">
                                          <p
                                            className="title m-0 "
                                            style={{ width: "initial" }}
                                          >
                                            {el.course_name || ""} {"-"}{" "}
                                            {el.name || ""}
                                          </p>
                                        </div>

                                        <div className="col-lg-2 col-md-6 col-sm-12 d-flex flex-column  justify-content-center ">
                                          <li className="IconsImage">
                                            <span className="material-icons">
                                              currency_rupee
                                            </span>
                                            <div className="d-flex flex-column">
                                              <p className="st_confirm_label ms-1">
                                                Fee Details
                                              </p>
                                              <p className="st_confirm_value ms-1">
                                                {el.exam_fees
                                                  .filter(
                                                    (fee) =>
                                                      fee.fees_count ===
                                                      el.exam_details.filter(
                                                        (elll) =>
                                                          elll.is_selected_exam ===
                                                          true
                                                      ).length
                                                  )
                                                  .map((fee, lop) => (
                                                    <React.Fragment key={lop}>
                                                      <span className="batch_fees_text">
                                                        {fee.fees_count} - Exam{" "}
                                                        -{" "}
                                                        {fee.fees
                                                          ? Number(
                                                              fee.fees
                                                            ).toFixed(2)
                                                          : "_"}
                                                      </span>
                                                      {(lop + 1) % 2 === 0 && (
                                                        <br />
                                                      )}
                                                    </React.Fragment>
                                                  ))}
                                              </p>
                                            </div>
                                          </li>
                                        </div>
                                        <div className="col-lg-5 col-md-6 col-sm-12 d-flex flex-column  justify-content-center align-items-end">
                                          <p className="title m-0 ">
                                            You've selected{" "}
                                            {el.exam_fees
                                              .filter(
                                                (fee) =>
                                                  fee.fees_count ===
                                                  el.exam_details.filter(
                                                    (el) => el.is_selected_exam
                                                  ).length
                                              )
                                              .map(
                                                (fee) => fee.fees_count
                                              )}{" "}
                                            exam, and the fee is Rs{" "}
                                            {el.exam_fees
                                              .filter(
                                                (fee) =>
                                                  fee.fees_count ===
                                                  el.exam_details.filter(
                                                    (el) => el.is_selected_exam
                                                  ).length
                                              )
                                              .map((fee) => fee.fees).length > 0
                                              ? el.exam_fees
                                                  .filter(
                                                    (fee) =>
                                                      fee.fees_count ===
                                                      el.exam_details.filter(
                                                        (el) =>
                                                          el.is_selected_exam
                                                      ).length
                                                  )
                                                  .map((fee) => fee.fees)
                                              : 0}
                                          </p>
                                          <div>
                                            <input
                                              class=" mt-1 st_confirm_check" //checkbox_batch_add
                                              type="checkbox"
                                              name="is_selected_all"
                                              id="is_selected_all"
                                              value={el.is_selected_all}
                                              onChange={(e) =>
                                                handleBatchDetailsSelectAll(
                                                  el.id,
                                                  e
                                                )
                                              }
                                              disabled={edit ? true : false}
                                              checked={
                                                el.is_selected_all === true
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <span className="checkbox_sentence">
                                              {" "}
                                              Select All Model Exams
                                            </span>
                                          </div>
                                        </div>
                                        {el.exam_details.length > 0 &&
                                          el.exam_details
                                            .filter(
                                              (eol) =>
                                                eol.is_selected_exam === true
                                            )
                                            ?.sort(
                                              (a, b) =>
                                                parseInt(a.exam_title_id) -
                                                parseInt(b.exam_title_id)
                                            )
                                            ?.map((onk, index) => (
                                              <div className="col-md-12 mt-3 mb-3">
                                                <div className="card  text-dark">
                                                  <div className="card-body BatchImage">
                                                    <div className="row">
                                                      <div className="col-md-12 col-sm-12 ">
                                                        <div className="row">
                                                          <div className="col-md-3">
                                                            <li className="IconsImage">
                                                              <div className="d-flex flex-column">
                                                                <p className="st_confirm_label ms-1">
                                                                  Model Exam
                                                                  Title
                                                                </p>
                                                                <p className="st_confirm_value ms-1">
                                                                  {
                                                                    onk.exam_title
                                                                  }
                                                                </p>
                                                              </div>
                                                            </li>
                                                          </div>
                                                          <div className="col-md-3">
                                                            <li className="IconsImage">
                                                              <div className="d-flex flex-column ">
                                                                <p className="st_confirm_label ms-1">
                                                                  Number of
                                                                  Papers
                                                                </p>
                                                                <p className="st_confirm_value ms-1">
                                                                  {
                                                                    onk.no_of_paper
                                                                  }
                                                                </p>
                                                              </div>
                                                            </li>
                                                          </div>
                                                          <div className="col-md-3">
                                                            <li className="IconsImage">
                                                              <div className="d-flex flex-column">
                                                                <p className="st_confirm_label ms-1">
                                                                  Last Date to
                                                                  Apply
                                                                  {console.log(
                                                                    "lalitha",
                                                                    onk
                                                                  )}
                                                                </p>
                                                                <p className="st_confirm_value ms-1">
                                                                  {onk.last_dt_apply
                                                                    ? moment
                                                                        .utc(
                                                                          onk.last_dt_apply
                                                                        )
                                                                        .format(
                                                                          "DD/MM/YYYY"
                                                                        )
                                                                    : ""}
                                                                </p>
                                                              </div>
                                                            </li>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-1 d-flex justify-content-lg-center mt-sm-4 align-items-center">
                                                          <input
                                                            ref={txtbatchDet}
                                                            className="checkbox_batch"
                                                            type="checkbox"
                                                            name="is_selected"
                                                            id="is_selected_batch"
                                                            onChange={(e) =>
                                                              handleBatchDetails(
                                                                el.id,
                                                                onk.pk,
                                                                e
                                                              )
                                                            }
                                                            checked={
                                                              onk.is_selected_exam ===
                                                              true
                                                                ? true
                                                                : false
                                                            }
                                                            disabled={
                                                              edit
                                                                ? true
                                                                : false
                                                            }
                                                            value={
                                                              onk.is_selected_exam
                                                            }
                                                          />
                                                        </div>
                                                        <div className="col-md-11 mt-3 mb-3">
                                                          {onk.papers.length >
                                                            0 &&
                                                            onk.papers
                                                              ?.sort(
                                                                (a, b) =>
                                                                  new Date(
                                                                    a.start_dt
                                                                  ) -
                                                                  new Date(
                                                                    b.start_dt
                                                                  )
                                                              )
                                                              ?.map(
                                                                (
                                                                  vgh,
                                                                  index
                                                                ) => (
                                                                  <div className="col-md-12  mb-3">
                                                                    <div className="card  text-dark">
                                                                      <div className="card-body BatchImage">
                                                                        <div className="row">
                                                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                                                            <div className="row">
                                                                              <div className="col-md-12 col-sm-12 ">
                                                                                <div className="row">
                                                                                  <div className="col-md-2">
                                                                                    <li className="IconsImage">
                                                                                      <span className="material-icons">
                                                                                        format_shapes
                                                                                      </span>
                                                                                      <div className="d-flex flex-column">
                                                                                        <p className="st_confirm_label ms-1">
                                                                                          Paper
                                                                                          Title
                                                                                        </p>
                                                                                        <p className="st_confirm_value ms-1">
                                                                                          {
                                                                                            vgh.paper_title
                                                                                          }
                                                                                        </p>
                                                                                      </div>
                                                                                    </li>
                                                                                  </div>
                                                                                  {/* New added */}
                                                                                  <div className="col-md-3">
                                                                                    <li className="IconsImage">
                                                                                      <span className="material-icons">
                                                                                        library_books
                                                                                      </span>
                                                                                      <div className="d-flex flex-column">
                                                                                        <p className="st_confirm_label ms-1">
                                                                                          Syllabus
                                                                                        </p>
                                                                                        <p className="st_confirm_value ms-1">
                                                                                          <a
                                                                                            target="_blank"
                                                                                            href={`${ImageUrlPrefix}/${vgh.syllabus_url}`}
                                                                                            className="subtitle_testpdf "
                                                                                          >
                                                                                            Click
                                                                                            here
                                                                                            to
                                                                                            view
                                                                                            syllabus
                                                                                          </a>
                                                                                        </p>
                                                                                      </div>
                                                                                    </li>
                                                                                  </div>
                                                                                  <div className="col-md-2">
                                                                                    <li className="IconsImage">
                                                                                      <span className="material-icons-outlined">
                                                                                        timer
                                                                                      </span>
                                                                                      <div className="d-flex flex-column">
                                                                                        <p className="st_confirm_label ms-1">
                                                                                          Duration
                                                                                        </p>
                                                                                        <p className="st_confirm_value ms-1">
                                                                                          {
                                                                                            vgh.duration
                                                                                          }
                                                                                          hours
                                                                                        </p>
                                                                                      </div>
                                                                                    </li>
                                                                                  </div>

                                                                                  <div className="col-md-2">
                                                                                    <li className="IconsImage">
                                                                                      <span className="material-icons">
                                                                                        event
                                                                                      </span>
                                                                                      <div className="d-flex flex-column ">
                                                                                        <p className="st_confirm_label ms-1">
                                                                                          Date
                                                                                        </p>
                                                                                        <p className="st_confirm_value ms-1">
                                                                                          {moment
                                                                                            .utc(
                                                                                              vgh.start_dt
                                                                                            )
                                                                                            .format(
                                                                                              "Do MMM YYYY"
                                                                                            )}
                                                                                        </p>
                                                                                      </div>
                                                                                    </li>
                                                                                  </div>

                                                                                  <div className="col-md-3">
                                                                                    <li className="IconsImage">
                                                                                      <span className="material-icons-outlined">
                                                                                        timer
                                                                                      </span>
                                                                                      <div className="d-flex flex-column">
                                                                                        <p className="st_confirm_label ms-1">
                                                                                          Time
                                                                                        </p>
                                                                                        <p className="st_confirm_value ms-1">
                                                                                          {
                                                                                            vgh.test_time
                                                                                          }
                                                                                        </p>
                                                                                      </div>
                                                                                    </li>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                              {/* {index >
                                                                      0 && (
                                                                      <span className="border-exam mt-3"></span>
                                                                    )} */}
                                                                            </div>
                                                                          </div>
                                                                          {/*  */}
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                )
                                                              )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                        {listErrors.batchDetails && (
                          <p className="errors mb-2">
                            {listErrors.batchDetails}
                          </p>
                        )}
                      </div>
                    </div>
                  </section>
                ) : (
                  <section className="  sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Exam Details</p>
                        <div className="row">
                          <div className="col-md-12 mt-3 mb-3">
                            <div className="card  text-dark">
                              <div className="card-body ">
                                <p className="batch_Err">No Batch Found</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                <section className=" sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Other Relevant Information </p>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label htmlFor="hallticket">
                              Did you receive your hall ticket from the
                              recruitment board?
                            </label>
                            <div className="radioGroup d-flex flex-row">
                              <div className="formCheck ">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="hallticket"
                                  id="hallticket1"
                                  onChange={handleChange}
                                  defaultChecked={
                                    list.hallticket === "Yes" ? true : false
                                  }
                                  value="Yes"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="hallticket1"
                                >
                                  Yes
                                </label>

                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="hallticket"
                                  id="hallticket2"
                                  onChange={handleChange}
                                  defaultChecked={
                                    list.hallticket === "No" ? true : false
                                  }
                                  value="No"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="hallticket2"
                                >
                                  NO
                                </label>
                              </div>
                            </div>
                            {listErrors.hallticket && (
                              <p className="errors">{listErrors.hallticket}</p>
                            )}
                          </div>
                        </div>

                        {list.hallticket === "Yes" && (
                          // <div className="col-md-6">
                          //   <div className="forms ">
                          //     <label htmlFor="ExamRegNo">
                          //       Exam Registration Number
                          //     </label>
                          //     <input
                          //       ref={txtAdExamReg} // Use the ref directly here
                          //       id="ExamRegNo"
                          //       type="number"
                          //       name="ExamRegNo"
                          //       className="Inputs"
                          //       onChange={handleChange}
                          //       defaultValue={list.ExamRegNo}
                          //       placeholder="Enter Exam Registration Number"
                          //       autoComplete="ExamRegNo"
                          //       required
                          //     />

                          //     {listErrors.ExamRegNo && (
                          //       <p className="errors">{listErrors.ExamRegNo}</p>
                          //     )}
                          //   </div>
                          // </div>
                          <>
                            <div className="col-md-6">
                              <div className="forms ">
                                <label htmlFor="hallExamPhoto">
                                  Upload Hall Ticket (Issued by the recruitment
                                  board)
                                  {list.hallExamPhoto !== "" &&
                                    list.hallExamPhoto !== null && (
                                      <a
                                        href={`${ImageUrlPrefix}/${list.hallExamPhoto}`}
                                        target="_blank"
                                        download
                                      >
                                        <span className="material-icons align-middle download_icon">
                                          file_download
                                        </span>
                                      </a>
                                    )}
                                </label>
                                <div className="row">
                                  <div
                                    className={
                                      list.hallExamPhoto !== "" &&
                                      list.hallExamPhoto !== null
                                        ? "col-md-3 "
                                        : "col-md-12"
                                    }
                                  >
                                    <input
                                      ref={txthallExamPhoto}
                                      id="hallExamPhoto"
                                      type="file"
                                      name="hallExamPhoto"
                                      placeholder="Upload ID Card"
                                      className={
                                        list.hallExamPhoto !== "" &&
                                        list.hallExamPhoto !== null
                                          ? "InputsFile nn_upload_width"
                                          : "InputsFile  vv_upload_width"
                                      }
                                      onChange={handleChange}
                                      autoComplete="hallExamPhoto"
                                      required
                                    />
                                  </div>
                                  {list.hallExamPhoto !== "" &&
                                    list.hallExamPhoto !== null && (
                                      <div
                                        className={
                                          "col-md-9 d-flex align-content-center p-0"
                                        }
                                      >
                                        <span className="nn_upload_file">
                                          {list.hallExamPhoto !== "" &&
                                          list.hallExamPhoto !== null
                                            ? list.original_hallExamPhoto
                                            : ""}
                                        </span>
                                        <span className="material-icons align-middle text-danger verified_upload">
                                          verified
                                        </span>
                                      </div>
                                    )}
                                </div>
                                {listErrors.hallExamPhoto && (
                                  <p className="errors">
                                    {listErrors.hallExamPhoto}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="forms ">
                                <label htmlFor="Institute">
                                  Registration Number of the Exam
                                </label>

                                <input
                                  ref={txtAdExamReg} // Use the ref directly here
                                  id="ExamRegNo"
                                  type="number"
                                  name="ExamRegNo"
                                  className="Inputs"
                                  onChange={handleChange}
                                  defaultValue={list.ExamRegNo}
                                  placeholder="Registration Number of the Exam"
                                  autoComplete="ExamRegNo"
                                  required
                                />
                                {listErrors.ExamRegNo && (
                                  <p className="errors">
                                    {listErrors.ExamRegNo}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label htmlFor="AIASAStudent">
                              Are You an AIASA Student?
                              <span
                                id="impo"
                                class="material-icons text-danger"
                              >
                                error
                              </span>
                            </label>
                            <div className="radioGroup d-flex flex-row">
                              <div className="formCheck ">
                                <input
                                  ref={txtAdayear}
                                  className="checkbox_radio"
                                  type="radio"
                                  name="AIASAStudent"
                                  id="AIASAStudent1"
                                  onChange={handleChange}
                                  checked={
                                    list.AIASAStudent === "Yes" ? true : false
                                  }
                                  value="Yes"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="AIASAStudent1"
                                >
                                  Yes
                                </label>

                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="AIASAStudent"
                                  id="AIASAStudent2"
                                  onChange={handleChange}
                                  checked={
                                    list.AIASAStudent === "NO" ? true : false
                                  }
                                  value="NO"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="AIASAStudent2"
                                >
                                  NO
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {list.AIASAStudent === "Yes" && (
                          <>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="Year">Year</label>
                                  {/* <input
                                          id="Year"
                                          type="text"
                                          name="Year"
                                          className="Inputs"
                                          onChange={handleChange}
                                          value={list.Year}
                                          placeholder="Year"
                                          autoComplete="Year"
                                          required
                                        /> */}
                                  <select
                                    ref={txtAdayear}
                                    id="Year"
                                    name="Year"
                                    className="Inputs"
                                    onChange={handleChange}
                                    value={list.Year}
                                    required
                                  >
                                    <option value="">-- Select Year --</option>
                                    {Array.from({ length: 50 }, (_, index) => {
                                      const year =
                                        new Date().getFullYear() - index;
                                      return (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      );
                                    })}
                                  </select>

                                  {listErrors.Year && (
                                    <p className="errors">{listErrors.Year}</p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="CategoryAias">Category</label>

                                  <Select
                                    ref={txtAdacate}
                                    id="CategoryAias"
                                    name="CategoryAias"
                                    value={
                                      list.CategoryAias !== null &&
                                      list.CategoryAias !== ""
                                        ? {
                                            value: list.CategoryAias,
                                            label: list.CategoryAias,
                                          }
                                        : null
                                    }
                                    onChange={(option) =>
                                      handleSelectCH(
                                        option,
                                        "CategoryAias",
                                        "CategoryAias"
                                      )
                                    }
                                    options={
                                      cOption
                                        .filter((item) => item && item.label) // Filter out null and undefined elements
                                        .sort((a, b) =>
                                          a.label.localeCompare(b.label)
                                        ) // Sort options alphabetically by label
                                    }
                                    isSearchable={true} // Enables search functionality
                                    placeholder="Select an option"
                                    maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                    filterOption={(option, searchText) =>
                                      option.label
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase())
                                    } // Filter options based on the search text
                                    styles={customStyles} // Apply custom styles her
                                  />
                                  {listErrors.CategoryAias && (
                                    <p className="errors">
                                      {listErrors.CategoryAias}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="CourseAias">Course</label>

                                  <Select
                                    ref={txtAdacourse}
                                    id="CourseAias"
                                    name="CourseAias"
                                    value={
                                      list.CourseAias !== null &&
                                      list.CourseAias !== ""
                                        ? {
                                            value: list.CourseAias,
                                            label: list.CourseAias,
                                          }
                                        : null
                                    }
                                    onChange={(option) =>
                                      handleSelectCH(
                                        option,
                                        "CourseAias",
                                        "CourseAias"
                                      )
                                    }
                                    options={
                                      courseAias
                                        .filter((item) => item && item.label) // Filter out null and undefined elements
                                        .sort((a, b) =>
                                          a.label.localeCompare(b.label)
                                        ) // Sort options alphabetically by label
                                    }
                                    isSearchable={true} // Enables search functionality
                                    placeholder="Select an option"
                                    maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                    filterOption={(option, searchText) =>
                                      option.label
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase())
                                    } // Filter options based on the search text
                                    styles={customStyles} // Apply custom styles her
                                  />
                                  {listErrors.CourseAias && (
                                    <p className="errors">
                                      {listErrors.CourseAias}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="IDNumber">ID Number</label>
                                  <input
                                    ref={txtAdaIdnum}
                                    id="IDNumber"
                                    type="text"
                                    name="IDNumber"
                                    className="Inputs"
                                    onChange={handleChange}
                                    value={list.IDNumber}
                                    placeholder="ID Number"
                                    autoComplete="ID Number"
                                    required
                                    maxLength={10}
                                    minLength={10}
                                  />
                                  {listErrors.IDNumber && (
                                    <p className="errors">
                                      {listErrors.IDNumber}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="IDPhoto">
                                    Upload ID Card (In pdf format)
                                    {list.IDPhoto !== "" &&
                                      list.IDPhoto !== null && (
                                        <a
                                          href={`${ImageUrlPrefix}/${list.IDPhoto}`}
                                          target="_blank"
                                          download
                                        >
                                          <span className="material-icons align-middle download_icon">
                                            file_download
                                          </span>
                                        </a>
                                      )}
                                  </label>
                                  <div className="row">
                                    <div
                                      className={
                                        list.IDPhoto !== "" &&
                                        list.IDPhoto !== null
                                          ? "col-md-3 "
                                          : "col-md-12"
                                      }
                                    >
                                      <input
                                        ref={txtAdafile}
                                        id="IDPhoto"
                                        type="file"
                                        name="IDPhoto"
                                        placeholder="Upload ID Card"
                                        className={
                                          list.IDPhoto !== "" &&
                                          list.IDPhoto !== null
                                            ? "InputsFile nn_upload_width"
                                            : "InputsFile  vv_upload_width"
                                        }
                                        onChange={handleChange}
                                        autoComplete="IDPhoto"
                                        required
                                      />
                                    </div>
                                    {list.IDPhoto !== "" &&
                                      list.IDPhoto !== null && (
                                        <div
                                          className={
                                            "col-md-9 d-flex align-content-center p-0"
                                          }
                                        >
                                          <span className="nn_upload_file">
                                            {list.IDPhoto !== "" &&
                                            list.IDPhoto !== null
                                              ? list.original_aiasa_id_path
                                              : ""}
                                          </span>
                                          <span className="material-icons align-middle text-danger verified_upload">
                                            verified
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                  {listErrors.IDPhoto && (
                                    <p className="errors">
                                      {listErrors.IDPhoto}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="heading  mb-4 dflexbetww ">
                    <div className="">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)"> Go to Communication</a>
                      </button>
                    </div>
                    <div className="d-flex smButton mobiledown">
                      <Link to="/" className="CancelButton">
                        Back to Home
                      </Link>
                      <button
                        type="button"
                        className="SaveButtons"
                        onClick={handleNext}
                      >
                        {" "}
                        <a href="javascript:void(0)">
                          Save and Continue to Review and Confirm
                        </a>
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </fieldset>
        )}
        {/* {step === 4} */}
        {step === 4 && (
          <fieldset>
            <div className="row">
              <div className="col-md-12">
                <section className=" sectionSix">
                  <div className="heading  dflexbetww ">
                    <div className="">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)">
                          Go to Review and Confirm
                        </a>
                      </button>
                    </div>
                    <div className="d-flex smButton  mobiledown">
                      <Link to="/" className="CancelButton">
                        Back to Home
                      </Link>
                      <button
                        type="button"
                        className="SaveButtons"
                        onClick={handleNexts}
                      >
                        <a href="javascript:void(0)">Submit and Update</a>
                      </button>
                    </div>
                  </div>

                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Basic Details</p>

                      <div className="row">
                        <div className="col-md-9">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  First Name
                                </label>
                                <span className="ReviewLabel">
                                  {list.FirstName || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Last Name
                                </label>
                                <span className="ReviewLabel">
                                  {list.LastName || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-12">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Father's Name/Guardian's Name
                                </label>
                                <span className="ReviewLabel">
                                  {" "}
                                  {list.Fathername || ""}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Gender
                                </label>
                                <span className="ReviewLabel">
                                  {list.gender || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Date of Birth
                                </label>
                                <span className="ReviewLabel">
                                  {list.DOB || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Age
                                </label>
                                <span className="ReviewLabel">
                                  {list.age || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Marital Status
                                </label>
                                <span className="ReviewLabel">
                                  {" "}
                                  {list.MaritalStatus || ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-5">
                          <div className="forms mb-2  ">
                            <div id="profilephotos">
                              {list.Profilephoto && (
                                <img
                                  src={`${ImageUrlPrefix}/${list.Profilephoto}`}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* New Added ----Id proofs review*/}
                <div className="sectionSix">
                  <div className="row">
                    <div className="col-md-6">
                      <section className="sectionSix">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">ID Proofs</p>

                            <div className="row mb-3">
                              <div className="col-lg-4 col-md-12">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    AADHAR Number
                                  </label>
                                  <span className="ReviewLabel">
                                    {list.aadharNo || ""}
                                  </span>
                                </div>
                              </div>

                              <div className="col-lg-8 col-md-12">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Upload AADHAR Card
                                    {list.aadharCardphoto && (
                                      <a
                                        href={`${ImageUrlPrefix}/${list.aadharCardphoto}`}
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </a>
                                    )}
                                  </label>
                                  <span className="ReviewLabel">
                                    {list.original_aadhar_path || ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="col-md-6">
                      <section className="sectionSix ">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">Specimen Signature</p>

                            <div className="row mb-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="forms ">
                                  <img
                                    className="SignatureFile"
                                    src={`${ImageUrlPrefix}/${list.signaturePhoto}`}
                                    alt="Imagefor signature"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>

                {/* New Added --- Communal category */}

                <div className="sectionSix">
                  <div className="row">
                    <div className="col-md-6">
                      <section className="sectionSix ">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">
                              Communal Reservation Category
                            </p>

                            <div className="row mb-3">
                              <div className="col-lg-4 col-md-12">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Category
                                  </label>
                                  <span className="ReviewLabel">
                                    {list.Cast || ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="col-md-6">
                      <section className="sectionSix ">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">
                              Applicable Special Category Preferences
                            </p>
                            <div className="row mb-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Category
                                  </label>
                                  <span className="ReviewLabel">
                                    {appCastLable.length > 0
                                      ? appCastLable
                                          .map((el) => el.label)
                                          .join(",")
                                      : "_" || ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>

                {/* New Added ----Technical Qualification */}
                <div className="sectionSix">
                  <div className="row">
                    {techQualification.length > 0 &&
                      !(
                        techQualification[0].name === "" &&
                        techQualification[0].level === "" &&
                        techQualification[0].lang === "" &&
                        techQualification[0].Cnum === "" &&
                        techQualification[0].grade === "" &&
                        techQualification[0].year === "" &&
                        techQualification[0].file === ""
                      ) &&
                      techQualification.map((el, index) => (
                        <div
                          className={
                            techQualification.length === 1
                              ? "col-md-12 "
                              : "col-md-12 "
                          }
                          key={index}
                        >
                          <section className=" sectionSix">
                            <div className="row box ">
                              <div className="col-md-12 ">
                                <p className="StepTitle">
                                  Technical Qualification{" "}
                                  {techQualification.length === 1
                                    ? " "
                                    : `- ${el.id}`}
                                </p>

                                <div className="row mb-3">
                                  <div className="col-md-4">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Do you possess any technical
                                        qualification?
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.TQ === "0" ? "Yes" : "No" || ""}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-2">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Technical Course
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.TQ === "0" && el.name
                                          ? el.name
                                          : "_"}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Typewriting Level
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.TQ === "0" && el.level
                                          ? el.level
                                          : "_"}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Language
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.TQ === "0" && el.level
                                          ? el.lang
                                          : "_"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      ))}
                  </div>
                </div>
                {/* New Added ----Contact Details */}
                <section className=" sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Contact details</p>

                      <div className="row mb-3">
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Phone Number
                            </label>
                            <span className="ReviewLabel">
                              {list.Mobile || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              WhatsApp Number
                            </label>
                            <span className="ReviewLabel">
                              {list.ContactNumber === true
                                ? list.Mobile
                                : list.WhatsAppNumber}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Father/Guardian Phone Number
                            </label>
                            <span className="ReviewLabel">
                              {list.ParentMobileNo || ""}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Email ID
                            </label>
                            <span className="ReviewLabel">
                              {list.emailaddress || ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* New Added ----Address */}
                <div className="sectionSix">
                  <div className="row">
                    <div className="col-md-6">
                      <section className="sectionSix">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">Present Address</p>

                            <div className="row mb-3">
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Present Address
                                  </label>
                                </div>
                              </div>

                              <span className="ReviewLabel mb-2">
                                {" "}
                                {list.BuildingNumberPresent +
                                  (list.BuildingNumberPresent ? "," : "") || ""}
                                {list.StreetNamePresent +
                                  (list.StreetNamePresent ? "," : "") || ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.AreaPresent +
                                  (list.AreaPresent ? "," : "") || ""}
                                {list.LandmarkPresent +
                                  (list.LandmarkPresent ? "," : "") || ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.TalukPresent +
                                  (list.TalukPresent ? "," : "") || ""}
                                {list.PostPresent +
                                  (list.PostPresent ? "," : "") || ""}
                              </span>

                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.districtPresent +
                                  (list.districtPresent ? "," : "") || ""}
                                {list.statePresent +
                                  (list.statePresent ? "," : "") || ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {list.CityPresent +
                                  (list.CityPresent ? "," : "") || ""}
                                {list.PincodePresent +
                                  (list.PincodePresent ? "" : "") || ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="col-md-6">
                      <section className="sectionSix ">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">Permanent Address</p>

                            <div className="row mb-3">
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    {list.SamePresentAddress === true
                                      ? "Present Address"
                                      : "Permanent Address"}
                                  </label>
                                </div>
                              </div>
                              <span className="ReviewLabel  mb-2">
                                {list.SamePresentAddress === true
                                  ? list.BuildingNumberPresent +
                                    (list.BuildingNumberPresent ? "," : "")
                                  : list.BuildingNumberPermanent +
                                      (list.BuildingNumberPermanent
                                        ? ","
                                        : "") || ""}
                                {list.SamePresentAddress === true
                                  ? list.StreetNamePresent +
                                    (list.StreetNamePresent ? "," : "")
                                  : list.StreetNamePermanent +
                                      (list.StreetNamePermanent ? "," : "") ||
                                    ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.SamePresentAddress === true
                                  ? list.AreaPresent +
                                    (list.AreaPresent ? "," : "")
                                  : list.AreaPermanent +
                                      (list.AreaPermanent ? "," : "") || ""}
                                {list.SamePresentAddress === true
                                  ? list.LandmarkPresent +
                                    (list.LandmarkPresent ? "," : "")
                                  : list.LandmarkPermanent +
                                      (list.LandmarkPermanent ? "," : "") || ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.SamePresentAddress === true
                                  ? list.TalukPresent +
                                    (list.TalukPresent ? "," : "")
                                  : list.TalukPermanent +
                                      (list.TalukPermanent ? "," : "") || ""}
                                {list.SamePresentAddress === true
                                  ? list.PostPresent +
                                    (list.PostPresent ? "," : "")
                                  : list.PostPermanent +
                                      (list.PostPermanent ? "," : "") || ""}
                              </span>

                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.SamePresentAddress === true
                                  ? list.districtPresent +
                                    (list.districtPresent ? "," : "")
                                  : list.districtPermanent +
                                      (list.districtPermanent ? "," : "") || ""}
                                {list.SamePresentAddress === true
                                  ? list.statePresent +
                                    (list.statePresent ? "," : "")
                                  : list.statePermanent +
                                      (list.statePermanent ? "," : "") || ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {list.SamePresentAddress === true
                                  ? list.CityPresent +
                                    (list.CityPresent ? "," : "")
                                  : list.CityPermanent +
                                      (list.CityPermanent ? "," : "") || ""}
                                {list.SamePresentAddress === true
                                  ? list.PincodePresent +
                                    (list.PincodePresent ? "" : "")
                                  : list.PincodePermanent +
                                      (list.PincodePermanent ? "" : "") || ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                {/* Model Exam Enrollment */}
                <section className=" sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Model Exam Enrollment</p>

                      <div className="row mb-3">
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Category
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {list.category || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Course
                            </label>
                            <span className="ReviewLabel">
                              {list.CouresName || ""}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Medium of Exam
                            </label>
                            <span className="ReviewLabel">
                              {list.TeachMedium || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Exam Center District
                            </label>
                            <span className="ReviewLabel">
                              {list.districts_name || "None"}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Mode of Exam
                            </label>
                            <span className="ReviewLabel">
                              {list.classMode || ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {batchDetails[0] !== null &&
                Object.keys(batchDetails).length > 0 ? (
                  <section className=" sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Exam Details</p>
                        {batchDetails.map((el, index) => (
                          <div className="row" key={index}>
                            {el.paper_type === 0 && (
                              <>
                                <div className="col-md-12 mt-3 mb-3">
                                  <div className="card  text-dark">
                                    <div className="card-body BatchImage">
                                      <div className="row">
                                        {/* <div className="col-lg-2 col-md-6 col-sm-12 "> */}
                                        <div className="col-lg-2 col-md-6 col-sm-12 ">
                                          <img
                                            title={el.img_url}
                                            src={`${ImageUrlPrefix}/${el.img_url}`}
                                            id={el.id}
                                            alt="Imge"
                                          />
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column  justify-content-center">
                                          <p
                                            className="title  m-0 "
                                            style={{ width: "initial" }}
                                          >
                                            {el.course_name || ""}
                                            {"-"}
                                            {el.name || ""}
                                          </p>
                                        </div>

                                        <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column  justify-content-center ">
                                          <li className="IconsImage">
                                            <span className="material-icons">
                                              currency_rupee
                                            </span>
                                            <div className="d-flex flex-column">
                                              <p className="st_confirm_label ms-1">
                                                Fee Details
                                              </p>
                                              <p className="st_confirm_value ms-1">
                                                {el.exam_fees
                                                  .filter(
                                                    (fee) =>
                                                      fee.fees_count ===
                                                      el.exam_details.filter(
                                                        (el) =>
                                                          el.is_selected_exam ===
                                                          true
                                                      ).length
                                                  )
                                                  .map((fee, klio) => (
                                                    <React.Fragment key={klio}>
                                                      <span className="batch_fees_text">
                                                        {fee.fees_count} Exams{" "}
                                                        {fee.fees
                                                          ? Number(
                                                              fee.fees
                                                            ).toFixed(2)
                                                          : "_"}
                                                      </span>
                                                      {(klio + 1) % 2 === 0 && (
                                                        <br />
                                                      )}
                                                    </React.Fragment>
                                                  ))}
                                              </p>
                                            </div>
                                          </li>
                                        </div>

                                        {el.exam_details.length > 0 &&
                                          el.exam_details
                                            .filter(
                                              (els) =>
                                                els.is_selected_exam === true
                                            )
                                            ?.sort(
                                              (a, b) =>
                                                new Date(a.started_dt) -
                                                new Date(b.started_dt)
                                            )
                                            ?.map((obh, index) => (
                                              <>
                                                {/* <div className="col-md-1 d-flex justify-content-lg-center mt-sm-4 align-items-center">
                                              <input
                                                ref={txtbatchDet}
                                                className="checkbox_batch"
                                                type="checkbox"
                                                name="is_selected"
                                                id="is_selected_batch"
                                                onChange={(e) =>
                                                  handleBatchDetails(
                                                    el.id,
                                                    obh.pk,
                                                    e
                                                  )
                                                }
                                                checked={
                                                  el.is_selected === true
                                                    ? true
                                                    : false
                                                }
                                                value={el.is_selected}
                                              />
                                            </div> */}
                                                <div className="col-md-12 mt-3 mb-3">
                                                  <div className="card  text-dark">
                                                    <div className="card-body BatchImage">
                                                      <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                                          <div className="row">
                                                            <div className="col-md-12 col-sm-12 ">
                                                              <div className="row">
                                                                <div className="col-md-2">
                                                                  <li className="IconsImage">
                                                                    <span className="material-icons">
                                                                      format_shapes
                                                                    </span>
                                                                    <div className="d-flex flex-column">
                                                                      <p className="st_confirm_label ms-1">
                                                                        Exam
                                                                        Time
                                                                      </p>
                                                                      <p className="st_confirm_value ms-1">
                                                                        {obh.exam_title
                                                                          ? obh.exam_title
                                                                          : "_"}
                                                                      </p>
                                                                    </div>
                                                                  </li>
                                                                </div>
                                                                {/* New added */}
                                                                <div className="col-md-2">
                                                                  <li className="IconsImage">
                                                                    <span className="material-icons">
                                                                      library_books
                                                                    </span>
                                                                    <div className="d-flex flex-column">
                                                                      <p className="st_confirm_label ms-1 ">
                                                                        Syllabus
                                                                      </p>
                                                                      <p className="subtitle ms-1">
                                                                        <a
                                                                          target="_blank"
                                                                          href={`${ImageUrlPrefix}/${obh.syllabus_url}`}
                                                                          className="subtitle_testpdf "
                                                                        >
                                                                          Click
                                                                          here
                                                                          to
                                                                          view
                                                                          syllabus
                                                                        </a>
                                                                      </p>
                                                                    </div>
                                                                  </li>
                                                                </div>
                                                                <div className="col-md-2">
                                                                  <li className="IconsImage">
                                                                    <span className="material-icons-outlined">
                                                                      timer
                                                                    </span>
                                                                    <div className="d-flex flex-column">
                                                                      <p className="st_confirm_label ms-1">
                                                                        Duration
                                                                      </p>
                                                                      <p className="st_confirm_value ms-1">
                                                                        {
                                                                          obh.duration_hrs
                                                                        }
                                                                        hours
                                                                      </p>
                                                                    </div>
                                                                  </li>
                                                                </div>

                                                                <div className="col-md-2">
                                                                  <li className="IconsImage">
                                                                    <span className="material-icons">
                                                                      event
                                                                    </span>
                                                                    <div className="d-flex flex-column ">
                                                                      <p className="st_confirm_label ms-1">
                                                                        Date
                                                                      </p>
                                                                      <p className="st_confirm_value ms-1">
                                                                        {obh.started_dt
                                                                          ? moment
                                                                              .utc(
                                                                                obh.started_dt
                                                                              )
                                                                              .format(
                                                                                "Do MMM YYYY"
                                                                              )
                                                                          : "_"}
                                                                      </p>
                                                                    </div>
                                                                  </li>
                                                                </div>

                                                                <div className="col-md-2">
                                                                  <li className="IconsImage">
                                                                    <span className="material-icons-outlined">
                                                                      timer
                                                                    </span>
                                                                    <div className="d-flex flex-column">
                                                                      <p className="st_confirm_label ms-1">
                                                                        Time
                                                                      </p>
                                                                      <p className="st_confirm_value ms-1">
                                                                        {
                                                                          obh.batch_time
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                  </li>
                                                                </div>
                                                                <div className="col-md-2">
                                                                  <li className="IconsImage">
                                                                    <span className="material-icons">
                                                                      event
                                                                    </span>
                                                                    <div className="d-flex flex-column">
                                                                      <p className="st_confirm_label ms-1">
                                                                        Last
                                                                        Date to
                                                                        Apply
                                                                      </p>
                                                                      <p className="st_confirm_value ms-1">
                                                                        {obh.last_dt_apply
                                                                          ? moment(
                                                                              obh.last_dt_apply
                                                                            ).format(
                                                                              "Do MMM YYYY"
                                                                            )
                                                                          : "_"}
                                                                      </p>
                                                                    </div>
                                                                  </li>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/*  */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {el.paper_type === 1 && (
                              <div className="col-md-12 mt-3 mb-3">
                                <div className="card  text-dark">
                                  <div className="card-body BatchImage">
                                    <div className="row">
                                      {/* <div className="col-lg-2 col-md-6 col-sm-12 "> */}
                                      <div className="col-lg-2 col-md-6 col-sm-12 ">
                                        <img
                                          title={el.img_url}
                                          src={`${ImageUrlPrefix}/${el.img_url}`}
                                          id={el.id}
                                          alt="Imge"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column  justify-content-center">
                                        <p
                                          className="title m-0 "
                                          style={{ width: "initial" }}
                                        >
                                          {el.course_name || ""}
                                          {"-"}
                                          {el.name || ""}
                                        </p>
                                      </div>

                                      <div className="col-lg-2 col-md-6 col-sm-12 d-flex flex-column  justify-content-center ">
                                        <li className="IconsImage">
                                          <span className="material-icons">
                                            currency_rupee
                                          </span>
                                          <div className="d-flex flex-column">
                                            <p className="title ms-1">
                                              Fee Details
                                            </p>
                                            <p className="subtitle ms-1">
                                              {el.exam_fees
                                                .filter(
                                                  (fee) =>
                                                    fee.fees_count ===
                                                    el.exam_details.filter(
                                                      (el) =>
                                                        el.is_selected_exam ===
                                                        true
                                                    ).length
                                                )
                                                .map((fee, kjui) => (
                                                  <React.Fragment key={kjui}>
                                                    <span className="batch_fees_text">
                                                      {fee.fees_count} Exams{" "}
                                                      {fee.fees
                                                        ? Number(
                                                            fee.fees
                                                          ).toFixed(2)
                                                        : "_"}
                                                    </span>
                                                    {(kjui + 1) % 2 === 0 && (
                                                      <br />
                                                    )}
                                                  </React.Fragment>
                                                ))}
                                            </p>
                                          </div>
                                        </li>
                                      </div>
                                      {/* <div className="col-lg-5 col-md-6 col-sm-12 d-flex flex-column  justify-content-center align-items-end">
                                        <p className="title m-0 ">
                                          You've selected no exam, and the fee
                                          is Rs. 0.
                                        </p>
                                        <div>
                                          <input
                                            class="checkbox_batch_add mt-1"
                                            type="checkbox"
                                            name="is_selected_all"
                                            id="is_selected_all"
                                            value={el.is_selected_all}
                                            onChange={(e) =>
                                              handleBatchDetailsSelectAll(
                                                el.id,
                                                e
                                              )
                                            }
                                            checked={
                                              el.is_selected_all === true
                                                ? true
                                                : false
                                            }
                                          />
                                          <span className="checkbox_sentence">
                                            {" "}
                                            Select All Model Exams
                                          </span>
                                        </div>
                                      </div> */}
                                      {el.exam_details.length > 0 &&
                                        el.exam_details
                                          .filter(
                                            (els) =>
                                              els.is_selected_exam === true
                                          )
                                          ?.sort(
                                            (a, b) =>
                                              parseInt(a.exam_title_id) -
                                              parseInt(b.exam_title_id)
                                          )
                                          ?.map((onk, index) => (
                                            <div className="col-md-12 mt-3 mb-3">
                                              <div className="card  text-dark">
                                                <div className="card-body BatchImage">
                                                  <div className="row">
                                                    <div className="col-md-12 col-sm-12 ">
                                                      <div className="row">
                                                        <div className="col-md-3">
                                                          <li className="IconsImage">
                                                            <div className="d-flex flex-column">
                                                              <p className="st_confirm_label ms-1">
                                                                Model Exam Title
                                                              </p>
                                                              <p className="st_confirm_value ms-1">
                                                                {onk.exam_title}
                                                              </p>
                                                            </div>
                                                          </li>
                                                        </div>
                                                        <div className="col-md-3">
                                                          <li className="IconsImage">
                                                            <div className="d-flex flex-column ">
                                                              <p className="st_confirm_label ms-1">
                                                                Number of Papers
                                                              </p>
                                                              <p className="st_confirm_value ms-1">
                                                                {
                                                                  onk.no_of_paper
                                                                }
                                                              </p>
                                                            </div>
                                                          </li>
                                                        </div>
                                                        <div className="col-md-3">
                                                          <li className="IconsImage">
                                                            <div className="d-flex flex-column">
                                                              <p className="st_confirm_label ms-1">
                                                                Last Date to
                                                                Apply
                                                              </p>
                                                              <p className="st_confirm_value ms-1">
                                                                {onk.last_dt_apply
                                                                  ? moment
                                                                      .utc(
                                                                        onk.last_dt_apply
                                                                      )
                                                                      .format(
                                                                        "DD/MM/YYYY"
                                                                      )
                                                                  : ""}
                                                              </p>
                                                            </div>
                                                          </li>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* <div className="col-md-1 d-flex justify-content-lg-center mt-sm-4 align-items-center">
                                                  <input
                                                    ref={txtbatchDet}
                                                    className="checkbox_batch"
                                                    type="checkbox"
                                                    name="is_selected"
                                                    id="is_selected_batch"
                                                    onChange={(e) =>
                                                      handleBatchDetails(
                                                        el.id,
                                                        onk.pk,
                                                        e
                                                      )
                                                    }
                                                    checked={
                                                      el.is_selected === true
                                                        ? true
                                                        : false
                                                    }
                                                    value={el.is_selected}
                                                  />
                                                </div> */}

                                                    {onk.papers
                                                      ?.sort(
                                                        (a, b) =>
                                                          new Date(a.start_dt) -
                                                          new Date(b.start_dt)
                                                      )
                                                      ?.map((vgh, index) => (
                                                        <div className="col-md-11 mt-3 mb-3">
                                                          <div className="card  text-dark">
                                                            <div className="card-body BatchImage">
                                                              <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                                                  <div className="row">
                                                                    <div className="col-md-12 col-sm-12 ">
                                                                      <div className="row">
                                                                        <div className="col-md-2">
                                                                          <li className="IconsImage">
                                                                            <span className="material-icons">
                                                                              format_shapes
                                                                            </span>
                                                                            <div className="d-flex flex-column">
                                                                              <p className="st_confirm_label ms-1">
                                                                                Paper
                                                                                Title
                                                                              </p>
                                                                              <p className="st_confirm_value ms-1">
                                                                                {
                                                                                  vgh.paper_title
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          </li>
                                                                        </div>
                                                                        {/* New added */}
                                                                        <div className="col-md-3">
                                                                          <li className="IconsImage">
                                                                            <span className="material-icons">
                                                                              library_books
                                                                            </span>
                                                                            <div className="d-flex flex-column">
                                                                              <p className="st_confirm_label ms-1">
                                                                                Syllabus
                                                                              </p>
                                                                              <p className="subtitle ms-1">
                                                                                <a
                                                                                  target="_blank"
                                                                                  href={`${ImageUrlPrefix}/${vgh.syllabus_url}`}
                                                                                  className="subtitle_testpdf "
                                                                                >
                                                                                  Click
                                                                                  here
                                                                                  to
                                                                                  view
                                                                                  syllabus
                                                                                </a>
                                                                              </p>
                                                                            </div>
                                                                          </li>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                          <li className="IconsImage">
                                                                            <span className="material-icons-outlined">
                                                                              timer
                                                                            </span>
                                                                            <div className="d-flex flex-column">
                                                                              <p className="st_confirm_label ms-1">
                                                                                Duration
                                                                              </p>
                                                                              <p className="st_confirm_value ms-1">
                                                                                {
                                                                                  vgh.duration
                                                                                }
                                                                                hours
                                                                              </p>
                                                                            </div>
                                                                          </li>
                                                                        </div>

                                                                        <div className="col-md-2">
                                                                          <li className="IconsImage">
                                                                            <span className="material-icons">
                                                                              event
                                                                            </span>
                                                                            <div className="d-flex flex-column ">
                                                                              <p className="st_confirm_label ms-1">
                                                                                Date
                                                                              </p>
                                                                              <p className="st_confirm_value ms-1">
                                                                                {moment
                                                                                  .utc(
                                                                                    vgh.start_dt
                                                                                  )
                                                                                  .format(
                                                                                    "Do MMM YYYY"
                                                                                  )}
                                                                              </p>
                                                                            </div>
                                                                          </li>
                                                                        </div>

                                                                        <div className="col-md-3">
                                                                          <li className="IconsImage">
                                                                            <span className="material-icons-outlined">
                                                                              timer
                                                                            </span>
                                                                            <div className="d-flex flex-column">
                                                                              <p className="st_confirm_label ms-1">
                                                                                Time
                                                                              </p>
                                                                              <p className="st_confirm_value ms-1">
                                                                                {
                                                                                  vgh.test_time
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          </li>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    {index >
                                                                      0 && (
                                                                      <span className="border-exam mt-3"></span>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                                {/*  */}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ))}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                ) : (
                  <section className="  sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Exam Details</p>
                        <div className="row">
                          <div className="col-md-12 mt-3 mb-3">
                            <div className="card  text-dark">
                              <div className="card-body ">
                                <p className="batch_Err">No Batch Found</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
                {/* ----------------------- */}
                <section className=" sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Other Relevant Information</p>

                      <div className="row mb-3">
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted  oneline"
                            >
                              Did you receive your hall ticket from the
                              recruitment board?{" "}
                            </label>
                            <span className="ReviewLabel">
                              {list.hallticket || ""}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted  oneline"
                            >
                              Upload Hall Ticket (Issued by the recruitment
                              board)
                              {list.hallticket === "Yes" &&
                                list.hallExamPhoto && (
                                  <a
                                    download
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${list.hallExamPhoto}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                            </label>
                            <span className="ReviewLabel">
                              {list.hallticket === "Yes"
                                ? list.hallExamPhoto !== null &&
                                  list.hallExamPhoto !== ""
                                  ? list.original_hallExamPhoto
                                  : ""
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Registration Number of the Exam
                            </label>
                            <span className="ReviewLabel mt-2">
                              {list.hallticket === "Yes"
                                ? list.ExamRegNo || ""
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted  oneline"
                              >
                                Are You an AIASA Student?
                              </label>
                              <span className="ReviewLabel">
                                {list.AIASAStudent || ""}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Year
                              </label>
                              <span className="ReviewLabel">
                                {list.AIASAStudent === "Yes"
                                  ? list.Year || ""
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Category
                              </label>
                              <span className="ReviewLabel">
                                {list.AIASAStudent === "Yes"
                                  ? list.CategoryAias || ""
                                  : ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Course
                              </label>
                              <span className="ReviewLabel">
                                {list.AIASAStudent === "Yes"
                                  ? list.CourseAias
                                  : ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                ID Number
                              </label>
                              <span className="ReviewLabel">
                                {list.AIASAStudent === "Yes"
                                  ? list.IDNumber || ""
                                  : ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-5">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted  oneline"
                              >
                                Upload ID Card
                                {list.IDPhoto && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${list.IDPhoto}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                    {/* <span class="material-icons">visibility</span> */}
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                                {list.AIASAStudent === "Yes"
                                  ? list.IDPhoto !== null && list.IDPhoto !== ""
                                    ? list.original_aiasa_id_path
                                    : ""
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {edit != null && (
                    <section className="sectionSix">
                      <div className="row box">
                        <div className="col-md-12">
                          <p className="StepTitle">Review by Admin</p>
                          <div className="row mb-3">
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label mb-2 text-muted  oneline"
                                >
                                  Application Status
                                </label>
                                <span className="ReviewLabel">
                                  {edit == 2 && "Hold"}
                                  {edit == 3 && "Rejected"}
                                  {edit == 0 && "Wait for approval"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                  {edit != null && (
                    <section className="sectionSix">
                      <div className="row box">
                        <div className="col-md-12">
                          <p className="StepTitle">Student Communication</p>

                          <div className="row  mb-3 mt-3">
                            {commentSec.length > 0 &&
                              commentSec.map((el) => (
                                <div className="col-md-12">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label mt-2 mb-2 text-muted  oneline"
                                    >
                                      {el.user_type == 0 && "Comment by Admin"}{" "}
                                      {el.user_type == 1 && "Reply By Student"}{" "}
                                      {el.updated_dt != null &&
                                        `- ${moment(el.updated_dt).format(
                                          "YYYY-MM-DD"
                                        )}`}
                                    </label>
                                    <span className="ReviewLabel">
                                      {el.comment != null || el.comment != ""
                                        ? el.comment
                                        : "None"}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label mb-0 text-muted  oneline"
                                >
                                  {" "}
                                  Reply by Student
                                </label>
                                <textarea
                                  class="Inputs"
                                  ref={txtAdReply_student}
                                  onChange={handleChange}
                                  defaultValue={list.reply_student}
                                  id="reply_student"
                                  rows="4"
                                  name="reply_student"
                                  placeholder="Reply by Student"
                                ></textarea>
                                {listErrors.reply_student && (
                                  <p className="errors">
                                    {listErrors.reply_student}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div
                          className="form-check form-check-inline"
                          id="Verifycheckbox"
                        >
                          <input
                            ref={txtadVerifyHere}
                            className="form-check-input"
                            type="checkbox"
                            id="Verify"
                            name="Verify"
                            onChange={handleChange}
                            checked={list.Verify === true ? true : false}
                            value="Yes"
                          />
                          <label className="form-check-label" htmlFor="Verify">
                            <a
                              className="LablePrivacyText"
                              href="javascript:void(0)"
                            >
                              I hereby certify that the above information are
                              true and correct to the best of my knowledge and
                              belief.
                            </a>
                            <a
                              className="LablePrivacy"
                              href={require("../../../PrivacyPolicyTermsCondition.pdf")}
                              target="_blank"
                            >
                              Terms and Conditions
                            </a>
                          </label>
                          {listErrors.Verify && (
                            <span className="errors align-top ms-3">
                              {listErrors.Verify}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="heading mt-3 mb-4 dflexbetww ">
                        <div className="">
                          <button
                            type="button"
                            className="SaveButton_Prev"
                            onClick={handlePrevious}
                          >
                            <a href="javascript:void(0)">
                              Go to Review and Confirm
                            </a>
                          </button>
                        </div>
                        <div className="d-flex smButton  mobiledown">
                          <Link to="/" className="CancelButton">
                            Back to Home
                          </Link>
                          <button
                            type="button"
                            className="SaveButtons"
                            onClick={handleNexts}
                          >
                            <a href="javascript:void(0)">Submit and Update</a>
                          </button>
                        </div>
                      </div>
                    </section>
                  )}
                </section>
              </div>
            </div>
          </fieldset>
        )}
        {/* {step === 5} previous code  */}
        {step === 5 && (
          <fieldset>
            <div className=" sectionSix">
              {batchDetails
                .filter((item) => {
                  if (item.is_selected === true) {
                    item.exam_details = item.exam_details.filter(
                      (el) => el.is_selected_exam === true
                    );
                    return item;
                  }
                })
                .map((itk, index) => (
                  <>
                    {" "}
                    <div className="heading mb-4 dflexbetww " key={index}>
                      <div className="">
                        <button
                          type="button"
                          className="SaveButton_Prev"
                          onClick={handlePrevious}
                        >
                          <a href="javascript:void(0)">
                            Go to Review Information
                          </a>
                        </button>
                      </div>
                      <div className="d-flex smButton  mobiledown">
                        <Link to="/" className="CancelButton">
                          Back to Home
                        </Link>
                        <button
                          type="button"
                          className="SaveButtons"
                          onClick={() =>
                            handleSubmit(
                              itk.id,
                              ActualFees - couponApplied.amount - dis,
                              ActualFees - couponApplied.amount - dis,
                              ActualFees -
                                couponApplied.amount -
                                dis +
                                0.18 *
                                  (ActualFees - couponApplied.amount - dis),
                              itk.course_name,
                              list.CourseCate
                            )
                          }
                        >
                          <a href="javascript:void(0)">
                            Save and Continue to Payment Gateway
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-12">
                        <div className="row" id={itk.id} key={index}>
                          <div className="col-lg-6 col-md-12">
                            <div className="card h-100">
                              <div className="row h-100">
                                <div className="col-md-5 d-flex flex-column  justify-content-center align-center mt-4">
                                  <img
                                    className="payment"
                                    src={`${ImageUrlPrefix}/${itk.img_url}`}
                                    alt="course_book"
                                    style={{
                                      width: "130px",
                                      height: "130px",
                                      alignSelf: "center",
                                    }}
                                  />
                                  <h5 className="card-title payment_course_name">
                                    {itk.course_name || ""} {"-"}{" "}
                                    {itk.name || ""}
                                  </h5>
                                </div>
                                <div className="col-lg-7 mt-3 mb-2 col-md-10 col-sm-12 col-xl-7">
                                  {itk.exam_details
                                    .filter(
                                      (esl) => esl.is_selected_exam === true
                                    )
                                    .map((items, index) => (
                                      <>
                                        <div
                                          className="row mt-2 mb-2"
                                          id={
                                            itk.exam_details.length !==
                                            index + 1
                                              ? "BorderBathc"
                                              : ""
                                          }
                                        >
                                          <div className="col-md-12 col-sm-12 ">
                                            <div className="row">
                                              <div className="col-md-7">
                                                <li className="IconsImage">
                                                  <span className="material-icons">
                                                    format_shapes
                                                  </span>
                                                  <div className="d-flex flex-column">
                                                    <p className="st_confirm_label ms-1">
                                                      Exam Title
                                                    </p>
                                                    <p className="st_confirm_value ms-1">
                                                      {items.exam_title}
                                                    </p>
                                                  </div>
                                                </li>
                                              </div>
                                              <div className="col-md-5">
                                                <li className="IconsImage">
                                                  <span className="material-icons">
                                                    event
                                                  </span>
                                                  <div className="d-flex flex-column ">
                                                    <p className="st_confirm_label ms-1">
                                                      Date
                                                    </p>
                                                    <p className="st_confirm_value ms-1">
                                                      {moment
                                                        .utc(items.start_on)
                                                        .format("YYYY-MM-DD")}
                                                    </p>
                                                  </div>
                                                </li>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <span className="border mt-3"></span> */}
                                          <div className="col-md-12 col-sm-12">
                                            <div className="row mt-3">
                                              <div className="col-md-7">
                                                <li className="IconsImage">
                                                  <span class="material-icons-outlined">
                                                    timer
                                                  </span>
                                                  <div className="d-flex flex-column">
                                                    <p className="st_confirm_label ms-1">
                                                      Time
                                                    </p>
                                                    <p className="st_confirm_value ms-1">
                                                      {items.batch_time}
                                                    </p>
                                                  </div>
                                                </li>
                                              </div>
                                              <div className="col-md-5">
                                                <li className="IconsImage">
                                                  <span className="material-icons-outlined">
                                                    timer
                                                  </span>
                                                  <div className="d-flex flex-column">
                                                    <p className="st_confirm_label ms-1">
                                                      Duration
                                                    </p>
                                                    <p className="st_confirm_value ms-1">
                                                      {items.duration_hrs} Hours
                                                    </p>
                                                  </div>
                                                </li>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="card h-100">
                              <div className="row mt-4">
                                <div className="col-6 col-sm-5 col-lg-5">
                                  <h6 className="Payment_labelInfo">
                                    Actual Course Fee
                                  </h6>

                                  <h6 className="payment_fees_name">
                                    Coupon Code
                                  </h6>
                                  <h6 className="payment_fees_name payment_fees_name_mobile">
                                    Old Student Discount
                                  </h6>
                                  <h6
                                    className="Payment_labelInfo"
                                    style={{ paddingTop: "9px" }}
                                  >
                                    Total Discount
                                  </h6>
                                  {couponApplied.status === true && (
                                    <h6 className="payment_fees_name">
                                      Coupon
                                    </h6>
                                  )}
                                  {couponApplied.status === true && (
                                    <h6 className="payment_fees_name">
                                      Coupon Applied
                                      <CurrencyRupee className="payment_currency" />
                                      <strike>{ActualFees}</strike>
                                    </h6>
                                  )}
                                  <h6 className="payment_fees_name">
                                    GST(18%)
                                  </h6>

                                  <h6
                                    className="Payment_labelInfo"
                                    id="remain_fee"
                                  >
                                    Net Amount To Pay
                                  </h6>
                                </div>
                                <div className="col-6 col-sm-5 col-lg-7">
                                  <h6 className="Payment_labelInfos">
                                    <CurrencyRupee className="payment_currency" />
                                    {itk.exam_fees.map((mm) => {
                                      const selectedPanelCount =
                                        itk.exam_details.filter(
                                          (panel) => panel.is_selected_exam
                                        ).length;
                                      if (
                                        selectedPanelCount === mm.fees_count
                                      ) {
                                        ActualFees = mm.fees;
                                        return mm.fees; // Convert fees to string if you need it as a string
                                      } else {
                                        return 0;
                                      }
                                    })}
                                  </h6>
                                  <div className="d-flex coupon_box">
                                    <input
                                      type="text"
                                      id="CouponCode"
                                      name="CouponCode"
                                      onChange={handleChange}
                                      value={couponApplied.coupon_code}
                                      className="payment_fees payment_input payment_fees_mobile gap-2"
                                      placeholder="Coupon Code"
                                    />
                                    &nbsp;&nbsp;
                                    <button
                                      className="btn payment payment_coupen"
                                      type="button"
                                      onClick={(e) =>
                                        handleCouponCode(e, ActualFees, itk.id)
                                      }
                                    >
                                      Apply Coupon Code
                                    </button>
                                  </div>
                                  {couponApplied.status === true && (
                                    <h6 className="payment_fees">
                                      {/* <span className="text-danger  fw-bold">{couCode.CouponCode}</span> */}
                                      <CurrencyRupee className="payment_currency" />
                                      <span className="text-success fw-bold ">
                                        {couponApplied.amount}
                                      </span>

                                      <span className="text-success ms-2">
                                        <strike>Coupon applied</strike>
                                      </span>
                                    </h6>
                                  )}

                                  {couponApplied.status === true && (
                                    <h6 className="payment_fees">
                                      <CurrencyRupee className="payment_currency" />
                                      <span className="text-danger fw-semibold">
                                        {" "}
                                        {ActualFees - couponApplied.amount}
                                      </span>
                                    </h6>
                                  )}
                                  <h6 className="payment_fees">
                                    <CurrencyRupee className="payment_currency" />
                                    <span>
                                      {getDisocount(itk.id)} {dis}
                                    </span>
                                    <span
                                      className={
                                        dis === 0
                                          ? "oldstdmsg"
                                          : "oldstdmsg_hide"
                                      }
                                    >
                                      (For this batch, there is no discount
                                      available for old students)
                                    </span>
                                  </h6>
                                  <h6 className="Payment_labelInfos">
                                    <CurrencyRupee className="payment_currency" />
                                    <span>
                                      {/* {itk.exam_details.reduce(
                                          (total, el) => total + el.exam_fees,
                                          0
                                        ) * (dis/100)} */}

                                      {ActualFees - couponApplied.amount - dis}
                                    </span>
                                  </h6>

                                  <h6 className="payment_fees">
                                    <CurrencyRupee className="payment_currency" />
                                    <span className="text-danger fw-semibold">
                                      {" "}
                                      {Math.ceil(
                                        (ActualFees -
                                          couponApplied.amount -
                                          dis) *
                                          0.18
                                      )}
                                    </span>
                                  </h6>
                                  <h6 className="Payment_labelInfos">
                                    <CurrencyRupee className="payment_currency" />
                                    <span>
                                      {Math.ceil(
                                        ActualFees -
                                          couponApplied.amount -
                                          dis +
                                          0.18 *
                                            (ActualFees -
                                              couponApplied.amount -
                                              dis)
                                      )}
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="heading mt-2 mb-4 dflexbetww ">
                      <div className="">
                        <button
                          type="button"
                          className="SaveButton_Prev"
                          onClick={handlePrevious}
                        >
                          <a href="javascript:void(0)">
                            Go to Review Information
                          </a>
                        </button>
                      </div>
                      <div className="d-flex smButton  mobiledown">
                        <Link to="/" className="CancelButton">
                          Back to Home
                        </Link>
                        <button
                          type="button"
                          className="SaveButtons"
                          onClick={() =>
                            handleSubmit(
                              itk.id,
                              ActualFees - couponApplied.amount - dis,
                              ActualFees - couponApplied.amount - dis,
                              ActualFees -
                                couponApplied.amount -
                                dis +
                                0.18 *
                                  (ActualFees - couponApplied.amount - dis),
                              itk.course_name,
                              list.CourseCate
                            )
                          }
                        >
                          <a href="javascript:void(0)">
                            Save and Continue to Payment Gateway
                          </a>
                        </button>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </fieldset>
        )}
        {/* {step === 5} */}
        {step === 5 && (
          <div className=" sectionSeven">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <div className="stepSeven">
                  <p className="psubtitle">{batchMessage || ""}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default ReviewModelexam;
