import React,{useContext} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserDetails';

function AuthUser({ children }) {
    let { user } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    let from = location.state?.form?.pathname  || "/";
    if (user.user_id) {
      // Redirect to dashboard if user is already authenticated
      return navigate(from,{replace:true});
    }
    else if(!user.user_id === "undefined"){

        return navigate("/login");

    }
     return children;
  }

  
export default AuthUser;
  