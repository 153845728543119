import React from "react";
import "./Question.css";

//display type 8 means -  image  mcq design
// sample design

function Qtypenine(props) {

  // console.log(props,"props");
  const { index } = props;

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  return (
    <div className=" Question_temp">
      {props.list.en_question !==null && props.list.en_question !=="" && (
        <div className="row">
          <div className="col-md-12">
            <span className="question_labels">
              {EsplitLines.length > 0 ? EsplitLines[0] : ""}
            </span>
          </div>
          {EsplitLines.map((line) => {
            if (line.startsWith("image - ")) {
              return (
                <div className="col-md-12" key={line}>
                  <img
                    className="imgtnss"
                    src={line.substring(8)}
                    alt="Question Image"
                  />
                </div>
              );
            }
          })}
          <div className="col-md-7  mt-4">
            <div className="row">
              <div className="col-md-12">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_1)}
                    name={`q_answer_${index}_en`}
                    value={props.list.options_en_1}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(A)</span>{" "}
                  <img
                    className="imgtnss_op"
                    src={`${props.list.options_en_1}`}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="forms_input">
                  <input
                    type="radio"
                    defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_2)}
                    className="radioInput"
                    name={`q_answer_${index}_en`}
                    value={props.list.options_en_2}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(B)</span>{" "}
                  <img
                    className="imgtnss_op"
                    src={`${props.list.options_en_2}`}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="forms_input">
                  <input
                    type="radio"
                    defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_3)}
                    className="radioInput"
                    name={`q_answer_${index}_en`}
                    value={props.list.options_en_3}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(C)</span>{" "}
                  <img
                    className="imgtnss_op"
                    src={`${props.list.options_en_3}`}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}_en`}
                    defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_4)}
                    value={props.list.options_en_4}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(D)</span>{" "}
                  <img
                    className="imgtnss_op"
                    src={`${props.list.options_en_4}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.list.tm_question !==null && props.list.tm_question !=="" && (
        <div className="row tamilQues">
          <div className="col-md-12">
          <span className="question_labels">
              {splitLines.length > 0 ? splitLines[0] : ""}
            </span>
          </div>
          {splitLines.map((line) => {
            if (line.startsWith("image - ")) {
              return (
                <div className="col-md-12" key={line}>
                  <img
                    className="imgtnss"
                    src={line.substring(8)}
                    alt="Question Image"
                  />
                </div>
              );
            }
          })}
          <div className="col-md-7 mt-4">
            <div className="row">
              <div className="col-md-12">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_1)}
                    name={`q_answer_${index}_tm`}
                    value={props.list.options_tm_1}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(A)</span>
                  <img
                    className="imgtnss_op"
                    src={`${props.list.options_tm_1}`}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_2)}
                    name={`q_answer_${index}_tm`}
                    value={props.list.options_tm_2}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(B)</span>
                  <img
                    className="imgtnss_op"
                    src={`${props.list.options_tm_2}`}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_3)}
                    name={`q_answer_${index}_tm`}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    value={props.list.options_tm_3}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(C)</span>
                  <img
                    className="imgtnss_op"
                    src={`${props.list.options_tm_3}`}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    defaultChecked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_4)}
                    name={`q_answer_${index}_tm`}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    value={props.list.options_tm_4}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(D)</span>
                  <img
                    className="imgtnss_op"
                    src={`${props.list.options_tm_4}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Qtypenine;
