import React from "react";
import "./Question.css";

//display type 10 and para_que 2   means - para question  mcq design

function Qquesparatype(props) {
  // console.log(props);
  const { index } = props;
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  return (
    <div className="Question_temp ">
      {props.list.en_question !==null && props.list.en_question !=="" && (
        <div className="row">
          <div className="col-md-12">
          <span className="question_labels">{props.list.en_question}</span>
          </div>
          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-md-6">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_1)}
                    value={props.list.options_en_1}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChanges(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1,
                        props.parent_pk
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">
                    (A) {props.list.options_en_1}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_2)}
                    value={props.list.options_en_2}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChanges(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1,
                        props.parent_pk
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">
                    (B) {props.list.options_en_2}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_3)}
                    name={`q_answer_${index}`}
                    value={props.list.options_en_3}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChanges(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1,
                        props.parent_pk
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">
                    (C) {props.list.options_en_3}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_4)}
                    name={`q_answer_${index}`}
                    value={props.list.options_en_4}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChanges(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1,
                        props.parent_pk
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">
                    (D) {props.list.options_en_4}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.list.tm_question !==null && props.list.tm_question !=="" && (
        <div className="row tamilQues">
          <div className="col-md-12">
          <span className="question_labels">{props.list.tm_question}</span>
          </div>
          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-md-6">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_1)}
                    value={props.list.options_tm_1}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChanges(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0,
                        props.parent_pk
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">
                    (A){props.list.options_tm_1}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_2)}
                    name={`q_answer_${index}`}
                    value={props.list.options_tm_2}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChanges(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0,
                        props.parent_pk
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">
                    (B) {props.list.options_tm_2}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_3)}
                    name={`q_answer_${index}`}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    value={props.list.options_tm_3}
                    onChange={(e) =>
                      props.handleOptionChanges(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0,
                        props.parent_pk
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">
                    (C) {props.list.options_tm_3}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_4)}
                    name={`q_answer_${index}`}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    value={props.list.options_tm_4}
                    onChange={(e) =>
                      props.handleOptionChanges(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0,
                        props.parent_pk
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">
                    (D) {props.list.options_tm_4}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Qquesparatype;
