import React from "react";
import "../question/Question.css";

//display type 7 means - multiple option  mcq design
// sample design
// பொருத்தமான விடையைத் தேர்க.
// a) எதிர் மறுத்துக் கூறல்	1. உற்றது உரைத்தல் விடை
// b) உடன்பட்டுக் கூறல்	2. இனமொழி விடை
// c) நேர்ந்ததைக் கூறல்	3. நேர்விடை
// d) இனமானதைக் கூறல்	4. மறைவிடை
// 	a	b	c	d
// A	4	1	2	3
// B	4	3	1	2
// C	4	2	3	1
// D	4	3	2	1

function RtypeEight(props) {
  const { index } = props;

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  const optionList_1 =
    props.list.options_en_1 !=="" && props.list.options_en_1 !==null
      ? props.list.options_en_1.split("\n")
      : [];
  const optionList_2 =
    props.list.options_en_2 !=="" && props.list.options_en_2 !==null
      ? props.list.options_en_2.split("\n")
      : [];
  const optionList_3 =
    props.list.options_en_3 !=="" && props.list.options_en_3 !==null
      ? props.list.options_en_3.split("\n")
      : [];
  const optionList_4 =
    props.list.options_en_4 !=="" && props.list.options_en_4 !==null
      ? props.list.options_en_4.split("\n")
      : [];

  const optionList_tm_1 =
    props.list.options_tm_1 !=="" && props.list.options_tm_1 !==null
      ? props.list.options_tm_1.split("\n")
      : [];
  const optionList_tm_2 =
    props.list.options_tm_2 !=="" && props.list.options_tm_2 !==null
      ? props.list.options_tm_2.split("\n")
      : [];
  const optionList_tm_3 =
    props.list.options_tm_3 !=="" && props.list.options_tm_3 !==null
      ? props.list.options_tm_3.split("\n")
      : [];
  const optionList_tm_4 =
    props.list.options_tm_4 !=="" && props.list.options_tm_4 !==null
      ? props.list.options_tm_4.split("\n")
      : [];
  return (
    <div className=" Question_temp">
      {props.list.en_question !==null && props.list.en_question !=="" && (
        <div className="row">
          <div className="col-md-12">
            <span className="question_labels">
              {EsplitLines.length > 0 ? EsplitLines[0] : ""}
            </span>
          </div>

          {EsplitLines.map((line, index) => {
            if (index !== 0 && line.trim() !== "") {
              return (
                <div className="col-md-6 mt-2" key={index}>
                  <span className="question_labels">{line}</span>
                </div>
              );
            }
          })}

          <div className="col-md-8">
            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="row">
                  <div class={props.type != "1" ? "col-md-2" : "col-md-1"}>
                    <span className="abcd_label"></span>
                  </div>
                  <div class="col-md-1">
                    <span className="abcd_label">a</span>
                  </div>
                  <div class="col-md-1">
                    <span className="abcd_label">b</span>
                  </div>
                  <div class="col-md-1">
                    <span className="abcd_label">c</span>
                  </div>
                  <div class="col-md-1">
                    <span className="abcd_label">d</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="row">
                  <div class={props.type != "1" ? "col-md-2" : "col-md-1"}>
                    {props.type != "1" && (
                      <input
                        type="radio"
                        className="radioInput"
                        name={`q_answer_${index}`}
                        checked={
                          props.list.test_lang === 1 &&
                          props.list.user_answer !==null &&
                          props.list.user_answer_column !==null &&
                          props.list.user_answer === props.list.options_en_1
                        }
                        value={props.list.options_en_1}
                      />
                    )}{" "}
                    <span className="answer_label">{"A)"}</span>
                  </div>
                  {optionList_1.map((line, index) => (
                    <div className="col-md-1" key={index}>
                      <span className="answer_label">{line}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="row">
                  <div class={props.type != "1" ? "col-md-2" : "col-md-1"}>
                    {props.type != "1" && (
                      <input
                        type="radio"
                        className="radioInput"
                        name={`q_answer_${index}`}
                        value={props.list.options_en_2}
                        checked={
                          props.list.test_lang === 1 &&
                          props.list.user_answer !==null &&
                          props.list.user_answer_column !==null &&
                          props.list.user_answer === props.list.options_en_2
                        }
                      />
                    )}{" "}
                    <span className="answer_label">{"B)"}</span>
                  </div>
                  {optionList_2.map((line, index) => (
                    <div className="col-md-1" key={index}>
                      <span className="answer_label">{line}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="row">
                  <div class={props.type != "1" ? "col-md-2" : "col-md-1"}>
                    {props.type != "1" && (
                      <input
                        type="radio"
                        className="radioInput"
                        name={`q_answer_${index}`}
                        value={props.list.options_en_3}
                        checked={
                          props.list.test_lang === 1 &&
                          props.list.user_answer !==null &&
                          props.list.user_answer_column !==null &&
                          props.list.user_answer === props.list.options_en_3
                        }
                      />
                    )}{" "}
                    <span className="answer_label">{"C)"}</span>
                  </div>
                  {optionList_3.map((line, index) => (
                    <div className="col-md-1" key={index}>
                      <span className="answer_label">{line}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="row">
                  <div class={props.type != "1" ? "col-md-2" : "col-md-1"}>
                    {props.type != "1" && (
                      <input
                        type="radio"
                        className="radioInput"
                        name={`q_answer_${index}`}
                        value={props.list.options_en_4}
                        checked={
                          props.list.test_lang === 1 &&
                          props.list.user_answer !==null &&
                          props.list.user_answer_column !==null &&
                          props.list.user_answer === props.list.options_en_4
                        }
                      />
                    )}{" "}
                    <span className="answer_label">{"D)"}</span>
                  </div>
                  {optionList_4.map((line, index) => (
                    <div className="col-md-1" key={index}>
                      <span className="answer_label">{line}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4"></div>

          {/* {props.type != "1" && (
            <div className="col-md-12 mt-3">
              <h6 className="user_answer">Your Answer</h6>
              <span className="user_label_answer">
                {props.type != "1"
                  ? props.list.user_answer_column === "options_en_1" ||
                    props.list.user_answer_column === "options_tm_1"
                    ? "A"
                    : props.list.user_answer_column === "options_en_2" ||
                      props.list.user_answer_column === "options_tm_2"
                    ? "B"
                    : props.list.user_answer_column === "options_en_3" ||
                      props.list.user_answer_column === "options_tm_3"
                    ? "C"
                    : props.list.user_answer_column === "options_en_4" ||
                      props.list.user_answer_column === "options_tm_4"
                    ? "D"
                    : "_"
                  : props.list.answer_en}
              </span>
            </div>
          )}
          <div className="col-md-12 mt-3">
            <h6 className="user_answer">Correct Answer</h6>
            <span className="user_label_answer">
              {props.type != "1"
                ? props.list.correct_answer_column === "options_en_1" ||
                  props.list.correct_answer_column === "options_tm_1"
                  ? "A"
                  : props.list.correct_answer_column === "options_en_2" ||
                    props.list.correct_answer_column === "options_tm_2"
                  ? "B"
                  : props.list.correct_answer_column === "options_en_3" ||
                    props.list.correct_answer_column === "options_tm_3"
                  ? "C"
                  : props.list.correct_answer_column === "options_en_4" ||
                    props.list.correct_answer_column === "options_tm_4"
                  ? "D"
                  : "_"
                : props.list.answer_en}
            </span>
          </div> */}
        </div>
      )}

      {props.list.tm_question !==null && props.list.tm_question !=="" && (
        <div className="row tamilQues">
          <div className="col-md-12">
            <span className="question_labels">
              {splitLines.length > 0 ? splitLines[0] : ""}
            </span>
          </div>
          {splitLines.map((line, index) => {
            if (index !== 0 && line.trim() !== "") {
              return (
                <div className="col-md-6 mt-2" key={index}>
                  <span className="question_labels">{line}</span>
                </div>
              );
            }
          })}

          <div className="col-md-8">
            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="row">
                  <div class={props.type != "1" ? "col-md-2" : "col-md-1"}>
                    <span className="abcd_label"></span>
                  </div>
                  <div class="col-md-1">
                    <span className="abcd_label">a</span>
                  </div>
                  <div class="col-md-1">
                    <span className="abcd_label">b</span>
                  </div>
                  <div class="col-md-1">
                    <span className="abcd_label">c</span>
                  </div>
                  <div class="col-md-1">
                    <span className="abcd_label">d</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="row">
                  <div class={props.type != "1" ? "col-md-2" : "col-md-1"}>
                    {props.type != "1" && (
                      <input
                        className="radioInput"
                        type="radio"
                        name={`q_answer_${index}`}
                        checked={
                          props.list.test_lang === 0 &&
                          props.list.user_answer !==null &&
                          props.list.user_answer_column !==null &&
                          props.list.user_answer === props.list.options_tm_1
                        }
                        value={props.list.options_tm_1}
                      />
                    )}{" "}
                    <span className="answer_label">{"A)"}</span>
                  </div>
                  {optionList_tm_1.map((line, index) => (
                    <div className="col-md-1" key={index}>
                      <span className="answer_label">{line}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="row">
                  <div class={props.type != "1" ? "col-md-2" : "col-md-1"}>
                    {props.type != "1" && (
                      <input
                        type="radio"
                        className="radioInput"
                        name={`q_answer_${index}`}
                        checked={
                          props.list.test_lang === 0 &&
                          props.list.user_answer !==null &&
                          props.list.user_answer_column !==null &&
                          props.list.user_answer === props.list.options_tm_2
                        }
                        value={props.list.options_tm_2}
                      />
                    )}{" "}
                    <span className="answer_label">{"B)"}</span>
                  </div>
                  {optionList_tm_2.map((line, index) => (
                    <div className="col-md-1" key={index}>
                      <span className="answer_label">{line}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="row">
                  <div class={props.type != "1" ? "col-md-2" : "col-md-1"}>
                    {props.type != "1" && (
                      <input
                        type="radio"
                        className="radioInput"
                        name={`q_answer_${index}`}
                        checked={
                          props.list.test_lang === 0 &&
                          props.list.user_answer !==null &&
                          props.list.user_answer_column !==null &&
                          props.list.user_answer === props.list.options_tm_3
                        }
                        value={props.list.options_tm_3}
                      />
                    )}{" "}
                    <span className="answer_label">{"C)"}</span>
                  </div>
                  {optionList_tm_3.map((line, index) => (
                    <div className="col-md-1" key={index}>
                      <span className="answer_label">{line}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="row">
                  <div class={props.type != "1" ? "col-md-2" : "col-md-1"}>
                    {props.type != "1" && (
                      <input
                        type="radio"
                        className="radioInput"
                        name={`q_answer_${index}`}
                        checked={
                          props.list.test_lang === 0 &&
                          props.list.user_answer !==null &&
                          props.list.user_answer_column !==null &&
                          props.list.user_answer === props.list.options_tm_4
                        }
                        value={props.list.options_tm_4}
                      />
                    )}{" "}
                    <span className="answer_label">{"D)"}</span>
                  </div>
                  {optionList_tm_4.map((line, index) => (
                    <div className="col-md-1" key={index}>
                      <span className="answer_label">{line}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4"></div>

          {/* {props.type != "1" && (
            <div className="col-md-12 mt-3">
              <h6 className="user_answer">உங்கள் விடை</h6>
              <span className="user_label_answer">
                {props.type !== "1"
                  ? props.list.user_answer_column === "options_en_1" ||
                    props.list.user_answer_column === "options_tm_1"
                    ? "A"
                    : props.list.user_answer_column === "options_en_2" ||
                      props.list.user_answer_column === "options_tm_2"
                    ? "B"
                    : props.list.user_answer_column === "options_en_3" ||
                      props.list.user_answer_column === "options_tm_3"
                    ? "C"
                    : props.list.user_answer_column === "options_en_4" ||
                      props.list.user_answer_column === "options_tm_4"
                    ? "D"
                    : "_"
                  : props.list.answer_tm}
              </span>
            </div>
          )}
          <div className="col-md-12 mt-3">
            <h6 className="user_answer">சரியான விடை</h6>
            <span className="user_label_answer">
              {props.type != "1"
                ? props.list.correct_answer_column === "options_en_1" ||
                  props.list.correct_answer_column === "options_tm_1"
                  ? "A"
                  : props.list.correct_answer_column === "options_en_2" ||
                    props.list.correct_answer_column === "options_tm_2"
                  ? "B"
                  : props.list.correct_answer_column === "options_en_3" ||
                    props.list.correct_answer_column === "options_tm_3"
                  ? "C"
                  : props.list.correct_answer_column === "options_en_4" ||
                    props.list.correct_answer_column === "options_tm_4"
                  ? "D"
                  : "_"
                : props.list.answer_tm}
            </span>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default RtypeEight;
