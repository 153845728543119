// ModalComponent.js
import React from "react";
import "../../../../pages/dashboard/Modelcss.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";

function GenarateAttentenceSheet(props) {
  const [formData, setFormData] = useState([
    {
      examTitle: "",
      Date: "",
      HallTStatus: "",
    },
  ]);
  const navigate = useNavigate();
  const [error, setError] = useState({});
  //   onHide={handleClose}
  console.log(props, "props.hallTicketData");
  return (
    <Modal show={props.show} onHide={props.handleHallClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 id="exampleModalLabel" className="HallModelTitle">
            Download Attendance Sheet
            {/* Generate AIASA's Hall Ticket - {props.hallTicketData.length > 0 && props.hallTicketData[0].paper_type === 0 ? "Single Papers" : "Combined Papers" }  */}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-0">
              <span className="HallText">
                {/* {props.hallTicketData.length > 0 &&  props.hallTicketData[0].paper_type === 0 ? `Are you sure you want to generate the AIASA's hall ticket for
                the model exam titled HISTORY scheduled for ${props.hallTicketData.length > 0 && props.hallTicketData[0].started_dt ? moment(props.hallTicketData[0].started_dt).format("DD/MM/YYYY"): "_"}?` : `Are you sure you want to generate the AIASA's hall ticket for the model exam titled ${props.hallTicketData.length > 0 && props.hallTicketData[0].paper_title}?`}  */}
                Are You sure you want to download the attendance sheet ?
              </span>
            </div>
          </div>
          {/* <div className="row mt-4">
            <div className="col-md-3 ">
              <div className="forms ">
                <p htmlFor="examNumber" className="hallTLables">
                  {props.hallTicketData.length > 0 && props.hallTicketData[0].paper_type === 0  ?  "Exam Title": "Model Exam Title" }
                </p>
                <p className="HallParaText">{props.hallTicketData.length > 0 && props.hallTicketData[0].paper_type === 0  ? `${props.hallTicketData.length > 0 && props.hallTicketData[0].exam_title}`: `${props.hallTicketData.length > 0 && props.hallTicketData[0].paper_title}`}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="forms ">
                <p htmlFor="examNumber" className="hallTLables">
                  {props.hallTicketData.length > 0 && props.hallTicketData[0].paper_type === 0  ?  "Date": "Number of Papers" }
                  
                </p>
                <p className="HallParaText"> { props.hallTicketData.length > 0 && props.hallTicketData[0].paper_type === 0  ? `${props.hallTicketData.length > 0 && props.hallTicketData[0].started_dt ? moment(props.hallTicketData[0].started_dt).format("DD/MM/YYYY") : "_"}`: `${props.hallTicketData.length > 0 && props.hallTicketData[0].no_of_paper}`} </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="forms ">
                <p htmlFor="examNumber" className="hallTLables">
                  Hall Ticket Status
                </p>
                <p className="HallParaText">{props.hallTicketData.length > 0 && props.hallTicketData[0].hall_ticket_status === 0 ? "Not Yet Generated": "Generated" }</p>
              </div>
            </div>
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link
          data-bs-dismiss="modal"
          onClick={props.handleHallClose}
          className="DashboardCancelButton"
        >
          Back to View Model Exam Batch
        </Link>
        <button
          type="button"
          className="DashboardButton"
          onClick={() =>
            props.handleMainHallThicket(
              props.hallTicketData.length > 0 &&
                props.hallTicketData[0].exam_detail_cstm_pk
            )
          }
        >
          <a href="javascript:void(0)">Download</a>
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default GenarateAttentenceSheet;
