export const LBL_BASIC_DETAILS = "Basic Details";
export const LBL_ID_PROOFS = "ID Proofs";
export const LBL_CONTACT_DETAILS = "Contact details";

export const R_PER_PAGE = 10; // all tables per page count

// img url for node js | react js

// export const ImageUrlPrefix ="https://annaiasacademy.com/api";

// export const ImageUrlPrefix ="https://annaiasacademy.com/api";
export const ImageUrlPrefix = "https://annaiasacademy.com/api";

// https://annaiasacademy.com

// export const ImageUrlPrefix ="https://annaiasacademy.com/api";

// payment secret key

// live key from aiasa  ==>  rzp_live_L7ooVeMu3Cr6KY

// test key from bharatclouds -->   rzp_test_c0hHzXEKePlGCL

// key_id: 'rzp_test_c0hHzXEKePlGCL',
//   key_secret: 'UvGYjU9s2fpftiqyT4HM2fKH'  from bharat clouds

// export const PaymentSKey ="rzp_live_L7ooVeMu3Cr6KY";

export const PaymentSKey = "rzp_live_L7ooVeMu3Cr6KY";

// microsoft teams client id

// 2ce154ce-34ed-46d2-9227-b4292d3d5fcf  - aiasa

// d8b05e43-1c70-4e43-b2a8-99618c1d1d57  - bharatclouds

export const ClientID = "2ce154ce-34ed-46d2-9227-b4292d3d5fcf"; //  client id

// when login its redirect to using domain -> portal azure add redirect url same as this url

// http://localhost:3000/  for localhost

// https://aiasadev.annaiasacademy.com/ for dev site

// https://annaiasacademy.com

export const RedirectURL = "https://annaiasacademy.com/api"; // create online meeting redirect url for microsoft instance

// export const RedirectURL ="https://annaiasacademy.com"; // create online meeting redirect url for microsoft instance
