import React, { useState ,  useRef } from "react";
import logo from "../../../img/AIASA.png";
import IconLogo from '../../../img/IconLogo.png'
import { Link, useLocation } from "react-router-dom";
import "../Login/Login.css";
import "./EmailVerfiy.css";
import video from "../../../video/pexels-nataliya-vaitkevich-7235868.mp4";
import { Logostyle } from "../../../Themes/themes";
import { Error } from "../../../Themes/themes";
import { useEffect } from "react";
import { AxiosInstance } from "../../../../src/axios/index";
import { useNavigate } from "react-router-dom/dist";
import Swal from "sweetalert2";
import Video from "../../../img/india.mp4";
function EmailVerfiy() {
  const navigate = useNavigate();
  const location = useLocation();
  const {email , mobileNumber} = location.state;
  const savedFields = JSON.parse(localStorage.getItem("registerFields"));

  const [initialfields, setInitialfields] = useState(
    savedFields || {
      email: "",
     
    }
  );



  const txtLoginEmail = useRef(null);
 

  const [error, setError] = useState({});


  const validateForm = (data) => {
    const errors = {};

    if (data.email === "") {
      errors.email = "Please Enter OTP.";

    }
    if (data.email.length < 6) {
      errors.email = "Please Enter Valid Six Digit OTP.";

    }
    validateFormFF(data);
    return errors;
  };


  const validateFormFF = (data) => {
    const errors = {};

    if (data.email === "") {

      txtLoginEmail.current.focus();
    }
    else if (data.email.length < 6) {

      txtLoginEmail.current.focus();
    }
    return errors;
  };


  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
    setError({
      ...error,
      [name]: "",
    });
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes === 0) {
            clearInterval(interval);
            setIsButtonDisabled(true); // Disable the button
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [minutes, seconds]);

  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(initialfields);

    // console.log(initialfields,"...initialfields...");

    if (
      initialfields.privacypolicy === false &&
      Object.keys(errors).length === 0
    ) {
      errors.privacypolicy = "Please agree to the Privacy Policy.";
    }

    if (Object.keys(errors).length === 0) {
      try {
        const response = await AxiosInstance.post("api/user/verify/emailOtp", {
          email: email,
          otp : initialfields.email,
        });
        // console.log(response.data);
        if (response.data.status === true ) {
          // Remove the registerFields item from local storage
          localStorage.removeItem("registerFields");
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              // navigate("/RegisterUser/PhoneNumberVerify",{ state: {phoneNumber : mobileNumber} });
              navigate("/registersuccess");
            }
          });
        } else {
          Swal.fire({
            title: "error",
            text: response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  const handleResend = (email) => {
    AxiosInstance.post("api/user/resend/otp", {
      "email": email
    }).then((res) => {
       if(res.data.status === true){
      Swal.fire({
      title: "success",
      text: "Otp send to your mail",
      icon: "success",
      confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          setMinutes(5);
          setSeconds(0);
          setIsButtonDisabled(false); // Disable the button
        }
      })}}).catch((error) => {
      return error;
    });
  }
  useEffect(() => {
    const fieldsToSaveCopy = {
      ...initialfields,
      password: "",
      cpassword: "",
    };
    localStorage.setItem("registerFields", JSON.stringify(fieldsToSaveCopy));
  }, [initialfields]);
  
  return (
    <>
      <section
        className="container-fluid LoginForm videoWrapper"
        id="wrapper_Login"
      >
        <video poster={Image} autoPlay muted loop>
          <source src={Video} type="video/mp4" />
        </video>
        <div className="row ">
          <div className="col-md-12 box" id="boxss">
            <form className="" onSubmit={handleSubmit}>
              <img src={IconLogo} id="img" alt="Logo" />
               <div className="d-flex justify-content-between">
                 <h6 className="emailVerfiy">Verify Email</h6>
                 <h6 className="emailVerfiy_timer"><span className="material-icons-outlined">timer</span> {formattedTime}</h6>
               </div>
<div className="row">
    <div className="col-md-12">
        <h6 className="email_text">{email}</h6>
        <p className="text-start mb-1">Email verification code has been sent to the email address you provided. Please enter the code below to verify your email address.</p>
    </div>
</div>
              <div className="form-outline mb-4">
                <input
                     ref={txtLoginEmail}
                  type="number"
                  name="email"
                  id="email"
                  disabled={isButtonDisabled}
                  value={initialfields.email}
                  onChange={handleChange}
                  className="form-control mb-3 registerfield "
                  placeholder="Email Verification Code"
                />
              </div>
              {error.email && <span className="error">{error.email}</span>}
<div className="text-end">
<span className="code_text_Email me-1">Didn't receive the code yet?</span><a className="resend_otp" onClick={()=>handleResend(email)}>Resend code</a>
</div> <div className="text-center ">
                <button
                  type="submit"
                  className="LoginBtn"
                  name="RegisterNow"
                  value=""
                  disabled={isButtonDisabled}
                >
                 Verify Email
                </button>
                <div className="BackLogin mt-4 mb-2">
                  <Link to="/register"><i class="fa fa-long-arrow-left" id="MIcons"></i>Back to register now</Link></div>
              </div>
              <div className="copyRights">© 2023 All Rights Reserved</div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default EmailVerfiy;


