import React, { useEffect, useState } from 'react'
import './CreateTestCourseBatch.css';
import { Error } from '../../../../Themes/themes';
import { AxiosInstance } from '../../../../axios/index';
import { useParams } from 'react-router-dom';
import {Link } from 'react-router-dom';


function CreateTestCourseBatch() {

const {id} = useParams();

const[editcourse,setEditcourse]=useState([]);

// setting error value for all input fields
const [error, setError] = useState({});
const [initialfields, setInitialfields] = useState({
  courseCategory: '',
  subCategory:'',
  class_name:'',
  classMode: '',
  medium: '',
  fees: '',
  duration: '',
  batch: '',
  startDate: new Date().toISOString().substr(0, 10), // set default value to today's date
  // endDate:new Date().toISOString().substr(0, 10),
  startTime:'',
  endTime:'',
});

/**fetch the data for the id from the url */
  useEffect(() => {
     const coursestoredItem = localStorage.getItem('createTestcoursebatch');
  if (coursestoredItem) {
    const courses = JSON.parse(coursestoredItem);
    const selectedCourse = courses.filter((course, i) => i == id);
    setEditcourse(selectedCourse);
  }
  }, []);



  /**
   * is saved function
   */
  function save() {
    alert('Test Batch Course details Saved Successfully');
  }


/**assign the edit datas to the form in all fields */
  useEffect(() => {
    if (editcourse.length) {
      setInitialfields({
        ...initialfields,
        courseCategory: editcourse[0].courseCategory,
        subCategory:editcourse[0].subCategory,
        class_name:editcourse[0].class_name,
        classMode: editcourse[0].classMode,
        medium: editcourse[0].medium,
        fees: editcourse[0].fees,
        duration: editcourse[0].duration,
        batch: editcourse[0].batch,
        startDate: editcourse[0].startDate,
        // endDate:editcourse[0].endDate,
        startTime:editcourse[0].startTime,
        endTime:editcourse[0].endTime,
      });
    }
  }, [editcourse]);


// validate form fields and setting error
    const errors = {};

    const validate = (data) => {
      const currentDate = new Date();
      const selectedDate = new Date(data.startDate);
      // const selectedendDate = new Date(data.endDate);

      if(data.courseCategory==""){
           errors.courseCategory = 'Please select course Category.';
      }
      else if(data.classMode==""){
          errors.classMode = 'Please select class Mode.';
      }
      else if(data.subCategory=="" && data.courseCategory=="TNPSC"){
          errors.subCategory = 'Please select Sub Category.';
      }
       else if(data.class_name==""){
          errors.class_name = 'Please Enter Class Name.';
      }
      else if(data.medium==""){
          errors.medium = 'Please select medium.';
      }
      else if(data.fees==""){
          errors.fees = 'Please Enter Fees Amount.';
      }
      else if(data.fees.length >= 10){
          errors.fees = 'Fees amount length exists';
      }
      else if(data.fees <= 0){
          errors.fees = 'Fees should be greater than 0.';
      }
      else if(data.duration==""){
          errors.duration = 'Please Select Duration.';
      }
      else if(data.batch==""){
          errors.batch = 'Please Select Batch Type.';
      }
      else if(data.startDate==""){
          errors.startDate = 'Please Select start Date.';
      }
      else if (selectedDate.toDateString() === currentDate.toDateString()) {
      errors.startDate = "Start date cannot be today's date";
      }
      else if(selectedDate < currentDate){
          errors.startDate = 'Start Date cannot be in the past.';
      }
      // else if(data.endDate==""){
      //     errors.endDate = 'Please Select end Date.';
      // }
      // else if(selectedendDate < currentDate){
      //     errors.endDate = 'End Date cannot be in the past.';
      // }
      // else if(selectedendDate.toDateString() === selectedDate.toDateString()){
      //     errors.endDate = 'End Date and Start Date should not be same.';
      // }
      // else if (selectedendDate.toDateString() === currentDate.toDateString()) {
      // errors.endDate = "End date cannot be today's date";
      // }
      // else if (data.startDate > data.endDate) {
      // errors.endDate = "End date should not less than start date";
      // }
      else if (data.startTime=="") {
      errors.startTime = "Please Select Start Time";
      }
      else if (data.endTime=="") {
      errors.endTime =  "Please Select End Time";
      }

      return errors;
    };

    /**
 * Handle change event for password fields.
 * Updates the corresponding field value in state based on the input field's name.
 * @param {object} event - The change event object.
 * @param {string} event.target.name - The name of the input field.
 * @param {string} event.target.value - The value of the input field.
 */
    const handleChange = (event) => {
      // console.log(event.target.name,event.target.value,"...handlechange...");
      const { name, value, id, checked } = event.target;
      // console.log(event.target.id,event.target.value,"...handlechange...");

      // set input values into state
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: value,
        }));
      

  // set error values into state
      setError({
        ...error,
        [name]: ''
      });
    };



    /**
 * Handles submission of the form when form submits.
 * Saves the updated form values to local storage.
 * @param {Object} e - The form submission event.
 */

const handleSubmit = async (e) => {
  e.preventDefault();
  const errors = validate(initialfields);

  if (Object.keys(errors).length === 0) {
    try {
      const existingData = localStorage.getItem("createTestcoursebatch") || "[]";
      const parsedData = JSON.parse(existingData);

      if (id) {
        // If an id is provided, update the data at the specified index
        parsedData[id] = initialfields;
          // try {
        //   const response = await AxiosInstance.post("/api/user/register",{batch_id:selectedIds});
        //   console.log(response.data);
        //   if(response.status="true")
        //   {
        //     save();
        //     window.location.href = '/ViewTestcoursebatch';
        //   }
        // } catch (error) {
        //   console.log(error);
        // }
    
        save();
        window.location.href = '/ViewTestcoursebatch';

      } else {
        // If no id is provided, add the new data to the end of the array
        parsedData.push(initialfields);
          // try {
        //   const response = await AxiosInstance.post("/api/user/register",{batch_id:selectedIds});
        //   console.log(response.data);
        //   if(response.status="true")
        //   {
        //     save();
        //     window.location.href = '/ViewTestcoursebatch';
        //   }
        // } catch (error) {
        //   console.log(error);
        // }
    
        save();
        window.location.href = '/ViewTestcoursebatch';
      }

      localStorage.setItem("createTestcoursebatch", JSON.stringify(parsedData));
    } catch (error) {
      console.log(error);
    }
  } else {
    setError(errors);
  }
};

  return <div>

<form onSubmit={handleSubmit} >

<div>
  <div>
 {id? (<h4>Edit Test Course Batch Details</h4>):(<h4>Create Test Course Batch Details</h4>)}
  <div class="row">
    <div class="form-group col-md-6">
    <label for="courseCategory">Course Category:</label>
    <select class="form-control" id="courseCategory" name='courseCategory'  onChange={handleChange} value={initialfields.courseCategory}>
      <option value="">--- Select Course Category ---</option>
      <option value="TNPSC">TNPSC</option>
      <option value="UPSC">UPSC</option>
    </select>
    </div>

    {initialfields.courseCategory=="TNPSC" ? (
    <>
        <div class="form-group col-md-6">
    <label for="subCategory">Sub Category:</label>
    <select class="form-control" id="subCategory" name='subCategory'  onChange={handleChange} value={initialfields.subCategory}>
      <option value="">--- Select Sub Category ---</option>
      <option value="Group I Preliminary">Group I Preliminary</option>
      <option value="Group I Mains">Group I Mains</option>
    </select>
    </div>
    <Error>{error.subCategory && <small className="error">{error.subCategory}</small>}</Error>
</>
    ) : (initialfields.subCategory='')}
   
    <Error>{error.courseCategory && <small className="error">{error.courseCategory}</small>}</Error>
  </div>

      <div class="row">
    <div class="form-group col-md-6">
    <label for="class_name">Class:</label>
        <input type="text" class="form-control" id="class_name" name="class_name" placeholder="Enter Class name" value={initialfields.class_name}   onChange={handleChange} />
    </div>
     <div class="form-group col-md-6">
     <label for="classMode">Class Mode:</label>
 
      <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name="classMode" id="online" value="online"   onChange={handleChange} checked={initialfields.classMode === 'online'} />
      <label class="form-check-label" for="online">Online</label>
      </div>
 
      <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name="classMode" id="offline" value="offline" checked={initialfields.classMode === 'offline'}    onChange={handleChange} />
      <label class="form-check-label" for="offline">Offline</label>
      </div>

     </div>
     <Error>{error.classMode && <small className="error">{error.classMode}</small>}</Error>
    <Error>{error.class_name && <small className="error">{error.class_name}</small>}</Error>
  </div>


        
  <div class="row">
    <div class="form-group col-md-6">
    <label for="medium">Medium:</label>
  
    <div class="form-check form-check-inline">
    <input class="form-check-input" type="radio" name="medium" id="Tamil" value="Tamil"  checked={initialfields.medium === 'Tamil'}   onChange={handleChange} />
    <label class="form-check-label" for="Tamil">Tamil</label>
    </div>
    
    <div class="form-check form-check-inline">
    <input class="form-check-input" type="radio" name="medium" id="English" value="English" checked={initialfields.medium === 'English'}     onChange={handleChange} />
    <label class="form-check-label" for="English">English</label>
    </div>
  
  </div>
      <div class="form-group col-md-6">
    <label for="fees">Fees:</label>
    <input type="number" class="form-control" id="fees" name="fees" placeholder="Enter Fees" value={initialfields.fees}   onChange={handleChange} />
    </div>
<Error>{error.fees && <small className="error">{error.fees}</small>}</Error>
  <Error>{error.medium && <small className="error">{error.medium}</small>}</Error>
  </div>
          
  <div class="form-row">

</div>
  
  <div class="row">
    <div class="form-group col-md-6">
        <label for="courseCategory">Duration:</label>
        <select class="form-control" id="duration" name='duration' value={initialfields.duration}   onChange={handleChange} >
        <option value="">--- Select Duration ---</option>
        <option value="6">6 Months</option>
        <option value="9">9 Months</option>
        <option value="14">14 Months</option>
        </select>
    </div>
    <div class="form-group col-md-6">
    <label for="batch">Batch Type:</label>
      
        <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="batch" id="weekend" value="weekend" checked={initialfields.batch === 'weekend'}    onChange={handleChange}  />
        <label class="form-check-label" for="weekend">Week End</label>
        </div>
      
        <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="batch" id="regular" value="regular" checked={initialfields.batch === 'regular'}  onChange={handleChange}  />
        <label class="form-check-label" for="regular"> Regular</label>
        </div>
    </div>
<Error>{error.batch && <small className="error">{error.batch}</small>}</Error>
     <Error>{error.duration && <small className="error">{error.duration}</small>}</Error>      
   </div>
        
  <div class="form-row">
    
  </div>
        
<div class="row">
  <div class="form-group col-md-6">
    <label for="startDate">Start Date:</label>
    <div class="input-group date">
    <input type="date"  class="form-control" id="startDate" name="startDate" value={initialfields.startDate}  onChange={handleChange} />
    </div>
  </div>

<div class="form-group col-md-6">
    <label for="startTime">Start Time</label>
  <input type="time" class="form-control" id="startTime" name="startTime" placeholder="Select start time"/>
</div>
  </div>
  <div class="row">

    <div class="form-group col-md-6">
   <label for="endTime">End Time</label>
  <input type="time" class="form-control" id="endTime" name="endTime" placeholder="Select End time"/>
    </div>

   <Error>{error.startDate && <small className="error">{error.startDate}</small>}</Error> 
  <Error>{error.startTime && <small className="error">{error.startTime}</small>}</Error>
    <Error>{error.endTime && <small className="error">{error.endTime}</small>}</Error>

</div>
        
<div class="col-sm-4 mt-3">
  <button type="submit" class="btn btn-primary col-sm-4 ">Save</button>
  <button type="button" class="btn btn-secondary col-sm-4 ms-5" ><Link to="/ViewCourseBatch" className='course_cancel'>Cancel</Link></button>
</div>   
   </div>      
      </div>
</form>
  </div>;
}

export default CreateTestCourseBatch
