import React from "react";
// import logo from '../../../img/AIASA.png'
import IconLogo from "../../../img/IconLogo.png";
import { Link } from "react-router-dom";

import "./Registersuccess.css";

function RegisterSuccess() {
  return (
    <>
      <section
        className="container-fluid LoginForm videoWrapper"
        id="wrapper_Login"
      >
        <div className="row">
          <div className="col-md-12 box" id="boxsss">
            <img src={IconLogo} id="img" alt="Logo" />
            <h1 className="hTag">Registration Successful</h1>
            <p>
              Success starts with registration.
              <br /> You've already achieved it!
            </p>
            <div className="BackLogin">
              <Link to="/login">
                <i class="fa fa-long-arrow-left" id="MIcons"></i>Back to Log in
              </Link>
            </div>
            <div className="copyRights">© 2023 All Rights Reserved</div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RegisterSuccess;
