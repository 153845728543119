import React, { useState, useRef, useEffect } from "react";

import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import { CloseOutlined, Pending } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { styled } from "@mui/material/styles";
import DatePickerSample from "../../DatePicker";
function CashFPayment(props) {
  //usestate

  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  let page = R_PER_PAGE;

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const [updatePag, setUpdatePag] = useState(false);
  const [updatePagDis, setUpdatePagDis] = useState(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [dCourse, setDcourse] = useState([]);
  const [dCourses, setDcourses] = useState([]);
  const [dCoursebatch_name, setDcoursebatch_name] = useState([]);
  const [dCoursesbatch_name, setDcoursesbatch_name] = useState([]);
  const [isOpen, setIsOpen] = useState({
    status: false,
    startDate: false,
    location: false,
    category: false,
    pay_duedate: false,
    batch_name: false,
  });
  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });

  const [appDataDue, setAppDataDue] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;
  let staff_loc_name = null;
  let staff_loc_id = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      staff_loc_id = user.staff_loc_id;
      staff_loc_name = user.staff_loc_name;
    }
  } else {
    console.log("UserId Not available");
  }

  const [updateChipData, setUpdateChipData] = useState([]);

  const [status, setStatus] = useState([]);
  const [appOn, setAppOn] = useState([]);
  const [appOn_due, setAppOn_due] = useState([]);
  const [typeOn, setTypeOn] = useState([]);
  const [location, setLocation] = useState([]);
  const [batch_name, setBatch_name] = useState([]);
  let filterStatus = [];
  let filterType = [];
  let filterApplyed = [];
  let filterApplyedDue = [];
  let filterSearch = [];
  let filterlocation = [];
  let filterbatch_name = [];
  // filter useEffect

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    filterStatus = status;
  }, [status]);

  useEffect(() => {
    filterlocation = location;
  }, [location]);

  useEffect(() => {
    filterbatch_name = batch_name;
  }, [batch_name]);

  useEffect(() => {
    // console.log(" names : ", typeOn);
    filterType = typeOn;
  }, [typeOn]);

  useEffect(() => {
    filterApplyed = appOn;
  }, [appData]);

  useEffect(() => {
    filterApplyedDue = appOn_due;
  }, [appDataDue]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    if (props.activeTab === 1) {
      loadChipdata();
    }
  }, []);

  useEffect(() => {
    if (props.activeTab === 1) {
      loadListdata();
    }
  }, [props.activeTab]);

  // useEffect(() => {
  //   // alert(updatePag);
  //   if (!updatePag) {
  //     loadListdata();
  //   }
  // }, [rowsPerPage, currentPage]);

  useEffect(() => {
    if (props.activeTab === 1) {
      loadListdata();
    }
  }, [updateChipData]);

  // useEffect(() => {
  //   console.log(" updateListData : ", updateListData);
  //   currentData = updateListData.slice(start, end);
  //   // total table data  with pages
  //   totalPages = Math.ceil(updateListData.length / rowsPerPage);
  // }, [updateListData]);

  function updateStateData() {
    filterStatus = status;
    filterType = typeOn;
    filterApplyed = appOn;
    filterlocation = location;
    filterbatch_name = batch_name;
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  const handlePayFee = (CourseDetail) => {
    let PayDue = "1";
    let PayTab = "1";
    navigate(`/CashPayment/payFee`, {
      state: {
        CourseDetail,
        PayDue,
        PayTab,
      },
    });
  };

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  const handleCheckboxChange = (event, names, typename) => {
    // console.log(typename, " : ", status);
    const { value, checked, name } = event.target;
    if (
      name === "Upcoming" ||
      name === "Ongoing" ||
      name === "Completed" ||
      name === "WaitForApproval" ||
      name === "Rejected" ||
      name === "Approved" ||
      name === "OnHold"
    ) {
      if (checked) {
        // setStatus([...status, value]);
        setStatus([...status, { id: value, name: name, type: "status" }]);
      } else {
        setStatus(status.filter((item) => item.id !== value));
      }
    } else if (
      name === "course" ||
      name === "TestBatch" ||
      name === "SpecialClass" ||
      name === "Counselling" ||
      name === "MockInterview" ||
      name === "ModelExam"
    ) {
      if (checked) {
        setTypeOn([...typeOn, { id: value, name: name, type: "type" }]);
      } else {
        setTypeOn(typeOn.filter((item) => item.id !== value));
      }
    } else if (names === "location") {
      if (checked) {
        // setStatus([...status, value]);
        setLocation([...location, { id: value, name: name, type: "location" }]);
      } else {
        setLocation(location.filter((item) => item.id !== value));
      }
    } else if (names === "batch_name") {
      if (checked) {
        // setStatus([...status, value]);
        setBatch_name([
          ...batch_name,
          { id: value, name: name, type: "batch_name" },
        ]);
      } else {
        setBatch_name(batch_name.filter((item) => item.id !== value));
      }
    }
  };

  const handleFilterCourseSearch = (event) => {
    const searchInput = event.target.value;
    if (searchInput !== "") {
      const matchingObjects = dCourse.filter(
        (item) => item && item.toLowerCase().includes(searchInput.toLowerCase())
      );
      // Do something with the matchingObjects, such as updating state or displaying them in the UI
      setDcourses(matchingObjects);
    } else {
      setDcourses(dCourse);
    }
  };

  const handleFilterCourseBSearch = (event) => {
    const searchInput = event.target.value;
    if (searchInput !== "") {
      const matchingObjects = dCoursebatch_name.filter(
        (item) => item && item.toLowerCase().includes(searchInput.toLowerCase())
      );
      // Do something with the matchingObjects, such as updating state or displaying them in the UI
      setDcoursesbatch_name(matchingObjects);
    } else {
      setDcoursesbatch_name(dCoursebatch_name);
    }
  };

  // handle search function
  const handleSearch = (event) => {
    // console.log(event);
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };
  //chip data list view
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setStatus([]);
    setLocation([]);
    setBatch_name([]);
    setUpdateChipData([]);
    setAppOn([]);
    setAppOn_due([]);
    setTypeOn([]);
    setAppData({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setAppDataDue({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setUpdateListData(listData);
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    // console.log(section);
    updateStateData();
    if (name === "status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        status: false,
      });
    } else if (name === "appliedOn") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "type") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "location") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        location: false,
      });
    } else if (name === "batch_name") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        batch_name: false,
      });
    } else if (name === "pay_duedate") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        pay_duedate: false,
      });
    }
  };

  //load list data for table
  function loadListdata() {
    let tempstatus = [];
    let temptypeon = [];
    let tempnumstatus = [];
    let tempTypenum = [];
    //  console.log(typeOn,"typeOn");
    let templocation = "";
    let tempbatch_name = [];
    status.map((obj) => {
      tempstatus.push(obj.id);
    });
    typeOn.map((obj) => {
      temptypeon.push(obj.id);
    });
    location.map((obj) => {
      if (obj.name === "Online") {
        templocation = "1";
      } else {
        templocation = "0";
      }
    });
    batch_name.map((obj) => {
      tempbatch_name.push(obj.name);
    });
    // status array to number array

    tempnumstatus = tempstatus.map(Number);

    tempTypenum = temptypeon.map(Number);

    let statusFilter = {
      type: typeOn.length > 0 ? tempTypenum : "",
      location: staff_loc_id ? staff_loc_id.toString() : "",
      start_date: appOn.length > 0 ? appOn[0].fdate : "",
      end_date: appOn.length > 0 ? appOn[0].edate : "",
      category: "",
      search: searchTerm.length > 0 ? searchTerm : "",
      payment_type: 0,
      status: "",
      section: "",
      batch_name: batch_name.length > 0 ? tempbatch_name : "",
      location_type: location.length > 0 ? templocation : "",
      // page_size: rowsPerPage,
      // page_no: currentPage,
      page_size: "",
      page_no: "",
    };

    AxiosInstance.post("api/course/admission/cash/list", statusFilter)
      .then((res) => {
        // console.log("editadasd ", res);
        if (res.data.status === true) {
          const fiata = res.data.data;
          const serialNumberAddedData = fiata.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
          setUpdateListData(serialNumberAddedData);
          // setUpdatePagDis(false);
          const LocationList = updateListData
            .map((item) => (item.location !== null ? item.location : []))
            .flat();
          // Remove duplicates by converting the array to a Set and back to an array
          const uniqueLocationList = [...new Set(LocationList)];
          setDcourse(uniqueLocationList);
          setDcourses(uniqueLocationList);

          // batch name list

          const batch_name_list = updateListData
            .map((item) => (item.location !== null ? item.batch_name : []))
            .flat();
          // Remove duplicates by converting the array to a Set and back to an array
          const uniquebatch_nameList = [...new Set(batch_name_list)];
          setDcoursebatch_name(uniquebatch_nameList);
          setDcoursesbatch_name(uniquebatch_nameList);
        } else if (res.data.status === false) {
          setUpdateListData([]);
          // setUpdatePagDis(true);
        }
      })
      .catch((error) => {
        // setUpdatePagDis(false);
        // console.log("Error res: ", error.response.data);
        setUpdateListData([]);
        return error;
      });
  }
  //load chip data
  function loadChipdata() {
    //setUpdateChipData([]);
    let tempArr = [];
    // setUpdateChipData(status);
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    filterStatus.map((obj) => {
      tempArr.push(obj);
    });

    filterType.map((obj) => {
      tempArr.push(obj);
    });

    filterApplyed.map((obj) => {
      tempArr.push(obj);
    });

    filterApplyedDue.map((obj) => {
      tempArr.push(obj);
    });

    filterlocation.map((obj) => {
      tempArr.push(obj);
    });

    filterbatch_name.map((obj) => {
      tempArr.push(obj);
    });

    // console.log(tempArr, "tempArr");
    setUpdateChipData(tempArr);
  }
  // applied on handlechange
  const handleAppliedOn = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appData.FDate;
      const to = name === "TDate" ? value : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppData((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      }
    }
  };

  const handleAppliedOnDue = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppDataDue((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appData.FDate;
      const to = name === "TDate" ? value : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn_due([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppDataDue((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn_due([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRangeDue",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn_due([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRangeDue",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn_due([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRangeDue",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn_due([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRangeDue",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn_due([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRangeDue",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn_due([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRangeDue",
          },
        ]);
      }
    }
  };
  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "appliedOn") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "status") {
      // Clear the status array
      setStatus([]);
      filterStatus = [];
      setIsOpen({
        ...isOpen,
        status: false,
      });
      // setUpdateListData(listData);
    } else if (name === "type") {
      setTypeOn([]);
      filterType = [];
      setIsOpen({
        ...isOpen,
        type: false,
      });
    } else if (name === "location") {
      setLocation([]);
      filterlocation = [];
      setIsOpen({
        ...isOpen,
        location: false,
      });
    } else if (name === "batch_name") {
      setBatch_name([]);
      filterbatch_name = [];
      setIsOpen({
        ...isOpen,
        batch_name: false,
      });
    } else if (name === "pay_duedate") {
      setAppDataDue({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn_due([]);
      filterApplyedDue = [];
      setIsOpen({
        ...isOpen,
        pay_duedate: false,
      });
    }
    loadChipdata();
  };

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "status") {
      setStatus(status.filter((item) => item.id !== chipToDelete.id));
      filterStatus = status.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "type") {
      setTypeOn(typeOn.filter((item) => item.id !== chipToDelete.id));
      filterType = typeOn.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "location") {
      setLocation(location.filter((item) => item.id !== chipToDelete.id));
      filterlocation = location.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "batch_name") {
      setBatch_name(batch_name.filter((item) => item.id !== chipToDelete.id));
      filterbatch_name = batch_name.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else if (chipToDelete.type === "dayRange") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    } else if (chipToDelete.type === "dayRangeDue") {
      setAppDataDue({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn_due([]);
      filterApplyedDue = [];
    } else {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setAppDataDue({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn_due([]);
      filterApplyedDue = [];
    }
    loadChipdata();
  };

  const handleChangeDate = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    }
  };

  const handleChangeDateDue = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppDataDue((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn_due([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    }
  };

  //  filter box tootle function
  const handleFilter = (e, boxName) => {
    if (boxName === "status") {
      setIsOpen((prevState) => ({
        startDate: false,
        location: false,
        batch_name: false,
        section: false,
        category: false,
        pay_duedate: false,
        status: !prevState.status,
      }));
    } else if (boxName === "startDate") {
      setIsOpen((prevState) => ({
        section: false,
        category: false,
        batch_name: false,
        status: false,
        location: false,
        pay_duedate: false,
        startDate: !prevState.startDate,
      }));
    } else if (boxName === "section") {
      setIsOpen((prevState) => ({
        category: false,
        status: false,
        location: false,
        batch_name: false,
        startDate: false,
        pay_duedate: false,
        section: !prevState.section,
      }));
    } else if (boxName === "location") {
      setIsOpen((prevState) => ({
        category: false,
        status: false,
        batch_name: false,
        startDate: false,
        pay_duedate: false,
        location: !prevState.location,
      }));
    } else if (boxName === "batch_name") {
      setIsOpen((prevState) => ({
        category: false,
        status: false,
        location: false,
        startDate: false,
        pay_duedate: false,
        batch_name: !prevState.batch_name,
      }));
    } else if (boxName === "category") {
      setIsOpen((prevState) => ({
        status: false,
        startDate: false,
        batch_name: false,
        location: false,
        section: false,
        pay_duedate: false,
        category: !prevState.category,
      }));
    } else if (boxName === "pay_duedate") {
      setIsOpen((prevState) => ({
        status: false,
        startDate: false,
        batch_name: false,
        location: false,
        section: false,
        category: false,
        pay_duedate: !prevState.pay_duedate,
      }));
    }
  };

  // generate pdf when user click download button
  const generatePDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add a title to the PDF document
    doc.text("Table Data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    // Add header row to tableData array
    const headers = [];
    const tableHeader = document.querySelectorAll("#example th");
    for (const header of tableHeader) {
      headers.push(header.innerText);
    }
    tableData.push(headers);

    // Add data rows to tableData array
    const tableRows = document.querySelectorAll("#example tbody tr");
    for (const row of tableRows) {
      const rowData = [];
      const rowCells = row.querySelectorAll("td");
      for (const cell of rowCells) {
        rowData.push(cell.innerText);
      }
      tableData.push(rowData);
    }

    // Add the table to the PDF document
    doc.autoTable({
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
    });
    // Save the PDF document
    doc.save("AdmissionData.pdf");
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
    // setUpdatePag(true);
    // setUpdatePagDis(false);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
    // setUpdatePag(false);
    // setUpdatePagDis(true);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;

    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      // setUpdateListData([]);
    }
    if (props.activeTab === 1) {
      loadListdata();
    }
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  //detail view for this list data
  const handleReadView = (user_id, batch_id, add_id, type) => {
    navigate(
      `/TransactionHistory/ViewAdmission/${user_id}/${batch_id}/${add_id}/${type}`
    );
  };

  // className="row tab-content mt-4"
  return (
    <div
      className={
        props.activeTab === 1
          ? "row tab-content mt-4"
          : "row tab-content mt-4 hide "
      }
      id="ex2-content"
    >
      <div
        className={
          props.activeTab === 1
            ? "tab-pane fade show active col-md-12"
            : "tab-pane fade col-md-12 "
        }
      >
        <div className="row ms-1">
          <div className="col-lg-5 col-md-6">
            <div class="input-group">
              <input
                type="text"
                class="Inputs_search"
                id="SearchInput"
                name="SearchInput"
                placeholder="Search by ID, Name, Email, Phone No."
                // onChange={handleSearch}
                onKeyDown={handleSearch}
              />
              <span class="input-group-text" id="SearchInput">
                <span className="material-icons">search</span>
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-md-4"></div>
          <div className="col-lg-6  d-flex justify-content-evenly col-md-6"></div>
        </div>
        {updateChipData.length > 0 ? (
          <div className="row mt-3">
            <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {updateChipData.map((data, index) => {
                  // console.log(data, " - data - ", index);
                  let icon;
                  let key = "" + data.name;
                  return (
                    <ListItem key={data}>
                      <Chip
                        icon={icon}
                        label={key}
                        onDelete={handleDelete(data, index)}
                      />
                    </ListItem>
                  );
                })}

                <ListItem>
                  {/* <Chip
                      label="clear all filter"
                      onClick={handleAllClear}
                    /> */}
                  <a className="allclear" href="#" onClick={handleAllClear}>
                    clear all filter
                  </a>
                </ListItem>
              </Paper>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="row mt-3 ms-1 me-1">
          <div className="col-md-12 table-responsive p-0">
            <table className="table" id="example">
              <thead>
                <tr>
                  <th id="th" style={{ width: "5%" }}>
                    {" "}
                    <span className="text_th">S.No</span>{" "}
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "serialNumber")}
                    >
                      {" "}
                      import_export{" "}
                    </span>
                  </th>
                  <th id="th">
                    {" "}
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "transaction_order_id")}
                    >
                      {" "}
                      Order ID
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "transaction_order_id")}
                    >
                      {" "}
                      import_export{" "}
                    </span>
                  </th>
                  <th id="th" style={{ width: "7%" }}>
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "fullname")}
                    >
                      Name
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "fullname")}
                    >
                      {" "}
                      import_export{" "}
                    </span>{" "}
                  </th>

                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "email")}
                    >
                      Email ID
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "email")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "phone_no")}
                    >
                      Phone Number
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "phone_no")}
                    >
                      import_export
                    </span>
                  </th>

                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "location")}
                    >
                      Location
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "location")}
                    >
                      import_export
                    </span>{" "}
                    <span
                      class={` align-middle material-icons  ${
                        location.length > 0 ? "active_icons" : ""
                      }`}
                      onClick={(e) => handleFilter(e, "location")}
                    >
                      filter_list
                    </span>
                    {isOpen.location === true && (
                      <div className="filterBoxs p-1">
                        <div className="row ms-1">
                          <div className="col-lg-12 col-md-12">
                            <div class="input-group searchInputsss">
                              <input
                                type="text"
                                class="Inputs "
                                id="SearchFilterInputs"
                                name="SearchFilterInputs"
                                placeholder="Search Here"
                                // onChange={handleSearch}
                                onKeyDown={handleFilterCourseSearch}
                              />
                              <span class="input-group-text" id="SearchInput">
                                <span className="material-icons">search</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {dCourses.map((el) => (
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el} // Use el.label to set the name and value
                                  value={el}
                                  id={el}
                                  checked={location.some((element) => {
                                    if (element.name === el) {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "location")
                                  }
                                />
                                <label
                                  class="form-check-label PinkText"
                                  for="defaultCheck1"
                                >
                                  {el}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4  col-4 col-sm-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("location")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("location")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "type")}
                    >
                      Type
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "type")}
                    >
                      import_export
                    </span>
                    <span
                      class={` align-middle material-icons  ${
                        typeOn.length > 0 ? "active_icons" : ""
                      }`}
                      onClick={(e) => handleFilter(e, "category")}
                    >
                      filter_list
                    </span>
                    {isOpen.category === true && (
                      <div className="filterBoxTwo p-1">
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="course"
                                value="0"
                                checked={typeOn.some((element) => {
                                  if (element.id === "0") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "course")
                                }
                                id="course"
                              />
                              <label
                                class="form-check-label pb-0 pt-0 align-top YellowText"
                                for="defaultCheck1"
                              >
                                Course
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="TestBatch"
                                value="1"
                                checked={typeOn.some((element) => {
                                  if (element.id === "1") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "TestBatch")
                                }
                                id="TestBatch"
                              />
                              <label
                                class="form-check-label pb-0 pt-0 align-top GreenText"
                                for="defaultCheck1"
                              >
                                Test Batch
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="SpecialClass"
                                value="3"
                                checked={typeOn.some((element) => {
                                  if (element.id === "3") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "SpecialClass")
                                }
                                id="SpecialClass"
                              />
                              <label
                                class="form-check-label pb-0 pt-0 align-top  YellowText"
                                for="defaultCheck1"
                              >
                                Special Class
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="Counselling"
                                value="2"
                                checked={typeOn.some((element) => {
                                  if (element.id === "2") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "Counselling")
                                }
                                id="Counselling"
                              />
                              <label
                                class="form-check-label pb-0 align-top pt-0 RedText"
                                for="defaultCheck1"
                              >
                                Counselling
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="MockInterview"
                                value="5"
                                checked={typeOn.some((element) => {
                                  if (element.id === "5") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "MockInterview")
                                }
                                id="MockInterview"
                              />
                              <label
                                class="form-check-label pb-0 align-top pt-0 RedText"
                                for="defaultCheck1"
                              >
                                Mock Interview
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="ModelExam"
                                value="4"
                                checked={typeOn.some((element) => {
                                  if (element.id === "4") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "ModelExam")
                                }
                                id="ModelExam"
                              />
                              <label
                                class="form-check-label pb-0 align-top pt-0 RedText"
                                for="defaultCheck1"
                              >
                                Model Exam
                              </label>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4  col-4 col-sm-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("type")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              disabled={typeOn.length === 0 ? true : false}
                              onClick={() => handleApplyFilter("type")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "batch_name")}
                    >
                      Batch Name
                    </span>

                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "batch_name")}
                    >
                      import_export
                    </span>
                    <span
                      onClick={(e) => handleFilter(e, "batch_name")}
                      class={` align-middle material-icons ${
                        batch_name.length > 0 ? "active_icons" : ""
                      }`}
                    >
                      filter_list
                    </span>
                    {isOpen.batch_name === true && (
                      <div className="filterBoxs p-1">
                        <div className="row ms-1">
                          <div className="col-lg-12 col-md-12">
                            <div class="input-group searchInputsss">
                              <input
                                type="text"
                                class="Inputs "
                                id="SearchFilterInputs"
                                name="SearchFilterInputs"
                                placeholder="Search Here"
                                // onChange={handleSearch}
                                onKeyDown={handleFilterCourseBSearch}
                              />
                              <span class="input-group-text" id="SearchInput">
                                <span className="material-icons">search</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {dCoursesbatch_name.map((el) => (
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el} // Use el.label to set the name and value
                                  value={el}
                                  id={el}
                                  checked={batch_name.some((element) => {
                                    if (element.name === el) {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "batch_name")
                                  }
                                />
                                <label
                                  class="form-check-label PinkText"
                                  for="defaultCheck1"
                                >
                                  {el}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4  col-4 col-sm-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("batch_name")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("batch_name")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "appliedOn")}
                    >
                      Applied On
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "appliedOn")}
                    >
                      import_export
                    </span>{" "}
                    <span
                      onClick={(e) => handleFilter(e, "startDate")}
                      class={` align-middle material-icons  ${
                        appOn.length > 0 ? "active_icons" : ""
                      }`}
                    >
                      filter_list
                    </span>
                    {isOpen.startDate === true && (
                      <div className="filterBoxOne container p-1">
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <div class="form-check p-1">
                              <label
                                htmlFor="FDate"
                                className="d-flex form-label"
                              >
                                From Date
                                <span
                                  data-required="true"
                                  aria-hidden="true"
                                ></span>
                              </label>
                              {/* <input
                                id="FDate"
                                type="date"
                                name="FDate"
                                value={appData.FDate}
                                className="FilterInputs"
                                onChange={handleAppliedOn}
                                placeholder="FDate"
                                autoComplete="FDate"
                                required
                              /> */}
                              <DatePickerSample
                                IconInput={"filterIocnBoxInput"}
                                class={"FilterInputs"}
                                name={"FDate"}
                                handleChange={handleChangeDate}
                                fieldInput={appData.FDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <div class="form-check p-1">
                              <label
                                htmlFor="TDate "
                                className="d-flex form-label"
                              >
                                To Date
                                <span
                                  data-required="true"
                                  aria-hidden="true"
                                ></span>
                              </label>
                              {/* <input
                                id="TDate"
                                type="date"
                                name="TDate"
                                value={appData.TDate}
                                className="FilterInputs"
                                placeholder="TDate"
                                onChange={handleAppliedOn}
                                autoComplete="TDate"
                                required
                              /> */}
                              <DatePickerSample
                                class={"FilterInputs"}
                                IconInput={"filterIocnBoxInput"}
                                name={"TDate"}
                                handleChange={handleChangeDate}
                                fieldInput={appData.TDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                id="Today"
                                value="Today"
                                checked={appData.Days === "Today"}
                                onChange={handleAppliedOn}
                              />
                              <span class="form-check-label d-flex" for="Today">
                                Today
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Yesterday"
                                checked={appData.Days === "Yesterday"}
                                id="Yesterday"
                                onChange={handleAppliedOn}
                              />
                              <span
                                class="form-check-label d-flex"
                                for="Yesterday"
                              >
                                Yesterday
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Tweek"
                                checked={appData.Days === "Tweek"}
                                id="Tweek"
                                onChange={handleAppliedOn}
                              />
                              <span class="form-check-label d-flex" for="Tweek">
                                This week
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Lweek"
                                checked={appData.Days === "Lweek"}
                                id="Lweek"
                                onChange={handleAppliedOn}
                              />
                              <span class="form-check-label d-flex" for="Lweek">
                                Last week
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Tmonth"
                                checked={appData.Days === "Tmonth"}
                                id="Tmonth"
                                onChange={handleAppliedOn}
                              />
                              <span
                                class="form-check-label d-flex"
                                for="Tmonth"
                              >
                                This month
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                checked={appData.Days === "Lmonth"}
                                value="Lmonth"
                                id="Lmonth"
                                onChange={handleAppliedOn}
                              />
                              <span
                                class="form-check-label d-flex"
                                for="Lmonth"
                              >
                                Last month
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4  col-4 col-sm-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("appliedOn")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("appliedOn")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "balance_amt")}
                    >
                      Fees Paid
                    </span>

                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "balance_amt")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span className="text_th">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentData.length === 0 ? (
                  <tr>
                    <td colspan="12" style={{ textAlign: "center" }}>
                      No Record to display
                    </td>
                  </tr>
                ) : (
                  currentData.map((course, index) => (
                    <tr key={index} title={course.transaction_order_id}>
                      <td id="td">{course.serialNumber}</td>
                      <td id="td" style={{ width: "5%" }}>
                        {course.transaction_order_id}
                      </td>
                      <td id="td">{course.fullname}</td>
                      <td id="td" style={{ width: "5%" }}>
                        {course.email}
                      </td>
                      <td id="td">{course.phone_no}</td>
                      <td id="td">{course.location}</td>
                      {course.type === 0 && (
                        <td id="td">
                          <span>course</span>
                        </td>
                      )}
                      {course.type === 1 && (
                        <td id="td">
                          <span>Test Batch</span>
                        </td>
                      )}
                      {course.type === 2 && (
                        <td id="td">
                          <span>Counselling</span>
                        </td>
                      )}
                      {course.type === 3 && (
                        <td id="td">
                          <span>Special classes</span>
                        </td>
                      )}
                      {course.type === 4 && (
                        <td id="td">
                          <span>Model exam </span>
                        </td>
                      )}

                      {course.type === 5 && (
                        <td id="td">
                          <span>Mock Interview </span>
                        </td>
                      )}
                      <td id="td"> {course.batch_name}</td>
                      <td id="td">
                        {moment(course.appliedOn).format("YYYY-MM-DD")}
                      </td>
                      <td id="td"> {course.balance_amt}</td>
                      <td id="td">
                        <button
                          type="button"
                          onClick={() => handlePayFee(course)}
                          className="PyNowBtn"
                        >
                          <span class="material-icons">send</span>
                          <Link className="PyNowBtnLink">Pay Now</Link>
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="row mt-3 ms-1 me-1">
          <div className="col-md-12">
            <div className="pag w-100 dflexcenter">
              <span className="ipage">Items Per Page</span>
              <select
                name="pageCount"
                id="pageCount"
                defaultValue={rowsPerPage}
                onChange={handlePageCount}
                className="ms-2"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="pagenumber ms-2">
                {" "}
                {currentPage * rowsPerPage + 1 - rowsPerPage} -{" "}
              
                {updateListData.length < currentPage * rowsPerPage
                  ? updateListData.length
                  : currentPage * rowsPerPage}{" "}
                of {updateListData.length}
              </span>
              <button
                className="prev_button ms-2 me-2"
                onClick={(e) => handlePrev(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span class="material-icons">chevron_left</span>
              </button>
              <button
                className="prev_button"
                onClick={() => handleNext(currentPage + 1)}
                disabled={updatePagDis}
              >
                <span class="material-icons">chevron_right</span>
              </button>
            </div>
          </div>
        </div> */}
        <div className="row mt-3 ms-1 me-1">
          <div className="col-md-12">
            <div className="pag w-100 dflexcenter">
              <span className="ipage">Items Per Page</span>
              <select
                name="pageCount"
                id="pageCount"
                defaultValue={rowsPerPage}
                onChange={handlePageCount}
                className="ms-2"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="pagenumber ms-2">
                {" "}
                {currentPage * rowsPerPage + 1 - rowsPerPage} -{" "}
                {/* {currentPage * rowsPerPage} of {updateListData.length} */}
                {updateListData.length < currentPage * rowsPerPage
                  ? updateListData.length
                  : currentPage * rowsPerPage}{" "}
                of {updateListData.length}
              </span>
              <button
                className="prev_button ms-2 me-2"
                onClick={(e) => handlePrev(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span class="material-icons">chevron_left</span>
              </button>
              <button
                className="prev_button"
                onClick={() => handleNext(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <span class="material-icons">chevron_right</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CashFPayment;
