import moment from "moment";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import QtypeOne from "../question/QtypeOne";
import QtypeTwo from "../question/QtypeTwo";
import QtypeThree from "../question/QtypeThree";
import QtypeFour from "../question/QtypeFour";
import QtypeFive from "../question/QtypeFive";
import Qtypesix from "../question/Qtypesix";
import Qtypeseven from "../question/Qtypeseven";
import Qtypeeight from "../question/Qtypeeight";
import Qtypenine from "../question/Qtypenine";
import QtypeTen from "../question/QtypeTen";
import QparaType from "../question/QparaType";
import Qquesparatype from "../question/Qquesparatype";
import styled from "styled-components";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import Select, { components } from "react-select";
import CloseIcon from "../../../../img/close.png";
import capRedFlag from "../../../../img/flagmark.png";
import capFlag from "../../../../img/flagclose.png";
import Swal from "sweetalert2";
import ModelComForP from "../../../../pages/ModelComForP";
import RedLogo from "../../../../img/Redlogo.png";
import FlagGreen from "../../../../img/flag_green.png";
import FlagRed from "../../../../img/flag_red.png";
import FlagWhite from "../../../../img/flg_white.png";
import { customStyles } from "../../../../pages/customStyles";
import PaymentSuccess from "./PaymentSuccess";
import WarningPopUp from "./WarningPopUp";

const MILLISECONDS_TO_SECONDS = 0.001;

const TestDetailsBox_submit = styled.div`
  border: none;
  padding: 14px 7px;
  margin: 7px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #ff6384 0%, #803242 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: "768px") {
    min-height: 140px;
  }
  @media (min-width: "991px") {
    min-height: 185px;
  }
`;

const TestDeatilsScore_submit = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-family: "Roboto";
  font-weight: var(--font-weight-medium);
  @media (min-width: "991px") {
    font-size: 36px;
  }
`;

const TestDetailsLabel_submit = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-family: "Roboto";
  @media (min-width: "991px") {
    font-size: 24px;
  }
`;

export default function JoinTestScreen() {
  const modalCloseRefTest = useRef(null);
  const [step, setStep] = useState(1);
  const [step_answer, setStep_answer] = useState(1);
  const [effStep, setEffStep] = useState(0);
  const [quesStep, setQuesStep] = useState(1);

  //View In langue

  const [langSet, setLangSet] = useState([
    {
      value: 1,
      label: "English",
    },
    {
      value: 0,
      label: "Tamil",
    },
    {
      value: 2,
      label: "All",
      defaultSelected: true, // This indicates it's the default selection
    },
  ]);

  const [isSubmit, setIsSubmit] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [totalStep, setTotalStep] = useState();

  const [openModel, setOpenModel] = useState(false);

  const [swalMsg, setSwalMsg] = useState({
    show: false,
    status: "",
    message: "",
    title: "",
    BottomOkBtn: "",
    BottomCancelBtn: "",
    BottomOkClose: "",
  });

  const [totalStep_answer, setTotalStep_answer] = useState();
  let step_size = 50;
  let step_size_answer = 1;

  const navigate = useNavigate();
  const location = useLocation();
  const [parentPk, setParentPk] = useState([]);
  const [userInput, setUserInput] = useState([]);
  const [userInput_set, setUserInput_set] = useState([]);
  const [selectedLa, setSelectedLa] = useState(null);
  const { batch_id, event_id, startTime, endTime, attempt_no } = location.state;

  const [quesSet, setQuesSet] = useState([]);

  const [quesTiming, setQuesTiming] = useState(null);

  const [sectionTab, setSectionTab] = useState(false);
  const [sectionTabSub, setSectionTabSub] = useState([]);
  const [formData, setFormData] = useState({
    Lang: 2,
    LangText: "All",
  });
  const [stepSeven, setStepSeven] = useState(false);

  const [timingHours, setTimingHours] = useState(null);
  const [eventName, setEventName] = useState({
    event_name: "",
    batch_name: "",
    user_profile_path: "",
    user_name: "",
    startDate: "",
  });
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    const effectiveStep = Math.ceil(quesSet.length / step_size);
    setEffStep(effectiveStep);
  }, [quesSet]);

  const navigateToPreviousScreen = () => {
    navigate(-2); // Navigate back by one step in the history
  };
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }
  const [time, setTime] = useState(moment.duration(0));
  const [listData, setListData] = useState([]);

  const [timeDifference, setTimeDifference] = useState(
    moment.duration(0, "hours")
  );
  const [remainingTime, setRemainingTime] = useState(moment.duration(0));
  const refStartTime = useRef(new Date());
  const refEndTime = useRef(new Date());
  // 1.initial set up
  // 2.calculate time taken
  // 3.reset the time
  // DERIVED STATE
  const OPTIMUM_TIME_EACH_QUESTION = listData
    ? listData.length / ((new Date(endTime) - new Date(startTime)) * 0.001)
    : 0; //conver to seconds

  useEffect(() => {
    const startMoment = moment(startTime, "HH:mm:ss");
    const endMoment = moment(endTime, "HH:mm:ss");
    const durationMs = endMoment.diff(startMoment);
    const durationFormatted = moment.utc(durationMs).format("HH:mm:ss");

    setTimingHours(durationFormatted);

    const timer = setInterval(() => {
      const now = moment();
      const timeDifference = moment.duration(endMoment.diff(now));
      if (timeDifference.asMilliseconds() > 0) {
        setTimeDifference(timeDifference);
        setTime(timeDifference); // Update the time state here
      } else {
        clearInterval(timer);
      }
    }, 1000);
    const remainingTimer = setInterval(() => {
      const now = moment();
      const remainingTime = moment.duration(now.diff(startMoment));
      // console.log("Remaining Time:", remainingTime.asMilliseconds());
      if (remainingTime.asMilliseconds() >= durationMs) {
        // console.log('remainingTimer',remainingTime.asMilliseconds());
        if (attempt_no === 1) {
          performApiBinding(); // Call the API here when the timer reaches 00:00:00
        }
        // performApiBinding(); // Call the API here when the timer reaches 00:00:00
        clearInterval(remainingTimer);
        setTime(moment.duration(0));
      }
    }, 1000);
    // setTimeout(() => {
    //   // console.log('setTimeout',durationMs);
    //   performApiBinding();
    //   clearInterval(timer);
    //   clearInterval(remainingTimer);
    //   setTime(moment.duration(0));
    // }, durationMs);
    return () => {
      clearInterval(timer);
      clearInterval(remainingTimer);
      setTime(moment.duration(0));
    };
  }, []);

  useEffect(() => {
    // Calculate the effective step based on the length of listData
    const effectiveStepsss = Math.ceil(listData.length / 1);
    setQuesStep(effectiveStepsss);
  }, [listData]);

  // question list api load

  useEffect(() => {
    AxiosInstance.post("api/test/questionlist", {
      batch_id: parseInt(batch_id),
      event_id: parseInt(event_id),
      user_id: userid,
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          let ut = res.data.data.que_list;
          setListData(ut);
          let eventNamess = res.data.data.info;
          setEventName({
            event_name: eventNamess.event_type_title,
            startDate: eventNamess.start_date,
            batch_name: eventNamess.batch_name,
            user_profile_path: eventNamess.profile_path,
            user_name: `${eventNamess.first_name} ${eventNamess.last_name}`,
          });
          let data_length = ut.length;
          const number_of_steps = Math.ceil(data_length / step_size);
          setTotalStep(number_of_steps);
          const number_of_steps_answer = Math.ceil(
            data_length / step_size_answer
          );
          setTotalStep_answer(number_of_steps_answer);
          const updatedData = ut.map((item) => ({
            pk: item.pk,
            is_flag: false,
            is_fill: false,
            is_not_visited: true,
            is_un_fill: true,
          }));
          setQuesSet(updatedData);
          const sectionTabEn = ut.some((item) => item.section_tab === 1);
          setSectionTab(sectionTabEn);
          const sectionTabEnSub = ut
            .filter((item) => item.section_tab === 1)
            .map((item) => ({
              subject: item.subject_type,
              pk: item.pk,
            }));

          setSectionTabSub(sectionTabEnSub);
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);

  // get seconds

  function getSeconds(timeString) {
    // Parse the time string as a moment object
    const time = moment(timeString, "HH:mm:ss");

    // Get the total seconds
    const totalSeconds =
      time.hours() * 3600 + time.minutes() * 60 + time.seconds();

    return totalSeconds;
  }

  function calculateTimePerQuestion(totalSeconds, numberOfQuestions) {
    // Calculate the time per question
    const timePerQuestion = totalSeconds / numberOfQuestions;
    return timePerQuestion;
  }

  function getTimeDifferenceInSeconds(startTimeString, endTimeString) {
    // Parse the time strings as moment objects
    const startTime = moment(startTimeString, "HH:mm:ss");
    const endTime = moment(endTimeString, "HH:mm:ss");
    // Calculate the difference in seconds
    const differenceInSeconds = endTime.diff(startTime, "seconds");

    return differenceInSeconds;
  }

  // user input store

  const handleUser = (pk) => {
    setUserInput((prevData) => prevData.filter((obj) => obj.pk !== pk));
    setUserInput_set((prevData) => prevData.filter((obj) => obj.pk !== pk));
    setQuesSet((prevData) => {
      return prevData.map((item) => {
        if (item.pk === pk) {
          return {
            ...item,
            // Toggle the value of is_flag
            is_fill: false,
          };
        }
        return item;
      });
    });
  };

  const handleBook = (pk) => {
    // console.log(pk,quesSet,listData);
    setQuesSet((prevData) => {
      return prevData.map((item) => {
        if (item.pk === pk) {
          return {
            ...item,
            // Toggle the value of is_flag
            is_flag: !item.is_flag,
          };
        }
        return item;
      });
    });
    setListData((prevData) => {
      return prevData.map((item) => {
        if (item.pk === pk) {
          return {
            ...item,
            // Toggle the value of is_flag
            is_bookmark: !item.is_bookmark,
          };
        }
        // else if (item.data && item.data.some((nestedItem) => nestedItem.pk === pk)) {
        //   return {
        //     ...item,
        //     // Use map to update the is_bookmark for each nested item in data
        //     data: item.data.map((nestedItem) =>
        //       nestedItem.pk === pk
        //         ? { ...nestedItem, is_bookmark: !nestedItem.is_bookmark }
        //         : nestedItem
        //     ),
        //   };
        // }
        return item;
      });
    });
  };

  // handle change function for answer option

  const handleOptionChange = (
    event,
    pk,
    batch_id,
    event_pk,
    user_id,
    lang,
    option
  ) => {
    const { value, name, files } = event.target;
    setUserInput((prevState) => {
      const existingIndex = prevState.findIndex((item) => item.pk === pk);
      if (existingIndex !== -1) {
        // Update existing entry in the state
        const updatedItem = {
          ...prevState[existingIndex],
          userOption: value,
          UserOptionSec: option,
          lang: lang,
        };
        const updatedState = [...prevState];
        updatedState[existingIndex] = updatedItem;
        return updatedState;
      } else {
        // Add new entry to the state
        return [
          ...prevState,
          { pk, userOption: value, UserOptionSec: option, lang: lang },
        ];
      }
    });
    setUserInput_set((prevState) => {
      const existingIndex = prevState.findIndex((item) => item.pk === pk);
      if (existingIndex !== -1) {
        // Update existing entry in the state
        const updatedItem = {
          ...prevState[existingIndex],
          userOption: value,
          UserOptionSec: option,
          lang: lang,
        };
        const updatedState = [...prevState];
        updatedState[existingIndex] = updatedItem;
        return updatedState;
      } else {
        // Add new entry to the state
        return [
          ...prevState,
          { pk, userOption: value, UserOptionSec: option, lang: lang },
        ];
      }
    });
    // setSelectedLa(lang);
    setQuesSet((prevData) => {
      return prevData.map((item) => {
        if (item.pk === pk) {
          return {
            ...item,
            is_fill: true,
            is_un_fill: false,
          };
        }
        return item;
      });
    });
  };

  //question set click means need to update stepper

  const handleStepClick = (stepnum, elements) => {
    const lastActiveQuestion = step_answer;
    const timeTaken =
      parseInt(new Date() - refStartTime.current) * MILLISECONDS_TO_SECONDS;
    setStep_answer(stepnum);
    setQuesSet((prevData) => {
      return prevData.map((item) => {
        if (item.pk === elements.pk) {
          return {
            ...item,
            // Toggle the value of is_flag
            is_not_visited: false,
          };
        }
        return item;
      });
    });
    // update time taken by the user

    if (userInput_set.length === 1) {
      const startMoment = moment(startTime, "HH:mm:ss");
      const endMoment = moment(endTime, "HH:mm:ss");
      const durationMs = endMoment.diff(startMoment);

      const hours = String(timeDifference.hours()).padStart(2, "0");
      const minutes = String(timeDifference.minutes()).padStart(2, "0");
      const seconds = String(timeDifference.seconds()).padStart(2, "0");

      const totalSeconds = hours * 3600 + minutes * 60 + seconds;

      const totalQuestion = quesSet.length;

      const TimeTakenSeconds = durationMs - totalSeconds;

      let dat = {
        batch_id: batch_id,
        event_id: event_id,
        question_pk: elements.pk,
        user_answer: userInput_set[0].UserOptionSec,
        language: userInput_set[0].lang,
        subject_name: elements.subject_type,
        // time_taken: TimeTakenSeconds,
        time_taken: timeTaken,
        attempt_no: attempt_no ? attempt_no : 1,
        // optimum_time: durationMs / totalQuestion,
        optimum_time: OPTIMUM_TIME_EACH_QUESTION,
        user_id: userid,
        created_by: userid,
        updated_by: userid,
      };

      AxiosInstance.post("api/test/answer", dat)
        .then((res) => {
          if (res.data.status === true) {
            // reset
            refStartTime.current = new Date();
            // setFormData((prevState) => ({
            //   ...prevState,
            //   pk: res.data.question_pk,
            //   user_answer: res.data.user_answer,
            //   lang: res.data.language,
            //   Lang:2,
            //   LangText : null
            // }));
            setUserInput_set([]);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };

  //  when user cannot submit means  its automatically call this function when time finish

  const performApiBinding = () => {
    if (isSubmit === false) {
      let tim = moment.utc(time.asMilliseconds()).format("HH:mm:ss");

      const ans_ques =
        quesSet.length > 0
          ? quesSet.filter((el) => el.is_fill === true).length
          : 0;

      const ans_ques_mark =
        quesSet.length > 0
          ? quesSet.filter((el) => el.is_flag === true && el.is_fill === true)
              .length
          : 0;

      const unans_ques =
        quesSet.length > 0 ? quesSet.filter((el) => el.is_un_fill).length : 0;

      const unans_mark =
        quesSet.length > 0
          ? quesSet.filter(
              (el) => el.is_flag === true && el.is_un_fill === true
            ).length
          : 0;

      const qu_not_v =
        quesSet.length > 0
          ? quesSet.filter((el) => el.is_not_visited).length
          : 0;

      let datb = {
        batch_id: batch_id,
        event_id: event_id,
        user_id: userid,
        duration_completed: tim,
        created_by: userid,
        ans_que: ans_ques,
        ans_que_mr: ans_ques_mark,
        unans_que: unans_ques,
        unans_que_mr: unans_mark,
        que_nyv: qu_not_v,
        attempt_no: attempt_no ? attempt_no : 1,
      };
      AxiosInstance.post("api/test/report/add", datb)
        .then((res) => {
          //  console.log(res);
          if (res.data.status === true) {
            // Swal.fire({
            //   title: "Success",
            //   text: `Total correct mark : ${res.data.data.marks_obtained}`,
            //   icon: "success",
            //   confirmButtonText: "Ok",
            // }).then((result) => {
            //   setStepSeven(true);
            // });
            setIsSubmit(true);
            setStepSeven(true);
          } else {
            setIsSubmit(true);
            // Swal.fire({
            //   title: "Success",
            //   text: res.data.message,
            //   icon: "success",
            //   confirmButtonText: "Ok",
            // });
            navigateToPreviousScreen();
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };

  // submit answer button function
  const handleSubmit = (
    time,
    to_qu,
    ans_ques,
    ans_ques_mark,
    qu_not_v,
    unans_ques,
    unans_mark
  ) => {
    let datb = {
      batch_id: batch_id,
      event_id: event_id,
      user_id: userid,
      duration_completed: time,
      created_by: userid,
      ans_que: ans_ques,
      ans_que_mr: ans_ques_mark,
      unans_que: unans_ques,
      unans_que_mr: unans_mark,
      que_nyv: qu_not_v,
      attempt_no: attempt_no ? attempt_no : 1,
    };
    AxiosInstance.post("api/test/report/add", datb)
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          // Swal.fire({
          //   title: "Success",
          //   text: `Total correct mark : ${res.data.data.marks_obtained}`,
          //   icon: "success",
          //   confirmButtonText: "Ok",
          // }).then((result) => {
          //   if (result.isConfirmed) {

          //   }
          // });
          setStepSeven(true);
          const modal = document.querySelector("#InstrModel"); // Adjust the selector as needed
          const modal_close = modalCloseRefTest.current;
          if (modal && modal_close) {
            modal_close.click();
          }
          setIsSubmit(true);
          setStepSeven(true);
        } else {
          // Swal.fire({
          //   title: "warning",
          //   text: res.data.message,
          //   icon: "warning",
          //   confirmButtonText: "Ok",
          // });
          navigateToPreviousScreen();
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const handleNext = () => {
    // // 2.time taken
    // const timeTaken = new Date() - refStartTime.current;
    // console.log("laksh-st_time", timeTaken);
    // // reset
    // refStartTime.current = new Date();
    if (step < totalStep) {
      setStep(step + 1);
    } else {
      setStep(1); // Reset to the first step when reaching the end
    }
  };

  const handlePrevious = () => {
    // // reset the time taken start time
    // refStartTime.current = new Date();
    if (step > 1) {
      setStep(step - 1);
    } else {
      setStep(totalStep); // Go back to the last step when at the first step
    }
  };

  const handlePrevious_answer = () => {
    // reset the time taken start time
    refStartTime.current = new Date();
    if (step_answer > 1) {
      setStep_answer(step_answer - 1);
    } else {
      step_answer(totalStep_answer); // Go back to the last step when at the first step
    }
  };

  const handleNext_answer = (elements) => {
    // 2.time taken
    const timeTaken =
      (new Date() - refStartTime.current) * MILLISECONDS_TO_SECONDS;

    setQuesSet((prevData) => {
      return prevData.map((item) => {
        if (item.pk === elements.pk) {
          return {
            ...item,
            // Toggle the value of is_flag
            is_not_visited: false,
          };
        }
        return item;
      });
    });

    const user_answer_input = userInput.find((el) => el.pk === elements.pk);

    const clickedTiming = moment.utc(time.asMilliseconds()).format("HH:mm:ss");
    setQuesTiming(clickedTiming);
    const getSecondsss = getSeconds(timingHours);
    const totalQuestion = quesSet.length;
    const timePerQuestion = calculateTimePerQuestion(
      getSecondsss,
      totalQuestion
    );
    const differenceInSeconds = getTimeDifferenceInSeconds(
      timingHours,
      clickedTiming
    );

    //lalitha
    if (userInput_set.length === 1) {
      const startMoment = moment(startTime, "HH:mm:ss");
      const endMoment = moment(endTime, "HH:mm:ss");
      const durationMs = endMoment.diff(startMoment);

      const hours = String(timeDifference.hours()).padStart(2, "0");
      const minutes = String(timeDifference.minutes()).padStart(2, "0");
      const seconds = String(timeDifference.seconds()).padStart(2, "0");

      const totalSeconds = hours * 3600 + minutes * 60 + seconds;

      const totalQuestion = quesSet.length;

      const TimeTakenSeconds = durationMs - totalSeconds;

      let dat = {
        batch_id: batch_id,
        event_id: event_id,
        question_pk: elements.pk,
        user_answer: userInput_set[0].UserOptionSec,
        language: userInput_set[0].lang,
        subject_name: elements.subject_type,
        // time_taken: TimeTakenSeconds,
        time_taken: timeTaken,
        attempt_no: attempt_no ? attempt_no : 1,
        // optimum_time: durationMs / totalQuestion,
        optimum_time: OPTIMUM_TIME_EACH_QUESTION,
        user_id: userid,
        created_by: userid,
        updated_by: userid,
      };

      AxiosInstance.post("api/test/answer", dat)
        .then((res) => {
          if (res.data.status === true) {
            if (step_answer < totalStep_answer) {
              setStep_answer(step_answer + 1);
            } else {
              setStep_answer(1);
            }
            setUserInput_set([]);
          }
        })
        .catch((error) => {
          return error;
        });
    }
    // laksh
  };

  const handleCheckUserInput = (element) => {
    setQuesSet((prevData) => {
      return prevData.map((item) => {
        if (item.pk === element.pk) {
          return {
            ...item,
            is_not_visited: false,
          };
        }
        return item;
      });
    });
    if (userInput_set.length === 1) {
      const startMoment = moment(startTime, "HH:mm:ss");
      const endMoment = moment(endTime, "HH:mm:ss");
      const durationMs = endMoment.diff(startMoment);
      const hours = String(timeDifference.hours()).padStart(2, "0");
      const minutes = String(timeDifference.minutes()).padStart(2, "0");
      const seconds = String(timeDifference.seconds()).padStart(2, "0");
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      const totalQuestion = quesSet.length;
      const TimeTakenSeconds = durationMs - totalSeconds;
      const timeTaken =
        (new Date() - refStartTime.current) * MILLISECONDS_TO_SECONDS;
      console.log("laksh-timeTaken", timeTaken, refStartTime.current);
      let dat = {
        batch_id: batch_id,
        event_id: event_id,
        question_pk: element.pk,
        user_answer: userInput_set[0].UserOptionSec,
        language: userInput_set[0].lang,
        subject_name: element.subject_type,
        // time_taken: TimeTakenSeconds,
        time_taken: timeTaken,
        attempt_no: attempt_no ? attempt_no : 1,
        optimum_time: durationMs / totalQuestion,
        user_id: userid,
        created_by: userid,
        updated_by: userid,
      };
      AxiosInstance.post("api/test/answer", dat)
        .then((res) => {
          if (res.data.status === true) {
            // setFormData((prevState) => ({
            //   ...prevState,
            //   pk: res.data.question_pk,
            //   user_answer: res.data.user_answer,
            //   lang: res.data.language,
            //   Lang:2,
            //   LangText : null
            // }));
            setUserInput_set([]);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };

  const handleCloseModels_Succ = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleCloseModel = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleSelectCH = (option, dname) => {
    if (dname === "Lang") {
      setFormData((prevState) => ({
        ...prevState,
        Lang: option.value,
        LangText: option.label,
      }));
    }
  };

  const handleSectionactive = (ed) => {
    return listData.some((el, index) => {
      return (
        index === step_answer &&
        el.pk === ed.pk &&
        el.subject_type === ed.subject
      );
    });
  };
  // console.log("swastic", eventName);
  // -------------New Added------------
  const [showAlert, setShowAlert] = useState(false);
  const [idleTime, setIdleTime] = useState(0);
  const [popupClosed, setPopupClosed] = useState(true); // Track if the pop-up is closed

  // Function to reset idle time
  const resetIdleTime = () => {
    setIdleTime(0);
  };

  // Event listener to reset idle time when user interacts with the page
  useEffect(() => {
    const resetIdle = () => {
      resetIdleTime();
    };

    // Attach event listeners conditionally based on the pop-up state
    if (popupClosed) {
      document.addEventListener("mousemove", resetIdle);
      document.addEventListener("keydown", resetIdle);
    } else {
      document.removeEventListener("mousemove", resetIdle);
      document.removeEventListener("keydown", resetIdle);
    }

    return () => {
      document.removeEventListener("mousemove", resetIdle);
      document.removeEventListener("keydown", resetIdle);
    };
  }, [popupClosed]); // Re-run effect when popupClosed changes

  // Function to start idle timer
  useEffect(() => {
    const idleTimer = setInterval(() => {
      setIdleTime((prevIdleTime) => prevIdleTime + 1);
    }, 1000);

    return () => clearInterval(idleTimer);
  }, []);

  // Check if no activity for 20 seconds and show alert
  useEffect(() => {
    if (idleTime >= 120 && !showAlert) {
      setShowAlert(true);
    } else if (idleTime < 120 && showAlert) {
      setShowAlert(false);
    }
  }, [idleTime, showAlert]);

  // Function to handle pop-up close
  const handlePopupClose = () => {
    setShowAlert(false);
    setPopupClosed(true); // Update pop-up state when closed
  };

  return (
    <div className=" BathDet">
      <div className="row">
        {stepSeven === false && (
          <div className="col-md-12">
            {swalMsg.show === true && (
              <ModelComForP
                show={swalMsg.show}
                message={swalMsg.message}
                status={swalMsg.status}
                title={swalMsg.title}
                BottomOkBtn={swalMsg.BottomOkBtn}
                BottomCancelBtn={swalMsg.BottomCancelBtn}
                handleCloseModels={handleCloseModel}
                // handleClose_yes={handleSPayfee}
                BottomOkClose={swalMsg.BottomOkClose}
                handleCloseModels_Succ={handleCloseModels_Succ}
              />
            )}
            {/* TITLE */}
            <section className="StepOne">
              <div className="heading heading_column  mt-3 d-flex justify-content-between ">
                <img src={RedLogo} className="TestLogo" alt="ImageLogo" />
                <h6
                  className={
                    stepSeven === false ? "Tit_witTest" : "Tit_wittTest"
                  }
                >
                  {eventName !== null
                    ? `${eventName.batch_name} - ${eventName.event_name}`
                    : ""}
                </h6>
                <div>
                  <img
                    src={`${ImageUrlPrefix}/${eventName.user_profile_path}`}
                    // src="https://tse4.mm.bing.net/th?id=OIP.WpnGIPj1DKAGo-CP64znTwHaHa&pid=Api&P=0&h=220"
                    className="testProfilepath"
                    alt="ImageLogo"
                  />

                  <span className="testUsernae">{eventName.user_name}</span>
                </div>

                {stepSeven === false && (
                  <div className="Timing_Sec">
                    <div className="row">
                      <div className="col-md-12 vvv_time">
                        <span className="Time">
                          {" "}
                          {String(timeDifference.hours()).padStart(2, "0")}
                        </span>
                        <span className="time_span ">h</span>
                        <span className="Time ms-1">
                          {" "}
                          {String(timeDifference.minutes()).padStart(
                            2,
                            "0"
                          )}{" "}
                        </span>
                        <span className="time_span "> m </span>
                        <span className="Time ms-1">
                          {" "}
                          {String(timeDifference.seconds()).padStart(
                            2,
                            "0"
                          )}{" "}
                          <span className="time_span "> s </span>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
            {/* ANSWER STATUS LIST */}
            <section className="StepOne">
              {showAlert && (
                <WarningPopUp
                  event={eventName}
                  performApiBinding={performApiBinding}
                  onClose={handlePopupClose}
                />
              )}
              <div className="row box">
                <div className="col-md-12">
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <div className="forms d-flex flex-row">
                        <div className="questionSet_with">
                          {quesSet.length > 0
                            ? quesSet.filter((el) => el.is_not_visited).length
                            : 0}
                        </div>
                        <span htmlFor="inputName" className="QuesLabel_test ">
                          Question Not Yet Visited
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="forms d-flex flex-row">
                        <div className="questionSet_with_green">
                          {quesSet.length > 0
                            ? quesSet.filter((el) => el.is_fill === true).length
                            : 0}
                        </div>
                        <span htmlFor="inputName" className="QuesLabel_test">
                          Answered Question
                        </span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="forms d-flex flex-row">
                        <div className="questionSet_with_red">
                          {quesSet.length > 0
                            ? quesSet.filter((el) => el.is_un_fill).length
                            : 0}
                        </div>
                        <span htmlFor="inputName" className="QuesLabel_test">
                          Unanswered Question
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="forms d-flex flex-row">
                        <div className="questionSet_with_blue">
                          {" "}
                          <span>
                            {quesSet.length > 0
                              ? quesSet.filter(
                                  (el) =>
                                    el.is_flag === true && el.is_fill === true
                                ).length
                              : 0}
                          </span>{" "}
                          <img
                            src={FlagGreen}
                            className="flaggreen"
                            alt="flag_green"
                          />{" "}
                        </div>
                        <span htmlFor="inputName" className="QuesLabel_test">
                          Answered Question Marked for Review
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="forms d-flex flex-row">
                        <div className="questionSet_with_blue">
                          <span>
                            {quesSet.length > 0
                              ? quesSet.filter(
                                  (el) =>
                                    el.is_flag === true &&
                                    el.is_un_fill === true
                                ).length
                              : 0}
                          </span>{" "}
                          <img
                            src={FlagRed}
                            className="flagred"
                            alt="flag_green"
                          />
                        </div>
                        <span htmlFor="inputName" className="QuesLabel_test">
                          Unanswered Question Marked for Review
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        {stepSeven === true && (
          <div className="col-md-12 ">
            <span className="Submit_welcome">
              <PaymentSuccess
                batchID={batch_id}
                eventID={event_id}
                userID={userid}
                startTime={startTime}
                endTime={endTime}
                finishTime={moment
                  .utc(time.asMilliseconds())
                  .format("HH:mm:ss")}
                event={eventName}
              />
              {/* Thank you for participating in the test. Your results will be
              available shortly. You can view your scores on the{" "}
              <Link className="" to={"/TestPerformanceListView"}>
                Test Performance
              </Link>{" "}
              page once they are published. */}
            </span>
          </div>
        )}
        {/* QUESTIONS */}
        {stepSeven === false && (
          <div className="col-md-9 col-sm-12 col-xs-12">
            <fieldset>
              {sectionTab === true && (
                <section className="StepOne">
                  <div className="row box_reviews">
                    <div className="col-md-12">
                      <p className="StepTitle margin-left_25">Sections</p>
                      <div className="row mb-4 mt-2">
                        <div className="col-md-12 tabSection">
                          <ul
                            className="nav  nav-fill mb-2 mt-2"
                            id="listViewTabs_sec"
                            role="tablist"
                          >
                            {sectionTabSub.length > 0 ? (
                              sectionTabSub.map((ed, index) => (
                                <li
                                  className="nav-item"
                                  role="presentation"
                                  key={index}
                                >
                                  <a
                                    className={
                                      handleSectionactive(ed)
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    data-mdb-toggle="tab"
                                    href="javascript:void(0)"
                                    role="tab"
                                    aria-controls={`ex2-tabs-${index + 1}`}
                                  >
                                    {ed.subject}
                                  </a>
                                </li>
                              ))
                            ) : (
                              <li className="nav-item" role="presentation">
                                <a
                                  // className={activeTab === index + 1 ? "nav-link active" : "nav-link"}
                                  className={"nav-link"}
                                  data-mdb-toggle="tab"
                                  href="javascript:void(0)"
                                  role="tab"
                                  aria-controls="ex2-tabs-1"
                                  // aria-selected={activeTab === index + 1 }
                                  // onClick={() => handleTabClick(index + 1, el.batch_id)}
                                >
                                  General Awareness
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              <section className="StepOne">
                {listData.length > 0 &&
                  listData.map((el, index) => {
                    // Calculate the effective step based on the loop count
                    const Thestepper = Math.ceil((index + 1) / 1);
                    if (step_answer === Thestepper) {
                      if (el.display_type === 0) {
                        return (
                          <>
                            <div className="row box_review">
                              <div className="col-md-12">
                                <p className="StepTitle margin-left_30  mb-1">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>

                                <div className="row">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4  d-flex justify-content-end">
                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <img
                                        src={capFlag}
                                        onClick={() => handleUser(el.pk)}
                                        className={`${
                                          el.is_bookmark === false
                                            ? "close_capture"
                                            : "close_capture"
                                        }`}
                                        alt="capture_flag"
                                      />
                                    )}

                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <span
                                        onClick={() => handleUser(el.pk)}
                                        className="close_capture_text"
                                      >
                                        Unselect My Answer
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                    <img
                                      src={capRedFlag}
                                      onClick={() => handleBook(el.pk)}
                                      className={`${
                                        el.is_bookmark === false
                                          ? "flag_capture_sta"
                                          : "flag_capture_sta"
                                      }`}
                                      alt="capture_flag"
                                    />
                                    <span
                                      onClick={() => handleBook(el.pk)}
                                      className="flag_question"
                                    >
                                      {el.is_bookmark === false
                                        ? "Mark Question for Review"
                                        : "UnMark Question for Review"}
                                    </span>
                                  </div>
                                </div>

                                <div className="row QuestionRow">
                                  <QtypeOne
                                    userInput={userInput}
                                    handleOptionChange={handleOptionChange}
                                    index={index}
                                    Lang={formData.Lang}
                                    list={el}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-4  display_sm_center p-0">
                                {step_answer <= totalStep_answer - 2 ? (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={() => handlePrevious_answer(el.pk)}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={() => handlePrevious_answer(el.pk)}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center d-flex justify-content-center">
                                {step_answer === totalStep_answer && (
                                  <button
                                    type="button"
                                    className="submit_bun width_100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#InstrModel"
                                    onClick={() => handleCheckUserInput(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Submit Answer
                                    </a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center  p-0 d-flex justify-content-end">
                                {step_answer === totalStep_answer ? (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                    disabled
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      } else if (el.display_type === 1) {
                        return (
                          <>
                            <div className="row box_review">
                              <div className="col-md-12">
                                <p className="StepTitle mb-1">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>
                                <div className="row">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4 d-flex justify-content-end">
                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <img
                                        src={capFlag}
                                        onClick={() => handleUser(el.pk)}
                                        className={`${
                                          el.is_bookmark === false
                                            ? "close_capture"
                                            : "close_capture"
                                        }`}
                                        alt="capture_flag"
                                      />
                                    )}

                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <span
                                        onClick={() => handleUser(el.pk)}
                                        className="close_capture_text"
                                      >
                                        Deselect My Answer
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                    <img
                                      src={capRedFlag}
                                      onClick={() => handleBook(el.pk)}
                                      className={`${
                                        el.is_bookmark === false
                                          ? "flag_capture_sta"
                                          : "flag_capture_sta"
                                      }`}
                                      alt="capture_flag"
                                    />
                                    <span
                                      onClick={() => handleBook(el.pk)}
                                      className="flag_question"
                                    >
                                      {el.is_bookmark === false
                                        ? "Mark Question for Review"
                                        : "UnMark Question for Review"}
                                    </span>
                                  </div>
                                </div>

                                <div className="row QuestionRow">
                                  <QtypeTwo
                                    userInput={userInput}
                                    handleOptionChange={handleOptionChange}
                                    index={index}
                                    Lang={formData.Lang}
                                    list={el}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 display_sm_center p-0">
                                {step_answer <= totalStep_answer - 2 ? (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center d-flex justify-content-center">
                                {step_answer === totalStep_answer && (
                                  <button
                                    type="button"
                                    className="submit_bun width_100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#InstrModel"
                                    onClick={() => handleCheckUserInput(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Submit Answer
                                    </a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                {step_answer === totalStep_answer ? (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                    disabled
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      } else if (el.display_type === 2) {
                        return (
                          <>
                            <div className="row box_review">
                              <div className="col-md-12">
                                <p className="StepTitle mb-1">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>

                                <div className="row">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4 d-flex justify-content-end">
                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <img
                                        src={capFlag}
                                        onClick={() => handleUser(el.pk)}
                                        className={`${
                                          el.is_bookmark === false
                                            ? "close_capture"
                                            : "close_capture"
                                        }`}
                                        alt="capture_flag"
                                      />
                                    )}

                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <span
                                        onClick={() => handleUser(el.pk)}
                                        className="close_capture_text"
                                      >
                                        Deselect My Answer
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                    <img
                                      src={capRedFlag}
                                      onClick={() => handleBook(el.pk)}
                                      className={`${
                                        el.is_bookmark === false
                                          ? "flag_capture_sta"
                                          : "flag_capture_sta"
                                      }`}
                                      alt="capture_flag"
                                    />
                                    <span
                                      onClick={() => handleBook(el.pk)}
                                      className="flag_question"
                                    >
                                      {el.is_bookmark === false
                                        ? "Mark Question for Review"
                                        : "UnMark Question for Review"}
                                    </span>
                                  </div>
                                </div>

                                <div className="row QuestionRow">
                                  <QtypeThree
                                    userInput={userInput}
                                    handleOptionChange={handleOptionChange}
                                    index={index}
                                    Lang={formData.Lang}
                                    list={el}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 display_sm_center p-0">
                                {step_answer <= totalStep_answer - 2 ? (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center d-flex justify-content-center">
                                {step_answer === totalStep_answer && (
                                  <button
                                    type="button"
                                    className="submit_bun width_100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#InstrModel"
                                    onClick={() => handleCheckUserInput(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Submit Answer
                                    </a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                {step_answer === totalStep_answer ? (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                    disabled
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      } else if (el.display_type === 3) {
                        return (
                          <>
                            <div className="row box_review">
                              <div className="col-md-12">
                                <p className="StepTitle mb-1">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>
                                <div className="row">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4 d-flex justify-content-end">
                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <img
                                        src={capFlag}
                                        onClick={() => handleUser(el.pk)}
                                        className={`${
                                          el.is_bookmark === false
                                            ? "close_capture"
                                            : "close_capture"
                                        }`}
                                        alt="capture_flag"
                                      />
                                    )}

                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <span
                                        onClick={() => handleUser(el.pk)}
                                        className="close_capture_text"
                                      >
                                        Deselect My Answer
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                    <img
                                      src={capRedFlag}
                                      onClick={() => handleBook(el.pk)}
                                      className={`${
                                        el.is_bookmark === false
                                          ? "flag_capture_sta"
                                          : "flag_capture_sta"
                                      }`}
                                      alt="capture_flag"
                                    />
                                    <span
                                      onClick={() => handleBook(el.pk)}
                                      className="flag_question"
                                    >
                                      {el.is_bookmark === false
                                        ? "Mark Question for Review"
                                        : "UnMark Question for Review"}
                                    </span>
                                  </div>
                                </div>
                                <div className="row QuestionRow">
                                  <QtypeFour
                                    userInput={userInput}
                                    handleOptionChange={handleOptionChange}
                                    index={index}
                                    Lang={formData.Lang}
                                    list={el}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 display_sm_center p-0">
                                {step_answer <= totalStep_answer - 2 ? (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center d-flex justify-content-center">
                                {step_answer === totalStep_answer && (
                                  <button
                                    type="button"
                                    className="submit_bun width_100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#InstrModel"
                                    onClick={() => handleCheckUserInput(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Submit Answer
                                    </a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                {step_answer === totalStep_answer ? (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                    disabled
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      } else if (el.display_type === 4) {
                        return (
                          <>
                            <div className="row box_review">
                              <div className="col-md-12">
                                <p className="StepTitle mb-1">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>
                                <div className="row">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4 d-flex justify-content-end">
                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <img
                                        src={capFlag}
                                        onClick={() => handleUser(el.pk)}
                                        className={`${
                                          el.is_bookmark === false
                                            ? "close_capture"
                                            : "close_capture"
                                        }`}
                                        alt="capture_flag"
                                      />
                                    )}

                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <span
                                        onClick={() => handleUser(el.pk)}
                                        className="close_capture_text"
                                      >
                                        Deselect My Answer
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                    <img
                                      src={capRedFlag}
                                      onClick={() => handleBook(el.pk)}
                                      className={`${
                                        el.is_bookmark === false
                                          ? "flag_capture_sta"
                                          : "flag_capture_sta"
                                      }`}
                                      alt="capture_flag"
                                    />
                                    <span
                                      onClick={() => handleBook(el.pk)}
                                      className="flag_question"
                                    >
                                      {el.is_bookmark === false
                                        ? "Mark Question for Review"
                                        : "UnMark Question for Review"}
                                    </span>
                                  </div>
                                </div>

                                <div className="row QuestionRow">
                                  <QtypeFive
                                    userInput={userInput}
                                    handleOptionChange={handleOptionChange}
                                    index={index}
                                    Lang={formData.Lang}
                                    list={el}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 display_sm_center p-0">
                                {step_answer <= totalStep_answer - 2 ? (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center d-flex justify-content-center">
                                {step_answer === totalStep_answer && (
                                  <button
                                    type="button"
                                    className="submit_bun width_100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#InstrModel"
                                    onClick={() => handleCheckUserInput(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Submit Answer
                                    </a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                {step_answer === totalStep_answer ? (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                    disabled
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      } else if (el.display_type === 5) {
                        return (
                          <>
                            <div className="row box_review">
                              <div className="col-md-12">
                                <p className="StepTitle mb-1">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>
                                <div className="row">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4 d-flex justify-content-end">
                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <img
                                        src={capFlag}
                                        onClick={() => handleUser(el.pk)}
                                        className={`${
                                          el.is_bookmark === false
                                            ? "close_capture"
                                            : "close_capture"
                                        }`}
                                        alt="capture_flag"
                                      />
                                    )}

                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <span
                                        onClick={() => handleUser(el.pk)}
                                        className="close_capture_text"
                                      >
                                        Deselect My Answer
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                    <img
                                      src={capRedFlag}
                                      onClick={() => handleBook(el.pk)}
                                      className={`${
                                        el.is_bookmark === false
                                          ? "flag_capture_sta"
                                          : "flag_capture_sta"
                                      }`}
                                      alt="capture_flag"
                                    />
                                    <span
                                      onClick={() => handleBook(el.pk)}
                                      className="flag_question"
                                    >
                                      {el.is_bookmark === false
                                        ? "Mark Question for Review"
                                        : "UnMark Question for Review"}
                                    </span>
                                  </div>
                                </div>
                                <div className="row">
                                  <Qtypesix
                                    userInput={userInput}
                                    handleOptionChange={handleOptionChange}
                                    index={index}
                                    Lang={formData.Lang}
                                    list={el}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4  display_sm_center p-0">
                                {step_answer <= totalStep_answer - 2 ? (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center d-flex justify-content-center">
                                {step_answer === totalStep_answer && (
                                  <button
                                    type="button"
                                    className="submit_bun width_100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#InstrModel"
                                    onClick={() => handleCheckUserInput(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Submit Answer
                                    </a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                {step_answer === totalStep_answer ? (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                    disabled
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      } else if (el.display_type === 6) {
                        return (
                          <>
                            <div className="row box_review">
                              <div className="col-md-12">
                                <p className="StepTitle mb-1">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>
                                <div className="row">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4 d-flex justify-content-end">
                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <img
                                        src={capFlag}
                                        onClick={() => handleUser(el.pk)}
                                        className={`${
                                          el.is_bookmark === false
                                            ? "close_capture"
                                            : "close_capture"
                                        }`}
                                        alt="capture_flag"
                                      />
                                    )}

                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <span
                                        onClick={() => handleUser(el.pk)}
                                        className="close_capture_text"
                                      >
                                        Deselect My Answer
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                    <img
                                      src={capRedFlag}
                                      onClick={() => handleBook(el.pk)}
                                      className={`${
                                        el.is_bookmark === false
                                          ? "flag_capture_sta"
                                          : "flag_capture_sta"
                                      }`}
                                      alt="capture_flag"
                                    />
                                    <span
                                      onClick={() => handleBook(el.pk)}
                                      className="flag_question"
                                    >
                                      {el.is_bookmark === false
                                        ? "Mark Question for Review"
                                        : "UnMark Question for Review"}
                                    </span>
                                  </div>
                                </div>

                                <div className="row">
                                  <Qtypeseven
                                    userInput={userInput}
                                    handleOptionChange={handleOptionChange}
                                    index={index}
                                    Lang={formData.Lang}
                                    list={el}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 display_sm_center p-0">
                                {step_answer <= totalStep_answer - 2 ? (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center d-flex justify-content-center">
                                {step_answer === totalStep_answer && (
                                  <button
                                    type="button"
                                    className="submit_bun width_100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#InstrModel"
                                    onClick={() => handleCheckUserInput(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Submit Answer
                                    </a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                {step_answer === totalStep_answer ? (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                    disabled
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      } else if (el.display_type === 7) {
                        return (
                          <>
                            <div className="row box_review">
                              <div className="col-md-12">
                                <p className="StepTitle mb-1">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>
                                <div className="row">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4 d-flex justify-content-end">
                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <img
                                        src={capFlag}
                                        onClick={() => handleUser(el.pk)}
                                        className={`${
                                          el.is_bookmark === false
                                            ? "close_capture"
                                            : "close_capture"
                                        }`}
                                        alt="capture_flag"
                                      />
                                    )}

                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <span
                                        onClick={() => handleUser(el.pk)}
                                        className="close_capture_text"
                                      >
                                        Deselect My Answer
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                    <img
                                      src={capRedFlag}
                                      onClick={() => handleBook(el.pk)}
                                      className={`${
                                        el.is_bookmark === false
                                          ? "flag_capture_sta"
                                          : "flag_capture_sta"
                                      }`}
                                      alt="capture_flag"
                                    />
                                    <span
                                      onClick={() => handleBook(el.pk)}
                                      className="flag_question"
                                    >
                                      {el.is_bookmark === false
                                        ? "Mark Question for Review"
                                        : "UnMark Question for Review"}
                                    </span>
                                  </div>
                                </div>

                                <div className="row">
                                  <Qtypeeight
                                    userInput={userInput}
                                    handleOptionChange={handleOptionChange}
                                    index={index}
                                    list={el}
                                    Lang={formData.Lang}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 display_sm_center p-0">
                                {step_answer <= totalStep_answer - 2 ? (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center d-flex justify-content-center">
                                {step_answer === totalStep_answer && (
                                  <button
                                    type="button"
                                    className="submit_bun width_100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#InstrModel"
                                    onClick={() => handleCheckUserInput(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Submit Answer
                                    </a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                {step_answer === totalStep_answer ? (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                    disabled
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      } else if (el.display_type === 8) {
                        return (
                          <>
                            <div className="row box_review">
                              <div className="col-md-12">
                                <p className="StepTitle mb-1">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>
                                <div className="row">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4 d-flex justify-content-end">
                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <img
                                        src={capFlag}
                                        onClick={() => handleUser(el.pk)}
                                        className={`${
                                          el.is_bookmark === false
                                            ? "close_capture"
                                            : "close_capture"
                                        }`}
                                        alt="capture_flag"
                                      />
                                    )}

                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <span
                                        onClick={() => handleUser(el.pk)}
                                        className="close_capture_text"
                                      >
                                        Deselect My Answer
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                    <img
                                      src={capRedFlag}
                                      onClick={() => handleBook(el.pk)}
                                      className={`${
                                        el.is_bookmark === false
                                          ? "flag_capture_sta"
                                          : "flag_capture_sta"
                                      }`}
                                      alt="capture_flag"
                                    />
                                    <span
                                      onClick={() => handleBook(el.pk)}
                                      className="flag_question"
                                    >
                                      {el.is_bookmark === false
                                        ? "Mark Question for Review"
                                        : "UnMark Question for Review"}
                                    </span>
                                  </div>
                                </div>

                                <div className="row">
                                  <Qtypenine
                                    userInput={userInput}
                                    handleOptionChange={handleOptionChange}
                                    index={index}
                                    Lang={formData.Lang}
                                    list={el}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 display_sm_center p-0">
                                {step_answer <= totalStep_answer - 2 ? (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center d-flex justify-content-center">
                                {step_answer === totalStep_answer && (
                                  <button
                                    type="button"
                                    className="submit_bun width_100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#InstrModel"
                                    onClick={() => handleCheckUserInput(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Submit Answer
                                    </a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                {step_answer === totalStep_answer ? (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                    disabled
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      } else if (el.display_type === 9) {
                        return (
                          <>
                            <div className="row box_review">
                              <div className="col-md-12">
                                <p className="StepTitle mb-1">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>
                                <div className="row">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4 d-flex justify-content-end">
                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <img
                                        src={capFlag}
                                        onClick={() => handleUser(el.pk)}
                                        className={`${
                                          el.is_bookmark === false
                                            ? "close_capture"
                                            : "close_capture"
                                        }`}
                                        alt="capture_flag"
                                      />
                                    )}

                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <span
                                        onClick={() => handleUser(el.pk)}
                                        className="close_capture_text"
                                      >
                                        Deselect My Answer
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                    <img
                                      src={capRedFlag}
                                      onClick={() => handleBook(el.pk)}
                                      className={`${
                                        el.is_bookmark === false
                                          ? "flag_capture"
                                          : "flag_capture_sta"
                                      }`}
                                      alt="capture_flag"
                                    />
                                    <span
                                      onClick={() => handleBook(el.pk)}
                                      className="flag_question"
                                    >
                                      {el.is_bookmark === false
                                        ? "Mark Question for Review"
                                        : "UnMark Question for Review"}
                                    </span>
                                  </div>
                                </div>

                                <div className="row">
                                  <QtypeTen
                                    userInput={userInput}
                                    handleOptionChange={handleOptionChange}
                                    index={index}
                                    Lang={formData.Lang}
                                    list={el}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 display_sm_center p-0">
                                {step_answer <= totalStep_answer - 2 ? (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center d-flex justify-content-center">
                                {step_answer === totalStep_answer && (
                                  <button
                                    type="button"
                                    className="submit_bun width_100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#InstrModel"
                                    onClick={() => handleCheckUserInput(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Submit Answer
                                    </a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                {step_answer === totalStep_answer ? (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                    disabled
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      } else if (el.display_type === 10) {
                        return (
                          <>
                            <div className="row box_review">
                              <div className="col-md-12">
                                <p className="StepTitle mb-1">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>
                                <div className="row">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4 d-flex justify-content-end">
                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <img
                                        src={capFlag}
                                        onClick={() => handleUser(el.pk)}
                                        className={`${
                                          el.is_bookmark === false
                                            ? "close_capture"
                                            : "close_capture"
                                        }`}
                                        alt="capture_flag"
                                      />
                                    )}

                                    {userInput.some(
                                      (item) => item.pk === el.pk
                                    ) && (
                                      <span
                                        onClick={() => handleUser(el.pk)}
                                        className="close_capture_text"
                                      >
                                        Deselect My Answer
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                    <img
                                      src={capRedFlag}
                                      onClick={() => handleBook(el.pk)}
                                      className={`${
                                        el.is_bookmark === false
                                          ? "flag_capture"
                                          : "flag_capture_sta"
                                      }`}
                                      alt="capture_flag"
                                    />
                                    <span
                                      onClick={() => handleBook(el.pk)}
                                      className="flag_question"
                                    >
                                      {el.is_bookmark === false
                                        ? "Mark Question for Review"
                                        : "UnMark Question for Review"}
                                    </span>
                                  </div>
                                </div>

                                <div className="row">
                                  {el.para_text_tm !== "" &&
                                    el.para_text_tm !== null &&
                                    (formData.Lang === 0 ||
                                      formData.Lang === 2) && (
                                      <QparaType
                                        index={index}
                                        list={el.para_text_tm}
                                      />
                                    )}

                                  {el.para_text_en !== "" &&
                                    el.para_text_en !== null &&
                                    (formData.Lang === 1 ||
                                      formData.Lang === 2) && (
                                      <QparaType
                                        index={index}
                                        list={el.para_text_en}
                                      />
                                    )}
                                </div>
                                <div className="row">
                                  <Qquesparatype
                                    userInput={userInput}
                                    handleOptionChange={handleOptionChange}
                                    index={index}
                                    list={el}
                                    Lang={formData.Lang}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 display_sm_center p-0">
                                {step_answer <= totalStep_answer - 2 ? (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nNButton width_100"
                                    onClick={handlePrevious_answer}
                                    disabled={step_answer === 1 ? true : false}
                                  >
                                    <a href="javascript:void(0)">Previous</a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4 display_sm_center d-flex justify-content-center">
                                {step_answer === totalStep_answer && (
                                  <button
                                    type="button"
                                    className="submit_bun width_100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#InstrModel"
                                    onClick={() => handleCheckUserInput(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Submit Answer
                                    </a>
                                  </button>
                                )}
                              </div>
                              <div className="col-md-4  display_sm_center p-0 d-flex justify-content-end">
                                {step_answer === totalStep_answer ? (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                    disabled
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="nPButton width_100"
                                    onClick={() => handleNext_answer(el)}
                                  >
                                    <a href="javascript:void(0)">
                                      Save and Next
                                    </a>
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      }
                    }
                    return null;
                  })}
              </section>
            </fieldset>
          </div>
        )}
        {/* SIDE COMPONENT */}
        {stepSeven === false && (
          <div className="col-md-3 col-sm-12 col-xs-12">
            {sectionTab === true && (
              <section className="StepOne">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle mb-1">View In</p>
                    <div className="forms  ">
                      <Select
                        id="Lang"
                        name="Lang"
                        value={
                          formData.Lang !== null && formData.Lang !== ""
                            ? {
                                value: formData.Lang,
                                label: formData.LangText,
                              }
                            : {
                                value: 2,
                                label: "All",
                              }
                        }
                        onChange={(option) => handleSelectCH(option, "Lang")}
                        options={
                          langSet
                            .filter((item) => item && item.label) // Filter out null and undefined elements
                            .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                        }
                        isSearchable={true} // Enables search functionality
                        placeholder="Select an option"
                        maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                        styles={customStyles} // Apply custom styles her
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}

            <section className="StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle mb-1">
                    Question Set -{" "}
                    {`${step} to 50 of ${
                      quesSet.length > 0 ? quesSet.length : "0"
                    }`}
                  </p>
                  <div className="row mt-1">
                    {quesSet.length > 0 &&
                      quesSet
                        .slice((step - 1) * step_size, step * step_size)
                        .map((el, index) => {
                          const questionNumber =
                            index + 1 + (step - 1) * step_size;
                          if (effStep === step) {
                            return (
                              <div
                                onClick={() =>
                                  handleStepClick(questionNumber, el)
                                }
                                className={`col-md-2 d-flex flex-row ${
                                  el.is_fill === true
                                    ? el.is_flag === true
                                      ? "questionSet_with_blue_right"
                                      : "questionSet_with_green_set"
                                    : el.is_flag === true
                                    ? "questionSet_with_blue_right"
                                    : el.is_not_visited === false
                                    ? "questionSet_with_red_sets"
                                    : "questionSet_with_right"
                                }`}
                                title={el.pk}
                                key={index}
                              >
                                {" "}
                                <span
                                  className={`${
                                    el.is_fill === true
                                      ? el.is_flag === true
                                        ? "mark_number"
                                        : "mark_number"
                                      : el.is_flag === true
                                      ? "mark_number"
                                      : el.is_fill === true
                                      ? "mark_number"
                                      : el.is_not_visited === false
                                      ? "mark_number"
                                      : "mark_numbers"
                                  }`}
                                >
                                  {questionNumber}
                                </span>
                                {/* {el.is_fill === true && (
                      
                     <span className="correct_mark">✓</span>
                   )} */}
                                {el.is_flag === true && (
                                  <img
                                    src={
                                      el.is_flag === true && el.is_fill === true
                                        ? FlagGreen
                                        : el.is_flag === true
                                        ? FlagRed
                                        : FlagWhite
                                    }
                                    className="flgQuestionSetImg"
                                    alt="flag_green"
                                  />
                                )}
                              </div>
                            );
                          } else {
                            return (
                              <div
                                onClick={() =>
                                  handleStepClick(questionNumber, el.pk)
                                }
                                className={`col-md-2 d-flex flex-row ${
                                  el.is_fill === true
                                    ? el.is_flag === true
                                      ? "questionSet_with_blue_right"
                                      : "questionSet_with_green_set"
                                    : el.is_flag === true
                                    ? "questionSet_with_blue_right"
                                    : el.is_not_visited === false
                                    ? "questionSet_with_red_sets"
                                    : "questionSet_with_right"
                                }`}
                                title={el.pk}
                                key={index}
                              >
                                <span
                                  className={`${
                                    el.is_fill === true
                                      ? el.is_flag === true
                                        ? "mark_number"
                                        : "mark_number"
                                      : el.is_flag === true
                                      ? "mark_number"
                                      : el.is_fill === true
                                      ? "mark_number"
                                      : el.is_not_visited === false
                                      ? "mark_number"
                                      : "mark_numbers"
                                  }`}
                                >
                                  {questionNumber}
                                </span>
                                {el.is_flag === true && (
                                  <img
                                    src={
                                      el.is_flag === true && el.is_fill === true
                                        ? FlagGreen
                                        : el.is_flag === true
                                        ? FlagRed
                                        : FlagWhite
                                    }
                                    className="flgQuestionSetImg"
                                    alt="flag_green"
                                  />
                                )}
                              </div>
                            );
                          }
                        })}
                  </div>
                </div>

                <div className="col-md-12 col-sm-12 col-lg-12 mb-3 mt-4 d-flex justify-content-center">
                  <button
                    type="button"
                    className="PrevIcons_ques"
                    onClick={handlePrevious}
                    disabled={step === 1 ? true : false}
                  >
                    <span className="material-icons">keyboard_backspace</span>
                  </button>

                  <button
                    type="button"
                    className="PrevIcons_ques"
                    onClick={handleNext}
                    disabled={step >= totalStep ? true : false}
                  >
                    <span className="material-icons">east</span>
                  </button>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>

      <div
        class="modal"
        id="InstrModel"
        tabindex="-1"
        aria-labelledby="InstrModel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="InstrModelLabel">
                Submit Your Answers
              </h5>
              <button
                type="button"
                class="btn-close"
                ref={modalCloseRefTest}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="model_inst_in">
                    Are you sure you want to submit your answers?
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 p-0">
                  <TestDetailsBox_submit
                    style={{
                      background:
                        "transparent linear-gradient(180deg, #FF6384 0%, #803242 100%) 0% 0% no-repeat padding-box",
                      boxShadow:
                        " inset -6px -6px 6px #00000029, 3px 3px 6px #00000029",
                    }}
                  >
                    <div>
                      <TestDetailsLabel_submit>
                        {"Total Number of Questions"}
                      </TestDetailsLabel_submit>
                      <TestDeatilsScore_submit>
                        {quesSet.length > 0 ? quesSet.length : 0}
                      </TestDeatilsScore_submit>
                    </div>
                  </TestDetailsBox_submit>
                </div>
                <div className="col-md-4 p-0">
                  <TestDetailsBox_submit
                    style={{
                      background:
                        "transparent linear-gradient(180deg, #4BC0C0 0%, #266060 100%) 0% 0% no-repeat padding-box",
                      boxShadow:
                        "inset -6px -6px 6px #00000029, 3px 3px 6px #00000029",
                    }}
                  >
                    <div>
                      <TestDetailsLabel_submit>
                        {"Answered Questions"}
                      </TestDetailsLabel_submit>
                      <TestDeatilsScore_submit>
                        {" "}
                        {quesSet.length > 0
                          ? quesSet.filter((el) => el.is_fill === true).length
                          : 0}
                      </TestDeatilsScore_submit>
                    </div>
                  </TestDetailsBox_submit>
                </div>
                <div className="col-md-4 p-0">
                  <TestDetailsBox_submit
                    style={{
                      background:
                        "transparent linear-gradient(180deg, #FF9F40  0%, #805020  100%) 0% 0% no-repeat padding-box",
                      boxShadow:
                        "inset -6px -6px 6px #00000029, 3px 3px 6px #00000029",
                    }}
                  >
                    <div>
                      <TestDetailsLabel_submit>
                        {"Answered Questions Marked for Review"}
                      </TestDetailsLabel_submit>
                      <TestDeatilsScore_submit>
                        {quesSet.length > 0
                          ? quesSet.filter(
                              (el) => el.is_flag === true && el.is_fill === true
                            ).length
                          : 0}
                      </TestDeatilsScore_submit>
                    </div>
                  </TestDetailsBox_submit>
                </div>
                <div className="col-md-4 p-0">
                  <TestDetailsBox_submit
                    style={{
                      background:
                        "transparent linear-gradient(180deg, #FFCD56   0%, #80672B   100%) 0% 0% no-repeat padding-box",
                      boxShadow:
                        "inset -6px -6px 6px #00000029, 3px 3px 6px #00000029",
                    }}
                  >
                    <div>
                      <TestDetailsLabel_submit>
                        {"Questions Not Yet Visited"}
                      </TestDetailsLabel_submit>
                      <TestDeatilsScore_submit>
                        {" "}
                        {quesSet.length > 0
                          ? quesSet.filter((el) => el.is_not_visited).length
                          : 0}
                      </TestDeatilsScore_submit>
                    </div>
                  </TestDetailsBox_submit>
                </div>

                <div className="col-md-4 p-0">
                  <TestDetailsBox_submit
                    style={{
                      background:
                        "transparent linear-gradient(180deg, #36A2EB  0%, #1B5176    100%) 0% 0% no-repeat padding-box",
                      boxShadow:
                        "inset -6px -6px 6px #00000029, 3px 3px 6px #00000029",
                    }}
                  >
                    <div>
                      <TestDetailsLabel_submit>
                        {"Unanswered Questions"}
                      </TestDetailsLabel_submit>
                      <TestDeatilsScore_submit>
                        {" "}
                        {quesSet.length > 0
                          ? quesSet.filter((el) => el.is_un_fill).length
                          : 0}
                      </TestDeatilsScore_submit>
                    </div>
                  </TestDetailsBox_submit>
                </div>

                <div className="col-md-4 p-0">
                  <TestDetailsBox_submit
                    style={{
                      background:
                        "transparent linear-gradient(180deg, #9966FF   0%, #4D3380     100%) 0% 0% no-repeat padding-box",
                      boxShadow:
                        "inset -6px -6px 6px #00000029, 3px 3px 6px #00000029",
                    }}
                  >
                    <div>
                      <TestDetailsLabel_submit>
                        {"Unanswered Questions Marked for Review"}
                      </TestDetailsLabel_submit>
                      <TestDeatilsScore_submit>
                        {quesSet.length > 0
                          ? quesSet.filter(
                              (el) =>
                                el.is_flag === true && el.is_un_fill === true
                            ).length
                          : 0}
                      </TestDeatilsScore_submit>
                    </div>
                  </TestDetailsBox_submit>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <Link data-bs-dismiss="modal" className="CancelButton mt-1">
                Don't Submit
              </Link>
              <button
                type="button"
                className="SaveButton"
                onClick={() =>
                  handleSubmit(
                    moment.utc(time.asMilliseconds()).format("HH:mm:ss"),
                    quesSet.length > 0 ? quesSet.length : 0,
                    quesSet.length > 0
                      ? quesSet.filter((el) => el.is_fill === true).length
                      : 0,
                    quesSet.length > 0
                      ? quesSet.filter(
                          (el) => el.is_flag === true && el.is_fill === true
                        ).length
                      : 0,
                    quesSet.length > 0
                      ? quesSet.filter((el) => el.is_not_visited).length
                      : 0,
                    quesSet.length > 0
                      ? quesSet.filter((el) => el.is_un_fill).length
                      : 0,
                    quesSet.length > 0
                      ? quesSet.filter(
                          (el) => el.is_flag === true && el.is_un_fill === true
                        ).length
                      : 0
                  )
                }
              >
                <Link to="javascript:void(0)">Yes, Submit Now</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
