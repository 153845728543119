import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomContainer from "../../../../../ui/CustomContainer";
import moment from "moment";

const TopAlertBoxContainer = styled.div`
  background-color: var(--color-brand-800);
  color: var(--color-white);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;

export default function TopAlertBox({ startTime, endTime }) {
  const isLate = new Date() > new Date(startTime);
  const [alertHours, setAlertHours] = useState(0);
  const [alertMinutes, setAlertMinutes] = useState(0);
  const [alertSeconds, setAlertSeconds] = useState(0);

  // setInterval(() => calculateTimeDiff(), 1000);
  useEffect(() => {
    function calculateTimeDiff(testTime = startTime) {
      const diff = Math.abs(new Date() - testTime);
      const seconds = Math.floor((diff / 1000) % 60);
      const minutes = Math.floor((diff / (1000 * 60)) % 60);
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      setAlertMinutes(minutes);
      setAlertSeconds(seconds);
      setAlertHours(hours);
    }
    const interval = setInterval(() => {
      calculateTimeDiff();
      if (new Date().getTime() === new Date(startTime).getTime()) {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [startTime]);
  return (
    <TopAlertBoxContainer>
      <CustomContainer>
        <p style={{ padding: "8px 0" }}>
          {isLate ? (
            <>
              <span>You are late by </span>
              <span style={{ color: "#111" }}>
                {" "}
                {`${alertHours.toString().padStart(2, 0)}h ${alertMinutes
                  .toString()
                  .padStart(2, 0)}m : ${alertSeconds
                  .toString()
                  .padStart(2, 0)}s`}
              </span>{" "}
              <span>
                The test will be accessible till{" "}
                {moment(endTime).format("hh : mm : A")} for late joiners.Please
                ensure to join within the specified time for future tests.
              </span>
            </>
          ) : (
            <>
              <span>You've got </span>
              <span style={{ color: "#111" }}>
                {" "}
                {`${alertMinutes.toString().padStart(2, 0)}m : ${alertSeconds
                  .toString()
                  .padStart(2, 0)}s`}
              </span>{" "}
              <span>
                to start the test. The 'Start Test' button will be enabled after
                that time. Until then, take a moment to go through the
                instructions.
              </span>
            </>
          )}
        </p>
      </CustomContainer>
    </TopAlertBoxContainer>
  );
}
