import React, { useEffect, useRef, useState } from "react";

import {
  Calendar,
  TimeGrid,
  momentLocalizer,
  components,
} from "react-big-calendar";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import { Tooltip } from "bootstrap";
import CustomEvent from "./CustomEvent";

const formats = {
  weekdayFormat: (date, culture, localizer) => {
    return localizer.format(date, "ddd", culture);
  },
  dayHeaderFormat: (date, culture, localizer) => {
    return localizer.format(date, "ddd", culture);
  },
  dayFormat: (date, culture, localizer) => {
    return localizer.format(date, "D", culture);
  },
  monthHeaderFormat: (date, culture, localizer) => {
    return localizer.format(date, "MMMM YYYY", culture);
  },
};
const eventStyleGetter = (event) => {
  const backgroundColor = "#" + event.hexColor;
  const style = {
    backgroundColor: backgroundColor,
    borderRadius: "0px",
    opacity: 0.8,
    color: "white",
    border: "0px",
    display: "block",
  };
  return {
    style: style,
  };
};

//  const  MyToolbar = ({ dates, view , onView ,onNavigate, label }) => {
const MyToolbar = ({ date, view, onView, onNavigate, label }) => {
  // console.log(date,"sdsdsf");
  const { id, type } = useParams();
  const [events, setEvents] = useState([]);
  //  console.log(id,"batch_Id");
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedPickDate, setSelectedPickDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  const handlePrevClick = (newDate) => {
    // console.log(newDate);
    onNavigate("PREV");
    const dates = moment(newDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    // console.log(dates,"skjhdasjfhksajhf");
    setSelectedPickDate(dates.toDate());
    const formattedDate = dates.format("YYYY-MM-DD");
    setSelectedDates(formattedDate);
    const datess = moment(formattedDate); // Replace with your desired date
    const startOfMonth = datess.startOf("month").toDate(); // Start date of the month
    const endOfMonth = datess.endOf("month").toDate(); // End date of the month

    AxiosInstance.post("api/event/list", {
      batch_id: id,
      start_date: startOfMonth,
      end_date: endOfMonth,
      // event_type: type === "1" ? 1 : 0,
      event_type:"",
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const parsedEvents = res.data.data.map((event) => {
            const startDateStr = `${event.start_date.split("T")[0]}T${
              event.start_time
            }`;
            const endDateStr = `${event.start_date.split("T")[0]}T${
              event.end_time
            }`;
            const startDate = moment(startDateStr).toDate();
            const endDate = moment(endDateStr).toDate();
            return {
              id: event.id,
              title: event.name,
              event_type: event.event_type,
              start: startDate,
              end: endDate,
              url: event.teams_url,
              subject: event.subject,
              staff_name: event.staff_name,
              staff_id: event.staff_id,
              description: event.description,
              syllabus_filepath: event.syllabus_filepath,
            };
          });
          setEvents(parsedEvents);
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const handleFilter = (event) => {
    onView(event.target.value);
  };

  const handleNextClick = (newDate) => {
    onNavigate("NEXT");
    const dates = moment(newDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    setSelectedPickDate(dates.toDate());
    const formattedDate = dates.format("YYYY-MM-DD");
    setSelectedDates(formattedDate);
    const datess = moment(formattedDate); // Replace with your desired date
    const startOfMonth = datess.startOf("month").toDate(); // Start date of the month
    const endOfMonth = datess.endOf("month").toDate(); // End date of the month
console.log(type,"type")
    AxiosInstance.post("api/event/list", {
      batch_id: id,
      start_date: startOfMonth,
      end_date: endOfMonth,
      // event_type: type === "1" ? 1 : 0,
      event_type:"",
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const parsedEvents = res.data.data.map((event) => {
            const startDateStr = `${event.start_date.split("T")[0]}T${
              event.start_time
            }`;
            const endDateStr = `${event.start_date.split("T")[0]}T${
              event.end_time
            }`;
            const startDate = moment(startDateStr).toDate();
            const endDate = moment(endDateStr).toDate();
            return {
              id: event.id,
              title: event.name,
              start: startDate,
              end: endDate,
              event_type: event.event_type,
              url: event.teams_url,
              subject: event.subject,
              staff_name: event.staff_name,
              staff_id: event.staff_id,
              description: event.description,
              syllabus_filepath: event.syllabus_filepath,
            };
          });
          setEvents(parsedEvents);
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const handleChange = (dates) => {
    setSelectedPickDate(dates);
    const formattedDate = moment(dates).format("YYYY-MM-DD");
    setSelectedDates(formattedDate);
    setShowDatePicker(false);
    const newDate = moment(dates).toDate();
    onNavigate("DATE", newDate);
  };
  const handleToday = () => {
    const today = moment();
    onNavigate("TODAY");
    setSelectedDates(today.format("YYYY-MM-DD"));
    setSelectedPickDate(today.toDate());

    //     // Calculate the current scroll position
    // const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

    // // Calculate the new scroll position (700 pixels down from the current position)
    // const targetScrollPosition = currentScrollPosition ;

    // // Scroll the window to the new calculated position
    // window.scrollTo({
    //   top: targetScrollPosition,
    //   behavior: 'smooth'
    // });
  };
  return (
    <div className="row mb-3" id="mytoolbar">
      <div className="col-md-3">
        <h4 className="CalendarTitle" onClick={toggleDatePicker}>
          {selectedDates
            ? moment(selectedDates).format("MMMM YYYY")
            : moment().format("MMMM YYYY")}
          <span class="material-icons align-bottom">arrow_drop_down</span>
        </h4>
        {showDatePicker && (
          <DatePicker
            selected={selectedPickDate}
            onChange={handleChange}
            inline
            className="datepicker"
          />
        )}
      </div>
      <div className="col-md-3">
        <button className="calViewBtn" onClick={handleToday}>
          Go to today
        </button>
      </div>
      <div className="col-md-3 d-flex justify-content-lg-end justify-content-start">
        <div className="up-in-toggle">
          <input
            type="radio"
            id="switch_left"
            name="view"
            value="month"
            checked={view === "month"}
            onChange={handleFilter}
          />
          <label htmlFor="switch_left">Month</label>
          <input
            type="radio"
            id="switch_right"
            name="view"
            value="week"
            checked={view === "week"}
            onChange={handleFilter}
          />
          <label htmlFor="switch_right">Week</label>
        </div>
      </div>
      <div className="col-md-3 d-flex justify-content-lg-end justify-content-start">
        <button
          className="PrevBtns"
          onClick={() =>
            handlePrevClick(
              moment(date)
                .subtract(1, view === "month" ? "month" : "week")
                .toDate(),
              view
            )
          }
        >
          <span class="material-icons">navigate_before</span>
        </button>
        <button
          className="PrevBtns"
          onClick={() =>
            handleNextClick(
              moment(date)
                .add(1, view === "month" ? "month" : "week")
                .toDate(),
              view
            )
          }
        >
          <span class="material-icons">navigate_next</span>
        </button>
      </div>

      {/* <input value={moment(date).format('YYYY-MM-DD')}  onChange={handleChange} type="date"/> */}
    </div>
  );
};

function MyCalendar(props) {

  const calendarRef = useRef(null);
  // const batch_Id = props.batch_id;
  // console.log(props,"props");
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (props.eventFields) {
      setEvents(props.eventFields);
    }
  }, [props.eventFields]);

  const moment = require("moment-timezone");
  const navigate = useNavigate();
  const [view, setView] = useState("month");
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedPickDate, setSelectedPickDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  const handleDateChange = (date) => {
    setSelectedPickDate(date);

    const formattedDate = moment(date).format("YYYY-MM-DD");
    setSelectedDates(formattedDate);

    setShowDatePicker(false);
    // const newDate = moment(formattedDate).toDate();
    // onNavigate('DATE', newDate);
  };

  const [date, setDate] = useState(moment().toDate());
  const handleNavigate = (newDate, newView) => {
    setDate(newDate);
    const date = moment(newDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const formattedDate = date.format("YYYY-MM-DD");
    const dates = moment(formattedDate); // Replace with your desired date
    const startOfMonth = dates.startOf("month").toDate(); // Start date of the month
    const endOfMonth = dates.endOf("month").toDate(); // End date of the month
    setView(newView);
    AxiosInstance.post("api/event/list", {
      batch_id: parseInt(props.batch_id),
      start_date: startOfMonth,
      end_date: endOfMonth,
      // event_type: props.type === "1" ? 1 : 0,
      event_type:"",
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const parsedEvents = res.data.data.map((event) => {
            const startDateStr = `${event.start_date.split("T")[0]}T${
              event.start_time
            }`;
            const endDateStr = `${event.start_date.split("T")[0]}T${
              event.end_time
            }`;
            const startDate = moment(startDateStr).toDate();
            const endDate = moment(endDateStr).toDate();
            return {
              id: event.id,
              title: event.name,
              event_type: event.event_type,
              start: startDate,
              end: endDate,
              url: event.teams_url,
              subject: event.subject,
              staff_name: event.staff_name,
              staff_id: event.staff_id,
              description: event.description,
              syllabus_filepath: event.syllabus_filepath,
            };
          });
          setEvents(parsedEvents);
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const handleView = (view) => {
    setView(view);
  };

  const handleDrillDown = () => {
    setView("week");
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const handleSelectSlot = ({ start }) => {
    const sEmail = props.studentEmail;
    const batch_id = props.batch_id;
    const category = props.category;
    const coures_name = props.Courses;
    const category_course_id = props.category_course_id;
    const subcate = props.subcategorys;
    const startTime = props.startTime;
    const endTime = props.endTime;
    const sDate = moment(start).format("YYYY-MM-DD");
    const type = props.type;
    // console.log("category", category_course_id);
    // navigate(`/CreateEvent/${sDate}/${props.batch_id}/${props.category}/${props.startTime}/${props.endTime}`)
    // if(props.type !=1){

    // }else{
    //   navigate(`/CreateTestEvent`,{
    //     state: {
    //       sEmail,
    //       sDate,
    //       batch_id,
    //       category,
    //       startTime,
    //       endTime,
    //     },
    //   })
    // }
    navigate(`/CreateEvent`, {
      state: {
        sEmail,
        sDate,
        batch_id,
        category,
        startTime,
        endTime,
        coures_name,
        category_course_id,
        subcate,
        type,
      },
    });

    //  setSelectedDate(start);
    // setEvents([...events, { start, end: moment(start).add(1, 'hour') }]);
  };
  const handleSelectEvent = (event) => {
    // console.log(event,"event");
    const sDates = event.start;
    const eDates = event.start;
    const outputFormat = "YYYY-MM-DD";
    // console.log(sDates,"sDates");
    // create a new moment object using the input date string and parse it
    const momentObj = moment(sDates, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const momentObjs = moment(sDates, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    // format the moment object using the output format
    // console.log(momentObj,"momentObj");
    const sDate = momentObj.format(outputFormat);
    const eDate = momentObjs.format(outputFormat);
    let id = event.id;
    console.log(props)
    let types = event.event_type;
    let coures_name = props.Courses;
    let category_course_id = props.category_course_id;
    let subcate = props.subcategorys;
  

    navigate("/AdminDetailCalendar", {
      state: {
        id,
        types,
        coures_name,
        category_course_id,
        subcate
      },
    });
    // navigate(`/CreateEvent/${id}/${sDate}/${props.batch_id}/${props.category}/${props.startTime}/${props.endTime}`)
  };
  const handleFilter = (e) => {
    const filter = e.target.value;
    if (filter === "month") {
      setView("month");
    } else if (filter === "week") {
      setView("week");
    }
  };

  const handleTodayClick = () => {
    // setSelectedDates(moment().format('YYYY-MM-DD'));
    // setSelectedPickDate(new Date());
    const now = new Date();
    this.calendarRef.current.getApi().gotoDate(now);
    if (calendarRef.current) {
      calendarRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    // onNavigate('TODAY');
  };
  const localizer = momentLocalizer(moment);

  // console.log(events,"eventsjshfjsfjsdghjdsg");
  return (
    <>
      {/* <div className='row d-flex align-items-end' id="mycompontent">
         <div className='col-md-3'> 
        <h4 className='CalendarTitle' onClick={toggleDatePicker}>{selectedDates ? moment(selectedDates).format("MMMM YYYY") : moment().format("MMMM YYYY") }
        <span class="material-icons align-bottom">arrow_drop_down</span>
      </h4>
       {showDatePicker && (
          <DatePicker
            selected={selectedPickDate}
            onChange={handleDateChange}
            inline
            className="datepicker"
          />
        )}
        </div>  
        <div className='col-md-3'>
        <button className='calViewBtn' onClick={handleTodayClick}>
          Go to today 
        </button>
        </div>  
        
        <div className='col-md-3 d-flex justify-content-lg-end justify-content-start'>
        <div className="up-in-toggle">
        <input
          type="radio"
          id="switch_left"
          name="view"
          value="month"
          checked={view === "month"}
          onChange={handleFilter}
        />
        <label htmlFor="switch_left">Month</label>
        <input
          type="radio"
          id="switch_right"
          name="view"
          value="week"
          checked={view === "week"}
          onChange={handleFilter}
        />
        <label htmlFor="switch_right">Week</label>
      </div>
        </div>
        <div className='col-md-3 d-flex justify-content-lg-end justify-content-start'>
        <button className='PrevBtn' onClick={() => handleNavigate(moment(date).subtract(1, view === 'month' ? 'month' : 'week').toDate(), view)}>
        <span class="material-icons">
        navigate_before
</span>
        </button>
        <button className='PrevBtn' onClick={() => handleNavigate(moment(date).add(1, view === 'month' ? 'month' : 'week').toDate(), view)}>
        <span class="material-icons">
navigate_next
</span>
        </button>
        </div>
       
    </div> */}
      <div className="row">
        <Calendar
          ref={calendarRef} // Assign the ref to the Calendar component
          //  cla_id={batch_Id}
          weekStartsOn={1}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 700 }}
          view={view}
          onDrillDown={handleDrillDown} // Handle the "Show More" button click
          formats={formats}
          components={{
            toolbar: MyToolbar,
            event: (props) => <CustomEvent {...props} />,
          }}
          selectable
          onSelectSlot={(slotInfo) => {
            const startDate_batch = moment(props.startDate);
            const startOfMonth = moment(date).startOf("month");
            const currentDate = moment().startOf("day"); // Set the time to the start of the day
            const start = moment(slotInfo.start);
            // Convert startDate_batch to a moment object

            // Calculate the end date that is `props.duration` months from the start of the current month
            const endPlusDurationMonths = startOfMonth
              .clone()
              .add(props.duration, "months");
            // console.log(endPlusDurationMonths.toString(),"endPlusDurationMonths");
            const endDate = startDate_batch
              .clone()
              .add(props.duration, "months");
            // console.log(endDate.toString(),"endDate");
            // Check if the slot's start date (only date part) is within the allowed range
            if (
              (start.isAfter(currentDate) ||
                start.isSame(currentDate, "day")) &&
              start.isSameOrAfter(startDate_batch) &&
              start.isSameOrBefore(endDate) &&
              props.studentEmail.length > 0
            ) {
              handleSelectSlot(slotInfo);
            } else if (props.studentEmail.length === 0) {
              
              alert("Admission is not recorded for this batch. Please verify the Admin status and proceed with event creation");
            } else {
              alert(
                `Please select a valid date range starting from today, after ${startDate_batch.format(
                  "MMMM YYYY"
                )}, or within the next ${props.duration} months.`
              );
            }
          }}
          onView={handleView}
           onSelectEvent={handleSelectEvent}
          date={date}
          // defaultView={view.WEEK}
          // showMultiDayTimes={false}
          onNavigate={handleNavigate}
        />
      </div>
    </>
  );
}
export default MyCalendar;
