import React from "react";
import Select, { components } from "react-select";
import { multiCusutomStyle } from "../../../../pages/Multicustomstyles";
import {
  CommonOption,
  CustomCOption,
  OptionBB,
} from "../../../../labels/Lableoption";
import { customStyles } from "../../../../pages/customStyles";

function DistrictExam(props) {
  return (
    <div className="row box ">
      <div className="col-md-12 mb-2">
        <p className="StepTitle">
          Exam District {props.record.id > 0 ? "" : ""}{" "}
          {props.record.id > 0 ? props.record.id : ""}{" "}
        </p>
        <div className="row " style={{paddingRight:"20px"}}>
          <div className="col-md-6">
            <div className="forms ">
              <label for="District">District:</label>
{console.log('laksh-select-district',props.district)}
              <Select
                ref={props.txtBatchDis}
                id="district"
                name="district"
                options={props.district}
                isClearable={true}
                onChange={(e) =>
                  props.handleChangeMutipleDistrict(
                    e,
                    props.record.id,
                    props.record.district
                  )
                }
                value={props.record.district ? props.record.district : ""}
                styles={customStyles}
              />
              {props.record.error_1 && (
                <small className="errors">{props.record.error_1}</small>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="forms ">
              <label for="districtCode">District Code:</label>
              <input
                type="number"
                className="Inputs"
                ref={props.txtBatchDisCode}
                id="districtCode"
                readOnly
                name="districtCode"
                placeholder="District Code"
                onChange={(e) =>
                  props.handleChangedrodown(e, props.record.id, "districtCode")
                }
                value={
                  props.record.districtCode > 0 ? props.record.districtCode : ""
                }
              />
              {props.record.error_2 && (
                <small className="errors">{props.record.error_2}</small>
              )}
            </div>
          </div>
        </div>
        <div className="row mb-4" style={{paddingRight:"20px"}}>
          <div className="col-md-12 d-flex justify-content-end">
            {props.record.id != 1 && props.record.id === props.length && (
              <a
                className="AddMore me-3"
                disabled={props.admission_status === 1 ? true : false}
                onClick={() =>
                  props.admission_status === 1
                    ? ""
                    : props.deleteDistrictWise(
                        props.record.id,
                        props.record.name
                      )
                }
              >
                <span
                  disabled={props.admission_status === 1 ? true : false}
                  className="material-icons download_icon align-middle"
                >
                  remove_circle
                </span>
                Delete this District
              </a>
            )}

            {props.record.id === props.length ? (
              <a
                className="AddMore"
                onClick={
                  props.admission_status === 1 ? "" : props.addDistrictWise
                }
              >
                <span className="material-icons download_icon align-middle">
                  add_circle
                </span>{" "}
                Add More District
              </a>
            ) : (
              /* For all other items, show the delete button */
              <a
                className="AddMore me-3"
                disabled={props.admission_status === 1 ? true : false}
                onClick={() =>
                  props.admission_status === 1
                    ? ""
                    : props.deleteDistrictWise(
                        props.record.id,
                        props.record.name
                      )
                }
              >
                <span
                  disabled={props.admission_status === 1 ? true : false}
                  className="material-icons download_icon align-middle"
                >
                  remove_circle
                </span>
                Delete this District
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default DistrictExam;
