import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";

import Title from "../../../../../ui/Title";
import FloatedTitleContainer from "../../../../../ui/FloatedTitleContainer";
import FloatedContainerText from "../../../../../ui/FloatedContainerText";
import PrimaryButton from "../../../../../ui/PrimaryButton";
import SecondaryButton from "../../../../../ui/SecondaryButton";
import NavigationButton from "../../../../../ui/NavigationButton";
import InstructionList from "../components/InstructionList";
import { ButtonContainer, StyledTestDetailsContainer } from "./ViewTestDetail";
import ModalDialogContent from "../../../../../ui/ModalDialogContent";
import ModalDialogActions from "../../../../../ui/ModalDialogActions";
import CustomContainer from "../../../../../ui/CustomContainer";
import { ALLOWED_MINUTES, MEDIA_SIZE_LG } from "../../../../../utils/helper";
import moment from "moment";
import TopAlertBox from "../components/TopAlertBox";
import StartTest from "../components/StartTest";

const StyledPara = styled.p`
  color: var(--color-text-primary);
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
`;
const HighLight = styled.span`
  color: var(--color-brand-900);
`;
const StyledAlert = styled.div`
  background-color: var(--color-brand-800);
`;
const StyledAlertPara = styled.p`
  margin: 0;
  padding: 5px 0;
  text-align: center;
  color: var(--color-white);
  font-size: 14px;
  font-weight: var(--font-weight-bold);
`;

const START_TIME_OFFSET = 15;
export default function JoinTest() {
  const navigate = useNavigate();
  const location = useLocation();

  // const calculatedEndTime = useRef(new Date());
  const [calculatedEndTime, setCalculatedEndTime] = useState(new Date());
  const userTime = useRef(new Date());

  const [startOpen, setStartOpen] = useState(false);
  const [lateOpen, setLateOpen] = useState(false);
  const [joinTestDetails, setJoinTestdetails] = useState({
    batchID: 0,
    eventID: 0,
    eventName: "",
    methodOfTest: "",
    testType: "",
    date: "",
    startDate: new Date(),
    startTime: "",
    endTime: "",
    totalNoQues: "",
    totalMark: "",
    descForStudent: "",
    attempt: "",
  });
  const handleJoinTest = () => {
    navigate("/JoinTest", {
      state: {
        batch_id: joinTestDetails.batchID,
        event_id: joinTestDetails.eventID,
        startTime: joinTestDetails.startTime,
        endTime: joinTestDetails.endTime,
        attempt_no: joinTestDetails.attempt,
      },
    });
  };
  // LOGIC FOR WARNING POP-UP
  const TODAY = new Date();
  const STARTDATE = new Date(joinTestDetails.startTime);
  const CALCULATEDENDTIME = new Date(
    STARTDATE.getFullYear(),
    STARTDATE.getMonth(),
    STARTDATE.getDate(),
    STARTDATE.getHours(),
    STARTDATE.getMinutes() + ALLOWED_MINUTES
  );
  let diff = STARTDATE - TODAY;
  let timeString = "";
  const isLate = TODAY > STARTDATE;
  if (isLate) {
    diff = TODAY - STARTDATE;
  }
  const seconds = Math.floor((diff / 1000) % 60);
  const minutes = Math.floor((diff / (1000 * 60)) % 60);
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  timeString = `${minutes}m : ${seconds}s`;

  useEffect(() => {
    if (joinTestDetails.attempt === 1) {
      if (isLate) {
        setLateOpen(true);
      } else {
        setStartOpen(true);
      }
    }
  }, [isLate, joinTestDetails.attempt]);
  // LOGIC FOR WARNING POP-UP

  useEffect(() => {
    const {
      eventName = "",
      methodOfTest = "",
      testType = "",
      date = "",
      startTime = "",
      endTime = "",
      totalNoQues = "",
      totalMark = "",
      descForStudent = "",
      batchID = 0,
      eventID = 0,
      attempt_no,
    } = location.state;
    const TODAY = new Date();
    const startTimeTemp = startTime?.split(":");
    const endTimeTemp = endTime?.split(":");
    setJoinTestdetails((prevData) => ({
      ...prevData,
      eventName,
      methodOfTest,
      testType,
      date,
      batchID,
      eventID,
      attempt: attempt_no,
      startTime: new Date(
        TODAY.getFullYear(),
        TODAY.getMonth(),
        TODAY.getDate(),
        +startTimeTemp[0],
        +startTimeTemp[1],
        +startTimeTemp[2]
        // 2024,
        // 2,
        // 27,
        // 11,
        // 0,
        // 0
      ),
      endTime: new Date(
        TODAY.getFullYear(),
        TODAY.getMonth(),
        TODAY.getDate(),
        endTimeTemp[0],
        endTimeTemp[1],
        endTimeTemp[2]
      ),
      totalNoQues,
      totalMark,
      descForStudent,
    }));
    setCalculatedEndTime(
      new Date(
        TODAY.getFullYear(),
        TODAY.getMonth(),
        TODAY.getDate(),
        +startTimeTemp[0],
        +startTimeTemp[1] + START_TIME_OFFSET,
        +startTimeTemp[2]
      )
    );
    // calculatedEndTime.current = new Date(
    //   TODAY.getFullYear(),
    //   TODAY.getMonth(),
    //   TODAY.getDate(),
    //   startTimeTemp[0],
    //   startTimeTemp[1] + START_TIME_OFFSET,
    //   startTimeTemp[2]
    // );
  }, [location]);
  // console.log(
  //   "laksh",
  //   calculatedEndTime,
  //   joinTestDetails.attempt,
  //   joinTestDetails.startDate,
  //   joinTestDetails.attempt === 1 &&
  //     new Date() > joinTestDetails.startDate &&
  //     new Date() < calculatedEndTime
  // );
  return (
    <>
      {/* {new Date(joinTestDetails.startTime) === new Date() || (
        <TopAlertBox
          startTime={joinTestDetails.startTime}
          endTime={calculatedEndTime}
        />
      )} */}
      {joinTestDetails.attempt === 1 && (
        <TopAlertBox
          startTime={joinTestDetails.startTime}
          endTime={calculatedEndTime}
        />
      )}
      {joinTestDetails.attempt !== 1 &&
        new Date() > joinTestDetails.startDate &&
        new Date() < calculatedEndTime && (
          <StyledAlert>
            <StyledAlertPara>
              You've already attended the test. However, this will be counted as
              your second attempt.
            </StyledAlertPara>
          </StyledAlert>
        )}
      <CustomContainer>
        {/* title section start */}
        <Title className="d-block d-sm-none">Join Test</Title>
        <div className="d-flex justify-content-between align-items-center">
          <Title className="d-none d-sm-block">Join Test</Title>
          <ButtonContainer
          // className="d-flex justify-content-end align-items-center ms-auto mb-2 flex-wrap"
          >
            {/* <div>
              <PrimaryButton onClick={() => setStartOpen(true)}>
                open start modal
              </PrimaryButton>
              <PrimaryButton onClick={() => setLateOpen(true)}>
                open late modal
              </PrimaryButton>
            </div> */}
            <SecondaryButton onClick={() => navigate(-1)}>
              back to shedule
            </SecondaryButton>
            {joinTestDetails.attempt === 1 ? (
              <StartTest
                startTime={joinTestDetails.startTime}
                calculatedEndTime={calculatedEndTime}
                onClick={handleJoinTest}
              >
                {" "}
                start Test
              </StartTest>
            ) : (
              <NavigationButton onClick={handleJoinTest}>
                Start Test
              </NavigationButton>
            )}
            {/* <p>{new Date(userTime.current)}</p> */}
            {/* <NavigationButton
                className="ms-1"
                onClick={() => navigate("/JoinTest")}
                disabled={
                  !(
                    new Date() >= joinTestDetails.startTime &&
                    new Date() <= calculatedEndTime
                  )
                }
              >
                <Stack direction="row" alignItems="center" columnGap={1}>
                  start Test
                </Stack>
              </NavigationButton> */}
          </ButtonContainer>
        </div>
        {/* body */}
        <Stack spacing={4}>
          <FloatedTitleContainer title="Test Details">
            <StyledTestDetailsContainer>
              <Grid container rowSpacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <FloatedContainerText
                    title="event name"
                    text={joinTestDetails.eventName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FloatedContainerText
                    title="method of test"
                    text={
                      joinTestDetails.methodOfTest === 0
                        ? "Objective"
                        : "Descriptive"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FloatedContainerText
                    title="test type"
                    text={joinTestDetails.testType}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FloatedContainerText
                    title="date"
                    text={
                      joinTestDetails.date
                        ? moment(joinTestDetails.date).format("DD/MM/YYYY")
                        : joinTestDetails.date
                    }
                    // text={
                    //   joinTestDetails.date instanceof Date
                    //     ? moment(joinTestDetails.date)?.format("YYYY/MM/DD")
                    //     : joinTestDetails.date
                    // }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FloatedContainerText
                    title="start time"
                    text={
                      joinTestDetails.startTime instanceof Date
                        ? moment(joinTestDetails.startTime).format("hh:mm A")
                        : joinTestDetails.startTime
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FloatedContainerText
                    title="end time"
                    text={
                      joinTestDetails.endTime instanceof Date
                        ? moment(joinTestDetails.endTime).format("hh:mm A")
                        : joinTestDetails.endTime
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FloatedContainerText
                    title="total number of questions"
                    text={joinTestDetails.totalNoQues}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FloatedContainerText
                    title="total marks"
                    text={joinTestDetails.totalMark}
                  />
                </Grid>
                <Grid item>
                  <FloatedContainerText
                    title="description of student"
                    normalText={joinTestDetails.descForStudent}
                  />
                </Grid>
              </Grid>
            </StyledTestDetailsContainer>
          </FloatedTitleContainer>
          {/* --- instruction details*/}
          <InstructionList />
        </Stack>
        <div className="d-flex justify-content-center align-items-center m-4">
          {joinTestDetails.attempt === 1 ? (
            <StartTest
              startTime={joinTestDetails.startTime}
              calculatedEndTime={calculatedEndTime}
              onClick={handleJoinTest}
            >
              {" "}
              start Test
            </StartTest>
          ) : (
            <NavigationButton onClick={handleJoinTest}>
              Start Test
            </NavigationButton>
          )}
        </div>
      </CustomContainer>
      {/* DIALOGUE FOR BEFORE TAKING TEST */}
      {joinTestDetails.attempt === 1 && (
        <Dialog
          open={startOpen}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ fontWeight: 800 }}>
            Greetings From AIASA
          </DialogTitle>
          <ModalDialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <StyledPara>
                <HighLight style={{ textAlign: "center" }}>
                  Best wishes for your Exams
                </HighLight>
                <br /> I'm sure your continuous hard work and determination will
                shine through your answer script.
              </StyledPara>
              <StyledPara>
                You've got <HighLight>{timeString}</HighLight> to start the test
                <br /> The 'Start Test' button will be enabled after that
                time.Until then, take a moment to go through the instructions.
              </StyledPara>
            </DialogContentText>
          </ModalDialogContent>
          <ModalDialogActions>
            <NavigationButton onClick={() => setStartOpen(!startOpen)}>
              Okay
            </NavigationButton>
          </ModalDialogActions>
        </Dialog>
      )}
      {/* DIALOGUE FOR AFTER TAKING TEST */}
      <Dialog open={lateOpen} aria-describedby="alert-dialog-slide-description">
        <DialogTitle style={{ fontWeight: 800 }}>
          Greetings From AIASA
        </DialogTitle>
        <ModalDialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <StyledPara>
              You are late by <HighLight>{timeString}</HighLight>
              <br /> The test will be accessible till{" "}
              {moment(CALCULATEDENDTIME).format("hh : mm : a")} for late
              joiners.
              <br />
              Please ensure to join within the specified time for future tests.
            </StyledPara>
            <StyledPara>
              <HighLight style={{ textAlign: "center" }}>
                Best wishes for your Exams
              </HighLight>
              <br /> I'm sure your continuous hard work and determination will
              shine through your answer script.
            </StyledPara>
          </DialogContentText>
        </ModalDialogContent>
        <ModalDialogActions>
          <NavigationButton onClick={() => setLateOpen(!lateOpen)}>
            Okay
          </NavigationButton>
        </ModalDialogActions>
      </Dialog>
    </>
  );
}
