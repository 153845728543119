import { useEffect, useState } from "react";

import CardTemplate1 from "../Card/CardTemplate1";
import Header from "../../../features/Core/Header/HomeHeader";
import React from "react";
import axios from "axios";

// import Header from '../../../../Core/Header/Header';

const BookList = () => {
  const [products, setProducts] = useState([]);

  const Productdata = [
    {
      id: 1,
      title: "பொதுத்தமிழ்_மனப்பாடம்",
      images:
        "https://i2.wp.com/aiasabook.com/wp-content/uploads/2021/11/as_em_unit_ix_09.jpg?resize=300%2C300&ssl=1",
      category: "Group-1",
      description:
        "The TNPSC Exam has sections based on general awareness, knowledge of the language of Tamil or English, mental ability as well as a section based on the specialization of the subject as required by the posts. For which, the TNPSC Books can be obtained from any book store, offline and online.",
      price: 200.9,
      shipping_charges: 40,
      discount: 0,
    },
    {
      id: 2,
      title: "TNPSC",
      images:
        "https://i2.wp.com/aiasabook.com/wp-content/uploads/2021/11/as_em_unit_ix_09.jpg?resize=300%2C300&ssl=1",
      category: "Group-2 & 2A",
      description:
        "Most of the TNPSC Books comprise practice sets and solved model papers so that candidates find it much easier and convenient to prepare for the exams. As such there are many books available in the market, but candidates are advised to pick out the ones which are recommended as the best ones only.",
      price: 300,
      shipping_charges: 45,
      discount: 8,
    },
    {
      id: 3,
      title: "TNPSC",
      images:
        "https://i2.wp.com/aiasabook.com/wp-content/uploads/2022/04/490714.jpg?resize=300%2C300&ssl=1",
      category: "Group-III",
      description:
        "TNPSC Books are based on the latest guidelines and patterns established by the officials for the upcoming exam and will help the aspiring candidates score well in the TNPSC Exam 2022.",
      price: 325,
      shipping_charges: 60,
      discount: 10,
    },
    {
      id: 4,
      title: "TNPSC",
      images:
        "https://i1.wp.com/aiasabook.com/wp-content/uploads/2021/11/as_gr-iv_em_01.jpg?resize=300%2C300&ssl=1",
      category: "Group-IV &VAO",
      description:
        "The best Books for TNPSC Recruitment can help candidates get their dream government job. To prepare for TNPSC 2022 Recruitment exams, candidates must take the help of various TNPSC Books, online courses, and notes that are available",
      price: 400.89,
      shipping_charges: 55,
      discount: 5,
    },
    {
      id: 5,
      title: "UPSC",
      images:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0IpHuGIfLmLR5JdF-Hcl1tgRNTTADKm2owilTygrHZDKUd4-IjXWdai7GqLJIlGcdvT0&usqp=CAU",
      category: "Group-III",
      description:
        "The best IAS books can vary from candidate to candidate depending on their previous expertise and knowledge of the subject matter. However, the UPSC booklist recommended by experts are listed in this article subject-wise.",
      price: 450,
      shipping_charges: 25,
      discount: 25,
    },
    {
      id: 6,
      title: "TRB",
      images:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtOZvTXptpXdGeiXPrL-sgP1izKAx8B8t10KChATyIuFdtQSZ_g9kD9AnPxcCIOf58jEM&usqp=CAU",
      category: "Physics",
      description:
        "The best IAS books can vary from candidate to candidate depending on their previous expertise and knowledge of the subject matter. However, the UPSC booklist recommended by experts are listed in this article subject-wise.",
      price: 535,
      shipping_charges: 50,
      discount: 11,
    },
    {
      id: 7,
      title: "TRB",
      images:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7ic_IgpKmWqe7WqEBLUBCrsuMmZRQuAbbPeS0yEhnCAa54mLGY72w4FO5AGECnROWXw&usqp=CAU",
      category: "chemistry",
      description:
        "The best IAS books can vary from candidate to candidate depending on their previous expertise and knowledge of the subject matter. However, the UPSC booklist recommended by experts are listed in this article subject-wise.",
      price: 560,
      shipping_charges: 80,
      discount: 50,
    },
    {
      id: 8,
      title: "UPSC",
      images:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0IpHuGIfLmLR5JdF-Hcl1tgRNTTADKm2owilTygrHZDKUd4-IjXWdai7GqLJIlGcdvT0&usqp=CAU",
      category: "Group-IV",
      description:
        "The best IAS books can vary from candidate to candidate depending on their previous expertise and knowledge of the subject matter. However, the UPSC booklist recommended by experts are listed in this article subject-wise.",
      price: 290,
      shipping_charges: 35,
      discount: 33,
    },
  ];
  useEffect(() => {
    axios
      .get("https://aiasastore.annaiasacademy.com/api/product/all?title=")
      .then((response) => {
        setProducts(response.data);
        // console.log(response.data, "...axios response...");

        localStorage.setItem("products", JSON.stringify(response.data));
        const products = JSON.parse(localStorage.getItem("products")) || [];
        // setProducts(products);
        const updateproduct = response.data.map((item) => {
          const discountedPrice =
            Number(item.price) -
            (Number(item.price) * (Number(item.discount) / 100)).toFixed(2);
          return {
            ...item,
            discountedPrice,
            discountedPrice,
          };
        });
        localStorage.setItem("products", JSON.stringify(updateproduct));
        setProducts(updateproduct); // Update the state with the new 'updateproduct' array
        // console.log(products, "...products...");
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div style={{ marginTop: "0rem" }}></div>
        <div className="row mt-5">
          <h1>Books List</h1>
          {products.map((product) => (
            <CardTemplate1 key={product.id} {...product} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BookList;
