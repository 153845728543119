import React from 'react';
import Select, { components } from 'react-select';
import { multiCusutomStyle } from '../../../../pages/Multicustomstyles';

// Define your custom option component
const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} style={{ backgroundColor: '#041134' , color:'#ffff' }}>
      {label}
    </div>
  );

  const Option = (props) => {
    return (
      <div style={{ textAlign: "left" }}>
          <components.Option {...props}>
           <label style={multiCusutomStyle.optionLabel} >{props.label}</label>
          </components.Option>
        </div>
    );
  };

  const GroupHeading = (props) => {
    return (
    //   <div style={{ textAlign: "left" ,  }}>
          <components.GroupHeading {...props}>
           <label style={{color :'#C0272D' }} >{props.children}</label>
          </components.GroupHeading>
    //  </div>
    );
  };
  
  // Define your custom group header component
//   const CustomGroupHeader = ({ innerProps, label }) => (
//     <div  {...innerProps} title={label} style={{ backgroundColor: '#ffff', color: '#041134', fontWeight: 'bold' }}>
//       {label ? label : "he"}
//     </div>
//   );

  const CustomGroupHeader = ({ innerProps, label }) => {
    // console.log(innerProps,label,"innerprops");
    (
    <div  {...innerProps} title={label} style={{ backgroundColor: '#ffff', color: '#041134', fontWeight: 'bold' }}>
    {label ? label : "he"}
  </div>
  )};
// Define the options with labels, colors, and group labels
const options = [
    {
        "label": "Civil Services - Prelims",
        "options": [
            {
                "value": "Civil Services Preliminary -  GS+ CSAT",
                "label": "Civil Services Preliminary -  GS+ CSAT",
               
            },
            {
                "value": "Civil Services - Preliminary - GS",
                "label": "Civil Services - Preliminary - GS",
               
            },
            {
                "value": "Civil Services - Preliminary - CSAT",
                "label": "Civil Services - Preliminary - CSAT",
               
            }
        ]
    },
    {
        "label": "Civil Services - Mains",
        "options": [
            {
                "value": "Civil Services - Mains- GS",
                "label": "Civil Services - Mains- GS",
               
            },
            {
                "value": "Civil Services - Mains - Optional Subject",
                "label": "Civil Services - Mains - Optional Subject",
                
            },
            {
                "value": "Civil Services -Mains - GS + Optional Subject",
                "label": "Civil Services -Mains - GS + Optional Subject",
                
            }
        ]
    },
    {
        "label": "Civil Services - Prelims cum Mains",
        "options": [
            {
                "value": "Civil Services - Prelims cum Mains",
                "label": "Civil Services - Prelims cum Mains",
                
            }
        ]
    },
    {
        "label": "Civil Services - Interview",
        "options": [
            {
                "value": "Civil Services - Interview",
                "label": "Civil Services - Interview",
                
            }
        ]
    }
];



// Define the custom styles for the Select component
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '8px',
  }),
};

// Main component
const DropdownHeader = (props) => {
  return (
      <Select
          options={props.record}
          isDisabled={props.isDisabled}
          ref={props.txtAdCourse}
          id={props.id}
          name={props.name}
          onChange={(e) => props.handleChangeCheckbox(e,"Label")}
          components={{ GroupHeading, Option }}
          value={(props.items !== null && props.items !== "") ? { value: props.items, label: props.items } : ""}
          styles={multiCusutomStyle}
          isClearable={true}
      />
  );
};

export default DropdownHeader;
