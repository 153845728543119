import "./Faculty.css";

import { Link, useNavigate  , useParams  } from "react-router-dom";
import React, { useEffect, useState ,useRef} from "react";

import { AxiosInstance } from "../../../../axios";
import Swal from "sweetalert2";
import moment from "moment";
import { validateSalary } from "./validation";
import DatePickerSample from "../../DatePicker";
import MonthDatePicker from "../../MonthDatePicker";

function AddSalary() {
  const inputRef = useRef(null);
  const { userId ,staff_id , staff_type,amount, id} = useParams();
  const navigate = useNavigate();
  const [fData, setFormData] = useState({
    Date: "",
    MonthOf: "",
    TSalary: staff_type === "0" ? "" : amount,
    deduction: "",
    THSalary: "",
    eDate:"",
    no_class :"",
    sDate:"",
  });

  const [error, setError] = useState([]);

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;
  let role_id = null;
  let user_type = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      role_id = user.role_id;
      user_type = user.user_type;
    }
  } else {
    console.log("User Id cannot available in localstorge");
  }

  useEffect(() => {
    AxiosInstance.post("api/staff/salary/findById", { id: id })
      .then((res) => {
        // console.log(res, "findbyid");
        if (res.data.status === true) {
          let el = res.data.data[0];
          setFormData({
            Date:  moment(el.processed_date).format("YYYY-MM-DD") ,
            MonthOf: el.month_of,
            TSalary: el.tot_salary,
            deduction: el.deduction,
            THSalary: el.takehome_salary,
            sDate : el.start_date !=null ? moment(el.start_date).format('YYYY-MM-DD') : "",
            eDate : el.end_date !=null ? moment(el.end_date).format('YYYY-MM-DD') : "",
            no_class : el.no_of_class,
          });
        }
      })
      .catch((error) => {
        return error;
      });
  }, [id]);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(fData.sDate !="" && fData.eDate !="" && fData.eDate !==null && fData.sDate !==null ){
      AxiosInstance.post("api/staff/salary/classList", {
        "staff_id":parseInt(staff_id),
        "start_date":fData.sDate,
        "end_date":fData.eDate,
        })
      .then((res) => {
        // console.log(res, "classList");
        if (res.data.status === true) {
          // console.log("fsdf");
          const noOfClass = res.data.data.no_of_class;
          const totSalary = res.data.data.tot_salary;
          // console.log("noOfClass:", noOfClass);
          // console.log("totSalary:", totSalary);
          setFormData(prevFormData => ({
            ...prevFormData,
            no_class: noOfClass,
            TSalary: totSalary
          }));
        }
      })
      .catch((error) => {
        return error;
      });
    } 
   
  }, [fData.sDate,fData.eDate]);


  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  
  const handleChangeCuDate = (dates, name) => {
    const dateSam = moment(dates).format('YYYY-MM-DD');
    // console.log(name, "name", dateSam);
      setFormData((prevState) => ({
        ...prevState,
        [name]: dateSam,
      }));
      setError({
        ...error,
        [name]: "",
      });
  }

  const handleEYDates = (dated, name , dname) => {
    const dateEYD = moment(dated).format('YYYY-MM');
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: dateEYD,
    }));
    setError({
      ...error,
      [name]: "",
    });
  }

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    // console.log(value,"sdfdsf")
    setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
  };
  const handleBlur = (event) => {
    const { name, value } = event.target;
    // console.log(name, value)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const handleSubmit = (e) => {
    // e.preventDefault(); // Prevent the default form submission behavior
    const errors = validateSalary(fData,staff_type);
    if (Object.keys(errors).length === 0) {
      const inputValue = inputRef.current.value;
      try {
        if (id) {
          const updateSalary = {
            staff_id:staff_id,
            id:id,
            staff_type:parseInt(staff_type),
            processed_date: fData.Date,
            start_date:  fData.sDate !='' && fData.sDate !=null && (staff_type ==="0" || staff_type ==="3") ?  fData.sDate : null,
            end_date:fData.eDate !='' && fData.eDate !=null && (staff_type ==="0" || staff_type ==="3") ? fData.eDate : null,
            no_of_class:fData.no_class !='' && fData.no_class !=null && (staff_type ==="0" || staff_type ==="3") ? fData.no_class : null,
            month_of: fData.MonthOf !='' && fData.MonthOf !=null && staff_type === "1" ?  fData.MonthOf : null,
            tot_salary: fData.TSalary,
            deduction: fData.deduction,
            takehome_salary: inputValue,
            updated_by: userid,
          }
          // console.log(updateSalary,"updateSalary");
         
          AxiosInstance.post("api/staff/salary/edit", updateSalary)
          .then((res) => {
           if (res.data.status === true) {
            Swal.fire({
              title: 'Success',
              text: res.data.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
              // console.log(location,"location");
                // navigate(`/Faculties/FacultyDetailView/${userId}/${staff_id}`)
                navigateToPreviousScreen();
              }
            });
           }
          })
          .catch((error) => {
            return error;
          });
        } else {
          const createSalary= {
            staff_id:staff_id,
            staff_type:parseInt(staff_type),
            processed_date: fData.Date,
            start_date:  fData.sDate !='' && fData.sDate !=null && (staff_type ==="0" || staff_type ==="3") ?  fData.sDate : null,
            end_date:fData.eDate !='' && fData.eDate !=null && (staff_type ==="0" || staff_type ==="3") ? fData.eDate : null,
            no_of_class:fData.no_class !='' && fData.no_class !=null && (staff_type ==="0" || staff_type ==="3") ? fData.no_class : null,
            month_of: fData.MonthOf !='' && fData.MonthOf !=null && staff_type === "1" ?  fData.MonthOf : null,
            tot_salary: fData.TSalary,
            deduction: fData.deduction,
            takehome_salary: inputValue,
            created_by: userid,
          };
          // console.log(createSalary, "createSalary");
          AxiosInstance.post("api/staff/salary/add", createSalary)
          .then((res) => {
           if (res.data.status === true) {
            Swal.fire({
              title: 'Success',
              text: res.data.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
              //   console.log(location,"location");
              //  navigate(`/Faculties/FacultyDetailView/${userId}/${staff_id}`)
              navigateToPreviousScreen();
              }
            });
           }else{
            Swal.fire({
              title: 'warning',
              text: res.data.message,
              icon: 'warning',
              confirmButtonText: 'Ok'
            })
           }
          })
          .catch((error) => {
            return error;
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };
  return (
    <div className="Faculty ">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne ">
            <div className="heading  mt-3 d-flex justify-content-between ">
              {id ? (
                <h4  className={user_type === 3 ? "STitless" : "STitles"}>Edit Salary</h4>
              ) : (
                <h4 className={user_type === 3 ? "STitless" : "STitles"}>Add New Salary</h4>
              )}
              <Link  to='javascript:void(0)'   onClick={navigateToPreviousScreen}  className="CancelButton">
               {user_type === 3 ?  " Back to Home" : " Back to View Faculty Profile"}
              </Link>
              <button
                type="button"
                className="SaveButton"
                onClick={handleSubmit}
                >
                 <Link to="javascript:void(0)">  {id ? "Update Salary" : "Save Salary"}</Link>
              </button>
            </div>
            <div className="row box ">
              <div className="col-md-12">
                <p className="StepTitle">Salary Details</p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="fname">Processed Date:</label>
                      {/* <input
                        type="date"
                        class="Inputs"
                        id="Date"
                        name="Date"
                        value={fData.Date}
                        onChange={handleChange}
                        placeholder="Date"
                        required
                      /> */}
                         <DatePickerSample  name={"Date"} handleChange={handleChangeCuDate} fieldInput={fData.Date} />
                      {error.Date && (
                        <small className="error">{error.Date}</small>
                      )}
                    </div>
                  </div>
                 {staff_type != 1 && (
                   <div className="col-md-6">
                   <div className="forms ">
                     <label for="sDate">Start Date:</label>
                     {/* <input
                       type="date"
                       class="Inputs"
                       id="sDate"
                       name="sDate"
                       value={fData.sDate}
                       onChange={handleChange}
                       placeholder="sDate"
                       required
                     /> */}
                      <DatePickerSample  name={"sDate"} handleChange={handleChangeCuDate} fieldInput={fData.sDate} />
                     {error.sDate && (
                       <small className="error">{error.sDate}</small>
                     )}
                   </div>
                 </div>
                 )}
                 {staff_type != 1 && (
                   <div className="col-md-6">
                   <div className="forms ">
                     <label for="eDate">End Date:</label>
                     {/* <input
                       type="date"
                       class="Inputs"
                       id="eDate"
                       name="eDate"
                       value={fData.eDate}
                       onChange={handleChange}
                       placeholder="eDate"
                       required
                     /> */}
                      <DatePickerSample  minDate={ fData.sDate !==null && fData.sDate !=="" ? fData.sDate :""} name={"eDate"} handleChange={handleChangeCuDate} fieldInput={fData.eDate} />
                     {error.eDate && (
                       <small className="error">{error.eDate}</small>
                     )}
                   </div>
                 </div>
                 )}
                  {staff_type != 1 && (
                    <div className="col-md-6">
                    <div className="forms ">
                      <label for="no_class">Number of Classes:</label>
                      <input
                        type="number"
                        class="Inputs"
                        id="no_class"
                        name="no_class"
                        value={fData.no_class}
                        onChange={handleChange}
                        placeholder="Number of Classes"
                        required
                      />
                      {error.no_class && (
                        <small className="error">{error.no_class}</small>
                      )}
                    </div>
                  </div>
                  )}
                  
                {staff_type  === "1" && (
                    <div className="col-md-6">
                    <div className="forms ">
                    <label htmlFor="MonthOf">For the Month of:</label>
{/* <input
  type="month"
  className="Inputs"
  id="MonthOf"
  name="MonthOf"
  value={fData.MonthOf}
  onChange={handleChange}
  placeholder="MonthOf"
  required
/> */}
<MonthDatePicker  dname={"MonthOf"}  name={"MonthOf"}  handleChange={handleEYDates} fieldInput={fData.MonthOf}/>
                      {error.MonthOf && (
                        <small className="error">{error.MonthOf}</small>
                      )}
                    </div>
                  </div>
                )}
                </div>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="phoneNumber">Total Salary:</label>
                      <input
                      type="number"
                        class="Inputs"
                        id="TSalary"
                        disabled
                        name="TSalary"
                        value={fData.TSalary !="" && fData !=null ? fData.TSalary : amount}
                        onKeyDown={handleChange}
                        placeholder="Total Salary"
                        required
                      />
                      {error.TSalary && (
                        <small className="error">{error.TSalary}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="deduction">Deduction:</label>
                      <input
                        type="number"
                        class="Inputs"
                        id="deduction"
                        name="deduction"
                        value={fData.deduction < 0 ? 0 : fData.deduction}
                        onChange={handleChange}
                        placeholder="Deduction"
                        required
                      />
                      {error.deduction && (
                        <small className="error">{error.deduction}</small>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="THSalary">Take Home Salary:</label>
                      <input
                        type="number"
                        class="Inputs"
                        id="THSalary"
                        name="THSalary"
                        value={staff_type ==="0" ? fData.TSalary - fData.deduction : fData.TSalary - fData.deduction}
                        // onChange={handleChange}
                        ref={inputRef}
                        placeholder="Take Home Salary"
                        required
                      />
                      {error.THSalary && (
                        <small className="error">{error.THSalary}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        
        </div>
      </div>
    </div>
  );
}
export default AddSalary;


