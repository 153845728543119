import { Download } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { ImageUrlPrefix } from "../../../../labels";
import moment from "moment";
import "../Purchase/Purchase.css";
import EyeIcons from "../../../../img/eyeIcons.png";
import { Link, useParams } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";

export default function ReviewUserDetail() {
  const { user_id } = useParams();

  const [admissionID, setAdmissionID] = useState({
    adId: 0,
    is_paid: null,
    batch_id: null,
  });
  const [couName, setCouName] = useState({
    coueName: "",
  });
  //education useState
  const [educationQualification, setEducationQualification] = useState([
    "SSLC",
  ]);
  const [batchDetails, setBatchDetails] = useState([]);
  //education useState
  const [sslcCerficates, setSslcCerficates] = useState([
    {
      name: "sslc",
      SSLC_CNo: "",
      SSLC_Year: "",
      SSLC_Marks: "",
      SSLC_Eboard: "",
      SSLC_Scl: "",
      SSLC_MI: "",
      SSLC_Path: null,
    },
    {
      name: "hsc",
      HSC_CNo: "",
      HSC_Year: "",
      HSC_Marks: "",
      HSC_Ebaord: "",
      HSC_Scl: "",
      HSC_MI: "",
      HSC_Path: null,
    },
    {
      name: "UG",
      UGCertificateNumber: "",
      UGYearpassing: "",
      UGDegree: "",
      UGMajor: "",
      UGMarksPercentage: "",
      UGClassName: "",
      UGNameInstitute: "",
      UG_Path: null,
    },
    {
      name: "PG",
      PGCertificateNumber: "",
      PGYearpassing: "",
      PGDegree: "",
      PGMajor: "",
      PGMarksPercentage: "",
      PGClassName: "",
      PGNameInstitute: "",
      PG_Path: null,
    },
    {
      name: "Integrated PG",
      IPCertificateNumber: "",
      IPYearpassing: "",
      IPDegree: "",
      IPMajor: "",
      IPMarksPercentage: "",
      IPClassName: "",
      IPNameInstitute: "",
      IP_Path: null,
    },
    {
      name: "Diploma",
      DCertificateNumber: "",
      DYearpassing: "",
      DDegree: "",
      DMajor: "",
      DMarksPercentage: "",
      DClassName: "",
      DNameInstitute: "",
      D_Path: null,
    },
    {
      name: "Professional Courses",
      PCCertificateNumber: "",
      PCYearpassing: "",
      PCDegree: "",
      PCMajor: "",
      PCMarksPercentage: "",
      PCClassName: "",
      PCNameInstitute: "",
      PC_Path: null,
    },
    {
      name: "Others",
      OtCertificateNumber: "",
      OtYearpassing: "",
      OtDegree: "",
      OtMajor: "",
      OtMarksPercentage: "",
      OtClassName: "",
      OtNameInstitute: "",
      Ot_Path: null,
    },
  ]);
  
  const [commentSec, setCommentSec] = useState([
    // {
    //   user_type:"",
    //   updated_dt: "",
    //   admin_comment: "",
    //   admission_id: "",
    // }
  ]);
  //specialPreference
  const [specialAdmissionPre, setSpecialAdmissionPre] = useState([
    // {
    //   id: 1,
    //   name: "",
    //   file: "",
    // },
  ]);
  //technical qualification
  const [techQualification, setTechQualification] = useState([
    {
      id: 1,
      name: "",
      level: "",
      lang: "",
      Cnum: "",
      grade: "",
      year: "",
      file: "",
    },
  ]);
  const [selectedSub, setSelectedSub] = useState([]);
  //list of inputs
  const [list, setList] = useState({
    FirstName: "",
    LastName: "",
    Fathername: "",
    MotherName: "",
    Profilephoto: null,
    DOB: "",
    age: 0,
    gender: "Male",
    MaritalStatus: "Single/Unmarried",
    RegisteredOn:"",
    enrolled:"",
    LastActiveOn:"",
    book_purchas:"",
    aadharNo: "",
    aadharCardphoto: null,
    Mobile: "",
    WhatsAppNumber: "",
    ParentMobileNo: "",
    ContactNumber: false,
    SamePresentAddress: false,
    emailaddress: "",
    BuildingNumberPresent: "",
    BuildingNumberPresent: "",
    StreetNamePresent: "",
    AreaPresent: "",
    LandmarkPresent: "",
    districtPresent: "",
    CityPresent: "",
    statePresent: "",
    PostPresent: "",
    TalukPresent: "",
    PincodePresent: "",
    BuildingNumberPermanent: "",
    StreetNamePermanent: "",
    AreaPermanent: "",
    LandmarkPermanent: "",
    CityPermanent: "",
    districtPermanent: "",
    statePermanent: "",
    PincodePermanent: "",
    PostPermanent: "",
    TalukPermanent: "",
    PresentAddress: false,
    Cast: "",
    CastFile: null,
    category: "",
    CompetitiveExamination: "Yes",
    CompetitiveExamName: "",
    Institute: "",
    ExamPreviously: "Yes",
    ExamDetailsRes: "",
    ExamWrite: "",
    workingRes: "Yes",
    Designation: "",
    Department: "",
    JoinGovt: "",
    AIASAStudent: "Yes",
    Year: "",
    CategoryAias: "",
    CourseAias: "",
    IDPhoto: null,
    original_aiasa_id_path:null,
    IDNumber: "",
    ClassType: "",
    CourseCate: "",
    CouresName: "",
    TeachMedium: "",
    Verify: false,
    admissionID: "",
    appliedOn: "",
    paidDate: "",
    BalanceFees: "",
    AdminStatus: "",
    section: "",
    comments: "",
    hallticket: "Yes",
    examcenter: "",
    ExamRegNo: "",
    district: "",
    examcenter_name: "",
    ExamName_passed: "",
    location_name: "",
    writtenexammark: "",
    Interviewmarks: "",
    overallrank: "",
    communalrank: "",
    typistrank: "",
    tamilrank: "",
    preferred_post: "",
    original_aadhar_path:null,
    original_profile_path:null,
    original_caste_path:null,
  });
  //date and time convert to normal data
  const dobCon = (userdate) => {
    if (!userdate) {
      return ""; // Handle empty input
    }
    const date = new Date(userdate);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Handle invalid date
    }
    // Extract the year, month, and day components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  //use effect for load data
  useEffect(() => {
    AxiosInstance.post("api/user/registered/view", {
      user_id: user_id,
    }).then((res) => {
        // console.log(res.data.data, "user view");
        const userinfo = res.data.data;
        const PersonDetails = {
          FirstName: userinfo.first_name,
          LastName: userinfo.last_name,
          Fathername: userinfo.father_name,
          MotherName: userinfo.mother_name,
          Profilephoto: userinfo.profile_path !==null ?  userinfo.profile_path : null ,
          DOB: userinfo.dob !==null ? dobCon(userinfo.dob) : "",
          // //  DOB: "2000-07-29",
          age: userinfo.age,
          gender: userinfo.gender,
          MaritalStatus: userinfo.marital_status,
          aadharNo: userinfo.aadhar_no,
          aadharCardphoto: userinfo.aadhar_path!==null ? userinfo.aadhar_path.split("/").pop() : null,
          original_aadhar_path:userinfo.original_aadhar_path !==null ? userinfo.original_aadhar_path : null,
          original_profile_path:userinfo.original_profile_path !==null ? userinfo.original_profile_path : null,
          original_caste_path:userinfo.original_caste_path !==null ? userinfo.original_caste_path : null,
          Cast: userinfo.community,
          CastFile:userinfo.community_path!==null ?  userinfo.community_path.split("/").pop() : "",
          Mobile: userinfo.phone_no,
          RegisteredOn: userinfo.registered_on !=null ?  dobCon(userinfo.registered_on) : "",
          enrolled:userinfo.enrolled == 0 ? "No" : "Yes",
          LastActiveOn: userinfo.last_login !=null ?  dobCon(userinfo.last_login) : "",
          book_purchas:userinfo.book_purchased == 0 ? "No" : "Yes",
          WhatsAppNumber: userinfo.whatsapp_no,
          ContactNumber: userinfo.contact_type === 0 ? false : true,
          ParentMobileNo: userinfo.parent_mob_no,
          emailaddress: userinfo.email,
        
        };

        const SpecPrefrence = userinfo.scp;
       
        setSpecialAdmissionPre(SpecPrefrence);
       

        userinfo.address.map((obj) => {
          if (obj.address_type === 1) {
            PersonDetails.SamePresentAddress =
              obj.same_address === 0 ? true : false;
            PersonDetails.BuildingNumberPresent = obj.address1;
            PersonDetails.StreetNamePresent = obj.address2;
            PersonDetails.AreaPresent = obj.address3;
            PersonDetails.LandmarkPresent = obj.landmark;
            PersonDetails.districtPresent = obj.district_name;
            PersonDetails.CityPresent = obj.city;
            PersonDetails.statePresent = obj.state_name;
            PersonDetails.PostPresent = obj.post_name;
            PersonDetails.TalukPresent = obj.taluk;
            PersonDetails.PincodePresent = obj.postal_code;
          } else {
            PersonDetails.SamePresentAddress =
              obj.same_address === 0 ? true : false;
            PersonDetails.BuildingNumberPermanent = obj.address1;
            PersonDetails.StreetNamePermanent = obj.address2;
            PersonDetails.AreaPermanent = obj.address3;
            PersonDetails.LandmarkPermanent = obj.landmark;
            PersonDetails.districtPermanent = obj.district_name;
            PersonDetails.CityPermanent = obj.city;
            PersonDetails.statePermanent = obj.state_name;
            PersonDetails.PostPermanent = obj.post_name;
            PersonDetails.TalukPermanent = obj.taluk;
            PersonDetails.PincodePermanent = obj.postal_code;
          }
        });

        // firsttime load course when empty

        // console.log(userinfo,"koloo");

        let el = userinfo.Courses[0] ? userinfo.Courses[0] : userinfo.Courses;

        PersonDetails.CompetitiveExamination =
          el.competitive_exam === 1 ? "Yes" : "NO";
        PersonDetails.CompetitiveExamName = el.competitive_exam_name;
        PersonDetails.Institute = el.institute_name;
        PersonDetails.ExamPreviously = el.cleared_any_exam === 1 ? "Yes" : "NO";
        PersonDetails.ExamDetailsRes =
        el.exam_title !==null && el.exam_title !==""
            ? el.exam_title.split(",")
            : "";
        PersonDetails.ExamWrite = el.exam_details;
        PersonDetails.workingRes = el.govt_emp === 1 ? "Yes" : "NO";
        PersonDetails.Designation = el.designation;
        PersonDetails.Department = el.department;
        PersonDetails.JoinGovt =
        el.govt_join_dt !=="" && el.govt_join_dt !==null
            ? dobCon(el.govt_join_dt)
            : "";
        PersonDetails.AIASAStudent = el.aiasa_student === 1 ? "Yes" : "NO";
        PersonDetails.Year = el.aiasa_year;
        PersonDetails.CategoryAias = el.aiasa_category;
        PersonDetails.CourseAias = el.aiasa_course;
        PersonDetails.IDNumber = el.aiasa_id_number;
        PersonDetails.IDPhoto = el.aiasa_id_path !==null ? el.aiasa_id_path : null ;
        PersonDetails.original_aiasa_id_path = el.original_aiasa_id_path !==null ? el.original_aiasa_id_path : null ;
        if (el.subject && el.subject.length > 0) {
          // Creating an array of objects in the desired format
          var result = el.subject.map((mmm) => ({
            label: mmm.name,
            value: mmm.name,
            pk: mmm.pk,
          }));
          // console.log(result,"reslt kxcxcvxcey");
          setSelectedSub(result);
        }

        setList(PersonDetails);
        

        let edq = userinfo.edu
          .filter((item) => item.qualification_type === "EDQ")
          .map((obj) => {
            let name = "";
            if (obj.name === "sslc") {
              name = "SSLC";
            } else if (obj.name === "hsc") {
              name = "HSC";
            } else {
              name = obj.name;
            }
            return name;
          });

        setEducationQualification(edq);

        let sslcCert = userinfo.edu.filter((item) => item.qualification_type === "EDQ")
          .map((el, index) => {
            if (el.name === "sslc") {
              const matchingCertificate = {};
              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.SSLC_CNo = el.certificate_no;
              matchingCertificate.SSLC_Year = el.year_of_passing;
              matchingCertificate.SSLC_Marks = el.marks_percentage;
              matchingCertificate.SSLC_Eboard = el.edu_board;
              matchingCertificate.SSLC_Scl = el.institute;
              matchingCertificate.SSLC_MI = el.edu_medium;
              matchingCertificate.SSLC_Path =  el.certificate_path!==null ?  el.certificate_path
                .split("/")
                .pop() : "";
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "hsc") {
              const matchingCertificate = {};
              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.HSC_CNo = el.certificate_no;
              matchingCertificate.HSC_Year = el.year_of_passing;
              matchingCertificate.HSC_Marks = el.marks_percentage;
              matchingCertificate.HSC_Ebaord = el.edu_board;
              matchingCertificate.HSC_Scl = el.institute;
              matchingCertificate.HSC_MI = el.edu_medium;
              matchingCertificate.HSC_Path =  el.certificate_path!==null ?  el.certificate_path
              .split("/")
              .pop() : "";
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "UG") {
              const matchingCertificate = {};

              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.UGCertificateNumber = el.certificate_no;
              matchingCertificate.UGYearpassing = el.year_of_passing;
              matchingCertificate.UGDegree = el.degree_name;
              matchingCertificate.UGMajor = el.major_subject;
              matchingCertificate.UGMarksPercentage = el.marks_percentage;
              matchingCertificate.UGClassName = el.grade;
              matchingCertificate.UGNameInstitute = el.institute;
              matchingCertificate.UG_Path = el.certificate_path!==null ?  el.certificate_path
              .split("/")
              .pop() : "";
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "PG") {
              const matchingCertificate = {};
              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.PGCertificateNumber = el.certificate_no;
              matchingCertificate.PGYearpassing = el.year_of_passing;
              matchingCertificate.PGMarksPercentage = el.marks_percentage;
              matchingCertificate.PGDegree = el.degree_name;
              matchingCertificate.PGMajor = el.major_subject;
              matchingCertificate.PGClassName = el.grade;
              matchingCertificate.PGNameInstitute = el.institute;
              matchingCertificate.PG_Path = el.certificate_path!==null ?  el.certificate_path
              .split("/")
              .pop() : "";
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "Integrated PG") {
              const matchingCertificate = {};

              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.IPCertificateNumber = el.certificate_no;
              matchingCertificate.IPYearpassing = el.year_of_passing;
              matchingCertificate.IPDegree = el.degree_name;
              matchingCertificate.IPMajor = el.major_subject;
              matchingCertificate.IPMarksPercentage = el.marks_percentage;
              matchingCertificate.IPClassName = el.grade;
              matchingCertificate.IPNameInstitute = el.institute;
              matchingCertificate.IP_Path = el.certificate_path!==null ?  el.certificate_path
              .split("/")
              .pop() : "";
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "Diploma") {
              const matchingCertificate = {};

              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.DCertificateNumber = el.certificate_no;
              matchingCertificate.DYearpassing = el.year_of_passing;
              matchingCertificate.DDegree = el.degree_name;
              matchingCertificate.DMajor = el.major_subject;
              matchingCertificate.DMarksPercentage = el.marks_percentage;
              matchingCertificate.DClassName = el.grade;
              matchingCertificate.DNameInstitute = el.institute;
              matchingCertificate.D_Path = el.certificate_path !==null ? el.certificate_path.split(
                "/"
              ).pop() : "";
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "Professional Courses") {
              const matchingCertificate = {};

              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.PCCertificateNumber = el.certificate_no;
              matchingCertificate.PCYearpassing = el.year_of_passing;
              matchingCertificate.PCDegree = el.degree_name;
              matchingCertificate.PCMajor = el.major_subject;
              matchingCertificate.PCMarksPercentage = el.marks_percentage;
              matchingCertificate.PCClassName = el.grade;
              matchingCertificate.PCNameInstitute = el.institute;
              matchingCertificate.PC_Path = el.certificate_path!==null ?  el.certificate_path
              .split("/")
              .pop() : "";
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "Others") {
              const matchingCertificate = {};

              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.OtCertificateNumber = el.certificate_no;
              matchingCertificate.OtYearpassing = el.year_of_passing;
              matchingCertificate.OtDegree = el.degree_name;
              matchingCertificate.OtMajor = el.major_subject;
              matchingCertificate.OtMarksPercentage = el.marks_percentage;
              matchingCertificate.OtClassName = el.grade;
              matchingCertificate.OtNameInstitute = el.institute;
              matchingCertificate.Ot_Path = el.certificate_path!==null ?  el.certificate_path
              .split("/")
              .pop() : "";
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            }
          });
        let teach = userinfo.edu
          .filter((item) => item.qualification_type === "THQ")
          .map((el, index) => {
            const Teachqu = {};

            // if a matching certificate is found, update its properties
            Teachqu.id = index === 0 ? 1 : index + 1;
            Teachqu.name = el.name;
            Teachqu.level = el.level;
            Teachqu.lang = el.edu_medium;
            Teachqu.Cnum = el.certificate_no;
            Teachqu.grade = el.grade;
            Teachqu.year = (el.year_of_passing !="" && el.year_of_passing!=null) ?  el.year_of_passing : "";
            Teachqu.file = el.certificate_path !==null ? el.certificate_path.split(
              "/"
            ).pop() : "";
            return Teachqu;
          });
        setTechQualification(teach);
        

      
 })
      .catch((error) => {
        return error;
      });
  }, [user_id]);

  

  
// console.log(type,batchDetails ,"sjhsajfhsajf");
  return (
    <div className="Purchase">
      <div className="row">
        <div className="col-md-12">
          <section className="sectionSix">
            <div className="heading row ">
              <div className="col-md-9 d-flex justify-content-between ">
              <h4 className="Title">View User</h4>
              <Link to="/RegisteredUser" className="CancelButton me-0 me-md-4" >
                Back to Registered Users
                </Link>
              </div>
              <div className="col-md-3 dflex">
              <div className="d-flex smButton">
                <button type="button" className="SaveButton_maa ">
                  <span class="material-icons">visibility</span>
                  <Link className="" to="/TransactionHistory">
                  View Transaction History
                  </Link>
                </button>
                
              </div>
              </div>
              {/* <div className=" mt-4">
                <h4 className="Title">View User</h4>
              </div>
              <div className="d-flex smButton mt-4">
              <Link to="/RegisteredUser" className="CancelButton" >
                Back to Registered Users
                </Link>
                <button type="button" className="SaveButton_maa ms-4">
                  <span class="material-icons">visibility</span>
                  <Link className="" to="/TransactionHistory">
                  View Transaction History
                  </Link>
                </button>
                
              </div> */}
            </div>

            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Basic Details</p>

                <div className="row">
                  <div className="col-md-9">
                    <div className="row mb-4">
                      <div className="col-md-4">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            First Name
                          </label>
                          <span className="ReviewLabel">
                            {list.FirstName || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Last Name
                          </label>
                          <span className="ReviewLabel">
                            {list.LastName || ""}
                          </span>
                        </div>
                      </div>

                    
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Father's Name/Guardian's Name
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {list.Fathername || ""}
                            </span>
                          </div>
                        </div>
                   
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Mother's Name
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {list.MotherName || ""}
                            </span>
                          </div>
                        </div>
                     

                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Date of Birth
                          </label>
                          <span className="ReviewLabel">{list.DOB || ""}</span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Gender
                          </label>
                          <span className="ReviewLabel">
                            {list.gender || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Age
                          </label>
                          <span className="ReviewLabel">{list.age || ""}</span>
                        </div>
                      </div>

                     
                     
                     
                        <div className="col-md-4">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Registered On
                            </label>
                            <span className="ReviewLabel">
                              {list.RegisteredOn || ""}
                            </span>
                          </div>
                        </div>
                    
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Marital Status
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {list.MaritalStatus || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Last Active on
                            </label>
                            <span className="ReviewLabel">
                              {list.LastActiveOn || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                            Enrolled?
                            </label>
                            <span className="ReviewLabel">
                              {list.enrolled || ""}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                           Books Purchased?
                            </label>
                            <span className="ReviewLabel">
                              {list.book_purchas || ""}
                            </span>
                          </div>
                        </div>

                        { selectedSub.length > 0  && (
                            <div className="col-lg-6 col-md-12">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Subject
                                </label>
                                <span className="ReviewLabel">
                                {selectedSub.length > 0 ? selectedSub.map((el) => el.value).join(', ') : null}
                                </span>
                              </div>
                            </div>

                          )

                        }
                     
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="forms mb-2  ">
                      <div id="profilephotos">
                        {list.Profilephoto && (
                          <img src={`${ImageUrlPrefix}/${list.Profilephoto}`} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
            <div className="sectionSix">
              <div className="row">
                <div className="col-md-6">
                  <section className="sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">ID Proofs</p>

                        <div className="row mb-3">
                          <div className="col-md-4">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                AADHAR Number
                              </label>
                              <span className="ReviewLabel">
                                {list.aadharNo || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Upload AADHAR Card
                                {list.aadharCardphoto && (
                                  <a
                                    href={`${ImageUrlPrefix}/${list.aadharCardphoto}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                              {list.original_aadhar_path || ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-md-6">
                  <section className="sectionSix ">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          Communal Reservation Category
                        </p>

                        <div className="row mb-3">
                          <div className="col-md-4">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Category
                              </label>
                              <span className="ReviewLabel">
                                {list.Cast || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Uploaded Certificate
                                {list.CastFile && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${list.CastFile}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                                {list.original_caste_path || ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div className="sectionSix">
            <div className="row">
                    {specialAdmissionPre.length > 0 && specialAdmissionPre.map((el, index) => (
                        <div
                          className={
                            specialAdmissionPre.length % 2 !== 0
                              ? specialAdmissionPre.length - 1 === index
                                ? "col-md-12"
                                : "col-md-6 "
                              : "col-md-6 "
                          }
                          key={index}
                        >
                          <section className="sectionSix">
                            <div className="row box">
                              <div className="col-md-12">
                                <p className="StepTitle">
                                  Special Category{" "}
                                  {specialAdmissionPre.length === 1
                                    ? " "
                                    : `- ${el.id}`}
                                </p>
                                <div className="row mb-3">
                                  <div className="col-md-4">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Category
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.name || ""}
                                      </span>
                                    </div>
                                  </div>
                                  {el.file !=="" &&
                                    el.file !==null && (
                                      <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                        <div className="forms ">
                                          <label
                                            htmlFor="inputName"
                                            className="form-label text-muted "
                                          >
                                            Uploaded Certificate
                                            {el.file !=="" &&
                                              el.file !==null && (
                                                <a
                                                  target="_blank"
                                                  href={`${ImageUrlPrefix}/${el.file}`}
                                                >
                                                  {" "}
                                                  <Download className="download_icon" />
                                                </a>
                                              )}
                                          </label>
                                          <span className="ReviewLabel">
                                            {el.file !==null ? el.original_pref_path: ""}
                                          </span>
                                        </div>
                                      </div>

                                    )}

                                  {el.path_sslc !=="" &&
                                    el.path_sslc !==null && (
                            <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                        <div className="forms ">
                                          <label
                                            htmlFor="inputName"
                                            className="form-label text-muted "
                                          >
                                            Uploaded Certificate for sslc
                                            {el.path_sslc !=="" &&
                                    el.path_sslc !==null && (
                                                <a
                                                  target="_blank"
                                                  href={`${ImageUrlPrefix}/${el.path_sslc}`}
                                                >
                                                  {" "}
                                                  <Download className="download_icon" />
                                                </a>
                                              )}
                                          </label>
                                          <span className="ReviewLabel">
                                            {el.path_sslc !==null ? el.original_path_sslc : ""}
                                          </span>
                                        </div>
                                      </div>

                                    )}

                                  {el.path_hsc !=="" &&
                                    el.path_hsc !== null && (
                            <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                        <div className="forms ">
                                          <label
                                            htmlFor="inputName"
                                            className="form-label text-muted "
                                          >
                                            Uploaded Certificate for HSC
                                            {el.path_hsc !=="" &&
                                              el.path_hsc !==null && (
                                                <a
                                                  target="_blank"
                                                  href={`${ImageUrlPrefix}/${el.path_hsc}`}
                                                >
                                                  {" "}
                                                  <Download className="download_icon" />
                                                </a>
                                              )}
                                          </label>
                                          <span className="ReviewLabel">
                                            {el.path_sslc !==null ?  el.original_path_hsc: ""}
                                          </span>
                                        </div>
                                      </div>

                                    )}


                                  {el.path_ug !=="" &&
                                    el.path_ug !== null && (
                                        <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                        <div className="forms ">
                                          <label
                                            htmlFor="inputName"
                                            className="form-label text-muted "
                                          >
                                            Uploaded Certificate for UG
                                            {el.path_ug !=="" &&
                                              el.path_ug !==null && (
                                                <a
                                                  target="_blank"
                                                  href={`${ImageUrlPrefix}/${el.path_ug}`}
                                                >
                                                  {" "}
                                                  <Download className="download_icon" />
                                                </a>
                                              )}
                                          </label>
                                          <span className="ReviewLabel">
                                            { el.path_sslc !==null ?  el.original_path_ug : ""}
                                          </span>
                                        </div>
                                      </div>

                                    )}

                                  {el.path_pg !=="" &&
                                    el.path_pg !==null && (
                            <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                        <div className="forms ">
                                          <label
                                            htmlFor="inputName"
                                            className="form-label text-muted "
                                          >
                                            Uploaded Certificate for PG
                                            {el.path_pg !=="" &&
                                              el.path_pg !==null && (
                                                <a
                                                  target="_blank"
                                                  href={`${ImageUrlPrefix}/${el.path_pg}`}
                                                >
                                                  {" "}
                                                  <Download className="download_icon" />
                                                </a>
                                              )}
                                          </label>
                                          <span className="ReviewLabel">
                                            {el.path_sslc !==null ? el.original_path_pg : ""}
                                          </span>
                                        </div>
                                      </div>

                                    )}

                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      ))}
                  </div>
            </div>
            <section className=" sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Contact details</p>

                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Phone Number
                        </label>
                        <span className="ReviewLabel">{list.Mobile || ""}</span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          WhatsApp Number
                        </label>
                        <span className="ReviewLabel">
                          {list.WhatsAppNumber || ""}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Father/Guardian Phone Number
                        </label>
                        <span className="ReviewLabel">
                          {list.ParentMobileNo || ""}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Email ID
                        </label>
                        <span className="ReviewLabel">
                          {list.emailaddress || ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="row">
                <div className="col-md-6">
                  <section className="sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Present Address</p>

                        <div className="row mb-3">
                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Present Address
                              </label>
                            </div>
                          </div>

                          <span className="ReviewLabel mb-2">
                            {" "}
                            {list.BuildingNumberPresent + (list.BuildingNumberPresent ? "," : "") || ""} 
                            {list.StreetNamePresent + (list.StreetNamePresent ? "," : "")  || ""}
                          </span>
                          <span className="ReviewLabel  mb-2">
                            {" "}
                            {list.AreaPresent + (list.AreaPresent ? "," : "") || ""}
                            {list.LandmarkPresent + (list.LandmarkPresent ? "," : "") || ""}
                          </span>
                          <span className="ReviewLabel  mb-2">
                            {" "}
                             {list.TalukPresent + (list.TalukPresent ? "," : "") || ""}
                              {list.PostPresent + (list.PostPresent ? "," : "") || ""}
                          </span>
                          <span className="ReviewLabel  mb-2">
                            {" "}
                           {list.PincodePresent + (list.PincodePresent ? "," : "") || ""}
                           {list.CityPresent + (list.CityPresent ? "," : "") || ""}
                          </span>
                          <span className="ReviewLabel  mb-2">
                            {" "}
                         {list.districtPresent + (list.districtPresent ? "," : "") || ""}
                         {list.statePresent + (list.statePresent ? "," : "") || ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-md-6">
                  <section className="sectionSix ">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                         Permanent Address
                        </p>

                        <div className="row mb-3">
                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {list.SamePresentAddress === true
                                  ? "Present Address"
                                  : "Permanent Address"}
                              </label>
                            </div>
                          </div>
                          <span className="ReviewLabel  mb-2">
                          {list.SamePresentAddress === true ? list.BuildingNumberPresent + (list.BuildingNumberPresent ? "," : "") : list.BuildingNumberPermanent + (list.BuildingNumberPermanent ? "," : "") || ""}
                          { list.SamePresentAddress === true ? list.StreetNamePresent + (list.StreetNamePresent ? "," : "")  : list.StreetNamePermanent + (list.StreetNamePermanent ? "," : "")  || ""}
                      </span>
                      <span className="ReviewLabel  mb-2">
                        {" "}
                        {list.SamePresentAddress === true ? list.AreaPresent + (list.AreaPresent ? "," : "") : list.AreaPermanent + (list.AreaPermanent ? "," : "") || ""}
                        { list.SamePresentAddress === true ? list.LandmarkPresent + (list.LandmarkPresent ? "," : "")  : list.LandmarkPermanent + (list.LandmarkPermanent ? "," : "")  || ""}
                      </span>
                      <span className="ReviewLabel  mb-2">
                        {" "}
                        { list.SamePresentAddress === true ? list.TalukPresent + (list.TalukPresent ? "," : "") : list.TalukPermanent + (list.TalukPermanent ? "," : "") || ""}
                        { list.SamePresentAddress === true ? list.PostPresent + (list.PostPresent ? "," : "")  : list.PostPermanent + (list.PostPermanent ? "," : "")  || ""}
                      </span>
                      <span className="ReviewLabel  mb-2">
                        {" "}
                        {list.SamePresentAddress === true ? list.PincodePresent + (list.PincodePresent ? "," : "")  : list.PincodePermanent + (list.PincodePermanent ? "," : "")  || ""}
                        {list.SamePresentAddress === true ? list.CityPresent + (list.CityPresent ? "," : "")  : list.CityPermanent + (list.CityPermanent ? "," : "")  || ""}
                      </span>
                      <span className="ReviewLabel  mb-2">
                        {" "}
                        { list.SamePresentAddress === true ? list.districtPresent + (list.districtPresent ? "," : "") : list.districtPermanent + (list.districtPermanent ? "," : "") || ""} 
                        { list.SamePresentAddress === true ? list.statePresent + (list.statePresent ? "," : "") : list.statePermanent + (list.statePermanent ? "," : "") || ""}
                      </span>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="row">
                {educationQualification.includes("SSLC") && (
                  <div
                    className={
                      educationQualification.includes("SSLC") &&
                      !educationQualification.includes("HSC")
                        ? " col-md-12"
                        : " col-md-6"
                    }
                  >
                    <section className="sectionSix ">
                      <div className="row box">
                        <div className="col-md-12">
                          <p className="StepTitle">SSLC</p>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Certificate Number
                                </label>
                                <span className="ReviewLabel">
                                  {sslcCerficates[0].SSLC_CNo || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {" "}
                                  Year of Passing
                                </label>
                                <span className="ReviewLabel">
                                  {sslcCerficates[0].SSLC_Year || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {" "}
                                  Marks Percentage
                                </label>
                                <span className="ReviewLabel">
                                  {sslcCerficates[0].SSLC_Marks || ""}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {" "}
                                  Education Board
                                </label>
                                <span className="ReviewLabel">
                                  {sslcCerficates[0].SSLC_Eboard || ""}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {" "}
                                  Medium of Instruction
                                </label>
                                <span className="ReviewLabel">
                                  {sslcCerficates[0].SSLC_MI || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {" "}
                                  School Name
                                </label>
                                <span className="ReviewLabel">
                                  {sslcCerficates[0].SSLC_Scl || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {" "}
                                  Uploaded Certificate
                                  {sslcCerficates[0].SSLC_Path && (
                                    <a
                                      target="_blank"
                                      href={`${ImageUrlPrefix}/${sslcCerficates[0].SSLC_Path}`}
                                    >
                                      {" "}
                                      <Download className="download_icon" />
                                    </a>
                                  )}
                                </label>
                                <span className="ReviewLabel">
                                {sslcCerficates[0].SSLC_Path !==null ? sslcCerficates[0].original_file_path : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>{" "}
                  </div>
                )}
                {educationQualification.includes("HSC") && (
                  <div
                    className={
                      !educationQualification.includes("SSLC") &&
                      educationQualification.includes("HSC")
                        ? " col-md-12"
                        : " col-md-6 "
                    }
                  >
                    <section
                      className={`${
                        !educationQualification.includes("SSLC") &&
                        educationQualification.includes("HSC")
                          ? ""
                          : ""
                      } sectionSix`}
                    >
                      <div className="row box">
                        <div className="col-md-12">
                          <p className="StepTitle">HSC</p>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Certificate Number
                                </label>
                                <div className="ReviewLabel">
                                  {sslcCerficates[1].HSC_CNo || ""}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {" "}
                                  Year of Passing
                                </label>
                                <span className="ReviewLabel">
                                  {sslcCerficates[1].HSC_Year || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {" "}
                                  Marks Percentage
                                </label>
                                <span className="ReviewLabel">
                                  {sslcCerficates[1].HSC_Marks || ""}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {" "}
                                  Education Board
                                </label>
                                <span className="ReviewLabel">
                                  {sslcCerficates[1].HSC_Ebaord || ""}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {" "}
                                  Medium of Instruction
                                </label>
                                <span className="ReviewLabel">
                                  {sslcCerficates[1].HSC_MI || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {" "}
                                  School Name
                                </label>
                                <span className="ReviewLabel">
                                  {sslcCerficates[1].HSC_Scl || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {" "}
                                  Uploaded Certificate
                                  {sslcCerficates[1].HSC_Path && (
                                    <a
                                      target="_blank"
                                      href={`${ImageUrlPrefix}/${sslcCerficates[1].HSC_Path}`}
                                    >
                                      {" "}
                                      <Download className="download_icon" />
                                    </a>
                                  )}
                                </label>
                                <span className="ReviewLabel">
                                {sslcCerficates[1].HSC_Path!==null ? sslcCerficates[1].original_file_path : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                )}
              </div>
          {educationQualification.includes("UG") && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">UG</p>

                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UGCertificateNumber || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {sslcCerficates[2].UGYearpassing || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UGDegree || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UGMajor || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UGMarksPercentage || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UGClassName || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                        Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UGNameInstitute || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded UG Certificate
                          {sslcCerficates[2].UG_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[2].UG_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                        {sslcCerficates[2].UG_Path!==null ? sslcCerficates[2].original_file_path : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          { educationQualification.includes("PG") && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">PG</p>

                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PGCertificateNumber || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {sslcCerficates[3].PGYearpassing || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PGDegree || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PGMajor || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PGMarksPercentage || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PGClassName || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                        Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PGNameInstitute || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[3].PG_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[3].PG_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                        { sslcCerficates[3].PG_Path !== null ? sslcCerficates[3].original_file_path : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          { educationQualification.includes("Integrated PG") && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Integrated PG</p>

                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPCertificateNumber || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPYearpassing || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPDegree || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPMajor || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPMarksPercentage || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPClassName || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                         Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPNameInstitute || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[4].IP_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[4].IP_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                        {sslcCerficates[4].IP_Path !==null ? sslcCerficates[4].original_file_path : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          { educationQualification.includes("Diploma") && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Diploma</p>

                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DCertificateNumber || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DYearpassing || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DDegree || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DMajor || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks of percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DMarksPercentage || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DClassName || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                         Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DNameInstitute || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[5].D_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[5].D_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                        {sslcCerficates[5].D_Path!==null ? sslcCerficates[5].original_file_path : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
            {
            educationQualification.includes("Professional Courses") && (
              <section className="sectionSix">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Professional Courses</p>

                    <div className="row mb-3">
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Certificate Number
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PCCertificateNumber || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Year of Passing
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {sslcCerficates[6].PCYearpassing || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Degree
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PCDegree || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Major
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PCMajor || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Marks Percentage
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PCMarksPercentage || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Class
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PCClassName || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                         Name of the college / university
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PCNameInstitute || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Uploaded Certificate
                            {sslcCerficates[6].PC_Path && (
                              <a
                                target="_blank"
                                href={`${ImageUrlPrefix}/${sslcCerficates[6].PC_Path}`}
                              >
                                {" "}
                                <Download className="download_icon" />
                              </a>
                            )}
                          </label>
                          <span className="ReviewLabel">
                          {sslcCerficates[6].PC_Path!==null ? sslcCerficates[6].original_file_path : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          {educationQualification.includes("Others") && (
            <section className=" sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Others</p>

                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtCertificateNumber || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtYearpassing || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtDegree || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtMajor || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtMarksPercentage || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtClassName || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                        Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtNameInstitute || ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[7].Ot_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[7].Ot_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                        {sslcCerficates[7].Ot_Path!==null ? sslcCerficates[7].original_file_path : ""} 
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

<div className="row">
              {techQualification.length > 0 && !(techQualification[0].name === "" && techQualification[0].level === "" && techQualification[0].lang === "" && techQualification[0].Cnum === "" && techQualification[0].grade === "" && techQualification[0].year === "" && techQualification[0].file === "") && (  
                          techQualification.map((el, index) => (
                          <div
                            className={
                              techQualification.length === 1
                                ? "col-md-12 "
                                : "col-md-12 "
                            }
                            key={index}
                          >
                            <section className=" sectionSix">
                              <div className="row box ">
                                <div className="col-md-12 ">
                                  <p className="StepTitle">
                                    Technical Qualification{" "}
                                    {techQualification.length === 1
                                      ? " "
                                      : `- ${el.id}`}
                                  </p>

                                  <div className="row mb-3">
                                    <div className="col-md-3">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Technicnal Course
                                        </label>
                                        <span className="ReviewLabel">
                                          {el.name || ""}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Typewriting Level
                                        </label>
                                        <span className="ReviewLabel">
                                          {el.level || ""}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Language
                                        </label>
                                        <span className="ReviewLabel">
                                          {el.lang || ""}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Certificate Number
                                        </label>
                                        <span className="ReviewLabel">
                                          {el.Cnum || ""}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Grade
                                        </label>
                                        <span className="ReviewLabel">
                                          {el.grade || ""}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Year of Passing
                                        </label>
                                        <span className="ReviewLabel">
                                          {el.year !=="" ? el.year : ""}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Upload Certificate
                                          {el.file && (
                                            <a
                                              target="_blank"
                                              href={`${ImageUrlPrefix}/${el.file}`}
                                            >
                                              {" "}
                                              <Download className="download_icon" />
                                            </a>
                                          )}
                                        </label>
                                        <span className="ReviewLabel">
                                        {el.original_file_path || ""}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        )))}
              </div>
         
  <section className=" sectionSix">
  <div className="row box">
    <div className="col-md-12">
      <p className="StepTitle">Have You Already Studied for Competitive Examination?</p>
      <div className="row mb-3 mt-2">
          <div className="col-md-3">
            <div className="forms ">
              <label
                htmlFor="inputName"
                className="form-label text-muted  oneline"
              >
                Have You Already Studied for Competitive Examination?
              </label>
              <span className="ReviewLabel mt-2">
                {list.CompetitiveExamination || ""}
              </span>
            </div>
          </div>
       

       
          <div className="col-md-3">
            <div className="forms ">
              <label
                htmlFor="inputName"
                className="form-label text-muted "
              >
                Name of the Examination
              </label>
              <span className="ReviewLabel">
                {list.CompetitiveExamName || ""}
              </span>
            </div>
          </div>
       

       
          <div className="col-md-6">
            <div className="forms ">
              <label
                htmlFor="inputName"
                className="form-label text-muted "
              >
                Name of the Institute
              </label>
              <span className="ReviewLabel">
                {list.Institute || ""}
              </span>
            </div>
          </div>
       

       
      </div>
    </div>
  </div>
</section>
       
     
  <section className="sectionSix">
  <div className="row box">
    <div className="col-md-12">
      <p className="StepTitle">Have You Cleared Any Exam Previously?</p>

      <div className="row mb-3">
       
          <div className="col-md-3">
            <div className="forms ">
              <label
                htmlFor="inputName"
                className="form-label text-muted  oneline"
              >
                Have You Cleared Any Exam Previously?
              </label>
              <span className="ReviewLabel mt-2">
                {list.ExamPreviously || ""}
              </span>
            </div>
          </div>
    

       
          <div className="col-md-3">
            <div className="forms ">
              <label
                htmlFor="inputName"
                className="form-label text-muted  oneline"
              >
                How many exams have you cleared in the past?
              </label>
              <span className="ReviewLabel mt-2">
                {list.ExamWrite || ""}
              </span>
            </div>
          </div>
      
        
          <div className="col-md-6">
            <div className="forms ">
              <label
                htmlFor="inputName"
                className="form-label text-muted  oneline"
              >
                Exam details
              </label>
              <span className="register_app_val mb-3">
                {list.ExamDetailsRes || ""}
              </span>
            </div>
          </div>
       

      
      </div>
    </div>
  </div>
</section>
   <section className=" sectionSix">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Are you a government employee?</p>

                <div className="row mb-3">
                 
                 
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted  oneline"
                        >
                          Are you a government employee?
                        </label>
                        <span className="ReviewLabel">
                          {list.workingRes || ""}
                        </span>
                      </div>
                    </div>
               
              
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Designation
                        </label>
                        <span className="ReviewLabel">
                          {list.Designation || ""}
                        </span>
                      </div>
                    </div>
               

                
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Department
                        </label>
                        <span className="ReviewLabel">
                          {list.Department || ""}
                        </span>
                      </div>
                    </div>
                

                 
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Joining Date of Government Job
                        </label>
                        <span className="ReviewLabel">
                          {list.workingRes === "Yes" ? list.JoinGovt : ""}
                        </span>
                      </div>
                    </div>
                 

                 
                </div>
              </div>
            </div>
          </section>
          <section className=" sectionSix">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Are You an AIASA Student?</p>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted  oneline"
                      >
                        Are You an AIASA Student?
                      </label>
                      <span className="ReviewLabel">
                        {list.AIASAStudent || ""}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Year
                      </label>
                      <span className="ReviewLabel">{list.Year || ""}</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        category
                      </label>
                      <span className="ReviewLabel">
                        {list.CategoryAias || ""}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Course
                      </label>
                      <span className="ReviewLabel">
                        {list.AIASAStudent === "Yes" ? list.CourseAias : ""}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        ID Number
                      </label>
                      <span className="ReviewLabel">{list.IDNumber || ""}</span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted  oneline"
                      >
                        Upload ID Card
                        {list.IDPhoto && (
                          <a
                            target="_blank"
                            href={`${ImageUrlPrefix}/${list.IDPhoto}`}
                          >
                            {" "}
                            <Download className="download_icon" />
                          </a>
                        )}
                      </label>
                      <span className="ReviewLabel">{list.original_aiasa_id_path || ""}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
