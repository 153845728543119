import React, { useState, useEffect } from "react";
import ExamPad from "../../../../img/testmodule/ExamPad.png";
import { Link } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = (props) => {
  const { startTime, endTime, finishTime, event, batchID, eventID, userID } =
    props;
  const navigate = useNavigate();
  const [showPage2, setShowPage2] = useState(false);
  const startMoment = moment(startTime, "hh:mm:ss A");
  const endMoment = moment(endTime, "hh:mm:ss A");
  const durationMs = endMoment.diff(startMoment);

  const finishingMoment = moment(finishTime, "HH:mm:ss A");
  let minutes = finishingMoment.minute();
  let seconds = finishingMoment.second();

  useEffect(() => {
    const interval = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        setShowPage2(true);
        clearInterval(interval);
      } else {
        if (seconds === 0) {
          minutes -= 1;
          seconds = 59;
        } else {
          seconds -= 1;
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [minutes, seconds]);
  const handleViewTestPerfomance = () => {
    // navigate(`/viewTestPerformance`, {
    //   state: {
    //     userID: userID,
    //     batchID: batchID,
    //     eventID: eventID,
    //   },
    // });
    navigate("/TestPerformanceListView");
  };

  return (
    <>
      {!showPage2 && (
        <div className="BathDet">
          <div className="row">
            <div className="col-md-12">
              <section className="StepOne">
                <div className="heading dflexbetww">
                  <div className="mt-2 dbetw">
                    <h6 className="Title">
                      {event.event_name} - {event.startDate}
                    </h6>
                  </div>
                </div>
                <section className="StepOne mt-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row mt-5">
                        <div className="col-lg-4 col-md-4 payment-col-img">
                          <div>
                            <img
                              className="payment-brand mb-4"
                              src={ExamPad}
                              alt="LogoImage"
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-10 ">
                          <p className="payment-name">Hi {event.user_name}</p>
                          <p className="payment-paragraph">
                            Your answer sheet has been submitted successfully.
                          </p>
                          <p className="payment-paragraph">
                            Your evaluated results will be accessible, and the
                            View Your Test Performance button will be enabled in{" "}
                            <span className="payment-span">
                              {minutes}m : {seconds}s{" "}
                            </span>
                            , allowing you to view your results.
                          </p>
                          <p className="payment-paragraph">
                            Additionally, you can view your test performance
                            from the{" "}
                            <span className="payment-color">
                              Test Performance
                            </span>{" "}
                            page.
                          </p>
                          <button
                            type="submit"
                            className="payment-button"
                            style={{ cursor: "none" }}
                            onClick={handleViewTestPerfomance}
                          >
                            View Your Test Performance
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
      )}

      {showPage2 && (
        <div className="BathDet">
          <div className="row">
            <div className="col-md-12">
              <section className="StepOne">
                <div className="heading dflexbetww">
                  <div className="mt-2 dbetw">
                    <h6 className="Title">
                      {event.event_name} - {event.startDate}
                    </h6>
                  </div>
                </div>
                <section className="StepOne mt-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row mt-5">
                        <div className="col-lg-4 col-md-4 payment-col-img">
                          <div>
                            <img
                              className="payment-brand mb-4"
                              src={ExamPad}
                              alt="LogoImage"
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-10 ">
                          <p className="payment-name">Hi {event.user_name}</p>
                          <p className="payment-paragraph">
                            Your answer sheet has been submitted successfully.
                          </p>
                          <p className="payment-paragraph">
                            You can view your test results and analytics by
                            clicking the button below
                          </p>
                          <button type="submit" className="DashboardButton">
                            <Link
                              className="text-white"
                              to={"/TestPerformanceListView"}
                            >
                              View Your Test Performance{" "}
                            </Link>
                          </button>
                          <p className="payment-paragraph mt-3">
                            Additionally, you can view your test performance
                            from the{" "}
                            <span className="payment-color">
                              Test Performance
                            </span>{" "}
                            page.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentSuccess;
