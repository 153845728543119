import React, { useState, useRef, useEffect } from "react";

import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import { CloseOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
function QuestionList() {
  // List data load table

  // use state
  let page = R_PER_PAGE;
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);

  const [csvListData, setCsvListData] = useState([]);

  const [error, setError] = useState([]);
  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;
  let role_id = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      role_id = user.role_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  useEffect(() => {
    loadListdata();
  }, []);

  function loadListdata() {
    let statusFilter = {};
    // console.log(statusFilter, "statusFilter");
    AxiosInstance.post("api/test/report/list")
      .then((res) => {
        // console.log("editadasd ", res);
        if (res.data.status === true) {
          const updatedData = res.data.data.map((item) => {
            return item;
          });
          // Filter out null items
          const filteredData = updatedData.filter((item) => item !== null);
          // Set the state with the filtered data
          setUpdateListData(filteredData);

          // const csvData = filteredData.map((el) => {
          //   return {
          //     "date":el.date,
          //     "completed_perc": el.completed_perc,
          //     "batch_name": el.batch_name,
          //     "category":el.category,
          //     "class_mode": el.class_mode,
          //     "medium": el.medium,
          //     "location": el.location,
          //   };
          // });
          // setCsvListData(csvData);
        } else if (res.data.status === false) {
          setUpdateListData([]);
        }
      })
      .catch((error) => {
        // console.log("Error res: ", error.response.data);
        setUpdateListData([]);
        return error;
      });
  }

  // generate pdf when user click download button
  const generatePDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add a title to the PDF document
    doc.text("Table Data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    // Add header row to tableData array
    const headers = [];
    const tableHeader = document.querySelectorAll("#example th");
    for (const header of tableHeader) {
      headers.push(header.innerText);
    }
    tableData.push(headers);

    // Add data rows to tableData array
    const tableRows = document.querySelectorAll("#example tbody tr");
    for (const row of tableRows) {
      const rowData = [];
      const rowCells = row.querySelectorAll("td");
      for (const cell of rowCells) {
        rowData.push(cell.innerText);
      }
      tableData.push(rowData);
    }

    // Add the table to the PDF document
    doc.autoTable({
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
    });
    // Save the PDF document
    doc.save("AdmissionData.pdf");
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;

    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      // setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  const handleReadView = (id, event_id) => {
    // navigate(`/Faculties/ReadViewSalary/${props.userId}/${id}/${staff_id}/${props.staff_type}`);
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  // const handleEditView = (staff_id) => {
  //   navigate(`/Faculties/addfaculty/${staff_id}`);
  // };
  // className="row tab-content mt-4"
  return (
    <div className=" BathDet ">
      <div className="row">
        <div className="col-md-3">
          <h6 className="Title">View test list</h6>
        </div>
        <div className="col-md-9 d-flex justify-content-end mt-2">
          <Link
            onClick={navigateToPreviousScreen}
            to="javascript:void(0)"
            className="CancelButtons"
          >
            Back to event details
          </Link>
        </div>
      </div>
      <div className="row ms-1 mt-4">
        <div className="col-lg-3 col-md-4"></div>
        <div className="col-lg-4 col-md-4"></div>
        <div className="col-lg-5  d-flex  col-md-6">
          <button type="button" className="ListViewBtnss me-4">
            <span className="material-icons me-1 align-middle text-white">
              file_download
            </span>
            <CSVLink data={updateListData}>Download as CSV</CSVLink>
          </button>
          <button type="button" className="ListViewBtnss" onClick={generatePDF}>
            <span className="material-icons me-1 align-middle text-white">
              file_download
            </span>
            Download as PDF
          </button>
        </div>
      </div>

      <div className="row mt-3 ms-1 me-1">
        <div className="col-md-12 table-responsive p-0">
          <table className="table" id="example">
            <thead>
              <tr>
                <th id="th">
                  {" "}
                  <span className="text_th">S.No</span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "serialNumber")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "full_name")}
                  >
                    {" "}
                    Full Name
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "full_name")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "batch_name")}
                  >
                    {" "}
                    Batch Name
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "batch_name")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "event_name")}
                  >
                    Event Name
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "event_name")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "tot_marks")}
                  >
                    Total Test mark
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "tot_marks")}
                  >
                    import_export
                  </span>{" "}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "marks_obtained")}
                  >
                    marks
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "marks_obtained")}
                  >
                    import_export
                  </span>{" "}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "duration_completed")}
                  >
                    Test duration
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "duration_completed")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  {" "}
                  <span className="text_th">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.length === 0 ? (
                <tr>
                  <td colspan="10" style={{ textAlign: "center" }}>
                    No record to display
                  </td>
                </tr>
              ) : (
                currentData.map((course, index) => (
                  <tr key={index} title={index}>
                    <td id="td">{course.serialNumber}</td>
                    <td id="td"> {course.full_name}</td>
                    <td id="td"> {course.batch_name}</td>
                    <td id="td"> {course.event_name}</td>
                    <td id="td">{course.tot_marks}</td>
                    <td id="td">{course.marks_obtained}</td>
                    <td id="td">{course.duration_completed}</td>
                    <td id="td">
                      <button
                        className="button"
                        onClick={() =>
                          handleReadView(course.pk, course.event_pk)
                        }
                      >
                        <span class="material-icons">visibility</span>
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-3 ms-1 me-1">
        <div className="col-md-12">
          <div className="pag w-100 dflexcenter">
            <span className="ipage">Items Per page</span>
            <select
              name="pageCount"
              id="pageCount"
              defaultValue={rowsPerPage}
              onChange={handlePageCount}
              className="ms-2"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="pagenumber ms-2">
              {" "}
              {currentPage * rowsPerPage + 1 - rowsPerPage} -{" "}
              {/* {currentPage * rowsPerPage} of {updateListData.length} */}
              {updateListData.length < currentPage * rowsPerPage
                ? updateListData.length
                : currentPage * rowsPerPage}{" "}
              of {updateListData.length}
            </span>
            <button
              className="prev_button ms-2 me-2"
              onClick={(e) => handlePrev(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <span class="material-icons">chevron_left</span>
            </button>
            <button
              className="prev_button"
              onClick={() => handleNext(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <span class="material-icons">chevron_right</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionList;
