import { ValidateCfocus } from "./ValidateCfocus";

export const validateFields = (
  data,
  certificates,
  edu,
  step,
  selectedPre,
  selectedPreDept,
  AdmissionRef_validation,
  batchDetails,
  selectedPrev,
  specialAdmissionPre,
  setSpecialAdmissionPre,
  techQualification,
  setTechQualification
) => {
  // console.log(selectedPrev,"selectedPrev");
  const errors = {};
  if (step === 1 && !data.FirstName) {
    errors.FirstName = "Please enter your first name.";
  }
  if (step === 1 && !data.LastName) {
    errors.LastName = "Please enter your LastName.";
  }
  if (step === 1 && !data.Fathername) {
    errors.Fathername = "Please enter your Fathername.";
  }
  if (step === 1 && !data.MotherName) {
    errors.MotherName = "Please enter your MotherName.";
  }
  if (step === 1 && !data.DOB) {
    errors.DOB = "Please enter your DOB.";
  }
  if (step === 1 && !data.age) {
    errors.age = "Please enter your age.";
  }
  if (step === 1 && !data.gender) {
    errors.gender = "Please choose gender.";
  }
  if (step === 1 && !data.MaritalStatus) {
    errors.MaritalStatus = "Please choose Marital Status.";
  }
  if (step === 1 && !data.Profilephoto) {
    errors.Profilephoto = "Please upload Profilephoto.";
  }
  if (step === 1 && !data.aadharNo) {
    errors.aadharNo = "Please enter Aadhar No.";
  }
  if (step === 1 && data.aadharNo && data.aadharNo.length < 12) {
    errors.aadharNo = " Aadhar No must be 12 digit.";
  }
  if (step === 1 && data.aadharNo && data.aadharNo.length > 12) {
    errors.aadharNo = " Aadhar No must be 12 digit.";
  }
  if (step === 1 && !data.aadharCardphoto) {
    errors.aadharCardphoto = "Please Upload AadharCardphoto in Pdf format.";
  }
  if (step === 1 && !data.Cast) {
    errors.Cast = "Please choose category.";
  }
  if (step === 1 && !data.SPCcategory) {
    errors.SPCcategory = "Please choose at least one category.";
  }
  if (step === 1 && specialAdmissionPre.length > 0 ) {
    const updateSpc = specialAdmissionPre.map((OB) => {
      let updatedOB = { ...OB };
  
      if (OB.name === "") {
        updatedOB.error_name = "Name is required";
      } else {
        updatedOB.error_name = "";
      }
      if (OB.name === "Tamil Medium (PSTM)" && OB.pstm_type  && OB.pstm_type.length === 0) {
        updatedOB.error_path_sslc = " At least one should be selected";
      } else {
        updatedOB.error_path_sslc = "";
      }
      return updatedOB;
    });
  
    setSpecialAdmissionPre(updateSpc);
  }
  // if (step === 1 && (!data.CastFile || data.CastFile.length === 0)) {
  //   errors.CastFile = "Please Upload CastFile in Pdf format.";
  // }
  //  if (step === 1 && data.CastFile.size > 2 * 1024 * 1024) {
  //   errors.CastFile = "File size must be less than 2MB.";
  // }
  if (step === 2 && !data.Mobile) {
    errors.Mobile = "Please enter Mobile number.";
  }
  if (step === 2 && data.Mobile && data.Mobile.length < 10) {
    errors.Mobile = "Please enter 10 digit Mobile number.";
  }
  if (step === 2 && data.Mobile && !/^\d+$/.test(data.Mobile)) {
    errors.Mobile = "Please enter a valid Mobile number.";
  }
  if (
    step === 2 &&
    (!data.WhatsAppNumber || !/^\d+$/.test(data.WhatsAppNumber)) &&
    !data.ContactNumber
  ) {
    errors.WhatsAppNumber = "Please enter WhatsApp Number.";
  }
  if (
    step === 2 &&
    data.WhatsAppNumber &&
    data.WhatsAppNumber.length < 10 &&
    data.ContactNumber === false
  ) {
    errors.WhatsAppNumber = "Please enter 10 digit WhatsApp Number.";
  }
  if (
    step === 2 &&
    data.WhatsAppNumber &&
    !/^\d+$/.test(data.WhatsAppNumber) &&
    !data.ContactNumber
  ) {
    errors.WhatsAppNumber = "Please enter a valid WhatsApp number.";
  }
  if (step === 2 && !data.ParentMobileNo) {
    errors.ParentMobileNo = "Please enter Parent MobileNo.";
  }
  if (step === 2 && data.ParentMobileNo && data.ParentMobileNo.length < 10) {
    errors.ParentMobileNo = "Please enter 10 digit Parent MobileNo.";
  }
  if (step === 2 && data.ParentMobileNo && !/^\d+$/.test(data.ParentMobileNo)) {
    errors.ParentMobileNo = "Please enter a valid WhatsApp number.";
  }
  if (step === 2 && !data.emailaddress) {
    errors.emailaddress = "Please enter email address.";
  }
  if (
    step === 2 &&
    data.emailaddress &&
    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.emailaddress)
  ) {
    errors.emailaddress = "Please enter a valid email address.";
  }
  if (step === 2 && !data.BuildingNumberPresent) {
    errors.BuildingNumberPresent = "Please enter Building Number .";
  }
  if (step === 2 && !data.StreetNamePresent) {
    errors.StreetNamePresent = "Please enter Street Name.";
  } if (step === 2 && !data.TalukPresent) {
    errors.TalukPresent = "Please  enter Taluk.";
  } 
  if (step === 2 && !data.PostPresent) {
    errors.PostPresent = "Please  enter Post.";
  }
  if (step === 2 && !data.AreaPresent) {
    errors.AreaPresent = "Please  enter Area.";
  }
  if (step === 2 && !data.PincodePresent) {
    errors.PincodePresent = "Please  enter Pincode.";
  }
  if (step === 2 && !/^[1-9][0-9]{5}$/.test(data.PincodePresent)) {
    errors.PincodePresent = "Please enter a valid Pincode.";
  }
  if (step === 2 && !data.CityPresent) {
    errors.CityPresent = "Please  enter City.";
  }
  if (
    step === 2 &&
    !data.BuildingNumberPermanent &&
    data.SamePresentAddress === false
  ) {
    errors.BuildingNumberPermanent = "Please enter Building Number .";
  }
  if (
    step === 2 &&
    !data.StreetNamePermanent &&
    data.SamePresentAddress === false
  ) {
    errors.StreetNamePermanent = "Please enter Street Name.";
  }
  if (step === 2 &&
    !data.TalukPermanent &&
    data.SamePresentAddress === false) {

    errors.TalukPermanent = "Please  enter Taluk.";

  }
  if (step === 2 && !data.PostPermanent && data.SamePresentAddress === false) {
    errors.PostPermanent = "Please  enter Post.";
  } if (step === 2 && !data.AreaPermanent && data.SamePresentAddress === false) {
    errors.AreaPermanent = "Please  enter Area.";
  }
  if (
    step === 2 &&
    !data.PincodePermanent &&
    data.SamePresentAddress === false
  ) {
    errors.PincodePermanent = "Please  enter Pincode.";
  }
  if (
    step === 2 &&
    !/^[1-9][0-9]{5}$/.test(data.PincodePermanent) &&
    data.SamePresentAddress === false
  ) {
    errors.PincodePermanent = "Please enter a valid Pincode.";
  }
  if (step === 2 && !data.CityPermanent && data.SamePresentAddress === false) {
    errors.CityPermanent = "Please  enter City.";
  }
  // if (step === 3 && techQualification.length > 0) {
  //   const updateTHQ = techQualification.map((OB) => {
  //     let updatedOB = { ...OB };
  //     console.log(OB,"OB");
  //     if (OB.name === "") {
  //       updatedOB.error_name = "Name is required";
  //     }
  //     if (OB.name !== "") {
  //       updatedOB.error_name = "";
  //     }
  //     if (OB.level === "") {
  //       updatedOB.error_level = "Level is required";
  //     } 
  //     if (OB.level !== "") {
  //       updatedOB.error_level = "";

  //     } 
  //     if (OB.lang === "") {
  //       updatedOB.error_edu_medium = "Lang is required";
  //     } 
  //     if (OB.lang !== "") {
  //       updatedOB.error_edu_medium = "";
  //     } 
  //     if (OB.Cnum === "") {
  //       updatedOB.error_certificate_no = "Certificate No is required";
  //     } 
  //     if (OB.Cnum !== "") {
  //       updatedOB.error_certificate_no = "";
  //     } 
  //     if (OB.year === "") {
  //       updatedOB.error_year_of_passing = "Year of passing is required";
  //     } 
  //     if (OB.year !== "") {
  //       updatedOB.error_year_of_passing = "";
  //     } 
  //     if (OB.grade === "") {
  //       updatedOB.error_grade = "Grade is required";
  //     } 
  //     if (OB.grade !== "") {
  //       updatedOB.error_grade = "";
  //     } 
  //     return updatedOB;
  //   });
  //   console.log(updateTHQ)
  //   setTechQualification(updateTHQ);
  // }
  if (step === 4 && !data.category) {
    errors.category = "please enter Course Category.";
  }
  if (step === 4 && !data.ExamName) {
    errors.ExamName = "please Enter Exam name you passed.";
  }
  if (step === 4 && !data.ExamRegno) {
    errors.ExamRegno = "please enter Exam Register number.";
  }
  if (step === 4 && data.ExamRegno && data.ExamRegno.length > 10) {
    errors.ExamRegno = "please enter maximum 10 number only.";
  }
  if (step === 4 && !data.location) {
    errors.location = "please choose location.";
  }
  if (step === 4 && selectedPreDept.length === 0) {
    errors.preferredDept = "please choose preferred Dept and related fields .";
  }
  if (
    step === 4 &&
    batchDetails.length > 0 &&
    !batchDetails.some((batch) => batch.is_selected)
  ) {
    errors.batchDetails = "Please choose a Batch.";
  }
  if (
    step === 4 &&
    batchDetails.length > 0 &&
    batchDetails.some(batch => batch.panel_details && batch.panel_details.length === 0) 
  ) {
    errors.batchDetails = "Please choose a Batch and Exam Detail.";
  } 
  if (
    step === 4 &&
    batchDetails.length > 0 &&
    batchDetails.some(batch => batch.is_selected) &&
    batchDetails.some(batch => batch.panel_details && batch.panel_details.length > 0) &&
    !batchDetails.some(batch => batch.panel_details && batch.panel_details.some(exam => exam.is_selected_panel))
  ) {
    errors.batchDetails = "Please choose a Batch and Exam Detail.";
  } 
  if (step === 4 && !data.markPaperone && data.category === "UPSC") {
    errors.markPaperone = "please enter mark Paper one.";
  }
  if (
    step === 4 &&
    data.markPaperone &&
    typeof data.markPaperone === "string" &&
    data.markPaperone.length > 10 &&
    data.category === "UPSC"
  ) {
    errors.markPaperone = "10 digit only allowed for this fields";
  }
  if (step === 4 && !data.markPaperTwo && data.category === "UPSC") {
    errors.markPaperTwo = "please enter mark Paper Two.";
  }
  if (
    step === 4 &&
    data.markPaperTwo &&
    typeof data.markPaperTwo === "string" &&
    data.markPaperTwo.length > 10 &&
    data.category === "UPSC"
  ) {
    errors.markPaperTwo = "10 digit only allowed for this fields";
  }
  if (step === 4 && !data.markpaperThree && data.category === "UPSC") {
    errors.markpaperThree = "please enter mark paper Three.";
  }
  if (
    step === 4 &&
    data.markpaperThree &&
    typeof data.markpaperThree === "string" &&
    data.markpaperThree.length > 10 &&
    data.category === "UPSC"
  ) {
    errors.markpaperThree = "10 digit only allowed for this fields";
  }
  if (step === 4 && !data.markPaperFour && data.category === "UPSC") {
    errors.markPaperFour = "please enter mark Paper Four.";
  }
  if (
    step === 4 &&
    data.markPaperFour &&
    typeof data.markPaperFour === "string" &&
    data.markPaperFour.length > 10 &&
    data.category === "UPSC"
  ) {
    errors.markPaperFour = "10 digit only allowed for this fields";
  }
  if (step === 4 && !data.markPaperFive && data.category === "UPSC") {
    errors.markPaperFive = "please enter mark Paper Five.";
  }
  if (
    step === 4 &&
    data.markPaperFive &&
    typeof data.markPaperFive === "string" &&
    data.markPaperFive.length > 10 &&
    data.category === "UPSC"
  ) {
    errors.markPaperFive = "10 digit only allowed for this fields";
  }
  if (step === 4 && !data.markPaperSix && data.category === "UPSC") {
    errors.markPaperSix = "please  enter mark Paper Six.";
  }
  if (
    step === 4 &&
    data.markPaperSix &&
    typeof data.markPaperSix === "string" &&
    data.markPaperSix.length > 10 &&
    data.category === "UPSC"
  ) {
    errors.markPaperSix = "10 digit only allowed for this fields";
  }
  if (step === 4 && !data.markPaperSeven && data.category === "UPSC") {
    errors.markPaperSeven = "please enter mark Paper Seven.";
  }
  if (
    step === 4 &&
    data.markPaperSeven &&
    typeof data.markPaperSeven === "string" &&
    data.markPaperSeven.length > 10 &&
    data.category === "UPSC"
  ) {
    errors.markPaperSeven = "10 digit only allowed for this fields";
  }
  if (step === 4 && !data.markPaperEight && data.category === "UPSC") {
    errors.markPaperEight = "please enter mark Paper Eight.";
  }
  if (
    step === 4 &&
    data.markPaperEight &&
    typeof data.markPaperEight === "string" &&
    data.markPaperEight.length > 10 &&
    data.category === "UPSC"
  ) {
    errors.markPaperEight = "10 digit only allowed for this fields";
  }
  if (step === 4 && !data.markPaperSeven && data.category === "UPSC") {
    errors.markPaperSeven = "please enter mark Paper Seven.";
  }
  if (
    step === 4 &&
    data.markPaperSeven &&
    typeof data.markPaperSeven === "string" &&
    data.markPaperSeven.length > 10 &&
    data.category === "UPSC"
  ) {
    errors.markPaperSeven = "10 digit only allowed for this fields";
  }
  if (step === 4 && !data.markPaperNine && data.category === "UPSC") {
    errors.markPaperNine = "please enter mark Paper Nine.";
  }
  if (
    step === 4 &&
    data.markPaperNine &&
    typeof data.markPaperNine === "string" &&
    data.markPaperNine.length > 10 &&
    data.category === "UPSC"
  ) {
    errors.markPaperNine = "10 digit only allowed for this fields";
  }
  if (
    step === 4 &&
    (data.category === "UPSC" ||
      (data.category === "TNPSC" &&
        (data.ExamName === "3" ||
          data.ExamName === "34" ||
          data.ExamName === 3 ||
          data.ExamName === 34))) &&
    !data.markUpdatedMains
  ) {
    errors.markUpdatedMains = "please enter this field.";
  }
  if (
    step === 4 &&
    (data.category === "UPSC" ||
      (data.category === "TNPSC" &&
        (data.ExamName === "3" ||
          data.ExamName === "34" ||
          data.ExamName === 3 ||
          data.ExamName === 34))) &&
    data.markUpdatedMains &&
    typeof data.markUpdatedMains === "string" &&
    data.markUpdatedMains.length > 10
  ) {
    errors.markUpdatedMains = "10 digit only allowed for this fields.";
  }
  if (
    step === 4 &&
    (data.category === "UPSC" ||
      (data.category === "TNPSC" &&
        (data.ExamName === "3" ||
          data.ExamName === "34" ||
          data.ExamName === 3 ||
          data.ExamName === 34))) &&
    !data.markUpdatedOral
  ) {
    errors.markUpdatedOral = "please enter this field.";
  }
  if (
    step === 4 &&
    (data.category === "UPSC" ||
      (data.category === "TNPSC" &&
        (data.ExamName === "3" ||
          data.ExamName === "34" ||
          data.ExamName === 3 ||
          data.ExamName === 34))) &&
    data.markUpdatedOral &&
    typeof data.markUpdatedOral === "string" &&
    data.markUpdatedOral.length > 10
  ) {
    errors.markUpdatedOral = "10 digit only allowed for this fields.";
  }
  if (
    step === 4 &&
    (data.category === "SSC" ||
      data.category === "TNPSC" ||
      data.category === "RRB" ||
      data.category === "UPSC") &&
    !data.overallrank
  ) {
    errors.overallrank = "please enter this field.";
  }
  if (
    step === 4 &&
    (data.category === "SSC" ||
      data.category === "TNPSC" ||
      data.category === "RRB" ||
      data.category === "UPSC") &&
    data.overallrank &&
    typeof data.overallrank === "string" &&
    data.overallrank.length > 10
  ) {
    errors.overallrank = "10 digit only allowed for this fields.";
  }
  if (
    step === 4 &&
    (data.category === "SSC" || data.category === "RRB") &&
    !data.MarksOne
  ) {
    errors.MarksOne = "please enter this field.";
  }
  if (
    step === 4 &&
    (data.category === "SSC" || data.category === "RRB") &&
    data.MarksOne &&
    typeof data.MarksOne === "string" &&
    data.MarksOne.length > 10
  ) {
    errors.MarksOne = "10 digit only allowed for this fields.";
  }
  if (
    step === 4 &&
    (data.category === "SSC" || data.category === "RRB") &&
    !data.MarksTwo
  ) {
    errors.MarksTwo = "please enter this field.";
  }
  if (
    step === 4 &&
    (data.category === "SSC" || data.category === "RRB") &&
    data.MarksTwo &&
    typeof data.MarksTwo === "string" &&
    data.MarksTwo.length > 10
  ) {
    errors.MarksTwo = "10 digit only allowed for this fields.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "4" || data.ExamName === 4) &&
    !data.Interviewmarks
  ) {
    errors.Interviewmarks = "please enter this field.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "4" || data.ExamName === 4) &&
    data.Interviewmarks &&
    typeof data.Interviewmarks === "string" &&
    data.Interviewmarks.length > 10
  ) {
    errors.Interviewmarks = "10 digit only allowed for this fields.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === "4" || data.ExamName === 4) &&
    !data.StenoRank
  ) {
    errors.StenoRank = "please enter this field.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === "4" || data.ExamName === 4) &&
    data.StenoRank &&
    typeof data.StenoRank === "string" &&
    data.StenoRank.length > 10
  ) {
    errors.StenoRank = "10 digit only allowed for this fields.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === "4" || data.ExamName === 4) &&
    !data.WindowRank
  ) {
    errors.WindowRank = "please enter this field.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === "4" || data.ExamName === 4) &&
    data.WindowRank &&
    typeof data.WindowRank === "string" &&
    data.WindowRank.length > 10
  ) {
    errors.WindowRank = "10 digit only allowed for this fields.";
  }
  if (
    step === 4 &&
    (data.category === "SSC" ||
      data.category === "TNPSC" ||
      data.category === "RRB" ||
      data.category === "UPSC") &&
    !data.communalrank
  ) {
    errors.communalrank = "please enter this field.";
  }
  if (
    step === 4 &&
    (data.category === "SSC" ||
      data.category === "TNPSC" ||
      data.category === "RRB" ||
      data.category === "UPSC") &&
    data.communalrank &&
    typeof data.communalrank === "string" &&
    data.communalrank.length > 10
  ) {
    errors.communalrank = "10 digit only allowed for this fields.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === 5) &&
    !data.typistrank
  ) {
    errors.typistrank = "please enter this field.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === 5) &&
    data.typistrank &&
    typeof data.typistrank === "string" &&
    data.typistrank.length > 10
  ) {
    errors.typistrank = "10 digit only allowed for this fields.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === 5) &&
    !data.tamilrank
  ) {
    errors.tamilrank = "please enter this field.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === 5) &&
    data.tamilrank &&
    typeof data.tamilrank === "string" &&
    data.tamilrank.length > 10
  ) {
    errors.tamilrank = "10 digit only allowed for this fields.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" ||
      data.ExamName === "4" ||
      data.ExamName === 5 ||
      data.ExamName === 4) &&
    !data.PSTMRank
  ) {
    errors.PSTMRank = "please enter this field.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" ||
      data.ExamName === "4" ||
      data.ExamName === 5 ||
      data.ExamName === 4) &&
    data.PSTMRank &&
    typeof data.PSTMRank === "string" &&
    data.PSTMRank.length > 10
  ) {
    errors.PSTMRank = "10 digit only allowed for this fields.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" ||
      data.ExamName === "4" ||
      data.ExamName === 5 ||
      data.ExamName === 4) &&
    !data.DRank
  ) {
    errors.DRank = "please enter this field.";
  }
  if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" ||
      data.ExamName === "4" ||
      data.ExamName === 5 ||
      data.ExamName === 4) &&
    data.DRank &&
    typeof data.DRank === "string" &&
    data.DRank.length > 10
  ) {
    errors.DRank = "10 digit only allowed for this fields.";
  }
  if (step === 4 && selectedPre.length === 0) {
    errors.preferredpost = "please choose preferred post and related fields .";
  }

  //    if (step === 4 && !data.writtenexammark) {
  //   errors.writtenexammark = "please Enter Written Exam mark.";
  // }
  //      if (step === 4 && !data.Interviewmarks) {
  //   errors.Interviewmarks = "please Enter Interview mark.";
  // }
  //      if (step === 4 && !data.overallrank) {
  //   errors.overallrank = "please Enter Overallrank.";
  // }
  //      if (step === 4 && !data.communalrank) {
  //   errors.communalrank = "please Enter Communal rank.";
  // }
  //      if (step === 4 && !data.typistrank) {
  //   errors.typistrank = "please Enter Typist rank.";
  // }
  //      if (step === 4 && !data.tamilrank) {
  //   errors.tamilrank = "please Enter Rank in Tamil Medium Reservation.";
  // }
  //      if (step === 4 && !data.preferredpost) {
  //   errors.preferredpost = "please Enter Preferred Post.";
  // }
  if (step === 4 && !data.ExamWrite && data.ExamPreviously === "Yes") {
    errors.ExamWrite = "Please enter this field.";
  }
  // if (
  //   step === 4 &&
  //   selectedPrev.length > 1 && selectedPrev.every(item => item.examname === "" && item.examvalue === "") &&
  //   data.ExamPreviously === "Yes"
  // ) {
  //   errors.ExamDetailsRes = "Please enter exam details.";
  // }
  if (
    step === 4 &&
    data.ExamWrite &&
    data.ExamPreviously === "Yes" &&
    typeof data.ExamWrite === "string" &&
    data.ExamWrite.length > 5
  ) {
    errors.ExamWrite = "Number must be 5 digits only.";
  }
  if (step === 4 && !data.Designation && data.workingRes === "Yes") {
    errors.Designation = "Please enter Designation.";
  }
  if (step === 4 && !data.Department && data.workingRes === "Yes") {
    errors.Department = "Please enter Department.";
  }
  if (step === 4 && !data.JoinGovt && data.workingRes === "Yes") {
    errors.JoinGovt = "Please enter Join date.";
  }
  if (step === 4 && !data.Year && data.AIASAStudent === "Yes") {
    errors.Year = "Please enter Year.";
  }
  if (step === 4 && !/^\d{4}$/.test(data.Year) && data.AIASAStudent === "Yes") {
    errors.Year = "Please enter a valid year.";
  }
  if (step === 4 && !data.CourseAias && data.AIASAStudent === "Yes") {
    errors.CourseAias = "Please choose Course.";
  }
  if (step === 4 && !data.CategoryAias && data.AIASAStudent === "Yes") {
    errors.CategoryAias = "Please Choose Category.";
  }
  if (step === 4 && !data.IDNumber && data.AIASAStudent === "Yes") {
    errors.IDNumber = "Please enter IDNumber.";
  }
  if (
    step === 2 &&
    data.IDNumber &&
    typeof data.IDNumber === "string" &&
    data.IDNumber.length > 20 &&
    data.AIASAStudent === "Yes"
  ) {
    errors.IDNumber = "Please enter maximum 10 number only.";
  }
  if (step === 4 && data.AIASAStudent === "Yes" && !data.IDPhoto) {
    errors.IDPhoto = "Please choose IDPhoto.";
  }
  if (
    step === 4 &&
    data.AIASAStudent === "Yes" &&
    data.IDPhoto.size > 2 * 1024 * 1024
  ) {
    errors.IDPhoto = "File size must be less than 2MB.";
  }
  if (step === 3 && edu.length === 0) {
    errors.EducationQualification = "please choose educational";
  }
  for (let i = 0; i < certificates.length; i++) {
    const cert = certificates[i];
    if (step === 3 && edu.includes("SSLC")) {
      if ( !cert.SSLC_CNo  && cert.name === "sslc") {
        errors.SSLC_CNo = "Certificate number is required";
      } 
      if (cert.SSLC_CNo && !cert.SSLC_CNo.trim() && cert.name === "sslc") {
        errors.SSLC_CNo = "Certificate number is required";
      } 
       if (cert.SSLC_CNo && !/^[A-Za-z0-9]*$/.test(cert.SSLC_CNo) && cert.name === "sslc") {
        errors.SSLC_CNo = "Certificate number can only contain letters and numbers";
      }
      //  if (
      //   cert.SSLC_CNo &&
      //   typeof cert.SSLC_CNo === "string" &&
      //   cert.SSLC_CNo.length > 10 &&
      //   cert.name === "sslc"
      // ) {
      //   errors.SSLC_CNo = "Certificate number is maximum 10 characters";
      //   AdmissionRef_validation.eduSCer.current.focus();
      // } 
       if (!cert.SSLC_Year && cert.name === "sslc") {

        errors.SSLC_Year = "Please enter Year of passing";
       
      }  if (!cert.SSLC_Marks && cert.name === "sslc") {
        errors.SSLC_Marks = "Marks percentage is required";
     
      }  if (
        cert.SSLC_Marks &&
        typeof cert.SSLC_Marks === "string" &&
        parseInt(cert.SSLC_Marks) > 100 &&
        cert.name === "sslc"
      ) {
        errors.SSLC_Marks = "MarksPercentage is must be less than 100%";

      
      }  if (!cert.SSLC_Eboard && cert.name === "sslc") {
          errors.SSLC_Eboard = "Please enter education board name";
   
      }  if (!cert.SSLC_Scl && cert.name === "sslc") {
        errors.SSLC_Scl = "School name is required";
    
      } if (cert.SSLC_Scl && !/^[A-Za-z\s]*$/.test(cert.SSLC_Scl) && cert.name === "sslc") {
        errors.SSLC_Scl = "School name can only contain letters and spaces";
    
      }   if (!cert.SSLC_MI && cert.name === "sslc") {
        errors.SSLC_MI = "Medium of instruction is required";
  
      } 
      //  if (!cert.SSLC_Path && cert.name === "sslc") {
      //   errors.SSLC_Path = "Please Upload SSLC Certificate in Pdf format";
   
      // }
    }
    if ( !cert.HSC_CNo && cert.name == "hsc" && edu.includes("HSC")) {
      errors.HSC_CNo = "Certificate number is required";
    } 
    if (cert.HSC_CNo && !cert.HSC_CNo.trim() && cert.name == "hsc" && edu.includes("HSC")) {
      errors.HSC_CNo = "Certificate number is required";
    
    }  if (cert.HSC_CNo && !/^[A-Za-z0-9]*$/.test(cert.HSC_CNo) && cert.name == "hsc" && edu.includes("HSC")) {
      errors.HSC_CNo = "Certificate number can only contain letters and numbers";
 
    }
    //  if (
    //   cert.HSC_CNo &&
    //   typeof cert.HSC_CNo === "string" &&
    //   cert.HSC_CNo.length > 10 &&
    //   cert.name === "hsc" &&
    //   edu.includes("HSC")
    // ) {
    //   errors.HSC_CNo = "Certificate number is maximum 10 number";
    //   AdmissionRef_validation.eduHCer.current.focus();
    // }
      if (!cert.HSC_Year && cert.name == "hsc" && edu.includes("HSC")) {
      errors.HSC_Year = "Please enter Year of passing";
    
    }  if (!cert.HSC_Marks && cert.name == "hsc" && edu.includes("HSC")) {
      errors.HSC_Marks = "Marks percentage is required";

    }  if (
      cert.HSC_Marks &&
      typeof cert.HSC_Marks === "string" &&
     parseInt(cert.HSC_Marks) > 100 &&
     cert.name === "hsc"
    ) {
      errors.HSC_Marks = "MarksPercentage is must be less than 100%";
   
    }  if (!cert.HSC_Ebaord && cert.name == "hsc" && edu.includes("HSC")) {
      errors.HSC_Ebaord = "Education Board name is required";
   
    }  if (!cert.HSC_Scl && cert.name == "hsc" && edu.includes("HSC")) {
      errors.HSC_Scl = "School name is required";
 
    }  if (!cert.HSC_MI && cert.name == "hsc" && edu.includes("HSC")) {
      errors.HSC_MI = "Medium of instruction is required";
  
    }
    //   if (!cert.HSC_Path && cert.name == "hsc" && edu.includes("HSC")) {
    //   errors.HSC_Path = "Please Upload HSC  Certificate in Pdf format";
    
    // }
    if (step === 3 && edu.includes("UG")) {
      if (!cert.UGDegree && cert.name === "UG") {
        errors.UGDegree = "Degree is required";

      } if (!cert.UGMajor && cert.name === "UG") {
        errors.UGMajor = "Major is required";
      }
      if (cert.UGMajor && cert.UGMajor === "Others" && !cert.UGMajor_Other && cert.name === "UG") {
        errors.UGMajor_Other = "Major Other field is required";
      }
      if (!cert.UGYearpassing && cert.name === "UG") {
        errors.UGYearpassing = "Please enter Year of passing";

      } if (!cert.UGCertificateNumber && cert.name === "UG") {
        errors.UGCertificateNumber = "Certificate number is required";
      }
      if (cert.UGCertificateNumber && !cert.UGCertificateNumber.trim() && cert.name === "UG") {
        errors.UGCertificateNumber = "Certificate number is required";

      } if (cert.UGCertificateNumber && !/^[A-Za-z0-9]*$/.test(cert.UGCertificateNumber) && cert.name === "UG") {
        errors.UGCertificateNumber = "Certificate number can only contain letters and numbers";

      } if (!cert.UGMarksPercentage && cert.name === "UG") {
        errors.UGMarksPercentage = "Marks percentage is required";

      } if (
        cert.UGMarksPercentage &&
        typeof cert.UGMarksPercentage == "string" &&
        parseInt(cert.UGMarksPercentage) > 100 &&
        cert.name === "UG"
      ) {
        errors.UGMarksPercentage =
          "MarksPercentage is must be less than 100%";
      }
      if (!cert.UGClassName && cert.name === "UG") {
        errors.UGClassName = "class is required";

      }
      //   if (cert.UGClassName && !/^[A-Za-z\s]*$/.test(cert.UGClassName) && cert.name === "UG") {
      //   errors.UGClassName = "class can only contain letters and spaces";
      //   AdmissionRef_validation.eduUclass.current.focus();
      // }
      if (!cert.UGNameInstitute && cert.name === "UG") {
        errors.UGNameInstitute = "Name of the college / university is required";

      } if (cert.UGNameInstitute && !/^[A-Za-z\s.]*$/.test(cert.UGNameInstitute) && cert.name === "UG") {
        errors.UGNameInstitute = "Name of the college / university can contain letters, spaces, and dots, but not numbers.";

      }
      //  if (!cert.UG_Path && cert.name === "UG") {
      //   errors.UG_Path = "Please Upload UG  Certificate in Pdf format";

      // }
    }

    if (step === 3 && edu.includes("PG")) {
      if (cert.PGDegree == "" && cert.name === "PG") {
        errors.PGDegree = "Degree is required";

      } if (!cert.PGMajor && cert.name === "PG") {
        errors.PGMajor = "Major is required";

      }
      if (cert.PGMajor && cert.PGMajor === "Others" && !cert.PGMajor_Other && cert.name === "PG") {
        errors.PGMajor_Other = "Major Other field is required";
      }
      if (!cert.PGYearpassing && cert.name === "PG") {
        errors.PGYearpassing = "Please enter Year of passing";

      } if (!cert.PGCertificateNumber && cert.name === "PG") {
        errors.PGCertificateNumber = "Certificate number is required";

      }
      if (cert.PGCertificateNumber && !cert.PGCertificateNumber.trim() && cert.name === "PG") {
        errors.PGCertificateNumber = "Certificate number is required";

      } if (cert.PGCertificateNumber && !/^[A-Za-z0-9]*$/.test(cert.PGCertificateNumber) && cert.name === "PG") {
        errors.PGCertificateNumber = "Certificate number can only contain letters and numbers";

      } if (!cert.PGMarksPercentage && cert.name === "PG") {
        errors.PGMarksPercentage = "Marks percentage is required";

      } if (
        cert.PGMarksPercentage &&
        typeof cert.PGMarksPercentage === "string" &&
        parseInt(cert.PGMarksPercentage) > 100 &&
        cert.name === "PG"
      ) {
        errors.PGMarksPercentage =
          "MarksPercentage is must be less than 100%";

      } if (!cert.PGClassName && cert.name === "PG") {
        errors.PGClassName = "class is required";

      }
      //  if (cert.PGClassName && !/^[A-Za-z\s]*$/.test(cert.PGClassName) && cert.name === "PG") {
      //   errors.PGClassName = "class can only contain letters and spaces";
      //   AdmissionRef_validation.eduPclass.current.focus();
      // }
      if (!cert.PGNameInstitute && cert.name === "PG") {
        errors.PGNameInstitute = "Name of the college / university is required";

      } if (cert.PGNameInstitute && !/^[A-Za-z\s.]*$/.test(cert.PGNameInstitute) && cert.name === "PG") {
        errors.PGNameInstitute = "Name of the college / university can only contain letters and spaces";

      }
      //  if (!cert.PG_Path && cert.name === "PG") {
      //   errors.PG_Path = "Please Upload PG Certificate in Pdf format";

      // }
    }

    if (step === 3 && edu.includes("Integrated PG")) {
      if (!cert.IPDegree && cert.name === "Integrated PG") {
        errors.IPDegree = "Degree is required";

      } if (!cert.IPMajor && cert.name === "Integrated PG") {
        errors.IPMajor = "Major is required";

      }
      if (cert.IPMajor && cert.IPMajor === "Others" && !cert.IPMajor_Other && cert.name === "Integrated PG") {
        errors.IPMajor_Other = "Major Other field is required";
      }
      if (!cert.IPYearpassing && cert.name === "Integrated PG") {
        errors.IPYearpassing = "Please enter Year of passing";

      } if (!cert.IPCertificateNumber && cert.name === "Integrated PG") {
        errors.IPCertificateNumber = "Certificate number is required";
      }
      if (cert.IPCertificateNumber && !cert.IPCertificateNumber.trim() && cert.name === "Integrated PG") {
        errors.IPCertificateNumber = "Certificate number is required";

      } if (cert.IPCertificateNumber && !/^[A-Za-z0-9]*$/.test(cert.IPCertificateNumber) && cert.name === "Integrated PG") {
        errors.IPCertificateNumber = "Certificate number can only contain letters and numbers";

      } if (!cert.IPMarksPercentage && cert.name === "Integrated PG") {
        errors.IPMarksPercentage = "Marks percentage is required";

      } if (
        cert.IPMarksPercentage &&
        typeof cert.IPMarksPercentage === "string" &&
        parseInt(cert.IPMarksPercentage) > 100 &&
        cert.name === "Integrated PG"
      ) {
        errors.IPMarksPercentage =
          "MarksPercentage is must be less than 100%";

      } if (!cert.IPClassName && cert.name === "Integrated PG") {
        errors.IPClassName = "Class is required";

      }
      //   if (cert.IPClassName && !/^[A-Za-z\s]*$/.test(cert.IPClassName) && cert.name === "Integrated PG") {
      //   errors.IPClassName = "class can only contain letters and spaces";
      //   AdmissionRef_validation.eduIclass.current.focus();
      // }
      if (!cert.IPNameInstitute && cert.name === "Integrated PG") {
        errors.IPNameInstitute = "Name of the college / university is required";

      } if (cert.IPNameInstitute && !/^[A-Za-z\s.]*$/.test(cert.IPNameInstitute) && cert.name === "Integrated PG") {
        errors.IPNameInstitute = "Name of the college / university can only contain letters and spaces";

      }
      //   if (!cert.IP_Path && cert.name === "Integrated PG") {
      //   errors.IP_Path = "Please Upload Integrated PG  Certificate in Pdf format";

      // }
    }

    if (step === 3 && edu.includes("Diploma")) {

      if (!cert.DDegree && cert.name === "Diploma") {
        errors.DDegree = "Degree is required";

      } if (!cert.DMajor && cert.name === "Diploma") {
        errors.DMajor = "Major is required";

      }
      if (cert.DMajor && cert.DMajor === "Others" && !cert.DMajor_Other && cert.name === "Diploma") {
        errors.DMajor_Other = "Major Other field is required";
      }
      if (!cert.DYearpassing && cert.name === "Diploma") {
        errors.DYearpassing = "Please enter Year of passing";

      } if (!cert.DCertificateNumber && cert.name === "Diploma") {
        errors.DCertificateNumber = "Certificate number is required";

      }
      if (cert.DCertificateNumber && !cert.DCertificateNumber.trim() && cert.name === "Diploma") {
        errors.DCertificateNumber = "Certificate number is required";

      } if (cert.DCertificateNumber && !/^[A-Za-z0-9]*$/.test(cert.DCertificateNumber) && cert.name === "Diploma") {
        errors.DCertificateNumber = "Certificate number can only contain letters and numbers";
      } if (!cert.DMarksPercentage && cert.name === "Diploma") {
        errors.DMarksPercentage = "Marks percentage is required";

      } if (
        cert.DMarksPercentage &&
        typeof cert.DMarksPercentage === "string" &&
        parseInt(cert.DMarksPercentage) > 100 &&
        cert.name === "Diploma"
      ) {
        errors.DMarksPercentage =
          "MarksPercentage is must be less than 100%";

      }
      if (!cert.DClassName && cert.name === "Diploma") {
        errors.DClassName = "Class is required";

      }
      //  if (cert.DClassName && !/^[A-Za-z\s]*$/.test(cert.DClassName) && cert.name === "Diploma") {
      //   errors.DClassName = "class can only contain letters and spaces";
      //   AdmissionRef_validation.eduDclass.current.focus();
      // }
      if (!cert.DNameInstitute && cert.name === "Diploma") {
        errors.DNameInstitute = "Name of the college / university is required";

      } if (cert.DNameInstitute && !/^[A-Za-z\s.]*$/.test(cert.DNameInstitute) && cert.name === "Diploma") {
        errors.DNameInstitute = "Name of the college / university can only contain letters and spaces";

      }
      //   if (!cert.D_Path && cert.name === "Diploma") {
      //   errors.D_Path = "Please Upload Diploma Certificate in Pdf format";

      // }
    }

    if (step === 3 && edu.includes("Professional Courses")) {

      if (!cert.PCDegree && cert.name === "Professional Courses") {
        errors.PCDegree = "Degree is required";

      } if (!cert.PCMajor && cert.name === "Professional Courses") {
        errors.PCMajor = "Major is required";

      }
      if (cert.PCMajor && cert.PCMajor === "Others" && !cert.PCMajor_Other && cert.name === "Professional Courses") {
        errors.PCMajor_Other = "Major Other field is required";
      }
      if (!cert.PCYearpassing && cert.name === "Professional Courses") {
        errors.PCYearpassing = "Please enter Year of passing";

      } if (!cert.PCCertificateNumber && cert.name === "Professional Courses") {
        errors.PCCertificateNumber = "Certificate number is required";

      }
      if (cert.PCCertificateNumber && !cert.PCCertificateNumber.trim() && cert.name === "Professional Courses") {
        errors.PCCertificateNumber = "Certificate number is required";

      } if (cert.PCCertificateNumber && !/^[A-Za-z0-9]*$/.test(cert.PCCertificateNumber) && cert.name === "Professional Courses") {
        errors.PCCertificateNumber = "Certificate number can only contain letters and numbers";

      } if (
        !cert.PCMarksPercentage &&
        cert.name === "Professional Courses"
      ) {
        errors.PCMarksPercentage = "Marks percentage is required";

      } if (
        cert.PCMarksPercentage &&
        typeof cert.PCMarksPercentage === "string" &&
        parseInt(cert.PCMarksPercentage) > 100 &&
        cert.name === "Professional Courses"
      ) {
        errors.PCMarksPercentage =
          "Marks percentage is must be less than 100%";

      } if (!cert.PCClassName && cert.name === "Professional Courses") {
        errors.PCClassName = "Class is required";

      }
      //  if (cert.PCClassName && !/^[A-Za-z\s]*$/.test(cert.PCClassName) && cert.name === "Professional Courses") {
      //   errors.PCClassName = "class can only contain letters and spaces";
      //   AdmissionRef_validation.eduCclass.current.focus();
      // } 
      if (
        !cert.PCNameInstitute &&
        cert.name === "Professional Courses"
      ) {
        errors.PCNameInstitute = "Name of the college / university is required";

      } if (cert.PCNameInstitute && !/^[A-Za-z\s.]*$/.test(cert.PCNameInstitute) && cert.name === "Professional Courses") {
        errors.PCNameInstitute = "Name of the college / university can only contain letters and spaces";

      }
      // if (!cert.PC_Path && cert.name === "Professional Courses") {
      //   errors.PC_Path = "Please Upload Professional Courses Certificate in Pdf format";

      // }
    }

    if (step === 3 && edu.includes("Others")) {
      if (!cert.OtDegree && cert.name === "Others") {
        errors.OtDegree = "Degree is required";

      } if (!cert.OtMajor && cert.name === "Others") {
        errors.OtMajor = "Major is required";

      }
      if (!cert.OtYearpassing && cert.name === "Others") {
        errors.OtYearpassing = "Please enter Year of passing";

      } if (!cert.OtCertificateNumber && cert.name === "Others") {
        errors.OtCertificateNumber = "Certificate number is required";
      }
      if (cert.OtCertificateNumber && !cert.OtCertificateNumber.trim() && cert.name === "Others") {
        errors.OtCertificateNumber = "Certificate number is required";

      } if (cert.OtCertificateNumber && !/^[A-Za-z0-9]*$/.test(cert.OtCertificateNumber) && cert.name === "Others") {
        errors.OtCertificateNumber = "Certificate number can only contain letters and numbers";

      } if (!cert.OtMarksPercentage && cert.name === "Others") {
        errors.OtMarksPercentage = "Marks percentage is required";

      } if (
        cert.OtMarksPercentage &&
        typeof cert.OtMarksPercentage === "string" &&
        parseInt(cert.OtMarksPercentage) > 100 &&
        cert.name === "Others"
      ) {
        errors.OtMarksPercentage =
          "Marks percentage is must be less than 100%";

      } if (!cert.OtClassName && cert.name === "Others") {
        errors.OtClassName = "Class is required";

      }
      //  if (cert.OtClassName && !/^[A-Za-z\s]*$/.test(cert.OtClassName) && cert.name === "Others") {
      //   errors.OtClassName = "class can only contain letters and spaces";
      //   AdmissionRef_validation.eduOclass.current.focus();
      // } 
      if (!cert.OtNameInstitute && cert.name === "Others") {
        errors.OtNameInstitute = "Name of the college / universitye is required";

      } if (cert.OtNameInstitute && !/^[A-Za-z\s.]*$/.test(cert.OtNameInstitute) && cert.name === "Others") {
        errors.OtNameInstitute = "Name of the college / university can only contain letters and spaces";

      }
      //   if (!cert.Ot_Path && cert.name === "Others") {
      //   errors.Ot_Path = "Please Upload Professional Courses Certificate in Pdf format";

      // }
    }
    // Add additional validation rules for SSLC/HSC_Path if necessary
  }
  ValidateCfocus(data,
    certificates,
    edu,
    step,
    selectedPre,
    selectedPreDept,
    AdmissionRef_validation,
    batchDetails,
    selectedPrev);
  return errors;
};
