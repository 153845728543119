import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserDetails";
// import banner from '../../img/banner/ban1.jpg';
import "../../Themes/themes.css";
// import bannerTwo from '../../img/banner/ban2.jpg';
import bannerThree from "../../img/banner/bann1.jpg";
import bannerOne from "../../img/banner/bann2.jpg";
import bannerTwo from "../../img/banner/bann3.jpg";
import bannerFive from "../../img/banner/bann4.jpg";
import bannerFour from "../../img/banner/bann5.jpg";
import bannerSix from "../../img/banner/bann6.jpg";
import bannerSeven from "../../img/banner/bann7.jpg";
import bannerEight from "../../img/banner/bann8.jpg";
import bannerNine from "../../img/banner/bann9.jpg";
import bannerTen from "../../img/banner/bann10.jpg";
import bannerEl from "../../img/banner/bann11.jpg";
import bannerTW from "../../img/banner/bann12.jpg";
import bannerTHit from "../../img/banner/bann13.jpg";

import bannerFT from "../../img/banner/bann14.jpg";
import bannerFIF from "../../img/banner/bann15.jpg";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import ModelCompontent from "./ModelCompontent";
import Footer from "../../features/Core/Footer/Footer";
import PaymentDueModel from "../PaymentDueModel";
import { AxiosInstance } from "../../axios";
import Swal from "sweetalert2";
import ModelComForP from "../ModelComForP";
// import PublicResult from "../../shared/Component/BackOffice/TestAdminWorkflow/PublicResult";
// import WarningPopUp from "../../shared/Component/BackOffice/StudentTestWorkflow/WarningPopUp";
// import RaiseQuery from "../../shared/Component/BackOffice/StudentTestWorkflow/RaiseQuery";
// import ViewQuery from "../../shared/Component/BackOffice/StudentTestWorkflow/ViewQuery";
// import ViewExplaination from "../../shared/Component/BackOffice/StudentTestWorkflow/ViewExplaination";

function IndexPage() {
  const { user } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);

  const [showModalData, setShowModalData] = useState({});

  const [activeIndex, setActiveIndex] = useState(0);
  const images = [
    bannerThree,
    bannerOne,
    bannerTwo,
    bannerFour,
    bannerFT,
    bannerFive,
    bannerSix,
    bannerSeven,
    bannerFIF,
    bannerEight,
    bannerNine,
    bannerTen,
    bannerEl,
    bannerTW,
    bannerTHit,
  ]; // Replace with your image sources
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;
  let role_id = null;
  let user_type = null;
  let menuData = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      role_id = user.role_id;
      user_type = user.user_type;
      menuData = user.menu_list;
    }
  } else {
    console.log("User Id cannot available in localstorge");
  }

  useEffect(() => {
    setShowModal(true); // Show the modal when the component loads

    AxiosInstance.post("api/user/reminder/byId", { user_id: userid })
      .then((res) => {
        if (res.data.status === true) {
          const dueDetailsData = res.data.data;
          // console.log(res.data.data)
          const dueDetailsString = JSON.stringify(dueDetailsData);
          localStorage.setItem("DueDetails", dueDetailsString);
          setShowModalData(dueDetailsData);
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Slide every minute
    return () => {
      clearInterval(interval);
    };
  }, [activeIndex]);

  const nextSlide = () => {
    setActiveIndex((activeIndex + 1) % images.length);
  };
  // main exam model box close

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="AdmissionForm FooterLink ">
      <div className="row">
        <div className="col-md-12  p-0">
          {user.user_id ? (
            <section className=" StepOne headerwel">
              <h2 className="text-center">Welcome To Dashboard</h2>
              {/* <RaiseQuery /> */}
              {/* <PublicResult /> */}
              {user_type === 2 &&
                showModalData.res_ids &&
                showModalData.res_ids.length > 0 &&
                showModalData.res_ids.map((el, index) => (
                  <PaymentDueModel
                    showModalData={el}
                    show={el.status !== false ? true : false}
                    admission_type={el.admission_type}
                    adm_pk={el.adm_pk}
                    user_id={userid}
                    section={el.section}
                    index={index}
                    key={index} // Don't forget to add a unique key for each item in the array
                  />
                ))}
              {user_type === 2 &&
                showModalData.res_idss &&
                showModalData.res_idss.length > 0 &&
                showModalData.res_idss.map((el, index) => {
                  <PaymentDueModel
                    showModalData={el}
                    show={el.status !== false ? true : false}
                    adm_pk={el.adm_pk}
                    admission_type={el.admission_type}
                    section={el.section}
                    user_id={userid}
                    index={index}
                  />;
                })}
              {/* {user.main_exam_details_exists === 0 &&
                user.ask_core_exam === 1 &&
                user.user_type === 2 &&
                user.batch_id !== "" && (
                  <ModelCompontent
                    show={showModal}
                    user_id={user.user_id}
                    batch_id={user.batch_id}
                    handleClose={handleCloseModal}
                  />
                )} */}
            </section>
          ) : (
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                {images.map((_, index) => (
                  <li
                    key={index}
                    data-target="#carouselExampleIndicators"
                    data-slide-to={index}
                    className={index === activeIndex ? "active" : ""}
                  ></li>
                ))}
              </ol>
              <div className="carousel-inner">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${
                      index === activeIndex ? "active" : ""
                    }`}
                  >
                    <img
                      className="d-block w-100"
                      src={image}
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default IndexPage;
