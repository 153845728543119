// import logo from './logo.svg';
import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddToCardTemplate from "./shared/Component/Card/AddToCardTemplate";
import AdmissionForm from "./pages/AdmissionForm";
import BookDetailPage from "./shared/Component/BookDetailPage/BookDetailPage";
import BookList from "./shared/Component/BookList/BookList";
// import CounsellingForm from "./pages/CounsellingForm";
import ForgetPassword from "./pages/ForgetPassword";
// import HeaderPage from "./features/Core/Header/HeaderPage";
import Newpassword from "./shared/Component/NewPassword/NewPassword";
import Register from "./shared/Component/Register/Register";
import RegisterSuccess from "./shared/Component/Registersuccess/Registersuccess";
import ResetPassword from "./pages/ResetPasswordEmail";
import Resetpassword from "./shared/Component/Resetpassword/Resetpassword";
import SampleForm from "./pages/SampleForm";
import TeamMeeting from "./pages/TeamsIntegration";
import ViewMeetingDetails from "./pages/ViewMeeting";
import Login from "./shared/Component/Login/Login";
import EmailReset from "./pages/ResetPasswordEmail";
import UserDetailsContextProvider from "./context/UserDetails";
import RequireAuth from "./auth/auth";
import Dashboard from "./pages/dashboard/Dashboard";
import IndexPage from "./pages/dashboard/IndexPage";
import ProfileSetting from "./pages/dashboard/ProfileSetting";
import Logout from "./pages/dashboard/Logout";
import AuthUser from "./auth/AuthUser";
import TestBatch from "./pages/TestBatch";
import SpecialClass from "./pages/SpecialClass";
import StudentConfirm from "./shared/Component/StudentConfirm/StudentConfirm";

import InvoiceTemplate from "./pages/InvoiceTemplate";
import MockInterview from "./shared/Component/MockInterview/MockInterview";
import Counselling from "./shared/Component/Counselling/Counselling";

// backoffice start
import Course from "./shared/Component/BackOffice/Course/Course";
import CourseEdit from "./shared/Component/BackOffice/Course/CourseEdit";
import Test from "./shared/Component/BackOffice/Test/Test";
import TestEdit from "./shared/Component/BackOffice/Test/TestEdit";
import BookCourse from "./shared/Component/BackOffice/BookCourse/BookCourse";
import BookCourseEdit from "./shared/Component/BackOffice/BookCourse/BookCourseEdit";
import Class from "./shared/Component/BackOffice/Class/Class";
import ClassEdit from "./shared/Component/BackOffice/Class/ClassEdit";
import Batch from "./shared/Component/BackOffice/BatchUpdate/BatchUpdate";
import BatchEdit from "./shared/Component/BackOffice/BatchUpdate/BatchUpdateEdit";
import Gallery from "./shared/Component/BackOffice/Gallery/Gallery";
import GalleryEdit from "./shared/Component/BackOffice/Gallery/GalleryEdit";
import TestBatchUpdateEdit from "./shared/Component/BackOffice/TestBatchUpdate/TestBatchUpdateEdit";
import TestBatchUpdate from "./shared/Component/BackOffice/TestBatchUpdate/TestBatchUpdate";
import BranchLocation from "./shared/Component/BackOffice/BranchLocation/BranchLocation";
import BranchLocationEdit from "./shared/Component/BackOffice/BranchLocation/BranchLocationEdit";
import CreateCourseBatch from "./shared/Component/BackOffice/CourseBatch/CreateCourseBatch";
import ViewCourseBatch from "./shared/Component/BackOffice/CourseBatch/CourseBatch";
import ViewTestCourseBatch from "./shared/Component/BackOffice/TestCourseBatch/TestCourseBatch";
import CreateTestCourseBatch from "./shared/Component/BackOffice/TestCourseBatch/CreateTestCourseBatch";
import Degree from "./shared/Component/BackOffice/Degree/Degree";
import DegreeEdit from "./shared/Component/BackOffice/Degree/DegreeEdit";
import Listview from "./shared/Component/StudentConfirm/Listview";
import ModelExamForm from "./shared/Component/ModelExam/ModelExamForm";
import ListviewBatch from "./shared/Component/BackOffice/CourseBatch/ListviewBatch";
import ReadViewBath from "./shared/Component/BackOffice/CourseBatch/ReadViewBath";
import CreateEvent from "./shared/Component/BackOffice/Event/CreateEvent";
import Calendar from "./shared/Component/BackOffice/CourseBatch/CalendarView";
import CalendarView from "./shared/Component/BackOffice/CourseBatch/CalendarView";
import MyCalendar from "./shared/Component/BackOffice/CourseBatch/CalendarView";
import NewFaculty from "./shared/Component/BackOffice/Faculty/NewFaculty";
import ReadFaculty from "./shared/Component/BackOffice/Faculty/ReadFaculty";
import ReviewFaculty from "./shared/Component/BackOffice/Faculty/ReviewFaculty";
import ListViewFaculty from "./shared/Component/BackOffice/Faculty/ListViewFaculty";
import FacultyProfile from "./shared/Component/BackOffice/Faculty/FacultyProfile";
import PurchaseListview from "./shared/Component/BackOffice/Purchase/PurchaseListview";
import ReadViewAdmissionHistory from "./shared/Component/BackOffice/Purchase/ReadViewAdmissionHistory";
import PaymentHistory from "./shared/Component/BackOffice/Purchase/PaymentHistory";
import ViewSchedule from "./shared/Component/BackOffice/Purchase/ViewSchdule";
import ViewCalendar from "./shared/Component/BackOffice/Purchase/ViewCalendar";
import DetailCalendar from "./shared/Component/BackOffice/Purchase/ViewCalendar";
import AddPanelMember from "./shared/Component/BackOffice/Faculty/AddPanelMember";
import ReadViewPMem from "./shared/Component/BackOffice/Faculty/ReadViewPMem";
import FacultyDetailview from "./shared/Component/BackOffice/Faculty/FacultyDetailview";
import AddSalary from "./shared/Component/BackOffice/Faculty/AddSalary";
import NonFacuityDetailProfile from "./shared/Component/BackOffice/Faculty/NonFacuityDetailProfile";
import NonFDetailview from "./shared/Component/BackOffice/Faculty/NonFDetailview";
import ReadViewSalary from "./shared/Component/BackOffice/Faculty/ReadViewSalary";
import DSchedule from "./shared/Component/BackOffice/CourseBatch/DSchedule";
import StaffDetailCalendar from "./shared/Component/BackOffice/Faculty/StaffDetailCalendar";
import FaculityView from "./shared/Component/BackOffice/Faculty/normalFaculity/FaculityView";
import PanelMemberView from "./shared/Component/BackOffice/Faculty/PanelMemberView";
import NonFacultyProfile from "./shared/Component/BackOffice/Faculty/NonFacultyProfile";
import ReviewTProgress from "./shared/Component/BackOffice/Faculty/ReviewTProgress";
import QtypeOne from "./shared/Component/BackOffice/question/QtypeOne";
import QtypeTwo from "./shared/Component/BackOffice/question/QtypeTwo";
import Quiz from "./shared/Component/BackOffice/question/Quiz";
import TestQuestion from "./shared/Component/BackOffice/question/TestQuestion";
import QuestionList from "./shared/Component/BackOffice/question/QuestionList";
import TestListview from "./shared/Component/BackOffice/TestAdminWorkflow/TestListview";
import DetailTestView from "./shared/Component/BackOffice/TestAdminWorkflow/DetailTestView";
import UploadQuestionFile from "./shared/Component/BackOffice/TestAdminWorkflow/UploadQuestionFile";
import TestPerformance from "./shared/Component/BackOffice/StudentTestWorkflow/TestPerformance";
import ViewTestDetail from "./shared/Component/BackOffice/StudentTestWorkflow/ViewTestDetail";
import TestViewSchedule from "./shared/Component/BackOffice/StudentTestWorkflow/TestViewSchedule";
import ViewEventDetails from "./shared/Component/BackOffice/StudentTestWorkflow/ViewEventDetails";
import ViewQuestionPaper from "./shared/Component/BackOffice/TestAdminWorkflow/ViewQuestionPaper";
import JoinTestScreen from "./shared/Component/BackOffice/StudentTestWorkflow/JoinTestScreen";
import ReviewSub from "./shared/Component/BackOffice/StudentTestWorkflow/ReviewSub";
import Testlistdata from "./shared/Component/BackOffice/TestAdminWorkflow/Testlistdata";
import EmailVerfiy from "./shared/Component/OtpScreen/EmailVerfiy";
import PhoneNumberVerify from "./shared/Component/OtpScreen/PhoneNumberVerify";
import UserList from "./shared/Component/BackOffice/RegsisteredUser/UserList";
import ReviewCouresAdmission from "./pages/ReviewCouresAdmission";
import ReviewTestAdmission from "./pages/ReviewTestAdmission";
import ReviewSpecialAdmission from "./pages/ReviewSpecialAdmission";
import ReviewMockInterviewAdm from "./shared/Component/MockInterview/ReviewMockInterviewAdm";
import ReviewCounselling from "./shared/Component/Counselling/ReviewCounselling";
import ReviewModelexam from "./shared/Component/ModelExam/ReviewModelexam";
import ReviewUserDetail from "./shared/Component/BackOffice/RegsisteredUser/ReviewUserDetail";
import TestPerformanceTablist from "./shared/Component/BackOffice/StudentTestWorkflow/TestPerformanceTablist";
import { useEffect } from "react";
import { RememberMe } from "@mui/icons-material";
import PayFee from "./pages/PayFee";
import PaymentDueModel from "./pages/PaymentDueModel";
import CashListView from "./shared/Component/BackOffice/Cashpayment/CashListView";
import CashPayFee from "./shared/Component/BackOffice/Cashpayment/CashPayFee";
// import ViewStudentQuery from "./shared/Component/BackOffice/Faculty/StudentsQuery/ViewStudentQuery";

// import StudentsQuery from "./shared/Component/BackOffice/Faculty/StudentsQuery/StudentsQuery";

// import PaymentSuccess from "./shared/Component/BackOffice/StudentTestWorkflow/PaymentSuccess";

// TEST MODULE-USER
// import ViewTestDetail from "./shared/Component/BackOffice/StudentTestWorkflow/pages/ViewTestDetail";
import JoinTest from "./shared/Component/BackOffice/StudentTestWorkflow/pages/JoinTest";
// import ViewTestPerfomance from "./shared/Component/BackOffice/StudentTestWorkflow/pages/ViewTestPerfomance";
// ADDING GLOBAL THEMES FOR STYLED-COMPONENTS
import GlobalStyles from "./styles/GlobalStyles";

import DownloadHallTicket from "./shared/Component/ModelExam/DownloadHallTicket";

//backoffice end

function App() {
  // RememberMe password

  // useEffect(() => {

  //   const handleClose = () => {

  //     const is_rem = localStorage.getItem('Is_remember');
  //     if (is_rem === "false") {
  //       // Your cleanup logic here
  //       localStorage.removeItem("userDetails");
  //       localStorage.removeItem("user_id");
  //       localStorage.removeItem('role_id');
  //       localStorage.removeItem('Is_remember');
  //       localStorage.removeItem('rzp_device_id');
  //       localStorage.removeItem('rzp_checkout_anon_id');
  //       sessionStorage.removeItem('stateRefreshs', true);
  //       sessionStorage.removeItem('stateRefreshs_test', true);
  //       sessionStorage.removeItem('stateRefreshs_spl', true);
  //       sessionStorage.removeItem('stateRefreshs_model', true);
  //       sessionStorage.removeItem('stateRefreshs_mock', true);
  //       sessionStorage.removeItem('stateRefreshs_coun', true);
  //     }
  //     console.log("Window is closing!");
  //   };
  //   window.addEventListener("beforeunload", handleClose);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleClose);

  //   };
  // }, []);

  return (
    <div className="App">
      <UserDetailsContextProvider>
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/booklist" element={<BookList />}></Route>
            <Route path="products/:id" element={<BookDetailPage />} />
            <Route path="/Teams" element={<TeamMeeting />} />
            <Route path="/view" element={<ViewMeetingDetails />} />

            {/* backoffice start */}
            {/* <Route path="/course" element={<Course />}></Route>
            <Route path="/courseedit" element={<CourseEdit />}></Route>
            <Route path="/courseedit/:id" element={<CourseEdit />}></Route> */}

            {/* <Route path="/Test" element={<Test />}></Route>
            <Route path="/Testedit" element={<TestEdit />}></Route>
            <Route path="/Testedit/:id" element={<TestEdit />}></Route> */}

            {/* <Route path="/bookcourse" element={<BookCourse />}></Route>
            <Route path="/bookcourseedit" element={<BookCourseEdit />}></Route> */}
            {/* <Route
              path="/bookcourseedit/:id"
              element={<BookCourseEdit />}
            ></Route> */}
            {/* <Route path="/class" element={<Class/>}></Route> */}
            {/* <Route path="/classedit" element={<ClassEdit/>}></Route> */}
            {/* <Route path="/classedit/:id" element={<ClassEdit/>}></Route> */}

            {/* <Route path="/batch" element={<Batch/>}></Route>
        <Route path="/batchedit" element={<BatchEdit/>}></Route> */}

            <Route path="/gallery" element={<Gallery />}></Route>
            <Route path="/galleryedit" element={<GalleryEdit />}></Route>
            {/* <Route path="/galleryedit/:id" element={<GalleryEdit/>}></Route> */}
            <Route path="/testbatch" element={<TestBatchUpdate />}></Route>
            <Route
              path="/testbatchedit"
              element={<TestBatchUpdateEdit />}
            ></Route>
            <Route path="/branch" element={<BranchLocation />}></Route>
            <Route path="/branchedit" element={<BranchLocationEdit />}></Route>
            <Route
              path="/branchedit/:id"
              element={<BranchLocationEdit />}
            ></Route>
            {/* <Route path="/ViewCourseBatch" element={<ViewCourseBatch/>}></Route>
        <Route path="/createCourseBatch" element={<CreateCourseBatch/>}></Route>
        <Route path="/createCourseBatch/:id" element={<CreateCourseBatch/>}></Route> */}
            <Route
              path="/ViewTestcoursebatch"
              element={<ViewTestCourseBatch />}
            ></Route>
            <Route
              path="/createTestcoursebatch"
              element={<CreateTestCourseBatch />}
            ></Route>
            <Route
              path="/createTestcoursebatch/:id"
              element={<CreateTestCourseBatch />}
            ></Route>
            <Route path="/ViewDegree" element={<Degree />}></Route>
            <Route path="/createDegree" element={<DegreeEdit />}></Route>
            <Route path="/createDegree/:id" element={<DegreeEdit />}></Route>
            {/* backoffice end */}
          </Routes>
          <Routes>
            <Route path="/add_to_cart" element={<AddToCardTemplate />} />
            {/* <Route path="/InterviewLogin" element={<InterviewLogin />} />
            <Route path="/CounselingLogin" element={<CounsellingForm />} />
            <Route path="/ModelExamLogin" element={<ModelExamForm />} /> */}

            {/* <Route
              path="/StudentRegistration"
              element={<StudentRegistration />}
            /> */}
            {/* <Route path="/StaffRegistration" element={<StaffRegisternew />} /> */}

            {/* <Route path="/" element={<SampleForm />}></Route> */}

            <Route path="/forgetPassword" element={<ForgetPassword />} />
            <Route path="/pwdmail/:email" element={<ResetPassword />} />
            <Route path="/newpassword/:id" element={<Newpassword />}></Route>
            <Route path="/resetpwd" element={<Resetpassword />}></Route>
            <Route
              path="/registersuccess"
              element={<RegisterSuccess />}
            ></Route>
            <Route path="/viewformapp" element={<Register />}></Route>
            <Route path="/register" element={<Login />} />
            <Route path="/resetemail" element={<EmailReset />} />

            <Route
              path="/login"
              element={
                <AuthUser>
                  <SampleForm />
                </AuthUser>
              }
            ></Route>

            <Route
              path="/RegisterUser/EmailVerify"
              element={<EmailVerfiy />}
            ></Route>
            <Route
              path="/RegisterUser/PhoneNumberVerify"
              element={<PhoneNumberVerify />}
            ></Route>

            {/* <Route path="/head" element={<HeaderPage />} /> */}

            {/* <Route path="/pay" element={<Razarpay />} /> */}
            <Route path="/" element={<Dashboard />}>
              <Route index element={<IndexPage />} />
              <Route
                path="Admission/Course"
                element={
                  <RequireAuth>
                    <AdmissionForm />
                  </RequireAuth>
                }
              />
              {/* ------------------- New Start ----------------------------- */}
              {/* New Added - Students Query */}
              {/* <Route
                path="StudentsQuery"
                element={
                  <RequireAuth>
                    <StudentsQuery />
                  </RequireAuth>
                }
              />

          
              <Route
                path="PaymentSuccess"
                element={
                  <RequireAuth>
                    <PaymentSuccess />
                  </RequireAuth>
                }
              /> */}
              {/*New POC Added- DummyComponent */}
              {/* <Route
                path="DummyComponent"
                element={
                  <RequireAuth>
                    <DummyComponent />
                  </RequireAuth>
                }
              /> */}
              {/*New POC Added- ClickHereDummy */}
              {/* <Route
                path="ClickHereDummy"
                element={
                  <RequireAuth>
                    <ClickHereDummy />
                  </RequireAuth>
                }
              /> */}

              {/*New POC Added- TestDummy */}
              {/* <Route
                path="TestDummy"
                element={
                  <RequireAuth>
                    <TestDummy />
                  </RequireAuth>
                }
              /> */}
              {/* ------------------- New End ----------------------------- */}

              <Route
                path="Admission/TestBatch"
                element={
                  <RequireAuth>
                    <TestBatch />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/SpecialClasses"
                element={
                  <RequireAuth>
                    <SpecialClass />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/Modelexam"
                element={
                  <RequireAuth>
                    {" "}
                    <ModelExamForm />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/Mockinterview"
                element={
                  <RequireAuth>
                    <MockInterview />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/Counselling"
                element={
                  <RequireAuth>
                    <Counselling />
                  </RequireAuth>
                }
              />

              <Route
                path="Admission/Course/:edit/:user_id/:admission_id"
                element={
                  <RequireAuth>
                    <ReviewCouresAdmission />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/TestBatch/:edit/:user_id/:admission_id"
                element={
                  <RequireAuth>
                    <ReviewTestAdmission />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/SpecialClasses/:edit/:user_id/:admission_id"
                element={
                  <RequireAuth>
                    <ReviewSpecialAdmission />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/Modelexam/:edit/:user_id/:admission_id"
                element={
                  <RequireAuth>
                    {" "}
                    <ReviewModelexam />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/Mockinterview/:edit/:user_id/:admission_id"
                element={
                  <RequireAuth>
                    <ReviewMockInterviewAdm />
                  </RequireAuth>
                }
              />

              <Route
                path="Admission/Counselling/:edit/:user_id/:admission_id"
                element={
                  <RequireAuth>
                    <ReviewCounselling />
                  </RequireAuth>
                }
              />

              <Route
                path="ProfileSetting"
                element={
                  <RequireAuth>
                    {" "}
                    <ProfileSetting />
                  </RequireAuth>
                }
              />
              <Route
                path="Invoice"
                element={
                  <RequireAuth>
                    {" "}
                    <InvoiceTemplate />
                  </RequireAuth>
                }
              />
              <Route
                path="classedit"
                element={
                  <RequireAuth>
                    {" "}
                    <ClassEdit />
                  </RequireAuth>
                }
              />
              <Route
                path="class"
                element={
                  <RequireAuth>
                    {" "}
                    <Class />
                  </RequireAuth>
                }
              />
              <Route
                path="classedit/:id"
                element={
                  <RequireAuth>
                    {" "}
                    <ClassEdit />
                  </RequireAuth>
                }
              />
              <Route
                path="ViewCourseBatch"
                element={
                  <RequireAuth>
                    {" "}
                    <ListviewBatch />{" "}
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="ViewCourseBatch/:active_tab"
                element={
                  <RequireAuth>
                    {" "}
                    <ListviewBatch />{" "}
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="DetailViewBatch/:id/:type"
                element={
                  <RequireAuth>
                    {" "}
                    <ReadViewBath />{" "}
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="createCourseBatch/:Type"
                element={
                  <RequireAuth>
                    {" "}
                    <CreateCourseBatch />{" "}
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="createCourseBatch/:Type/:id"
                element={
                  <RequireAuth>
                    {" "}
                    <CreateCourseBatch />{" "}
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/batch"
                element={
                  <RequireAuth>
                    <Batch />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/batchedit"
                element={
                  <RequireAuth>
                    {" "}
                    <BatchEdit />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/ListViewStudentConfirm"
                element={
                  <RequireAuth>
                    <Listview />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/ListViewStudentConfirm/:typeFilter"
                element={
                  <RequireAuth>
                    <Listview />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/studentConfirm/:userId/:admissId/:appliedOn/:type"
                element={
                  <RequireAuth>
                    <StudentConfirm />{" "}
                  </RequireAuth>
                }
              />

              <Route
                // path="/CreateEvent/:sDate/:batch_id/:category/:startTime/:endTime"
                path="/CreateEvent"
                element={
                  <RequireAuth>
                    <CreateEvent />{" "}
                  </RequireAuth>
                }
              />

              <Route
                // path="/CreateEvent/:sDate/:batch_id/:category/:startTime/:endTime"
                path="/Question"
                element={
                  <RequireAuth>
                    <QtypeOne />{" "}
                  </RequireAuth>
                }
              />

              <Route
                // path="/CreateEvent/:sDate/:batch_id/:category/:startTime/:endTime"
                path="/QuestionType"
                element={
                  <RequireAuth>
                    <QtypeTwo />{" "}
                  </RequireAuth>
                }
              />
              {/* <Route
                path="/CreateEvent"
                element={
                  <RequireAuth>
                    <CreateEvent />{" "}
                  </RequireAuth>
                }
              /> */}

              <Route
                path="/Faculties/addfaculty/:facId"
                element={
                  <RequireAuth>
                    <NewFaculty />{" "}
                  </RequireAuth>
                }
              />
              {/*               
                <Route
                path="/Faculties/addfaculty/:Faculty"
                element={
                  <RequireAuth>
                    <NewFaculty />{" "}
                  </RequireAuth>
                }
              />   */}
              <Route
                path="/Faculties/addfaculty/:facId/:userId"
                element={
                  <RequireAuth>
                    <NewFaculty />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/readfaculty/:userId"
                element={
                  <RequireAuth>
                    <ReadFaculty />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/ReviewFacultyProfile/:userId"
                element={
                  <RequireAuth>
                    <ReviewFaculty />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/Faculties"
                element={
                  <RequireAuth>
                    <ListViewFaculty />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/:active_tab"
                element={
                  <RequireAuth>
                    <ListViewFaculty />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/AddPanelMember"
                element={
                  <RequireAuth>
                    <AddPanelMember />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/AddPanelMember/:userId"
                element={
                  <RequireAuth>
                    <AddPanelMember />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/Faculties/ReadViewPanelMember/:userId"
                element={
                  <RequireAuth>
                    <ReadViewPMem />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/FacultyDetailView/:userId/:staff_id"
                element={
                  <RequireAuth>
                    <FacultyDetailview />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/FacultyDetailView/:userId/:staff_id/:active_tab"
                element={
                  <RequireAuth>
                    <FacultyDetailview />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/NonTeachingDetailView/:userId/:staff_id"
                element={
                  <RequireAuth>
                    <NonFDetailview />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/Faculties/NonTeachingDetailView/:userId/:staff_id/:active_tab"
                element={
                  <RequireAuth>
                    <NonFDetailview />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/AddSalary/:userId/:staff_id/:staff_type/:amount"
                element={
                  <RequireAuth>
                    <AddSalary />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/AddSalary/:userId/:staff_id/:staff_type/:amount/:id"
                element={
                  <RequireAuth>
                    <AddSalary />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/ReadViewSalary/:userId/:id/:staff_id/:staff_type"
                element={
                  <RequireAuth>
                    <ReadViewSalary />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/AddSalary/:staff_id/:userId"
                element={
                  <RequireAuth>
                    <AddSalary />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/Faculties/PanelMember/:userId/:staff_id"
                element={
                  <RequireAuth>
                    <PanelMemberView />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/PanelMember/:userId/:staff_id/:active_tab"
                element={
                  <RequireAuth>
                    <PanelMemberView />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/facultyProfile"
                element={
                  <RequireAuth>
                    <FacultyProfile />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/facultyProfile/:userId"
                element={
                  <RequireAuth>
                    <FacultyProfile />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/NonFacultyProfile"
                element={
                  <RequireAuth>
                    <NonFacultyProfile />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/NonFacultyProfile/:userId"
                element={
                  <RequireAuth>
                    <NonFacultyProfile />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TransactionHistory"
                element={
                  <RequireAuth>
                    <PurchaseListview />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TransactionHistory/:active_tab"
                element={
                  <RequireAuth>
                    <PurchaseListview />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TransactionHistory/ViewAdmission/:user_id/:batch_id/:add_id/:type"
                element={
                  <RequireAuth>
                    <ReadViewAdmissionHistory />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TransactionHistory/ViewAdmission/ViewPaymentHistory/:type/:adm_id"
                element={
                  <RequireAuth>
                    <PaymentHistory />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TransactionHistory/ViewAdmission/ViewSchedule/:batch_id/:type"
                element={
                  <RequireAuth>
                    <ViewSchedule />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/TestPerformanceListView"
                element={
                  <RequireAuth>
                    <TestPerformanceTablist />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TestPerformanceListView/:active_tab/:batchIds"
                element={
                  <RequireAuth>
                    <TestPerformanceTablist />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/DetailCalendar"
                element={
                  <RequireAuth>
                    <DetailCalendar />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ViewEventDetails"
                element={
                  <RequireAuth>
                    <ViewEventDetails />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/AdminDetailCalendar"
                element={
                  <RequireAuth>
                    <DSchedule />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ViewQuestionPaper"
                element={
                  <RequireAuth>
                    <ViewQuestionPaper />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/JoinTest"
                element={
                  <RequireAuth>
                    <JoinTestScreen />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/StaffDetailCalendar"
                element={
                  <RequireAuth>
                    <StaffDetailCalendar />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ProfileFacultyView"
                element={
                  <RequireAuth>
                    <FaculityView />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ProfileFacultyView/:active_tab"
                element={
                  <RequireAuth>
                    <FaculityView />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ReviewTeachingProgress"
                element={
                  <RequireAuth>
                    <ReviewTProgress />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ViewSchedule/joinTest"
                element={
                  <RequireAuth>
                    <JoinTest />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/TestQuestion"
                element={
                  <RequireAuth>
                    <TestQuestion />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TestPermeance"
                element={
                  <RequireAuth>
                    <QuestionList />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/TestList"
                element={
                  <RequireAuth>
                    <TestListview />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ReviewSubmission/:batch_id/:event_id"
                element={
                  <RequireAuth>
                    <ReviewSub />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TestPerformance/:batch_id"
                element={
                  <RequireAuth>
                    <TestPerformance />{" "}
                  </RequireAuth>
                }
              />
              {/* testperfomance */}
              {/* <Route
                path="/viewTestPerformance"
                element={
                  <RequireAuth>
                    <ViewTestPerfomance />{" "}
                  </RequireAuth>
                }
              /> */}
              <Route
                path="/ViewTestDetail/:batch_id/:event_id/:status/:event_type"
                element={
                  <RequireAuth>
                    <ViewTestDetail />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/TestViewSchedule/:batch_id/:batch_type"
                element={
                  <RequireAuth>
                    <TestViewSchedule />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/RegisterUser/ViewUser/:user_id"
                element={
                  <RequireAuth>
                    <ReviewUserDetail />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/UploadQuestionFile"
                element={
                  <RequireAuth>
                    <UploadQuestionFile />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/RegisteredUser"
                element={
                  <RequireAuth>
                    <UserList />{" "}
                  </RequireAuth>
                }
              />

              {/* <Route
                path="/ExpandTable"
                element={
                  <CollapsibleTable />
                }
              /> */}

              <Route path="/PaymentDue" element={<PaymentDueModel />} />

              <Route
                path="/PayFee"
                element={
                  <RequireAuth>
                    <PayFee />
                  </RequireAuth>
                }
              />

              <Route
                path="/CashPayment"
                element={
                  <RequireAuth>
                    <CashListView />
                  </RequireAuth>
                }
              />

              <Route
                path="/CashPayment/:active_tab"
                element={
                  <RequireAuth>
                    <CashListView />
                  </RequireAuth>
                }
              />

              <Route
                path="/CashPayment/payFee"
                element={
                  <RequireAuth>
                    <CashPayFee />
                  </RequireAuth>
                }
              />

              <Route
                path="/DetailTestView/:id/:status"
                element={
                  <RequireAuth>
                    <DetailTestView />{" "}
                  </RequireAuth>
                }
              />

              {/* <Route
                path="/Query"
                element={
                  <RequireAuth>
                    <ViewStudentQuery />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/StudentsQuery"
                element={
                  <RequireAuth>
                    <StudentsQuery />
                  </RequireAuth>
                }
              /> */}

            
              <Route
                path="DownloadHallTicket"
                element={
                  <RequireAuth>
                    <DownloadHallTicket />
                  </RequireAuth>
                }
              />
            </Route>

            {/* <Route path="*" element={<PageNotFound />} /> */}
          </Routes>
        </BrowserRouter>
      </UserDetailsContextProvider>
    </div>
  );
}

export default App;
