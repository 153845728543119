import React, { useEffect, useState } from "react";
import { json, Link, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";

export default function Class() {
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState([]);

  //edit function

  const handleEdit = (id) => {
    navigate(`/classedit/${id}`);
  };

  //delete function
  const handleDelete = (id) => {};

  //list of course
  useEffect(() => {
    AxiosInstance.post(`api/course/list`)
      .then((res) => {
        if (res.status === 200) {
          const couresDetails = res.data.data;
          setCourseData(couresDetails);
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);

  return (
    <div className=" Classes mt-5">
      <div className="row">
        <div className="col-md-3">
          <Link to="/classedit" className="SaveButton">
            Create Category Classes
          </Link>
        </div>
      </div>

      <div className="row mt-5 mb-5">
        <div className="col-md-12 text-center">
          <h1 className="Title text-center">Category Classes List</h1>
          {courseData.length > 0 ? (
            <table class="table table-bordered">
              <thead>
                <tr>
                 <th>Course Id</th>
                  <th>Course Category</th>
                  <th>Sub Category</th>
                  <th>Category Classes</th>
                  <th>Branch</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {courseData.map((course, index) => (
                  <tr key={index} title={course.id}>
                       <td>{course.id}</td>
                    <td>{course.category_code}</td>
                    <td>
                      {(course.subcategory_code !==null && course.subcategory_code !="" )
                        ? course.subcategory_code
                        : "None"}
                    </td>
                    <td>{course.course_name}</td>
                    <td>
                      {course.branch_id !==null ? course.branch_id : "None"}
                    </td>
                    <td>
                      <button
                        className="button me-2"
                        onClick={() => handleEdit(course.id)}
                      >
                        <span class="material-icons">edit</span>
                      </button>
                      <button
                        className="button"
                        onClick={() => handleDelete(course.id)}
                      >
                        <span class="material-icons">delete</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div class="alert alert-danger" role="alert">
              No class data found.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
