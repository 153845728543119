import moment from "moment";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import RtypeOne from "./RtypeOne";
import RtypeTwo from "./RtypeTwo";
import RtypeThree from "./RtypeThree";
import RtypeFour from "./RtypeFour";
import RtypeFive from "./RtypeFive";
import RtypeSix from "./RtypeSix";
import RtypeSeven from "./RtypeSeven";
import RtypeEight from "./RtypeEight";
import RtypeNine from "./RtypeNine";
import RtypeTen from "./RtypeTen";
import RtypeParaOption from "./RtypeParaOption";
import Rtypepara from "./Rtypepara";
import ReviewAnswerAll from "./ReviewAnswerAll";

export default function ReviewSub() {
  const firstColumnRef = useRef(null);
  const vvvvRef = useRef(null);
  
  const [firstColumnHeight, setFirstColumnHeight] = useState(0);
  const [step, setStep] = useState(1);
  const [totalStep, setTotalStep] = useState();
  let step_size = 10;
  const handleNext = () => {
    if (step < totalStep) {
      setStep(step + 1);
    } else {
      setStep(1); // Reset to the first step when reaching the end
    }
  };

  // update same question set height to answer column height 

  useEffect(() => {
    const updateColumnHeight = () => {
      if (firstColumnRef.current && vvvvRef.current) {
        const newHeight = firstColumnRef.current.clientHeight;
        setFirstColumnHeight(newHeight);
        vvvvRef.current.style.height = `${newHeight}px`;
      }
    };

    // Initial height update
    updateColumnHeight();

    // Listen for changes in the first column content
    // You need to adapt this to your actual content update logic
    const observer = new MutationObserver(updateColumnHeight);
    observer.observe(firstColumnRef.current, { childList: true, subtree: true });

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      setStep(totalStep); // Go back to the last step when at the first step
    }
  };
  const navigate = useNavigate();
  const location = useLocation();

  const { batch_id, event_id } = useParams();
  const [formData, setFormData] = useState([
    {
      pk: "",
      user_answer: "",
      lang: "",
    },
  ]);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [step]);
  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  const [listData, setListData] = useState([]);

  const [quesSet, setQuesSet] = useState([]);

  useEffect(() => {
    AxiosInstance.post("api/test/lang/byUser", {
      user_id: userid,
      batch_id: parseInt(batch_id),
      event_id: parseInt(event_id),
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const lang = res.data.data.test_lang;
          AxiosInstance.post("api/test/review/answer", {
            user_id: userid,
            batch_id: parseInt(batch_id),
            event_id: parseInt(event_id),
            language: lang,
          })
            .then((res) => {
              // console.log(res);
              if (res.data.status === true) {
                // console.log(res.data.data,"Sfsdf");
                let ut = res.data.data;
                let elementToAdd = null;
                ut.forEach((el, index) => {
                  if (el.display_type === 10 && el.para_que === 1) {
                    elementToAdd = el;
                  }
                });

                // Add the elementToAdd to all elements with display_type === 10 and para_que === 2
                ut.forEach((el, index) => {
                  if (el.display_type === 10 && el.para_que === 2) {
                    el.easy = elementToAdd;
                  }
                });

                const bb = ut.filter((el) => el.para_que !== 1);
                setListData(bb);
                let data_length = res.data.data.length;
                const number_of_steps = Math.ceil(data_length / step_size);
                // console.log(number_of_steps);
                setTotalStep(number_of_steps);
                const bhh = ut.filter((el) => el.para_que !== 1);
                const updatedData = bhh.map((item) => ({
                  pk: item.pk,
                  is_correct:
                    item.answer_status === 0 || item.answer_status === null
                      ? false
                      : true,
                }));
                // const quesPl = ut.filter((el)=>el.display_type === 10 && el.para_que === 1).map((item) => ({
                //   pk: item.pk,
                //   is_correct : item.answer_status === 0 ?  false : true,
                // }));
                // // Use the spread operator (...) to concatenate the elements of quesPl into updatedData
                // updatedData.push(...quesPl);
                setQuesSet(updatedData);
              }
            })
            .catch((error) => {
              return error;
            });
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);
  //question set click means need to update stepper

  const handleStepClick = (stepnum) => {
    setStep(stepnum);
  };

  // console.log(listData,"listdata" , listData.length,totalStep);

  return (
    <div className=" BathDet">
      <div className="row ">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading  mt-3 d-flex justify-content-end ">
              <h6 className="Tit_withddd">Review Submission</h6>
              <Link
                to="javascript:void(0)"
                onClick={navigateToPreviousScreen}
                className="CancelButton"
              >
                Back to View Test
              </Link>
            </div>
          </section>
        </div>
        <div className="col-md-12">
          <div className="row  ">
            <div className="col-md-4 col-sm-12 col-xs-12  ">
              <section className="StepTwo" ref={firstColumnRef} >
                <div className="row box" >
                  <div className="col-md-12">
                    <p className="StepTitle">Question Set</p>
                    <div className="row mt-4">
                      {quesSet.length > 0 &&
                        quesSet.map((el, index) => {
                          const effectiveStep = Math.ceil((index + 1) / 10);
                          if (el.is_correct === true) {
                            return (
                              <div
                                onClick={() => handleStepClick(effectiveStep)}
                                className={`col-md-2 questionSet_wrong `}
                              >
                                <span
                                  //  onClick={()=>handleStepClick(effectiveStep)}
                                  className="mark_number"
                                >
                                  {index + 1}
                                </span>
                                <span
                                  // onClick={()=>handleStepClick(effectiveStep)}
                                  className="correct_mark"
                                >
                                  ✓
                                </span>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                onClick={() => handleStepClick(effectiveStep)}
                                className={`col-md-2 questionSet_Review `}
                              >
                                {" "}
                                <span
                                  // onClick={()=>handleStepClick(effectiveStep)}
                                  className="mark_number"
                                >
                                  {index + 1}
                                </span>
                                <span
                                  // onClick={()=>handleStepClick(effectiveStep)}
                                  className="correct_mark"
                                >
                                  X
                                </span>
                              </div>
                            );
                          }
                        })}
                      {/* {quesSet.length > 0 &&
                    quesSet.map((el , index) => {
                      if (el.is_correct === true) {
                        return (
                          <div className={`col-md-2 questionSet_wrong `}
                          >
                            <span className="mark_number">{index + 1}</span>
                            <span className="correct_mark">✓</span>
                          </div>
                        )
                       } else {
                        return (
                          <div className={`col-md-2 questionSet `}
                          >
                             <span className="mark_number">{index + 1}</span>
                            <span className="correct_mark">X</span>
                          </div>
                        )
                      }
                    })} */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-md-8 col-sm-12 col-xs-12 rightside_scroll" ref={vvvvRef}>
              <section className="StepOne">
                {listData.length > 0 &&
                  listData.map((el, index) => {
                    // Calculate the effective step based on the loop count
                    if (el.display_type === 0) {
                      return (
                        <div className="row ">
                          <div className="col-md-9 box_review ">
                            <p className="StepTitle">
                              {(index + 1).toString().padStart(2, "0")}
                            </p>
                            <div className="row">
                              <RtypeOne type={0} index={index} list={el} />
                            </div>
                          </div>
                          <div className="col-md-3 box_review box_review_set ">
                            {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                            <ReviewAnswerAll type={0} index={index} list={el} />
                          </div>
                        </div>
                        // <div className="row box">
                        //   <div className="col-md-12">
                        //     <p className="StepTitle">
                        //       {(index + 1).toString().padStart(2, "0")}
                        //     </p>
                        //     <div className="row">
                        //       <RtypeOne type={0} index={index} list={el} />
                        //     </div>
                        //   </div>
                        // </div>
                      );
                    } else if (el.display_type === 1) {
                      return (
                        <>
                          <div className="row ">
                            <div className="col-md-9 box_review ">
                              <p className="StepTitle">
                                {(index + 1).toString().padStart(2, "0")}
                              </p>

                              <div className="row">
                                <RtypeTwo type={0} index={index} list={el} />
                              </div>
                            </div>
                            <div className="col-md-3 box_review box_review_set ">
                              {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                              <ReviewAnswerAll
                                type={0}
                                index={index}
                                list={el}
                              />
                            </div>
                          </div>
                        </>
                        // <div className="row box">
                        //   <div className="col-md-12">
                        //     <p className="StepTitle">
                        //       {(index + 1).toString().padStart(2, "0")}
                        //     </p>

                        //     <div className="row">
                        //       <RtypeTwo type={0} index={index} list={el} />
                        //     </div>
                        //   </div>
                        // </div>
                      );
                    } else if (el.display_type === 2) {
                      return (
                        <>
                          <div className="row ">
                            <div className="col-md-9 box_review ">
                              <p className="StepTitle">
                                {(index + 1).toString().padStart(2, "0")}
                              </p>
                              <div className="row">
                                <RtypeThree type={0} index={index} list={el} />
                              </div>
                            </div>
                            <div className="col-md-3 box_review box_review_set ">
                              {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                              <ReviewAnswerAll
                                type={0}
                                index={index}
                                list={el}
                              />
                            </div>
                          </div>
                        </>
                        // <div className="row box">
                        //   <div className="col-md-12">
                        //     <p className="StepTitle">
                        //       {(index + 1).toString().padStart(2, "0")}
                        //     </p>
                        //     <div className="row">
                        //       <RtypeThree type={0} index={index} list={el} />
                        //     </div>
                        //   </div>
                        // </div>
                      );
                    } else if (el.display_type === 3) {
                      return (
                        <>
                          <div className="row ">
                            <div className="col-md-9 box_review ">
                              <p className="StepTitle">
                                {(index + 1).toString().padStart(2, "0")}
                              </p>

                              <div className="row">
                                <RtypeFour type={0} index={index} list={el} />
                              </div>
                            </div>
                            <div className="col-md-3 box_review box_review_set ">
                              {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                              <ReviewAnswerAll
                                type={0}
                                index={index}
                                list={el}
                              />
                            </div>
                          </div>
                        </>
                        // <div className="row box">
                        //   <div className="col-md-12">
                        //     <p className="StepTitle">
                        //       {(index + 1).toString().padStart(2, "0")}
                        //     </p>

                        //     <div className="row">
                        //       <RtypeFour type={0} index={index} list={el} />
                        //     </div>
                        //   </div>
                        // </div>
                      );
                    } else if (el.display_type === 4) {
                      return (
                        <>
                          <div className="row ">
                            <div className="col-md-9 box_review ">
                              <p className="StepTitle">
                                {(index + 1).toString().padStart(2, "0")}
                              </p>

                              <div className="row">
                                <RtypeFive type={0} index={index} list={el} />
                              </div>
                            </div>
                            <div className="col-md-3 box_review box_review_set ">
                              {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                              <ReviewAnswerAll
                                type={0}
                                index={index}
                                list={el}
                              />
                            </div>
                          </div>
                        </>
                        // <div className="row box">
                        //   <div className="col-md-12">
                        //     <p className="StepTitle">
                        //       {(index + 1).toString().padStart(2, "0")}
                        //     </p>

                        //     <div className="row">
                        //       <RtypeFive type={0} index={index} list={el} />
                        //     </div>
                        //   </div>
                        // </div>
                      );
                    } else if (el.display_type === 5) {
                      return (
                        <>
                          <div className="row ">
                            <div className="col-md-9 box_review ">
                              <p className="StepTitle">
                                {(index + 1).toString().padStart(2, "0")}
                              </p>

                              <div className="row">
                                <RtypeSix type={0} index={index} list={el} />
                              </div>
                            </div>
                            <div className="col-md-3 box_review box_review_set ">
                              {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                              <ReviewAnswerAll
                                type={0}
                                index={index}
                                list={el}
                              />
                            </div>
                          </div>
                        </>
                        // <div className="row box">
                        //   <div className="col-md-12">
                        //     <p className="StepTitle">
                        //       {(index + 1).toString().padStart(2, "0")}
                        //     </p>

                        //     <div className="row">
                        //       <RtypeSix type={0} index={index} list={el} />
                        //     </div>
                        //   </div>
                        // </div>
                      );
                    } else if (el.display_type === 6) {
                      return (
                        <>
                          <div className="row ">
                            <div className="col-md-9 box_review ">
                              <p className="StepTitle">
                                {(index + 1).toString().padStart(2, "0")}
                              </p>

                              <div className="row">
                                <RtypeSeven type={0} index={index} list={el} />
                              </div>
                            </div>
                            <div className="col-md-3 box_review box_review_set ">
                              {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                              <ReviewAnswerAll
                                type={0}
                                index={index}
                                list={el}
                              />
                            </div>
                          </div>
                        </>
                        // <div className="row box">
                        //   <div className="col-md-12">
                        //     <p className="StepTitle">
                        //       {(index + 1).toString().padStart(2, "0")}
                        //     </p>

                        //     <div className="row">
                        //       <RtypeSeven type={0} index={index} list={el} />
                        //     </div>
                        //   </div>
                        // </div>
                      );
                    } else if (el.display_type === 7) {
                      return (
                        <>
                          <div className="row ">
                            <div className="col-md-9 box_review ">
                              <p className="StepTitle">
                                {(index + 1).toString().padStart(2, "0")}
                              </p>

                              <div className="row">
                                <RtypeEight type={0} index={index} list={el} />
                              </div>
                            </div>
                            <div className="col-md-3 box_review box_review_set ">
                              {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                              <ReviewAnswerAll
                                type={0}
                                index={index}
                                list={el}
                              />
                            </div>
                          </div>
                        </>
                        // <div className="row box">
                        //   <div className="col-md-12">
                        //     <p className="StepTitle">
                        //       {(index + 1).toString().padStart(2, "0")}
                        //     </p>

                        //     <div className="row">
                        //       <RtypeEight type={0} index={index} list={el} />
                        //     </div>
                        //   </div>
                        // </div>
                      );
                    } else if (el.display_type === 8) {
                      return (
                        <>
                          <div className="row ">
                            <div className="col-md-9 box_review ">
                              <p className="StepTitle">
                                {(index + 1).toString().padStart(2, "0")}
                              </p>

                              <div className="row">
                                <RtypeNine type={0} index={index} list={el} />
                              </div>
                            </div>
                            <div className="col-md-3 box_review box_review_set ">
                              {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                              <ReviewAnswerAll
                                type={0}
                                index={index}
                                list={el}
                              />
                            </div>
                          </div>
                        </>
                        // <div className="row box">
                        //   <div className="col-md-12">
                        //     <p className="StepTitle">
                        //       {(index + 1).toString().padStart(2, "0")}
                        //     </p>

                        //     <div className="row">
                        //       <RtypeNine type={0} index={index} list={el} />
                        //     </div>
                        //   </div>
                        // </div>
                      );
                    } else if (el.display_type === 9) {
                      return (
                        <>
                          <div className="row ">
                            <div className="col-md-9 box_review ">
                              <p className="StepTitle">
                                {(index + 1).toString().padStart(2, "0")}
                              </p>

                              <div className="row">
                                <RtypeTen type={0} index={index} list={el} />
                              </div>
                            </div>
                            <div className="col-md-3 box_review box_review_set ">
                              {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                              <ReviewAnswerAll
                                type={0}
                                index={index}
                                list={el}
                              />
                            </div>
                          </div>
                        </>
                        // <div className="row box">
                        //   <div className="col-md-12">
                        //     <p className="StepTitle">
                        //       {(index + 1).toString().padStart(2, "0")}
                        //     </p>

                        //     <div className="row">
                        //       <RtypeTen type={0} index={index} list={el} />
                        //     </div>
                        //   </div>
                        // </div>
                      );
                    } else if (el.display_type === 10 && el.para_que === 2) {
                      if (el.easy !==null) {
                        return (
                          <>
                            <div className="row">
                              <div className="col-md-9 box_review">
                                <div className="row">
                                  <Rtypepara
                                    type={0}
                                    index={index}
                                    list={el.easy}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row ">
                              <div className="col-md-9 box_review ">
                                <p className="StepTitle">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>
                                <RtypeParaOption
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                              <div className="col-md-3 box_review box_review_set ">
                                {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                                <ReviewAnswerAll
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                            </div>
                          </>
                        );
                      } else {
                        return (
                          <div className="row ">
                            <div className="col-md-9 box_review ">
                              <p className="StepTitle">
                                {(index + 1).toString().padStart(2, "0")}
                              </p>
                              <RtypeParaOption
                                type={0}
                                index={index}
                                list={el}
                              />
                            </div>
                            <div className="col-md-3 box_review box_review_set ">
                              {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                              <ReviewAnswerAll
                                type={0}
                                index={index}
                                list={el}
                              />
                            </div>
                          </div>
                        );
                      }
                    }
                  })}
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
