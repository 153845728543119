import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import AddToCardTemplate from '../Card/AddToCardTemplate';
import { Link } from 'react-router-dom';
import CardTemplate1 from '../Card/CardTemplate1';
import { Remove } from '@mui/icons-material';
import { Add } from '@mui/icons-material';
import './BookDetailPage.css'

function BookDetailPage() {
    const { id } = useParams(); 
    
  const [book, setBook] = useState([]);
  const [selectedId, setSelectedId] = useState([]); 
  const [similarList, setSimilarList] = useState([]);
  const [cartlists, setCartlists] = useState([]);
  const [quantities, setQuantities] =  useState(1);



  useEffect(() => {
    const storedData = localStorage.getItem('products');
    if (storedData) {
        setBook(JSON.parse(storedData));

    } 
    const localStorageValue = localStorage.getItem('cart') || '';
    if (localStorageValue) {
      setCartlists(JSON.parse(localStorageValue));

      const matchingCartItem = JSON.parse(localStorageValue).filter(item => item.id == id);
      const cartquantity = matchingCartItem.map(item => item.quantity);
      if (cartquantity.length > 0) {
        // console.log("yes quantity", cartquantity);
        setQuantities(cartquantity);
      } else {
        // console.log("no quantity");
        setQuantities(Number(1));
      }
    } 
    // else {
    //   console.log("localStorageValue is empty or undefined");
    // }
    
  }, []);

  useEffect(() => {
    // console.log(quantities, '....updated quantity value');
  }, [quantities]);

  useEffect(() => {
    const filteredData = book.filter(data => data.id === parseInt(id));
    setSelectedId(filteredData);
    const firstFiveProducts = book.slice(0, 4);
    setSimilarList(firstFiveProducts);
  }, [book, id]);


function singleproductid(data)
{
  const existingcartData = JSON.parse(localStorage.getItem('cart'));
  // const filteredItems = existingcartData.filter(item => item.id == data.id);
  const filteredItems = existingcartData ? existingcartData.filter(item => item.id == data.id) : {};
  const updatedQuantity = incprodquantity(data,false);

  if (Object.keys(filteredItems).length === 0) {
    const datas = ({ ...data, quantity: updatedQuantity });
    const updatedCart = existingcartData ? [...existingcartData, datas] : [datas];
    localStorage.setItem('cart', JSON.stringify(updatedCart));
} else {
  const updatedCartItem = { ...filteredItems[0], quantity: updatedQuantity };
  const filteredCartData = existingcartData.filter(item => item !== filteredItems[0]);
  const updatedCart = [...filteredCartData, updatedCartItem];
  localStorage.setItem('cart', JSON.stringify(updatedCart));
  }
}




function incprodquantity(data,increment = true)
{

//   const cartlist = JSON.parse(localStorage.getItem('cart')) || [];
// if (!Array.isArray(cartlist)) {
//   // handle the case where cartlist is not an array
//   console.log('cartlist is not an array');
// } else {
//   const filteredcartid = cartlist.filter(item => item.id === data.id);
//   // const cartlist = JSON.parse(localStorage.getItem('cart')) || {};
//   // const filteredcartid = cartlist.filter(item => item.id === data.id);
//   // const filteredcartid = cartlist ? cartlist.filter(item => item.id == data.id) : '';

//   const quantity = filteredcartid.reduce((acc, item) => {
//     return item;
//   }, 0);
  
  const cartlist = JSON.parse(localStorage.getItem('cart')) || {};
  const filteredcartid = cartlist.filter(item => item.id === data.id);
  const quantity = filteredcartid.reduce((acc, item) => {
    return item;
  }, 0);
  
  if(filteredcartid.length === 0)
  {
    let quantity;

    if (quantities < 5 && increment) {
      quantity =  Number(quantities)+ 1; 
      setQuantities(quantity);
    }
    return quantities;

  }
  else{
    if (quantity.id  && quantities < 5 && increment ) {
      quantity.quantity =  Number(quantities)+ 1; 
    }
    setQuantities(quantity.quantity);
    return quantities;

  }
  window.location.reload();
// }
}

function decprodquantity(data)
{
  const cartlist = JSON.parse(localStorage.getItem('cart')) || {};
  const filteredcartid = cartlist.filter(item => item.id === data.id);
  const quantity = filteredcartid.reduce((acc, item) => {
    return item;
  }, 0);
  
  if (quantity.id  && quantity.quantity > 1) {
    quantity.quantity =  Number(quantity.quantity) - 1; 

  }
  localStorage.setItem('cart', JSON.stringify(cartlist));
  window.location.reload();
  return quantity.quantity;

}
  return (
    <div>



{selectedId.map(data => (

          
  <div  key={data.id.toString()} className="">
            <section className="h-100 p-5 h-custom" >

  <h4 className='text-start text-danger'>Product Page</h4>

    <div className="row d-flex justify-content-center align-items-center h-100">
      {/* <div className="col-12"> */}
        <div className="card card-registration card-registration-2" style={{borderRadius:'15px'}}>
          <div className="card-body p-2">
            <div className="row g-3">
              <div className="col-lg-4 col-md-12"  style={{height:'30rem'}} >
                <div className="p-1">
                <div >
          <img src={data.images} style={{height: '30rem'}}
            className="card-img-top" alt="books" />

        </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12" style={{backgroundColor:'#3c3c3c21'}}>
                <div className="p-2">
                  <h4 className="fw-bold pt-1">Product details</h4>
                  <hr className="my-2"/>
                  <div className="mx-4 d-flex flex-column flex-wrap align-items-start">

                  <h5 className="mt-2 text-start ">Book name: {data.name}</h5>
                  <h5 className="text-start mb-3">Book category: {data.title}</h5>
                  <div className="d-flex flex-row align-items-start">          
                  <span className='text-gray text-decoration-line-through'>₹{data.price}</span>
                  <span className="h5 mb-0 me-5 ms-2 text-gray">₹{data.discountedPrice.toFixed(2)}</span>

              </div>
              <div className='d-flex flex-row align-items-start'>
<span className=" me-1 mb-2 text-danger col-md-12 col-lg-12">{data.discount}% off</span> 

</div>
              <div className="col-md-3 col-lg-3 col-xl-2 d-flex">
              <span className='me-2'>Quantity</span>

                    <button className="btn btn-danger px-3 me-2 quantity"  onClick={() => decprodquantity(data)}>
                   <Remove className='quantityIcon'/>
                  </button>
  <div className="form-outline mx-1">
    <input name="quantity" value={quantities} type="number" className="form-control" style={{width:'194%',fontSize:'0.9rem'}}/>
    {/* <label className="form-label" htmlFor={`form${index}`}>Quantity</label> */}
  </div>
                  <button className="btn btn-danger px-3 ms-4 quantity" onClick={() => incprodquantity(data,true)} >

                   <Add className='quantityIcon'/>
                  </button>
                    </div>
                  {/* <div className="d-flex flex-column align-items-start"> */}
                  <h6 className="text-start mt-1">Product details:</h6><br/>

                  <p className="text-muted " style={{textAlign:'justify',marginTop:'-1.5rem'}}>{data.description}</p>

                    </div>
                
                    {/* <div className="mx-4 d-flex flex-row align-items-start justify-content-start">    */}
                  <button type="button" className=" me-5 btn btn-danger btn-block  mt-0"
                    data-mdb-ripple-color="dark"  onClick={() => singleproductid(data)} ><Link  style={{textDecoration:'none',color:'white'}}  to="/add_to_cart" >Add to cart</Link> </button>
    <button type="button" className="btn btn-danger btn-block "
                    data-mdb-ripple-color="dark"><Link  style={{textDecoration:'none',color:'white'}}  to="" >Place order</Link></button>
{/* </div> */}
                </div>

                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
      </div>
</section>
</div>

))}
        <div className='container-fluid'>
        <h5 className='text-danger'>Similar Products</h5>

 <div className='row'>
 {similarList.map((slist) => (
  <CardTemplate1 key={slist.id} {...slist} />
        ))}
            </div>
            </div>

    </div>
  );
};

export default BookDetailPage;
