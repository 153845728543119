import React, { useState, useContext, useRef, useEffect } from "react";
import "./LoginForm.css";
import Logo from "../img/AIASA.png";
import IconLogo from "../img/IconLogo.png";
import { Link } from "react-router-dom";
import Video from "../img/india.mp4";
import Image from "../img/img1.png";
import VideoOne from "../img/india.webm";
import axios from "axios";

import { useNavigate, useLocation } from "react-router-dom";
import { AxiosInstance } from "../axios";
import { UserContext } from "../context/UserDetails";
import Swal from "sweetalert2";

function SampleForm() {
  const txtLoginEmail = useRef(null);
  const txtLoginPass = useRef(null);

  const { login } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  let from = location.state?.form?.pathname || "/";

  const [fields, setFields] = useState({
    email: "",
    password: "",
    is_rem:false,
  });

  useEffect(() => {
  const getRem =   localStorage.getItem("Is_remember");
  if(getRem){
   const vvv= JSON.parse(getRem);
    if(vvv.is_rem){
      setFields({
        ['email']: vvv.email,
        ['password']: vvv.password,
        ['is_rem']: vvv.is_rem,
      });
    }
  }
  }, []);

  const errors = {};

  function isValidEmail(email) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }
  const [fieldsError, setFieldsError] = useState({});

  const [eyes, setEyes] = useState(false);

  const validateFieldss = (data) => {
    if (!data.email) {
      errors.email = "Please Enter Email address.";
     
    }  if (!isValidEmail(data.email)) {
      errors.email = "Please Enter a Valid Email";
  
    }  if (!data.password) {
      errors.password = "Please Enter Password.";
    
    }  if (data.password && data.password.length < 6) {
      errors.password = "Password must be 6 character.";
     
    }
    validateFieldssFocus(data);
    return errors;
  };

  const validateFieldssFocus = (data) => {
    if (!data.email) {
      txtLoginEmail.current.focus();
    }  if (!isValidEmail(data.email)) {
      txtLoginEmail.current.focus();
    }  if (!data.password) {
      txtLoginPass.current.focus();
    }  if (data.password && data.password.length < 6) {
      txtLoginPass.current.focus();
    }

    return errors;
  };

  const handleChange = (event) => {
// console.log(event,"event");
    var eventValue = event.target.value;
    var eventName = event.target.name;
    var eventChecked = event.target.checked;
    if(eventName === "is_rem"){
      // console.log(eventChecked,"eventChecked");
      if(eventChecked){
        setFields({
          ...fields,
          ['is_rem']: eventChecked,
        });
      }else{
        setFields({
          ...fields,
          ['is_rem']: false,
        });
      }
    }else{
      setFields({
        ...fields,
        [event.target.name]: eventValue,
      });
      setFieldsError({
        ...fieldsError,
        [event.target.name]: "",
      });
    }
  
  };
const handleEyes = () => {
  setEyes(!eyes);
}
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFieldss(fields);

    if (Object.keys(errors).length === 0) {
      try {
        const response = await AxiosInstance.post("api/user/login",{
          email: fields.email,
          password: fields.password,
        }
         );
        if (response.data.status == true) {
          sessionStorage.setItem("userDetails", JSON.stringify(response.data));
          localStorage.setItem(
            "user_id",
            JSON.stringify(response.data.user_id)
          );
          localStorage.setItem(
            "role_id",
            JSON.stringify(response.data.role_id)
          );
        
          // warning
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              login(response.data, () => {
                navigate(from, { replace: true });
              });
              localStorage.setItem("Is_remember", JSON.stringify({
                is_rem : fields.is_rem,
                email : fields.email,
                password :fields.password,
              }));
            }
          });
        } else if (response.data.status == false) {
          Swal.fire({
            title: "error",
            text: response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
          // errors.email = response.data.message;
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setFieldsError(errors);
    }
  };

  return (
    <div className=" LoginForm videoWrapper" id="wrapper_Login">
      <video poster={Image} autoPlay muted loop>
        <source src={Video} type="video/mp4" />
      </video>
      <div className="row">
        <div className="col-md-12 box">
          <form className="" onSubmit={handleSubmit}>
            <img src={IconLogo} id="img" alt="Logo" />
            <h1 className="">Log in to Anna IAS Academy</h1>
            <p>Your Journey to Success Begin Here</p>
            <input
            className="LoginInputs"
              ref={txtLoginEmail}
              type="email"
              placeholder="Email"
              onChange={handleChange}
              value={fields.email}
              name="email"
            />
            {fieldsError.email && (
              <small className="error">{fieldsError.email}</small>
            )}
             <input
    className="LoginInputs"
    type={eyes === true ? "text" : "password"}
    ref={txtLoginPass}
    placeholder="Password"
    onChange={handleChange}
    value={fields.password}
    name="password"
  />
{/* AIASA-0000031 issues updated by lalitha  */}
 {fields.password.length > 0 && (<span className="material-icons eyes_login" onClick={handleEyes}>{eyes  === true ? "visibility" : "visibility_off"}</span>)}
            {fieldsError.password && (
              <small className="error">{fieldsError.password}</small>
            )}
            <div className="d-flex">
              <input type="checkbox" name="is_rem" onChange={handleChange} checked={fields.is_rem} value={fields.is_rem} />
              <span className="checkbox-inline">Remember me</span>
              <Link to="/forgetPassword" className="forgot-link">
                Forgot Password?
              </Link>
            </div>
            <button type="submit" className="LoginBtn" name="Login">
              Login
            </button>
          </form>
          <div className="row SignupText">
            <div className="col-md-12">
              <div className="reg">
                <span>Not yet registered ?</span>
                <Link to="/register" className="rega">
                  Register Now
                </Link>
              </div>
            </div>
          </div>
          <div className="copyRights">© 2023 All Rights Reserved</div>
        </div>
      </div>
    </div>
  );
}

export default SampleForm;
