import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import {CustomDatePickerInput} from "./CustomDatePickerInput";

import "material-icons/iconfont/material-icons.css";
import moment from "moment";

const  DatePickerSample = (props) => {

    const handleFocus = () => {
        props.txtAdMDob.current.setFocus();
    }
    function range(start, end) {
        var ans = [];
        for (let i = start; i <= end; i++) {
            ans.push(i);
        }
        return ans;
    }
    let getYear = (dates) => {
        let currentYear = dates.getFullYear();
        return currentYear;
    };

    let getMonth = (dates) => {
        let months = dates.getMonth();
        return months;
    };
    // const [startDate, setStartDate] = useState(props.fieldInput);
    const years = range(1900, getYear(new Date()) + 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

   
    // console.log(props.fieldInput)

    return (
       
       <div className="d-flex"> 
    

       <DatePicker
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => (
                <div
                    style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <button type="button" class='PrevButton_date' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        <span class="material-icons">
                            chevron_left
                        </span>
                    </button>
                    <select className="react-datepicker_Select"
                        value={getYear(date)}
                        onChange={({ target: { value } }) =>  {
                            // props.handleYearChange && props.handleYearChange(value,props.name); 
                            changeYear(value)
                        }}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <select className="react-datepicker_Select"
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>{

                            // props.handleMonthChange && props.handleMonthChange(value,props.name); 
                            changeMonth(months.indexOf(value))
                        }
                           
                        }
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <button type="button" class='PrevButton_date'  onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        <span class="material-icons">
                            chevron_right
                        </span>
                    </button>
                </div>
            )}
            onChange={ props.handleIdChange ? (date) => props.handleIdChange(date,props.fieldsId ,props.name) : (date) => props.handleChange(date,props.name)}
            showYearDropdown
            value={props.fieldInput}
            id={props.name}
            ref={props.txtAdMDob}
            name={props.name}
            className={props.class ? `${props.class}  `: `Inputs `}
            dateFormat="DD/MM/YYYY"
            readOnly ={props.readOnly ? props.readOnly : ""}
            minDate={ props.minDate !==undefined ? new Date(props.minDate) : ""}
            maxDate={ props.maxDate !==undefined ? new Date(props.maxDate) : ""}
            placeholderText="YYYY/MM/DD" // Set your custom placeholder here
            disabled={props.disabledProperty}/>
            <span onClick={handleFocus} className={`${props.IconInput ? `${props.IconInput} material-icons-outlined react-datepicker-wrapper_span` : "material-icons-outlined react-datepicker-wrapper_span"}`}>
                calendar_month
            </span>


         </div>
    );
}
export default DatePickerSample;
