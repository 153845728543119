import React, { useEffect,useState } from 'react'
import './Meeting.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
const Textarea = styled.textarea`
width:100%!important;
`;

function TeamMeeting() {

  const msalConfig = {
    auth: {
        // clientId: '6ceb56aa-dd8c-4012-ac01-09797ac3e06f',
                clientId: 'c69ced55-6428-4e48-b48a-68ceb5395632',
                redirectUri: 'https://myapps.annaiasacademy.com/' // Replace with your redirect URI
            // redirectUri: 'http://localhost:3000/' // Replace with your redirect URI
    }
};

const msalInstance = new PublicClientApplication(msalConfig);

// const [meetingDetails, setMeetingDetails] = useState(null);
// const [allowedAttendees, setAllowedAttendees] = useState([]);
const navigate = useNavigate();

  const [list, setList] = useState({StartDateTime:"" , EndDateTime: "" , Subject : ""})


  function appendToStorage(name, data){
    var old = localStorage.getItem(name);
    if(old === null || old === undefined) {
        localStorage.setItem(name,data);
    } else {
        localStorage.setItem(name, old +"," + data);
    }
 }
 


  const handleChange = (e) => {

    let label = e.target.name;
    let value = e.target.value;
     setList({...list, [label]:value});

  }

  const handleSubmit = (e) => {

    e.preventDefault();
    let StartDateTime =  new Date(e.target.StartDateTime.value).toISOString();
    let EndDateTime =  new Date(e.target.EndDateTime.value).toISOString();
    let Subject =   e.target.Subject.value;

    msalInstance.loginPopup()
    .then(response => {
        // console.log(response);
        const accessToken = response.accessToken;
        // Use the access token to call the Microsoft Graph API
  
        const client = Client.init({
          authProvider: (done) => {
              done(null, accessToken);
          }
        });

        const onlineMeeting = {
          "startDateTime": StartDateTime,
          "endDateTime": EndDateTime,
          "participants": {
            "organizer": {
                "identity": {
                  "user": {
                    "id": "070eace1-d02e-4276-a3bf-ca6d1a204784",
                    "tenantId": "0169b6fb-efb9-4926-be5d-62d4f1d97902",
                    "displayName": "Lalitha"
                  }
                },
                "role": "presenter",
                "upn": "lalitha.a@bharatclouds.com"
              },
              
            "attendees": [
              {
                "upn": "jeevapriya.v@bharatclouds.com",
                "role": "attendee",
                "identity": {
                  "user": {
                    "id": "0a12ac5c-a1c5-48f8-ba82-aa7a1cf8f10b",
                    "displayName": "jeeva",

                    "identityProvider": "AAD"
                  }
                }
              }
            ],
          },
          "accessLevel":"Restricted",
          "subject": Subject,
          "joinMeetingIdSettings": {
            "isPasscodeRequired": true
          },
          "allowedAudience": "Organization",
           "lobbyBypassSettings": {
            "scope": "organization",
            "isDialInBypassEnabled": false
              }
                   

      };
      
          client.api('/me/onlineMeetings')
          .post(onlineMeeting)
          .then(response => {
            //  console.log(response);

            
              let Teams = { meetingID: response.id,accessToken : accessToken,attendee : response.participants,joinWebUrl : response.joinWebUrl, CreatedTime :response.creationDateTime , Meetingcode :response.meetingCode, Subject : response.subject};
          
              //localstroage
          
               appendToStorage("TeamDetails",JSON.stringify(Teams))
                 
               alert('Meeting created successfully');
               navigate("/view");
              // Use response.joinWebUrl to join the meeting
          })
          .catch(error => {
              console.log(error);
          });

        
        
       

      
    })
    .catch(error => {
        console.log(error);
    });


  }

  
  return (
   
    <div className='container'>
      <div className='row'>
        <div className='col-md-12  col-lg-12 '>
        <div className='wrapper  bg-opacity-75'>
        <form className='p-4' onSubmit={handleSubmit}>
      <div className="mb-3">
       <label htmlFor="StartDateTime" className="form-label">StartDateTime</label>
      <input type="datetime-local" name='StartDateTime' value={list.StartDateTime} className="form-control" onChange={handleChange} id="StartDateTime" aria-describedby="StartDateTime"/>
     </div>
    <div className="mb-3">
      <label htmlFor="EndDateTime" className="form-label">EndDateTime</label>
      <input type="datetime-local" name='EndDateTime' value={list.EndDateTime}  className="form-control" onChange={handleChange} id="EndDateTime" aria-describedby="EndDateTime"/>
    
   </div>
  <div className="mb-3">
    <label htmlFor="Subject" className="form-label">Subject</label>
     <Textarea name="Subject"  value={list.Subject} id="Subject" cols="40" onChange={handleChange} rows="3"></Textarea>
      </div>
 
      <button type="submit" className="btn btn-primary">Create Meeting</button>

    

      </form>

   
        </div>
        </div>
      
      </div>    
    </div>
  )
}


export default  TeamMeeting;

