import React,{useState} from "react";
import "./ResetPasswordEmail.css";
import Logo from "../img/AIASA.png";
import IconLogo from "../img/IconLogo.png";
import { Link } from "react-router-dom";
import { AxiosInstance } from "../axios";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
function EmailReset() {
//   const {email} = useParams();
// console.log(email,"....location...")

  const handleResendEmailClick = async() => {
    // try {
    // // Make the API call to resend the email
    // const response = await AxiosInstance.post('/api/user/forgot',{ email : resetEmail.Email});
    // console.log(response.data);
    //         if(response.data.status==true)
    //         {
    //           // window.location.href = '/admission';
    //         }
    //      } 
    //   catch (error)
    //     {
    //       console.log(error);
    //     }
  };

  return (
       <div className=" ForgetForm " id="wrapper_Forget">
        <div className="row">
        <div className="col-md-12 box">
            <img src={IconLogo} id="img" alt="Logo" />
            <h1 className="">Check Your Email</h1>
            <p>Get back in control<br/>check your email for you password reset link.</p>
           
            <div className="row SignupText">
            <div className="col-md-12">
              <div className="reg">
                <span>Didn't receive the email?</span>

                <Link to="#" className="rega" onClick={handleResendEmailClick}>
                 Click to Resend
                </Link>
              </div>
            </div>
          </div>
          <div className="BackLogin"><Link to="/login"><i class="fa fa-long-arrow-left" id="MIcons"></i>Back to Log in</Link></div>
          <div className="copyRights">© 2023 All Rights Reserved</div>
        </div>
      </div>
    </div>
  );
}

export default EmailReset;
