


import React ,{useState,useEffect} from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AxiosInstance } from '../../../../axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import ReadViewSubject from './ReadViewSubject';
import CategorySubject from './CategorySubject';
export default function ReadViewPMem(props) {
  // const {userId} = useParams();
  const navigate = useNavigate();
  
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }
 
    const [formFields, setFormFields] = useState({
        fname : "",
         lname : "",
         phoneNumber : "",
         email : "",
         status:"",
         department:"",
         designation : "",
      });

      const [addJob, setAddJob] = useState([
        { 
            id : 1,
            department : "",
            exp_designation:""
    
        }
      ]);

      useEffect(() => {
   
        AxiosInstance.post("api/staff/view", { "user_id": props.userId })
          .then((res) => {
            // console.log(res,"findbyid");
            if(res.data.status === true){
              setFormFields({  
                   fname : res.data.data.first_name,
                   lname : res.data.data.last_name,
                   phoneNumber : res.data.data.mobile_no,
                   email : res.data.data.email,
                   status : res.data.data.status,
                  //  department:res.data.data.department,
                  //  designation :res.data.data.exp_designation,
                })
                const addj = res.data.data.subject.map((item, index) => ({
                  id: index + 1,
                  department: item.department,
                  exp_designation: item.exp_designation,
                }));
                // Updating the state with the modified data
                setAddJob(addj);
            }
          })
          .catch((error) => {
            return error;
          });
    
      }, [props.userId]);


      useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);
  
      const navigateToPreviousScreen = () => {
        navigate(-1); // Navigate back by one step in the history
      };
      const UpgradeHandle = () => {
        const state = {
          // Your state properties here...
          upgrade: 1,
        };
        navigate(`/Faculties/addfaculty/${0}/${props.userId}`, { state });
        // {`/Faculties/addfaculty/${0}/${props.userId}`}
      }
  return (
    <div
      className={
        props.activeTab === 3
          ? "row tab-content mt-4"
          : "row tab-content mt-4 hide "
      }
      id="ex2-content"
    >
      <div
        className={
          props.activeTab === 3
            ? "tab-pane fade show active col-md-12"
            : "tab-pane fade col-md-12 "
        }
      >
    <div className="">
    <section className="StepOne">
          <div className="heading me-4 d-flex justify-content-between ">
            <div className="ms-lg-4 mt-4">
              <h6 className="Title">View Faculty Profile</h6>
            </div>
            <div className="d-flex smButton  mt-4">
              <Link to="/Faculties/3"  className="CancelButtons">
              Back to Faculties
              </Link>
              <button type="button" className="ListViewBtn_facul me-4">
                <Link to={`/Faculties/AddPanelMember/${props.userId}`}>
                  <span className="material-icons me-1 fs-5 align-middle text-white">
                    border_color
                  </span>
                </Link>
                <Link to={`/Faculties/AddPanelMember/${props.userId}`}>Edit Faculty Profile</Link>
              </button>
              <button type="button" className="ListViewBtn" onClick={UpgradeHandle} >
              <Link onClick={UpgradeHandle} to="javascript:void(0)">Upgrade to Teaching Faculty</Link>
              </button>
            </div>
          </div>

          <div className="row box">
            <div className="col-md-12">
              <p className="StepTitle">Basic Details</p>
              <div className="row">
                  <div className="col-md-12">
                    <div className="row mb-4">
                      <div className="col-md-6 col-lg-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            First Name
                          </label>
                          <span className="ReviewLabel">
                            {formFields.fname || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                           Last Name
                          </label>
                          <span className="ReviewLabel">
                            {formFields.lname || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Phone Number
                          </label>
                          <span className="ReviewLabel">
                            {formFields.phoneNumber || ""}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                         Email ID
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {formFields.email || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                        Status
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {formFields.status === 4 ? "Active"  : "Inactive"}
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
            </div>
          </div>
        </section>

        <section className=" StepOne">
          {addJob.map((item) => (
<div className="row box">
<div className="col-md-12">
  <p className="StepTitle">Job Info {item.id > 0 ? item.id : ""}</p>
  <div className="row">
      <div className="col-md-12">
        <div className="row mb-4">
          <div className="col-md-6 col-lg-3">
            <div className="forms ">
              <label
                htmlFor="inputName"
                className="form-label text-muted "
              >
               Department
              </label>
              <span className="ReviewLabel">
                {item.department || ""}
              </span>
            </div>
          </div>

          <div className="col-md-6 col-lg-3">
            <div className="forms ">
              <label
                htmlFor="inputName"
                className="form-label text-muted "
              >
               Designation
              </label>
              <span className="ReviewLabel">
                {item.exp_designation || ""}
              </span>
            </div>
          </div>
        
        </div>
      </div>
    </div>
</div>
</div>
          ))}
         
        </section>
  </div>
  </div>
  </div>
  )
}
