import Swal from "sweetalert2";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, LBL_BASIC_DETAILS } from "../../../../labels";
import "./Profile.css";
import React, { useState, useEffect, useRef } from "react";
import { TechQualification } from "../../../../pages/TechnicalQualification";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Download } from "@mui/icons-material";
import { validateProfile } from "./ValidateProfile";
import intlTelInput from "intl-tel-input";
import moment from "moment";
import { validateForms } from "./validation";
import { validateProfiles } from "./validateProfiles";
import Select, { components, useStateManager } from "react-select";
import { customStyles } from "../../../../pages/customStyles";
import DatePickerSample from "../../DatePicker";
import MonthDatePicker from "../../MonthDatePicker";
import { TechFaculty } from "./TechFaculty";
// import CategorySubject from "./CategorySubject";
// import ReadViewSubject from "./ReadViewSubject";

function NonFacultyProfile() {
  const userJSON = localStorage.getItem("userDetails");
  let roleid = null;
  let user_type = null;
  let userid = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
    if (user && user.role_id) {
      roleid = user.role_id;
    }
    if (user && user.user_type) {
      user_type = user.user_type;
    }
  } else {
    console.log("user not available in localstorge");
  }

  //fields use current fields focus

  const txtAdFname = useRef(null);
  const txtAdLname = useRef(null);
  const txtAdAge = useRef(null);
  const txtAdPlaceBrith = useRef(null);
  const txtAdMDob = useRef(null);
  const txtAdMStatus = useRef(null);
  const txtAdSpouse = useRef(null);
  const txtAdOccupation = useRef(null);
  const txtAdGender = useRef(null);
  const txtAdFath = useRef(null);
  const txtAdMoth = useRef(null);
  const txtAdPSize = useRef(null);
  const txtAdAno = useRef(null);
  const txtAdApath = useRef(null);
  const txtAdpno = useRef(null);
  const txtAdPpath = useRef(null);
  const txtAdPno = useRef(null);
  const txtAdWno = useRef(null);
  const txtAdFpo = useRef(null);
  const txtAdEmail = useRef(null);
  const txtAdPhno = useRef(null);
  const txtAdPsname = useRef(null);
  const txtAdParea = useRef(null);
  const txtAdPland = useRef(null);
  const txtAdPpost = useRef(null);
  const txtAdPtaluk = useRef(null);
  const txtAdPpincode = useRef(null);
  const txtAdPcity = useRef(null);
  const txtAdPstate = useRef(null);
  const txtAdPdistrict = useRef(null);
  const txtAdPerhno = useRef(null);
  const txtAdPersname = useRef(null);
  const txtAdPerarea = useRef(null);
  const txtAdPerland = useRef(null);
  const txtAdPererpost = useRef(null);
  const txtAdPertaluk = useRef(null);
  const txtAdPerpincode = useRef(null);
  const txtAdPercity = useRef(null);
  const txtAdPerstate = useRef(null);
  const txtAdPerdistrict = useRef(null);
  const txtadVerifyHere = useRef(null);

  const txtAdEduc = useRef(null);

  const txtAdUCer = useRef(null);
  const txtAdUpass = useRef(null);
  const txtAdUmarks = useRef(null);
  const txtAdUdeg = useRef(null);
  const txtAdUMaj = useRef(null);
  const txtAdUMajOther = useRef(null);
  const txtAdUclass = useRef(null);
  const txtAdUins = useRef(null);
  const txtAdUfile = useRef(null);

  const txtAdPhdCer = useRef(null);
  const txtAdPhdpass = useRef(null);
  const txtAdPhdmarks = useRef(null);
  const txtAdPhddeg = useRef(null);
  const txtAdPhdMaj = useRef(null);
  const txtAdPhdclass = useRef(null);
  const txtAdPhdins = useRef(null);
  const txtAdPhdfile = useRef(null);
  const txtAdPCer = useRef(null);
  const txtAdPpass = useRef(null);
  const txtAdPmarks = useRef(null);
  const txtAdPdeg = useRef(null);
  const txtAdPMaj = useRef(null);
  const txtAdPMajOther = useRef(null);
  const txtAdIMajOther = useRef(null);
  const txtAdDMajOther = useRef(null);
  const txtAdCMajOther = useRef(null);
  const txtAdPhdMajOther = useRef(null);
  const txtAdPclass = useRef(null);
  const txtAdPins = useRef(null);
  const txtAdPfile = useRef(null);
  const txtAdICer = useRef(null);
  const txtAdIpass = useRef(null);
  const txtAdImarks = useRef(null);
  const txtAdIdeg = useRef(null);
  const txtAdIMaj = useRef(null);
  const txtAdIclass = useRef(null);
  const txtAdIins = useRef(null);
  const txtAdIfile = useRef(null);
  const txtAdDCer = useRef(null);
  const txtAdDpass = useRef(null);
  const txtAdDmarks = useRef(null);
  const txtAdDdeg = useRef(null);
  const txtAdDMaj = useRef(null);
  const txtAdDclass = useRef(null);
  const txtAdDins = useRef(null);
  const txtAdDfile = useRef(null);
  const txtAdCCer = useRef(null);
  const txtAdCpass = useRef(null);
  const txtAdCmarks = useRef(null);
  const txtAdCdeg = useRef(null);
  const txtAdCMaj = useRef(null);
  const txtAdCclass = useRef(null);
  const txtAdCins = useRef(null);
  const txtAdCfile = useRef(null);
  const txtAdOCer = useRef(null);
  const txtAdOpass = useRef(null);
  const txtAdOmarks = useRef(null);
  const txtAdOdeg = useRef(null);
  const txtAdOMaj = useRef(null);
  const txtAdOclass = useRef(null);
  const txtAdOins = useRef(null);
  const txtAdOfile = useRef(null);
  const txtAdSpc = useRef(null);
  const txtAdWorkingexp = useRef(null);
  const txtAdTeachingexp = useRef(null);
  const txtAdDes = useRef(null);
  const txtAdDjoin = useRef(null);
  const txtAdVoicepp = useRef(null);
  const txtWfile = useRef(null);
  const txtTfile = useRef(null);

  const txtfacLoc = useRef(null);

  const { userId } = useParams();
  const navigate = useNavigate();
  //useState for all dropdown , input fields
  const [pgDegree, setPGDegree] = useState([]);
  const [ipgDegree, setIPGDegree] = useState([]);
  const [readbatch, setReadbatch] = useState([]);
  const [readSpecialpre, setReadSpecialpre] = useState([]);
  const [step, setStep] = useState(1);
  const [cOption, setCOption] = useState([]);
  const [catOption, setCatOption] = useState([]);
  const [teachMedium, setTeachMedium] = useState([]);
  const [department, setDepartment] = useState([]);
  const [examination, setExamination] = useState([]);
  const [previousexam, setPreviousexam] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [technicalOption, setTechnicalOption] = useState([]);
  const [pcDegree, setPCDegree] = useState([]);
  const [phDegree, setPhDegree] = useState([]);
  const [diplomaDegree, setDiplomaDegree] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [coursesCate, setCoursesCate] = useState([]);
  const [city, setCity] = useState([]);
  const [major, setMajor] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [locations, setLocations] = useState([]);
  const [specialPrefrence, setSpecialPrefrence] = useState([]);
  const [ugDegree, setUGDegree] = useState([]);
  const [pgMajor, setPgMajor] = useState([]);
  const [ipMajor, setIpMajor] = useState([]);
  const [PhMajor, setPhMajor] = useState([]);

  const [dmajor, setDmajor] = useState([]);
  const [pcMajor, setPcmajor] = useState([]);
  const [otMajor, setOTmajor] = useState([]);
  const [listErrors, setListErrors] = useState({});
  const [batchDetails, setBatchDetails] = useState([]);
  const [categoryJob, setCategoryJob] = useState([]);

  const [courseAias, setCourseAias] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [list, setList] = useState({
    FirstName: "",
    LastName: "",
    Fathername: "",
    DOB: "",
    age: 0,
    PlaceBirth: "",
    gender: "Male",
    MaritalStatus: "Single/Unmarried",
    Spouse: "",
    Occupation: "",
    Mobile: "",
    WhatsAppNumber: "",
    MobileCode: "+91",
    WhatsAppNumberCode: "+91",
    ContactNumber: false,
    emailaddress: "",
    Profilephoto: null,
    aadharNo: "",
    aadharCardphoto: null,
    original_aadhar_path: null,
    original_profile_path: null,
    original_caste_path: null,
    panNo: "",
    panPhoto: null,

    BuildingNumberPresent: "",
    BuildingNumberPresent: "",
    StreetNamePresent: "",
    AreaPresent: "",
    LandmarkPresent: "",
    districtPresent: "",
    CityPresent: "",
    statePresent: "",
    PostPresent: "",
    TalukPresent: "",
    PincodePresent: "",
    SamePresentAddress: false,
    BuildingNumberPermanent: "",
    StreetNamePermanent: "",
    AreaPermanent: "",
    LandmarkPermanent: "",
    CityPermanent: "",
    districtPermanent: "",
    statePermanent: "",
    PincodePermanent: "",
    PostPermanent: "",
    TalukPermanent: "",
    spq: "",
    prevTeachexp: "Yes",
    workingexp: "",
    teachexp: "Testing",
    teachType: "",
    designation: "",
    DJoin: "",
    VoiceOpinion: "",
    Verify: false,
    Adminstatus: "",
    comments: "",
    working_ex_file: null,
    Teaching_ex_file: null,
    original_aadhar_path: null,
    original_profile_path: null,
    original_caste_path: null,
    original_pan_path: null,
    original_workexp: null,
    original_teachexp: null,
    status: 0,
    location: "",
    location_name: "",
  });
  // AIASA-000125 - v1 issues solved by lalitha
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [step]);
  const [category, setCategory] = useState([]);
  const [subject, setSubject] = useState([]);
  const [classOption, setClassOption] = useState([]);

  const [uGisOther, setUGIsOther] = useState(false);
  const [pGisOther, setPGIsOther] = useState(false);
  const [pHDisOther, setPHDIsOther] = useState(false);
  const [iPGisOther, setIPGIsOther] = useState(false);
  const [dipisOther, setDipIsOther] = useState(false);
  const [pCisOther, setPCIsOther] = useState(false);

  const [ugOption, setUgOption] = useState({
    UG: null,
    PG: null,
    Integrated_PG: null,
    phd: null,
    Diploma: null,
    Professional_Courses: null,
  });

  const [mOption, setMOption] = useState({
    UGMajor: null,
    UGClassName: null,
    PGMajor: null,
    PGClassName: null,
    IPMajor: null,
    IPClassName: null,
    PHDMajor: null,
    PHDClassName: null,
    DMajor: null,
    DClassName: null,
    PCMajor: null,
    PCClassName: null,
    OtClassName: null,
  });
  const [educationQualification, setEducationQualification] = useState(["UG"]);
  //education useState
  const [sslcCerficates, setSslcCerficates] = useState([
    {
      name: "UG",
      UGCertificateNumber: "",
      UGYearpassing: "",
      UGDegree: "",
      UGMajor: "",
      UGMajor_Other: "",
      UGMarksPercentage: "",
      UGClassName: "",
      UGNameInstitute: "",
      UG_Path: null,
      original_file_path: null,
    },
    {
      name: "PG",
      PGCertificateNumber: "",
      PGYearpassing: "",
      PGDegree: "",
      PGMajor: "",
      PGMajor_Other: "",
      PGMarksPercentage: "",
      PGClassName: "",
      PGNameInstitute: "",
      PG_Path: null,
      original_file_path: null,
    },
    {
      name: "Integrated PG",
      IPCertificateNumber: "",
      IPYearpassing: "",
      IPDegree: "",
      IPMajor: "",
      IPMajor_Other: "",
      IPMarksPercentage: "",
      IPClassName: "",
      IPNameInstitute: "",
      IP_Path: null,
      original_file_path: null,
    },
    {
      name: "phd",
      PHDCertificateNumber: "",
      PHDYearpassing: "",
      PHDDegree: "",
      PHDMajor: "",
      PHDMajor_Other: "",
      PHDMarksPercentage: "",
      PHDClassName: "",
      PHDNameInstitute: "",
      PHD_Path: null,
      original_file_path: null,
    },
    {
      name: "Diploma",
      DCertificateNumber: "",
      DYearpassing: "",
      DDegree: "",
      DMajor: "",
      DMajor_other: "",
      DMarksPercentage: "",
      DClassName: "",
      DNameInstitute: "",
      D_Path: null,
      original_file_path: null,
    },
    {
      name: "Professional Courses",
      PCCertificateNumber: "",
      PCYearpassing: "",
      PCDegree: "",
      PCMajor: "",
      PCMajor_Other: "",
      PCMarksPercentage: "",
      PCClassName: "",
      PCNameInstitute: "",
      PC_Path: null,
      original_file_path: null,
    },
    {
      name: "Others",
      OtCertificateNumber: "",
      OtYearpassing: "",
      OtDegree: "",
      OtMajor: "",
      OtMarksPercentage: "",
      OtClassName: "",
      OtNameInstitute: "",
      Ot_Path: null,
      original_file_path: null,
    },
  ]);
  //technical qualification
  const [techQualification, setTechQualification] = useState([
    {
      id: 1,
      name: "",
      level: "",
      lang: "",
      Cnum: "",
      grade: "",
      kComputer: "",
      year: "",
      file: "",
      error_name: "",
      error_certificate_no: "",
      error_year_of_passing: "",
      error_level: "",
      error_grade: "",
      error_certificate_path: "",
      error_kComputer: "",
    },
  ]);
  //date and time convert to normal data
  const dobCon = (userdate) => {
    if (!userdate) {
      return ""; // Handle empty input
    }
    const date = new Date(userdate);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Handle invalid date
    }
    // Extract the year, month, and day components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  useEffect(() => {
    AxiosInstance.post("api/staff/view", {
      user_id: userId,
    })
      .then((res) => {
        // console.log(res.data.data);
        const userinfo = res.data.data;
        const PersonDetails = {
          FirstName: userinfo.first_name,
          LastName: userinfo.last_name,
          Fathername: userinfo.father_name,
          MotherName: userinfo.mother_name,
          Profilephoto:
            userinfo.profile_path !== null ? userinfo.profile_path : null,
          original_profile_path: userinfo.original_profile_path,
          original_aadhar_path: userinfo.original_aadhar_path,
          original_caste_path: userinfo.original_caste_path,
          DOB: userinfo.dob !== null ? dobCon(userinfo.dob) : "",
          //  DOB: "2000-07-29",
          PlaceBirth: userinfo.place_of_birth,
          age: userinfo.age,
          gender: userinfo.gender,
          MaritalStatus: userinfo.marital_status,
          status: userinfo.status,
          Spouse: userinfo.spouse_name,
          Occupation: userinfo.spouse_ocp,
          aadharNo: userinfo.aadhar_no,
          aadharCardphoto:
            userinfo.aadhar_path !== null ? userinfo.aadhar_path : null,
          panNo: userinfo.pan_no,
          panPhoto: userinfo.pan_path,
          original_aadhar_path: userinfo.original_aadhar_path,
          original_profile_path: userinfo.original_profile_path,
          original_caste_path: userinfo.original_caste_path,
          original_pan_path: userinfo.original_pan_path,
          Mobile: userinfo.mobile_no,
          WhatsAppNumber: userinfo.whatsapp_no,
          MobileCode:
            userinfo.country_code !== null ? userinfo.country_code : "+91",
          WhatsAppNumberCode:
            userinfo.country_code !== null ? userinfo.country_code : "+91",
          ContactNumber: userinfo.contact_type === 0 ? false : true,
          emailaddress: userinfo.email,
          spq: userinfo.special_qualification,
          prevTeachexp: userinfo.experience === 1 ? "Yes" : "NO",
          // workingexp: userinfo.working_experience,
          // teachexp: userinfo.teaching_experience,
          workingexp: userinfo.working_exp,
          teachexp: "",
          working_ex_file: userinfo.working_exp_filepath,
          Teaching_ex_file: userinfo.teaching_exp_filepath,
          original_workexp: userinfo.original_workexp,
          designation: userinfo.designation,
          teachType: userinfo.staff_type === 0 ? "0" : "1",
          DJoin:
            userinfo.aiasa_doj !== null && userinfo.aiasa_doj !== ""
              ? dobCon(userinfo.aiasa_doj)
              : "",
          VoiceOpinion: userinfo.feedback,
          location_name: userinfo.staff_loc_name,
          location: userinfo.staff_loc_id,
        };

        userinfo.address.map((obj) => {
          if (obj.address_type === 1) {
            PersonDetails.SamePresentAddress =
              obj.same_address === 0 ? true : false;
            PersonDetails.BuildingNumberPresent = obj.address1;
            PersonDetails.StreetNamePresent = obj.address2;
            PersonDetails.AreaPresent = obj.address3;
            PersonDetails.LandmarkPresent = obj.landmark;
            PersonDetails.districtPresent = obj.district_name;
            PersonDetails.CityPresent = obj.city;
            PersonDetails.statePresent = obj.state_name;
            PersonDetails.PostPresent = obj.post_name;
            PersonDetails.TalukPresent = obj.taluk;
            PersonDetails.PincodePresent = obj.postal_code;
          } else {
            PersonDetails.SamePresentAddress =
              obj.same_address === 0 ? true : false;
            PersonDetails.BuildingNumberPermanent = obj.address1;
            PersonDetails.StreetNamePermanent = obj.address2;
            PersonDetails.AreaPermanent = obj.address3;
            PersonDetails.LandmarkPermanent = obj.landmark;
            PersonDetails.districtPermanent = obj.district_name;
            PersonDetails.CityPermanent = obj.city;
            PersonDetails.statePermanent = obj.state_name;
            PersonDetails.PostPermanent = obj.post_name;
            PersonDetails.TalukPermanent = obj.taluk;
            PersonDetails.PincodePermanent = obj.postal_code;
          }
        });
        setList(PersonDetails);
        let edq = userinfo.edu
          .filter((item) => item.qualification_type === "EDQ")
          .map((obj) => {
            let name = "";
            if (obj.name === "sslc") {
              name = "SSLC";
            } else if (obj.name === "hsc") {
              name = "HSC";
            } else {
              name = obj.name;
            }
            return name;
          });

        setEducationQualification(edq);
        let sslcCert = userinfo.edu
          .filter((item) => item.qualification_type === "EDQ")
          .map((el, index) => {
            if (el.name === "UG") {
              const matchingCertificate = {};
              if (el.type_of_degree === 1) {
                setUGIsOther(true);
                setMOption((prevState) => ({
                  ...prevState,
                  ["UGMajor"]: {
                    value: "Others",
                    label: "Others",
                  },
                }));
              } else {
                setMOption((prevState) => ({
                  ...prevState,
                  ["UGMajor"]: {
                    value: el.major_subject,
                    label: el.major_subject,
                  },
                }));
              }
              setUgOption((prevState) => ({
                ...prevState,
                ["UG"]: {
                  value: el.degree_name,
                  label: el.degree_name,
                },
              }));

              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.UGCertificateNumber = el.certificate_no;
              matchingCertificate.UGYearpassing = el.year_of_passing;
              matchingCertificate.UGDegree = el.degree_name;
              matchingCertificate.UGMajor_Other = el.major_subject;
              matchingCertificate.UGMajor =
                el.type_of_degree === 1 ? "Others" : el.major_subject;
              matchingCertificate.UGMarksPercentage = el.marks_percentage;
              matchingCertificate.UGClassName = el.grade;
              matchingCertificate.original_file_path = el.original_file_path;
              setMOption((prevState) => ({
                ...prevState,
                ["UGClassName"]: {
                  value: el.grade,
                  label: el.grade,
                },
              }));
              matchingCertificate.UGNameInstitute = el.institute;
              matchingCertificate.UG_Path = el.certificate_path;
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "PG") {
              const matchingCertificate = {};
              if (el.type_of_degree === 1) {
                setPGIsOther(true);
                setMOption((prevState) => ({
                  ...prevState,
                  ["PGMajor"]: {
                    value: "Others",
                    label: "Others",
                  },
                }));
              } else {
                setMOption((prevState) => ({
                  ...prevState,
                  ["PGMajor"]: {
                    value: el.major_subject,
                    label: el.major_subject,
                  },
                }));
              }

              setUgOption((prevState) => ({
                ...prevState,
                ["PG"]: {
                  value: el.degree_name,
                  label: el.degree_name,
                },
              }));

              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.PGCertificateNumber = el.certificate_no;
              matchingCertificate.PGYearpassing = el.year_of_passing;
              matchingCertificate.PGMarksPercentage = el.marks_percentage;
              matchingCertificate.PGDegree = el.degree_name;
              matchingCertificate.PGMajor_Other = el.major_subject;
              matchingCertificate.PGMajor =
                el.type_of_degree === 1 ? "Others" : el.major_subject;
              matchingCertificate.PGClassName = el.grade;
              matchingCertificate.original_file_path = el.original_file_path;
              setMOption((prevState) => ({
                ...prevState,
                ["PGClassName"]: {
                  value: el.grade,
                  label: el.grade,
                },
              }));
              matchingCertificate.PGNameInstitute = el.institute;
              matchingCertificate.PG_Path = el.certificate_path;
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "Integrated PG") {
              const matchingCertificate = {};
              if (el.type_of_degree === 1) {
                setIPGIsOther(true);
                setMOption((prevState) => ({
                  ...prevState,
                  ["IPMajor"]: {
                    value: "Others",
                    label: "Others",
                  },
                }));
              } else {
                setMOption((prevState) => ({
                  ...prevState,
                  ["IPMajor"]: {
                    value: el.major_subject,
                    label: el.major_subject,
                  },
                }));
              }

              setUgOption((prevState) => ({
                ...prevState,
                ["Integrated_PG"]: {
                  value: el.degree_name,
                  label: el.degree_name,
                },
              }));

              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.IPCertificateNumber = el.certificate_no;
              matchingCertificate.IPYearpassing = el.year_of_passing;
              matchingCertificate.IPDegree = el.degree_name;
              matchingCertificate.IPMajor_Other = el.major_subject;
              matchingCertificate.IPMajor =
                el.type_of_degree === 1 ? "Others" : el.major_subject;
              matchingCertificate.IPMarksPercentage = el.marks_percentage;
              matchingCertificate.IPClassName = el.grade;
              matchingCertificate.original_file_path = el.original_file_path;
              setMOption((prevState) => ({
                ...prevState,
                ["IPClassName"]: {
                  value: el.grade,
                  label: el.grade,
                },
              }));
              matchingCertificate.IPNameInstitute = el.institute;
              matchingCertificate.IP_Path = el.certificate_path;
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "phd") {
              const matchingCertificate = {};
              if (el.type_of_degree === 1) {
                setPHDIsOther(true);
                setMOption((prevState) => ({
                  ...prevState,
                  ["PHDMajor"]: {
                    value: "Others",
                    label: "Others",
                  },
                }));
              } else {
                setMOption((prevState) => ({
                  ...prevState,
                  ["PHDMajor"]: {
                    value: el.major_subject,
                    label: el.major_subject,
                  },
                }));
              }
              setUgOption((prevState) => ({
                ...prevState,
                ["phd"]: {
                  value: el.degree_name,
                  label: el.degree_name,
                },
              }));

              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.PHDCertificateNumber = el.certificate_no;
              matchingCertificate.PHDYearpassing = el.year_of_passing;
              matchingCertificate.PHDDegree = el.degree_name;
              matchingCertificate.PHDMajor_Other = el.major_subject;
              matchingCertificate.PHDMajor =
                el.type_of_degree === 1 ? "Others" : el.major_subject;
              matchingCertificate.PHDMarksPercentage = el.marks_percentage;
              matchingCertificate.PHDClassName = el.grade;
              matchingCertificate.original_file_path = el.original_file_path;
              setMOption((prevState) => ({
                ...prevState,
                ["PHDClassName"]: {
                  value: el.grade,
                  label: el.grade,
                },
              }));
              matchingCertificate.PHDNameInstitute = el.institute;
              matchingCertificate.PHD_Path = el.certificate_path;
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "Diploma") {
              const matchingCertificate = {};
              if (el.type_of_degree === 1) {
                setDipIsOther(true);
                setMOption((prevState) => ({
                  ...prevState,
                  ["DMajor"]: {
                    value: "Others",
                    label: "Others",
                  },
                }));
              } else {
                setMOption((prevState) => ({
                  ...prevState,
                  ["DMajor"]: {
                    value: el.major_subject,
                    label: el.major_subject,
                  },
                }));
              }
              setUgOption((prevState) => ({
                ...prevState,
                ["Diploma"]: {
                  value: el.degree_name,
                  label: el.degree_name,
                },
              }));

              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.DCertificateNumber = el.certificate_no;
              matchingCertificate.DYearpassing = el.year_of_passing;
              matchingCertificate.DDegree = el.degree_name;
              matchingCertificate.DMajor_Other = el.major_subject;
              matchingCertificate.DMajor =
                el.type_of_degree === 1 ? "Others" : el.major_subject;
              matchingCertificate.DMarksPercentage = el.marks_percentage;
              matchingCertificate.DClassName = el.grade;
              matchingCertificate.original_file_path = el.original_file_path;
              setMOption((prevState) => ({
                ...prevState,
                ["DClassName"]: {
                  value: el.grade,
                  label: el.grade,
                },
              }));
              matchingCertificate.DNameInstitute = el.institute;
              matchingCertificate.D_Path = el.certificate_path;
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "Professional Courses") {
              const matchingCertificate = {};

              if (el.type_of_degree === 1) {
                setPCIsOther(true);
                setMOption((prevState) => ({
                  ...prevState,
                  ["PCMajor"]: {
                    value: "Others",
                    label: "Others",
                  },
                }));
              } else {
                setMOption((prevState) => ({
                  ...prevState,
                  ["PCMajor"]: {
                    value: el.major_subject,
                    label: el.major_subject,
                  },
                }));
              }

              setUgOption((prevState) => ({
                ...prevState,
                ["Professional_Courses"]: {
                  value: el.degree_name,
                  label: el.degree_name,
                },
              }));

              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.PCCertificateNumber = el.certificate_no;
              matchingCertificate.PCYearpassing = el.year_of_passing;
              matchingCertificate.PCDegree = el.degree_name;
              matchingCertificate.PCMajor_Other = el.major_subject;
              matchingCertificate.PCMajor =
                el.type_of_degree === 1 ? "Others" : el.major_subject;
              matchingCertificate.PCMarksPercentage = el.marks_percentage;
              matchingCertificate.PCClassName = el.grade;
              matchingCertificate.original_file_path = el.original_file_path;
              setMOption((prevState) => ({
                ...prevState,
                ["PCClassName"]: {
                  value: el.grade,
                  label: el.grade,
                },
              }));
              matchingCertificate.PCNameInstitute = el.institute;
              matchingCertificate.PC_Path = el.certificate_path;
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            } else if (el.name === "Others") {
              const matchingCertificate = {};

              // if a matching certificate is found, update its properties
              matchingCertificate.name = el.name;
              matchingCertificate.OtCertificateNumber = el.certificate_no;
              matchingCertificate.OtYearpassing = el.year_of_passing;
              matchingCertificate.OtDegree = el.degree_name;
              matchingCertificate.OtMajor = el.major_subject;
              matchingCertificate.OtMarksPercentage = el.marks_percentage;
              matchingCertificate.OtClassName = el.grade;
              matchingCertificate.original_file_path = el.original_file_path;

              setMOption((prevState) => ({
                ...prevState,
                ["OtClassName"]: {
                  value: el.grade,
                  label: el.grade,
                },
              }));
              matchingCertificate.OtNameInstitute = el.institute;
              matchingCertificate.Ot_Path = el.certificate_path;
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === el.name) {
                    // return { ...q, matchingCertificate };
                    return matchingCertificate;
                  }
                  return q;
                });
              });
            }
          });
        let teach = userinfo.edu
          .filter((item) => item.qualification_type === "THQ")
          .map((el, index) => {
            const Teachqu = {};

            // if a matching certificate is found, update its properties
            Teachqu.id = index === 0 ? 1 : index + 1;
            Teachqu.name = el.name;
            Teachqu.level = el.level;
            Teachqu.lang = el.edu_medium;
            Teachqu.Cnum = el.certificate_no;
            Teachqu.grade = el.grade;
            Teachqu.kComputer = el.kdg_in_com;
            Teachqu.year = el.year_of_passing;
            Teachqu.file = el.certificate_path;
            Teachqu.error_name = "";
            Teachqu.error_certificate_no = "";
            Teachqu.error_year_of_passing = "";
            Teachqu.error_level = "";
            Teachqu.error_grade = "";
            Teachqu.error_certificate_path = "";
            Teachqu.error_kComputer = "";
            Teachqu.original_file_path = el.original_file_path;
            return Teachqu;
          });
        setTechQualification(teach);
      })
      .catch((error) => {
        return error;
      });
  }, [userId]);

  // Faculty profile read api for all steps
  useEffect(() => {
    if (!userId) {
      const userJSON = localStorage.getItem("userDetails");
      if (userJSON) {
        const user = JSON.parse(userJSON);
        if (user && user.user_id) {
          const userid = user.user_id;

          AxiosInstance.post("api/staff/view", {
            user_id: userid,
          })
            .then((res) => {
              // console.log(res.data.data);
              const userinfo = res.data.data;

              const PersonDetails = {
                FirstName: userinfo.first_name,
                LastName: userinfo.last_name,
                Fathername: userinfo.father_name,
                MotherName: userinfo.mother_name,
                Profilephoto:
                  userinfo.profile_path !== null ? userinfo.profile_path : null,
                original_profile_path: userinfo.original_profile_path,
                original_aadhar_path: userinfo.original_aadhar_path,
                original_caste_path: userinfo.original_caste_path,
                DOB: userinfo.dob !== null ? dobCon(userinfo.dob) : "",
                //  DOB: "2000-07-29",
                PlaceBirth: userinfo.place_of_birth,
                age: userinfo.age,
                gender: userinfo.gender,
                MaritalStatus: userinfo.marital_status,
                Spouse: userinfo.spouse_name,
                Occupation: userinfo.spouse_ocp,
                aadharNo: userinfo.aadhar_no,
                aadharCardphoto:
                  userinfo.aadhar_path !== null ? userinfo.aadhar_path : null,
                panNo: userinfo.pan_no,
                panPhoto: userinfo.pan_path,
                original_aadhar_path: userinfo.original_aadhar_path,
                original_profile_path: userinfo.original_profile_path,
                original_caste_path: userinfo.original_caste_path,
                original_pan_path: userinfo.original_pan_path,
                Mobile: userinfo.mobile_no,
                WhatsAppNumber: userinfo.whatsapp_no,
                ContactNumber: userinfo.contact_type === 0 ? false : true,
                emailaddress: userinfo.email,
                spq: userinfo.special_qualification,
                prevTeachexp: userinfo.experience === 1 ? "Yes" : "NO",
                workingexp: userinfo.working_exp,
                teachexp: "",
                working_ex_file: userinfo.working_exp_filepath,
                Teaching_ex_file: null,
                original_workexp: userinfo.original_workexp,
                designation: userinfo.designation,
                teachType: userinfo.staff_type === 0 ? "0" : "1",
                DJoin: dobCon(userinfo.aiasa_doj),
                VoiceOpinion: userinfo.feedback,
                location_name: userinfo.staff_loc_name,
                location: userinfo.staff_loc_id,
              };

              userinfo.address.map((obj) => {
                if (obj.address_type === 1) {
                  PersonDetails.SamePresentAddress =
                    obj.same_address === 0 ? true : false;
                  PersonDetails.BuildingNumberPresent = obj.address1;
                  PersonDetails.StreetNamePresent = obj.address2;
                  PersonDetails.AreaPresent = obj.address3;
                  PersonDetails.LandmarkPresent = obj.landmark;
                  PersonDetails.districtPresent = obj.district_name;
                  PersonDetails.CityPresent = obj.city;
                  PersonDetails.statePresent = obj.state_name;
                  PersonDetails.PostPresent = obj.post_name;
                  PersonDetails.TalukPresent = obj.taluk;
                  PersonDetails.PincodePresent = obj.postal_code;
                } else {
                  PersonDetails.SamePresentAddress =
                    obj.same_address === 0 ? true : false;
                  PersonDetails.BuildingNumberPermanent = obj.address1;
                  PersonDetails.StreetNamePermanent = obj.address2;
                  PersonDetails.AreaPermanent = obj.address3;
                  PersonDetails.LandmarkPermanent = obj.landmark;
                  PersonDetails.districtPermanent = obj.district_name;
                  PersonDetails.CityPermanent = obj.city;
                  PersonDetails.statePermanent = obj.state_name;
                  PersonDetails.PostPermanent = obj.post_name;
                  PersonDetails.TalukPermanent = obj.taluk;
                  PersonDetails.PincodePermanent = obj.postal_code;
                }
              });
              setList(PersonDetails);

              // const ca = userinfo.category !==null && userinfo.category
              //   .split(",")
              //   .map((category) => category.toUpperCase());
              // setCategory(ca);

              // const originalData = userinfo.subject.reduce((acc, cur) => {
              //   const categoryIndex = acc.findIndex(
              //     (item) => item.category === cur.category
              //   );
              //   if (categoryIndex === -1) {
              //     acc.push({
              //       id: acc.length + 1,
              //       category: cur.category,
              //       optionSelected: [
              //         {
              //           value: cur.subject,
              //           label: cur.subject,
              //         },
              //       ],
              //     });
              //   } else {
              //     acc[categoryIndex].optionSelected.push({
              //       value: cur.subject,
              //       label: cur.subject,
              //     });
              //   }
              //   return acc;
              // }, []);

              // console.log(originalData, "originalData");
              // setSubject(originalData);

              // const originalData = res.data.data.subject !==null && res.data.data.subject.reduce((acc, cur) => {
              //   const categoryIndex = acc.findIndex(
              //     (item) => item.category === cur.category
              //   );
              //   if (categoryIndex === -1) {
              //     acc.push({
              //       id: acc.length + 1,
              //       category: cur.category,
              //       optionSelected: [
              //         {
              //           value: cur.subject,
              //           label: cur.subject,
              //         },
              //       ],
              //       subject_name: [],
              //     });
              //   } else {
              //     acc[categoryIndex].optionSelected.push({
              //       value: cur.subject,
              //       label: cur.subject,
              //     });
              //   }
              //   return acc;
              // }, []);

              // // console.log(originalData, "originalData");
              // // setSubject(originalData);

              // originalData.map((obj) => {
              //   getallSubjectView(
              //     originalData,
              //     obj.category,
              //     obj.optionSelected
              //   );
              // });

              let edq = userinfo.edu
                .filter((item) => item.qualification_type === "EDQ")
                .map((obj) => {
                  let name = "";
                  if (obj.name === "sslc") {
                    name = "SSLC";
                  } else if (obj.name === "hsc") {
                    name = "HSC";
                  } else {
                    name = obj.name;
                  }
                  return name;
                });

              setEducationQualification(edq);
              let sslcCert = userinfo.edu
                .filter((item) => item.qualification_type === "EDQ")
                .map((el, index) => {
                  if (el.name === "UG") {
                    const matchingCertificate = {};
                    if (el.type_of_degree === 1) {
                      setUGIsOther(true);
                      setMOption((prevState) => ({
                        ...prevState,
                        ["UGMajor"]: {
                          value: "Others",
                          label: "Others",
                        },
                      }));
                    } else {
                      setMOption((prevState) => ({
                        ...prevState,
                        ["UGMajor"]: {
                          value: el.major_subject,
                          label: el.major_subject,
                        },
                      }));
                    }
                    setUgOption((prevState) => ({
                      ...prevState,
                      ["UG"]: {
                        value: el.degree_name,
                        label: el.degree_name,
                      },
                    }));

                    // if a matching certificate is found, update its properties
                    matchingCertificate.name = el.name;
                    matchingCertificate.UGCertificateNumber = el.certificate_no;
                    matchingCertificate.UGYearpassing = el.year_of_passing;
                    matchingCertificate.UGDegree = el.degree_name;
                    matchingCertificate.UGMajor_Other = el.major_subject;
                    matchingCertificate.UGMajor =
                      el.type_of_degree === 1 ? "Others" : el.major_subject;
                    matchingCertificate.UGMarksPercentage = el.marks_percentage;
                    matchingCertificate.UGClassName = el.grade;
                    matchingCertificate.original_file_path =
                      el.original_file_path;
                    setMOption((prevState) => ({
                      ...prevState,
                      ["UGClassName"]: {
                        value: el.grade,
                        label: el.grade,
                      },
                    }));
                    matchingCertificate.UGNameInstitute = el.institute;
                    matchingCertificate.UG_Path = el.certificate_path;
                    setSslcCerficates((prevState) => {
                      return prevState.map((q) => {
                        if (q.name === el.name) {
                          // return { ...q, matchingCertificate };
                          return matchingCertificate;
                        }
                        return q;
                      });
                    });
                  } else if (el.name === "PG") {
                    const matchingCertificate = {};
                    if (el.type_of_degree === 1) {
                      setPGIsOther(true);
                      setMOption((prevState) => ({
                        ...prevState,
                        ["PGMajor"]: {
                          value: "Others",
                          label: "Others",
                        },
                      }));
                    } else {
                      setMOption((prevState) => ({
                        ...prevState,
                        ["PGMajor"]: {
                          value: el.major_subject,
                          label: el.major_subject,
                        },
                      }));
                    }

                    setUgOption((prevState) => ({
                      ...prevState,
                      ["PG"]: {
                        value: el.degree_name,
                        label: el.degree_name,
                      },
                    }));

                    // if a matching certificate is found, update its properties
                    matchingCertificate.name = el.name;
                    matchingCertificate.PGCertificateNumber = el.certificate_no;
                    matchingCertificate.PGYearpassing = el.year_of_passing;
                    matchingCertificate.PGMarksPercentage = el.marks_percentage;
                    matchingCertificate.PGDegree = el.degree_name;
                    matchingCertificate.PGMajor_Other = el.major_subject;
                    matchingCertificate.PGMajor =
                      el.type_of_degree === 1 ? "Others" : el.major_subject;
                    matchingCertificate.PGClassName = el.grade;
                    matchingCertificate.original_file_path =
                      el.original_file_path;
                    setMOption((prevState) => ({
                      ...prevState,
                      ["PGClassName"]: {
                        value: el.grade,
                        label: el.grade,
                      },
                    }));
                    matchingCertificate.PGNameInstitute = el.institute;
                    matchingCertificate.PG_Path = el.certificate_path;
                    setSslcCerficates((prevState) => {
                      return prevState.map((q) => {
                        if (q.name === el.name) {
                          return matchingCertificate;
                        }
                        return q;
                      });
                    });
                  } else if (el.name === "Integrated PG") {
                    const matchingCertificate = {};
                    if (el.type_of_degree === 1) {
                      setIPGIsOther(true);
                      setMOption((prevState) => ({
                        ...prevState,
                        ["IPMajor"]: {
                          value: "Others",
                          label: "Others",
                        },
                      }));
                    } else {
                      setMOption((prevState) => ({
                        ...prevState,
                        ["IPMajor"]: {
                          value: el.major_subject,
                          label: el.major_subject,
                        },
                      }));
                    }

                    setUgOption((prevState) => ({
                      ...prevState,
                      ["Integrated_PG"]: {
                        value: el.degree_name,
                        label: el.degree_name,
                      },
                    }));

                    // if a matching certificate is found, update its properties
                    matchingCertificate.name = el.name;
                    matchingCertificate.IPCertificateNumber = el.certificate_no;
                    matchingCertificate.IPYearpassing = el.year_of_passing;
                    matchingCertificate.IPDegree = el.degree_name;
                    matchingCertificate.IPMajor_Other = el.major_subject;
                    matchingCertificate.IPMajor =
                      el.type_of_degree === 1 ? "Others" : el.major_subject;
                    matchingCertificate.IPMarksPercentage = el.marks_percentage;
                    matchingCertificate.IPClassName = el.grade;
                    matchingCertificate.original_file_path =
                      el.original_file_path;
                    setMOption((prevState) => ({
                      ...prevState,
                      ["IPClassName"]: {
                        value: el.grade,
                        label: el.grade,
                      },
                    }));
                    matchingCertificate.IPNameInstitute = el.institute;
                    matchingCertificate.IP_Path = el.certificate_path;
                    setSslcCerficates((prevState) => {
                      return prevState.map((q) => {
                        if (q.name === el.name) {
                          // return { ...q, matchingCertificate };
                          return matchingCertificate;
                        }
                        return q;
                      });
                    });
                  } else if (el.name === "phd") {
                    const matchingCertificate = {};
                    if (el.type_of_degree === 1) {
                      setPHDIsOther(true);
                      setMOption((prevState) => ({
                        ...prevState,
                        ["PHDMajor"]: {
                          value: "Others",
                          label: "Others",
                        },
                      }));
                    } else {
                      setMOption((prevState) => ({
                        ...prevState,
                        ["PHDMajor"]: {
                          value: el.major_subject,
                          label: el.major_subject,
                        },
                      }));
                    }
                    setUgOption((prevState) => ({
                      ...prevState,
                      ["phd"]: {
                        value: el.degree_name,
                        label: el.degree_name,
                      },
                    }));

                    // if a matching certificate is found, update its properties
                    matchingCertificate.name = el.name;
                    matchingCertificate.PHDCertificateNumber =
                      el.certificate_no;
                    matchingCertificate.PHDYearpassing = el.year_of_passing;
                    matchingCertificate.PHDDegree = el.degree_name;
                    matchingCertificate.PHDMajor_Other = el.major_subject;
                    matchingCertificate.PHDMajor =
                      el.type_of_degree === 1 ? "Others" : el.major_subject;
                    matchingCertificate.PHDMarksPercentage =
                      el.marks_percentage;
                    matchingCertificate.PHDClassName = el.grade;
                    matchingCertificate.original_file_path =
                      el.original_file_path;
                    setMOption((prevState) => ({
                      ...prevState,
                      ["PHDClassName"]: {
                        value: el.grade,
                        label: el.grade,
                      },
                    }));
                    matchingCertificate.PHDNameInstitute = el.institute;
                    matchingCertificate.PHD_Path = el.certificate_path;
                    setSslcCerficates((prevState) => {
                      return prevState.map((q) => {
                        if (q.name === el.name) {
                          // return { ...q, matchingCertificate };
                          return matchingCertificate;
                        }
                        return q;
                      });
                    });
                  } else if (el.name === "Diploma") {
                    const matchingCertificate = {};
                    if (el.type_of_degree === 1) {
                      setDipIsOther(true);
                      setMOption((prevState) => ({
                        ...prevState,
                        ["DMajor"]: {
                          value: "Others",
                          label: "Others",
                        },
                      }));
                    } else {
                      setMOption((prevState) => ({
                        ...prevState,
                        ["DMajor"]: {
                          value: el.major_subject,
                          label: el.major_subject,
                        },
                      }));
                    }
                    setUgOption((prevState) => ({
                      ...prevState,
                      ["Diploma"]: {
                        value: el.degree_name,
                        label: el.degree_name,
                      },
                    }));

                    // if a matching certificate is found, update its properties
                    matchingCertificate.name = el.name;
                    matchingCertificate.DCertificateNumber = el.certificate_no;
                    matchingCertificate.DYearpassing = el.year_of_passing;
                    matchingCertificate.DDegree = el.degree_name;
                    matchingCertificate.DMajor_Other = el.major_subject;
                    matchingCertificate.DMajor =
                      el.type_of_degree === 1 ? "Others" : el.major_subject;
                    matchingCertificate.DMarksPercentage = el.marks_percentage;
                    matchingCertificate.DClassName = el.grade;
                    matchingCertificate.original_file_path =
                      el.original_file_path;
                    setMOption((prevState) => ({
                      ...prevState,
                      ["DClassName"]: {
                        value: el.grade,
                        label: el.grade,
                      },
                    }));
                    matchingCertificate.DNameInstitute = el.institute;
                    matchingCertificate.D_Path = el.certificate_path;
                    setSslcCerficates((prevState) => {
                      return prevState.map((q) => {
                        if (q.name === el.name) {
                          // return { ...q, matchingCertificate };
                          return matchingCertificate;
                        }
                        return q;
                      });
                    });
                  } else if (el.name === "Professional Courses") {
                    const matchingCertificate = {};

                    if (el.type_of_degree === 1) {
                      setPCIsOther(true);
                      setMOption((prevState) => ({
                        ...prevState,
                        ["PCMajor"]: {
                          value: "Others",
                          label: "Others",
                        },
                      }));
                    } else {
                      setMOption((prevState) => ({
                        ...prevState,
                        ["PCMajor"]: {
                          value: el.major_subject,
                          label: el.major_subject,
                        },
                      }));
                    }

                    setUgOption((prevState) => ({
                      ...prevState,
                      ["Professional_Courses"]: {
                        value: el.degree_name,
                        label: el.degree_name,
                      },
                    }));

                    // if a matching certificate is found, update its properties
                    matchingCertificate.name = el.name;
                    matchingCertificate.PCCertificateNumber = el.certificate_no;
                    matchingCertificate.PCYearpassing = el.year_of_passing;
                    matchingCertificate.PCDegree = el.degree_name;
                    matchingCertificate.PCMajor_Other = el.major_subject;
                    matchingCertificate.PCMajor =
                      el.type_of_degree === 1 ? "Others" : el.major_subject;
                    matchingCertificate.PCMarksPercentage = el.marks_percentage;
                    matchingCertificate.PCClassName = el.grade;
                    matchingCertificate.original_file_path =
                      el.original_file_path;
                    setMOption((prevState) => ({
                      ...prevState,
                      ["PCClassName"]: {
                        value: el.grade,
                        label: el.grade,
                      },
                    }));
                    matchingCertificate.PCNameInstitute = el.institute;
                    matchingCertificate.PC_Path = el.certificate_path;
                    setSslcCerficates((prevState) => {
                      return prevState.map((q) => {
                        if (q.name === el.name) {
                          // return { ...q, matchingCertificate };
                          return matchingCertificate;
                        }
                        return q;
                      });
                    });
                  } else if (el.name === "Others") {
                    const matchingCertificate = {};

                    // if a matching certificate is found, update its properties
                    matchingCertificate.name = el.name;
                    matchingCertificate.OtCertificateNumber = el.certificate_no;
                    matchingCertificate.OtYearpassing = el.year_of_passing;
                    matchingCertificate.OtDegree = el.degree_name;
                    matchingCertificate.OtMajor = el.major_subject;
                    matchingCertificate.OtMarksPercentage = el.marks_percentage;
                    matchingCertificate.OtClassName = el.grade;
                    matchingCertificate.original_file_path =
                      el.original_file_path;

                    setMOption((prevState) => ({
                      ...prevState,
                      ["OtClassName"]: {
                        value: el.grade,
                        label: el.grade,
                      },
                    }));
                    matchingCertificate.OtNameInstitute = el.institute;
                    matchingCertificate.Ot_Path = el.certificate_path;
                    setSslcCerficates((prevState) => {
                      return prevState.map((q) => {
                        if (q.name === el.name) {
                          // return { ...q, matchingCertificate };
                          return matchingCertificate;
                        }
                        return q;
                      });
                    });
                  }
                });
              let teach = userinfo.edu
                .filter((item) => item.qualification_type === "THQ")
                .map((el, index) => {
                  const Teachqu = {};

                  // if a matching certificate is found, update its properties
                  Teachqu.id = index === 0 ? 1 : index + 1;
                  Teachqu.name = el.name;
                  Teachqu.level = el.level;
                  Teachqu.lang = el.edu_medium;
                  Teachqu.Cnum = el.certificate_no;
                  Teachqu.grade = el.grade;
                  Teachqu.kComputer = el.kdg_in_com;
                  Teachqu.year =
                    el.year_of_passing !== "" && el.year_of_passing !== null
                      ? el.year_of_passing
                      : "";
                  Teachqu.file = el.certificate_path;
                  Teachqu.error_name = "";
                  Teachqu.error_certificate_no = "";
                  Teachqu.error_year_of_passing = "";
                  Teachqu.error_level = "";
                  Teachqu.error_grade = "";
                  Teachqu.error_certificate_path = "";
                  Teachqu.error_kComputer = "";
                  return Teachqu;
                });
              setTechQualification(teach);
            })
            .catch((error) => {
              return error;
            });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (step === 1) {
      // Initialize intl-tel-input on component mount
      const iti = intlTelInput(txtAdPno.current, {
        // Add options as needed, refer to the intl-tel-input documentation
        initialCountry: "in",
        separateDialCode: true,
      });
      // You can listen for changes if needed
      txtAdPno.current.addEventListener("countrychange", () => {
        // console.log('Country changed:', iti.getSelectedCountryData());
        const selectedCountryData = iti.getSelectedCountryData();
        setList((prevFields) => ({
          ...prevFields,
          MobileCode: `+${selectedCountryData.dialCode}`, // Update phone number with the new country code
        }));
      });

      const iti_wha = intlTelInput(txtAdWno.current, {
        // Add options as needed, refer to the intl-tel-input documentation
        initialCountry: "in",
        separateDialCode: true,
      });
      // You can listen for changes if needed
      txtAdWno.current.addEventListener("countrychange", () => {
        // console.log('Country changed:', iti_wha.getSelectedCountryData());
        const selectedCountryData = iti_wha.getSelectedCountryData();
        setList((prevFields) => ({
          ...prevFields,
          WhatsAppNumberCode: `+${selectedCountryData.dialCode}`, // Update phone number with the new country code
        }));
      });

      // Clean up on component unmount
      return () => {
        iti.destroy();
        iti_wha.destroy();
      };
    }
  }, [step]);

  useEffect(() => {
    // console.log(subject, "subject");
    //temp_subject = subject;
  }, [subject]);

  const getallSubject = (sub, name) => {
    AxiosInstance.post("api/course/subject", { category_name: name, type: 1 })
      .then((res) => {
        if (res.data.data.length > 0) {
          // const subjects = res.data.data.map((item, index) => (
          //   <option id={index} key={index} value={item.subject}>
          //     {item.subject}
          //   </option>
          // ));
          const subjects = [];
          res.data.data.map((item, index) => {
            subjects.push({
              value: item.subject + "",
              label: item.subject + "",
            });
          });
          // setCate(subject);
          let subs = [];
          sub.map((obj) => {
            if (obj.category === name) {
              obj.subject_name = subjects;
            }
            subs.push(obj);
          });
          setSubject(subs);
        } else {
          // const option = (
          //   <option key="select" value="select">
          //     Please select
          //   </option>
          // );
          const subjects = [];
          let subs = [];
          sub.map((obj) => {
            if (obj.category === name) {
              obj.subject_name = subjects;
            }
            subs.push(obj);
          });
          setSubject(subs);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const getallSubjectView = (sub, name, options) => {
    AxiosInstance.post("api/course/subject", { category_name: name, type: 1 })
      .then((res) => {
        if (res.data.data.length > 0) {
          // const subjects = res.data.data.map((item, index) => (
          //   <option id={index} key={index} value={item.subject}>
          //     {item.subject}
          //   </option>
          // ));
          const subjects = [];
          res.data.data.map((item, index) => {
            subjects.push({
              value: item.subject + "",
              label: item.subject + "",
            });
          });
          // setCate(subject);
          let subs = [];
          sub.map((obj) => {
            if (obj.category === name) {
              obj.subject_name = subjects;
              obj.optionSelected = options;
            }
            subs.push(obj);
          });
          setSubject(subs);
        } else {
          // const option = (
          //   <option key="select" value="select">
          //     Please select
          //   </option>
          // );
          const subjects = [];
          let subs = [];
          sub.map((obj) => {
            if (obj.category === name) {
              obj.subject_name = subjects;
            }
            subs.push(obj);
          });
          setSubject(subs);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (!userId) {
      const userJSON = localStorage.getItem("userDetails");
      if (userJSON) {
        const user = JSON.parse(userJSON);
        if (user && user.user_id) {
          const userid = user.user_id;
          const StepFive = {
            step: 5,
            user_id: userid,
            updated_by: userid,
          };
          // console.log(StepFive, "StepFive");
          if (list.Verify === true) {
            AxiosInstance.post("/api/staff/add", StepFive)
              .then((res) => {
                // console.log(res);
                if (res.data.status === true) {
                  Swal.fire({
                    title: "Success",
                    text: "Successfully",
                    icon: "Success",
                    confirmButtonText: "Ok",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/");
                    }
                  });
                } else {
                  Swal.fire({
                    title: "warning",
                    text: res.data.message,
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                }
              })
              .catch((error) => {
                return error;
              });
          } else {
            let err = "Please look and verify the Terms and Conditions.";
            txtadVerifyHere.current.focus();
            setListErrors({
              ...listErrors,
              Verify: err,
            });
          }
        }
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForms(list);
    // console.log(errors, "review and submit");
    if (Object.keys(errors).length === 0) {
      try {
        const userJSON = localStorage.getItem("userDetails");
        if (userJSON) {
          const user = JSON.parse(userJSON);
          if (user && user.user_id) {
            const userid = user.user_id;
            // console.log(userid,"userid" , userId);
            const reqdata = {
              user_id: userId ? userId : userid,
              updated_by: userid,
              status: parseInt(list.Adminstatus),
              comments: list.comments,
            };
            // console.log(reqdata);
            const response = await AxiosInstance.post(
              "api/staff/approval",
              reqdata
            );
            if (response.data.status === true) {
              Swal.fire({
                title: "Success",
                text: response.data.message,
                icon: "success",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/Faculties");
                }
              });
            } else {
              Swal.fire({
                title: "Failure",
                text: response.data.message,
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          }
        }
      } catch (error) {
        // console.log(error.response.data.message);
        Swal.fire({
          title: "Failure",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } else {
      setListErrors(errors);
    }
  };

  const handleCategoryChanges = (e, name) => {
    // const { name, value } = e.target;
    // console.log("radio select ", e.target.value);
    if (e.target.checked) {
      handleAddSubject(e.target.value);
      setCategory([...category, e.target.value]);
      setListErrors({
        ...listErrors,
        category: "",
      });
    } else {
      handleDelSubject(e.target.value);
      setCategory(category.filter((item) => item !== e.target.value));
    }
  };
  const handleChangeCheckbox = (e, recordID) => {
    let subs = [];
    //subject.filter((item) => item.id !== e.id);
    subject.map((obj, index) => {
      if (obj.id === recordID) {
        obj.optionSelected = e;
      }
      subs.push(obj);
    });
    setSubject(subs);
    setListErrors({
      ...listErrors,
      optionSelected: "",
    });
  };

  const handleAddSubject = (name) => {
    // console.log("Add subject", subject);
    let subs = [];
    subject.map((obj) => {
      subs.push(obj);
    });
    subs.push({
      id: subject.length + 1,
      category: "" + name,
      subject_name: [],
      optionSelected: null,
    });
    // setSubject(subs);
    getallSubject(subs, name);
  };

  const handleDelSubject = (name) => {
    //const { name, value } = e.target;
    // console.log("DEL ", name);
    let subs = subject.filter((item) => item.category !== name);
    subs.map((obj, index) => {
      obj.id = index + 1;
      subs[index] = obj;
    });
    setSubject(subs);
  };

  useEffect(() => {
    AxiosInstance.get("api/all/getDesignation/1")
      .then((res) => {
        const designation = res.data.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          ));
        setDesignation(designation);
      })
      .catch((error) => {
        return error;
      });
    AxiosInstance.post("api/branch/list")
      .then((res) => {
        // console.log(res,"locations")
        if (res.data.data.length > 0) {
          let locations = res.data.data.map((item, index) => (
            <option key={index} id={item.name} value={item.id}>
              {item.name}
            </option>
          ));
          setLocations(locations);
        } else {
          let locations = <option value="select"> Please select </option>;
          setLocations(locations);
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);

  // useEffect(() => {
  //   if (categoryJob.includes("UPSC") ) {
  //     AxiosInstance.post("api/course/subject",{"category_name":"UPSC"})
  //       .then((res) => {
  //         if (res.data.data.length > 0) {
  //           const subject = res.data.data.map((item) => (
  //             <option key={item.subject} value={item.subject}>
  //               {item.subject}
  //             </option>
  //           ));
  //           setSubjectDropdown(subject);
  //         } else {
  //           const option = (
  //             <option key="select" value="select">
  //               Please select
  //             </option>
  //           );
  //           setSubjectDropdown(option);
  //         }
  //       })
  //       .catch((error) => {
  //         return error;
  //       });
  //   }

  // }, [categoryJob.includes("UPSC")]);

  useEffect(() => {
    if (sslcCerficates[0].UGDegree !== "") {
      const bbb = {
        degree_name: sslcCerficates[0].UGDegree,
        type: "UG",
      };
      AxiosInstance.post(`api/all/getDegreeMajor/`, bbb)
        .then((res) => {
          if (res.data.data.length > 0) {
            // in response duplicate entry come means its showing extra data in next api call option
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            // const majorOptions = uniqueMajorData.map((item) => (
            //   <option key={item.name} value={item.name}>
            //     {item.name}
            //   </option>
            // ));
            const majorOptions = uniqueMajorData.map((item, index) => ({
              value: item.name,
              label: item.name,
            }));
            // const additionalOptions = [
            //   { value: "Others", label: "Others" },
            // ];
            // // Append the additional objects to the majorOptions array
            // const updatedMajorOptions = majorOptions.concat(additionalOptions);
            // // Set the state with the updated array
            setMajor(majorOptions);
          } else {
            setMajor(
              []
              //   [
              //   <option key="select" disabled value="select">
              //     Please select
              //   </option>,
              // ]
            );
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[0].UGDegree]);

  useEffect(() => {
    if (sslcCerficates[1].PGDegree !== "") {
      const bbbs = {
        degree_name: sslcCerficates[1].PGDegree,
        type: "PG",
      };
      AxiosInstance.post(`api/all/getDegreeMajor/`, bbbs)
        .then((res) => {
          if (res.data.data.length > 0) {
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            // const pgMajor = uniqueMajorData.map((item) => (
            //   <option key={item.name} value={item.name}>
            //     {item.name}
            //   </option>
            // ));
            const pgMajor = uniqueMajorData.map((item, index) => ({
              value: item.name,
              label: item.name,
            }));
            setPgMajor(pgMajor);
          } else {
            // const option = (
            //   <option key="select" value="select">
            //     Please select
            //   </option>
            // );
            setPgMajor([]);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[1].PGDegree]);

  useEffect(() => {
    if (sslcCerficates[2].IPDegree !== "") {
      const bbbss = {
        degree_name: sslcCerficates[2].IPDegree,
        type: "IPG",
      };
      AxiosInstance.post(`api/all/getDegreeMajor/`, bbbss)
        .then((res) => {
          if (res.data.data.length > 0) {
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            // const IpMajor = uniqueMajorData.map((item) => (
            //   <option key={item.name} value={item.name}>
            //     {item.name}
            //   </option>
            // ));
            const IpMajor = uniqueMajorData.map((item, index) => ({
              value: item.name,
              label: item.name,
            }));
            setIpMajor(IpMajor);
          } else {
            setIpMajor(
              []
              //   [
              //   <option key="select" disabled value="select">
              //     Please select
              //   </option>,
              // ]
            );
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[2].IPDegree]);

  useEffect(() => {
    if (sslcCerficates[3].PHDDegree !== "") {
      const bbbsss = {
        degree_name: sslcCerficates[3].PHDDegree,
        type: "phd",
      };
      AxiosInstance.post(`api/all/getDegreeMajor/`, bbbsss)
        .then((res) => {
          if (res.data.data.length > 0) {
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            // const PhMajor = uniqueMajorData.map((item) => (
            //   <option key={item.name} value={item.name}>
            //     {item.name}
            //   </option>
            // ));
            const PhMajor = uniqueMajorData.map((item, index) => ({
              value: item.name,
              label: item.name,
            }));
            setPhMajor(PhMajor);
          } else {
            // const option = (
            //   <option key="select" value="select">
            //     Please select
            //   </option>
            // );
            setPhMajor(null);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[3].PHDDegree]);

  useEffect(() => {
    if (sslcCerficates[4].DDegree !== "") {
      const bbbsss = {
        degree_name: sslcCerficates[4].DDegree,
        type: "diploma",
      };
      AxiosInstance.post(`api/all/getDegreeMajor/`, bbbsss)
        .then((res) => {
          if (res.data.data.length > 0) {
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            // const dmajor = uniqueMajorData.map((item) => (
            //   <option key={item.name} value={item.name}>
            //     {item.name}
            //   </option>
            // ));
            const dmajor = uniqueMajorData.map((item, index) => ({
              value: item.name,
              label: item.name,
            }));
            setDmajor(dmajor);
          } else {
            setDmajor(
              []
              //   [
              //   <option key="select" disabled value="select">
              //     Please select
              //   </option>,
              // ]
            );
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[4].DDegree]);

  useEffect(() => {
    if (sslcCerficates[5].PCDegree !== "") {
      const bbbsss = {
        degree_name: sslcCerficates[5].PCDegree,
        type: "PC",
      };
      AxiosInstance.post(`api/all/getDegreeMajor/`, bbbsss)
        .then((res) => {
          if (res.data.data.length > 0) {
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            // const pcMajor = uniqueMajorData.map((item) => (
            //   <option key={item.name} value={item.name}>
            //     {item.name}
            //   </option>
            // ));
            const pcMajor = uniqueMajorData.map((item, index) => ({
              value: item.name,
              label: item.name,
            }));
            setPcmajor(pcMajor);
          } else {
            setPcmajor(
              []
              //   [
              //   <option key="select" disabled value="select">
              //     Please select
              //   </option>,
              // ]
            );
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[5].PCDegree]);

  useEffect(() => {
    if (sslcCerficates[6].OtDegree !== "") {
      AxiosInstance.get(`api/all/getDegreeMajor/${sslcCerficates[6].OtDegree}`)
        .then((res) => {
          if (res.data.data.length > 0) {
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            // const OtMajor = uniqueMajorData.map((item) => (
            //   <option key={item.name} value={item.name}>
            //     {item.name}
            //   </option>
            // ));
            const OtMajor = uniqueMajorData.map((item, index) => ({
              value: item.name,
              label: item.name,
            }));
            setOTmajor(OtMajor);
          } else {
            // const option = (
            //   <option key="select" value="select">
            //     Please select
            //   </option>
            // );
            setOTmajor(null);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[6].OtDegree]);

  //major,designation , department , exam details , degree  dropdown
  useEffect(() => {
    AxiosInstance.get("api/all/getDegreeNames/UG")
      .then((res) => {
        // const ugDegree = res.data.data.map((item) => (
        //   <option key={item.name} value={item.name}>
        //     {item.name}
        //   </option>
        // ));
        const ugDegree = res.data.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setUGDegree(ugDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/PHD")
      .then((res) => {
        // const phDegree = res.data.data.map((item) => (
        //   <option key={item.name} value={item.name}>
        //     {item.name}
        //   </option>
        // ));
        const phDegree = res.data.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setPhDegree(phDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/PG")
      .then((res) => {
        // const pgDegree = res.data.data.map((item, index) => (
        //   <option key={index} value={item.name}>
        //     {item.name}
        //   </option>
        // ));
        const pgDegree = res.data.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setPGDegree(pgDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/IPG")
      .then((res) => {
        // const ipgDegree = res.data.data.map((item) => (
        //   <option key={item.name} value={item.name}>
        //     {item.name}
        //   </option>
        // ));
        const ipgDegree = res.data.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setIPGDegree(ipgDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/diploma")
      .then((res) => {
        // const diplomaDegree = res.data.data.map((item) => (
        //   <option key={item.name} value={item.name}>
        //     {item.name}
        //   </option>
        // ));
        const diplomaDegree = res.data.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setDiplomaDegree(diplomaDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/PC")
      .then((res) => {
        // const pcDegree = res.data.data.map((item) => (
        //   <option key={item.name} value={item.name}>
        //     {item.name}
        //   </option>
        // ));
        const pcDegree = res.data.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setPCDegree(pcDegree);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  //when pincode enter city dropdown , state , district  fill with ui
  useEffect(() => {
    if (list.PincodePresent !== "") {
      AxiosInstance.get(`api/all/getAddress/${list.PincodePresent}`)
        .then((res) => {
          let code = res.data.data;
          // const city = code.map((item, index) => (
          //   <option key={index} value={item.city}>
          //     {item.city}
          //   </option>
          // ));
          const city = code.map((item, index) => ({
            value: item.city,
            label: item.city,
          }));
          setCity(city);
          list.statePresent = code[0].state;
          list.districtPresent = code[0].district;
        })
        .catch((error) => {
          return error;
        });
    }
  }, [list.PincodePresent]);
  useEffect(() => {
    if (list.PincodePermanent !== "") {
      AxiosInstance.get(`api/all/getAddress/${list.PincodePermanent}`)
        .then((res) => {
          let code = res.data.data;
          const city = code.map((item, index) => ({
            value: item.city,
            label: item.city,
          }));
          setCity(city);
          list.statePermanent = code[0].state;
          list.districtPermanent = code[0].district;
        })
        .catch((error) => {
          return error;
        });
    }
  }, [list.PincodePermanent]);

  //dropdown api call
  useEffect(() => {
    // get response from api to fill dropdowns

    AxiosInstance.get("api/codevalues?codetype=EDQ")
      .then((res) => {
        const educationOptions = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setEducationOptions(educationOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=CLASS_EDQ")
      .then((res) => {
        const classOptions = res.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setClassOption(classOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=THQ")
      .then((res) => {
        const technicalOption = res.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        // Sort the options alphabetically, ignoring "Nil"
        const sortedOptions = technicalOption
          .filter((item) => item.value !== "Nil")
          .sort((a, b) => a.value.localeCompare(b.value));
        const NilOption = technicalOption
          .filter((item) => item.value === "Nil")
          .sort((a, b) => a.value.localeCompare(b.value));
        // Set the sorted list to the state
        sortedOptions.push(...NilOption);
        setTechnicalOption(sortedOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=CTGY")
      .then((res) => {
        const categoryOption = res.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setCategoryOption(categoryOption);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=ASP")
      .then((res) => {
        const specialPrefrence = res.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setSpecialPrefrence(specialPrefrence);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=CURS")
      .then((res) => {
        const cOption = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setCOption(cOption);
        setCatOption(res.data);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=MDP")
      .then((res) => {
        const teachMedium = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setTeachMedium(teachMedium);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  //when pincode enter city dropdown , state , district  fill with ui -- end

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;

    if (checked) {
      setEducationQualification([...educationQualification, value]);
      setListErrors({
        ...listErrors,
        EducationQualification: "",
      });
    } else {
      // alert("uncheck");
      const userJSON = localStorage.getItem("userDetails");
      if (userJSON) {
        const user = JSON.parse(userJSON);
        if (user && user.user_id) {
          const userid = user.user_id;
          AxiosInstance.post("api/course/admission/delete/qualifications", {
            user_id: userid,
            name: value,
            qualification_type: "EDQ",
          })
            .then((res) => {
              // console.log(res);
              if (res.status === 200) {
                setEducationQualification(
                  educationQualification.filter((item) => item !== value)
                );
                // Swal.fire({
                //   title: 'Success',
                //   text: "Deleted successfully",
                //   icon: 'success',
                //   confirmButtonText: 'Ok'
                // }).then((result) => {
                //   if (result.isConfirmed) {

                //   }
                // });
              }
            })
            .catch((error) => {
              return error;
            });
        }
      } else {
        console.log("User Id cannot avaliable in localstorge");
      }
    }
  };

  //technical qualification add function
  const AddQualification = () => {
    if (technicalOption.length > techQualification.length) {
      setTechQualification((prevState) => [
        ...prevState,
        {
          id: prevState.length > 0 ? prevState.length + 1 : 1,
          name: "",
          level: "",
          lang: "",
          Cnum: "",
          kComputer: "",
          grade: "",
          year: "",
          file: "",
          error_name: "",
          edu_medium: "",
          error_certificate_no: "",
          error_year_of_passing: "",
          error_level: "",
          error_grade: "",
          error_certificate_path: "",
        },
      ]);
    } else {
      Swal.fire({
        title: "Warning",
        text: "you reach more Technical Qualification",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  };
  //technical qualification delete function
  const DeleteQual = (id, name) => {
    const userJSON = localStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;
        AxiosInstance.post("api/course/admission/delete/qualifications", {
          user_id: userid,
          name: name,
          qualification_type: "THQ",
          id: id,
        })
          .then((res) => {
            if (res.status === 200) {
              const val = techQualification
                .filter((item) => item.id !== id)
                .map((item, index) => ({ ...item, id: index + 1 }));
              setTechQualification(val);

              // Swal.fire({
              //   title: 'Success',
              //   text: "Deleted successfully",
              //   icon: 'success',
              //   confirmButtonText: 'Ok'
              // }).then((result) => {
              //   if (result.isConfirmed) {

              //   }
              // });
            }
          })
          .catch((error) => {
            return error;
          });
      }
    } else {
      console.log("User Id cannot avaliable in localstorge");
    }
  };
  const setMaxMonthYearTec = (value) => {
    // console.log(value);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based

    // Format the current month and year as "yyyy-MM"
    const maxMonthYear = `${currentYear}-${currentMonth
      .toString()
      .padStart(2, "0")}`;
    // Parse the provided value into a JavaScript Date object
    const selectedDate = new Date(value);

    if (selectedDate > currentDate) {
      alert("Year of passing should be in past");
    } else {
      // Return the value because it's not in the future
      return value;
    }
  };

  const TechhandleChange_date = (date, id, name) => {
    const dateEYD = moment(date).format("YYYY-MM");
    if (setMaxMonthYearTec(dateEYD)) {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: dateEYD, ["error_year_of_passing"]: "" };
          }
          return q;
        });
      });
    }
  };
  const TechhandleChanges = (option, id, dname) => {
    if (dname === "name") {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, ["name"]: option.value, ["error_name"]: "" };
          }
          return q;
        });
      });
    } else {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return {
              ...q,
              ["kComputer"]: option.value,
              ["error_kComputer"]: "",
            };
          }
          return q;
        });
      });
    }
  };

  //technical qualification handle change  function
  const TechhandleChange = (e, id) => {
    const { name, value, files } = e.target;
    if (name === "file" && files && files.length > 0) {
      const TQ_path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("TQ_path", TQ_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setTechQualification((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      file: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2mb");
        }
      }
    } else if (name === "year") {
      if (setMaxMonthYearTec(value)) {
        setTechQualification((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              return { ...q, [name]: value, ["error_year_of_passing"]: "" };
            }
            return q;
          });
        });
      }
    } else if (name === "Cnum") {
      if (!/^[A-Za-z0-9]*$/.test(value)) {
        alert("Certificate number can only contain letters and numbers");
      } else {
        setTechQualification((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              return { ...q, [name]: value, ["error_certificate_no"]: "" };
            }
            return q;
          });
        });
      }
    } else if (name === "grade") {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value, ["error_grade"]: "" };
          }
          return q;
        });
      });
    } else if (name === "level") {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value, ["error_level"]: "" };
          }
          return q;
        });
      });
    } else if (name === "lang") {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value, ["error_edu_medium"]: "" };
          }
          return q;
        });
      });
    } else if (name === "kComputer") {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value, ["error_kComputer"]: "" };
          }
          return q;
        });
      });
    } else {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value };
          }
          return q;
        });
      });
    }
  };
  const setMaxMonthYear = (value, names) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based

    // Format the current month and year as "yyyy-MM"
    const maxMonthYear = `${currentYear}-${currentMonth
      .toString()
      .padStart(2, "0")}`;

    // Parse the provided value into a JavaScript Date object
    const selectedDate = new Date(value);

    if (selectedDate > currentDate) {
      setListErrors({
        ...listErrors,
        [names]: "Year of passing should be in past",
      });
    } else {
      // Return the value because it's not in the future
      return value;
    }
  };
  const handleEdus = (option, name, dname) => {
    if (name === "Integrated PG") {
      setUgOption((prevState) => ({
        ...prevState,
        ["Integrated_PG"]: option,
      }));
      setMOption((prevState) => ({
        ...prevState,
        ["IPMajor"]: "",
      }));
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === name) {
            return { ...q, [dname]: option.value };
          }
          return q;
        });
      });
    } else if (name === "Professional Courses") {
      setUgOption((prevState) => ({
        ...prevState,
        ["Professional_Courses"]: option,
      }));
      setMOption((prevState) => ({
        ...prevState,
        ["PCMajor"]: "",
      }));
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === name) {
            return { ...q, [dname]: option.value };
          }
          return q;
        });
      });
    } else if (name === "UG") {
      setUgOption((prevState) => ({
        ...prevState,
        ["UG"]: option,
      }));
      setMOption((prevState) => ({
        ...prevState,
        ["UGMajor"]: "",
      }));
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === name) {
            return { ...q, [dname]: option.value };
          }
          return q;
        });
      });
    } else if (name === "PG") {
      setUgOption((prevState) => ({
        ...prevState,
        ["PG"]: option,
      }));
      setMOption((prevState) => ({
        ...prevState,
        ["PGMajor"]: "",
      }));
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === name) {
            return { ...q, [dname]: option.value };
          }
          return q;
        });
      });
    } else if (name === "Diploma") {
      setUgOption((prevState) => ({
        ...prevState,
        ["Diploma"]: option,
      }));
      setMOption((prevState) => ({
        ...prevState,
        ["DMajor"]: "",
      }));
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === name) {
            return { ...q, [dname]: option.value };
          }
          return q;
        });
      });
    } else if (name === "phd") {
      setUgOption((prevState) => ({
        ...prevState,
        ["phd"]: option,
      }));
      setMOption((prevState) => ({
        ...prevState,
        ["PHDMajor"]: "",
      }));
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === name) {
            return { ...q, [dname]: option.value };
          }
          return q;
        });
      });
    } else {
      setUgOption((prevState) => ({
        ...prevState,
        [name]: option,
      }));
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === name) {
            return { ...q, [dname]: option.value };
          }
          return q;
        });
      });
    }
    setListErrors({
      ...listErrors,
      [dname]: "",
    });
  };

  const handleEduMajor = (option, name, dname) => {
    if (option.label === "Others" && name === "UG" && dname === "UGMajor") {
      setUGIsOther(true);
    } else if (
      option.label !== "Others" &&
      name === "UG" &&
      dname === "UGMajor"
    ) {
      setUGIsOther(false);
    } else if (
      option.label === "Others" &&
      name === "PG" &&
      dname === "PGMajor"
    ) {
      setPGIsOther(true);
    } else if (
      option.label !== "Others" &&
      name === "PG" &&
      dname === "PGMajor"
    ) {
      setPGIsOther(false);
    } else if (
      option.label === "Others" &&
      name === "Integrated PG" &&
      dname === "IPMajor"
    ) {
      setIPGIsOther(true);
    } else if (
      option.label !== "Others" &&
      name === "Integrated PG" &&
      dname === "IPMajor"
    ) {
      setIPGIsOther(false);
    } else if (
      option.label === "Others" &&
      name === "phd" &&
      dname === "PHDMajor"
    ) {
      setPHDIsOther(true);
    } else if (
      option.label !== "Others" &&
      name === "phd" &&
      dname === "PHDMajor"
    ) {
      setPHDIsOther(false);
    } else if (
      option.label === "Others" &&
      name === "Diploma" &&
      dname === "DMajor"
    ) {
      setDipIsOther(true);
    } else if (
      option.label !== "Others" &&
      name === "Diploma" &&
      dname === "DMajor"
    ) {
      setDipIsOther(false);
    } else if (
      option.label === "Others" &&
      name === "Professional Courses" &&
      dname === "PCMajor"
    ) {
      setPCIsOther(true);
    } else if (
      option.label !== "Others" &&
      name === "Professional Courses" &&
      dname === "PCMajor"
    ) {
      setPCIsOther(false);
    }
    setMOption((prevState) => ({
      ...prevState,
      [dname]: option,
    }));
    setSslcCerficates((prevState) => {
      return prevState.map((q) => {
        if (q.name === name) {
          return { ...q, [dname]: option.value };
        }
        return q;
      });
    });
    setListErrors({
      ...listErrors,
      [dname]: "",
    });
  };

  const handleEYDate = (dates, name, dname) => {
    const dateEYD = moment(dates).format("YYYY-MM");
    // console.log(name, "name", dname, dateEYD);
    if (setMaxMonthYear(dateEYD, name)) {
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === dname) {
            return { ...q, [name]: dateEYD };
          }
          return q;
        });
      });
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else {
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === dname) {
            return { ...q, [name]: "" };
          }
          return q;
        });
      });
    }
  };

  const handleYearChange_month = (year, dname) => {
    if (dname === "UGYearpassing") {
      if (
        sslcCerficates[0].UGYearpassing !== null &&
        sslcCerficates[0].UGYearpassing !== "" &&
        sslcCerficates[0].UGYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[0].UGYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "UG") {
                return { ...q, ["UGYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["UGYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "UG") {
                return { ...q, ["UGYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "UG") {
              return { ...q, ["UGYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["UGYearpassing"]: "",
        });
      }
    } else if (dname === "PGYearpassing") {
      if (
        sslcCerficates[1].PGYearpassing !== null &&
        sslcCerficates[1].PGYearpassing !== "" &&
        sslcCerficates[1].PGYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[1].PGYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "PG") {
                return { ...q, ["PGYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["PGYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "PG") {
                return { ...q, ["PGYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "PG") {
              return { ...q, ["PGYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["PGYearpassing"]: "",
        });
      }
    } else if (dname === "IPYearpassing") {
      if (
        sslcCerficates[2].IPYearpassing !== null &&
        sslcCerficates[2].IPYearpassing !== "" &&
        sslcCerficates[2].IPYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[2].IPYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Integrated PG") {
                return { ...q, ["IPYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["IPYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Integrated PG") {
                return { ...q, ["IPYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Integrated PG") {
              return { ...q, ["IPYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["IPYearpassing"]: "",
        });
      }
    } else if (dname === "PHDYearpassing") {
      if (
        sslcCerficates[3].PHDYearpassing !== null &&
        sslcCerficates[3].PHDYearpassing !== "" &&
        sslcCerficates[3].PHDYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[3].PHDYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "phd") {
                return { ...q, ["PHDYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["PHDYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "phd") {
                return { ...q, ["PHDYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "phd") {
              return { ...q, ["PHDYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["PHDYearpassing"]: "",
        });
      }
    } else if (dname === "DYearpassing") {
      if (
        sslcCerficates[4].DYearpassing !== null &&
        sslcCerficates[4].DYearpassing !== "" &&
        sslcCerficates[4].DYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[4].DYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Diploma") {
                return { ...q, ["DYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["DYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Diploma") {
                return { ...q, ["DYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Diploma") {
              return { ...q, ["DYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["DYearpassing"]: "",
        });
      }
    } else if (dname === "PCYearpassing") {
      if (
        sslcCerficates[5].PCYearpassing !== null &&
        sslcCerficates[5].PCYearpassing !== "" &&
        sslcCerficates[5].PCYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[5].PCYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Professional Courses") {
                return { ...q, ["PCYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["PCYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Professional Courses") {
                return { ...q, ["PCYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Professional Courses") {
              return { ...q, ["PCYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["PCYearpassing"]: "",
        });
      }
    } else if (dname === "OtYearpassing") {
      if (
        sslcCerficates[6].OtYearpassing !== null &&
        sslcCerficates[6].OtYearpassing !== "" &&
        sslcCerficates[6].OtYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[6].OtYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Others") {
                return { ...q, ["OtYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["OtYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Others") {
                return { ...q, ["OtYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Others") {
              return { ...q, ["OtYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["OtYearpassing"]: "",
        });
      }
    }
  };

  const handleMonthChange_month = (month, dname) => {
    if (dname === "UGYearpassing") {
      if (
        sslcCerficates[0].UGYearpassing !== null &&
        sslcCerficates[0].UGYearpassing !== "" &&
        sslcCerficates[0].UGYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[0].UGYearpassing);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        //  console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "UG") {
                return { ...q, ["UGYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["UGYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "UG") {
                return { ...q, ["UGYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "UG") {
              return { ...q, ["UGYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["UGYearpassing"]: "",
        });
      }
    } else if (dname === "PGYearpassing") {
      if (
        sslcCerficates[1].PGYearpassing !== null &&
        sslcCerficates[1].PGYearpassing !== "" &&
        sslcCerficates[1].PGYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[1].PGYearpassing);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        //  console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "PG") {
                return { ...q, ["PGYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["PGYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "PG") {
                return { ...q, ["PGYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "PG") {
              return { ...q, ["PGYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["PGYearpassing"]: "",
        });
      }
    } else if (dname === "IPYearpassing") {
      if (
        sslcCerficates[2].IPYearpassing !== null &&
        sslcCerficates[2].IPYearpassing !== "" &&
        sslcCerficates[2].IPYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[2].IPYearpassing);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        //  console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Integrated PG") {
                return { ...q, ["IPYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["IPYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Integrated PG") {
                return { ...q, ["IPYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Integrated PG") {
              return { ...q, ["IPYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["IPYearpassing"]: "",
        });
      }
    } else if (dname === "DYearpassing") {
      if (
        sslcCerficates[4].DYearpassing !== null &&
        sslcCerficates[4].DYearpassing !== "" &&
        sslcCerficates[4].DYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[4].DYearpassing);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        //  console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Diploma") {
                return { ...q, ["DYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["DYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Diploma") {
                return { ...q, ["DYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Diploma") {
              return { ...q, ["DYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["DYearpassing"]: "",
        });
      }
    } else if (dname === "PCYearpassing") {
      if (
        sslcCerficates[5].PCYearpassing !== null &&
        sslcCerficates[5].PCYearpassing !== "" &&
        sslcCerficates[5].PCYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[5].PCYearpassing);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        //  console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Professional Courses") {
                return { ...q, ["PCYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["PCYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Professional Courses") {
                return { ...q, ["PCYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Professional Courses") {
              return { ...q, ["PCYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["PCYearpassing"]: "",
        });
      }
    } else if (dname === "OtYearpassing") {
      if (
        sslcCerficates[6].OtYearpassing !== null &&
        sslcCerficates[6].OtYearpassing !== "" &&
        sslcCerficates[6].OtYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[6].OtYearpassing);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        //  console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Others") {
                return { ...q, ["OtYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["OtYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Others") {
                return { ...q, ["OtYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Others") {
              return { ...q, ["OtYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["OtYearpassing"]: "",
        });
      }
    }
  };

  // education cerficate handle change
  const handleEdu = (eduname, event) => {
    const names = event.target.name;
    const value = event.target.value;
    const files = event.target.files;

    if (names === "SSLC_Path" && files && files.length > 0) {
      const SSLC_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("SSLC_Path", SSLC_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            SSLC_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            SSLC_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          SSLC_Path: err,
        });
      }
    } else if (names === "PHD_Path" && files && files.length > 0) {
      const PHD_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("phd_path", PHD_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            PHD_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            PHD_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          PHD_Path: err,
        });
      }
    } else if (names === "HSC_Path" && files && files.length > 0) {
      const HSC_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("HSC_Path", HSC_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            HSC_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            HSC_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          HSC_Path: err,
        });
      }

      const UG_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("UG_Path", UG_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            UG_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            UG_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          UG_Path: err,
        });
      }
    } else if (names === "UG_Path" && files && files.length > 0) {
      const UG_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("UG_Path", UG_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            UG_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            UG_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          UG_Path: err,
        });
      }
    } else if (names === "PG_Path" && files && files.length > 0) {
      const PG_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("PG_Path", PG_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            PG_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            PG_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          PG_Path: err,
        });
      }
    } else if (names === "IP_Path" && files && files.length > 0) {
      const IP_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("IP_Path", IP_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            IP_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            IP_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          IP_Path: err,
        });
      }
    } else if (names === "PC_Path" && files && files.length > 0) {
      const PC_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("PC_Path", PC_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            PC_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            PC_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          PC_Path: err,
        });
      }
    } else if (names === "D_Path" && files && files.length > 0) {
      const D_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("D_Path", D_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            D_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            D_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          D_Path: err,
        });
      }
    } else if (names === "Ot_Path" && files && files.length > 0) {
      const Ot_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("Ot_Path", Ot_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            Ot_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            Ot_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          Ot_Path: err,
        });
      }
    } else if (names === "PG") {
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === eduname) {
            return { ...q, [names]: value };
          }
          return q;
        });
      });
      setListErrors({
        ...listErrors,
        [names]: "",
      });
    } else if (names === "PHDMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "PGMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "UGMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "IPMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "DMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "PCMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "OtMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (
      names === "UGYearpassing" ||
      names === "PGYearpassing" ||
      names === "IPYearpassing" ||
      names === "DYearpassing" ||
      names === "PCYearpassing" ||
      names === "PHDYearpassing" ||
      names === "OtYearpassing"
    ) {
      if (setMaxMonthYear(value, names)) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else {
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === eduname) {
            return { ...q, [names]: value };
          }
          return q;
        });
      });
      setListErrors({
        ...listErrors,
        [names]: "",
      });
    }
  };
  const handlePrevious = () => {
    setStep(step - 1);
  };
  //step handle next button click
  const handleNext = () => {
    const userJSON = localStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;

        const AdmissionRef_validation = {
          firstName: txtAdFname,
          lastName: txtAdLname,
          age: txtAdAge,
          mdob: txtAdMDob,
          mStatus: txtAdMStatus,
          gender: txtAdGender,
          father: txtAdFath,
          place_of_birth: txtAdPlaceBrith,
          spouse_name: txtAdSpouse,
          Occupation: txtAdOccupation,
          pSize: txtAdPSize,
          ano: txtAdAno,
          apath: txtAdApath,
          panno: txtAdpno,
          Ppath: txtAdPpath,
          pno: txtAdPno,
          wno: txtAdWno,
          fpo: txtAdFpo,
          email: txtAdEmail,
          phno: txtAdPhno,
          psname: txtAdPsname,
          parea: txtAdParea,
          pland: txtAdPland,
          ppost: txtAdPpost,
          ptaluk: txtAdPtaluk,
          ppincode: txtAdPpincode,
          pcity: txtAdPcity,
          pstate: txtAdPstate,
          pdistrict: txtAdPdistrict,
          perhno: txtAdPerhno,
          persname: txtAdPersname,
          perarea: txtAdPerarea,
          perland: txtAdPerland,
          pererpost: txtAdPererpost,
          pertaluk: txtAdPertaluk,
          perpincode: txtAdPerpincode,
          percity: txtAdPercity,
          perstate: txtAdPerstate,
          perdistrict: txtAdPerdistrict,
          education: txtAdEduc,
          eduUCer: txtAdUCer,
          eduUpass: txtAdUpass,
          eduUmarks: txtAdUmarks,
          eduUdeg: txtAdUdeg,
          eduUMaj: txtAdUMaj,
          eduUMajOther: txtAdUMajOther,
          eduPMajOther: txtAdPMajOther,
          eduPhdMajOther: txtAdPhdMajOther,
          eduIMajOther: txtAdIMajOther,
          eduDMajOther: txtAdDMajOther,
          eduCMajOther: txtAdCMajOther,
          eduUclass: txtAdUclass,
          eduUins: txtAdUins,
          eduUfile: txtAdUfile,
          eduPCer: txtAdPCer,
          eduPpass: txtAdPpass,
          eduPmarks: txtAdPmarks,
          eduPdeg: txtAdPdeg,
          eduPMaj: txtAdPMaj,
          eduPclass: txtAdPclass,
          eduPins: txtAdPins,
          eduPfile: txtAdPfile,
          eduPhdCer: txtAdPhdCer,
          eduPhdpass: txtAdPhdpass,
          eduPhdmarks: txtAdPhdmarks,
          eduPhddeg: txtAdPhddeg,
          eduPhdMaj: txtAdPhdMaj,
          eduPhdclass: txtAdPhdclass,
          eduPhdins: txtAdPhdins,
          eduPhdfile: txtAdPhdfile,
          eduICer: txtAdICer,
          eduIpass: txtAdIpass,
          eduImarks: txtAdImarks,
          eduIdeg: txtAdIdeg,
          eduIMaj: txtAdIMaj,
          eduIclass: txtAdIclass,
          eduIins: txtAdIins,
          eduIfile: txtAdIfile,
          eduDCer: txtAdDCer,
          eduDpass: txtAdDpass,
          eduDmarks: txtAdDmarks,
          eduDdeg: txtAdDdeg,
          eduDMaj: txtAdDMaj,
          eduDclass: txtAdDclass,
          eduDins: txtAdDins,
          eduDfile: txtAdDfile,
          eduCCer: txtAdCCer,
          eduCpass: txtAdCpass,
          eduCmarks: txtAdCmarks,
          eduCdeg: txtAdCdeg,
          eduCMaj: txtAdCMaj,
          eduCclass: txtAdCclass,
          eduCins: txtAdCins,
          eduCfile: txtAdCfile,
          eduOCer: txtAdOCer,
          eduOpass: txtAdOpass,
          eduOmarks: txtAdOmarks,
          eduOdeg: txtAdOdeg,
          eduOMaj: txtAdOMaj,
          eduOclass: txtAdOclass,
          eduOins: txtAdOins,
          eduOfile: txtAdOfile,
          spc: txtAdSpc,
          workingexp: txtAdWorkingexp,
          teachingexp: txtAdTeachingexp,
          VoiceOpinion: txtAdVoicepp,
          Teaching_ex_file: txtTfile,
          working_ex_file: txtWfile,
          location: txtfacLoc,
        };

        const errors = validateProfiles(
          list,
          sslcCerficates,
          educationQualification,
          step,
          AdmissionRef_validation,
          techQualification,
          setTechQualification
        );
        // console.log(errors);
        if (Object.keys(errors).length === 0) {
          // setStep(step + 1);
          if (step === 1) {
            const SteponeData = {
              step: 1,
              user_id: userId ? userId : userid,
              first_name: list.FirstName,
              last_name: list.LastName,
              father_name: list.Fathername,
              place_of_birth: list.PlaceBirth,
              dob: list.DOB,
              age: list.age,
              gender: list.gender,
              marital_status: list.MaritalStatus,
              spouse_name: list.Spouse,
              spouse_ocp: list.Occupation,
              profile_path: list.Profilephoto,
              aadhar_no: list.aadharNo,
              aadhar_path: list.aadharCardphoto,
              pan_no: list.panNo,
              pan_path: list.panPhoto,
              original_aadhar_path: list.original_aadhar_path,
              original_profile_path: list.original_profile_path,
              original_pan_path: list.original_pan_path,
              mobile_no: list.Mobile,
              country_code: list.MobileCode,
              whatsapp_no:
                list.ContactNumber === true ? list.Mobile : list.WhatsAppNumber,
              contact_type: list.ContactNumber === true ? "1" : "0",
              email: list.emailaddress,
              created_by: userid,
            };
            // console.log(SteponeData);
            AxiosInstance.post("/api/staff/add", SteponeData)
              .then((res) => {
                if (res.data.status === true) {
                  setStep(step + 1);
                } else {
                  Swal.fire({
                    title: "warning",
                    text: res.data.message,
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                }
              })
              .catch((error) => {
                return error;
              });
          } else if (step === 2) {
            const address = [
              {
                address1: list.BuildingNumberPresent,
                address2: list.StreetNamePresent,
                address3: list.AreaPresent,
                landmark: list.LandmarkPresent,
                post_name: list.PostPresent,
                taluk: list.TalukPresent,
                postal_code: list.PincodePresent,
                city: list.CityPresent,
                district_name: list.districtPresent,
                state_name: list.statePresent,
                same_address: list.SamePresentAddress == true ? "0" : "1",
                address_type: "1",
              },
              {
                address1:
                  list.SamePresentAddress == true
                    ? list.BuildingNumberPresent
                    : list.BuildingNumberPermanent,
                address2:
                  list.SamePresentAddress == true
                    ? list.StreetNamePresent
                    : list.StreetNamePermanent,
                address3:
                  list.SamePresentAddress == true
                    ? list.AreaPresent
                    : list.AreaPermanent,
                landmark:
                  list.SamePresentAddress == true
                    ? list.LandmarkPresent
                    : list.LandmarkPermanent,
                post_name:
                  list.SamePresentAddress == true
                    ? list.PostPresent
                    : list.PostPermanent,
                taluk:
                  list.SamePresentAddress == true
                    ? list.TalukPresent
                    : list.TalukPermanent,
                postal_code:
                  list.SamePresentAddress == true
                    ? list.PincodePresent
                    : list.PincodePermanent,
                city:
                  list.SamePresentAddress == true
                    ? list.CityPresent
                    : list.CityPermanent,
                district_name:
                  list.SamePresentAddress == true
                    ? list.districtPresent
                    : list.districtPermanent,
                state_name:
                  list.SamePresentAddress == true
                    ? list.statePresent
                    : list.statePermanent,
                same_address: list.SamePresentAddress == true ? "0" : "1",
                address_type: "2",
              },
            ];
            const StepTwoData = {
              step: 2,
              user_id: userId ? userId : userid,
              address: address,
              updated_by: userid,
            };
            // console.log(StepTwoData);
            AxiosInstance.post("/api/staff/add", StepTwoData)
              .then((res) => {
                // console.log(res);
                if (res.data.status === true) {
                  setStep(step + 1);
                } else {
                  Swal.fire({
                    title: "warning",
                    text: res.data.message,
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                }
              })
              .catch((error) => {
                return error;
              });
          } else if (step === 3) {
            const errors_thq = validateThq(
              techQualification,
              setTechQualification
            );
            if (
              errors_thq.length > 0 &&
              errors_thq.every(
                (el) =>
                  el.error_name === "" &&
                  el.error_certificate_no === "" &&
                  el.error_year_of_passing === "" &&
                  el.error_level === "" &&
                  el.error_grade === "" &&
                  el.error_kComputer === ""
              )
            ) {
              const filteredDetails = sslcCerficates.filter((detail) => {
                let cert = detail.name;
                if (detail.name === "UG") {
                  detail.UGMajor =
                    uGisOther === true ? detail.UGMajor_Other : detail.UGMajor;
                  detail.type_of_degree = uGisOther === true ? 1 : 0;
                } else if (detail.name === "PG") {
                  detail.PGMajor =
                    pGisOther === true ? detail.PGMajor_Other : detail.PGMajor;
                  detail.type_of_degree = pGisOther === true ? 1 : 0;
                } else if (detail.name === "Integrated PG") {
                  detail.IPMajor =
                    iPGisOther === true ? detail.IPMajor_Other : detail.IPMajor;
                  detail.type_of_degree = iPGisOther === true ? 1 : 0;
                } else if (detail.name === "phd") {
                  detail.PHDMajor =
                    pHDisOther === true
                      ? detail.PHDMajor_Other
                      : detail.PHDMajor;
                  detail.type_of_degree = pHDisOther === true ? 1 : 0;
                } else if (detail.name === "Diploma") {
                  detail.DMajor =
                    dipisOther === true ? detail.DMajor_Other : detail.DMajor;
                  detail.type_of_degree = dipisOther === true ? 1 : 0;
                } else if (detail.name === "Professional Courses") {
                  detail.PCMajor =
                    pCisOther === true ? detail.PCMajor_Other : detail.PCMajor;
                  detail.type_of_degree = pCisOther === true ? 1 : 0;
                } else if (detail.name === "Others") {
                  detail.type_of_degree = 1;
                }
                return educationQualification.includes(cert);
              });
              const TQ = techQualification.map((item) => ({
                id: item.id,
                name: item.name,
                level:
                  item.name !== "Knowledge in computer"
                    ? item.level
                    : item.level,
                lang: item.lang,
                Cnum: item.Cnum,
                kdg_in_com:
                  item.name === "Knowledge in computer" ? item.level : "",
                grade: item.grade,
                year: item.year,
                file: item.file,
                original_file_path:
                  item.original_file_path !== null &&
                  item.original_file_path !== "null"
                    ? item.original_file_path
                    : null,
                type_of_degree: 0,
              }));
              const StepThreeData = {
                user_id: userId ? userId : userid,
                updated_by: userid,
                step: 3,
                edu_details: filteredDetails,
                tech_qualification: TQ,
                special_qualification: list.spq,
              };
              // console.log(StepThreeData);
              AxiosInstance.post("/api/staff/add", StepThreeData)
                .then((res) => {
                  // console.log(res);
                  if (res.data.status === true) {
                    setStep(step + 1);
                  } else {
                    Swal.fire({
                      title: "warning",
                      text: res.data.message,
                      icon: "warning",
                      confirmButtonText: "Ok",
                    });
                  }
                })
                .catch((error) => {
                  return error;
                });
            }
          } else if (step === 4) {
            const StepFourData = {
              step: 4,
              user_id: userId ? userId : userid,
              experience: list.prevTeachexp === "Yes" ? 1 : 0,
              working_exp: list.workingexp,
              teaching_exp: "",
              working_exp_filepath: list.working_ex_file,
              original_workexp: list.original_workexp,
              original_teachexp: "",
              teaching_exp_filepath: "",
              staff_type: list.teachType,
              category: null,
              designation: list.designation,
              subject: [],
              subject_pk: [],
              aiasa_doj: list.DJoin,
              location: list.location,
              feedback: list.VoiceOpinion,
              updated_by: userid,
              created_by: userid,
            };
            // console.log(StepFourData, "StepFourData");

            AxiosInstance.post("/api/staff/add", StepFourData)
              .then((res) => {
                // console.log(res);
                if (res.data.status === true) {
                  setStep(step + 1);
                } else {
                  Swal.fire({
                    title: "warning",
                    text: res.data.message,
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                }
              })
              .catch((error) => {
                return error;
              });
          }
        } else {
          setListErrors(errors);
        }
      }
    } else {
      console.log("User Id cannot avaliable in localstorge");
    }
    // setStep(step + 1);
  };
  const handleStepPrevious = (count) => {
    setStep(count);
  };
  //dob convert to age function
  const DobCovertAge = (dob) => {
    var ageInYears = moment().diff(dob, "years", false);

    // const dobDate = new Date(dob);
    // const now = new Date();
    // const ageInMs = now - dobDate;
    // const ageInYears = Math.floor(ageInMs / 31536000000); // 1 year ≈ 31536000000 ms

    return ageInYears;
  };
  //handlechange event

  const handleChangeDate = (date, name) => {
    const dateDOB = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateDOB);
    const age = DobCovertAge(date);
    if (age >= 18 && age <= 60) {
      setList({
        ...list,
        [name]: dateDOB,
        ["age"]: age,
      });
      setListErrors({
        ...listErrors,
        [name]: "",
        ["age"]: "",
      });
    } else {
      const err =
        "Please select a DOB for autofill with an age between 18 and 60 years";
      setList({
        ...list,
        age: "",
      });
      setListErrors({
        ...listErrors,
        DOB: err,
      });
    }
  };

  const handleSelectCH = (option, dname) => {
    // console.log(option,dname);
    setList((prevState) => ({
      ...prevState,
      [dname]: option.value,
    }));

    setListErrors({
      ...listErrors,
      [dname]: "",
    });
  };
  const handleChangeCuDate = (dates, name) => {
    const dateSam = moment(dates).format("YYYY-MM-DD");
    setList((prevState) => ({
      ...prevState,
      [name]: dateSam,
    }));
    setListErrors({
      ...listErrors,
      [name]: "",
    });
  };
  const handleChange = (event) => {
    const { name, value, files, checked } = event.target;
    // console.log(value);
    if (name == "ContactNumber") {
      setList((prevList) => ({
        ...prevList,
        [name]: checked,
      }));
      setListErrors({
        ...listErrors,
        ["WhatsAppNumber"]: "",
      });
    } else if (name === "PincodePresent") {
      if (/^[1-9][0-9]{5}$/.test(value) || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
          ["CityPresent"]: "",
        }));
        setListErrors({
          ...listErrors,
          ["PincodePresent"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
          ["CityPresent"]: "",
        }));
        setListErrors({
          ...listErrors,
          ["PincodePresent"]: "Please Enter valid Pincode",
        });
        return;
      }
    } else if (name === "PincodePermanent") {
      if (/^[1-9][0-9]{5}$/.test(value) || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
          ["CityPermanent"]: "",
        }));
        setListErrors({
          ...listErrors,
          ["PincodePermanent"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
          ["CityPermanent"]: "",
        }));
        setListErrors({
          ...listErrors,
          ["PincodePermanent"]: "Please Enter valid Pincode",
        });
        return;
      }
    } else if (name === "Mobile") {
      if (value.length === 10 || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["Mobile"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["Mobile"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    } else if (name === "WhatsAppNumber") {
      if (value.length === 10 || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["WhatsAppNumber"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["WhatsAppNumber"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    } else if (name == "aadharNo") {
      if (value.length === 12 || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["aadharNo"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["aadharNo"]: "Please Enter valid 12 digit number",
        });
        return;
      }
    } else if (name == "panNo") {
      if (value.length === 10 || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["panNo"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["panNo"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    } else if (name == "SamePresentAddress") {
      setList((prevList) => ({
        ...prevList,
        [name]: checked,
      }));
    } else if (name === "Profilephoto" && files && files.length > 0) {
      const profile_path = files[0];
      // console.log(profile_path);
      if (
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("profile_path", profile_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                Profilephoto: res.data.path,
                original_profile_path: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                Profilephoto: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            Profilephoto: err,
          });
        }
      } else {
        let err = "(Png , jpg ,jpeg )images only allowed";
        setListErrors({
          ...listErrors,
          Profilephoto: err,
        });
      }
    } else if (name === "aadharCardphoto" && files && files.length > 0) {
      const aadhar_path = files[0];
      if (
        files[0].type == "application/pdf" ||
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("aadhar_path", aadhar_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                aadharCardphoto: res.data.path,
                original_aadhar_path: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                aadharCardphoto: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            aadharCardphoto: err,
          });
        }
      } else {
        let err = "pdf and img files only allowed";
        setListErrors({
          ...listErrors,
          aadharCardphoto: err,
        });
      }
    } else if (name === "panPhoto" && files && files.length > 0) {
      const pan_path = files[0];
      if (
        files[0].type == "application/pdf" ||
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("pan_path", pan_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                panPhoto: res.data.path,
                original_pan_path: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                panPhoto: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            panPhoto: err,
          });
        }
      } else {
        let err = "pdf and img files only allowed";
        setListErrors({
          ...listErrors,
          panPhoto: err,
        });
      }
    } else if (name == "Verify") {
      setList((prevList) => ({
        ...prevList,
        [name]: checked,
      }));
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else if (name == "categoryJob") {
      if (checked) {
        setCategoryJob([...categoryJob, value]);
      } else {
        setCategoryJob(categoryJob.filter((item) => item !== value));
      }
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else if (name == "USub") {
      const selectedOptions = Array.from(event.target.selectedOptions);
      const selectedValues = selectedOptions.map((option) => option.value);
      // console.log(selectedValues, "selectedValues");
      setList({
        ...list,
        [name]: selectedValues,
      });
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else if (name == "spq") {
      setList({
        ...list,
        [name]: value,
      });
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else if (name == "VoiceOpinion") {
      setList({
        ...list,
        [name]: value,
      });
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else if (name === "Adminstatus") {
      if (checked) {
        setList((prevFields) => ({
          ...prevFields,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          [name]: "",
        });
      }
    } else if (name === "working_ex_file" && files && files.length > 0) {
      const working_ex_file = files[0];
      if (
        files[0].type == "application/pdf" ||
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("faculty_exp", working_ex_file);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                working_ex_file: res.data.path,
                original_workexp: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                working_ex_file: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            working_ex_file: err,
          });
        }
      } else {
        let err = "pdf and img files only allowed";
        setListErrors({
          ...listErrors,
          working_ex_file: err,
        });
      }
    } else if (name === "Teaching_ex_file" && files && files.length > 0) {
      const Teaching_ex_file = files[0];
      if (
        files[0].type == "application/pdf" ||
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("faculty_exp", Teaching_ex_file);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                Teaching_ex_file: res.data.path,
                original_teachexp: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                Teaching_ex_file: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            Teaching_ex_file: err,
          });
        }
      } else {
        let err = "pdf and img files only allowed";
        setListErrors({
          ...listErrors,
          Teaching_ex_file: err,
        });
      }
    } else {
      setList({
        ...list,
        [name]: value,
      });
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    }
  };
  const validateThq = (techQualification, setTechQualification) => {
    if (techQualification && techQualification.length > 0) {
      const updateTHQ = techQualification.map((OB) => {
        let updatedOB = { ...OB };
        // console.log(techQualification,"techQualification");
        if (!OB.name) {
          updatedOB.error_name = "Name is required";
        }
        if (OB.name === "Nil") {
          updatedOB.error_name = "";
          updatedOB.error_level = "";
          updatedOB.error_edu_medium = "";
          updatedOB.error_certificate_no = "";
          updatedOB.error_year_of_passing = "";
          updatedOB.error_grade = "";
          updatedOB.error_kComputer = "";
        }
        if (!OB.level && OB.name !== "Nil") {
          updatedOB.error_level = "Level is required";
        }

        // if (!OB.kComputer && OB.name !== "Nil") {
        //   updatedOB.error_kComputer = "Knowlege of computer is required";
        // }

        if (!OB.lang && OB.name !== "Nil") {
          updatedOB.error_edu_medium = "Lang is required";
        }

        if (!OB.Cnum && OB.name !== "Nil") {
          updatedOB.error_certificate_no = "Certificate No is required";
        }

        if (!OB.year && OB.name !== "Nil") {
          updatedOB.error_year_of_passing = "Year of passing is required";
        }

        if (!OB.grade && OB.name !== "Nil") {
          updatedOB.error_grade = "Grade is required";
        }

        return updatedOB;
      });
      setTechQualification(updateTHQ);
      return updateTHQ;
    }
  };
  //  front end
  return (
    <div className="FProfile">
      {userId ? (
        <h4 className="Title">Faculty Profile</h4>
      ) : (
        <h4 className="Title">My Profile</h4>
      )}
      <div className="row progressTitle">
        <div className={`col-md-2 col-sm-2 col-2 col_progress  `}>
          <div
            className={`${
              step === 1 ||
              step === 2 ||
              step === 3 ||
              step === 4 ||
              step === 5 ||
              step === 6 ||
              step === 7
                ? "Card"
                : "  Cards"
            }`}
          >
            <p
              className="circle"
              onClick={
                step === 2 ||
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6 ||
                step === 7
                  ? () => handleStepPrevious(1) // Wrap the call in an arrow function
                  : handleNext
              }
            >
              {step === 2 ||
              step === 3 ||
              step === 4 ||
              step === 5 ||
              step === 6 ||
              step === 7
                ? "✓"
                : "1"}
            </p>
            <p className="cTitle fw-semibold">Personal Profile</p>
            <p className="cSubtitle">Define Your Identity</p>
            <span
              className={`${
                step === 2 ||
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6 ||
                step === 7
                  ? "Stepborders"
                  : "Stepborder"
              } display_none_Stepborder`}
            ></span>
          </div>
        </div>
        <div className={`col-md-2 col-sm-2 col-2  `}>
          <div
            className={`${
              step === 2 ||
              step === 3 ||
              step === 4 ||
              step === 5 ||
              step === 6 ||
              step === 7
                ? "Card ProMobile"
                : "  Cards"
            }`}
          >
            <p
              className="circle"
              onClick={
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6 ||
                step === 7
                  ? () => handleStepPrevious(2) // Wrap the call in an arrow function
                  : handleNext
              }
            >
              {step === 3 ||
              step === 4 ||
              step === 5 ||
              step === 6 ||
              step === 7
                ? "✓"
                : "2"}
            </p>
            <p className="cTitle ">Communication</p>
            <p className="cSubtitle">Stay Connected Always</p>
            <span
              className={`${
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6 ||
                step === 7
                  ? "Stepborders"
                  : "Stepborder"
              } display_none_Stepborder`}
            ></span>
          </div>
        </div>
        <div className={`col-md-3 col-sm-3 col-3 `}>
          <div
            className={`${
              step === 3 || step === 4 || step === 5 || step === 6 || step === 7
                ? "Card ProMobile"
                : "  Cards"
            }`}
          >
            <p
              className="circle"
              onClick={
                step === 4 || step === 5 || step === 6 || step === 7
                  ? () => handleStepPrevious(3) // Wrap the call in an arrow function
                  : handleNext
              }
            >
              {step === 4 || step === 5 || step === 6 || step === 7 ? "✓" : "3"}
            </p>
            <p className="cTitle ">Academic Background</p>
            <p className="cSubtitle ">Your Learning Journey</p>
            <span
              className={`${
                step === 4 || step === 5 || step === 6 || step === 7
                  ? "Stepborders"
                  : "Stepborder"
              } display_none_Stepborder`}
            ></span>
          </div>
        </div>
        <div className={`col-md-2 col-sm-2 col-2 col_end_progress `}>
          <div
            className={`${
              step === 4 || step === 5 || step === 6 || step === 7
                ? "Card ProMobile"
                : "  Cards"
            }`}
          >
            <p
              className="circle"
              onClick={
                step === 5 || step === 6 || step === 7
                  ? () => handleStepPrevious(4) // Wrap the call in an arrow function
                  : handleNext
              }
            >
              {step === 5 || step === 6 || step === 7 ? "✓" : "4"}
            </p>
            <p className="cTitle ">Employment Details</p>
            <p className="cSubtitle ">Career Snapshot</p>
            <span
              className={`${
                step === 5 || step === 6 || step === 7
                  ? "Stepborders"
                  : "Stepborder"
              } display_none_Stepborder`}
            ></span>
          </div>
        </div>

        <div className={`col-md-3 col-sm-3 col-3 col_end_progress `}>
          <div
            className={`${
              step === 5 || step === 6 || step === 7 ? "Card" : "  Cards"
            }`}
          >
            <p
              className="circle"
              onClick={
                step === 6 || step === 7
                  ? () => handleStepPrevious(5) // Wrap the call in an arrow function
                  : handleNext
              }
            >
              {step === 6 || step === 7 ? "✓" : "5"}
            </p>
            <p className="cTitle ">Review and Confirm</p>
            <p className="cSubtitle ">Review Your Details</p>
            {/* <span
                      className={`${
                        step === 5 || step === 6 ? "Stepborders" : "Stepborder"
                      } display_none_Stepborder`}
                    ></span> */}
          </div>
        </div>
        {/* <div
                  className="col-md-2 col-sm-12" >
                </div> */}
      </div>
      {step === 1 && (
        <fieldset>
          <div className="row">
            <div className="col-md-12">
              <section className="StepOne ">
                <div className="heading  d-flex justify-content-end ">
                  <button
                    type="button"
                    className="SaveButton"
                    onClick={handleNext}
                  >
                    <a href="javascript:void(0)">
                      Save and Continue to Communication
                    </a>
                  </button>
                </div>
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Basic Details</p>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="FirstName">First Name</label>
                          <input
                            ref={txtAdFname}
                            id="FirstName"
                            type="text"
                            name="FirstName"
                            className="Inputs"
                            onChange={handleChange}
                            placeholder="First Name"
                            value={list.FirstName}
                            autoComplete="FirstName"
                            minLength={5}
                            maxLength={15}
                            required
                          />
                          {listErrors.FirstName && (
                            <p className="errors">{listErrors.FirstName}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="LastName">Last Name</label>
                          <input
                            ref={txtAdLname}
                            id="LastName"
                            type="text"
                            name="LastName"
                            placeholder=" Last Name"
                            className="Inputs"
                            minLength={5}
                            maxLength={15}
                            onChange={handleChange}
                            value={list.LastName}
                            autoComplete="LastName"
                            required
                          />
                          {listErrors.LastName && (
                            <p className="errors">{listErrors.LastName}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="Fathername">Father's Name</label>
                          <input
                            ref={txtAdFath}
                            id="Fathername"
                            type="text"
                            name="Fathername"
                            placeholder=" Fathers Name"
                            onChange={handleChange}
                            value={list.Fathername}
                            className="Inputs"
                            minLength={5}
                            maxLength={15}
                            autoComplete="Fathername"
                            required
                          />
                          {listErrors.Fathername && (
                            <p className="errors">{listErrors.Fathername}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="DOB">Date of Birth</label>
                          {/* <input
                              ref={txtAdMDob}
                              id="DOB"
                              type="date"
                              name="DOB"
                              onChange={handleChange}
                              value={list.DOB}
                              className="Inputs"
                              autoComplete="DOB"
                              required
                            /> */}
                          <DatePickerSample
                            txtAdMDob={txtAdMDob}
                            name={"DOB"}
                            handleChange={handleChangeDate}
                            fieldInput={list.DOB}
                          />
                          {listErrors.DOB && (
                            <p className="errors">{listErrors.DOB}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="age">Age</label>
                          <input
                            ref={txtAdAge}
                            id="age"
                            type="text"
                            readOnly
                            name="age"
                            onChange={handleChange}
                            value={list.age}
                            className="Inputs"
                            placeholder="Age"
                            autoComplete="age"
                            required
                          />
                          {listErrors.age && (
                            <p className="errors">{listErrors.age}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="PlaceBirth">Place of Birth</label>
                          <input
                            ref={txtAdPlaceBrith}
                            id="PlaceBirth"
                            type="text"
                            name="PlaceBirth"
                            onChange={handleChange}
                            value={list.PlaceBirth}
                            className="Inputs"
                            placeholder="Place of Birth"
                            autoComplete="Place of Birth"
                            required
                          />
                          {listErrors.PlaceBirth && (
                            <p className="errors">{listErrors.PlaceBirth}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <div className="forms row  ">
                          <label htmlFor="Gender">Gender</label>
                          <div className="d-flex  col-md-12" id="radiobuttons">
                            <div className="form-check form-check-inline Mobileform-check-inline">
                              <input
                                ref={txtAdGender}
                                className="checkbox_radio"
                                type="radio"
                                name="gender"
                                onChange={handleChange}
                                checked={list.gender === "Male" ? true : false}
                                id="Male"
                                value="Male"
                              />
                              <label className="checkbox-label" htmlFor="Male">
                                Male
                              </label>
                            </div>
                            <div className="form-check form-check-inline Mobileform-check-inline">
                              <input
                                className="checkbox_radio"
                                type="radio"
                                name="gender"
                                id="Female"
                                onChange={handleChange}
                                checked={
                                  list.gender === "Female" ? true : false
                                }
                                value="Female"
                              />
                              <label
                                className="checkbox-label"
                                htmlFor="Female"
                              >
                                Female
                              </label>
                            </div>
                            <div className="form-check form-check-inline Mobileform-check-inline">
                              <input
                                className="checkbox_radio"
                                type="radio"
                                name="gender"
                                id="Transgender"
                                onChange={handleChange}
                                checked={
                                  list.gender === "Transgender" ? true : false
                                }
                                value="Transgender"
                              />
                              <label
                                className="checkbox-label"
                                htmlFor="Transgender"
                              >
                                Transgender
                              </label>
                            </div>
                          </div>
                        </div>
                        {listErrors.gender && (
                          <small className="errors fw-semibold">
                            {listErrors.gender}
                          </small>
                        )}
                      </div>
                      {/* AIASA-000116  - v1 issues solved by lalitha */}
                      <div className="col-md-6">
                        <div className="forms row  ">
                          <label htmlFor="MaritalStatus">Marital Status</label>
                          <div
                            ref={txtAdMStatus}
                            className="d-flex ms-4  col-md-12"
                            id="radiobuttons"
                          >
                            <div className="form-checks form-check-inline">
                              <input
                                className="checkbox_radio"
                                type="radio"
                                name="MaritalStatus"
                                onChange={handleChange}
                                checked={
                                  list.MaritalStatus === "Single/Unmarried"
                                    ? true
                                    : false
                                }
                                id="Single/Unmarried"
                                value="Single/Unmarried"
                              />
                              <label
                                className="checkbox-label"
                                htmlFor="Single/Unmarried"
                              >
                                Single/Unmarried
                              </label>
                            </div>
                            <div className="form-checks form-check-inline">
                              <input
                                className="checkbox_radio"
                                type="radio"
                                name="MaritalStatus"
                                id="Married"
                                onChange={handleChange}
                                checked={
                                  list.MaritalStatus === "Married"
                                    ? true
                                    : false
                                }
                                value="Married"
                              />
                              <label
                                className="checkbox-label"
                                htmlFor="Married"
                              >
                                Married
                              </label>
                            </div>

                            <div className="form-checks form-check-inline">
                              <input
                                className="checkbox_radio"
                                type="radio"
                                name="MaritalStatus"
                                id="Widowed"
                                onChange={handleChange}
                                checked={
                                  list.MaritalStatus === "Widowed"
                                    ? true
                                    : false
                                }
                                value="Widowed"
                              />
                              <label
                                className="checkbox-label"
                                htmlFor="Widowed"
                              >
                                Widowed
                              </label>
                            </div>
                          </div>
                        </div>
                        {listErrors.MaritalStatus && (
                          <small className="errors fw-semibold">
                            {listErrors.MaritalStatus}
                          </small>
                        )}
                      </div>
                    </div>
                    {(list.MaritalStatus !== "Single/Unmarried" ||
                      list.MaritalStatus !== "Widowed") && (
                      <div className="row  mb-4">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="Spouse">Name of the Spouse</label>
                            <input
                              ref={txtAdSpouse}
                              id="Spouse"
                              type="text"
                              name="Spouse"
                              onChange={handleChange}
                              value={list.Spouse}
                              className="Inputs"
                              placeholder="Name of the Spouse"
                              autoComplete="Spouse"
                              required
                            />
                            {listErrors.Spouse && (
                              <p className="errors">{listErrors.Spouse}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="Occupation">
                              Occupation of the Spouse
                            </label>
                            <input
                              ref={txtAdOccupation}
                              id="Occupation"
                              type="text"
                              name="Occupation"
                              onChange={handleChange}
                              value={list.Occupation}
                              className="Inputs"
                              placeholder="Occupation of the Spouse"
                              autoComplete="Occupation"
                              required
                            />
                            {listErrors.Occupation && (
                              <p className="errors">{listErrors.Occupation}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
              <section className="sectionThree">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Contact Details</p>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="Mobile">Phone Number</label>
                          <input
                            ref={txtAdPno}
                            id="Mobile"
                            type="tel"
                            name="Mobile"
                            onChange={handleChange}
                            value={list.Mobile}
                            className="Inputs_Phone"
                            placeholder="Phone Number"
                            autoComplete="Mobile"
                            required
                            minLength={10}
                            maxLength={10}
                            inputMode="tel"
                          />
                          {listErrors.Mobile && (
                            <p className="errors">{listErrors.Mobile}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <div className="d-flex justify-content-between">
                            <label htmlFor="WhatsAppNumber ">
                              WhatsApp Number
                            </label>
                            <label>
                              <input
                                className="form-check-input me-2 align-text-center"
                                type="checkbox"
                                id="ContactNumber"
                                name="ContactNumber"
                                onChange={handleChange}
                                value={list.ContactNumber}
                                checked={
                                  list.ContactNumber === true ? true : false
                                }
                              />
                              <span
                                className="fom-check-label"
                                htmlFor="ContactNumber"
                              >
                                Same as Contact Number
                              </span>
                            </label>
                          </div>
                          <input
                            ref={txtAdWno}
                            id="WhatsAppNumber"
                            type="tel"
                            name="WhatsAppNumber"
                            placeholder="WhatsApp Number"
                            minLength={10}
                            maxLength={10}
                            className="Inputs_Phone"
                            onChange={handleChange}
                            value={
                              list.ContactNumber !== true
                                ? list.WhatsAppNumber
                                : list.Mobile
                            }
                            autoComplete="WhatsAppNumber"
                            required
                            inputMode="tel"
                          />
                          {listErrors.WhatsAppNumber && (
                            <p className="errors">
                              {listErrors.WhatsAppNumber}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="emailaddress">Email ID</label>
                          <input
                            ref={txtAdEmail}
                            id="emailaddress"
                            type="email"
                            disabled
                            readOnly
                            name="emailaddress"
                            className="Inputs"
                            onChange={handleChange}
                            value={list.emailaddress}
                            placeholder=" Email ID"
                            autoComplete="emailaddress"
                            required
                          />
                          {listErrors.emailaddress && (
                            <p className="errors">{listErrors.emailaddress}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="sectionTwo ">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Passport Size Photograph</p>
                    <div className="row">
                      <div className="col-md-6 d-flex  justify-content-center">
                        <div className="forms">
                          <img
                            className="ProfilePhoto  bg-opacity-75"
                            src={`${ImageUrlPrefix}/${list.Profilephoto}`}
                            alt="profile"
                          />

                          {!list.Profilephoto && (
                            <span id="text">Profile Picture</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="Profilephoto">
                            Upload Passport Size Photograph
                          </label>
                          <input
                            ref={txtAdPSize}
                            id="Profilephoto"
                            type="file"
                            name="Profilephoto"
                            onChange={handleChange}
                            className="InputsFile"
                            autoComplete="Profilephoto"
                            required
                          />
                          {listErrors.Profilephoto && (
                            <p className="errors fw-semibold">
                              {listErrors.Profilephoto}
                            </p>
                          )}
                        </div>

                        <div className="forms mt-4 ms-md-3 mb-4">
                          <h6 className="profileText">Note:</h6>
                          <p className="warningOne my-2">
                            Image format: The recommended image format for
                            profile pictures on Anna IAS Academy is JPEG or PNG.
                          </p>
                          <h6 className="warningTwo">
                            <span className="fw-semibold">Image size</span>:
                            Profile pictures should be a minimum of 400x400
                            pixels and a maximum of 1024x1024 pixels. The file
                            size should not exceed 2 MB.
                          </h6>
                          <h6 className="warningTwo">
                            <span className="fw-semibold">
                              Image quality and content
                            </span>
                            : Choose a clear and high-quality image for your
                            profile picture. Your profile picture should be
                            appropriate and professional.
                          </h6>
                          <h6 className="warningTwo">
                            <span className="fw-semibold">
                              Image orientation
                            </span>
                            :Profile pictures should be in a square format, as
                            they will be displayed as a circle on the platform.
                            Make sure your image is not stretched or distorted.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="sectionTwo">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">ID Proofs</p>

                    <div className="row ">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="aadharNo">
                            AADHAR Number
                            <span
                              data-required="true"
                              aria-hidden="true"
                            ></span>
                          </label>
                          <input
                            ref={txtAdAno}
                            id="aadharNo"
                            type="number"
                            name="aadharNo"
                            onChange={handleChange}
                            value={list.aadharNo < 0 ? 0 : list.aadharNo}
                            className="Inputs"
                            placeholder="Aadhar Number"
                            autoComplete="aadharNo"
                            required
                          />
                          {listErrors.aadharNo && (
                            <p className="errors">{listErrors.aadharNo}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="forms ">
                          <label htmlFor="aadharCardphoto">
                            Upload AADHAR Card (In pdf/Img format)
                            {list.aadharCardphoto !== "" &&
                              list.aadharCardphoto !== null && (
                                <a
                                  href={`${ImageUrlPrefix}/${list.aadharCardphoto}`}
                                  target="_blank"
                                  download
                                >
                                  <span className="material-icons align-middle download_icon">
                                    file_download
                                  </span>
                                </a>
                              )}
                          </label>
                          <div className="row">
                            <div
                              className={
                                list.aadharCardphoto !== "" &&
                                list.aadharCardphoto !== null
                                  ? "col-md-3 "
                                  : "col-md-12"
                              }
                            >
                              <input
                                ref={txtAdApath}
                                id="aadharCardphoto"
                                type="file"
                                name="aadharCardphoto"
                                placeholder="Upload AADHAR Card"
                                className={
                                  list.aadharCardphoto !== "" &&
                                  list.aadharCardphoto !== null
                                    ? "InputsFile nn_upload_width"
                                    : "InputsFile  vv_upload_width"
                                }
                                onChange={handleChange}
                                autoComplete="aadharCardphoto"
                                required
                              />
                            </div>
                            {list.aadharCardphoto !== "" &&
                              list.aadharCardphoto !== null && (
                                <div
                                  className={
                                    "col-md-9 d-flex align-content-center p-0"
                                  }
                                >
                                  <span className="nn_upload_file">
                                    {list.aadharCardphoto !== "" &&
                                    list.aadharCardphoto !== null
                                      ? list.original_aadhar_path
                                      : ""}
                                  </span>
                                  <span className="material-icons align-middle text-danger verified_upload">
                                    verified
                                  </span>
                                </div>
                              )}
                          </div>

                          {listErrors.aadharCardphoto && (
                            <p className="errors">
                              {listErrors.aadharCardphoto}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="panNo">
                            PAN Number
                            <span
                              data-required="true"
                              aria-hidden="true"
                            ></span>
                          </label>
                          <input
                            ref={txtAdpno}
                            id="panNo"
                            type="text"
                            name="panNo"
                            onChange={handleChange}
                            value={list.panNo < 0 ? 0 : list.panNo}
                            className="Inputs"
                            placeholder="PAN Number"
                            autoComplete="panNo"
                            required
                          />
                          {listErrors.panNo && (
                            <p className="errors">{listErrors.panNo}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="forms ">
                          <label htmlFor="aadharCardphoto">
                            Upload PAN Card (In pdf format)
                            {list.panPhoto !== "" && list.panPhoto !== null && (
                              <a
                                href={`${ImageUrlPrefix}/${list.panPhoto}`}
                                target="_blank"
                                download
                              >
                                <span className="material-icons align-middle download_icon">
                                  file_download
                                </span>
                              </a>
                            )}
                          </label>

                          <div className="row">
                            <div
                              className={
                                list.panPhoto !== "" && list.panPhoto !== null
                                  ? "col-md-3 "
                                  : "col-md-12"
                              }
                            >
                              <input
                                ref={txtAdPpath}
                                id="panPhoto"
                                type="file"
                                name="panPhoto"
                                placeholder="Upload pan Card"
                                className={
                                  list.panPhoto !== "" && list.panPhoto !== null
                                    ? "InputsFile nn_upload_width"
                                    : "InputsFile  "
                                }
                                onChange={handleChange}
                                autoComplete="panPhoto"
                                required
                              />
                            </div>
                            {list.panPhoto !== "" && list.panPhoto !== null && (
                              <div
                                className={
                                  "col-md-9 d-flex align-content-center p-0"
                                }
                              >
                                <span className="nn_upload_file">
                                  {list.panPhoto !== "" &&
                                  list.panPhoto !== null
                                    ? list.original_pan_path
                                    : ""}
                                </span>
                                <span className="material-icons align-middle text-danger verified_upload">
                                  verified
                                </span>
                              </div>
                            )}
                          </div>

                          {listErrors.panPhoto && (
                            <p className="errors">{listErrors.panPhoto}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="heading  d-flex justify-content-end ">
                  <button
                    type="button"
                    className="SaveButton"
                    onClick={handleNext}
                  >
                    <a href="javascript:void(0)">
                      Save and Continue to Communication
                    </a>
                  </button>
                </div>
              </section>
            </div>
          </div>
        </fieldset>
      )}
      {step === 2 && (
        <fieldset>
          <div className="row">
            <div className="col-md-12">
              <section className="sectionFour">
                <div className="heading  dflexbetww  ">
                  <div className="">
                    <button
                      type="button"
                      className="SaveButton_Prev"
                      onClick={handlePrevious}
                    >
                      <a href="javascript:void(0)">Go to Personal Details</a>
                    </button>
                  </div>
                  <div className="d-flex smButton mobiledown">
                    <Link to="/" className="CancelButton mb-sm-4">
                      Back to Home
                    </Link>
                    <button
                      type="button"
                      className="SaveButtons"
                      onClick={handleNext}
                    >
                      <a href="javascript:void(0)">
                        {" "}
                        Save and Continue to Academic Background
                      </a>
                    </button>
                  </div>
                </div>
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Present Address</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="BuildingNumberPresent">
                            House/Flat Number & Name
                          </label>
                          <input
                            ref={txtAdPhno}
                            id="BuildingNumberPresent"
                            type="text"
                            name="BuildingNumberPresent"
                            className="Inputs"
                            onChange={handleChange}
                            value={list.BuildingNumberPresent}
                            placeholder=" House/Building Number"
                            autoComplete="BuildingNumberPresent"
                            required
                          />
                          {listErrors.BuildingNumberPresent && (
                            <p className="errors">
                              {listErrors.BuildingNumberPresent}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <div className="forms ">
                            <label htmlFor="StreetNamePresent">
                              Street Name
                            </label>
                            <input
                              ref={txtAdPsname}
                              id="StreetNamePresent"
                              type="text"
                              name="StreetNamePresent"
                              className="Inputs"
                              onChange={handleChange}
                              value={list.StreetNamePresent}
                              placeholder="Street Name"
                              autoComplete="StreetNamePresent"
                              required
                            />
                            {listErrors.StreetNamePresent && (
                              <p className="errors">
                                {listErrors.StreetNamePresent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="AreaPresent">Locality/Area</label>
                          <input
                            ref={txtAdParea}
                            id="AreaPresent"
                            type="text"
                            name="AreaPresent"
                            className="Inputs"
                            onChange={handleChange}
                            value={list.AreaPresent}
                            placeholder="Locality/Area"
                            autoComplete="AreaPresent"
                            required
                          />
                          {listErrors.AreaPresent && (
                            <p className="errors">{listErrors.AreaPresent}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="LandmarkPresent">Landmark</label>
                          <input
                            ref={txtAdPland}
                            id="LandmarkPresent"
                            type="text"
                            name="LandmarkPresent"
                            className="Inputs"
                            onChange={handleChange}
                            value={list.LandmarkPresent}
                            placeholder=" Landmark"
                            autoComplete="LandmarkPresent"
                            required
                          />
                          {listErrors.LandmarkPresent && (
                            <p className="errors">
                              {listErrors.LandmarkPresent}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="PostPresent">Post</label>
                          <input
                            ref={txtAdPpost}
                            id="PostPresent"
                            type="text"
                            name="PostPresent"
                            className="Inputs"
                            onChange={handleChange}
                            value={list.PostPresent}
                            placeholder=" Post"
                            autoComplete="PostPresent"
                            required
                          />

                          {listErrors.PostPresent && (
                            <p className="errors">{listErrors.PostPresent}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="TalukPresent">Taluk</label>
                          <input
                            ref={txtAdPtaluk}
                            id="TalukPresent"
                            type="text"
                            name="TalukPresent"
                            className="Inputs"
                            onChange={handleChange}
                            value={list.TalukPresent}
                            placeholder=" Taluk"
                            autoComplete="TalukPresent"
                            required
                          />
                          {listErrors.TalukPresent && (
                            <p className="errors">{listErrors.TalukPresent}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="PincodePresent">
                            Pin/Postal Code
                          </label>
                          <input
                            ref={txtAdPpincode}
                            id="PincodePresent"
                            type="text"
                            onChange={handleChange}
                            value={list.PincodePresent}
                            name="PincodePresent"
                            className="Inputs"
                            placeholder="Pin/Postal Code"
                            autoComplete="PincodePresent"
                            required
                          />
                          {listErrors.PincodePresent && (
                            <p className="errors">
                              {listErrors.PincodePresent}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="CityPresent">City/Town/Village</label>
                          <Select
                            ref={txtAdPcity}
                            id="CityPresent"
                            name="CityPresent"
                            disabled={
                              list.admission_status === 1 ? true : false
                            }
                            value={
                              list.CityPresent !== null &&
                              list.CityPresent !== ""
                                ? {
                                    value: list.CityPresent,
                                    label: list.CityPresent,
                                  }
                                : null
                            }
                            onChange={(option) =>
                              handleSelectCH(option, "CityPresent")
                            }
                            options={
                              city
                                .filter((item) => item && item.label) // Filter out null and undefined elements
                                .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                            }
                            isSearchable={true}
                            placeholder="Select an option"
                            maxMenuHeight={200}
                            filterOption={(option, searchText) =>
                              option.label
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                            }
                            styles={customStyles}
                          />

                          {listErrors.CityPresent && (
                            <p className="errors">{listErrors.CityPresent}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row  mb-4">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="districtPresent" id="hio">
                            District
                          </label>
                          <input
                            ref={txtAdPdistrict}
                            id="districtPresent"
                            type="text"
                            value={list.districtPresent}
                            name="districtPresent"
                            className="Inputs"
                            placeholder="district"
                            disabled
                            required
                          />

                          {listErrors.districtPresent && (
                            <p className="errors">
                              {listErrors.districtPresent}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="statePresent" id="hio">
                            {" "}
                            State/Union Territory
                          </label>
                          <input
                            ref={txtAdPstate}
                            id="statePresent"
                            type="text"
                            value={list.statePresent}
                            name="statePresent"
                            className="Inputs"
                            placeholder=" State/Union Territory"
                            disabled
                            required
                          />
                          {/* <select
                          id="statePresent"
                          name="statePresent"
                          className="Inputs"
                          onChange={handleChange}
                          defaultValue={fieldInput.statePresent}
                          required
                        >
                          <option value="" disabled selected>
                            
                          </option>
                        </select> */}
                          {listErrors.statePresent && (
                            <p className="errors">{listErrors.statePresent}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="sectionFour ">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="final">Permanent Address</p>

                    <div className="row row mt-4 ms-2">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between">
                          <label>
                            <input
                              className="form-check-input me-2 align-text-center"
                              type="checkbox"
                              id="SamePresentAddress"
                              name="SamePresentAddress"
                              onChange={handleChange}
                              value={list.SamePresentAddress}
                              checked={
                                list.SamePresentAddress === true ? true : false
                              }
                            />
                            <span
                              className="form-check-label"
                              htmlFor="SamePresentAddress"
                            >
                              Same as Present Address
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="BuildingNumberPermanent">
                            House/Flat Number & Name
                          </label>
                          <input
                            ref={txtAdPerhno}
                            id="BuildingNumberPermanent"
                            type="text"
                            name="BuildingNumberPermanent"
                            className="Inputs"
                            onChange={handleChange}
                            value={
                              list.SamePresentAddress !== true
                                ? list.BuildingNumberPermanent
                                : list.BuildingNumberPresent
                            }
                            placeholder=" House/Building Number"
                            autoComplete="BuildingNumberPermanent"
                            required
                          />
                          {listErrors.BuildingNumberPermanent && (
                            <p className="errors">
                              {listErrors.BuildingNumberPermanent}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <div className="forms ">
                            <label htmlFor="StreetNamePermanent">
                              Street Name
                            </label>
                            <input
                              ref={txtAdPersname}
                              id="StreetNamePermanent"
                              type="text"
                              name="StreetNamePermanent"
                              className="Inputs"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.StreetNamePermanent
                                  : list.StreetNamePresent
                              }
                              placeholder="Street Name"
                              autoComplete="StreetNamePermanent"
                              required
                            />
                            {listErrors.StreetNamePermanent && (
                              <p className="errors">
                                {listErrors.StreetNamePermanent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="AreaPermanent">Locality/Area</label>
                          <input
                            ref={txtAdPerarea}
                            id="AreaPermanent"
                            type="text"
                            name="AreaPermanent"
                            onChange={handleChange}
                            value={
                              list.SamePresentAddress !== true
                                ? list.AreaPermanent
                                : list.AreaPresent
                            }
                            className="Inputs"
                            placeholder="Locality/Area"
                            autoComplete="AreaPermanent"
                            required
                          />
                          {listErrors.AreaPermanent && (
                            <p className="errors">{listErrors.AreaPermanent}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="LandmarkPermanent">Landmark</label>
                          <input
                            ref={txtAdPerland}
                            id="LandmarkPermanent"
                            type="text"
                            name="LandmarkPermanent"
                            className="Inputs"
                            onChange={handleChange}
                            value={
                              list.SamePresentAddress !== true
                                ? list.LandmarkPermanent
                                : list.LandmarkPresent
                            }
                            placeholder=" City"
                            autoComplete="LandmarkPermanent"
                            required
                          />
                          {listErrors.LandmarkPermanent && (
                            <p className="errors">
                              {listErrors.LandmarkPermanent}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="PostPermanent">Post</label>
                          <input
                            ref={txtAdPererpost}
                            id="PostPermanent"
                            type="text"
                            name="PostPermanent"
                            className="Inputs"
                            onChange={handleChange}
                            value={
                              list.SamePresentAddress !== true
                                ? list.PostPermanent
                                : list.PostPresent
                            }
                            placeholder=" Post"
                            autoComplete="PostPermanent"
                            required
                          />

                          {listErrors.PostPermanent && (
                            <p className="errors">{listErrors.PostPermanent}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="TalukPermanent">Taluk</label>
                          <input
                            ref={txtAdPertaluk}
                            id="TalukPermanent"
                            type="text"
                            name="TalukPermanent"
                            className="Inputs"
                            onChange={handleChange}
                            value={
                              list.SamePresentAddress !== true
                                ? list.TalukPermanent
                                : list.TalukPresent
                            }
                            placeholder=" Taluk"
                            autoComplete="TalukPermanent"
                            required
                          />
                          {listErrors.TalukPermanent && (
                            <p className="errors">
                              {listErrors.TalukPermanent}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="PincodePermanent">
                            Pin/Postal Code
                          </label>
                          <input
                            ref={txtAdPerpincode}
                            id="PincodePermanent"
                            type="text"
                            onChange={handleChange}
                            value={
                              list.SamePresentAddress !== true
                                ? list.PincodePermanent
                                : list.PincodePresent
                            }
                            name="PincodePermanent"
                            className="Inputs"
                            placeholder="Pin/Postal Code"
                            autoComplete="PincodePermanent"
                            required
                          />
                          {listErrors.PincodePermanent && (
                            <p className="errors">
                              {listErrors.PincodePermanent}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="CityPermanent">
                            City/Town/Village
                          </label>

                          {list.SamePresentAddress !== true ? (
                            <Select
                              ref={txtAdPercity}
                              id="CityPermanent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="CityPermanent"
                              value={
                                list.CityPermanent !== null &&
                                list.CityPermanent !== ""
                                  ? {
                                      value: list.CityPermanent,
                                      label: list.CityPermanent,
                                    }
                                  : null
                              }
                              onChange={(option) =>
                                handleSelectCH(option, "CityPermanent")
                              }
                              options={
                                city
                                  .filter((item) => item && item.label) // Filter out null and undefined elements
                                  .sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  ) // Sort options alphabetically by label
                              }
                              isSearchable={true}
                              placeholder="Select an option"
                              maxMenuHeight={200}
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              }
                              styles={customStyles}
                            />
                          ) : (
                            <input
                              ref={txtAdPercity}
                              id="CityPermanent"
                              type="text"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress === true
                                  ? list.CityPresent
                                  : list.CityPermanent
                              }
                              name="CityPermanent"
                              className="Inputs"
                              placeholder="City"
                              autoComplete="CityPermanent"
                              required
                            />
                          )}

                          {listErrors.CityPermanent && (
                            <p className="errors">{listErrors.CityPermanent}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row  mb-4">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="districtPermanent" id="hio">
                            District
                          </label>
                          <input
                            ref={txtAdPerdistrict}
                            id="districtPermanent"
                            type="text"
                            onChange={handleChange}
                            value={
                              list.SamePresentAddress !== true
                                ? list.districtPermanent
                                : list.districtPresent
                            }
                            name="districtPermanent"
                            className="Inputs"
                            placeholder="district"
                            autoComplete="districtPermanent"
                            required
                          />

                          {listErrors.districtPermanent && (
                            <p className="errors">
                              {listErrors.districtPermanent}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="statePermanent" id="hio">
                            State/Union Territory
                          </label>
                          <input
                            ref={txtAdPerstate}
                            id="statePermanent"
                            type="text"
                            onChange={handleChange}
                            value={
                              list.SamePresentAddress !== true
                                ? list.statePermanent
                                : list.statePresent
                            }
                            name="statePermanent"
                            className="Inputs"
                            placeholder="State/Union Territory"
                            autoComplete="statePermanent"
                            required
                          />

                          {listErrors.statePermanent && (
                            <p className="errors">
                              {listErrors.statePermanent}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="heading  mb-4 dflexbetww ">
                  <div className="">
                    <button
                      type="button"
                      className="SaveButton_Prev"
                      onClick={handlePrevious}
                    >
                      <a href="javascript:void(0)">Go to Personal Details</a>
                    </button>
                  </div>
                  <div className="d-flex smButton mobiledown  ">
                    <Link to="/" className="CancelButton">
                      Back to Home
                    </Link>
                    <button
                      type="button"
                      className="SaveButtons"
                      onClick={handleNext}
                    >
                      <a href="javascript:void(0)">
                        Save and Continue to Academic Background
                      </a>
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </fieldset>
      )}

      {step === 3 && (
        <fieldset>
          <div className="row">
            <div className="col-md-12">
              <section className="sectionFive">
                <div className="heading dflexbetww ">
                  <div className="">
                    <button
                      type="button"
                      className="SaveButton_Prev"
                      onClick={handlePrevious}
                    >
                      <a href="javascript:void(0)">Go to Communication</a>
                    </button>
                  </div>
                  <div className="d-flex smButton mobiledown">
                    <Link to="/" className="CancelButton">
                      Back to Home
                    </Link>
                    <button
                      type="button"
                      className="SaveButtons"
                      onClick={handleNext}
                    >
                      <a href="javascript:void(0)">
                        Save and Continue to Employment Details
                      </a>
                    </button>
                  </div>
                </div>
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Educational Qualification</p>

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <div className="forms row ">
                          <label htmlFor="EducationQualification">
                            Select All The Academic Certificates You Have
                          </label>
                          <div
                            className="d-flex mt-3 ms-3  col-md-12"
                            id="radiobuttons"
                          >
                            <div className="form-check form-check-inline">
                              <input
                                ref={txtAdEduc}
                                className="form-check-input"
                                type="checkbox"
                                id="EducationQualification3"
                                value="UG"
                                checked={educationQualification.includes("UG")}
                                onChange={handleCheckboxChange}
                              />
                              <span
                                className="form-check-label"
                                htmlFor="EducationQualification3"
                              >
                                UG
                              </span>
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="EducationQualification4"
                                value="PG"
                                checked={educationQualification.includes("PG")}
                                onChange={handleCheckboxChange}
                              />
                              <span
                                className="form-check-label"
                                htmlFor="EducationQualification4"
                              >
                                PG
                              </span>
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="EducationQualification5"
                                value="Integrated PG"
                                checked={educationQualification.includes(
                                  "Integrated PG"
                                )}
                                onChange={handleCheckboxChange}
                              />
                              <span
                                className="form-check-label"
                                htmlFor="EducationQualification5"
                              >
                                Integrated PG
                              </span>
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="EducationQualification3"
                                value="phd"
                                checked={educationQualification.includes("phd")}
                                onChange={handleCheckboxChange}
                              />
                              <span
                                className="form-check-label"
                                htmlFor="EducationQualification3"
                              >
                                Ph.D
                              </span>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="EducationQualification6"
                                value="Diploma"
                                checked={educationQualification.includes(
                                  "Diploma"
                                )}
                                onChange={handleCheckboxChange}
                              />
                              <span
                                className="form-check-label"
                                htmlFor="EducationQualification6"
                              >
                                Diploma
                              </span>
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="EducationQualification7"
                                value="Professional Courses"
                                checked={educationQualification.includes(
                                  "Professional Courses"
                                )}
                                onChange={handleCheckboxChange}
                              />
                              <span
                                className="form-check-label"
                                htmlFor="EducationQualification7"
                              >
                                Professional Courses
                              </span>
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="EducationQualification8"
                                value="Others"
                                checked={educationQualification.includes(
                                  "Others"
                                )}
                                onChange={handleCheckboxChange}
                              />
                              <span
                                className="form-check-label"
                                htmlFor="EducationQualification8"
                              >
                                Others
                              </span>
                            </div>
                          </div>

                          {listErrors.EducationQualification && (
                            <p className="errors">
                              {listErrors.EducationQualification}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* //educational section */}

              {educationQualification.includes("UG") && (
                <section className="sectionFive">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">UG</p>

                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="UGDegree">Degree</label>
                            <Select
                              ref={txtAdUdeg}
                              id="UGDegree"
                              name="UGDegree"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={ugOption.UG} // Use ugOption.ug as the value
                              onChange={(option) =>
                                handleEdus(option, "UG", "UGDegree")
                              }
                              options={ugDegree.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />
                            {/* {!uGisOther ? (
                                  
                             

                                  ) : (

                                    <input
                                    ref={txtAdUdeg}
                                    id="UGDegree"
                                    name="UGDegree"
                                      type="text"
                                      disabled={
                                        list.admission_status === 1
                                          ? true
                                          : false
                                      }
                                      className="Inputs"
                                      placeholder="Please enter degree"
                                      onChange={(e) => handleEdu("UG", e)}
                                      value={sslcCerficates[0].UGDegree}
                                      required
                                    />

                                  )} */}

                            {listErrors.UGDegree && (
                              <p className="errors">{listErrors.UGDegree}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="UGMajor">Major</label>
                            <Select
                              ref={txtAdUMaj}
                              id="UGMajor"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="UGMajor"
                              value={mOption.UGMajor}
                              onChange={(option) =>
                                handleEduMajor(option, "UG", "UGMajor")
                              }
                              options={major.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />

                            {listErrors.UGMajor && (
                              <p className="errors">{listErrors.UGMajor}</p>
                            )}
                          </div>
                        </div>
                        {uGisOther && (
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="UGMajor_Other">
                                Major(Other)
                              </label>
                              <input
                                ref={txtAdUMajOther}
                                id="UGMajor_Other"
                                type="text"
                                name="UGMajor_Other"
                                className="Inputs"
                                placeholder="Please enter major"
                                onChange={(e) => handleEdu("UG", e)}
                                value={sslcCerficates[0].UGMajor_Other}
                                required
                              />

                              {listErrors.UGMajor_Other && (
                                <p className="errors">
                                  {listErrors.UGMajor_Other}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row ">
                        {/* AIASA-000118 v1 - issues solved by lalitha */}
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="UGYearpassing">
                              Year of Passing
                            </label>
                            {/* <input
                                ref={txtAdUpass}
                                id="UGYearpassing"
                                type="month"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="UGYearpassing"
                                className="Inputs"
                                onChange={(e) => handleEdu("UG", e)}
                                placeholder="Year passing"
                                value={sslcCerficates[0].UGYearpassing}
                                autoComplete="Yearpassing"
                                required
                              /> */}
                            <MonthDatePicker
                              handleYearChange={handleYearChange_month}
                              handleMonthChange={handleMonthChange_month}
                              disabledProperty={
                                list.admission_status === 1 ? true : false
                              }
                              dname={"UG"}
                              txtAdSpass={txtAdUpass}
                              name={"UGYearpassing"}
                              handleChange={handleEYDate}
                              fieldInput={sslcCerficates[0].UGYearpassing}
                            />
                            {listErrors.UGYearpassing && (
                              <p className="errors">
                                {listErrors.UGYearpassing}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="UGCertificateNumber">
                              Certificate Number
                            </label>
                            <input
                              ref={txtAdUCer}
                              id="UGCertificateNumber"
                              type="text"
                              name="UGCertificateNumber"
                              className="Inputs"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={(e) => handleEdu("UG", e)}
                              placeholder="Certificate Number"
                              value={sslcCerficates[0].UGCertificateNumber}
                              autoComplete="UGCertificate Number"
                              required
                            />
                            {listErrors.UGCertificateNumber && (
                              <p className="errors">
                                {listErrors.UGCertificateNumber}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="UGMarksPercentage">
                              Marks Percentage
                            </label>
                            <input
                              ref={txtAdUmarks}
                              id="UGMarksPercentage"
                              type="number"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="UGMarksPercentage"
                              className="Inputs"
                              onChange={(e) => handleEdu("UG", e)}
                              placeholder="Marks Percentage"
                              value={sslcCerficates[0].UGMarksPercentage}
                              autoComplete="Marks Percentage"
                              required
                            />
                            {listErrors.UGMarksPercentage && (
                              <p className="errors">
                                {listErrors.UGMarksPercentage}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="UGClassName">Class</label>
                            <Select
                              ref={txtAdUclass}
                              id="UGClassName"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="UGClassName"
                              value={mOption.UGClassName}
                              onChange={(option) =>
                                handleEduMajor(option, "UG", "UGClassName")
                              }
                              options={classOption.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />
                            {/* <input
                                ref={txtAdUclass}
                                id="UGClassName"
                                type="text"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="UGClassName"
                                className="Inputs"
                                onChange={(e) => handleEdu("UG", e)}
                                placeholder="Class"
                                value={sslcCerficates[0].UGClassName}
                                autoComplete="HSCSchoolName"
                                required
                              /> */}
                            {listErrors.UGClassName && (
                              <p className="errors">{listErrors.UGClassName}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4 ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="UGNameInstitute">
                              Name of the college / university
                            </label>
                            <input
                              ref={txtAdUins}
                              id="UGNameInstitute"
                              type="text"
                              name="UGNameInstitute"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) => handleEdu("UG", e)}
                              placeholder="Name of the college / university"
                              value={sslcCerficates[0].UGNameInstitute}
                              autoComplete="UGNameInstitute"
                              required
                            />
                            {listErrors.UGNameInstitute && (
                              <p className="errors">
                                {listErrors.UGNameInstitute}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="UG_Path">
                              Upload UG Certificate (In pdf/Img format){" "}
                              <span className="optionFields">(Optional)</span>
                              {sslcCerficates[0].UG_Path !== "" &&
                                sslcCerficates[0].UG_Path !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${sslcCerficates[0].UG_Path}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                            <div className="row">
                              <div
                                className={
                                  sslcCerficates[0].UG_Path !== "" &&
                                  sslcCerficates[0].UG_Path !== null
                                    ? "col-md-3 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  ref={txtAdUfile}
                                  id="UG_Path"
                                  type="file"
                                  name="UG_Path"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  className={
                                    sslcCerficates[0].UG_Path !== "" &&
                                    sslcCerficates[0].UG_Path !== null
                                      ? "InputsFile nn_upload_width"
                                      : "InputsFile  vv_upload_width"
                                  }
                                  onChange={(e) => handleEdu("UG", e)}
                                  placeholder="UG_Path"
                                  autoComplete="UG_Path"
                                  required
                                />
                              </div>
                              {sslcCerficates[0].UG_Path !== "" &&
                                sslcCerficates[0].UG_Path !== null && (
                                  <div
                                    className={
                                      "col-md-9 d-flex align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_file">
                                      {sslcCerficates[0].UG_Path !== "" &&
                                      sslcCerficates[0].UG_Path !== null
                                        ? sslcCerficates[0].original_file_path
                                        : ""}
                                    </span>
                                    <span className="material-icons align-middle text-danger verified_upload">
                                      verified
                                    </span>
                                  </div>
                                )}
                            </div>
                            {listErrors.UG_Path && (
                              <p className="errors">{listErrors.UG_Path}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {educationQualification.includes("PG") && (
                <section className="  sectionFive">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">PG</p>
                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PGDegree">Degree</label>
                            <Select
                              id="PGDegree"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              ref={txtAdPdeg}
                              name="PGDegree"
                              value={ugOption.PG} // Use ugOption.ug as the value
                              onChange={(option) =>
                                handleEdus(option, "PG", "PGDegree")
                              }
                              options={pgDegree.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />

                            {/* {!pGisOther ? (
                          

                            ) : (

                              <input
                                ref={txtAdPdeg}
                                name="PGDegree"
                                id="PGDegree"
                                type="text"
                                disabled={
                                  list.admission_status === 1
                                    ? true
                                    : false
                                }
                                className="Inputs"
                                placeholder="Please enter degree"
                                onChange={(e) => handleEdu("PG", e)}
                                defaultValue={sslcCerficates[1].PGDegree}
                                required
                              />

                            )} */}

                            {listErrors.PGDegree && (
                              <p className="errors">{listErrors.PGDegree}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PGMajor">Major</label>
                            <Select
                              id="PGMajor"
                              name="PGMajor"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              ref={txtAdPMaj}
                              value={mOption.PGMajor} // Use ugOption.ug as the value
                              onChange={(option) =>
                                handleEduMajor(option, "PG", "PGMajor")
                              }
                              options={pgMajor.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />

                            {listErrors.PGMajor && (
                              <p className="errors">{listErrors.PGMajor}</p>
                            )}
                          </div>
                        </div>

                        {pGisOther && (
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PGMajor_Other">Major</label>
                              <input
                                type="text"
                                placeholder="Please Enter Major"
                                ref={txtAdPMajOther}
                                id="PGMajor_Other"
                                name="PGMajor_Other"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("PG", e)}
                                defaultValue={sslcCerficates[1].PGMajor_Other}
                                required
                              />

                              {listErrors.PGMajor_Other && (
                                <p className="errors">
                                  {listErrors.PGMajor_Other}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PGYearpassing">
                              Year of Passing
                            </label>
                            {/* <input
                                ref={txtAdPpass}
                                id="PGYearpassing"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                type="month"
                                name="PGYearpassing"
                                className="Inputs"
                                onChange={(e) => handleEdu("PG", e)}
                                placeholder="Year passing"
                                value={sslcCerficates[1].PGYearpassing}
                                autoComplete="Yearpassing"
                                required
                              /> */}
                            {/* minDate={sslcCerficates[0].UGYearpassing !=="" && sslcCerficates[0].UGYearpassing !==null ? sslcCerficates[0].UGYearpassing: "" } */}
                            <MonthDatePicker
                              handleYearChange={handleYearChange_month}
                              handleMonthChange={handleMonthChange_month}
                              disabledProperty={
                                list.admission_status === 1 ? true : false
                              }
                              dname={"PG"}
                              txtAdSpass={txtAdPpass}
                              name={"PGYearpassing"}
                              handleChange={handleEYDate}
                              fieldInput={sslcCerficates[1].PGYearpassing}
                            />

                            {listErrors.PGYearpassing && (
                              <p className="errors">
                                {listErrors.PGYearpassing}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PGCertificateNumber">
                              Certificate Number
                            </label>
                            <input
                              ref={txtAdPCer}
                              id="PGCertificateNumber"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="PGCertificateNumber"
                              className="Inputs"
                              onChange={(e) => handleEdu("PG", e)}
                              placeholder="Certificate Number"
                              value={sslcCerficates[1].PGCertificateNumber}
                              autoComplete="Certificate Number"
                              required
                            />
                            {listErrors.PGCertificateNumber && (
                              <p className="errors">
                                {listErrors.PGCertificateNumber}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PGMarksPercentage">
                              Marks Percentage
                            </label>
                            <input
                              ref={txtAdPmarks}
                              id="PGMarksPercentage"
                              type="number"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="PGMarksPercentage"
                              className="Inputs"
                              onChange={(e) => handleEdu("PG", e)}
                              placeholder="Marks Percentage"
                              value={sslcCerficates[1].PGMarksPercentage}
                              autoComplete="Marks Percentage"
                              required
                            />
                            {listErrors.PGMarksPercentage && (
                              <p className="errors">
                                {listErrors.PGMarksPercentage}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PGClassName">Class</label>
                            <Select
                              ref={txtAdPclass}
                              id="PGClassName"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="PGClassName"
                              value={mOption.PGClassName}
                              onChange={(option) =>
                                handleEduMajor(option, "PG", "PGClassName")
                              }
                              options={classOption.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />
                            {/* <input
                                ref={txtAdPclass}
                                id="PGClassName"
                                type="text"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="PGClassName"
                                className="Inputs"
                                onChange={(e) => handleEdu("PG", e)}
                                placeholder="Class Name"
                                value={sslcCerficates[1].PGClassName}
                                autoComplete="ClassName"
                                required
                              /> */}
                            {listErrors.PGClassName && (
                              <p className="errors">{listErrors.PGClassName}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4 ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PGNameInstitute">
                              Name of the college / university
                            </label>
                            <input
                              ref={txtAdPins}
                              id="PGNameInstitute"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="PGNameInstitute"
                              className="Inputs"
                              onChange={(e) => handleEdu("PG", e)}
                              placeholder="Name of the college / university"
                              value={sslcCerficates[1].PGNameInstitute}
                              autoComplete="PGNameInstitute"
                              required
                            />
                            {listErrors.PGNameInstitute && (
                              <p className="errors">
                                {listErrors.PGNameInstitute}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PG_Path">
                              Upload PG Certificate (In pdf/Img format){" "}
                              <span className="optionFields">(Optional)</span>
                              {sslcCerficates[1].PG_Path !== "" &&
                                sslcCerficates[1].PG_Path !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${sslcCerficates[1].PG_Path}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                            <div className="row">
                              <div
                                className={
                                  sslcCerficates[1].PG_Path !== "" &&
                                  sslcCerficates[1].PG_Path !== null
                                    ? "col-md-3 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  ref={txtAdPfile}
                                  id="PG_Path"
                                  type="file"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  name="PG_Path"
                                  className={
                                    sslcCerficates[1].PG_Path !== "" &&
                                    sslcCerficates[1].PG_Path !== null
                                      ? "InputsFile nn_upload_width"
                                      : "InputsFile  vv_upload_width"
                                  }
                                  onChange={(e) => handleEdu("PG", e)}
                                  placeholder="Path"
                                  autoComplete="Path"
                                  required
                                />
                              </div>
                              {sslcCerficates[1].PG_Path !== "" &&
                                sslcCerficates[1].PG_Path !== null && (
                                  <div
                                    className={
                                      "col-md-9 d-flex align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_file">
                                      {sslcCerficates[1].PG_Path !== "" &&
                                      sslcCerficates[1].PG_Path !== null
                                        ? sslcCerficates[1].original_file_path
                                        : ""}
                                    </span>
                                    <span className="material-icons align-middle text-danger verified_upload">
                                      verified
                                    </span>
                                  </div>
                                )}
                            </div>

                            {listErrors.PG_Path && (
                              <p className="errors">{listErrors.PG_Path}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {educationQualification.includes("Integrated PG") && (
                <section className=" sectionFive">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Integrated PG</p>

                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="IPDegree">Degree</label>
                            <Select
                              ref={txtAdIdeg}
                              id="IPDegree"
                              name="IPDegree"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={ugOption.Integrated_PG} // Use ugOption.ug as the value
                              onChange={(option) =>
                                handleEdus(option, "Integrated PG", "IPDegree")
                              }
                              options={ipgDegree.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />

                            {/* {!iPGisOther ? (
                          

                            ) : (

                              <input
                              ref={txtAdIdeg}
                              id="IPDegree"
                              name="IPDegree"
                                type="text"
                                disabled={
                                  list.admission_status === 1
                                    ? true
                                    : false
                                }
                                className="Inputs"
                                placeholder="Please enter degree"
                                onChange={(e) =>
                                  handleEdu("Integrated PG", e)
                                }
                                value={sslcCerficates[2].IPDegree}
                                required
                              />

                            )} */}

                            {listErrors.IPDegree && (
                              <p className="errors">{listErrors.IPDegree}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="IPMajor">Major</label>
                            {sslcCerficates[2].IPMajor === "Others" ||
                            iPGisOther === true ? (
                              <input
                                ref={txtAdIMaj}
                                id="IPMajor"
                                name="IPMajor"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Integrated PG", e)}
                                value={sslcCerficates[2].IPMajor}
                                required
                                placeholder="Please enter major"
                              />
                            ) : (
                              <Select
                                ref={txtAdIMaj}
                                id="IPMajor"
                                name="IPMajor"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={mOption.IPMajor} // Use ugOption.ug as the value
                                onChange={(option) =>
                                  handleEduMajor(
                                    option,
                                    "Integrated PG",
                                    "IPMajor"
                                  )
                                }
                                options={ipMajor.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />
                            )}

                            {listErrors.IPMajor && (
                              <p className="errors">{listErrors.IPMajor}</p>
                            )}
                          </div>
                        </div>
                        {iPGisOther && (
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="IPMajor_Other">Major</label>
                              <input
                                ref={txtAdIMajOther}
                                id="IPMajor_Other"
                                name="IPMajor_Other"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Integrated PG", e)}
                                value={sslcCerficates[2].IPMajor_Other}
                                required
                                placeholder="Please enter major"
                              />

                              {listErrors.IPMajor_Other && (
                                <p className="errors">
                                  {listErrors.IPMajor_Other}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="IPYearpassing">
                              Year of Passing
                            </label>
                            {/* <input
                                ref={txtAdIpass}
                                id="IPYearpassing"
                                type="month"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="IPYearpassing"
                                className="Inputs"
                                onChange={(e) => handleEdu("Integrated PG", e)}
                                placeholder="Year passing"
                                value={sslcCerficates[2].IPYearpassing}
                                autoComplete="Yearpassing"
                                required
                              /> */}
                            {/* minDate={sslcCerficates[1].PGYearpassing !=="" && sslcCerficates[1].PGYearpassing !==null ? sslcCerficates[1].PGYearpassing: "" } */}
                            <MonthDatePicker
                              handleYearChange={handleYearChange_month}
                              handleMonthChange={handleMonthChange_month}
                              disabledProperty={
                                list.admission_status === 1 ? true : false
                              }
                              dname={"Integrated PG"}
                              txtAdSpass={txtAdIpass}
                              name={"IPYearpassing"}
                              handleChange={handleEYDate}
                              fieldInput={sslcCerficates[2].IPYearpassing}
                            />
                            {listErrors.IPYearpassing && (
                              <p className="errors">
                                {listErrors.IPYearpassing}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="IPCertificateNumber">
                              Certificate Number
                            </label>
                            <input
                              ref={txtAdICer}
                              id="IPCertificateNumber"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="IPCertificateNumber"
                              className="Inputs"
                              onChange={(e) => handleEdu("Integrated PG", e)}
                              placeholder="Certificate Number"
                              value={sslcCerficates[2].IPCertificateNumber}
                              autoComplete="Certificate Number"
                              required
                            />
                            {listErrors.IPCertificateNumber && (
                              <p className="errors">
                                {listErrors.IPCertificateNumber}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="IPMarksPercentage">
                              Marks Percentage
                            </label>
                            <input
                              ref={txtAdImarks}
                              id="IPMarksPercentage"
                              type="number"
                              name="IPMarksPercentage"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) => handleEdu("Integrated PG", e)}
                              placeholder="Marks Percentage"
                              value={sslcCerficates[2].IPMarksPercentage}
                              autoComplete="Marks Percentage"
                              required
                            />
                            {listErrors.IPMarksPercentage && (
                              <p className="errors">
                                {listErrors.IPMarksPercentage}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="IPClassName">Class</label>
                            <Select
                              ref={txtAdIclass}
                              id="IPClassName"
                              type="text"
                              name="IPClassName"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={mOption.IPClassName}
                              onChange={(option) =>
                                handleEduMajor(
                                  option,
                                  "Integrated PG",
                                  "IPClassName"
                                )
                              }
                              options={classOption.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />

                            {/* <input
                                ref={txtAdIclass}
                                id="IPClassName"
                                type="text"
                                name="IPClassName"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Integrated PG", e)}
                                placeholder="Class Name"
                                value={sslcCerficates[2].IPClassName}
                                autoComplete="SchoolName"
                                required
                              /> */}
                            {listErrors.IPClassName && (
                              <p className="errors">{listErrors.IPClassName}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4 ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="IPNameInstitute">
                              Name of the college / university
                            </label>
                            <input
                              ref={txtAdIins}
                              id="IPNameInstitute"
                              type="text"
                              name="IPNameInstitute"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) => handleEdu("Integrated PG", e)}
                              placeholder="Name of the college / university"
                              value={sslcCerficates[2].IPNameInstitute}
                              autoComplete="IPNameInstitute"
                              required
                            />
                            {listErrors.IPNameInstitute && (
                              <p className="errors">
                                {listErrors.IPNameInstitute}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="IP_Path">
                              Upload Integrated PG Certificate (In pdf/Img
                              format){" "}
                              <span className="optionFields">(Optional)</span>
                              {sslcCerficates[2].IP_Path !== "" &&
                                sslcCerficates[2].IP_Path !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${sslcCerficates[2].IP_Path}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                            <div className="row">
                              <div
                                className={
                                  sslcCerficates[2].IP_Path !== "" &&
                                  sslcCerficates[2].IP_Path !== null
                                    ? "col-md-3 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  ref={txtAdIfile}
                                  id="IP_Path"
                                  type="file"
                                  name="IP_Path"
                                  className={
                                    sslcCerficates[2].IP_Path !== "" &&
                                    sslcCerficates[2].IP_Path !== null
                                      ? "InputsFile nn_upload_width"
                                      : "InputsFile  vv_upload_width"
                                  }
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  onChange={(e) =>
                                    handleEdu("Integrated PG", e)
                                  }
                                  placeholder="Path"
                                  autoComplete="Path"
                                  required
                                />
                              </div>
                              {sslcCerficates[2].IP_Path !== "" &&
                                sslcCerficates[2].IP_Path !== null && (
                                  <div
                                    className={
                                      "col-md-9 d-flex align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_file">
                                      {sslcCerficates[2].IP_Path !== "" &&
                                      sslcCerficates[2].IP_Path !== null
                                        ? sslcCerficates[2].original_file_path
                                        : ""}
                                    </span>
                                    <span className="material-icons align-middle text-danger verified_upload">
                                      verified
                                    </span>
                                  </div>
                                )}
                            </div>

                            {listErrors.IP_Path && (
                              <p className="errors">{listErrors.IP_Path}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {educationQualification.includes("phd") && (
                <section className="sectionFive">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Ph.D</p>

                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PHDDegree">Degree</label>
                            <Select
                              ref={txtAdPhddeg}
                              id="PHDDegree"
                              name="PHDDegree"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={ugOption.phd} // Use ugOption.ug as the value
                              onChange={(option) =>
                                handleEdus(option, "phd", "PHDDegree")
                              }
                              options={phDegree.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />

                            {/* {!pHDisOther ? (
                            

                            ) : (

                              <input
                                ref={txtAdPhddeg}
                                id="PHDDegree"
                                name="PHDDegree"
                                type="text"
                                disabled={
                                  list.admission_status === 1
                                    ? true
                                    : false
                                }
                                className="Inputs"
                                placeholder="Please enter degree"
                                onChange={(e) => handleEdu("phd", e)}
                                value={sslcCerficates[3].PHDDegree}
                                required
                              />

                            )} */}

                            {listErrors.PHDDegree && (
                              <p className="errors">{listErrors.PHDDegree}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PHDMajor">Major</label>
                            <Select
                              ref={txtAdPhdMaj}
                              id="PHDMajor"
                              name="PHDMajor"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={mOption.PHDMajor} // Use ugOption.ug as the value
                              onChange={(option) =>
                                handleEduMajor(option, "phd", "PHDMajor")
                              }
                              options={PhMajor.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />

                            {listErrors.PHDMajor && (
                              <p className="errors">{listErrors.PHDMajor}</p>
                            )}
                          </div>
                        </div>

                        {pHDisOther && (
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PHDMajor_Other">Major</label>
                              <input
                                type="text"
                                placeholder="Please Enter Major"
                                ref={txtAdPhdMajOther}
                                id="PHDMajor_Other"
                                name="PHDMajor_Other"
                                className="Inputs"
                                onChange={(e) => handleEdu("phd", e)}
                                value={sslcCerficates[3].PHDMajor_Other}
                                required
                              />

                              {listErrors.PHDMajor_Other && (
                                <p className="errors">
                                  {listErrors.PHDMajor_Other}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PHDCertificateNumber">
                              Certificate Number
                            </label>
                            <input
                              ref={txtAdPhdCer}
                              id="PHDCertificateNumber"
                              type="text"
                              name="PHDCertificateNumber"
                              className="Inputs"
                              onChange={(e) => handleEdu("phd", e)}
                              placeholder="Certificate Number"
                              value={sslcCerficates[3].PHDCertificateNumber}
                              autoComplete="Certificate Number"
                              required
                            />
                            {listErrors.PHDCertificateNumber && (
                              <p className="errors">
                                {listErrors.PHDCertificateNumber}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PHDYearpassing">
                              Year of Passing
                            </label>
                            {/* <input
                                ref={txtAdPhdpass}
                                id="PHDYearpassing"
                                type="month"
                                name="PHDYearpassing"
                                className="Inputs"
                                onChange={(e) => handleEdu("phd", e)}
                                placeholder="Year passing"
                                value={sslcCerficates[3].PHDYearpassing}
                                autoComplete="Yearpassing"
                                required
                              /> */}
                            {/* minDate={sslcCerficates[2].IPYearpassing !=="" && sslcCerficates[2].IPYearpassing !==null ?sslcCerficates[2].IPYearpassing: "" } */}
                            <MonthDatePicker
                              handleYearChange={handleYearChange_month}
                              handleMonthChange={handleMonthChange_month}
                              disabledProperty={
                                list.admission_status === 1 ? true : false
                              }
                              dname={"phd"}
                              txtAdSpass={txtAdPhdpass}
                              name={"PHDYearpassing"}
                              handleChange={handleEYDate}
                              fieldInput={sslcCerficates[3].PHDYearpassing}
                            />
                            {listErrors.PHDYearpassing && (
                              <p className="errors">
                                {listErrors.PHDYearpassing}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PHDMarksPercentage">
                              Marks Percentage
                            </label>
                            <input
                              ref={txtAdPhdmarks}
                              id="PHDMarksPercentage"
                              type="number"
                              name="PHDMarksPercentage"
                              className="Inputs"
                              onChange={(e) => handleEdu("phd", e)}
                              placeholder="Marks Percentage"
                              value={sslcCerficates[3].PHDMarksPercentage}
                              autoComplete="Marks Percentage"
                              required
                            />
                            {listErrors.PHDMarksPercentage && (
                              <p className="errors">
                                {listErrors.PHDMarksPercentage}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PHDClassName">Class</label>
                            <Select
                              ref={txtAdPhdclass}
                              id="PHDClassName"
                              type="text"
                              name="PHDClassName"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={mOption.PHDClassName}
                              onChange={(option) =>
                                handleEduMajor(option, "phd", "PHDClassName")
                              }
                              options={classOption.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />

                            {/* <input
                                ref={txtAdPhdclass}
                                id="PHDClassName"
                                type="text"
                                name="PHDClassName"
                                className="Inputs"
                                onChange={(e) => handleEdu("phd", e)}
                                placeholder="Class Name"
                                value={sslcCerficates[3].PHDClassName}
                                autoComplete="SchoolName"
                                required
                              /> */}
                            {listErrors.IPClassName && (
                              <p className="errors">
                                {listErrors.PHDClassName}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4 ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PHDNameInstitute">
                              Name of the college / university
                            </label>
                            <input
                              ref={txtAdPhdins}
                              id="PHDNameInstitute"
                              type="text"
                              name="PHDNameInstitute"
                              className="Inputs"
                              onChange={(e) => handleEdu("phd", e)}
                              placeholder="Name of the college / university"
                              value={sslcCerficates[3].PHDNameInstitute}
                              autoComplete="PHDNameInstitute"
                              required
                            />
                            {listErrors.PHDNameInstitute && (
                              <p className="errors">
                                {listErrors.PHDNameInstitute}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PHD_Path">
                              Upload PHD Certificate (In pdf/Img format){" "}
                              <span className="optionFields">(Optional)</span>
                              {sslcCerficates[3].PHD_Path !== "" &&
                                sslcCerficates[3].PHD_Path !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${sslcCerficates[3].PHD_Path}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                            <div className="row">
                              <div
                                className={
                                  sslcCerficates[3].PHD_Path !== "" &&
                                  sslcCerficates[3].PHD_Path !== null
                                    ? "col-md-3 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  ref={txtAdPhdfile}
                                  id="PHD_Path"
                                  type="file"
                                  name="PHD_Path"
                                  className={
                                    sslcCerficates[3].PHD_Path !== "" &&
                                    sslcCerficates[3].PHD_Path !== null
                                      ? "InputsFile nn_upload_width"
                                      : "InputsFile  vv_upload_width"
                                  }
                                  onChange={(e) => handleEdu("phd", e)}
                                  placeholder="Path"
                                  autoComplete="Path"
                                  required
                                />
                              </div>
                              {sslcCerficates[3].PHD_Path !== "" &&
                                sslcCerficates[3].PHD_Path !== null && (
                                  <div
                                    className={
                                      "col-md-9 d-flex align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_file">
                                      {sslcCerficates[3].PHD_Path !== "" &&
                                      sslcCerficates[3].PHD_Path !== null
                                        ? sslcCerficates[3].original_file_path
                                        : ""}
                                    </span>
                                    <span className="material-icons align-middle text-danger verified_upload">
                                      verified
                                    </span>
                                  </div>
                                )}
                            </div>

                            {listErrors.PHD_Path && (
                              <p className="errors">{listErrors.PHD_Path}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {educationQualification.includes("Diploma") && (
                <section className="sectionFive">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Diploma</p>

                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="DDegree">Diploma</label>
                            <Select
                              ref={txtAdDdeg}
                              id="DDegree"
                              name="DDegree"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={ugOption.Diploma} // Use ugOption.ug as the value
                              onChange={(option) =>
                                handleEdus(option, "Diploma", "DDegree")
                              }
                              options={diplomaDegree.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />

                            {/* {!dipisOther ? (
                          


                            ) : (

                              <input
                                ref={txtAdDdeg}
                                id="DDegree"
                                name="DDegree"
                                type="text"
                                disabled={
                                  list.admission_status === 1
                                    ? true
                                    : false
                                }
                                className="Inputs"
                                placeholder="Please enter degree"
                                onChange={(e) => handleEdu("Diploma", e)}
                                value={sslcCerficates[4].DDegree}
                                required
                              />

                            )} */}
                            {listErrors.DDegree && (
                              <p className="errors">{listErrors.DDegree}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="DMajor">Major</label>
                            <Select
                              ref={txtAdDMaj}
                              id="DMajor"
                              name="DMajor"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={mOption.DMajor} // Use ugOption.ug as the value
                              onChange={(option) =>
                                handleEduMajor(option, "Diploma", "DMajor")
                              }
                              options={dmajor.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />
                            {listErrors.DMajor && (
                              <p className="errors">{listErrors.DMajor}</p>
                            )}
                          </div>
                        </div>
                        {dipisOther && (
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="DMajor_Other">Major</label>
                              <input
                                type="text"
                                placeholder="Please Enter Major"
                                ref={txtAdDMajOther}
                                id="DMajor_Other"
                                name="DMajor_Other"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Diploma", e)}
                                value={sslcCerficates[4].DMajor_Other}
                                required
                              />

                              {listErrors.DMajor_Other && (
                                <p className="errors">
                                  {listErrors.DMajor_Other}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="DYearpassing">
                              Year of Passing
                            </label>
                            {/* <input
                                ref={txtAdDpass}
                                id="DYearpassing"
                                type="month"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="DYearpassing"
                                className="Inputs"
                                onChange={(e) => handleEdu("Diploma", e)}
                                placeholder="Year passing"
                                value={sslcCerficates[4].DYearpassing}
                                autoComplete="Yearpassing"
                                required
                              /> */}
                            {/* minDate={sslcCerficates[3].PHDYearpassing !=="" && sslcCerficates[3].PHDYearpassing !==null ?sslcCerficates[3].PHDYearpassing: "" } */}
                            <MonthDatePicker
                              handleYearChange={handleYearChange_month}
                              handleMonthChange={handleMonthChange_month}
                              disabledProperty={
                                list.admission_status === 1 ? true : false
                              }
                              dname={"Diploma"}
                              txtAdSpass={txtAdDpass}
                              name={"DYearpassing"}
                              handleChange={handleEYDate}
                              fieldInput={sslcCerficates[4].DYearpassing}
                            />
                            {listErrors.DYearpassing && (
                              <p className="errors">
                                {listErrors.DYearpassing}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="DCertificateNumber">
                              Certificate Number
                            </label>
                            <input
                              ref={txtAdDCer}
                              id="DCertificateNumber"
                              type="text"
                              name="DCertificateNumber"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) => handleEdu("Diploma", e)}
                              placeholder="Certificate Number"
                              value={sslcCerficates[4].DCertificateNumber}
                              autoComplete="Certificate Number"
                              required
                            />
                            {listErrors.DCertificateNumber && (
                              <p className="errors">
                                {listErrors.DCertificateNumber}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="DMarksPercentage">
                              Marks Percentage
                            </label>
                            <input
                              ref={txtAdDmarks}
                              id="DMarksPercentage"
                              type="number"
                              name="DMarksPercentage"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) => handleEdu("Diploma", e)}
                              placeholder="Marks Percentage"
                              value={sslcCerficates[4].DMarksPercentage}
                              autoComplete="Marks Percentage"
                              required
                            />
                            {listErrors.DMarksPercentage && (
                              <p className="errors">
                                {listErrors.DMarksPercentage}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="DClassName">Class</label>
                            <Select
                              ref={txtAdDclass}
                              id="DClassName"
                              type="text"
                              name="DClassName"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={mOption.DClassName}
                              onChange={(option) =>
                                handleEduMajor(option, "Diploma", "DClassName")
                              }
                              options={classOption.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />

                            {/* <input
                                ref={txtAdDclass}
                                id="DClassName"
                                type="text"
                                name="DClassName"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Diploma", e)}
                                placeholder=" Class Name"
                                value={sslcCerficates[4].DClassName}
                                autoComplete="SchoolName"
                                required
                              /> */}
                            {listErrors.DClassName && (
                              <p className="errors">{listErrors.DClassName}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4 ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="DNameInstitute">
                              Name of the college / university
                            </label>
                            <input
                              ref={txtAdDins}
                              id="DNameInstitute"
                              type="text"
                              name="DNameInstitute"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) => handleEdu("Diploma", e)}
                              placeholder="Name of the college / university"
                              value={sslcCerficates[4].DNameInstitute}
                              autoComplete="DNameInstitute"
                              required
                            />
                            {listErrors.DNameInstitute && (
                              <p className="errors">
                                {listErrors.DNameInstitute}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="D_Path">
                              Upload Diploma Certificate (In pdf/Img format){" "}
                              <span className="optionFields">(Optional)</span>
                              {sslcCerficates[4].D_Path !== "" &&
                                sslcCerficates[4].D_Path !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${sslcCerficates[4].D_Path}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                            <div className="row">
                              <div
                                className={
                                  sslcCerficates[4].D_Path !== "" &&
                                  sslcCerficates[4].D_Path !== null
                                    ? "col-md-3 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  ref={txtAdDfile}
                                  id="D_Path"
                                  type="file"
                                  name="D_Path"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  className={
                                    sslcCerficates[4].D_Path !== "" &&
                                    sslcCerficates[4].D_Path !== null
                                      ? "InputsFile nn_upload_width"
                                      : "InputsFile  vv_upload_width"
                                  }
                                  onChange={(e) => handleEdu("Diploma", e)}
                                  placeholder="Path"
                                  autoComplete="Path"
                                  required
                                />
                              </div>
                              {sslcCerficates[4].D_Path !== "" &&
                                sslcCerficates[4].D_Path !== null && (
                                  <div
                                    className={
                                      "col-md-9 d-flex align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_file">
                                      {sslcCerficates[4].D_Path !== "" &&
                                      sslcCerficates[4].D_Path !== null
                                        ? sslcCerficates[4].original_file_path
                                        : ""}
                                    </span>
                                    <span className="material-icons align-middle text-danger verified_upload">
                                      verified
                                    </span>
                                  </div>
                                )}
                            </div>
                            {listErrors.D_Path && (
                              <p className="errors">{listErrors.D_Path}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {educationQualification.includes("Professional Courses") && (
                <section className="sectionFive">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Professional Courses</p>
                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PCDegree">Degree</label>
                            <Select
                              ref={txtAdCdeg}
                              id="PCDegree"
                              name="PCDegree"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={ugOption.Professional_Courses} // Use ugOption.ug as the value
                              onChange={(option) =>
                                handleEdus(
                                  option,
                                  "Professional Courses",
                                  "PCDegree"
                                )
                              }
                              options={pcDegree.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />
                            {/* {!pCisOther ? (
                          



                            ) : (

                              <input
                              ref={txtAdCdeg}
                              id="PCDegree"
                              name="PCDegree"
                                type="text"
                                disabled={
                                  list.admission_status === 1
                                    ? true
                                    : false
                                }
                                className="Inputs"
                                placeholder="Please enter degree"
                                onChange={(e) =>
                                  handleEdu("Professional Courses", e)
                                }
                                value={sslcCerficates[5].PCDegree}
                                required
                              />

                            )} */}

                            {listErrors.PCDegree && (
                              <p className="errors">{listErrors.PCDegree}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PCMajor">Major</label>
                            <Select
                              ref={txtAdCMaj}
                              id="PCMajor"
                              name="PCMajor"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={mOption.PCMajor} // Use ugOption.ug as the value
                              onChange={(option) =>
                                handleEduMajor(
                                  option,
                                  "Professional Courses",
                                  "PCMajor"
                                )
                              }
                              options={pcMajor.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />

                            {listErrors.PCMajor && (
                              <p className="errors">{listErrors.PCMajor}</p>
                            )}
                          </div>
                        </div>
                        {pCisOther && (
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PCMajor_Other">Major</label>
                              <input
                                type="text"
                                placeholder="Please Enter Major"
                                ref={txtAdCMajOther}
                                id="PCMajor_Other"
                                name="PCMajor_Other"
                                className="Inputs"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                onChange={(e) =>
                                  handleEdu("Professional Courses", e)
                                }
                                defaultValue={sslcCerficates[5].PCMajor_Other}
                                required
                              />
                              {listErrors.PCMajor_Other && (
                                <p className="errors">
                                  {listErrors.PCMajor_Other}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PCYearpassing">
                              Year of Passing
                            </label>
                            {/* <input
                                ref={txtAdCpass}
                                id="PCYearpassing"
                                type="month"
                                name="PCYearpassing"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) =>
                                  handleEdu("Professional Courses", e)
                                }
                                placeholder="Year passing"
                                value={sslcCerficates[5].PCYearpassing}
                                autoComplete="Yearpassing"
                                required
                              /> */}
                            {/* minDate={sslcCerficates[4].DYearpassing !=="" && sslcCerficates[4].DYearpassing !==null ? sslcCerficates[4].DYearpassing: "" } */}
                            <MonthDatePicker
                              handleYearChange={handleYearChange_month}
                              handleMonthChange={handleMonthChange_month}
                              disabledProperty={
                                list.admission_status === 1 ? true : false
                              }
                              dname={"Professional Courses"}
                              txtAdSpass={txtAdCpass}
                              name={"PCYearpassing"}
                              handleChange={handleEYDate}
                              fieldInput={sslcCerficates[5].PCYearpassing}
                            />
                            {listErrors.PCYearpassing && (
                              <p className="errors">
                                {listErrors.PCYearpassing}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PCCertificateNumber">
                              Certificate Number
                            </label>
                            <input
                              ref={txtAdCCer}
                              id="PCCertificateNumber"
                              type="text"
                              name="PCCertificateNumber"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) =>
                                handleEdu("Professional Courses", e)
                              }
                              placeholder="Certificate Number"
                              value={sslcCerficates[5].PCCertificateNumber}
                              autoComplete="Certificate Number"
                              required
                            />
                            {listErrors.PCCertificateNumber && (
                              <p className="errors">
                                {listErrors.PCCertificateNumber}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PCMarksPercentage">
                              Marks Percentage
                            </label>
                            <input
                              ref={txtAdCmarks}
                              id="PCMarksPercentage"
                              type="number"
                              name="PCMarksPercentage"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) =>
                                handleEdu("Professional Courses", e)
                              }
                              placeholder="Marks Percentage"
                              value={sslcCerficates[5].PCMarksPercentage}
                              autoComplete="Marks Percentage"
                              required
                            />
                            {listErrors.PCMarksPercentage && (
                              <p className="errors">
                                {listErrors.PCMarksPercentage}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PCClassName">Class</label>
                            <Select
                              ref={txtAdCclass}
                              id="PCClassName"
                              type="text"
                              name="PCClassName"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={mOption.PCClassName}
                              onChange={(option) =>
                                handleEduMajor(
                                  option,
                                  "Professional Courses",
                                  "PCClassName"
                                )
                              }
                              options={classOption.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />
                            {/* <input
                                ref={txtAdCclass}
                                id="PCClassName"
                                type="text"
                                name="PCClassName"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) =>
                                  handleEdu("Professional Courses", e)
                                }
                                placeholder="ClassName"
                                value={sslcCerficates[5].PCClassName}
                                autoComplete="PCClassName"
                                required
                              /> */}
                            {listErrors.PCClassName && (
                              <p className="errors">{listErrors.PCClassName}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4 ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PCNameInstitute">
                              Name of the college / university
                            </label>
                            <input
                              ref={txtAdCins}
                              id="PCNameInstitute"
                              type="text"
                              name="PCNameInstitute"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) =>
                                handleEdu("Professional Courses", e)
                              }
                              placeholder="Name of the college / university"
                              value={sslcCerficates[5].PCNameInstitute}
                              autoComplete="PCNameInstitute"
                              required
                            />
                            {listErrors.PCNameInstitute && (
                              <p className="errors">
                                {listErrors.PCNameInstitute}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="PC_Path">
                              Upload Professional Courses Certificate (In
                              pdf/Img format){" "}
                              <span className="optionFields">(Optional)</span>
                              {sslcCerficates[5].PC_Path !== "" &&
                                sslcCerficates[5].PC_Path !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${sslcCerficates[5].PC_Path}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                            <div className="row">
                              <div
                                className={
                                  sslcCerficates[5].PC_Path !== "" &&
                                  sslcCerficates[5].PC_Path !== null
                                    ? "col-md-3 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  ref={txtAdCfile}
                                  id="PC_Path"
                                  type="file"
                                  name="PC_Path"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  className={
                                    sslcCerficates[5].PC_Path !== "" &&
                                    sslcCerficates[5].PC_Path !== null
                                      ? "InputsFile nn_upload_width"
                                      : "InputsFile  vv_upload_width"
                                  }
                                  onChange={(e) =>
                                    handleEdu("Professional Courses", e)
                                  }
                                  placeholder="Path"
                                  autoComplete="Path"
                                  required
                                />
                              </div>
                              {sslcCerficates[5].PC_Path !== "" &&
                                sslcCerficates[5].PC_Path !== null && (
                                  <div
                                    className={
                                      "col-md-9 d-flex align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_file">
                                      {sslcCerficates[5].PC_Path !== "" &&
                                      sslcCerficates[5].PC_Path !== null
                                        ? sslcCerficates[5].original_file_path
                                        : ""}
                                    </span>
                                    <span className="material-icons align-middle text-danger verified_upload">
                                      verified
                                    </span>
                                  </div>
                                )}
                            </div>

                            {listErrors.PC_Path && (
                              <p className="errors">{listErrors.PC_Path}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {educationQualification.includes("Others") && (
                <section className="sectionFive">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Others</p>
                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="OtDegree">Degree</label>
                            <input
                              ref={txtAdOdeg}
                              id="OtDegree"
                              name="OtDegree"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) => handleEdu("Others", e)}
                              value={sslcCerficates[6].OtDegree}
                              required
                              placeholder="Plz Enter Degree name"
                            />
                            {/* <select
                              
                              >
                                <option value="" selected>
                                  Please select
                                </option>
                                {ugDegree}
                              </select> */}

                            {listErrors.OtDegree && (
                              <p className="errors">{listErrors.OtDegree}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="OtMajor">Major</label>
                            {/* {sslcCerficates[6].OtDegree === "Others" ? (
                               
                              ) : (
                                <select
                                  ref={txtAdOMaj}
                                  id="OtMajor"
                                  name="OtMajor"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  className="Inputs"
                                  onChange={(e) => handleEdu("Others", e)}
                                  defaultValue={sslcCerficates[6].OtMajor}
                                  required
                                >
                                  <option value="" selected>
                                    Please select
                                  </option>
                                  {otMajor}
                                </select>
                              )} */}

                            <input
                              type="text"
                              placeholder="Please Enter Major"
                              ref={txtAdOMaj}
                              id="OtMajor"
                              name="OtMajor"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) => handleEdu("Others", e)}
                              defaultValue={sslcCerficates[6].OtMajor}
                              required
                            />

                            {listErrors.OtMajor && (
                              <p className="errors">{listErrors.OtMajor}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="OtYearpassing">
                              Year of Passing
                            </label>
                            {/* <input
                                ref={txtAdOpass}
                                id="OtYearpassing"
                                type="month"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="OtYearpassing"
                                className="Inputs"
                                onChange={(e) => handleEdu("Others", e)}
                                placeholder="Year passing"
                                value={sslcCerficates[6].OtYearpassing}
                                autoComplete="Yearpassing"
                                required
                              /> */}
                            <MonthDatePicker
                              handleYearChange={handleYearChange_month}
                              handleMonthChange={handleMonthChange_month}
                              disabledProperty={
                                list.admission_status === 1 ? true : false
                              }
                              dname={"Others"}
                              txtAdSpass={txtAdOpass}
                              name={"OtYearpassing"}
                              handleChange={handleEYDate}
                              fieldInput={sslcCerficates[6].OtYearpassing}
                            />
                            {listErrors.OtYearpassing && (
                              <p className="errors">
                                {listErrors.OtYearpassing}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="OtCertificateNumber">
                              Certificate Number
                            </label>
                            <input
                              ref={txtAdOCer}
                              id="OtCertificateNumber"
                              type="text"
                              name="OtCertificateNumber"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) => handleEdu("Others", e)}
                              placeholder="Certificate Number"
                              value={sslcCerficates[6].OtCertificateNumber}
                              autoComplete="Certificate Number"
                              required
                            />
                            {listErrors.OtCertificateNumber && (
                              <p className="errors">
                                {listErrors.OtCertificateNumber}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="OtMarksPercentage">
                              Marks Percentage
                            </label>
                            <input
                              ref={txtAdOmarks}
                              id="OtMarksPercentage"
                              type="number"
                              name="OtMarksPercentage"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) => handleEdu("Others", e)}
                              placeholder="Marks Percentage"
                              value={sslcCerficates[6].OtMarksPercentage}
                              autoComplete="Marks Percentage"
                              required
                            />
                            {listErrors.OtMarksPercentage && (
                              <p className="errors">
                                {listErrors.OtMarksPercentage}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="OtClassName">Class</label>
                            <Select
                              ref={txtAdOclass}
                              id="OtClassName"
                              type="text"
                              name="OtClassName"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={mOption.OtClassName}
                              onChange={(option) =>
                                handleEduMajor(option, "Others", "OtClassName")
                              }
                              options={classOption.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )} // Sort options alphabetically by label
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />
                            {/* <input
                                ref={txtAdOclass}
                                id="OtClassName"
                                type="text"
                                name="OtClassName"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Others", e)}
                                placeholder="Class Name"
                                value={sslcCerficates[6].OtClassName}
                                autoComplete="ClassName"
                                required
                              /> */}
                            {listErrors.OtClassName && (
                              <p className="errors">{listErrors.OtClassName}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4 ">
                        <div className="col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="OtNameInstitute">
                              Name of the college / university
                            </label>
                            <input
                              ref={txtAdOins}
                              id="OtNameInstitute"
                              type="text"
                              name="OtNameInstitute"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={(e) => handleEdu("Others", e)}
                              placeholder="Name of the college / university"
                              value={sslcCerficates[6].OtNameInstitute}
                              autoComplete="OtNameInstitute"
                              required
                            />
                            {listErrors.OtNameInstitute && (
                              <p className="errors">
                                {listErrors.OtNameInstitute}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="forms ">
                            <label htmlFor="Ot_Path">
                              Upload Others Certificate (In pdf/Img format){" "}
                              <span className="optionFields">(Optional)</span>
                              {sslcCerficates[6].Ot_Path !== "" &&
                                sslcCerficates[6].Ot_Path !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${sslcCerficates[6].Ot_Path}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                            <div className="row">
                              <div
                                className={
                                  sslcCerficates[6].Ot_Path !== "" &&
                                  sslcCerficates[6].Ot_Path !== null
                                    ? "col-md-3 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  ref={txtAdOfile}
                                  id="Ot_Path"
                                  type="file"
                                  name="Ot_Path"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  className={
                                    sslcCerficates[6].Ot_Path !== "" &&
                                    sslcCerficates[6].Ot_Path !== null
                                      ? "InputsFile nn_upload_width"
                                      : "InputsFile  vv_upload_width"
                                  }
                                  onChange={(e) => handleEdu("Others", e)}
                                  placeholder="Path"
                                  autoComplete="Path"
                                  required
                                />
                              </div>
                              {sslcCerficates[6].Ot_Path !== "" &&
                                sslcCerficates[6].Ot_Path !== null && (
                                  <div
                                    className={
                                      "col-md-9 d-flex align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_file">
                                      {sslcCerficates[6].Ot_Path !== "" &&
                                      sslcCerficates[6].Ot_Path !== null
                                        ? sslcCerficates[6].original_file_path
                                        : ""}
                                    </span>
                                    <span className="material-icons align-middle text-danger verified_upload">
                                      verified
                                    </span>
                                  </div>
                                )}
                            </div>

                            {listErrors.Ot_Path && (
                              <p className="errors">{listErrors.Ot_Path}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {/* //educational section  end */}

              <section className="  sectionFive">
                {techQualification.map((item, index) => (
                  <TechFaculty
                    key={item.id}
                    AddQualification={AddQualification}
                    DeleteQual={DeleteQual}
                    length={techQualification.length}
                    technicalOption={technicalOption}
                    TechhandleChange_date={TechhandleChange_date}
                    TechhandleChange={TechhandleChange}
                    TechhandleChanges={TechhandleChanges}
                    record={item}
                  />
                ))}
              </section>

              <section className=" sectionThree">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">
                      Special / Preferential Qualification (if any)
                    </p>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="forms ">
                          <label htmlFor="spq">
                            Special / Preferential Qualification
                          </label>
                          <textarea
                            ref={txtAdSpc}
                            name="spq"
                            id="spq"
                            row="10"
                            onChange={handleChange}
                            col="10"
                            defaultValue={list.spq}
                            className="Inputs"
                            placeholder="Special / Preferential Qualification"
                          ></textarea>

                          {listErrors.spq && (
                            <p className="errors">{listErrors.spq}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="heading mb-4 dflexbetww ">
                  <div className="">
                    <button
                      type="button"
                      className="SaveButton_Prev"
                      onClick={handlePrevious}
                    >
                      <a href="javascript:void(0)">Go to Communication</a>
                    </button>
                  </div>
                  <div className="d-flex smButton mobiledown">
                    <Link to="/" className="CancelButton">
                      Back to Home
                    </Link>
                    <button
                      type="button"
                      className="SaveButtons"
                      onClick={handleNext}
                    >
                      <a href="javascript:void(0)">
                        Save and Continue to Employment Details
                      </a>
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </fieldset>
      )}

      {step === 4 && (
        <fieldset>
          <div className="row">
            <div className="col-md-12">
              <section className="sectionFour">
                <div className="heading  dflexbetww ">
                  <div className="">
                    <button
                      type="button"
                      className="SaveButton_Prev"
                      onClick={handlePrevious}
                    >
                      <a href="javascript:void(0)">Go to Academic Background</a>
                    </button>
                  </div>
                  <div className="d-flex smButton mobiledown">
                    <Link to="/" className="CancelButton">
                      Back to Home
                    </Link>
                    <button
                      type="button"
                      className="SaveButtons"
                      onClick={handleNext}
                    >
                      <a href="javascript:void(0)">
                        Save and Continue to Review and Confirm
                      </a>
                    </button>
                  </div>
                </div>
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Previous Experience</p>
                    <div className="row  mb-4">
                      <div className="col-md-12">
                        <div className="forms ">
                          <label htmlFor="teachindexp">
                            Do you have any previous working or teaching
                            experience?
                          </label>
                          <div className="radioGroup d-flex flex-row ">
                            <div className="formCheck ">
                              <input
                                className="checkbox_radio"
                                type="radio"
                                name="prevTeachexp"
                                id="prevTeachexp1"
                                onChange={handleChange}
                                checked={
                                  list.prevTeachexp === "Yes" ? true : false
                                }
                                value="Yes"
                              />
                              <label
                                className="checkbox-label"
                                htmlFor="prevTeachexp1"
                              >
                                Yes
                              </label>

                              <input
                                className="checkbox_radio"
                                type="radio"
                                name="prevTeachexp"
                                id="prevTeachexp2"
                                onChange={handleChange}
                                checked={
                                  list.prevTeachexp === "NO" ? true : false
                                }
                                value="NO"
                              />
                              <label
                                className="checkbox-label"
                                htmlFor="prevTeachexp2"
                              >
                                NO
                              </label>
                            </div>
                          </div>
                          {listErrors.prevTeachexp && (
                            <p className="errors">{listErrors.prevTeachexp}</p>
                          )}
                        </div>
                      </div>
                      {list.prevTeachexp === "Yes" && (
                        <>
                          <div className="col-md-6 ">
                            <div className="forms ">
                              <div className="forms ">
                                <label htmlFor="workingexp">
                                  Working Experience
                                </label>
                                <textarea
                                  ref={txtAdWorkingexp}
                                  name="workingexp"
                                  id="workingexp"
                                  defaultValue={list.workingexp}
                                  onChange={handleChange}
                                  className="Inputs"
                                  placeholder="Working Experience"
                                ></textarea>
                                {listErrors.workingexp && (
                                  <p className="errors">
                                    {listErrors.workingexp}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                              <div className="forms ">
                                <div className="forms ">
                                  <label htmlFor="teachexp">
                                    Teaching Experience
                                  </label>
                                  <textarea
                                    ref={txtAdTeachingexp}
                                    name="teachexp"
                                    id="teachexp"
                                    defaultValue={list.teachexp}
                                    onChange={handleChange}
                                    className="Inputs"
                                    placeholder="Teaching Experience"
                                  ></textarea>
                                  {listErrors.teachexp && (
                                    <p className="errors">
                                      {listErrors.teachexp}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div> */}
                          <div className="col-lg-6 col-md-12">
                            <div className="forms ">
                              <div className="forms ">
                                <label htmlFor="working_ex_file">
                                  Working Experience File ( In PDF format)
                                  {list.working_ex_file !== "" &&
                                    list.working_ex_file !== null && (
                                      <a
                                        href={`${ImageUrlPrefix}/${list.working_ex_file}`}
                                        target="_blank"
                                        download
                                      >
                                        <span className="material-icons align-middle download_icon">
                                          file_download
                                        </span>
                                      </a>
                                    )}
                                </label>
                                <div className="row">
                                  <div
                                    className={
                                      list.working_ex_file !== "" &&
                                      list.working_ex_file !== null
                                        ? "col-md-3"
                                        : "col-md-12"
                                    }
                                  >
                                    <input
                                      ref={txtWfile}
                                      className={
                                        list.working_ex_file !== "" &&
                                        list.working_ex_file !== null
                                          ? "InputsFile nn_upload_width"
                                          : "InputsFile  vv_upload_width"
                                      }
                                      type="file"
                                      name="working_ex_file"
                                      id="working_ex_file"
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                  {list.working_ex_file !== "" &&
                                    list.working_ex_file !== null && (
                                      <div
                                        className={
                                          "col-md-9 d-flex align-content-center p-0"
                                        }
                                      >
                                        <span className="nn_upload_file">
                                          {list.working_ex_file !== "" &&
                                          list.working_ex_file !== null
                                            ? list.original_workexp
                                            : ""}
                                        </span>
                                        <span className="material-icons align-middle text-danger verified_upload">
                                          verified
                                        </span>
                                      </div>
                                    )}
                                </div>

                                {listErrors.working_ex_file && (
                                  <p className="errors">
                                    {listErrors.working_ex_file}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                              <div className="forms ">
                                <div className="forms ">
                                  <label htmlFor="Teaching_ex_file">
                                    Teaching Experience File ( In PDF format) <span className="optionFields">(Optional)</span>
                                    {list.Teaching_ex_file !=="" &&
                                list.Teaching_ex_file !==null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${list.Teaching_ex_file}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                                  </label>
                                  <div className="row">
                              <div
                                className={
                                  list.Teaching_ex_file !=="" &&
                                    list.Teaching_ex_file !==null
                                    ? "col-md-3 "
                                    : "col-md-12"
                                }
                              >
                                  <input
                                     className={
                                      list.Teaching_ex_file !=="" &&
                                        list.Teaching_ex_file !==null ? "InputsFile nn_upload_width" : "InputsFile  vv_upload_width"}
                                    type="file"
                                    name="Teaching_ex_file"
                                    id="Teaching_ex_file"
                                    onChange={handleChange}
                                    required
                                  />
                              
                               
                              </div>
                              {
                                list.Teaching_ex_file !=="" &&
                                list.Teaching_ex_file !==null && (

                                  <div
                                    className={"col-md-9 d-flex align-content-center p-0"}
                                  >
                                    <span className="nn_upload_file" >
                                      {list.Teaching_ex_file !=="" &&
                                        list.Teaching_ex_file !==null
                                        ? list.Teaching_ex_file.split("/").pop()
                                        : ""}
                                    </span>
                                    <span className="material-icons align-middle text-danger verified_upload">
                                      verified
                                    </span>
                                  </div>

                                )}

                                 </div>
                                
                                  {listErrors.Teaching_ex_file && (
                                    <p className="errors">
                                      {listErrors.Teaching_ex_file}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div> */}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>
              <section className=" StepOne ">
                <div className="row box ">
                  <div className="col-md-12">
                    <p className="StepTitle">Current Job Info</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label for="JType">Job Type:</label>
                          <div className="d-flex  col-md-12" id="radiobuttons">
                            {list.teachType === "0" && (
                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="teachType"
                                  id="Teaching"
                                  value="0"
                                  checked={list.teachType === "0"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Teaching"
                                >
                                  Teaching
                                </label>
                              </div>
                            )}

                            {list.teachType === "1" && (
                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="teachType"
                                  id="NonTeaching"
                                  value="1"
                                  checked={list.teachType === "1"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="NonTeaching"
                                >
                                  Non teaching
                                </label>
                              </div>
                            )}
                          </div>
                          {listErrors.teachType && (
                            <small className="errors">
                              {listErrors.teachType}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label for="designation">Designation:</label>
                          <select
                            ref={txtAdDes}
                            class="Inputs"
                            id="designation"
                            name="designation"
                            disabled={roleid !== 5 ? true : false}
                            value={list.designation}
                            onChange={handleChange}
                          >
                            <option value="">--- Designation ---</option>
                            {designation}
                          </select>

                          {listErrors.designation && (
                            <small className="errors">
                              {listErrors.designation}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label for="DJoin">Date of Joining:</label>
                          {/* <input
                              className="Inputs"
                              type="date"
                              disabled={roleid !== 5 ? true : false}
                              name="DJoin"
                              id="DJoin"
                              ref={txtAdDjoin}
                              value={list.DJoin}
                              onChange={handleChange}
                            /> */}
                          <DatePickerSample
                            disabledProperty={user_type !== 1 ? true : false}
                            txtAdMDob={txtAdDjoin}
                            name={"DJoin"}
                            handleChange={handleChangeCuDate}
                            fieldInput={list.DJoin}
                          />
                          {listErrors.DJoin && (
                            <small className="errors">{listErrors.DJoin}</small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label for="designation" title={`sdsd${user_type}`}>
                            Branch Location:
                          </label>
                          <select
                            ref={txtfacLoc}
                            class="Inputs"
                            id="location"
                            name="location"
                            value={list.location}
                            disabled={user_type !== 1 ? true : false}
                            onChange={handleChange}
                          >
                            <option value="">---Select Location ---</option>
                            {locations}
                          </select>

                          {listErrors.location && (
                            <small className="errors">
                              {listErrors.location}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className=" sectionFour">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Voice Your Opinion</p>
                    <div className="row  mb-4">
                      <div className="col-md-12">
                        <div className="forms ">
                          <div className="forms ">
                            <label htmlFor="VoiceOpinion">
                              Insights and Proposals
                            </label>
                            <textarea
                              ref={txtAdVoicepp}
                              name="VoiceOpinion"
                              id="VoiceOpinion"
                              className="Inputs"
                              onChange={handleChange}
                              defaultValue={list.VoiceOpinion}
                              placeholder="Insights and Proposals"
                            ></textarea>
                            {listErrors.VoiceOpinion && (
                              <p className="errors">
                                {listErrors.VoiceOpinion}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="heading  dflexbetww">
                  <div className="">
                    <button
                      type="button"
                      className="SaveButton_Prev"
                      onClick={handlePrevious}
                    >
                      <a href="javascript:void(0)">
                        {" "}
                        Go to Academic Background
                      </a>
                    </button>
                  </div>
                  <div className="d-flex smButton mobiledown">
                    <Link to="/" className="CancelButton">
                      Back to Home
                    </Link>
                    <button
                      type="button"
                      className="SaveButtons"
                      onClick={handleNext}
                    >
                      <a href="javascript:void(0)">
                        Save and Continue to Review and Confirm
                      </a>
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </fieldset>
      )}

      {step === 5 && (
        <fieldset>
          <div className="row">
            <div className="col-md-12">
              <section className="StepOne">
                <div className="row">
                  <div className="col-md-4 col-12 col-sm-12">
                    <button
                      type="button"
                      className="SaveButton_Prev "
                      onClick={handlePrevious}
                    >
                      <a href="javascript:void(0)">Go to Employment Details</a>
                    </button>
                  </div>
                  {!userId && (
                    <div className="col-md-8 col-12 col-sm-12">
                      <div className="heading d-flex justify-content-end ">
                        <button
                          type="button"
                          className="SaveButton"
                          onClick={handleSave}
                        >
                          <a href="javascript:void(0)"> Submit for Review</a>
                        </button>
                      </div>
                    </div>
                  )}
                  {userId && (
                    <div className="col-md-8 col-12 col-sm-12">
                      <div className="heading me-4  d-flex justify-content-end ">
                        <button
                          type="button"
                          className="SaveButton"
                          onClick={handleSubmit}
                        >
                          <a href="javascript:void(0)">Update and Submit</a>
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Basic Details</p>

                    <div className="row mb-3">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                First Name
                              </label>
                              <span className="ReviewLabel">
                                {list.FirstName || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Last Name
                              </label>
                              <span className="ReviewLabel">
                                {list.LastName || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Father's Name/Guardian's Name
                              </label>
                              <span className="ReviewLabel">
                                {" "}
                                {list.Fathername || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Date of Birth
                              </label>
                              <span className="ReviewLabel">
                                {list.DOB || ""}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Age
                              </label>
                              <span className="ReviewLabel">
                                {list.age || ""}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Gender
                              </label>
                              <span className="ReviewLabel">
                                {list.gender || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Place of Birth
                              </label>
                              <span className="ReviewLabel">
                                {list.PlaceBirth || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marital Status
                              </label>
                              <span className="ReviewLabel">
                                {" "}
                                {list.MaritalStatus || ""}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Name of the Spouse
                              </label>
                              <span className="ReviewLabel">
                                {" "}
                                {list.Spouse || ""}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Occupation of the Spouse
                              </label>
                              <span className="ReviewLabel">
                                {" "}
                                {list.Occupation || ""}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Status
                              </label>{" "}
                              {list.status === 0 && (
                                <span className="ReviewLabel">Email Sent</span>
                              )}
                              {list.status === 1 && (
                                <span className="ReviewLabel">
                                  Waiting For Approval
                                </span>
                              )}
                              {list.status === 4 && (
                                <span className="ReviewLabel">Active</span>
                              )}
                              {(list.status === 2 || list.status === 3) && (
                                <span className="ReviewLabel">InActive</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-4">
                        <div className="forms mb-2  ">
                          <div id="profilephotos">
                            {list.Profilephoto && (
                              <img
                                src={`${ImageUrlPrefix}/${list.Profilephoto}`}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className=" StepOne">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Contact details</p>

                    <div className="row mb-3">
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Phone Number
                          </label>
                          <span className="ReviewLabel">
                            {list.Mobile || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            WhatsApp Number
                          </label>
                          <span className="ReviewLabel">
                            {list.ContactNumber === true
                              ? list.Mobile
                              : list.WhatsAppNumber}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Email ID
                          </label>
                          <span className="ReviewLabel">
                            {list.emailaddress || ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className=" StepOne">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">ID Proofs</p>

                    <div className="row mb-3">
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            AADHAR Number
                          </label>
                          <span className="ReviewLabel">
                            {list.aadharNo || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Upload AADHAR Card
                            {list.aadharCardphoto && (
                              <a
                                href={`${ImageUrlPrefix}/${list.aadharCardphoto}`}
                              >
                                {" "}
                                <Download className="download_icon" />
                              </a>
                            )}
                          </label>
                          <span className="ReviewLabel">
                            {list.original_aadhar_path || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            PAN Number
                          </label>
                          <span className="ReviewLabel">
                            {list.panNo || ""}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Uploaded PAN Card
                            {list.panPhoto && (
                              <a href={`${ImageUrlPrefix}/${list.panPhoto}`}>
                                {" "}
                                <Download className="download_icon" />
                              </a>
                            )}
                          </label>
                          <span className="ReviewLabel">
                            {list.panPhoto !== null
                              ? list.panPhoto.split("/").pop()
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div className="StepOne">
                <div className="row">
                  <div className="col-md-6">
                    <section className="sectionSix">
                      <div className="row box">
                        <div className="col-md-12">
                          <p className="StepTitle">Present Address</p>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Present Address
                                </label>
                              </div>
                            </div>

                            <span className="ReviewLabel mb-2">
                              {" "}
                              {list.BuildingNumberPresent +
                                (list.BuildingNumberPresent ? "," : "") || ""}
                              {list.StreetNamePresent +
                                (list.StreetNamePresent ? "," : "") || ""}
                            </span>
                            <span className="ReviewLabel  mb-2">
                              {" "}
                              {list.AreaPresent +
                                (list.AreaPresent ? "," : "") || ""}
                              {list.LandmarkPresent +
                                (list.LandmarkPresent ? "," : "") || ""}
                            </span>
                            <span className="ReviewLabel  mb-2">
                              {" "}
                              {list.TalukPresent +
                                (list.TalukPresent ? "," : "") || ""}
                              {list.PostPresent +
                                (list.PostPresent ? "," : "") || ""}
                            </span>
                            <span className="ReviewLabel  mb-2">
                              {" "}
                              {list.PincodePresent +
                                (list.PincodePresent ? "," : "") || ""}
                              {list.CityPresent +
                                (list.CityPresent ? "," : "") || ""}
                            </span>
                            <span className="ReviewLabel  mb-2">
                              {" "}
                              {list.districtPresent +
                                (list.districtPresent ? "," : "") || ""}
                              {list.statePresent +
                                (list.statePresent ? "," : "") || ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="col-md-6">
                    <section className="sectionSix ">
                      <div className="row box">
                        <div className="col-md-12">
                          <p className="StepTitle">Permanent Address</p>

                          <div className="row mb-3">
                            <div className="col-md-12 col-lg-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {list.SamePresentAddress === true
                                    ? "Present Address"
                                    : "Permanent Address"}
                                </label>
                              </div>
                            </div>
                            <span className="ReviewLabel  mb-2">
                              {list.SamePresentAddress === true
                                ? list.BuildingNumberPresent +
                                  (list.BuildingNumberPresent ? "," : "")
                                : list.BuildingNumberPermanent +
                                    (list.BuildingNumberPermanent ? "," : "") ||
                                  ""}
                              {list.SamePresentAddress === true
                                ? list.StreetNamePresent +
                                  (list.StreetNamePresent ? "," : "")
                                : list.StreetNamePermanent +
                                    (list.StreetNamePermanent ? "," : "") || ""}
                            </span>
                            <span className="ReviewLabel  mb-2">
                              {" "}
                              {list.SamePresentAddress === true
                                ? list.AreaPresent +
                                  (list.AreaPresent ? "," : "")
                                : list.AreaPermanent +
                                    (list.AreaPermanent ? "," : "") || ""}
                              {list.SamePresentAddress === true
                                ? list.LandmarkPresent +
                                  (list.LandmarkPresent ? "," : "")
                                : list.LandmarkPermanent +
                                    (list.LandmarkPermanent ? "," : "") || ""}
                            </span>
                            <span className="ReviewLabel  mb-2">
                              {" "}
                              {list.SamePresentAddress === true
                                ? list.TalukPresent +
                                  (list.TalukPresent ? "," : "")
                                : list.TalukPermanent +
                                    (list.TalukPermanent ? "," : "") || ""}
                              {list.SamePresentAddress === true
                                ? list.PostPresent +
                                  (list.PostPresent ? "," : "")
                                : list.PostPermanent +
                                    (list.PostPermanent ? "," : "") || ""}
                            </span>
                            <span className="ReviewLabel  mb-2">
                              {" "}
                              {list.SamePresentAddress === true
                                ? list.PincodePresent +
                                  (list.PincodePresent ? "," : "")
                                : list.PincodePermanent +
                                    (list.PincodePermanent ? "," : "") || ""}
                              {list.SamePresentAddress === true
                                ? list.CityPresent +
                                  (list.CityPresent ? "," : "")
                                : list.CityPermanent +
                                    (list.CityPermanent ? "," : "") || ""}
                            </span>
                            <span className="ReviewLabel  mb-2">
                              {" "}
                              {list.SamePresentAddress === true
                                ? list.districtPresent +
                                  (list.districtPresent ? "," : "")
                                : list.districtPermanent +
                                    (list.districtPermanent ? "," : "") || ""}
                              {list.SamePresentAddress === true
                                ? list.statePresent +
                                  (list.statePresent ? "," : "")
                                : list.statePermanent +
                                    (list.statePermanent ? "," : "") || ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>

              {educationQualification.includes("UG") && (
                <section className=" StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">UG</p>

                      <div className="row mb-3">
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Certificate Number
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[0].UGCertificateNumber || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Year of Passing
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {sslcCerficates[0].UGYearpassing || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Degree
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[0].UGDegree || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Major
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[0].UGMajor || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Marks Percentage
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[0].UGMarksPercentage || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Class
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[0].UGClassName || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Name of Institute
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[0].UGNameInstitute || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Uploaded UG Certificate
                              {sslcCerficates[0].UG_Path && (
                                <a
                                  target="_blank"
                                  href={`${ImageUrlPrefix}/${sslcCerficates[0].UG_Path}`}
                                >
                                  {" "}
                                  <Download className="download_icon" />
                                </a>
                              )}
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[0].UG_Path !== null
                                ? sslcCerficates[0].original_file_path
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {educationQualification.includes("PG") && (
                <section className="StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">PG</p>

                      <div className="row mb-3">
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Certificate Number
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[1].PGCertificateNumber || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Year of Passing
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {sslcCerficates[1].PGYearpassing || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Degree
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[1].PGDegree || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Major
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[1].PGMajor || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Marks Percentage
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[1].PGMarksPercentage || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              class
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[1].PGClassName || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Name of the college / university
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[1].PGNameInstitute || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Uploaded Certificate
                              {sslcCerficates[1].PG_Path && (
                                <a
                                  target="_blank"
                                  href={`${ImageUrlPrefix}/${sslcCerficates[1].PG_Path}`}
                                >
                                  {" "}
                                  <Download className="download_icon" />
                                </a>
                              )}
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[1].original_file_path || ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {educationQualification.includes("Integrated PG") && (
                <section className=" StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Integrated PG</p>

                      <div className="row mb-3">
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Certificate Number
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[2].IPCertificateNumber || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Year of Passing
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[2].IPYearpassing || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Degree
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[2].IPDegree || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Major
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[2].IPMajor || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Marks Percentage
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[2].IPMarksPercentage || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Class
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[2].IPClassName || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Name of the college / university
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[2].IPNameInstitute || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Uploaded Certificate
                              {sslcCerficates[2].IP_Path && (
                                <a
                                  target="_blank"
                                  href={`${ImageUrlPrefix}/${sslcCerficates[2].IP_Path}`}
                                >
                                  {" "}
                                  <Download className="download_icon" />
                                </a>
                              )}
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[2].original_file_path || ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {educationQualification.includes("phd") && (
                <section className=" StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">PHD</p>

                      <div className="row mb-3">
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Certificate Number
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[3].PHDCertificateNumber || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Year of Passing
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[3].PHDYearpassing || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Degree
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[3].PHDDegree || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Major
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[3].PHDMajor || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Marks Percentage
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[3].PHDMarksPercentage || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Class
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[3].PHDClassName || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Name of the college / university
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[3].PHDNameInstitute || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Uploaded Certificate
                              {sslcCerficates[3].PHD_Path && (
                                <a
                                  target="_blank"
                                  href={`${ImageUrlPrefix}/${sslcCerficates[3].PHD_Path}`}
                                >
                                  {" "}
                                  <Download className="download_icon" />
                                </a>
                              )}
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[3].original_file_path || ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {educationQualification.includes("Diploma") && (
                <section className=" StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Diploma</p>

                      <div className="row mb-3">
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Certificate Number
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[4].DCertificateNumber || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Year of Passing
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[4].DYearpassing || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Degree
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[4].DDegree || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Major
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[4].DMajor || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Marks of percentage
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[4].DMarksPercentage || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Class
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[4].DClassName || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Name of the college / university
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[4].DNameInstitute || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Uploaded Certificate
                              {sslcCerficates[4].D_Path && (
                                <a
                                  target="_blank"
                                  href={`${ImageUrlPrefix}/${sslcCerficates[4].D_Path}`}
                                >
                                  {" "}
                                  <Download className="download_icon" />
                                </a>
                              )}
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[4].D_Path !== null
                                ? sslcCerficates[4].D_Path.split("/").pop()
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {educationQualification.includes("Professional Courses") && (
                <section className=" StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Professional Courses</p>

                      <div className="row mb-3">
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Certificate Number
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[5].PCCertificateNumber || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Year of Passing
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {sslcCerficates[5].PCYearpassing || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Degree
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[5].PCDegree || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Major
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[5].PCMajor || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Marks Percentage
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[5].PCMarksPercentage || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Class
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[5].PCClassName || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Name of the college / university
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[5].PCNameInstitute || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Uploaded Certificate
                              {sslcCerficates[5].PC_Path && (
                                <a
                                  target="_blank"
                                  href={`${ImageUrlPrefix}/${sslcCerficates[5].PC_Path}`}
                                >
                                  {" "}
                                  <Download className="download_icon" />
                                </a>
                              )}
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[5].PC_Path !== null
                                ? sslcCerficates[5].original_file_path
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {educationQualification.includes("Others") && (
                <section className=" StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Others</p>

                      <div className="row mb-3">
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Certificate Number
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[6].OtCertificateNumber || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Year of Passing
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[6].OtYearpassing || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Degree
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[6].OtDegree || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Major
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[6].OtMajor || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Marks Percentage
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[6].OtMarksPercentage || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Class
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[6].OtClassName || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Name of the college / university
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[6].OtNameInstitute || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Uploaded Certificate
                              {sslcCerficates[6].Ot_Path && (
                                <a
                                  target="_blank"
                                  href={`${ImageUrlPrefix}/${sslcCerficates[6].Ot_Path}`}
                                >
                                  {" "}
                                  <Download className="download_icon" />
                                </a>
                              )}
                            </label>
                            <span className="ReviewLabel">
                              {sslcCerficates[6].Ot_Path !== null
                                ? sslcCerficates[6].original_file_path
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              <div className="StepOne">
                <div className="row">
                  {techQualification.length > 0 &&
                    !(
                      techQualification[0].name === "" &&
                      techQualification[0].level === "" &&
                      techQualification[0].lang === "" &&
                      techQualification[0].Cnum === "" &&
                      techQualification[0].grade === "" &&
                      techQualification[0].year === "" &&
                      techQualification[0].file === ""
                    ) &&
                    techQualification.map((el, index) => (
                      <div
                        className={
                          techQualification.length === 1
                            ? "col-md-12 "
                            : "col-md-12 "
                        }
                        key={index}
                      >
                        <section className=" sectionSix">
                          <div className="row box ">
                            <div className="col-md-12 ">
                              <p className="StepTitle">
                                Technical Qualification{" "}
                                {techQualification.length === 1
                                  ? " "
                                  : `- ${el.id}`}
                              </p>

                              <div className="row mb-3">
                                <div className="col-lg-3 col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      Technicnal Course
                                    </label>
                                    <span className="ReviewLabel">
                                      {el.name || ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-3 col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      Typewriting Level
                                    </label>
                                    <span className="ReviewLabel">
                                      {el.level || ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-3 col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      Language
                                    </label>
                                    <span className="ReviewLabel">
                                      {el.lang || ""}
                                    </span>
                                  </div>
                                </div>

                                {/* <div className="col-lg-3 col-md-6">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                       knowledge of computer
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.kComputer || ""}
                                      </span>
                                    </div>
                                  </div> */}

                                <div className="col-lg-3 col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      Certificate Number
                                    </label>
                                    <span className="ReviewLabel">
                                      {el.Cnum || ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-3 col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      Grade
                                    </label>
                                    <span className="ReviewLabel">
                                      {el.grade || ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-3 col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      Year of Passing
                                    </label>
                                    <span className="ReviewLabel">
                                      {el.year !== "" ? el.year : ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-3 col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      Upload Certificate
                                      {el.file && (
                                        <a
                                          target="_blank"
                                          href={`${ImageUrlPrefix}/${el.file}`}
                                        >
                                          {" "}
                                          <Download className="download_icon" />
                                        </a>
                                      )}
                                    </label>
                                    <span className="ReviewLabel">
                                      {el.original_file_path || ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    ))}
                </div>
              </div>

              <div className="StepOne">
                <div className="row">
                  <div className="col-md-12">
                    <section className="StepOne">
                      <div className="row box">
                        <div className="col-md-12">
                          <p className="StepTitle">
                            Special / Preferential Qualification (if any)
                          </p>

                          <div className="row mb-3">
                            <div className="col-md-12">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Special / Preferential Qualification
                                </label>
                              </div>
                            </div>

                            <span className="ReviewLabel mb-2">{list.spq}</span>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>

              <div className="StepOne">
                <div className="row">
                  <div className="col-md-12">
                    <section className="StepOne">
                      <div className="row box">
                        <div className="col-md-12">
                          <p className="StepTitle">Previous Experience</p>

                          <div className="row ">
                            <div className="col-md-12">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Do you have any previous working or teaching
                                  experience?
                                </label>
                              </div>
                            </div>
                            <span className="ReviewLabel mb-2 mt-2">
                              {list.prevTeachexp}
                            </span>
                          </div>

                          <div className="row ">
                            <div className="col-md-12">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Working Experience
                                </label>
                              </div>
                            </div>
                            <span className="ReviewLabel mb-2">
                              {list.prevTeachexp === "Yes"
                                ? list.workingexp
                                : ""}
                            </span>
                          </div>
                          <div className="row ">
                            <div className="col-md-12">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Working Experience File{" "}
                                  {list.working_ex_file !== "" &&
                                    list.working_ex_file !== null && (
                                      <a
                                        href={`${ImageUrlPrefix}/${list.working_ex_file}`}
                                        target="_blank"
                                        download
                                      >
                                        <span className="material-icons align-middle download_icon">
                                          file_download
                                        </span>
                                      </a>
                                    )}
                                </label>
                              </div>
                            </div>
                            <span className="ReviewLabel mb-2">
                              {list.prevTeachexp === "Yes"
                                ? list.original_workexp
                                : ""}
                            </span>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Teaching Experience
                                </label>
                              </div>
                            </div>
                            <span className="ReviewLabel mb-2">
                              {list.prevTeachexp === "Yes" ? list.teachexp : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <section className=" StepOne">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Current Job Info</p>

                    <div className="row mb-3">
                      <div className="col-lg-3 col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Job Type
                          </label>
                          <span className="ReviewLabel">
                            {list.teachType === "0"
                              ? "Teaching"
                              : "NonTeaching" || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Designation
                          </label>
                          <span className="ReviewLabel">
                            {list.designation || ""}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Date of Joining
                          </label>
                          <span className="ReviewLabel">
                            {list.DJoin || ""}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Branch Location
                          </label>
                          <span className="ReviewLabel">
                            {list.location_name || ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="StepOne">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Voice Your Opinion</p>

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Insights and Proposals
                          </label>
                        </div>
                      </div>

                      <span className="ReviewLabel mb-2">
                        {list.VoiceOpinion}
                      </span>
                    </div>
                  </div>
                </div>
                {!userId && (
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <div
                        className="form-check form-check-inline"
                        id="Verifycheckbox"
                      >
                        <input
                          ref={txtadVerifyHere}
                          className="form-check-input"
                          type="checkbox"
                          id="Verify"
                          name="Verify"
                          onChange={handleChange}
                          checked={list.Verify === true ? true : false}
                          value="Yes"
                        />
                        <label className="form-check-label" htmlFor="Verify">
                          <a
                            className="LablePrivacyText"
                            href="javascript:void(0)"
                          >
                            I hereby certify that the above information are true
                            and correct to the best of my knowledge and belief.
                          </a>
                          <a
                            className="LablePrivacy"
                            href={require("../../../../PrivacyPolicyTermsCondition.pdf")}
                            target="_blank"
                          >
                            Terms and Conditions
                          </a>
                        </label>
                        {listErrors.Verify && (
                          <span className="errors align-top ms-3">
                            {listErrors.Verify}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {!userId && (
                  <div className="row">
                    <div className="col-md-4">
                      <button
                        type="button"
                        className="SaveButton_Prev "
                        onClick={handlePrevious}
                      >
                        {" "}
                        <a href="javascript:void(0)">
                          {" "}
                          Go to Employment Details
                        </a>
                      </button>
                    </div>
                    <div className="col-md-8">
                      <div className="heading d-flex justify-content-end ">
                        <button
                          type="button"
                          className="SaveButton"
                          onClick={handleSave}
                        >
                          <a href="javascript:void(0)">Submit for Review</a>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </section>
              {userId && (
                <section className="StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Review by Admin</p>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label for="status">Employee Status:</label>
                            <div
                              className="d-flex  col-md-12"
                              id="radiobuttons"
                            >
                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="Adminstatus"
                                  id="Active"
                                  value="4"
                                  checked={list.Adminstatus === "4"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Active"
                                >
                                  Active
                                </label>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="Adminstatus"
                                  id="Hold"
                                  value="2"
                                  checked={list.Adminstatus === "2"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Hold"
                                >
                                  Hold
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="Adminstatus"
                                  id="Reject"
                                  value="3"
                                  checked={list.Adminstatus === "3"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Reject"
                                >
                                  Reject
                                </label>
                              </div>
                            </div>
                            {listErrors.Adminstatus && (
                              <p className="errors">{listErrors.Adminstatus}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Comments
                            </label>
                            <textarea
                              name="comments"
                              id="comments"
                              onChange={handleChange}
                              defaultValue={list.comments}
                              className="Inputs"
                              placeholder="Comments"
                            ></textarea>
                            {listErrors.comments && (
                              <p className="errors">{listErrors.comments}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)">
                          Go to Employment Details
                        </a>
                      </button>
                    </div>
                    <div className="col-md-8">
                      <div className="heading  d-flex justify-content-end ">
                        <Link to="/Faculties/2" className="CancelButton">
                          Back to Faculties
                        </Link>
                        <button
                          type="button"
                          className="SaveButton"
                          onClick={handleSubmit}
                        >
                          <a href="javascript:void(0)">Update and Submit</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
}
export default NonFacultyProfile;
