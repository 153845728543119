import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix } from "../../../../labels";
import Swal from "sweetalert2";

export default function DetailTestView() {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location);
  const { id, status } = useParams();

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot available in localstorge");
  }

  useEffect(() => {
    // console.log(id, "id");
    AxiosInstance.post("api/event/findById", {
      id:parseInt(id),
      event_type: 1,
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          let el = res.data.data;
          setFdata((prevFdata) => ({
            ...prevFdata,
            title: el.name,
            sDate:
              el.start_date !==null
                ? moment(el.start_date).format("YYYY-MM-DD")
                : "",
            startTime: el.start_time,
            endTime: el.end_time,
            subject: el.subject,
            staff_name: el.staff_name,
            description: el.description,
            batchName: el.batch_name,
            category: el.category,
            course: el.category_class,
            classMode: el.class_mode,
            medium: el.medium,
            location: el.location,
            teams_url: el.teams_url,
            batch_id: el.batch_id,
            event_id: el.id,
            test_num: el.test_no,
            test_topic: el.test_topic,
            tol_mark: el.tot_marks,
            no_ques: el.no_of_questions,
            qpath: el.que_paper_filepath,
          }));
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);

  const [fdata, setFdata] = useState({
    title: "",
    sDate: "",
    startTime: "",
    endTime: "",
    subject: "",
    staff_name: "",
    description: "",
    batchName: "",
    category: "",
    course: "",
    classMode: "",
    medium: "",
    location: "",
    teams_url: "",
    batch_id: "",
    event_id: "",
    test_num: "",
    test_topic: "",
    tol_mark: "",
    no_ques: "",
    qpath: null,
  });

  const Upload_Question = () => {
    navigate(`/UploadQuestionFile`, {
      state: {
        id,
        status,
      },
    });
  };

  const handleNavigate = () => {

    const eventName = fdata.title;
    const bachId = fdata.batch_id;

    // console.log(bachId,"bachId");

    navigate('/ViewQuestionPaper',{
      state: {
        id,
        status,
        eventName,
        bachId
      },
    });
  }

  return (
    <div className="BathDet">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading  mt-3 d-flex justify-content-between ">
              <h6
                className={
                  status === "1" && fdata.qpath !==null
                    ? "Tits_detail"
                    : "Tits_details"
                }
              >
                {" "}
                View Test
              </h6>
              <Link to="/TestList" className="CancelButton">
                Back to Test
              </Link>
              <button
                type="button"
                className="detailEventButton"
                onClick={Upload_Question}
              >
                {" "}
                <span class="material-icons me-1">upload</span><a href="javascript:void(0)">Upload New
                Question Paper</a>
              </button>
              {fdata.qpath !==null && fdata.qpath !=="" && status === "1" && (
                <button type="button" className="detailEventButtons" onClick={handleNavigate}>
                  {" "}
                  <span class="material-icons me-2">visibility</span><a href="javascript:void(0)">View
                  Question Paper</a>
                </button>
              )}
            </div>
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Event Details</p>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Event Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.title !==null ? fdata.title : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Test Number
                      </label>
                      <span className="ReviewLabel">
                        {fdata.test_num !==null ? fdata.test_num : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Test Topic
                      </label>
                      <span className="ReviewLabel">
                        {fdata.test_topic !==null ? fdata.test_topic : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Total Number of Questions
                      </label>
                      <span className="ReviewLabel">
                        {fdata.no_ques !==null ? fdata.no_ques : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Total Marks
                      </label>
                      <span className="ReviewLabel">
                        {fdata.tol_mark !==null ? fdata.tol_mark : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Batch Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.batchName !==null ? fdata.batchName : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Category
                      </label>
                      <span className="ReviewLabel">
                        {fdata.category !==null ? fdata.category : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Course
                      </label>
                      <span className="ReviewLabel">
                        {fdata.course !==null ? fdata.course : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Date
                      </label>
                      <span className="ReviewLabel">
                        {fdata.sDate !==null ? fdata.sDate : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Start Timing
                      </label>
                      <span className="ReviewLabel">
                        {fdata.startTime !==null
                          ? moment(fdata.startTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        End Timing
                      </label>
                      <span className="ReviewLabel">
                        {fdata.endTime !==null
                          ? moment(fdata.endTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Subject
                      </label>
                      <span className="ReviewLabel">
                        {fdata.subject !==null ? fdata.subject : "⎯"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Uploaded Question Paper</p>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Uploaded Question Paper
                        {fdata.qpath !=="" && fdata.qpath !==null && (
                          <a
                            href={`${ImageUrlPrefix}/${fdata.qpath}`}
                            target="_blank"
                            download
                          >
                            <span className="material-icons align-middle download_icon">
                              file_download
                            </span>
                          </a>
                        )}
                      </label>
                      <span className="ReviewLabel">
                        {fdata.qpath !==null ? (
                          <a
                            target="_blank"
                            className="questionpapera"
                            href={`${ImageUrlPrefix}/${fdata.qpath}`}
                          >
                            {fdata.qpath.split("/").pop()}
                          </a>
                        ) : (
                          "⎯"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
