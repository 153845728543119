import React from "react";
import Select, { components } from "react-select";
import { ImageUrlPrefix } from "../../../../labels";
import DatePickerSample from "../../DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import TimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import { useParams } from "react-router-dom";

function ExamDetail(props) {
  const { id: batchID } = useParams();
  const TODAY = new Date();
  const calculateEndTime = (starttime, duration) => {
    if (starttime && duration !== "") {
      const startParts = starttime.split(":");
      const hoursToAdd = parseInt(duration, 10); // Parse duration to integer
      const newHour = parseInt(startParts[0], 10) + hoursToAdd;
      const newMinute = parseInt(startParts[1], 10);
      const newSecond = parseInt(startParts[2], 10); // Add this line for seconds

      // Ensure that newHour, newMinute, and newSecond are represented with two digits
      const formattedHour = newHour.toString().padStart(2, "0");
      const formattedMinute = newMinute.toString().padStart(2, "0");
      const formattedSecond = newSecond.toString().padStart(2, "0"); // Add this line for seconds

      // Use the same format as the input
      const endTime = `${formattedHour}:${formattedMinute}:${formattedSecond}`;
      // console.log(endTime, "endTime");
      return endTime;
    } else {
      return "";
    }
  };

  console.log(props, "props");

  return (
    <div className="row box ">
      <div className="col-md-12">
        <p className="StepTitle">
          {" "}
          Model Exam {props.record.id > 0 ? "-" : ""}{" "}
          {props.record.id > 0 ? props.record.id : ""} Details
        </p>
        <div className="row mb-4" style={{ paddingRight: "20px" }}>
          {/* title */}
          <div className="col-md-6">
            <div className="forms ">
              <label for="title">
                {props.papersDetails === "0"
                  ? "Exam Title"
                  : " Model Exam Title"}
              </label>
              <input
                ref={props.txtBatchETitle}
                type="text"
                class="Inputs"
                id="title"
                name="title"
                placeholder="Exam Title"
                value={props.record.title}
                onChange={(e) => props.examdetailschange(e, props.record.id)}
              />
              {props.record.error_1 && (
                <small className="error">{props.record.error_1}</small>
              )}
            </div>
          </div>
          {/* last date apply */}
          <div className="col-md-6">
            <div className="forms ">
              <label for="LastDateApply">Last Date To Apply:</label>
              <DatePickerSample
                minDate={moment().add(1, "day").toDate()}
                // minDate={props.papersDetails === "1"?new Date(TODAY.getFullYear(),TODAY.getMonth(),TODAY.getDate()+1):props.minLastDate}
                // txtAdMDob={props.record.txtBatchDapply}
                txtAdMDob={props.txtBatchDapply}
                name={"LastDateApply"}
                handleIdChange={(date) =>
                  props.examdetailschange_date(
                    date,
                    props.record.id,
                    "LastDateApply"
                  )
                }
                fieldsId={props.record.id}
                // fieldInput={props.record.LastDateApply}
                // value={props.record.LastDateApply}
                fieldInput={
                  props.record.LastDateApply
                    ? moment(props.record.LastDateApply).format("DD/MM/YYYY")
                    : props.record.LastDateApply
                }
              />
              {props.record.error_8 && (
                <small className="error">{props.record.error_8}</small>
              )}
            </div>
          </div>
          {/* start time */}
          {/* {props.papersDetails === "0" && (
            <div className="col-md-6">
              <div className="forms ">
                <label for="startTime">Start Time:</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    ref={props.txtBatchEstartTime}
                    id="startTime"
                    step="1"
                    name="startTime"
                    placeholder="Enter startTime"
                    title={props.record.startTime}
                    value={dayjs(props.record.startTime, "HH:mm:ss")}
                    format="hh:mm:ss A"
                    onChange={(e) =>
                      props.examdetailschangeTime(
                        e,
                        props.record.id,
                        "startTime"
                      )
                    }
                  />
                </LocalizationProvider>
                {props.record.error_4 && (
                  <small className="error">{props.record.error_4}</small>
                )}
              </div>
            </div>
          )} */}
          {/* end time */}
          {/* {props.papersDetails === "0" && (
            <div className="col-md-6">
              <div className="forms ">
                <label for="endTime">End Time:</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    ref={props.txtBatchEendTime}
                    id="endTime"
                    step="1"
                    name="endTime"
                    placeholder="Enter endTime"
                    title={props.record.endTime}
                    value={dayjs(props.record.endTime, "HH:mm:ss")}
                    format="hh:mm:ss A"
                     minTime={props.record.startTime !==null &&  props.record.startTime !=="" ? dayjs(props.record.startTime, "HH:mm:ss").startOf("day") : ""}
                    onChange={(e) =>
                      props.examdetailschangeTime(e, props.record.id, "endTime")
                    }
                  />
                </LocalizationProvider>
              
                {props.record.error_5 && (
                  <small className="error">{props.record.error_5}</small>
                )}
              </div>
            </div>
          )} */}

          {/* start time */}
          {props.papersDetails === "0" && (
            <div className="col-md-6">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className="forms " style={{ position: "relative" }}>
                  <label for="startTime">Start Time:</label>
                  <MobileTimePicker
                    // label="lakshmana"
                    // value={moment(new Date())}
                    // views={["hours", "minutes", "seconds"]}
                    // onChange={(e) => props.handleLaksh(e)}
                    ref={props.txtBatchEstartTime}
                    id="startTime"
                    step="1"
                    name="startTime"
                    placeholder="Enter startTime"
                    title={props.record.startTime}
                    value={moment(props.record.startTime)}
                    defaultValue="hh:mm:ss"
                    format="hh:mm:ss A"
                    onChange={(e) =>
                      props.onHandleExamTimeChange(
                        e,
                        props.record.id,
                        "startTime"
                      )
                    }
                  />
                  <TimeIcon
                    color="primary"
                    style={{
                      position: "absolute",
                      top: "50px",
                      right: "10px",
                    }}
                  />
                </div>
              </LocalizationProvider>
              {props.record.error_4 && (
                <small className="error">{props.record.error_4}</small>
              )}
            </div>
          )}
          {/* end time */}
          {props.papersDetails === "0" && (
            <div className="col-md-6">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className="forms " style={{ position: "relative" }}>
                  <label for="startTime">End Time:</label>
                  <MobileTimePicker
                    // label="lakshmana"
                    // value={moment(new Date())}
                    // views={["hours", "minutes", "seconds"]}
                    // onChange={(e) => props.handleLaksh(e)}
                    ref={props.txtBatchEendTime}
                    id="endTime"
                    step="1"
                    name="endTime"
                    placeholder="Enter endTime"
                    title={props.record.endTime}
                    value={moment(props.record.endTime)}
                    // value={
                    //   props.record.endTime ? moment(props.record.endTime) : null
                    // }
                    // defaultValue=""
                    format="hh:mm:ss A"
                    onChange={(e) =>
                      props.onHandleExamTimeChange(
                        e,
                        props.record.id,
                        "endTime"
                      )
                    }
                  />
                  <TimeIcon
                    color="primary"
                    style={{
                      position: "absolute",
                      top: "50px",
                      right: "10px",
                    }}
                  />
                </div>
              </LocalizationProvider>
              {props.record.error_5 && (
                <small className="error">{props.record.error_5}</small>
              )}
            </div>
          )}

          {/* duration */}
          {props.papersDetails === "0" && (
            <div className="col-md-6">
              <div className="forms ">
                <label for="duration">Duration (in Hours)</label>
                <input
                  ref={props.txtBatchEduration}
                  type="number"
                  class="Inputs"
                  id="duration"
                  name="duration"
                  disabled
                  placeholder="Duration (in Hours)"
                  value={props.record.duration < 0 ? 0 : props.record.duration}
                  onChange={(e) => props.examdetailschange(e, props.record.id)}
                />
                {props.record.error_3 && (
                  <small className="error">{props.record.error_3}</small>
                )}
              </div>
            </div>
          )}
          {/* date */}
          {props.papersDetails === "0" && (
            <div className="col-md-6">
              <div className="forms ">
                <label for="date">Exam Date</label>
                {/* <input
    ref={props.txtBatchEdate}
    type="date"
    class="Inputs"
    id="date"
    name="date"
    placeholder="date"
    value={props.record.date}
    min={props.mindate !=null ? props.mindate : ""}
    onChange={(e) => props.examdetailschange(e, props.record.id)}
  /> */}
                {console.log("laksh-props", props.record.LastDateApply)}
                <DatePickerSample
                  // ? moment(props.record.LastDateApply).add(1, 'day').toDate() : ""
                  // minDate={
                  //   props.record.LastDateApply
                  // }
                  minDate={props.minExamDate}
                  // disabledProperty={!props.record.LastDateApply}
                  txtAdMDob={props.txtBatchEdate}
                  name={"date"}
                  handleIdChange={(date) =>
                    props.examdetailschange_date(date, props.record.id, "date")
                  }
                  fieldsId={props.record.id}
                  // fieldInput={props.record.date}
                  fieldInput={
                    props.record.date
                      ? moment(props.record.date).format("DD/MM/YYYY")
                      : props.record.date
                  }
                />
                <small
                  className="pt-1 "
                  style={{
                    display: props.record.LastDateApply
                      ? "none"
                      : "inline-block",
                    color: "grey",
                    textAlign: "center",
                  }}
                ></small>
                {props.record.error_2 && (
                  <small className="error">{props.record.error_2}</small>
                )}
              </div>
            </div>
          )}
          {/* no of papers */}
          {props.papersDetails === "1" && (
            <div className="col-md-6">
              <div className="forms ">
                <label for="NPapers">Number of Papers</label>
                <div className="d-flex align-items-center justify-content-start">
                  <input
                    ref={props.txtBatchNPapers}
                    type="number"
                    class="Inputs"
                    id="NPapers"
                    style={{ width: "100%" }}
                    name="NPapers"
                    readOnly
                    placeholder="Enter the No of Paper"
                    value={
                      props.record.NPapers && /^\d*$/.test(props.record.NPapers)
                        ? props.record.NPapers > 0
                          ? props.record.NPapers
                          : ""
                        : ""
                    }
                    onChange={(e) =>
                      props.examdetailschange(e, props.record.id)
                    }
                  />
                  <span
                    className="material-icons Increasecricle"
                    onClick={(e) =>
                      props.handleIncreasePaperModel(e, props.record.id)
                    }
                  >
                    add_circle
                  </span>
                  <span
                    className="material-icons Increasecricle"
                    onClick={(e) =>
                      props.handleDecreasePaperModel(e, props.record.id)
                    }
                  >
                    remove_circle
                  </span>
                </div>

                {props.record.error_9 && (
                  <small className="error">{props.record.error_9}</small>
                )}
              </div>
            </div>
          )}
          {/* upload syllabus */}
          {props.papersDetails === "0" && (
            <div className="col-md-6">
              <div className="forms ">
                <label for="syllabus">
                  Upload Exam Syllabus (in pdf/Img format):
                  {props.record.syllabus !== "" &&
                    props.record.syllabus !== null && (
                      <a
                        href={`${ImageUrlPrefix}/${props.record.syllabus}`}
                        target="_blank"
                        download
                      >
                        <span className="material-icons align-middle download_icon">
                          file_download
                        </span>
                      </a>
                    )}
                </label>
                <div className="row">
                  <div
                    className={
                      props.record.syllabus !== "" &&
                      props.record.syllabus !== null
                        ? "col-md-3 "
                        : "col-md-12"
                    }
                  >
                    <input
                      ref={props.txtBatchEsyllabus}
                      type="file"
                      className={
                        props.record.syllabus !== "" &&
                        props.record.syllabus !== null
                          ? "InputsFilessss nn_upload_width"
                          : "InputsFilessss  vv_upload_width"
                      }
                      id="syllabus"
                      name="syllabus"
                      onChange={(e) =>
                        props.examdetailschange(e, props.record.id)
                      }
                    />
                  </div>
                  {props.record.syllabus !== "" &&
                    props.record.syllabus !== null && (
                      <div
                        className={"col-md-9 d-flex align-content-center p-0"}
                      >
                        <span className="nn_upload_file">
                          {props.record.syllabus !== "" &&
                          props.record.syllabus !== null
                            ? props.record.original_syllabus_url
                            : ""}
                        </span>
                      </div>
                    )}
                </div>

                {props.record.error_6 && (
                  <small className="error">{props.record.error_6}</small>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="row mb-4">
          <div className="col-md-12 dflexendcolu">
            {/* <a
                className="AddMore me-3"
                onClick={() => props.DeleteExamDetails(props.record.id)}
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete This Exam
              </a> */}
            {props.record.id !== 1 && props.record.id === props.length && (
              <a
                className="AddMore me-3"
                // onClick={() => props.DeleteExamDetails(props.record.id)}
                onClick={() =>
                  props.DeleteExamDetails(
                    props.record.id,
                    props.record.pk,
                    batchID
                  )
                }
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete This Exam
              </a>
            )}
            {props.record.id !== props.length ? (
              <a
                className="AddMore"
                onClick={() =>
                  props.DeleteExamDetails(
                    props.record.id,
                    props.record.pk,
                    batchID
                  )
                }
                // onClick={() => props.DeleteExamDetails(props.record.id)}
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete This Exam
              </a>
            ) : (
              <a className="AddMore" onClick={props.AddExamDetails}>
                <span className="material-icons download_icon align-middle">
                  add_circle
                </span>
                Add More Exam
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ExamDetail;
