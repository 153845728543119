import React, { useEffect, useState } from 'react'
import { json, Link } from 'react-router-dom';

export default function Gallery() {

    const [imageData, setImageData] = useState([]);
    const [videoData, setVideoData] = useState([]);


const handleEdit=(index,field)=>
{
  window.location.href = `/galleryedit/${index}`;
}

const handleDelete=(index,type)=>
{
  // console.log(index,type,".....delete")

  const fileitem = localStorage.getItem(type);
  if (fileitem) {
    const files = JSON.parse(fileitem);
    const DeleteFile = files.filter((file, i) => i !== index);
    // console.log(DeleteFile,"....selectedCourse");
    localStorage.setItem(type,JSON.stringify(DeleteFile));
    window.location.reload();

  }
}

  useEffect(() => {
    const storedimagedata = localStorage.getItem('imageFile');
    if (storedimagedata) {
      setImageData( JSON.parse(storedimagedata));
    }

    const storedvideodata = localStorage.getItem('videoFile');
    if (storedvideodata) {
      setVideoData( JSON.parse(storedvideodata));
    }

  }, []);

  // console.log(videoData);
// 

  return (
    <>
    {/* <div className='p-5 '> */}
    <button className='btn btn-success gallery mt-4'><Link to="/galleryedit" className='gallery_link'>Create Gallery</Link>
</button>
    {/* </div> */}
  
    <div className='p-5'>
      <h1>Gallery List</h1>

       <div className='row mt-5'>
                 <h5 class="text-danger text-left">Images</h5>

   {(imageData.length > 0)? (imageData.map((url, index)  => (
            
      <div className="col-4   p-2" style={{width:'19rem',height:'28rem'}}  key={index}>
      <div class="card">
        <img src={url} class="card-img-top" alt="Image"  height={250} width={286}/>
    
        <div class="card-body">
        
         <button className='btn btn-primary me-2' onClick={()=>handleEdit(index,"imageFile")}>Edit</button>
       <button className='btn btn-danger' onClick={()=>handleDelete(index,"imageFile")}>Delete</button>
        </div>
      </div>             
        </div>
            
            ))):(<div class="alert alert-danger" role="alert">
  No image data found.
</div>)} 

                 <h5 class="text-danger text-left">Videos</h5>

   {(videoData.length > 0)? (videoData.map((url, index)  => (
            
      <div className="col-4  p-2" style={{width:'19rem',height:'28rem'}} key={index}>

      <div class="card ">
    <video controls class="card-img-top">
      <source src={url} type="video/mp4" style={{width:'19rem',height:'28rem'}}/>
    </video>
    <div class="card-body">
    
    <button className='btn btn-primary me-2' onClick={()=>handleEdit(index,"videoFile")}>Edit</button>
   <button className='btn btn-danger' onClick={()=>handleDelete(index,"videoFile")}>Delete</button>
    </div>
  </div>            
        </div>
            
            ))):(<div class="alert alert-danger" role="alert">
  No video data found.
</div>)} 
            </div>
     
   
        
    </div>   

  </>
  )
}
