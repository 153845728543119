import React, { useEffect, useState, useRef } from "react";
import "./CreateCourseBatch.css";
import { Error } from "../../../../Themes/themes";
import { AxiosInstance } from "../../../../axios/index";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import Select, { components } from "react-select";
import DistrictExam from "./DistrictExam";
import OldStudentBatch from "./OldStudentBatch";
import ExamDetail from "./ExamDetail";
import ExamFees from "./ExamFees";
import { Client } from "@microsoft/microsoft-graph-client";
import { PublicClientApplication } from "@azure/msal-browser";
import { Download } from "@mui/icons-material";
import { ClientID, ImageUrlPrefix, RedirectURL } from "../../../../labels";
import { type } from "@testing-library/user-event/dist/type";
import AddpanelMemberMocksec from "./AddpanelMemberMocksec";
import MockInterviewFees from "./MockInterviewFees";
import { AddCounsellingPanel } from "./AddCounsellingPanel";
import Counsellingfees from "./Counsellingfees";
import { customStyles } from "../../../../pages/customStyles";
import { multiCusutomStyle } from "../../../../pages/Multicustomstyles";
import {
  CustomCOption,
  OptionBB,
  OptionBatch,
} from "../../../../labels/Lableoption";
import DatePickerSample from "../../DatePicker";
import SelectDropdown from "./SelectDropdown";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import DropdownHeader from "./DropdownHeader";
import ModelComForP from "../../../../pages/ModelComForP";
import ModelExamDiscount from "./ModelExamDiscount";
import ExamPaperDetails from "./ExamPaperDetails";
import EditViewDistrict from "./EditViewDistrict";
import { date } from "yup";
// import { OptionBB } from "../../../../labels/Lableoption";

//multiple dropdown chip

function CreateCourseBatch() {
  const currentDate = moment().format("YYYY-MM-DD");

  const txtBatchCategory = useRef(null);
  const txtBatchPapers = useRef(null);
  const txtBatchSper = useRef(null);
  const txtBatch_batchImg = useRef(null);
  const txtBatchClassMode = useRef(null);
  const txtBatchMedium = useRef(null);
  const txtBatchName = useRef(null);
  const txtBatchLocation = useRef(null);
  const txtBatchNoS = useRef(null);
  const txtBatchCourse = useRef(null);
  const txtBatchSec = useRef(null);

  const txtBatchDuration = useRef(null);
  const txtBatchSdate = useRef(null);
  const txtBatchSdate_span = useRef(null);
  const txtBatchEsdate = useRef(null);
  const txtBatchEtime = useRef(null);
  const txtBatchStime = useRef(null);
  const txtBatchFees = useRef(null);
  const txtBatchSamount = useRef(null);
  const txtBatchMsg = useRef(null);

  const txtBatchNTest = useRef(null);
  const txtBatchTestIMg = useRef(null);
  const txtBatchSpSyll = useRef(null);
  const txtBatchSubject = useRef(null);
  const txtBatchExamName = useRef(null);
  const txtBatchPanelMembers = useRef(null);
  const txtBatchStaff = useRef(null);

  const txtBatchDisEdit = useRef(null);

  const txtBatchDis = useRef(null);
  const txtBatchDisCode = useRef(null);
  const txtBatchDEX = useRef(null);
  const txtBatchDisMC = useRef(null);

  const txtBatchNModelExam = useRef(null);
  const txtBatchMNPapers = useRef(null);
  const txtBatchMDExamTitle = useRef(null);
  const txtBatchDapply = useRef(null);

  const txtBatchCDate = useRef(null);

  const txtBatchETitle = useRef(null);
  const txtBatchEstartTime = useRef(null);
  const txtBatchEdate = useRef(null);
  const txtBatchEduration = useRef(null);
  const txtBatchNPapers = useRef(null);
  const txtBatchEendTime = useRef(null);
  const txtBatchEsyllabus = useRef(null);
  const txtBatchEFees = useRef(null);

  const txtBatchPETitle = useRef(null);
  const txtBatchPEstartTime = useRef(null);
  const txtBatchPEdate = useRef(null);
  const txtBatchPEduration = useRef(null);
  const txtBatchPEendTime = useRef(null);
  const txtBatchPEsyllabus = useRef(null);

  const txtBatchMPanelName = useRef(null);
  const txtBatchMADate = useRef(null);
  const txtBatchMPM = useRef(null);
  const txtBatchMSM = useRef(null);
  const txtBatchMFees = useRef(null);

  const txtBatchCPanelName = useRef(null);
  const txtBatchCStartTime = useRef(null);
  const txtBatchCCDate = useRef(null);
  const txtBatchCEndTime = useRef(null);
  const txtBatchCdept = useRef(null);
  const txtBatchCPM = useRef(null);
  const txtBatchCSM = useRef(null);
  const txtBatchCFees = useRef(null);

  const txtBatchMExamTitle = useRef(null);
  const txtBatchMDUr = useRef(null);
  const txtBatchMDate = useRef(null);
  const txtBatchMEndTime = useRef(null);
  const txtBatchMStartTime = useRef(null);
  const txtBatchMPath = useRef(null);
  const txtBatchMfees = useRef(null);

  const txtBatchOStudentAMount = useRef(null);
  const txtBatchOBatchFrom = useRef(null);
  const txtBatchOBatchTo = useRef(null);
  const txtBatchOApplicableBatch = useRef(null);

  const txtCMEF = useRef(null);

  const txtBatchMESFN = useRef(null);
  const txtBatchMESFNSF = useRef(null);
  const txtBatchMESFNSC = useRef(null);
  const txtBatchMESFNAB = useRef(null);

  const txtBatchPP = useRef(null);
  const txtBatchperOne = useRef(null);
  const txtBatchperTwo = useRef(null);
  const txtBatchperTwoDate = useRef(null);
  const txtBatchperThree = useRef(null);
  const txtBatchperThreeDate = useRef(null);
  const formatYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
  const formatDDMMYYYY = /^\d{2}-\d{2}-\d{4}$/;
  // Team Integration

  const msalConfig = {
    auth: {
      clientId: ClientID,
      redirectUri: RedirectURL,
    },
  };

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const msalInstance = new PublicClientApplication(msalConfig);
  const { Type, id } = useParams();
  //get user_id from localhost and set global

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  //navigate another component

  const navigate = useNavigate();

  const [editcourse, setEditcourse] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [subcategory_m, setSubcategory_m] = useState([]);

  const [uniqueCoursedata, setUniqueCoursedata] = useState([]);
  const [expandedSubCategory, setExpandedSubCategory] = useState(null);
  const [subjectselect, setSubjectselect] = useState([]);
  const [closeMenu, setCloseMenu] = useState();
  const [subjects, setSubjects] = useState([]);
  const [ccategory, setCcategory] = useState([]);
  // setting error value for all input fields
  const [error, setError] = useState({});
  const [locations, setLocations] = useState([]);
  const [dPart, setDPart] = useState([]);
  const [subject, setSubject] = useState([]);
  const [examination, setExamination] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [panelMem, setPanelMem] = useState([]);
  const [staffMem, setStaffMem] = useState([]);
  const [cStaffMem, setCStaffMem] = useState([]);
  const [examCenter, setExamCenter] = useState([]);
  const [eCenter, setECenter] = useState([]);
  const [district, setDistrict] = useState([]);
  const [oNGoingBatch, setONGoingBatch] = useState([]);
  const [selectedMem, setSelectedMem] = useState([]);
  const [selectedDistrict, setSelectDistrict] = useState([]);

  const [selectedStaffMem, setSelectedStaffMem] = useState([]);

  const [selectedDatesMock, setSelectedDatesMock] = useState([]);

  //district wise component design
  const [teamId, setTeamID] = useState();

  // model exam district | exam center

  const [districtWise, setDistrictWise] = useState([
    {
      id: 1,
      pk: "",
      district: "",
      districtCode: "",
      examCenter: [
        {
          ExamCenter_id: 1,
          examCenterOption: [],
          exam_center: "",
          MaxCount: 0,
          locationUrl: "",
          error_3: "",
          error_4: "",
          error_5: "",
        },
      ],
      error_1: "",
      error_2: "",
    },
  ]);

  const [swalMsg, setSwalMsg] = useState({
    show: false,
    status: "",
    message: "",
    title: "",
    BottomOkBtn: "",
    BottomCancelBtn: "",
    BottomOkClose: "",
  });

  // panel details for mock interview

  const [mockPanel, setMockPanel] = useState([
    {
      id: 1,
      panel_name: "",
      available_dates: [],
      panelMem: [],
      staffMem: [],
      fees: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
      error_5: "",
    },
  ]);

  // panel details for counselling

  const [cPanel, setCPanel] = useState([
    {
      id: 1,
      panel_name: "",
      date: "",
      StartTime: "",
      EndTime: "",
      department: [],
      CPaneMem: [],
      CStaffM: [],
      fees: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
      error_5: "",
      error_6: "",
      error_7: "",
      error_8: "",
    },
  ]);

  //exam details component design
  const [examDetail, setExamDetails] = useState([
    // {
    //      id: 1,
    //      title: "",
    //     exam_title_id: "",
    //     pk: "",
    //     date: "",
    //     duration: "",
    //     startTime: "",
    //     endTime: "",
    //     syllabus: null,
    //     original_syllabus_url: null,
    //     LastDateApply: "",
    //     NPapers: "",
    //     PaperDetailsSection: [],
    //     fees: "",
    //     error_1: "",
    //     error_2: "",
    //     error_3: "",
    //     error_4: "",
    //     error_5: "",
    //     error_6: "",
    //     error_7: "",
    // },
  ]);

  const [previousexam, setPreviousexam] = useState([]);
  const [batchlist, setBatchlist] = useState([]);

  const [subMulti, setSubMulti] = useState([]);

  const [initialfields, setInitialfields] = useState({
    name: "",
    section: "",
    courseCategory: "",
    courseSubCategory: "",
    courseNameCategory: "",
    subCategory: "",
    class_name: "",
    course_id: "",
    classMode: "",
    medium: "",
    fees: "",
    offerAmount: 0,
    duration: "",
    batchImg: null,
    original_img_url: null,
    batch: "",
    startDate: new Date().toISOString().substr(0, 10), // set default value to today's date
    // endDate: "",
    startTime: getCurrentTime(),
    startTime_In: "",
    endTime_In: "",
    endTime: "",
    NoStudents: "",
    NoTest: "",
    location: "2",
    Subject: "",
    OldSDiscount: "",
    OldSBatch: "",
    testDet: null,
    original_test_img_url: null,
    ExamName: "",
    ExamName_select: "",
    CDate: "",
    panelMem: "",
    ExamCenter: "",
    District: "",
    BatchRFrom: "",
    BatchRTo: "",
    DateApply: "",
    LastDateApply: false,
    team_id: null,
    mockInterview_endDate: "",
    special_syll: null,
    original_subject_file_path: null,
    pPayment: "",
    perOne: "",
    perTwo: "",
    perTwoDate: "",
    perThree: "",
    perThreeDate: "",
    Papers: "",
    NModelExam: "",
    MNPapers: "",
    MExamTitle: "",
  });
  // const itemsRef = useRef([]);
  const [mExamSF, setMExamSF] = useState([
    {
      id: 1,
      NoStudentBatch: "",
      SOfferDis: "",
      selectedOptions: [],
      ApplicableBatch: [],
      MEABatch: "",
      StudentBatchCount: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
      txtMeT: useRef(null),
      txtBatchMESFNSF: useRef(null),
      txtBatchMESFNSC: useRef(null),
      txtBatchMESFNAB: useRef(null),
    },
  ]);
  // current time get date foramt

  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const currentTime = `${hours}:${minutes}`;
    return currentTime;
  }

  function guessDateFormat(dateString) {
    if (formatYYYYMMDD.test(dateString)) {
      return moment(dateString).format("YYYY-MM-DD");
      // return "YYYY-MM-DD";
    } else if (formatDDMMYYYY.test(dateString)) {
      const parsedDate = moment(dateString, "DD-MM-YYYY"); // Parse with the detected format
      return parsedDate.format("YYYY-MM-DD"); // Reformat to "YYYY-MM-DD"
      // return  moment(dateString).format("DD-MM-YYYY")
      // return "DD-MM-YYYY";
    } else {
      return ""; // Unknown or invalid format
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  const handleWheel = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const inputElement = txtBatchNTest.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);
  useEffect(() => {
    const inputElement = txtBatchFees.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  useEffect(() => {
    const inputElement = txtBatchSamount.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);
  useEffect(() => {
    const inputElement = txtBatchNoS.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);
  //get course  for category

  useEffect(() => {
    if (initialfields.courseCategory !== "") {
      AxiosInstance.get(
        `/api/all/getCourses/${initialfields.courseCategory}/${parseInt(Type)}`
      )
        .then((res) => {
          const subcategory = res.data.data.map((item, index) => ({
            value: item.id,
            label: item.name,
            subCategory: item.subcategory_code,
            course_name: item.course_name,
          }));

          const subcategorys = res.data.data;

          const bb = res.data.data
            .filter((el) => el.subcategory_code)
            .map((el, index) => ({
              id: el.id,
              value: el.subcategory_code,
              label: el.subcategory_code,
              name: el.name,
              subCategory: el.subcategory_code,
              course_name: el.course_name,
              ind: index,
              isArr: true,
            }));
          const uniqueValuesSet = new Set();
          // Filter the array to get unique items based on the 'value' property
          const uniqueData = bb.filter((item) => {
            if (!uniqueValuesSet.has(item.subCategory)) {
              uniqueValuesSet.add(item.subCategory);
              return true;
            }
            return false;
          });

          //  console.log(uniqueData,"uniqueData");
          const Tempndex = uniqueData.map((el, index) => ({
            ...el,
            ind: index,
            isArr: true,
          }));
          // console.log(Tempndex,"Tempndex");
          setUniqueCoursedata(Tempndex);

          const options_class_name = transformData(res.data.data);
          setSubjects(options_class_name);

          setSubcategory(subcategorys);
          // console.log(subcategory,"subcategory");
          setSubcategory_m(subcategory);
          setSubjectselect([]);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [initialfields.courseCategory]);

  // useEffect(() => {
  //   itemsRef.current = itemsRef.current.slice(0, mExamSF.length);
  // }, [mExamSF]);

  useEffect(() => {
    if (
      initialfields.courseCategory !== "" &&
      initialfields.class_name !== ""
    ) {
      AxiosInstance.post("api/course/subject", {
        category_name: initialfields.courseCategory,
        course_name: initialfields.courseNameCategory,
        subcategory: initialfields.courseSubCategory,
        type: parseInt(Type),
      })
        .then((res) => {
          // console.log(res, "use effect");
          if (res.data.status === true) {
            if (res.data.data.length > 0) {
              const subject = res.data.data.map((item, index) => ({
                value: item.subject,
                label: item.subject,
                pk: item.id,
              }));
              setSubject(subject);
            } else {
              setSubject([]);
            }
          } else {
            setSubject([]);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [initialfields.courseCategory, initialfields.class_name]);

  //location - branch api binding

  useEffect(() => {
    AxiosInstance.post("api/branch/list")
      .then((res) => {
        // console.log(res, "locations");
        if (res.data.data.length > 0) {
          let locations = res.data.data.map((item, index) => (
            <option key={index} id={item.name} value={item.id}>
              {item.name}
            </option>
          ));
          setLocations(locations);
        } else {
          let locations = <option value="select"> Please select </option>;
          setLocations(locations);
        }
      })
      .catch((error) => {
        return error;
      });
    // counselling department dropdown
    AxiosInstance.get(`api/all/getDepartment/${parseInt(Type)}`)
      .then((res) => {
        const sortedDepartments = res.data.data
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort array of objects by name
          .map((item) => ({
            value: item.name,
            label: item.name,
          }));
        setDPart(sortedDepartments);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  // district based examcenter

  useEffect(() => {
    AxiosInstance.post("api/all/district/", {
      id: 31,
    })
      .then((res) => {
        const district = res.data.map((item, index) => ({
          value: item.name,
          district_id: item.id,
          label: item.name,
          district_code: item.district_code,
        }));
        setDistrict(district);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.post("api/batch/meSpcOff/ongoing")
      .then((res) => {
        const onGoingBatchss = res.data.data.map((item, index) => ({
          type: item.type,
          batch_id: item.batch_id,
          batch_name: item.batch_name,
          started_dt: item.started_dt,
          category_class: item.category_class,
          category: item.category,
          section: item.section,
          class_mode: item.class_mode,
          medium: item.medium,
          batch_type: item.batch_type,
          end_dt: item.end_dt,
          label: `${item.batch_name} - ${item.medium}${
            item.section !== null ? `(${item.section})` : ""
          }`,
          value: item.batch_id,
        }));
        const uniqueValuesSet = new Set();
        const uniqueData = onGoingBatchss.filter((item) => {
          if (!uniqueValuesSet.has(item.batch_name)) {
            uniqueValuesSet.add(item.batch_name);
            return true;
          }
          return false;
        });

        // console.log(uniqueData, "uniqueData");
        const Tempndex = uniqueData.map((el, index) => ({
          ...el,
          ind: index,
          isArr: true,
        }));
        // console.log(Tempndex, "Tempndex");
        setUniqueCoursedata(Tempndex);
        const options_class_name = transformDataOngoin(onGoingBatchss);
        setONGoingBatch(options_class_name);

        // setONGoingBatch(onGoingBatchss);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  //name of exam field api binding
  useEffect(() => {
    if (initialfields.courseCategory !== "") {
      AxiosInstance.post("api/all/getPassedExamName", {
        category: initialfields.courseCategory,
        type: Type,
      })
        .then((res) => {
          const previousexam = res.data.data.map((item, index) => (
            // { key: index, value: item.name }
            <option key={item.exam_name} value={item.id}>
              {item.exam_name}
            </option>
          ));
          setPreviousexam(previousexam);

          // const examination = res.data.data.map((item) => (
          //   <option key={item.name} value={item.id}>
          //     {item.exam_name}
          //   </option>
          // ));

          const examination = res.data.data.map((item, index) => ({
            value: item.id,
            label: item.exam_name,
          }));

          setExamination(examination);
        })
        .catch((error) => {
          return error;
        });
      AxiosInstance.post("api/all/getPanelMembers")
        .then((res) => {
          const panelMem = res.data.data.map((item, index) => ({
            value: item.full_name,
            label: item.full_name,
            staff_id: item.staff_id,
            type: item.staff_type,
            email: item.email,
            name: item.full_name,
          }));
          setPanelMem(panelMem);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [initialfields.courseCategory]);

  //course api binding
  useEffect(() => {
    AxiosInstance.get("api/codevalues?codetype=CURS")
      .then((res) => {
        const ccategory = res.data.map((item, index) => (
          <option key={index} value={item.name}>
            {item.name}
          </option>
        ));
        setCcategory(ccategory);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.post("api/all/getTeachingStaffList")
      .then((res) => {
        const staffMemPs = res.data.data.map((item, index) => ({
          value: item.full_name,
          label: item.full_name,
          staff_id: item.staff_id,
          type: item.staff_type,
          name: item.full_name,
          email: item.email,
        }));
        setStaffMem(staffMemPs);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  //find by id api binding
  useEffect(() => {
    AxiosInstance.post(`api/batch/findById`, {
      id: id,
      start_date: moment().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),
    })
      .then((res) => {
        // console.log(res, "use effect");
        if (res.data.status === true) {
          // basic info fileds
          // const today = moment();
          if (Object.keys(res.data.data.batch_info).length > 0) {
            let dateLast = res.data.data.batch_info.apply_last_date;
            const desiredDateTime = moment(dateLast);
            const currentDateTime = moment();
            const el = res.data.data.batch_info;
            setInitialfields({
              name: el.name,
              section: el.section,
              Papers: el.paper_type === 0 ? "0" : "1",
              NModelExam: el.no_of_modelexam ? el.no_of_modelexam : "",
              courseCategory: el.category,
              special_syll:
                el.subject_file_path !== null || el.subject_file_path !== ""
                  ? el.subject_file_path
                  : null,
              original_subject_file_path:
                el.original_subject_file_path !== null ||
                el.original_subject_file_path !== ""
                  ? el.original_subject_file_path
                  : null,
              courseNameCategory: el.category_class,
              courseSubCategory: el.subcategory,
              class_name: el.category_class,
              course_id: el.category_course_id,
              classMode: el.class_mode,
              medium: el.medium,
              batchImg:
                el.img_url !== null || el.img_url !== "" ? el.img_url : null,
              original_img_url:
                el.original_img_url !== null || el.original_img_url !== ""
                  ? el.original_img_url
                  : null,
              fees: el.fees,
              offerAmount: el.discount,
              duration: el.duration,
              pPayment:
                el.partial_payment && el.partial_payment === 1 ? "Yes" : "NO",
              perOne: el.pay_1_perc,
              perTwo: el.pay_2_perc,
              perTwoDate: el.pay_2_duedate
                ? moment(el.pay_2_duedate, "DD/MM/YYYY").format("YYYY-MM-DD")
                : "",
              perThree: el.pay_3_perc,
              perThreeDate: el.pay_3_duedate
                ? moment(el.pay_3_duedate, "DD/MM/YYYY").format("YYYY-MM-DD")
                : "",
              batch: el.batch_type,
              startDate: moment(el.started_dt).format("YYYY-MM-DD"),
              mockInterview_endDate: moment(el.end_dt).format("YYYY-MM-DD"),
              CDate: moment(el.started_dt).format("YYYY-MM-DD"),
              // endDate: res.data.data[0],
              startTime:
                el.start_time !== null
                  ? moment(el.start_time, "hh:mm:ss").format("hh:mm:ss a")
                  : "",
              startTime_In: `${moment(el.started_dt).format("YYYY-MM-DD")}T${
                el.start_time
              }`,
              endTime:
                el.end_time !== null
                  ? moment(el.end_time, "HH:mm:ss").format("hh:mm:ss a")
                  : "",
              endTime_In: `${moment(el.started_dt).format("YYYY-MM-DD")}T${
                el.end_time
              }`,
              NoStudents: el.no_of_students,
              NoTest: el.no_of_test,
              status:
                el.status === 0
                  ? "0"
                  : el.status === 1
                  ? "1"
                  : el.status === 2
                  ? "2"
                  : "",
              location: el.location_id !== null ? el.location_id : "2",
              DateApply: moment(el.apply_last_date).format("YYYY-MM-DD"),
              testDet:
                el.test_img_url !== null || el.test_img_url !== ""
                  ? el.test_img_url
                  : null,
              original_test_img_url:
                el.original_test_img_url !== null ||
                el.original_test_img_url !== ""
                  ? el.original_test_img_url
                  : null,
              ExamName: el.passed_exam_id,
              ExamName_select: el.passed_exam_name,
              message: el.message,
              team_id: el.team_id,
              LastDateApply: desiredDateTime.isAfter(currentDateTime)
                ? true
                : false,
            });
            const subMul =
              el.subject.length > 0
                ? el.subject.map((elll) => ({
                    label: elll.name,
                    value: elll.name,
                    pk: elll.pk,
                  }))
                : [];
            setSubMulti(subMul);
          }

          if (Object.keys(res.data.data.batch_exam_details).length > 0) {
            if (res.data.data.batch_exam_details.exam_details.length > 0) {
              const sssss = res.data.data.batch_exam_details.exam_details
                ?.sort((a, b) => {
                  return (
                    moment(a.last_dt_apply, "DD-MM-YYYY").toDate() -
                    moment(b.last_dt_apply, "DD-MM-YYYY").toDate()
                  );
                })
                ?.map((el, index) => {
                  const tempFinalDate = el.last_dt_apply.split("-");
                  const finalDate = new Date(
                    tempFinalDate[2],
                    +tempFinalDate[1] - 1,
                    tempFinalDate[0]
                  );
                  const hh = moment(finalDate).format("YYYY-MM-DD");

                  // start-end time
                  const TODAY = new Date();
                  let apiStartTime = null;
                  let apiEndTime = null;

                  if (el.paper_type === 0) {
                    const splittedStartTime = el.start_time?.split(":");
                    apiStartTime = new Date(
                      TODAY.getFullYear(),
                      TODAY.getMonth(),
                      TODAY.getDate(),
                      splittedStartTime[0],
                      splittedStartTime[1],
                      splittedStartTime[2]
                    );
                    const splittedEndTime = el.end_time?.split(":");
                    apiEndTime = new Date(
                      TODAY.getFullYear(),
                      TODAY.getMonth(),
                      TODAY.getDate(),
                      splittedEndTime[0],
                      splittedEndTime[1],
                      splittedEndTime[2]
                    );
                  }

                  // console.log(hh, "finalDate");

                  return {
                    id: index + 1,
                    exam_title_id: el.exam_title_id,
                    pk: el.pk,
                    title: el.exam_title,
                    date: el.started_dt
                      ? moment(el.started_dt).format("YYYY-MM-DD")
                      : "",
                    duration: el.duration,
                    // startTime: el.start_time,
                    // endTime: el.end_time,
                    startTime: apiStartTime,
                    endTime: apiEndTime,
                    syllabus: el.syllabus_url,
                    original_syllabus_url: el.original_syllabus_url,
                    LastDateApply: hh,

                    NPapers: el.no_of_paper,
                    PaperDetailsSection:
                      el.paper_detail.length > 0
                        ? el.paper_detail
                            ?.sort(
                              (a, b) =>
                                parseInt(a.paper_id) - parseInt(b.paper_id)
                            )
                            ?.map((elll, inf) => {
                              const splittedStartTimePaper =
                                elll.start_time?.split(":");
                              const apiStartTimePaper = new Date(
                                TODAY.getFullYear(),
                                TODAY.getMonth(),
                                TODAY.getDate(),
                                splittedStartTimePaper[0],
                                splittedStartTimePaper[1],
                                splittedStartTimePaper[2]
                              );
                              const splittedEndTimePaper =
                                elll.end_time?.split(":");
                              const apiEndTimePaper = new Date(
                                TODAY.getFullYear(),
                                TODAY.getMonth(),
                                TODAY.getDate(),
                                splittedEndTimePaper[0],
                                splittedEndTimePaper[1],
                                splittedEndTimePaper[2]
                              );

                              return {
                                id: inf + 1,
                                pk: elll.paper_id,
                                title: elll.paper_title,
                                date: elll.start_dt
                                  ? moment(elll.start_dt).format("YYYY-MM-DD")
                                  : "",
                                duration: elll.duration,
                                startTime: apiStartTimePaper,
                                endTime: apiEndTimePaper,
                                syllabus: elll.syllabus_path,
                                original_syllabus_url:
                                  elll.original_syllabus_filename,
                                error_1: "",
                                error_2: "",
                                error_3: "",
                                error_4: "",
                                error_5: "",
                                error_6: "",
                                error_7: "",
                              };
                            })
                        : [],
                    fees: "",
                    error_1: "",
                    error_2: "",
                    error_3: "",
                    error_4: "",
                    error_5: "",
                    error_6: "",
                    error_7: "",
                    error_8: "",
                    error_9: "",
                  };
                });
              console.log(sssss, "sssss");
              setExamDetails(sssss); // Set the initial data
            }
          }

          // model exam special offer
          if (
            res.data.data &&
            res.data.data.spc_offer &&
            res.data.data.spc_offer.length > 0
          ) {
            let speciModel = res.data.data.spc_offer.map((vb, index) => {
              let vhui = "";
              if (vb.discount_type === 0) {
                vhui = "1";
              } else if (vb.discount_type === 1) {
                vhui = "2";
              } else if (vb.discount_type === 2) {
                vhui = "3";
              } else if (vb.discount_type === 3) {
                vhui = "4";
              }
              const bhu =
                vb.applicable_batches.length > 0
                  ? vb.applicable_batches.map((el) => ({
                      id: el.id,
                      label: el.batch_name,
                      value: el.id,
                    }))
                  : [];
              return {
                id: index + 1,
                pk: vb.id,
                NoStudentBatch: vhui,
                SOfferDis: vb.offer_perc,
                selectedOptions: bhu,
                ApplicableBatch: [],
                MEABatch: "",
                StudentBatchCount: vb.stud_count,
                error_1: "",
                error_2: "",
                error_3: "",
                error_4: "",
              };
            });
            setMExamSF(speciModel);
          }

          if (Object.keys(res.data.data.batch_exam_details).length > 0) {
            res.data.data.batch_exam_details.exam_fees.map((el, index) => {
              setExamDetails((prevState) =>
                prevState.map((q) => {
                  if (q.id === el.fees_count) {
                    return { ...q, fees: el.fees }; // Update the fees property
                  }
                  return q;
                })
              );
            });
          }

          if (res.data.data.batch_old_student_discount.length > 0) {
            const promises = res.data.data.batch_old_student_discount.map(
              (obj) => getOldBatchView(obj.batch_start, obj.batch_end)
            );
            Promise.all(promises)
              .then((results) => {
                let idCounter = 1; // Initialize the ID counter
                const result = Object.values(
                  res.data.data.batch_old_student_discount.reduce(
                    (acc, obj, index) => {
                      const BacthList = results[index].map((item) => ({
                        batch_id: item.batch_id,
                        batch_name: item.batch_name,
                        category_class: item.category_class,
                        started_dt: item.started_dt,
                        category: item.category,
                        section: item.section,
                        class_mode: item.class_mode,
                        medium: item.medium,
                        batch_type: item.batch_type,
                      }));

                      if (!acc[obj.pk]) {
                        acc[obj.pk] = {
                          id: idCounter,
                          OldDPercentage: obj.old_stud_disc,
                          BatchRFrom: moment(obj.batch_start).format(
                            "YYYY-MM-DD"
                          ),
                          BatchRTo: moment(obj.batch_end).format("YYYY-MM-DD"),
                          selectedOptions: [],
                          ApplicableBatch: [],
                          error_1: "",
                          error_2: "",
                          error_3: "",
                          error_4: "",
                        };
                        idCounter++; // Increment the ID counter
                      }

                      acc[obj.pk].ApplicableBatch =
                        getMultiSelectDropdown(BacthList);
                      const applicableBatches =
                        obj.applicable_batches.split(",");
                      const selectedBatchId = obj.applicable_bid.split(",");
                      acc[obj.pk].selectedOptions = applicableBatches.map(
                        (batch, index) => ({
                          id: selectedBatchId[index]
                            ? parseInt(selectedBatchId[index].trim())
                            : null,
                          cateOp: batch.trim(),
                          // value: batch.trim(),
                          value: selectedBatchId[index]
                            ? parseInt(selectedBatchId[index].trim())
                            : null,
                          label: batch.trim(),
                        })
                      );
                      return acc;
                    },
                    {}
                  )
                );
                // console.log(result, "smfksjdgd");
                setOldBatch(result);
              })
              .catch((error) => {
                console.error(error);
              });
          }
          // panel members fill
          if (Object.keys(res.data.data.batch_panels).length > 0) {
            if (res.data.data.batch_panels.panel_data.length > 0) {
              const updatedMockPanel = [...mockPanel];
              const updatedCPanel = [...cPanel];
              res.data.data.batch_panels.panel_data.forEach((el, index) => {
                let panelMem = [];
                let staffMem = [];
                let department = [];
                let CPaneMem = [];
                let CStaffM = [];
                if (el.panel_mebers_list.length > 0) {
                  el.panel_mebers_list.map((ko) => {
                    if (ko.type === 3) {
                      // panelMem
                      panelMem.push({
                        staff_id: ko.panel_member_id,
                        name: ko.panel_member_name,
                        label: ko.panel_member_name,
                        value: ko.panel_member_name,
                        type: ko.type,
                        department:
                          ko.department !== "" ? ko.department.split(",") : [],
                      });
                      CPaneMem.push({
                        staff_id: ko.panel_member_id,
                        name: ko.panel_member_name,
                        label: ko.panel_member_name,
                        value: ko.panel_member_name,
                        type: ko.type,
                        department:
                          ko.department !== "" ? ko.department.split(",") : [],
                      });
                      if (ko.department !== "") {
                        const bbd = ko.department.split(",");
                        department.push(bbd);
                      }
                    } else if (ko.type === 0) {
                      //  StaffMem
                      staffMem.push({
                        staff_id: ko.panel_member_id,
                        name: ko.panel_member_name,
                        label: ko.panel_member_name,
                        value: ko.panel_member_name,
                        type: ko.type,
                        department: "",
                      });
                      CStaffM.push({
                        staff_id: ko.panel_member_id,
                        name: ko.panel_member_name,
                        label: ko.panel_member_name,
                        value: ko.panel_member_name,
                        type: ko.type,
                        department: "",
                      });
                    }
                  });
                }
                updatedMockPanel[index] = {
                  ...updatedMockPanel[index],
                  id: index + 1,
                  pk: el.panel_cstm_pk,
                  panel_name: el.panel_name, // Update other properties as needed
                  available_dates: el.available_dates,
                  panelMem,
                  staffMem,
                  error_1: "",
                  error_2: "",
                  error_3: "",
                  error_4: "",
                  error_5: "",
                };
                const selectedDp = department.map((option) => ({
                  value: option,
                  label: option,
                }));
                updatedCPanel[index] = {
                  ...updatedCPanel[index],
                  id: index + 1,
                  pk: el.panel_cstm_pk,
                  panel_name: el.panel_name, // Update other properties as needed
                  date: el.available_dates[0], // first index only access because its not the array
                  department: selectedDp,
                  StartTime: el.start_time,
                  EndTime: el.end_time,
                  CPaneMem,
                  CStaffM,
                  error_1: "",
                  error_2: "",
                  error_3: "",
                  error_4: "",
                  error_5: "",
                  error_6: "",
                  error_7: "",
                  error_8: "",
                };
              });
              setMockPanel(updatedMockPanel);
              setCPanel(updatedCPanel);
            }
          }
          // panel members fees fill
          if (Object.keys(res.data.data.batch_panels).length > 0) {
            if (res.data.data.batch_panels.exam_fees.length > 0) {
              res.data.data.batch_panels.exam_fees.map((el, index) => {
                setMockPanel((prevState) =>
                  prevState.map((q) => {
                    if (q.id === el.fees_count) {
                      return { ...q, fees: el.fees }; // Update the fees property
                    }
                    return q;
                  })
                );
                setCPanel((prevState) =>
                  prevState.map((q) => {
                    if (q.id === el.fees_count) {
                      return { ...q, fees: el.fees }; // Update the fees property
                    }
                    return q;
                  })
                );
              });
            }
          }

          //  exam center fill
          if (res.data.data.batch_exam_center.length > 0) {
            const examCenterDistrict = res.data.data.batch_exam_center.map(
              (center) => ({
                value: center.exam_center_district,
                district_id: center.district_id,
                label: center.exam_center_district,
                district_code: center.exam_district_code,
              })
            );
            // console.log(examCenterDistrict, "examCenterDistrict");
            setSelectDistrict(examCenterDistrict);
            // const promises = res.data.data.batch_exam_center.map((obj) =>
            //   getExamCenterView(obj.exam_center_district)
            // );
            // Promise.all(promises)
            //   .then((results) => {
            //     const result = Object.values(
            //       res.data.data.batch_exam_center.reduce((acc, obj, index) => {
            //         const examCenterList = results[index].map((item) => ({
            //           id: item.id,
            //           value: item.name,
            //           label: item.name,
            //         }));

            //         if (!acc[obj.exam_center_district]) {
            //           const id = index + 1; // Increase id based on the loop index
            //           acc[obj.exam_center_district] = {
            //             id: id,
            //             pk: obj.pk,
            //             error_1: "",
            //             error_2: "",
            //             district_id: obj.district_id,
            //             districtCode: obj.exam_district_code,
            //             district: {
            //               value: obj.district_id,
            //               district_id: obj.district_id,
            //               label: obj.exam_center_district,
            //               district_code: obj.exam_district_code,
            //             },
            //             examCenter: [
            //               {
            //                 examCenter_id: id,
            //                 examCenterOption: [],
            //                 exam_center: "",
            //                 MaxCount: obj.max_count,
            //                 locationUrl: obj.location_url,
            //                 error_3: "",
            //                 error_4: "",
            //                 error_5: "",
            //               },
            //             ],
            //           };
            //         }
            //         // Populate examCenterOption if examCenterList is available
            //         if (examCenterList.length > 0) {
            //           acc[obj.exam_center_district].examCenter.forEach(
            //             (center) => {
            //               center.examCenterOption = examCenterList;
            //             }
            //           );
            //         }
            //         // Populate exam_center if exam_center_name is not null
            //         if (obj.exam_center_name !== null) {
            //           acc[obj.exam_center_district].examCenter.forEach(
            //             (center) => {
            //               center.exam_center = acc[obj.exam_center].map((el)=>el);
            //             }
            //           );
            //         }

            //         return acc;
            //       }, {})
            //     );
            //     const districtWiseData = Object.values(result);
            //     console.log(districtWiseData, "districtWiseData");
            //     setDistrictWise(districtWiseData);
            //     console.log("District Wise:", districtWiseData);
            //   })
            //   .catch((error) => {
            //     console.error(error);
            //   });

            // new format
            const promises = res.data.data.batch_exam_center.map((obj) =>
              getExamCenterView(obj.exam_center_district)
            );

            Promise.all(promises)
              .then((results) => {
                const result = Object.values(
                  res.data.data.batch_exam_center.reduce((acc, obj, index) => {
                    const examCenterList = results[index].map((item) => ({
                      id: item.id,
                      value: item.name,
                      label: item.name,
                    }));

                    if (!acc[obj.exam_center_district]) {
                      const id = index + 1; // Increase id based on the loop index
                      acc[obj.exam_center_district] = {
                        id: id,
                        pk: obj.pk,
                        error_1: "",
                        error_2: "",
                        district_id: obj.district_id,
                        districtCode: obj.exam_district_code,
                        district: {
                          value: obj.district_id,
                          district_id: obj.district_id,
                          label: obj.exam_center_district,
                          district_code: obj.exam_district_code,
                        },
                        examCenter: obj.exam_center_name.map((elo) => ({
                          examCenter_id: obj.id,
                          examCenterOption: examCenterList,
                          exam_center: elo.name ? elo.name : "", // Populate exam_center if exam_center_name is not null
                          MaxCount: elo.max_count,
                          locationUrl:
                            elo.location_url !== null &&
                            elo.location_url !== "null"
                              ? elo.location_url
                              : "",
                          error_3: "",
                          error_4: "",
                          error_5: "",
                        })),
                      };
                    }
                    return acc;
                  }, {})
                );
                const districtWiseData = Object.values(result);
                setDistrictWise(districtWiseData);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      })
      .catch((error) => {
        return error;
      });
  }, [id]);

  //old batch component design

  // when edit its take 0 as default

  const [oldBatch, setOldBatch] = useState([
    {
      id: 1,
      OldDPercentage: id ? 0 : "",
      BatchRFrom: "",
      BatchRTo: "",
      selectedOptions: [],
      ApplicableBatch: [],
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
    },
  ]);

  //use effect get oldbatch dropdown for available batch
  async function getOldBatchView(fTime, Ttime) {
    const dats = {
      start_date: fTime,
      end_date: Ttime,
    };

    try {
      const response = await AxiosInstance.post("api/batch/oldStud/list", dats);
      if (response.data.data.length > 0) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const transformDataOngoin = (data) => {
    const transformedData = [];

    data.forEach((item) => {
      const groupLabel = item.category;
      const option = {
        type: item.type,
        batch_id: item.batch_id,
        batch_name: item.batch_name,
        started_dt: item.started_dt,
        category_class: item.category_class,
        category: item.category,
        section: item.section,
        class_mode: item.class_mode,
        medium: item.medium,
        batch_type: item.batch_type,
        end_dt: item.end_dt,
        label: `${item.batch_name} - ${item.medium}${
          item.section !== null ? `(${item.section})` : ""
        }`,
        value: item.batch_id,
      };

      // Check if the group already exists in transformedData
      const groupIndex = transformedData.findIndex(
        (group) => group.label === groupLabel
      );

      if (groupIndex === -1) {
        // Group doesn't exist, create a new group
        transformedData.push({
          label: groupLabel,
          options: [option],
        });
      } else {
        // Group exists, add the option to the existing group
        transformedData[groupIndex].options.push(option);
      }
    });

    return transformedData;
  };

  const transformData = (data) => {
    const transformedData = [];

    data.forEach((item) => {
      const groupLabel = item.subcategory_code;
      const option = {
        value: item.id,
        label: item.course_name,
        category_code: item.category_code,
        name: item.name,
        subcategory_code: item.subcategory_code,
      };

      // Check if the group already exists in transformedData
      const groupIndex = transformedData.findIndex(
        (group) => group.label === groupLabel
      );

      if (groupIndex === -1) {
        // Group doesn't exist, create a new group
        transformedData.push({
          label: groupLabel,
          options: [option],
        });
      } else {
        // Group exists, add the option to the existing group
        transformedData[groupIndex].options.push(option);
      }
    });

    return transformedData;
  };
  // validate form fields and setting error

  const errors = {};

  const validateOptionSubjects = [19, "19", 41, "41", 281, "281"];

  const validsCourseCategories = [5, 6, 7, "5", "6", "7"];
  const validsCourseCategoriesTn = [
    15,
    16,
    17,
    18,
    19,
    20,
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "39",
    "40",
    "41",
    "88",
    "89",
    39,
    40,
    41,
    88,
    89,
  ];
  const validsCourseCategoriesTnTypeOne = [
    279,
    280,
    281,
    313,
    312,
    "279",
    "280",
    "281",
    "313",
    "312",
  ];

  const validsCourseCategoriesUPTypeThree = [
    715,
    716,
    717,
    "715",
    "716",
    "717",
  ];
  const validsCourseCategoriesTnTypeThree = [
    790,
    726,
    725,
    719,
    718,
    "790",
    "726",
    "725",
    "719",
    "718",
  ];
  const validsCourseCategoriesTRTypeThree = [813, "813"];
  const validsCourseCategoriesRRTypeThree = [
    825,
    826,
    827,
    828,
    829,
    830,
    831,
    832,
    833,
    "825",
    "826",
    "827",
    "828",
    "829",
    "830",
    "831",
    "832",
    "833",
  ];
  const validsCourseCategoriesSSTypeThree = [
    819,
    820,
    821,
    822,
    823,
    824,
    "819",
    "820",
    "821",
    "822",
    "823",
    "824",
  ];
  const validsCourseCategoriesTNBTypeThree = [
    816,
    817,
    818,
    "816",
    "817",
    "818",
  ];
  const validsCourseCategoriesTETypeThree = [814, "814", 815, "815"];
  const validsCourseCategoriesBanTypeThree = [
    834,
    835,
    836,
    837,
    838,
    839,
    840,
    841,
    "834",
    "835",
    "836",
    "837",
    "838",
    "839",
    "840",
    "841",
  ];

  const validate = (
    data,
    selectedOptions,
    selectedMem,
    selectedStaffMem,
    selectedDistrict,
    examDetail,
    mockPanel,
    subMulti,
    mExamSF,
    districtWise
  ) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date to midnight
    // console.log(oldBatch, "oldBatch" ,oldBatch);
    // console.log(data.fees, "data.fees");
    const selectedDate = new Date(data.startDate);
    const selectedendDate = new Date(data.endDate);
    if (data.courseCategory == "") {
      errors.courseCategory = "Please select course Category.";
    }
    if (data.class_name == "") {
      errors.class_name = "Please Enter course Name.";
    }
    if (data.Papers == "" && Type === "4") {
      errors.Papers = "Please choose Single or Combined Paper.";
    }
    if (data.section == "" && Type !== "4") {
      errors.section = "Please select section .";
    }
    if (data.name == "") {
      errors.name = "Please enter name.";
    }
    if (!data.ExamName && (Type === "2" || Type === "5")) {
      errors.ExamName = "Please select the exam name.";
    }
    if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "0" || Type === 0) &&
      data.courseCategory === "UPSC" &&
      validsCourseCategories.includes(data.course_id)
    ) {
      errors.Subject = "Please select the subject.";
    }
    if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "0" || Type === 0) &&
      data.courseCategory === "TNPSC" &&
      validsCourseCategoriesTn.includes(data.course_id)
    ) {
      errors.Subject = "Please select the subject.";
    }
    if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "1" || Type === 1) &&
      data.courseCategory === "TNPSC" &&
      validsCourseCategoriesTnTypeOne.includes(data.course_id)
    ) {
      errors.Subject = "Please select the subject.";
    }
    if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "UPSC" &&
      validsCourseCategoriesUPTypeThree.includes(data.course_id)
    ) {
      errors.Subject = "Please select the subject.";
    }
    if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "TNPSC" &&
      !validsCourseCategoriesTnTypeThree.includes(data.course_id)
    ) {
      errors.Subject = "Please select the subject.";
    }
    if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "TRB" &&
      !validsCourseCategoriesTRTypeThree.includes(data.course_id)
    ) {
      errors.Subject = "Please select the subject.";
    }
    if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "RRB" &&
      validsCourseCategoriesRRTypeThree.includes(data.course_id)
    ) {
      errors.Subject = "Please select the subject.";
    }
    if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "SSC" &&
      validsCourseCategoriesSSTypeThree.includes(data.course_id)
    ) {
      errors.Subject = "Please select the subject.";
    }
    if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "TNUSRB" &&
      validsCourseCategoriesTNBTypeThree.includes(data.course_id)
    ) {
      errors.Subject = "Please select the subject.";
    }
    if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "TET" &&
      validsCourseCategoriesTETypeThree.includes(data.course_id)
    ) {
      errors.Subject = "Please select the subject.";
    }
    if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "Banking" &&
      validsCourseCategoriesBanTypeThree.includes(data.course_id)
    ) {
      errors.Subject = "Please select the subject.";
    }
    //   if (!data.Subject  && Type !== "4" && Type !== "5" && Type !== "2") {
    //   errors.Subject = "Please select the subject.";

    // }

    if (data.classMode == "" && Type !== "2" && Type !== "5") {
      errors.classMode = "Please select class Mode.";
    }
    if (data.location == "" && Type !== "4") {
      errors.location = "Please select location.";
    }
    if (data.medium == "" && Type !== "2" && Type !== "5") {
      errors.medium = "Please select medium.";
    }
    if (data.NoTest == "" && Type === "1" && Type !== "4" && Type !== "5") {
      errors.NoTest = "Please enter No of Test.";
    }
    if (data.NModelExam == "" && Type === "4") {
      errors.NModelExam = "Please enter No of Model exam.";
    }
    if (data.NoStudents == "" && Type !== "4") {
      errors.NoStudents = "Please enter No of Students.";
    }
    if (!data.testDet && Type === "1" && Type !== "4" && Type !== "5") {
      errors.testDet = "Please upload the pdf as 2MB .";
    }
    if (!data.special_syll && Type === "3") {
      errors.special_syll = "Please upload the pdf.";
    }
    if (!data.batchImg) {
      errors.batchImg = "Please  choose batch Img.";
      txtBatch_batchImg.current.focus();
    }
    if (selectedDistrict.length === 0 && Type === "4" && id) {
      errors.District = "Please  choose at least one district .";
    }
    if (data.batch == "" && Type !== "2" && Type !== "4" && Type !== "5") {
      errors.batch = "Please Select Batch Type.";
    }
    if (data.pPayment === "" && Type !== "4") {
      errors.pPayment = "Please Select Partial Type.";
    }
    if (data.perOne === "" && data.pPayment === "Yes" && Type !== "4") {
      errors.perOne = "Please enter  percentage .";
    }
    if (
      data.perOne &&
      !/^\d+$/.test(data.perOne) &&
      data.pPayment === "Yes" &&
      Type !== "4"
    ) {
      errors.perOne = "Please enter a valid number for percentage.";
    }
    if (data.perTwo === "" && data.pPayment === "Yes") {
      errors.perTwo = "Please enter  percentage .";
    }
    if (
      data.perTwo &&
      !/^\d+$/.test(data.perTwo) &&
      data.pPayment === "Yes" &&
      Type !== "4"
    ) {
      errors.perTwo = "Please enter a valid number for percentage.";
    }
    if (data.perTwoDate === "" && data.pPayment === "Yes" && Type !== "4") {
      errors.perTwoDate = "Please select  Partial Date.";
    }
    if (data.perThree === "" && data.pPayment === "Yes" && Type !== "4") {
      errors.perThree = "Please enter  percentage .";
    }
    if (
      data.perThree &&
      !/^\d+$/.test(data.perThree) &&
      data.pPayment === "Yes" &&
      Type !== "4"
    ) {
      errors.perThree = "Please enter a valid number for percentage.";
    }
    if (data.perThreeDate === "" && data.pPayment === "Yes" && Type !== "4") {
      errors.perThreeDate = "Please select  Partial Date.";
    }
    if (
      parseFloat(initialfields.perOne) +
        parseFloat(initialfields.perTwo) +
        parseFloat(initialfields.perThree) >
        100 ||
      (parseFloat(initialfields.perOne) +
        parseFloat(initialfields.perTwo) +
        parseFloat(initialfields.perThree) <
        100 &&
        Type !== "4")
    ) {
      errors.perTwo = "Please enter less than 100% when its add to others .";
      errors.perOne = "Please enter less than 100% when its add to others .";
      errors.perThree = "Please enter less than 100% when its add to others .";
    }
    if (data.startDate == "" && Type !== "2" && Type !== "4") {
      errors.startDate = "Please Select start Date.";
    }
    if (!data.mockInterview_endDate && Type === "5") {
      errors.mockInterview_endDate = "Please select end Date.";
    }
    // AIASA-000135 | AIASA-0000037 v1 - issues solved by lalitha
    //  else if (
    //   selectedDate.toDateString() === currentDate.toDateString() &&
    //   Type !== "2" &&
    //   Type !== "4"
    // ) {
    //   errors.startDate = "Start date cannot be today's date";
    //   txtBatchSdate.current.setFocus();
    // }
    if (
      selectedDate <= currentDate &&
      Type !== "2" &&
      Type !== "4" &&
      (id === null || id === undefined)
    ) {
      errors.startDate = "Start Date cannot be in the past.";
    }
    // Type !== "2"
    if (data.duration == "" && Type !== "5" && Type !== "4" && Type !== "2") {
      errors.duration = "Please Select Duration.";
    }
    if (data.startTime == "" && Type !== "4" && Type !== "5" && Type !== "2") {
      errors.startTime = "Please choose time.";
    } else if (
      !data.endTime_In &&
      !data.endTime &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      errors.endTime = "Please choose End Time above Given startTime.";
    }
    if (data.fees === "" && Type !== "4" && Type !== "5" && Type !== "2") {
      setInitialfields((prevFields) => ({
        ...prevFields,
        ["fees"]: 0,
      }));
      errors.fees = "Please Enter Fees Amount.";
    } else if (
      data.fees &&
      !/^[0-9]+$/.test(data.fees) &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      errors.fees = "Please Enter  valid Fees Amount.";
    }
    if (data.fees == 0 && Type !== "4" && Type !== "5" && Type !== "2") {
      errors.fees = "Please Enter Fees Amount Above 0.";
    }
    if (
      data.offerAmount &&
      !/^[0-9]+$/.test(data.offerAmount) &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      errors.offerAmount = "Please enter valid offer Amount .";
    }
    if (
      data.offerAmount &&
      data.fees &&
      data.offerAmount >= data.fees &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      errors.offerAmount = "Please enter less than fees Amount .";
    }
    if (districtWise.length > 0) {
      const UpdateDW = districtWise.map((OB) => {
        let updatedOB = { ...OB };
        if (!OB.district) {
          updatedOB.error_1 = "Please select District";
        }
        if (!OB.districtCode) {
          updatedOB.error_2 = "Please Enter District Code";
        }
        if (OB.examCenter.length > 0) {
          OB.examCenter.map((klkl) => {
            // console.log(klkl, "klkl");
            if (!klkl.exam_center && id) {
              // klkl.error_3 = "ExamCenter is required";
              klkl.error_3 = "";
            } else {
              klkl.error_3 = "";
            }
            if (!klkl.MaxCount && id) {
              // klkl.error_4 = "MaxCount is required";
              klkl.error_4 = "";
            } else {
              klkl.error_4 = "";
            }
            if (!klkl.locationUrl && id) {
              klkl.error_5 = "";
              // klkl.error_5 = "Location Url is required";
            } else {
              klkl.error_5 = "";
            }
          });
        }
        return updatedOB;
      });
      setDistrictWise(UpdateDW);
    }

    if (examDetail.length > 0 && Type === "4") {
      const updatedExamDetails = examDetail.map((OB) => {
        let updatedOB = { ...OB };
        if (OB.title.trim() === "") {
          updatedOB.error_1 = "Title is required";
          // console.log("xcx");
        }
        if (OB.date === "" && data.Papers === "0") {
          updatedOB.error_2 = "Date is required";
        } else {
          updatedOB.error_2 = "";
        }
        if (OB.duration === "" && data.Papers === "0") {
          updatedOB.error_3 = "Duration is required";
        }
        // else if (OB.duration === "" && data.Papers === "0") {
        //   updatedOB.error_3 = "Duration is required";
        // }
        else {
          updatedOB.error_3 = "";
        }
        if (OB.startTime === "" && data.Papers === "0") {
          updatedOB.error_4 = "Start time is required";
        } else {
          updatedOB.error_4 = "";
        }
        if (OB.endTime === "" && data.Papers === "0") {
          updatedOB.error_5 = "End time is required";
        } else {
          updatedOB.error_5 = "";
        }
        if (OB.syllabus === null && data.Papers === "0") {
          updatedOB.error_6 = "Syllabus is required";
        } else {
          updatedOB.error_6 = "";
        }
        if (OB.NPapers === "" && data.Papers === "1") {
          updatedOB.error_9 = "Please enter No of papers";
        } else {
          updatedOB.error_9 = "";
        }

        if (!OB.LastDateApply) {
          updatedOB.error_8 = "Last Date of Apply is required";
        } else if (
          OB.date &&
          OB.LastDateApply &&
          OB.LastDateApply >= OB.date &&
          data.Papers === "0"
        ) {
          updatedOB.error_8 =
            "Last Date to Apply cannot be after the Exam Date";
          OB.LastDateApply = "";
        } else {
          updatedOB.error_8 = "";
        }

        // if (OB.LastDateApply > OB.date) { // If "Last Date to Apply" is after the "Exam Date"
        //   updatedOB.error_8 = "Last Date to Apply cannot be after the Exam Date";
        // } else {
        //   updatedOB.error_8 = ""; // Clear the error message if no issue
        // }

        if (OB.fees === "") {
          updatedOB.error_7 = "Fees is required";
        }
        //  else if (OB.fees && !/^[0-9]+$/.test(OB.fees)) {
        //   updatedOB.error_7 = " valid Fees amount is required";
        // }
        else {
          updatedOB.error_7 = "";
        }

        if (OB.PaperDetailsSection && OB.PaperDetailsSection.length > 0) {
          OB.PaperDetailsSection.forEach((paper) => {
            if (paper.title === "") {
              paper.error_1 = "Title is required";
            } else {
              paper.error_1 = "";
            }
            if (paper.date === "" && data.Papers === "1") {
              paper.error_2 = "Date is required";
            } else {
              paper.error_2 = "";
            }
            if (paper.duration === "" && data.Papers === "1") {
              paper.error_3 = "Duration is required";
            } else {
              paper.error_3 = "";
            }
            if (paper.startTime === "" && data.Papers === "1") {
              paper.error_4 = "Start time is required";
            } else {
              paper.error_4 = "";
            }
            if (paper.endTime === "" && data.Papers === "1") {
              paper.error_5 = "End time is required";
            } else {
              paper.error_5 = "";
            }
            if (paper.syllabus === null && data.Papers === "1") {
              paper.error_6 = "Syllabus is required";
            } else {
              updatedOB.error_6 = "";
            }
          });
        }

        return updatedOB;
      });

      setExamDetails(updatedExamDetails);
    }

    if (oldBatch.length > 0) {
      const UpdateOldStudentss = oldBatch.map((OB) => {
        let updatedOB = { ...OB };
        if (OB.OldDPercentage === "") {
          updatedOB.error_1 = "Old Student Discount is required";
        }

        if (OB.OldDPercentage && !/^[0-9]+$/.test(OB.OldDPercentage)) {
          updatedOB.error_1 = "Enter valid amount for Old Student Discount ";
        }
        if (
          OB.BatchRFrom === "" &&
          (OB.OldDPercentage === "" || parseInt(OB.OldDPercentage) !== 0)
        ) {
          updatedOB.error_2 = "Batch Range From is required";
        }
        if (
          OB.BatchRTo === "" &&
          (OB.OldDPercentage === "" || parseInt(OB.OldDPercentage) !== 0)
        ) {
          updatedOB.error_3 = "Batch Range To is required";
        }
        if (
          OB.selectedOptions.length === 0 &&
          (OB.OldDPercentage === "" || parseInt(OB.OldDPercentage) !== 0)
        ) {
          updatedOB.error_4 = "Applicable Batches is required";
        }
        if (
          OB.ApplicableBatch &&
          OB.ApplicableBatch.length === 0 &&
          OB.OldDPercentage &&
          parseInt(OB.OldDPercentage) > 1 &&
          OB.BatchRFrom &&
          OB.BatchRTo
        ) {
          updatedOB.error_4 =
            "Applicable batch is not avalible so put discount amount as 0";
          updatedOB.BatchRFrom = "";
          updatedOB.BatchRTo = "";
        }
        return updatedOB;
      });

      setOldBatch(UpdateOldStudentss);
    }

    if (mExamSF.length > 0) {
      const UpdateMESF = mExamSF.map((OB) => {
        // console.log(OB, "OB");
        let updatedOB = { ...OB };
        if (OB.NoStudentBatch === "") {
          updatedOB.error_1 = "Please select Discount";
          // console.log(OB.NoStudentBatch, "NoStudentBatchNoStudentBatch");
        }
        if (
          OB.NoStudentBatch &&
          (OB.NoStudentBatch === "1" ||
            OB.NoStudentBatch === "2" ||
            OB.NoStudentBatch === "3") &&
          !OB.SOfferDis
        ) {
          updatedOB.error_2 = "Please Enter valid amount for special offer ";
        }

        if (
          OB.NoStudentBatch &&
          OB.NoStudentBatch === "2" &&
          OB.selectedOptions.length === 0
        ) {
          updatedOB.error_4 = "Please choose applicable batch";
          // updatedOB.txtCMEF.current.focus();
        }
        if (
          OB.NoStudentBatch &&
          OB.NoStudentBatch === "3" &&
          !OB.StudentBatchCount
        ) {
          updatedOB.error_3 = "Please Enter Student Count";
          // updatedOB.txtCMEF.current.focus();
        }

        return updatedOB;
      });

      setMExamSF(UpdateMESF);
    }

    if (!data.message) {
      errors.message = "Please enter welcome message.";
    }

    if (mockPanel.length > 0 && Type === "5") {
      const updatedMockPanel = mockPanel.map((OB) => {
        let updatedOB = { ...OB };

        if (OB.panel_name.trim() === "") {
          updatedOB.error_1 = "Panel Name is required";
        } else {
          updatedOB.error_1 = "";
        }

        if (OB.available_dates.length === 0) {
          updatedOB.error_2 = "Available dates are required";
        } else {
          updatedOB.error_2 = "";
        }

        if (OB.panelMem.length === 0) {
          updatedOB.error_3 = "Panel Member is required";
        } else {
          updatedOB.error_3 = "";
        }

        if (OB.staffMem.length === 0) {
          updatedOB.error_4 = "Staff Member is required";
        } else {
          updatedOB.error_4 = "";
        }

        if (OB.fees === "") {
          updatedOB.error_5 = "Fees is required";
        } else if (OB.fees && !/^[0-9]+$/.test(OB.fees)) {
          updatedOB.error_5 = " valid Fees amount is required";
          // txtBatchEFees.current.focus();
        } else {
          updatedOB.error_5 = "";
        }

        return updatedOB;
      });

      setMockPanel(updatedMockPanel);
    }
    if (cPanel.length > 0 && Type === "2") {
      const updatedCPanel = cPanel.map((OB) => {
        let updatedOB = { ...OB };

        if (OB.panel_name.trim() === "") {
          updatedOB.error_1 = "Panel Name is required";
        } else {
          updatedOB.error_1 = "";
        }

        if (OB.date === "") {
          updatedOB.error_2 = "Date is required";
        } else {
          updatedOB.error_2 = "";
        }

        if (OB.StartTime.trim() === "") {
          updatedOB.error_3 = "Start Time is required";
        } else {
          updatedOB.error_3 = "";
        }

        if (OB.EndTime.trim() === "") {
          updatedOB.error_4 = "End Time is required";
        } else {
          updatedOB.error_4 = "";
        }

        if (OB.department.length === 0) {
          updatedOB.error_5 = "Department is required";
        } else {
          updatedOB.error_5 = "";
        }

        if (OB.CPaneMem.length === 0) {
          updatedOB.error_6 = "Panel Member is required";
        } else {
          updatedOB.error_6 = "";
        }

        if (OB.CStaffM.length === 0) {
          updatedOB.error_7 = "Staff Members are required";
        } else {
          updatedOB.error_7 = "";
        }

        if (OB.fees === "") {
          updatedOB.error_8 = "Fees is required";
        } else if (OB.fees && !/^[0-9]+$/.test(OB.fees)) {
          updatedOB.error_8 = " valid Fees amount is required";
          // txtBatchEFees.current.focus();
        } else {
          updatedOB.error_8 = "";
        }

        return updatedOB;
      });

      setCPanel(updatedCPanel);
    }

    if (Type === "4") {
      validateBFocusSD(
        data,
        selectedOptions,
        selectedMem,
        selectedStaffMem,
        selectedDistrict,
        examDetail,
        mockPanel,
        subMulti,
        mExamSF,
        districtWise
      );
    } else {
      validateBFocus(
        data,
        selectedOptions,
        selectedMem,
        selectedStaffMem,
        selectedDistrict,
        examDetail,
        mockPanel,
        subMulti,
        mExamSF,
        districtWise
      );
    }

    return errors;
  };

  const validateBFocusSD = (
    data,
    selectedOptions,
    selectedMem,
    selectedStaffMem,
    selectedDistrict,
    examDetail,
    mockPanel,
    subMulti,
    mExamSF,
    districtWise
  ) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date to midnight

    // console.log(mExamSF, "mExamSF");

    const selectedDate = new Date(data.startDate);
    const selectedendDate = new Date(data.endDate);

    if (!data.message) {
      txtBatchMsg.current.focus();
    }
    if (mExamSF.length > 0 && Type === "4") {
      const UpdateMESF = mExamSF.map((OB, index) => {
        let updatedOB = { ...OB };
        if (OB.NoStudentBatch === "" && OB.txtMeT.current) {
          OB.txtMeT.current.focus(); // Set focus if ref is valid
        } else if (
          OB.NoStudentBatch &&
          OB.NoStudentBatch === "1" &&
          OB.SOfferDis === "" &&
          OB.txtBatchMESFNSF.current
        ) {
          OB.txtBatchMESFNSF.current.focus();
        } else if (
          OB.NoStudentBatch &&
          OB.NoStudentBatch === "3" &&
          OB.StudentBatchCount === "" &&
          OB.txtBatchMESFNSC.current
        ) {
          // console.log(OB.NoStudentBatch, "OB.NoStudentBatch");
          OB.txtBatchMESFNSC.current.focus();
        } else if (
          OB.NoStudentBatch &&
          OB.NoStudentBatch === "2" &&
          OB.selectedOptions.length === 0 &&
          OB.txtBatchMESFNAB.current
        ) {
          OB.txtBatchMESFNAB.current.focus();
        }
        return updatedOB;
      });

      // setMExamSF(UpdateMESF);
    }
    if (oldBatch.length > 0) {
      const UpdateOldStudent = oldBatch.map((OB) => {
        let updatedOB = { ...OB };
        if (OB.OldDPercentage === "") {
          txtBatchOStudentAMount.current.focus();
        } else if (OB.OldDPercentage && !/^[0-9]+$/.test(OB.OldDPercentage)) {
          txtBatchOStudentAMount.current.focus();
        } else if (
          OB.BatchRFrom === "" &&
          (OB.OldDPercentage === "" || parseInt(OB.OldDPercentage) !== 0)
        ) {
          // txtBatchOBatchFrom.current.setFocus();
        } else if (
          OB.BatchRTo === "" &&
          (OB.OldDPercentage === "" || parseInt(OB.OldDPercentage) !== 0)
        ) {
          // txtBatchOBatchTo.current.setFocus();
        } else if (
          OB.selectedOptions.length === 0 &&
          (OB.OldDPercentage === "" || parseInt(OB.OldDPercentage) !== 0)
        ) {
          txtBatchOApplicableBatch.current.focus();
        } else if (
          OB.ApplicableBatch &&
          OB.ApplicableBatch.length === 0 &&
          OB.OldDPercentage &&
          parseInt(OB.OldDPercentage) > 1 &&
          OB.BatchRFrom &&
          OB.BatchRTo
        ) {
          txtBatchOApplicableBatch.current.focus();
        }

        return updatedOB;
      });
    }

    if (examDetail.length > 0 && Type === "4") {
      examDetail.map((OB, i) => {
        if (OB.title.trim() === "") {
          txtBatchETitle.current.focus();
        }
        if (OB.LastDateApply === "" && data.Papers === "0") {
          txtBatchETitle.current.focus();
          // txtBatchDapply.current.setFocus();
        }
        if (OB.date === "" && data.Papers === "0") {
          txtBatchETitle.current.focus();
          // txtBatchEdate.current.setFocus();
        }
        if (OB.duration === "" && data.Papers === "0") {
          txtBatchEduration.current.focus();
        }
        if (OB.startTime === "" && data.Papers === "0") {
          // txtBatchEstartTime.current.focus();
          txtBatchETitle.current.focus();
        }
        if (OB.endTime === "" && data.Papers === "0") {
          // txtBatchEendTime.current.focus();
          txtBatchETitle.current.focus();
        }
        if (OB.NPapers === "" && data.Papers === "1") {
          txtBatchNPapers.current.focus();
        }
        if (OB.syllabus === null && data.Papers === "0") {
          txtBatchEsyllabus.current.focus();
        }
        if (OB.fees === "") {
          txtBatchEFees.current.focus();
        }
        if (OB.PaperDetailsSection.length > 0) {
          OB.PaperDetailsSection.map((paper) => {
            if (paper.title === "") {
              txtBatchPETitle.current.focus();
            }
            if (paper.date === "" && data.Papers === "1") {
              txtBatchPETitle.current.focus();
            }
            if (paper.duration === "" && data.Papers === "1") {
              txtBatchPEduration.current.focus();
            }
            if (paper.startTime === "" && data.Papers === "1") {
              txtBatchPETitle.current.focus();
            }
            if (paper.endTime === "" && data.Papers === "1") {
              txtBatchPETitle.current.focus();
            }
            if (paper.syllabus === null && data.Papers === "1") {
              txtBatchPEsyllabus.current.focus();
            }
          });
        }
      });
    }

    if (districtWise.length > 0 && Type === "4") {
      // console.log(districtWise, "districtWise");
      const UpdateDW = districtWise.map((OB) => {
        let updatedOB = { ...OB };
        // console.log(!OB.district, "!OB.district");
        if (!OB.district) {
          txtBatchDis.current.focus();
        } else if (OB.districtCode === "") {
          txtBatchDisCode.current.focus();
        }

        return updatedOB;
      });
      setDistrictWise(UpdateDW);
    }
    if (data.class_name == "") {
      txtBatchCourse.current.focus();
    } else if (data.Papers == "" && Type === "4") {
      txtBatchPapers.current.focus();
    }
    if (data.courseCategory == "") {
      txtBatchCategory.current.focus();
    }
    if (data.name == "") {
      txtBatchName.current.focus();
    } else if (data.section == "" && Type !== "4") {
      txtBatchSec.current.focus();
    } else if (!data.ExamName && (Type === "2" || Type === "5")) {
      txtBatchExamName.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "0" || Type === 0) &&
      data.courseCategory === "UPSC" &&
      validsCourseCategories.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "0" || Type === 0) &&
      data.courseCategory === "TNPSC" &&
      validsCourseCategoriesTn.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "1" || Type === 1) &&
      data.courseCategory === "TNPSC" &&
      validsCourseCategoriesTnTypeOne.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "UPSC" &&
      validsCourseCategoriesUPTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "TNPSC" &&
      !validsCourseCategoriesTnTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "TRB" &&
      !validsCourseCategoriesTRTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "RRB" &&
      validsCourseCategoriesRRTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "SSC" &&
      validsCourseCategoriesSSTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "TNUSRB" &&
      validsCourseCategoriesTNBTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "TET" &&
      validsCourseCategoriesTETypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "Banking" &&
      validsCourseCategoriesBanTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (!data.Subject && Type !== "4" && Type !== "5" && Type !== "2") {
      txtBatchSubject.current.focus();
    } else if (data.classMode == "" && Type !== "2" && Type !== "5") {
      txtBatchClassMode.current.focus();
    } else if (data.location == "" && Type !== "4") {
      txtBatchLocation.current.focus();
    } else if (data.medium == "" && Type !== "2" && Type !== "5") {
      txtBatchMedium.current.focus();
    } else if (
      data.NoTest == "" &&
      Type === "1" &&
      Type !== "4" &&
      Type !== "5"
    ) {
      txtBatchNTest.current.focus();
    } else if (data.NModelExam == "" && Type === "4") {
      txtBatchNModelExam.current.focus();
    } else if (data.NoStudents == "" && Type !== "4") {
      txtBatchNoS.current.focus();
    } else if (!data.testDet && Type === "1" && Type !== "4" && Type !== "5") {
      txtBatchTestIMg.current.focus();
    } else if (!data.special_syll && Type === "3") {
      txtBatchSpSyll.current.focus();
    } else if (!data.batchImg) {
      txtBatch_batchImg.current.focus();
    } else if (selectedDistrict.length === 0 && Type === "4" && id) {
      txtBatchDisEdit.current.focus();
    } else if (
      data.batch == "" &&
      Type !== "2" &&
      Type !== "4" &&
      Type !== "5"
    ) {
      txtBatchSper.current.focus();
    } else if (data.pPayment === "" && Type !== "4") {
      txtBatchPP.current.focus();
    } else if (data.perOne === "" && data.pPayment === "Yes" && Type !== "4") {
      txtBatchperOne.current.focus();
    } else if (data.perTwo === "" && data.pPayment === "Yes" && Type !== "4") {
      txtBatchperTwo.current.focus();
    } else if (
      data.perTwoDate === "" &&
      data.pPayment === "Yes" &&
      Type !== "4"
    ) {
      txtBatchperTwoDate.current.setFocus();
    } else if (
      data.perThree === "" &&
      data.pPayment === "Yes" &&
      Type !== "4"
    ) {
      txtBatchperThree.current.focus();
    } else if (
      data.perThreeDate === "" &&
      data.pPayment === "Yes" &&
      Type !== "4"
    ) {
      txtBatchperThreeDate.current.setFocus();
    } else if (
      (Type !== "4" &&
        parseFloat(initialfields.perOne) +
          parseFloat(initialfields.perTwo) +
          parseFloat(initialfields.perThree) >
          100) ||
      parseFloat(initialfields.perOne) +
        parseFloat(initialfields.perTwo) +
        parseFloat(initialfields.perThree) <
        100
    ) {
      txtBatchPP.current.focus();
    }
    // else if (!data.DateApply && Type !== "4") {
    //   txtBatchDapply.current.focus();
    // }
    //  else if (!data.CDate && Type === "2" && Type !== "5") {
    //   txtBatchCDate.current.focus();
    // }
    else if (data.startDate == "" && Type !== "2" && Type !== "4") {
      txtBatchSdate.current.setFocus();
    } else if (!data.mockInterview_endDate && Type === "5") {
      txtBatchEsdate.current.setFocus();
    }
    // AIASA-000135 | AIASA-0000037 v1 - issues solved by lalitha
    //  else if (
    //   selectedDate.toDateString() === currentDate.toDateString() &&
    //   Type !== "2" &&
    //   Type !== "4"
    // ) {
    //   txtBatchSdate.current.focus();
    // }
    else if (
      selectedDate <= currentDate &&
      Type !== "2" &&
      Type !== "4" &&
      (id === null || id === undefined)
    ) {
      txtBatchSdate.current.setFocus();
    }
    // Type !== "2"
    else if (
      data.duration == "" &&
      Type !== "5" &&
      Type !== "4" &&
      Type !== "2"
    ) {
      txtBatchDuration.current.focus();
    } else if (
      data.startTime == "" &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      txtBatchStime.current.focus();
    } else if (
      !data.endTime_In &&
      !data.endTime &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      txtBatchEtime.current.focus();
    } else if (
      data.fees === "" &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      txtBatchFees.current.focus();
    } else if (
      data.fees &&
      !/^[0-9]+$/.test(data.fees) &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      txtBatchFees.current.focus();
    } else if (data.fees == 0 && Type !== "4" && Type !== "5" && Type !== "2") {
      txtBatchFees.current.focus();
    }

    // else if (data.fees.length >= 10 && Type !== "4") {
    // } else if (data.fees <= 0 && Type !== "4") {
    // }
    else if (
      data.offerAmount &&
      !/^[0-9]+$/.test(data.offerAmount) &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      txtBatchSamount.current.focus();
    } else if (
      data.offerAmount &&
      data.offerAmount >= data.fees &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      txtBatchSamount.current.focus();
    } else if (mockPanel.length > 0 && Type === "5") {
      mockPanel.forEach((OB, i) => {
        if (OB.panel_name.trim() === "") {
          txtBatchMPanelName.current.focus();
        } else if (OB.available_dates.length === 0) {
          txtBatchMADate.current.focus();
        } else if (OB.panelMem.length === 0) {
          txtBatchMPM.current.focus();
        } else if (OB.staffMem.length === 0) {
          txtBatchMSM.current.focus();
        } else if (OB.fees === "") {
          txtBatchMFees.current.focus();
        }
      });
    } else if (cPanel.length > 0 && Type === "2") {
      cPanel.forEach((OB, i) => {
        if (OB.panel_name.trim() === "") {
          txtBatchCPanelName.current.focus();
        } else if (OB.date === "") {
          txtBatchCCDate.current.focus();
        } else if (OB.StartTime.trim() === "") {
          txtBatchCStartTime.current.focus();
        } else if (OB.EndTime.trim() === "") {
          txtBatchCEndTime.current.focus();
        } else if (OB.department.length === 0) {
          txtBatchCdept.current.focus();
        } else if (OB.CPaneMem.length === 0) {
          txtBatchCPM.current.focus();
        } else if (OB.CStaffM.length === 0) {
          txtBatchCSM.current.focus();
        } else if (OB.fees === "") {
          txtBatchCFees.current.focus();
        }
      });
    }

    return errors;
  };

  const validateBFocus = (
    data,
    selectedOptions,
    selectedMem,
    selectedStaffMem,
    selectedDistrict,
    examDetail,
    mockPanel,
    subMulti,
    mExamSF,
    districtWise
  ) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date to midnight

    const selectedDate = new Date(data.startDate);
    const selectedendDate = new Date(data.endDate);
    if (data.courseCategory == "") {
      txtBatchCategory.current.focus();
    } else if (data.class_name == "") {
      txtBatchCourse.current.focus();
    } else if (data.Papers == "" && Type === "4") {
      txtBatchPapers.current.focus();
    } else if (data.section == "" && Type !== "4") {
      txtBatchSec.current.focus();
    } else if (data.name == "") {
      txtBatchName.current.focus();
    } else if (!data.ExamName && (Type === "2" || Type === "5")) {
      txtBatchExamName.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "0" || Type === 0) &&
      data.courseCategory === "UPSC" &&
      validsCourseCategories.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "0" || Type === 0) &&
      data.courseCategory === "TNPSC" &&
      validsCourseCategoriesTn.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "1" || Type === 1) &&
      data.courseCategory === "TNPSC" &&
      validsCourseCategoriesTnTypeOne.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "UPSC" &&
      validsCourseCategoriesUPTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "TNPSC" &&
      !validsCourseCategoriesTnTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "TRB" &&
      !validsCourseCategoriesTRTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "RRB" &&
      validsCourseCategoriesRRTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "SSC" &&
      validsCourseCategoriesSSTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "TNUSRB" &&
      validsCourseCategoriesTNBTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "TET" &&
      validsCourseCategoriesTETypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (
      subMulti &&
      subMulti.length === 0 &&
      (Type === "3" || Type === 3) &&
      data.courseCategory === "Banking" &&
      validsCourseCategoriesBanTypeThree.includes(data.course_id)
    ) {
      txtBatchSubject.current.focus();
    } else if (!data.Subject && Type !== "4" && Type !== "5" && Type !== "2") {
      txtBatchSubject.current.focus();
    } else if (data.classMode == "" && Type !== "2" && Type !== "5") {
      txtBatchClassMode.current.focus();
    } else if (data.location == "" && Type !== "4") {
      txtBatchLocation.current.focus();
    } else if (data.medium == "" && Type !== "2" && Type !== "5") {
      txtBatchMedium.current.focus();
    } else if (
      data.NoTest == "" &&
      Type === "1" &&
      Type !== "4" &&
      Type !== "5"
    ) {
      txtBatchNTest.current.focus();
    } else if (data.NModelExam == "" && Type === "4") {
      txtBatchNModelExam.current.focus();
    } else if (data.NoStudents == "" && Type !== "4") {
      txtBatchNoS.current.focus();
    } else if (!data.testDet && Type === "1" && Type !== "4" && Type !== "5") {
      txtBatchTestIMg.current.focus();
    } else if (!data.special_syll && Type === "3") {
      txtBatchSpSyll.current.focus();
    } else if (!data.batchImg) {
      txtBatch_batchImg.current.focus();
    } else if (selectedDistrict.length === 0 && Type === "4" && id) {
      txtBatchDisEdit.current.focus();
    } else if (
      data.batch == "" &&
      Type !== "2" &&
      Type !== "4" &&
      Type !== "5"
    ) {
      txtBatchSper.current.focus();
    } else if (data.pPayment === "" && Type !== "4") {
      txtBatchPP.current.focus();
    } else if (data.perOne === "" && data.pPayment === "Yes" && Type !== "4") {
      txtBatchperOne.current.focus();
    } else if (data.perTwo === "" && data.pPayment === "Yes" && Type !== "4") {
      txtBatchperTwo.current.focus();
    } else if (
      data.perTwoDate === "" &&
      data.pPayment === "Yes" &&
      Type !== "4"
    ) {
      txtBatchperTwoDate.current.setFocus();
    } else if (
      data.perThree === "" &&
      data.pPayment === "Yes" &&
      Type !== "4"
    ) {
      txtBatchperThree.current.focus();
    } else if (
      data.perThreeDate === "" &&
      data.pPayment === "Yes" &&
      Type !== "4"
    ) {
      txtBatchperThreeDate.current.setFocus();
    } else if (
      (Type !== "4" &&
        parseFloat(initialfields.perOne) +
          parseFloat(initialfields.perTwo) +
          parseFloat(initialfields.perThree) >
          100) ||
      parseFloat(initialfields.perOne) +
        parseFloat(initialfields.perTwo) +
        parseFloat(initialfields.perThree) <
        100
    ) {
      txtBatchPP.current.focus();
    }
    // else if (!data.DateApply && Type !== "4") {
    //   txtBatchDapply.current.focus();
    // }
    //  else if (!data.CDate && Type === "2" && Type !== "5") {
    //   txtBatchCDate.current.focus();
    // }
    else if (data.startDate == "" && Type !== "2" && Type !== "4") {
      txtBatchSdate.current.setFocus();
    } else if (!data.mockInterview_endDate && Type === "5") {
      txtBatchEsdate.current.setFocus();
    }
    // AIASA-000135 | AIASA-0000037 v1 - issues solved by lalitha
    //  else if (
    //   selectedDate.toDateString() === currentDate.toDateString() &&
    //   Type !== "2" &&
    //   Type !== "4"
    // ) {
    //   txtBatchSdate.current.focus();
    // }
    else if (
      selectedDate <= currentDate &&
      Type !== "2" &&
      Type !== "4" &&
      (id === null || id === undefined)
    ) {
      txtBatchSdate.current.setFocus();
    }
    // Type !== "2"
    else if (
      data.duration == "" &&
      Type !== "5" &&
      Type !== "4" &&
      Type !== "2"
    ) {
      txtBatchDuration.current.focus();
    } else if (
      data.startTime == "" &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      txtBatchStime.current.focus();
    } else if (
      !data.endTime_In &&
      !data.endTime &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      txtBatchEtime.current.focus();
    } else if (
      data.fees === "" &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      txtBatchFees.current.focus();
    } else if (
      data.fees &&
      !/^[0-9]+$/.test(data.fees) &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      txtBatchFees.current.focus();
    } else if (data.fees == 0 && Type !== "4" && Type !== "5" && Type !== "2") {
      txtBatchFees.current.focus();
    }

    // else if (data.fees.length >= 10 && Type !== "4") {
    // } else if (data.fees <= 0 && Type !== "4") {
    // }
    else if (
      data.offerAmount &&
      !/^[0-9]+$/.test(data.offerAmount) &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      txtBatchSamount.current.focus();
    } else if (
      data.offerAmount &&
      data.offerAmount >= data.fees &&
      Type !== "4" &&
      Type !== "5" &&
      Type !== "2"
    ) {
      txtBatchSamount.current.focus();
    } else if (districtWise.length > 0) {
      // console.log(districtWise, "districtWise");
      const UpdateDW = districtWise.map((OB) => {
        let updatedOB = { ...OB };
        // console.log(!OB.district, "!OB.district");
        if (!OB.district) {
          txtBatchDis.current.focus();
        } else if (OB.districtCode === "") {
          txtBatchDisCode.current.focus();
        }

        return updatedOB;
      });
      setDistrictWise(UpdateDW);
    } else if (examDetail.length > 0) {
      examDetail.map((OB, i) => {
        if (OB.title.trim() === "") {
          txtBatchETitle.current.focus();
        } else if (OB.date === "" && data.Papers === "0") {
          txtBatchEdate.current.setFocus();
        } else if (OB.duration === "" && data.Papers === "0") {
          txtBatchEduration.current.focus();
        } else if (OB.startTime === "" && data.Papers === "0") {
          txtBatchEstartTime.current.focus();
        } else if (OB.endTime === "" && data.Papers === "0") {
          txtBatchEendTime.current.focus();
        } else if (OB.NPapers === "" && data.Papers === "1") {
          txtBatchNPapers.current.focus();
        } else if (OB.syllabus === null) {
          txtBatchEsyllabus.current.focus();
        } else if (OB.fees === "") {
          txtBatchEFees.current.focus();
        } else if (OB.PaperDetailsSection.length > 0) {
          OB.PaperDetailsSection.forEach((paper) => {
            if (paper.title === "") {
              txtBatchPETitle.current.focus();
            } else if (paper.date === "" && data.Papers === "1") {
              txtBatchPEdate.current.setFocus();
            } else if (paper.duration === "" && data.Papers === "1") {
              txtBatchPEduration.current.focus();
            } else if (paper.startTime === "" && data.Papers === "1") {
              txtBatchPEstartTime.current.focus();
            } else if (paper.endTime === "" && data.Papers === "1") {
              txtBatchPEendTime.current.focus();
            } else if (paper.syllabus === null && data.Papers === "1") {
              txtBatchPEsyllabus.current.focus();
            }
          });
        }
      });
    } else if (oldBatch.length > 0) {
      const UpdateOldStudent = oldBatch.map((OB) => {
        let updatedOB = { ...OB };
        if (OB.OldDPercentage === "") {
          txtBatchOStudentAMount.current.focus();
        } else if (OB.OldDPercentage && !/^[0-9]+$/.test(OB.OldDPercentage)) {
          txtBatchOStudentAMount.current.focus();
        } else if (
          OB.BatchRFrom === "" &&
          (OB.OldDPercentage === "" || parseInt(OB.OldDPercentage) !== 0)
        ) {
          // txtBatchOBatchFrom.current.setFocus();
        } else if (
          OB.BatchRTo === "" &&
          (OB.OldDPercentage === "" || parseInt(OB.OldDPercentage) !== 0)
        ) {
          // txtBatchOBatchTo.current.setFocus();
        } else if (
          OB.selectedOptions.length === 0 &&
          (OB.OldDPercentage === "" || parseInt(OB.OldDPercentage) !== 0)
        ) {
          txtBatchOApplicableBatch.current.focus();
        } else if (
          OB.ApplicableBatch &&
          OB.ApplicableBatch.length === 0 &&
          OB.OldDPercentage &&
          parseInt(OB.OldDPercentage) > 1 &&
          OB.BatchRFrom &&
          OB.BatchRTo
        ) {
          txtBatchOApplicableBatch.current.focus();
        }

        return updatedOB;
      });
    } else if (mExamSF.length > 0) {
      const UpdateMESF = mExamSF.map((OB) => {
        let updatedOB = { ...OB };
        if (OB.NoStudentBatch === "") {
          txtBatchMESFN.current.focus();
        } else if (
          OB.NoStudentBatch &&
          OB.NoStudentBatch === "1" &&
          OB.SOfferDis === ""
        ) {
          txtBatchMESFNSF.current.focus();
        } else if (
          OB.NoStudentBatch &&
          OB.NoStudentBatch === "3" &&
          OB.StudentBatchCount === ""
        ) {
          // console.log(OB.NoStudentBatch, "OB.NoStudentBatch");
          txtBatchMESFNSC.current.focus();
        } else if (
          OB.NoStudentBatch &&
          OB.NoStudentBatch === "3" &&
          OB.selectedOptions.length === 0
        ) {
          txtBatchMESFNAB.current.focus();
        }
        return updatedOB;
      });

      setMExamSF(UpdateMESF);
    } else if (mockPanel.length > 0 && Type === "5") {
      mockPanel.forEach((OB, i) => {
        if (OB.panel_name.trim() === "") {
          txtBatchMPanelName.current.focus();
        } else if (OB.available_dates.length === 0) {
          txtBatchMADate.current.focus();
        } else if (OB.panelMem.length === 0) {
          txtBatchMPM.current.focus();
        } else if (OB.staffMem.length === 0) {
          txtBatchMSM.current.focus();
        } else if (OB.fees === "") {
          txtBatchMFees.current.focus();
        }
      });
    } else if (cPanel.length > 0 && Type === "2") {
      cPanel.forEach((OB, i) => {
        if (OB.panel_name.trim() === "") {
          txtBatchCPanelName.current.focus();
        } else if (OB.date === "") {
          txtBatchCCDate.current.focus();
        } else if (OB.StartTime.trim() === "") {
          txtBatchCStartTime.current.focus();
        } else if (OB.EndTime.trim() === "") {
          txtBatchCEndTime.current.focus();
        } else if (OB.department.length === 0) {
          txtBatchCdept.current.focus();
        } else if (OB.CPaneMem.length === 0) {
          txtBatchCPM.current.focus();
        } else if (OB.CStaffM.length === 0) {
          txtBatchCSM.current.focus();
        } else if (OB.fees === "") {
          txtBatchCFees.current.focus();
        }
      });
    } else if (!data.message) {
      txtBatchMsg.current.focus();
    }
    return errors;
  };

  //district wise handlechange

  const handleChangedrodown = (e, id, dname) => {
    const { name, value } = e.target;
    if (dname === "districtCode") {
      setDistrictWise((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, ["districtCode"]: value, error_2: "" };
          }
          return q;
        });
      });
    }
    // getExamCenter(id, value);
  };

  const handleExamTimeChange = (time, id, dname) => {
    // console.log(
    //   "lakshChange",
    //   time?._d,
    //   new Date(time?._d),
    //   moment(time?._d).format("hh:mm:ss A"),
    //   moment(time?._d).format("HH:mm:ss")
    // );
    const formattedTime = new Date(time?._d);
    if (dname === "startTime") {
      // console.log("laksh-st");
      setExamDetails((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            // console.log("laksh-st", q);
            return {
              ...q,
              error_4: "",
              error_5: "",
              [dname]: formattedTime,
              endTime: "",
              duration: "",
            };
          }
          return q;
        });
      });
    } else if (dname === "endTime") {
      // console.log("laksh-et");
      setExamDetails((prevState) => {
        return prevState.map((q) => {
          // console.log("laksh-et-q", q);
          const formattedTimeMoment = moment(formattedTime, "hh:mm:ss A");
          const startTimeMoment = moment(q.startTime, "hh:mm:ss A");
          if (q.id === id) {
            // console.log("laksh-et-q", q, id);
            if (q.startTime === null || q.startTime === "") {
              return {
                ...q,
                error_5: "Please Choose startTime First",
                [dname]: null,
              };
            } else if (
              q.startTime !== "" &&
              formattedTimeMoment.isBefore(startTimeMoment)
            ) {
              return {
                ...q,
                error_5: "End time must be after start time",
                [dname]: "",
                ["duration"]: "",
              };
            } else if (
              q.startTime !== "" &&
              formattedTimeMoment.isSame(startTimeMoment)
            ) {
              return {
                ...q,
                error_5: "End time must be after start time",
                [dname]: "",
                ["duration"]: "",
              };
            } else {
              const duration = moment.duration(
                formattedTimeMoment.diff(startTimeMoment)
              );
              const hours = duration.hours();
              const minutes = duration.minutes();
              if (minutes > 0) {
                return {
                  ...q,
                  error_5: "Mintues Not Allowed to select",
                  [dname]: "",
                  ["duration"]: "",
                };
              } else {
                return {
                  ...q,
                  error_5: "",
                  [dname]: formattedTime,
                  ["duration"]: hours,
                  error_3: "",
                };
              }
            }
          }
          return q;
        });
      });
    } else {
      console.error("un-named type handlechange");
    }
  };
  const handleExamTimeChangePaper = (TimeDate, id, parentId, namess) => {
    // console.log(
    //   "lakshChangePaper",
    //   TimeDate?._d,
    //   new Date(TimeDate?._d),
    //   moment(TimeDate?._d).format("hh:mm:ss A"),
    //   moment(TimeDate?._d).format("HH:mm:ss")
    // );
    const formattedTime = new Date(TimeDate?._d);
    if (namess === "startTime") {
      // const formattedTime = new Date(TimeDate?._d);
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      setExamDetails((prevState) => {
        return prevState.map((q) => {
          if (q.id === parentId) {
            const updatedPaperDetailsSection = q.PaperDetailsSection.map(
              (paper) => {
                if (
                  paper.id === id
                  // paper.endTime === "" &&
                  // paper.duration === ""
                ) {
                  if (q.PaperDetailsSection.length > 1) {
                    const lastEndTime = q.PaperDetailsSection[id - 2];
                    const currentDate = q.PaperDetailsSection[id - 1];
                    if (
                      lastEndTime?.endTime > formattedTime &&
                      lastEndTime?.date === currentDate?.date
                    ) {
                      // console.log("laksh-waste", lastEndTime, currentDate);
                      return {
                        ...paper,
                        [namess]: "",
                        ["startTime"]: "",
                        ["duration"]: "",
                        error_4: "Start time should be above previos end time",
                      };
                    }
                  }
                  return {
                    ...paper,
                    [namess]: formattedTime,
                    ["endTime"]: "",
                    ["duration"]: "",
                    error_4: "",
                  };
                }
                // else {
                //   return {
                //     ...paper,
                //     [namess]: formattedTime,
                //     error_4: "",
                //     ["endTime"]: " ",
                //     ["duration"]: "",
                //   };
                // }
                return paper;
              }
            );
            return { ...q, PaperDetailsSection: updatedPaperDetailsSection };
          }
          return q;
        });
      });
    } else if (namess === "endTime") {
      // const formattedTime = dayjs(TimeDate).format("hh:mm:ss A");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      setExamDetails((prevState) => {
        return prevState.map((q) => {
          if (q.id === parentId) {
            const updatedPaperDetailsSection = q.PaperDetailsSection.map(
              (paper) => {
                const formattedTimeMoment = moment(formattedTime, "hh:mm:ss A");
                const startTimeMoment = moment(paper.startTime, "hh:mm:ss A");

                if (paper.id === id) {
                  if (paper.startTime === "") {
                    return {
                      ...paper,
                      error_5: "Please Choose startTime First",
                      [namess]: "",
                    };
                  } else if (
                    paper.startTime !== "" &&
                    formattedTimeMoment.isBefore(startTimeMoment)
                  ) {
                    return {
                      ...paper,
                      error_5: "End time must be after start time",
                      [namess]: "",
                      ["duration"]: "",
                    };
                  } else if (
                    paper.startTime !== "" &&
                    formattedTimeMoment.isSame(startTimeMoment)
                  ) {
                    return {
                      ...paper,
                      error_5: "End time must be after start time",
                      [namess]: "",
                      ["duration"]: "",
                    };
                  } else {
                    const duration = moment.duration(
                      formattedTimeMoment.diff(startTimeMoment)
                    );
                    const hours = duration.hours();
                    const minutes = duration.minutes();
                    if (minutes > 0) {
                      return {
                        ...paper,
                        error_5: "Minutes Not Allowed to select",
                        [namess]: "",
                        ["duration"]: "",
                      };
                    } else {
                      return {
                        ...paper,
                        [namess]: formattedTime,
                        error_5: "",
                        ["duration"]: hours,
                        error_3: "",
                      };
                    }
                  }
                }
                return paper;
              }
            );
            return { ...q, PaperDetailsSection: updatedPaperDetailsSection };
          }
          return q;
        });
      });
    } else {
      console.error("un-named type handlechange");
    }
  };

  //district wise handlechange for exam center

  const handleChangeExamcenter = (e, parentId, id, disName) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setDistrictWise((prevState) => {
      return prevState.map((district) => {
        if (district.id === parentId) {
          // Find the examCenter object with the provided id
          const updatedExamCenter = district.examCenter.map((center) => {
            if (center.examCenter_id === id) {
              return { ...center, exam_center: value, error_3: "" };
            }
            return center;
          });
          // Return the district with updated examCenter array
          return { ...district, examCenter: updatedExamCenter };
        }
        return district;
      });
    });
    // if (selected) {
    //   const ECenter = {
    //     district: disName,
    //     id: selected.id,
    //     value: selected.value,
    //     label: selected.value,
    //   };
    //   // console.log(ECenter, "selectedMem");
    //   setECenter(ECenter);

    //   setDistrictWise((prevState) => {
    //     return prevState.map((district) => {
    //       if (district.id === parentId) {
    //         // Find the examCenter object with the provided id
    //         const updatedExamCenter = district.examCenter.map((center) => {
    //           if (center.examCenter_id === id) {
    //             return { ...center, exam_center: ECenter.label, error_3: "" };
    //           }
    //           return center;
    //         });
    //         // Return the district with updated examCenter array
    //         return { ...district, examCenter: updatedExamCenter };
    //       }
    //       return district;
    //     });
    //   });
    // } else {
    //   setDistrictWise((prevState) => {
    //     return prevState.map((district) => {
    //       if (district.id === parentId) {
    //         // Find the examCenter object with the provided id
    //         const updatedExamCenter = district.examCenter.map((center) => {
    //           if (center.examCenter_id === id) {
    //             return { ...center, exam_center: "" };
    //           }
    //           return center;
    //         });
    //         // Return the district with updated examCenter array
    //         return { ...district, examCenter: updatedExamCenter };
    //       }
    //       return district;
    //     });
    //   });
    // }
  };

  const handleExamChnageDropdown = (e, parentId, id) => {
    const { name, value } = e.target;
    if (name === "MaxCount") {
      setDistrictWise((prevState) => {
        return prevState.map((district) => {
          if (district.id === parentId) {
            // Find the examCenter object with the provided id
            const updatedExamCenter = district.examCenter.map((center) => {
              if (center.examCenter_id === id) {
                return { ...center, MaxCount: value, error_4: "" };
              }
              return center;
            });
            // Return the district with updated examCenter array
            return { ...district, examCenter: updatedExamCenter };
          }
          return district;
        });
      });
    } else if (name === "locationUrl") {
      const urlPattern =
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
      const url = value;
      if (!urlPattern.test(url)) {
        setDistrictWise((prevState) => {
          return prevState.map((district) => {
            if (district.id === parentId) {
              // Find the examCenter object with the provided id
              const updatedExamCenter = district.examCenter.map((center) => {
                if (center.examCenter_id === id) {
                  return {
                    ...center,
                    locationUrl: "",
                    error_5: "Invalid URL. Please enter a valid URL.",
                  };
                }
                return center;
              });
              // Return the district with updated examCenter array
              return { ...district, examCenter: updatedExamCenter };
            }
            return district;
          });
        });
      } else {
        setDistrictWise((prevState) => {
          return prevState.map((district) => {
            if (district.id === parentId) {
              // Find the examCenter object with the provided id
              const updatedExamCenter = district.examCenter.map((center) => {
                if (center.examCenter_id === id) {
                  return { ...center, locationUrl: value, error_5: "" };
                }
                return center;
              });
              // Return the district with updated examCenter array
              return { ...district, examCenter: updatedExamCenter };
            }
            return district;
          });
        });
      }
    }
  };

  // mutiple dropdown selection
  const handleChangeMutiple = (selected, id) => {
    console.log(selected, "selected");
    const selectedValues = selected.map((option) => ({
      id: option.batch_id,
      value: option.value,
      label: option.label,
      cateOp: option.cateOp,
    }));

    const arrayToSet = (selectedValues) =>
      new Set(selectedValues.map((item) => item.id));
    const areArraysEqual = (arr1, arr2) => {
      const set1 = arrayToSet(arr1);
      const set2 = arrayToSet(arr2);
      if (set1.size !== set2.size) return false;
      for (let item of set1) {
        if (!set2.has(item)) return false;
      }
      return true;
    };

    setSelectedOptions(selectedValues);
    setOldBatch((prevState) => {
      const isNameExists = prevState.some(
        (q) => q.id !== id && areArraysEqual(q.selectedOptions, selectedValues)
      );
      // console.log(
      //   isNameExists,
      //   "isNameExistsisNameExistsisNameExistsisNameExists"
      // );
      if (isNameExists) {
        return prevState.map((q) => {
          if (q.id === id) {
            return {
              ...q,
              selectedOptions: [],
              error_4: "This Name already exists",
            };
          }
          return q;
        });
      } else {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, selectedOptions: selectedValues, error_4: "" };
          }
          return q;
        });
      }
    });
    // setOldBatch((prevState) => {
    //   console.log(prevState,"prevState")
    //   const isNameExists = prevState.some(
    //     (q) => q.id !== id && q.selectedOptions.filter((oi) => oi.cateOp) === selectedValues.filter((cv) => cv.cateOp)
    //   );
    //   console.log(isNameExists,"isNameExists")
    //   if (isNameExists) {
    //     return prevState.map((q) => {
    //       if (q.id === id) {
    //         return { ...q, selectedOptions: "", error_4: "This Name already exists" };
    //       }
    //       return q;
    //     });
    //   }else{
    //     return prevState.map((q) => {
    //       if (q.id === id) {
    //         return { ...q, selectedOptions: selectedValues, error_4: "" };
    //       }
    //       return q;
    //     });
    //   }

    // });
    // setOldBatch((prevState) => {

    //   console.log(isDuplicate,"isDuplicate")
    //   return prevState.map((q) => {
    //     if (q.id === id) {
    //       console.log(isDuplicate,"isDuplicate")
    //       const newSelectedOptions = isDuplicate
    //         ? q.selectedOptions // Keep the old state if duplicate
    //         : selectedValues;

    //       return { ...q, selectedOptions: newSelectedOptions, error_4: isDuplicate ? "Duplicate label found" : "" };
    //     }
    //     return q;
    //   });
    // });
    setError({
      ...error,
      oldBatch: "",
    });
  };
  //add more district's wise design

  const addDistrictWise = () => {
    setDistrictWise((prevState) => [
      ...prevState,
      {
        id: prevState.length > 0 ? prevState.length + 1 : 1,
        pk: "",
        district: "",
        districtCode: "",
        examCenter: [
          {
            examCenterOption: [],
            exam_center: "",
            MaxCount: 0,
            locationUrl: "",
            error_3: "",
            error_4: "",
            error_5: "",
          },
        ],
        error_1: "",
        error_2: "",
      },
    ]);
  };

  const addExamCenter = (id) => {
    // console.log(districtWise, "districtWise");
    // setDistrictWise((prevState) => {
    //   const index = prevState.findIndex((district) => district.id === id);
    //   if (index !== -1) {

    //     return prevState.map((district, i) => {
    //       if (i === index) {
    //         return {
    //           id: prevState.length > 0 ? prevState.length + 1 : 1,
    //           pk: "",
    //           district: "",
    //           districtCode: "",
    //           examCenter: [
    //             {
    //               examCenter_id:
    //                 district.examCenter.length > 0
    //                   ? district.examCenter[district.examCenter.length - 1]
    //                       .examCenter_id + 1
    //                   : 1, // Increase examCenter_id based on the length of the examCenter array
    //               examCenterOption: [
    //                 ...district.examCenter[0].examCenterOption,
    //               ],
    //               exam_center: [],
    //               MaxCount: 0,
    //               locationUrl: "",
    //               error_3: "",
    //               error_4: "",
    //               error_5: "",
    //             },
    //           ],
    //           error_1: "",
    //           error_2: "",
    //         };
    //       }
    //       return district;
    //     });
    //   }

    //   // If the district object is not found, return the previous state
    //   return prevState;
    // });
    setDistrictWise((prevState) => [
      ...prevState,
      {
        id: prevState.length > 0 ? prevState.length + 1 : 1,
        pk: "",
        district:
          prevState.length > 0 ? prevState[prevState.length - 1].district : "",
        districtCode:
          prevState.length > 0
            ? prevState[prevState.length - 1].districtCode
            : "",
        examCenter: [
          {
            examCenterOption:
              prevState[prevState.length - 1].examCenter[0].examCenterOption,
            exam_center:
              prevState[prevState.length - 1].examCenter[0].exam_center,
            MaxCount: 0,
            locationUrl: "",
            error_3: "",
            error_4: "",
            error_5: "",
          },
        ],
        error_1: "",
        error_2: "",
      },
    ]);
  };

  //add more old batch
  const addoldbatch = () => {
    setOldBatch((prevState) => [
      ...prevState,
      {
        id: prevState.length > 0 ? prevState.length + 1 : 1,
        OldDPercentage: "",
        BatchRFrom: "",
        BatchRTo: "",
        selectedOptions: [],
        ApplicableBatch: [],
        error_1: "",
        error_2: "",
        error_3: "",
        error_4: "",
      },
    ]);
  };

  const addMESF = () => {
    setMExamSF((prevState) => [
      ...prevState,
      {
        id: prevState.length > 0 ? prevState.length + 1 : 1,
        NoStudentBatch: "",
        SOfferDis: "",
        selectedOptions: [],
        ApplicableBatch: [],
        MEABatch: "",
        StudentBatchCount: "",
        error_1: "",
        error_2: "",
        error_3: "",
        error_4: "",
      },
    ]);
  };

  //delete old batch
  const deleteOldBatch = (id) => {
    const val = oldBatch
      .filter((item) => item.id !== id)
      .map((item, index) => ({ ...item, id: index + 1 }));
    setOldBatch(val);
  };

  const deleteMESF = (id) => {
    const val = mExamSF
      .filter((item) => item.id !== id)
      .map((item, index) => ({ ...item, id: index + 1 }));
    setMExamSF(val);
  };

  //delete district's wise design

  const deleteDistrictWise = (id) => {
    // console.log(districtWise, "districtWise");
    const val = districtWise
      .filter((item) => item.id !== id)
      .map((item, index) => ({ ...item, id: index + 1 }));
    setDistrictWise(val);
    // console.log(val, "val");
  };

  //find by id -> load exam center option dropdown

  const getExamCenterView = (value) => {
    return AxiosInstance.post("api/examcenter/name", { district: value })
      .then((res) => res.data.data)
      .catch((error) => {
        console.error(error);
        return [];
      });
  };
  const getExamCenterViewEditDis = async (value) => {
    try {
      const res = await AxiosInstance.post("api/examcenter/name", {
        district: value,
      });
      const examCenterList = res.data.data.map((item, index) => ({
        id: item.id,
        value: item.name,
        label: item.name,
      }));
      return examCenterList;
    } catch (error) {
      throw error;
    }
  };

  //load exam center option dropdown

  const getExamCenter = (dID, value) => {
    AxiosInstance.post("api/examcenter/name", {
      district: value,
    })
      .then((res) => {
        let examCenter = res.data.data.map((item, index) => (
          <option key={index} value={item.id}>
            {item.name}
          </option>
        ));
        setExamCenter(examCenter);
        const examCenterList = res.data.data.map((item, index) => ({
          id: item.id,
          value: item.name,
          label: item.name,
        }));
        setDistrictWise((prevState) => {
          return prevState.map((q) => {
            if (q.id === dID) {
              return { ...q, examCenterOption: examCenterList };
            }
            return q;
          });
        });
      })
      .catch((error) => {
        return error;
      });
  };

  // batch old student handle  change

  const batchChange = (e, id) => {
    const { name, value, files } = e.target;
    if (name === "OldDPercentage") {
      if (value === "0") {
        setOldBatch((prevState) => {
          const updatedState = prevState.map((q) => {
            if (q.id === id) {
              return {
                ...q,
                [name]: value,
                error_1: "",
                error_2: "",
                error_3: "",
                error_4: "",
                BatchRFrom: "",
                BatchRTo: "",
              };
            }
            return q;
          });
          const updatedBatch = updatedState.find((q) => q.id === id);
          if (
            updatedBatch.BatchRFrom.trim() !== "" &&
            updatedBatch.BatchRTo.trim() !== ""
          ) {
            getOldBatch(updatedBatch.BatchRFrom, updatedBatch.BatchRTo, id);
          }

          return updatedState;
        });
      }
      // else if(value.startsWith("0",0)){
      //   setOldBatch((prevState) => {
      //     const updatedState = prevState.map((q) => {
      //       if (q.id === id) {
      //         return {
      //           ...q,
      //           [name]: value,
      //           error_1: "Value doesn't startwith 0",

      //         };
      //       }
      //       return q;
      //     });
      //     return updatedState;
      //   });

      // }
      else {
        setOldBatch((prevState) => {
          const updatedState = prevState.map((q) => {
            if (q.id === id) {
              return { ...q, [name]: value, error_1: "" };
            }
            return q;
          });
          const updatedBatch = updatedState.find((q) => q.id === id);
          if (
            updatedBatch.BatchRFrom.trim() !== "" &&
            updatedBatch.BatchRTo.trim() !== ""
          ) {
            getOldBatch(updatedBatch.BatchRFrom, updatedBatch.BatchRTo, id);
          }

          return updatedState;
        });
      }
    } else {
      setOldBatch((prevState) => {
        const updatedState = prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value };
          }
          return q;
        });
        const updatedBatch = updatedState.find((q) => q.id === id);
        if (
          updatedBatch.BatchRFrom.trim() !== "" &&
          updatedBatch.BatchRTo.trim() !== ""
        ) {
          getOldBatch(updatedBatch.BatchRFrom, updatedBatch.BatchRTo, id);
        }

        return updatedState;
      });
    }

    setError({
      ...error,
      oldBatch: "",
    });
  };

  //model exam special offer

  const ModelSFExam = (e, id, names) => {
    const { name, value, files, checked } = e.target;
    if (names === "NoStudentBatch" && value !== "4") {
      setMExamSF((prevState) => {
        // First, check if the value already exists in the 'NoStudentBatch' property across the list of objects
        const valueAlreadyExists = prevState.some(
          (q) => q["NoStudentBatch"] === value && q.id !== id
        );
        // If it already exists, you can return the previous state without changes or handle the error accordingly
        if (valueAlreadyExists) {
          // console.log("Value already exists, operation not allowed.");
          // Optionally, you could set some kind of error flag in the state if needed
          return prevState.map((q) => {
            if (q.id === id) {
              return { ...q, error_1: "Value already exists" };
            }
            return q;
          });
        }
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, ["NoStudentBatch"]: value, error_1: "" };
          }
          return q;
        });
        // If it doesn't exist, proceed with the original mapping logic to update the "NoStudentBatch" property
      });
    } else if (names === "NoStudentBatch" && value === "4") {
      const bh = mExamSF.length;
      console.log(bh, "bh");
      if (bh > 1) {
        Swal.fire({
          title: "Warning",
          text: "Do You want to delete the offer details",
          icon: "Warning",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            setMExamSF([
              {
                id: 1,
                NoStudentBatch: "4",
                SOfferDis: "",
                selectedOptions: [],
                ApplicableBatch: [],
                MEABatch: "",
                StudentBatchCount: "",
                error_1: "",
                error_2: "",
                error_3: "",
                error_4: "",
              },
            ]);
          }
        });
      } else {
        setMExamSF([
          {
            id: 1,
            NoStudentBatch: "4",
            SOfferDis: "",
            selectedOptions: [],
            ApplicableBatch: [],
            MEABatch: "",
            StudentBatchCount: "",
            error_1: "",
            error_2: "",
            error_3: "",
            error_4: "",
          },
        ]);
      }
    } else if (names === "SOfferDis") {
      setMExamSF((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, ["SOfferDis"]: value, error_2: "" };
          }
          return q;
        });
      });
    } else if (names === "StudentBatchCount") {
      setMExamSF((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, ["StudentBatchCount"]: value, error_3: "" };
          }
          return q;
        });
      });
    }
  };

  const ModelSFExamClickFunction = (id, names, values) => {
    if (names === "NoStudentBatch") {
      setMExamSF((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, ["NoStudentBatch"]: "", error_1: "" };
          }
          return q;
        });
        // If it doesn't exist, proceed with the original mapping logic to update the "NoStudentBatch" property
      });
    }
  };

  // multi select dropdown with group
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  function groupBys(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  //get old batch

  const getOldBatch = (from, to, id) => {
    // console.log("xzczxc");
    const dat = {
      start_date: from,
      end_date: to,
    };
    AxiosInstance.post("api/batch/oldStud/list", dat)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === true) {
          // console.log("batchlistxxc");
          if (res.data.data.length > 0) {
            const batchlist = res.data.data;
            // console.log(batchlist,"batchlist");
            setOldBatch((prevState) => {
              return prevState.map((q) => {
                if (q.id === id) {
                  return {
                    ...q,
                    ApplicableBatch: getMultiSelectDropdown(batchlist),
                  };
                }
                return q;
              });
            });
          }
        } else {
          setOldBatch((prevState) => {
            return prevState.map((q) => {
              if (q.id === id) {
                return {
                  ...q,
                  ApplicableBatch: [],
                };
              }
              return q;
            });
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  // subject based multiselect

  function getMultiSelectDropdown(list) {
    // console.log("getMultiSelectDropdown");
    const grouped = groupBy(list, (category) => category.category);

    let datas = [];
    if (grouped.get("UPSC") !== undefined) {
      datas = getDropdownData(datas, grouped.get("UPSC"), "UPSC");
    }
    if (grouped.get("TNPSC") !== undefined) {
      datas = getDropdownData(datas, grouped.get("TNPSC"), "TNPSC");
    }
    if (grouped.get("TRB") !== undefined) {
      datas = getDropdownData(datas, grouped.get("TRB"), "TRB");
    }
    if (grouped.get("TET") !== undefined) {
      datas = getDropdownData(datas, grouped.get("TET"), "TET");
    }
    if (grouped.get("TNUSRB") !== undefined) {
      datas = getDropdownData(datas, grouped.get("TNUSRB"), "TNUSRB");
    }
    if (grouped.get("SSC") !== undefined) {
      datas = getDropdownData(datas, grouped.get("SSC"), "SSC");
    }
    if (grouped.get("RRB") !== undefined) {
      datas = getDropdownData(datas, grouped.get("RRB"), "RRB");
    }
    if (grouped.get("Banking") !== undefined) {
      datas = getDropdownData(datas, grouped.get("Banking"), "Banking");
    }
    if (grouped.get("Others") !== undefined) {
      datas = getDropdownData(datas, grouped.get("Others"), "Others");
    }

    return datas;
  }

  function getDropdownData(arr, list, title) {
    arr.push({
      batch_id: "",
      label: title,
      cateOp: title,
      value: title,
      isDisabled: true,
    });
    list.map((obj) => {
      arr.push({
        batch_id: obj.batch_id,
        label: `${obj.batch_name} - ${obj.category_class} ${
          obj.section !== null ? `(${obj.section})` : ""
        } - ${obj.class_mode} - ${obj.medium} - ${
          obj.batch_type !== null ? obj.batch_type : ""
        }`,
        // value: `${obj.batch_name} - ${obj.category_class} ${
        //   obj.section !== null ? `(${obj.section})` : ""
        // } - ${obj.class_mode} - ${obj.medium} - ${
        //   obj.batch_type !== null ? obj.batch_type : ""
        // }`,
        value: obj.batch_id,
        cateOp: obj.batch_name,
        isDisabled: false,
      });
    });
    return arr;
  }

  // counselling department change
  const handleChangeDepartmentCPanel = (selected, id) => {
    // console.log(selected);
    const selectedDp = selected.map((option) => ({
      value: option.value,
      label: option.value,
    }));
    setCPanel((prevPanel) =>
      prevPanel.map((panel) => {
        if (panel.id === id) {
          return { ...panel, department: selectedDp, error_5: "" }; // Remove the spread operator here
        }
        return panel;
      })
    );
  };

  //counselling panelMem selection

  const handleChangePMCPanel = (selected, id) => {
    // console.log(selected);
    const selectedPM = selected.map((option) => ({
      staff_id: option.staff_id,
      type: option.type,
      label: option.value,
      name: option.name,
      email: option.email,
      department: option.department,
    }));
    // setCPanel((prevPanel) =>
    //   prevPanel.map((panel) => {
    //     if (panel.id === id) {
    //       return { ...panel, CPaneMem: selectedPM }; // Remove the spread operator here
    //     }
    //     return panel;
    //   })
    // );

    setCPanel((prevPanel) => {
      // Check if CPaneMem and selectedPM have the same staff_id
      const hasSameStaffId = prevPanel.some((panel) =>
        panel.CPaneMem.some(
          (mem) => mem.staff_id === selectedPM[0]?.staff_id // Use optional chaining to avoid errors if selectedPM is empty
        )
      );
      if (hasSameStaffId) {
        // Show an alert here because panelMem and selectedPM have the same staff_id
        alert("This Panel Member already exist in pervious panel");
        return prevPanel; // Return the unchanged state
      }
      // If there is no staff_id collision and selectedPM is not empty,
      // update the state only when panel.id === id
      return prevPanel.map((panel) => {
        if (panel.id === id) {
          // Use a conditional to handle the case where selectedPM is empty
          return selectedPM.length > 0
            ? { ...panel, CPaneMem: selectedPM, error_6: "" }
            : { ...panel, CPaneMem: [], error_6: "" }; // Remove panelMem
        }
        return panel;
      });
    });
  };

  // counselling teaching faculity selection
  const handleChangeTMCPanel = (selected, id) => {
    // console.log(selected);
    const selectedSM = selected.map((option) => ({
      staff_id: option.staff_id,
      type: option.type,
      label: option.value,
      name: option.name,
      email: option.email,
      department: "",
    }));
    setCPanel((prevPanel) => {
      // Check if CStaffM and selectedSM have the same staff_id
      const hasSameStaffId = prevPanel.some((panel) =>
        panel.CStaffM.some(
          (mem) => mem.staff_id === selectedSM[0]?.staff_id // Use optional chaining to avoid errors if selectedSM is empty
        )
      );
      if (hasSameStaffId) {
        // Show an alert here because panelMem and selectedSM have the same staff_id
        alert("This Teaching faculty already exist in pervious panel");
        return prevPanel; // Return the unchanged state
      }
      // If there is no staff_id collision and selectedSM is not empty,
      // update the state only when panel.id === id
      return prevPanel.map((panel) => {
        if (panel.id === id) {
          // Use a conditional to handle the case where selectedSM is empty
          return selectedSM.length > 0
            ? { ...panel, CStaffM: selectedSM, error_7: "" }
            : { ...panel, CStaffM: [], error_7: "" }; // Remove panelMem
        }
        return panel;
      });
    });
  };

  // mutiple dropdown selection  for panel members

  const handleChangeMutiplePanleMme = (selected, id) => {
    const selectedMems = selected.map((option) => ({
      staff_id: option.staff_id,
      type: option.type,
      email: option.email,
      name: option.name,
      label: option.value,
      department: "",
    }));
    // console.log(mockPanel, selectedMems);
    // if(mockPanel.length <= 1){
    // setMockPanel((prevPanel) =>
    //   prevPanel.map((panel) => {
    //     if (panel.id === id) {
    //       return { ...panel, panelMem: selectedMems }; // Remove the spread operator here
    //     }
    //     return panel;
    //   })
    // );

    setMockPanel((prevPanel) => {
      // Check if panelMem and selectedMems have the same staff_id
      const hasSameStaffId = prevPanel.some((panel) =>
        panel.panelMem.some(
          (mem) => mem.staff_id === selectedMems[0]?.staff_id // Use optional chaining to avoid errors if selectedMems is empty
        )
      );
      if (hasSameStaffId) {
        // Show an alert here because panelMem and selectedMems have the same staff_id
        alert("This Panel Mmebers already exist in pervious panel");
        return prevPanel; // Return the unchanged state
      }
      // If there is no staff_id collision and selectedMems is not empty,
      // update the state only when panel.id === id
      return prevPanel.map((panel) => {
        if (panel.id === id) {
          // Use a conditional to handle the case where selectedMems is empty
          return selectedMems.length > 0
            ? { ...panel, panelMem: selectedMems }
            : { ...panel, panelMem: [] }; // Remove panelMem
        }
        return panel;
      });
    });

    // }

    // selectedMems.map((obj,index) => {
    //   mockPanel.map((el,inx)=> el.panelMem.map((ell,inn) => {
    //    if(ell.staff_id === obj.staff_id){
    //      error_mock = `Already exist this ${ell.name} member name  in ${inx + 1} panel`;

    //    }

    //    if(error_mock !==""){
    //      setMockPanel((prevPanel) =>
    //      prevPanel.map((panel) => {
    //        if (panel.id === id) {
    //          return { ...panel, panelMem:selectedMems , error_3 : "" }; // Remove the spread operator here
    //        }
    //        return panel;
    //      })
    //      );
    //     }else{
    //      setMockPanel((prevPanel) =>
    //      prevPanel.map((panel) => {
    //        if (panel.id === id) {
    //          return { ...panel, error_3:error_mock }; // Remove the spread operator here
    //        }
    //        return panel;
    //      })
    //      );
    //     }

    //   }) )
    // })

    // selectedMems.map((obj,index) => {
    //    mockPanel.map((el,inx)=> el.panelMem.map((ell,inn) => {
    //     if(ell.staff_id === obj.staff_id){
    //       // el.error_3 = `Already exist this ${ell.name} member name  in ${inx + 1} panel`;
    //       setMockPanel((prevPanel) =>
    //       prevPanel.map((panel) => {
    //         if (panel.id === id) {
    //           return { ...panel, error_3:`Already exist this ${ell.name} member name  in ${inx + 1} panel` }; // Remove the spread operator here
    //         }
    //         return panel;
    //       })
    //       );// alert(`Already exist this ${ell.name} member name  in ${inx + 1} panel`);
    //     }else{
    //       setMockPanel((prevPanel) =>
    //       prevPanel.map((panel) => {
    //         if (panel.id === id) {
    //           return { ...panel, panelMem:selectedMems , error_3 : "" }; // Remove the spread operator here
    //         }
    //         return panel;
    //       })
    //       );
    //     }
    //    }) )
    // })
  };

  // multiple select applicable batch for model exam special offer

  const handleChangeMutipleMESF = (selected, id) => {
    const selectedBatch = selected.map((option) => ({
      id: option.batch_id,
      batch_id: option.batch_id,
      label: option.label,
      value: option.value,
    }));
    setMExamSF((prevPanel) => {
      return prevPanel.map((panel) => {
        if (panel.id === id) {
          return selectedBatch.length > 0
            ? { ...panel, selectedOptions: selectedBatch, error_4: "" }
            : { ...panel, selectedOptions: [] }; // Remove panelMem
        }
        return panel;
      });
    });
  };

  // district multiple selected function

  const handleChangeMutipleDistrict = (selected, id, districtss) => {
    if (selected) {
      // console.log(selected, "selected");
      const selectDistrict = {
        value: selected.value,
        district_id: selected.district_id,
        label: selected.value,
        district_code: selected.district_code,
      };
      // prevent previously selected district
      // console.log(
      //   "lalitha",
      //   districtWise.find((ele) => ele.district.label === selectDistrict.label)
      // );

      if (
        districtWise.find((ele) => ele.district.label === selectDistrict.label)
      ) {
        setDistrictWise((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              return {
                ...q,
                district: "",
                districtCode: "",
                error_1: "This district already selected",
                error_2: "",
              };
            }
            return q;
          });
        });
      } else {
        setDistrictWise((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              return {
                ...q,
                district: selectDistrict,
                districtCode: selected.district_code,
                error_1: "",
                error_2: "",
              };
            }
            return q;
          });
        });
      }

      setError({
        ...error,
        District: "",
      });
    } else {
      setDistrictWise((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, district: "", districtCode: "" };
          }
          return q;
        });
      });
    }
  };

  const handleChangeMutipleDistrict_Edit = (selected) => {
    // console.log(selected, "selected");
    const selectDistrict = selected.map((option) => ({
      value: option.value,
      district_id: option.district_id,
      label: option.value,
      district_code: option.district_code,
    }));
    setSelectDistrict(selectDistrict);
    const updatedDistrictWise = districtWise.filter((district) =>
      selected.some((ell) => ell.label === district.district.label)
    );
    // console.log(updatedDistrictWise, "updatedDistrictWise");
    // Update the IDs of the districts
    const updatedDistrictWiseWithIds = updatedDistrictWise.map(
      (district, index) => ({
        ...district,
        id: index + 1,
      })
    );
    setDistrictWise(updatedDistrictWiseWithIds);

    // Create a new array to store the selected districts that don't already exist in the districtWise state
    const newSelected = selected.filter(
      (ell) =>
        !districtWise.some((district) => district.district.label === ell.label)
    );
    // console.log(newSelected, "newSelected");
    // Add the new selected districts to the districtWise state
    getExamCenterDisEdit(newSelected);

    setError({
      ...error,
      District: "",
    });
  };
  async function getExamCenterDisEdit(newSelected) {
    for (const ell of newSelected) {
      try {
        const options = await getExamCenterViewEditDis(ell.value);
        setDistrictWise((prevState) => [
          ...prevState,
          {
            id:
              prevState.length > 0 ? prevState[prevState.length - 1].id + 1 : 1,
            pk: "",
            district: {
              value: ell.value,
              district_id: ell.district_id,
              label: ell.label,
              district_code: ell.district_code,
            },
            districtCode: ell.district_code,
            examCenter: [
              {
                examCenterOption: options, // Assigning fetched options here
                exam_center: "",
                MaxCount: 0,
                locationUrl: "",
                error_3: "",
                error_4: "",
                error_5: "",
              },
            ],
            error_1: "",
            error_2: "",
          },
        ]);
      } catch (error) {
        // Handle error if needed
        console.error("Error fetching exam center options:", error);
      }
    }
  }

  // mock panel add

  const AddMockPanel = () => {
    if (mockPanel.length < 10) {
      setMockPanel((prevState) => [
        ...prevState,
        {
          id: prevState.length > 0 ? prevState.length + 1 : 1,
          panel_name: "",
          available_dates: [],
          panelMem: [],
          staffMem: [],
          fees: "",
          error_1: "",
          error_2: "",
          error_3: "",
          error_4: "",
          error_5: "",
        },
      ]);
    } else {
      setSwalMsg({
        show: true,
        status: "Warning",
        message: "you reach more panel details",
        title: "Warning",
        BottomOkClose: "Ok",
      });
    }
  };

  // add counselling panel

  const AddCPanel = () => {
    if (cPanel.length < 10) {
      setCPanel((prevState) => [
        ...prevState,
        {
          id: prevState.length > 0 ? prevState.length + 1 : 1,
          panel_name: "",
          date: "",
          StartTime: "",
          EndTime: "",
          department: [],
          CPaneMem: [],
          CStaffM: [],
          fees: "",
          error_1: "",
          error_2: "",
          error_3: "",
          error_4: "",
          error_5: "",
          error_6: "",
          error_7: "",
          error_8: "",
        },
      ]);
    } else {
      Swal.fire({
        title: "Warning",
        text: "you reach more panel details",
        icon: "Warning",
        confirmButtonText: "Ok",
      });
    }
  };

  // delete counselling panel

  const DeleteCPanel = (id) => {
    const val = cPanel
      .filter((item) => item.id !== id)
      .map((item, index) => ({ ...item, id: index + 1 }));
    setCPanel(val);
  };

  // mock panel delete

  const DeleteMockPanel = (id) => {
    const val = mockPanel
      .filter((item) => item.id !== id)
      .map((item, index) => ({ ...item, id: index + 1 }));
    setMockPanel(val);
  };

  // mock panel onchange function

  // const handleDateChangeMock = (date, id) => {
  //   // console.log(date, "date");
  //   const nnn = moment(date).format("YYYY-MM-DD");
  //   if (!selectedDatesMock.includes(date)) {
  //     setSelectedDatesMock([...selectedDatesMock, nnn]);

  //     // console.log(selectedDatesMock, "selectedDatesMock");
  //     const updatedPanel = mockPanel.map((panel) =>
  //       panel.id === id
  //         ? { ...panel, available_dates: [...panel.available_dates, nnn] }
  //         : panel
  //     );

  //     setMockPanel(updatedPanel);
  //   } else {
  //     alert("ssf");
  //     setSelectedDatesMock(
  //       selectedDatesMock.filter((selectedDate) => selectedDate !== date)
  //     );
  //   }
  // };

  // avaliable dates handle change
  // mock panel onchange function
  const handleDateChangeMock = (date, id) => {
    const nnn = moment(date).format("YYYY-MM-DD");

    if (!selectedDatesMock.includes(nnn)) {
      // If nnn (formatted date) doesn't exist in the array, add it
      setSelectedDatesMock([...selectedDatesMock, nnn]);

      const updatedPanel = mockPanel.map((panel) =>
        panel.id === id
          ? { ...panel, available_dates: [...panel.available_dates, nnn] }
          : panel
      );

      setMockPanel(updatedPanel);
    } else {
      // Date is already selected, handle it as needed (e.g., display an error message)
      alert("Date already selected");
      setSelectedDatesMock(
        selectedDatesMock.filter((selectedDate) => selectedDate !== date)
      );
    }
  };

  // delete mock panel function

  const handleDeleteMock = (dateToDelete, id) => {
    const updatedPanel = mockPanel.map((panel) =>
      panel.id === id
        ? {
            ...panel,
            available_dates: panel.available_dates.filter(
              (date) => date !== dateToDelete
            ),
          }
        : panel
    );
    setMockPanel(updatedPanel);
  };

  //  mockpanel handlechnage function

  const mockPanelHandlechange = (e, id) => {
    const { name, value } = e.target;

    const isValueAlreadyUsed = mockPanel.some(
      (panel) => panel.id !== id && panel.panel_name === value
    );
    if (isValueAlreadyUsed) {
      alert("Value is already there.");
      return; // Prevent further processing if value already exists
    }
    setMockPanel((prevState) => {
      const newState = prevState.map((q) => {
        if (q.id === id) {
          return { ...q, [name]: value };
        }
        return q;
      });

      return newState;
    });
  };

  // counselling panel details handle change

  const handleChangeCPanel_date = (date, id, name) => {
    // console.log(date, id , name ,"ssdf");
    const dateSam = moment(date).format("YYYY-MM-DD");
    const isValueAlreadyUsed_Date = cPanel.some(
      (panel) => panel.id !== id && panel.date === dateSam
    );
    if (isValueAlreadyUsed_Date) {
      alert("Date is already there.");
      return; // Prevent further processing if value already exists
    }
    setCPanel((prevState) => {
      const newState = prevState.map((q) => {
        if (q.id === id) {
          return { ...q, [name]: dateSam, error_2: "" };
        }
        return q;
      });
      return newState;
    });
  };

  const handleChangeCPanel = (e, id) => {
    const { name, value } = e.target;
    if (name === "panel_name") {
      const isValueAlreadyUsed = cPanel.some(
        (panel) => panel.id !== id && panel.panel_name === value
      );
      const isValueAlreadyUsed_Date = cPanel.some(
        (panel) => panel.id !== id && panel.date === value
      );
      if (isValueAlreadyUsed) {
        alert("Value is already there.");
        return; // Prevent further processing if value already exists
      } else if (isValueAlreadyUsed_Date) {
        alert("Date is already there.");
        return; // Prevent further processing if value already exists
      }
      setCPanel((prevState) => {
        const newState = prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value, error_1: "" };
          }
          return q;
        });
        return newState;
      });
    } else if (name === "fees") {
      const isValueAlreadyUsed = cPanel.some(
        (panel) => panel.id !== id && panel.panel_name === value
      );
      const isValueAlreadyUsed_Date = cPanel.some(
        (panel) => panel.id !== id && panel.date === value
      );
      if (isValueAlreadyUsed) {
        alert("Value is already there.");
        return; // Prevent further processing if value already exists
      } else if (isValueAlreadyUsed_Date) {
        alert("Date is already there.");
        return; // Prevent further processing if value already exists
      }
      setCPanel((prevState) => {
        const newState = prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value, error_8: "" };
          }
          return q;
        });
        return newState;
      });
    } else {
      const isValueAlreadyUsed = cPanel.some(
        (panel) => panel.id !== id && panel.panel_name === value
      );
      const isValueAlreadyUsed_Date = cPanel.some(
        (panel) => panel.id !== id && panel.date === value
      );
      if (isValueAlreadyUsed) {
        alert("Value is already there.");
        return; // Prevent further processing if value already exists
      } else if (isValueAlreadyUsed_Date) {
        alert("Date is already there.");
        return; // Prevent further processing if value already exists
      }
      setCPanel((prevState) => {
        const newState = prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value };
          }
          return q;
        });
        return newState;
      });
    }
  };

  const handleChangeCPanelTime = (TimeDate, id, dname) => {
    if (dname === "StartTime") {
      const formattedTime = dayjs(TimeDate).format("hh:mm:ss A");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      setCPanel((prevState) => {
        const newState = prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [dname]: formattedTime, error_3: "" };
          }
          return q;
        });
        return newState;
      });
    } else if (dname === "EndTime") {
      const formattedTime = dayjs(TimeDate).format("hh:mm:ss A");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      setCPanel((prevState) => {
        const newState = prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [dname]: formattedTime, error_4: "" };
          }
          return q;
        });
        return newState;
      });
    }
  };

  // staff members chnage function

  const handleChangeMutiplestaffMem = (selected, id) => {
    // console.log(mockPanel);
    const selectedStaffMem = selected.map((option) => ({
      staff_id: option.staff_id,
      type: option.type,
      label: option.value,
      name: option.name,
      email: option.email,
      department: "",
    }));
    setSelectedStaffMem(selectedStaffMem);
    // setMockPanel((prevPanel) =>
    //   prevPanel.map((panel) =>
    //     panel.id === id ? { ...panel, staffMem: selectedStaffMem } : panel
    //   )
    // );
    setMockPanel((prevPanel) => {
      // Check if staffMem and selectedStaffMem have the same staff_id
      const hasSameStaffId = prevPanel.some((panel) =>
        panel.staffMem.some(
          (mem) => mem.staff_id === selectedStaffMem[0]?.staff_id // Use optional chaining to avoid errors if selectedMems is empty
        )
      );
      if (hasSameStaffId) {
        // Show an alert here because panelMem and selectedMems have the same staff_id
        alert("This Teaching faculty already exist in pervious panel");
        return prevPanel; // Return the unchanged state
      }
      // If there is no staff_id collision and selectedMems is not empty,
      // update the state only when panel.id === id
      return prevPanel.map((panel) => {
        if (panel.id === id) {
          // Use a conditional to handle the case where selectedMems is empty
          return selectedStaffMem.length > 0
            ? { ...panel, staffMem: selectedStaffMem }
            : { ...panel, staffMem: [] }; // Remove panelMem
        }
        return panel;
      });
    });
    setError({
      ...error,
      staffMem: "",
    });
  };

  //exam  details  add function
  const AddExamDetails = () => {
    // setExamDetails((prevState) => [
    //   ...prevState,
    //   {
    //     id: prevState.length > 0 ? prevState.length + 1 : 1,
    //     title: "",
    //     exam_title_id: "",
    //     pk: "",
    //     date: "",
    //     duration: "",
    //     startTime: "",
    //     endTime: "",
    //     syllabus: null,
    //     original_syllabus_url: null,
    //     LastDateApply: "",
    //     NPapers: "",
    //     PaperDetailsSection: [],
    //     fees: "",
    //     error_1: "",
    //     error_2: "",
    //     error_3: "",
    //     error_4: "",
    //     error_5: "",
    //     error_6: "",
    //     error_7: "",
    //   },
    // ]);

    // setInitialfields((prevFields) => ({
    //   ...prevFields,
    //   ["NModelExam"]: examDetail.length > 0 ? examDetail.length : 0,
    // }));

    setInitialfields((prevFields = { NModelExam: 0 }) => ({
      ...prevFields,
      NModelExam: prevFields.NModelExam > 0 ? prevFields.NModelExam + 1 : 1,
    }));

    // Update ExamDetails based on the new count
    setExamDetails((prevState) => {
      const newExamDetails = Array.from(
        {
          length:
            initialfields.NModelExam > 0 ? initialfields.NModelExam + 1 : 1,
        },
        (_, index) => {
          const existingExamDetail = prevState[index];
          if (existingExamDetail) {
            return existingExamDetail;
          } else {
            return {
              id: index + 1,
              title: "",
              exam_title_id: "",
              pk: "",
              date: "",
              duration: "",
              startTime: "",
              endTime: "",
              syllabus: null,
              original_syllabus_url: null,
              LastDateApply: "",
              NPapers: "",
              PaperDetailsSection: [],
              fees: "",
              error_1: "",
              error_2: "",
              error_3: "",
              error_4: "",
              error_5: "",
              error_6: "",
              error_7: "",
              error_8: "",
              error_9: "",
              // txtBatchDapply : txtBatchDapply,
            };
          }
        }
      );

      return newExamDetails;
    });
    setError({
      ...error,
      ["NModelExam"]: "",
    });
  };
  //exam  details delete function
  // const DeleteExamDetails = (id, name) => {

  //   const val = examDetail
  //     .filter((item) => item.id !== id)
  //     .map((item, index) => ({ ...item, id: index + 1 }));
  //   setExamDetails(val);
  //   setInitialfields((prevFields) => ({
  //     ...prevFields,
  //     ["NModelExam"]:
  //       prevFields["NModelExam"] - (examDetail.length > 0 ? 1 : 0),
  //   }));

  // };
  const DeleteExamDetails = (id, pk, batchID, name) => {
    const formData = {
      batch_id: parseInt(batchID),
      exam_detail_pk: pk,
      type: 4,
    };
    if (pk) {
      AxiosInstance.post("api/batch/modelExam/admCheck", formData)
        .then((res) => {
          // console.log("laksh-res", res);
          if (res.data.status) {
            // alert(res.data.message);
            Swal.fire({
              title: "Warning",
              text: res.data.message,
              icon: "Warning",
              confirmButtonText: "Ok",
            });
          } else {
            const val = examDetail
              .filter((item) => item.id !== id)
              .map((item, index) => ({ ...item, id: index + 1 }));
            setExamDetails(val);
            setInitialfields((prevFields) => ({
              ...prevFields,
              ["NModelExam"]:
                prevFields["NModelExam"] - (examDetail.length > 0 ? 1 : 0),
            }));
          }
        })
        .catch((err) => console.error("api/batch/modelExam/admCheck", err));
    } else {
      const val = examDetail
        .filter((item) => item.id !== id)
        .map((item, index) => ({ ...item, id: index + 1 }));
      setExamDetails(val);
      setInitialfields((prevFields) => ({
        ...prevFields,
        ["NModelExam"]:
          prevFields["NModelExam"] - (examDetail.length > 0 ? 1 : 0),
      }));
    }
  };

  const DeleteExamDetailsPaper = (id, parentId) => {
    const updatedExamDetails = examDetail.map((exam) => {
      // If the exam's ID matches the parentId, modify its PaperDetailsSection
      if (exam.id === parentId) {
        console.log(exam.NPapers, "exam.NPapers");
        exam.NPapers = exam.NPapers - 1;
        // Step 2: Filter out the paper with the specified paperId
        const updatedPapers = exam.PaperDetailsSection.filter(
          (paper) => paper.id !== id
        ).map((item, index) => ({ ...item, id: index + 1 }));
        return {
          ...exam,

          PaperDetailsSection: updatedPapers,
        };
      }
      // If it's not the matching exam, just return it unchanged
      return exam;
    });
    setExamDetails(updatedExamDetails);
  };

  const AddExamDetailsPaper = (id, parentId) => {
    setExamDetails((prevState) => {
      return prevState.map((q) => {
        if (q.id === parentId) {
          // Update NPapers count and clear error
          const updatedNPapers = q.NPapers > 0 ? q.NPapers + 1 : 1;
          const updatedObject = {
            ...q,
            NPapers: updatedNPapers,
            error_9: "",
          };

          // Add one new object to PaperDetailsSection
          const newPaperDetails = {
            id: q.PaperDetailsSection.length + 1,
            title: "",
            date: "",
            duration: "",
            startTime: "",
            endTime: "",
            syllabus: null,
            original_syllabus_url: null,
            error_1: "",
            error_2: "",
            error_3: "",
            error_4: "",
            error_5: "",
            error_6: "",
            error_7: "",
            // txtBatchPEdate : txtBatchPEdate,
          };
          const updatedPaperDetailsSection = [
            ...q.PaperDetailsSection,
            newPaperDetails,
          ];
          // Update the object with the new PaperDetailsSection
          return {
            ...updatedObject,
            PaperDetailsSection: updatedPaperDetailsSection,
          };
        }
        return q;
      });
    });
  };

  const examdetailschange_date = (date_exam, id, name) => {
    const dateExam = moment(date_exam).format("YYYY-MM-DD");
    if (name === "date") {
      setExamDetails((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            const tempLastDateToApply = new Date(q.LastDateApply);
            const tempExamDate = new Date(date_exam);
            if (tempLastDateToApply > tempExamDate) {
              return {
                ...q,
                error_2:
                  "Exam Date should be greater than the Last Date to Apply Date",
                [name]: "",
              };
            } else if (q.LastDateApply !== "") {
              return { ...q, error_2: "", [name]: dateExam };
            } else {
              return {
                ...q,
                error_2: "Please select LastDate to Apply Before choose Date",
                [name]: "",
              };
            }
          }
          return q;
        });
      });
    } else if (name === "LastDateApply") {
      setExamDetails((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, error_8: "", [name]: dateExam }; //reset the date value whenever lastdateapply changes.
          }
          return q;
        });
      });
    }
  };

  const examdetailschange_datePaper = (date_exam, id, parentId, name) => {
    const dateExam = moment(date_exam).format("YYYY-MM-DD");
    if (name === "date") {
      setExamDetails((prevState) => {
        return prevState.map((q) => {
          if (q.id === parentId) {
            const updatedPaperDetailsSection = q.PaperDetailsSection.map(
              (paper) => {
                if (paper.id === id) {
                  if (q.LastDateApply === "") {
                    return {
                      ...paper,
                      error_2: "Please Select Last Date To Apply",
                      [name]: "",
                    };
                  } else {
                    return {
                      ...paper,
                      [name]: dateExam,
                      ["startTime"]: "",
                      error_2: "",
                    };
                  }
                }
                return paper;
              }
            );
            return { ...q, PaperDetailsSection: updatedPaperDetailsSection };
          }
          return q;
        });
      });
    }
  };

  const batchChange_date = (batchDate, id, name) => {
    //  console.log(name,"name");
    if (name === "BatchRFrom") {
      const dateExam = moment(batchDate).format("YYYY-MM-DD");
      setOldBatch((prevState) => {
        const updatedState = prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: dateExam, error_2: "" };
          }
          return q;
        });
        const updatedBatch = updatedState.find((q) => q.id === id);
        if (
          updatedBatch.BatchRFrom.trim() !== "" &&
          updatedBatch.BatchRTo.trim() !== ""
        ) {
          getOldBatch(updatedBatch.BatchRFrom, updatedBatch.BatchRTo, id);
        }
        return updatedState;
      });
      setError({
        ...error,
        oldBatch: "",
      });
    } else if (name === "BatchRTo") {
      const dateExam = moment(batchDate).format("YYYY-MM-DD");
      setOldBatch((prevState) => {
        const updatedState = prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: dateExam, error_3: "" };
          }
          return q;
        });
        const updatedBatch = updatedState.find((q) => q.id === id);
        if (
          updatedBatch.BatchRFrom.trim() !== "" &&
          updatedBatch.BatchRTo.trim() !== ""
        ) {
          getOldBatch(updatedBatch.BatchRFrom, updatedBatch.BatchRTo, id);
        }
        return updatedState;
      });
      setError({
        ...error,
        oldBatch: "",
      });
    }
  };

  const examdetailschangeTime = (TimeDate, id, dname) => {
    if (dname === "startTime") {
      const formattedTime = dayjs(TimeDate).format("hh:mm:ss A");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      setExamDetails((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            if (
              dname === "startTime" &&
              q.endTime === "" &&
              q.duration === ""
            ) {
              return { ...q, error_4: "", error_5: "", [dname]: formattedTime };
            } else {
              return {
                ...q,
                error_4: "",
                error_5: "",
                [dname]: formattedTime,
                ["endTime"]: " ",
                ["duration"]: "",
              };
            }
          }
          return q;
        });
      });
    } else if (dname === "endTime") {
      const formattedTime = dayjs(TimeDate).format("hh:mm:ss A");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      setExamDetails((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            if (q.startTime === "") {
              return {
                ...q,
                error_5: "Please Choose startTime First",
                [dname]: "",
              };
            }
            const formattedTimeMoment = moment(formattedTime, "hh:mm:ss A");
            const startTimeMoment = moment(q.startTime, "hh:mm:ss A");

            if (
              q.startTime !== "" &&
              formattedTimeMoment.isBefore(startTimeMoment)
            ) {
              return {
                ...q,
                error_5: "End time must be after start time",
                [dname]: "",
                ["duration"]: "",
              };
            } else if (
              q.startTime !== "" &&
              formattedTimeMoment.isSame(startTimeMoment)
            ) {
              return {
                ...q,
                error_5: "End time must be after start time",
                [dname]: "",
                ["duration"]: "",
              };
            } else {
              const duration = moment.duration(
                formattedTimeMoment.diff(startTimeMoment)
              );
              const hours = duration.hours();
              // console.log(hours, "hours");
              const minutes = duration.minutes();
              if (minutes > 0) {
                return {
                  ...q,
                  error_5: "Minutes Not Allowed to select",
                  [dname]: "",
                  ["duration"]: "",
                };
              } else {
                return {
                  ...q,
                  error_5: "",
                  [dname]: formattedTime,
                  ["duration"]: hours,
                };
              }
            }
          }
          return q;
        });
      });
    }
  };

  const examdetailschangeTimePaper = (TimeDate, id, parentId, namess) => {
    if (namess === "startTime") {
      const formattedTime = dayjs(TimeDate).format("hh:mm:ss A");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      setExamDetails((prevState) => {
        return prevState.map((q) => {
          if (q.id === parentId) {
            const updatedPaperDetailsSection = q.PaperDetailsSection.map(
              (paper) => {
                if (
                  paper.id === id &&
                  paper.endTime === "" &&
                  paper.duration === ""
                ) {
                  return {
                    ...paper,
                    [namess]: formattedTime,
                    ["endTime"]: "",
                    error_4: "",
                    ["duration"]: "",
                  };
                }
                //  else {
                //   return {
                //     ...paper,
                //     [namess]: formattedTime,
                //     error_4: "",
                //     ["endTime"]: " ",
                //     ["duration"]: "",
                //   };
                // }
                return paper;
              }
            );
            return { ...q, PaperDetailsSection: updatedPaperDetailsSection };
          }
          return q;
        });
      });
    } else if (namess === "endTime") {
      const formattedTime = dayjs(TimeDate).format("hh:mm:ss A");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      setExamDetails((prevState) => {
        return prevState.map((q) => {
          if (q.id === parentId) {
            const updatedPaperDetailsSection = q.PaperDetailsSection.map(
              (paper) => {
                const formattedTimeMoment = moment(formattedTime, "hh:mm:ss A");
                const startTimeMoment = moment(paper.startTime, "hh:mm:ss A");
                if (paper.id === id) {
                  if (paper.startTime === "") {
                    return {
                      ...paper,
                      error_5: "Please Choose startTime First",
                      [namess]: "",
                    };
                  } else if (
                    paper.startTime !== "" &&
                    formattedTimeMoment.isBefore(startTimeMoment)
                  ) {
                    return {
                      ...paper,
                      error_5: "End time must be after start time",
                      [namess]: "",
                      ["duration"]: "",
                    };
                  } else if (
                    paper.startTime !== "" &&
                    formattedTimeMoment.isSame(startTimeMoment)
                  ) {
                    return {
                      ...paper,
                      error_5: "End time must be after start time",
                      [namess]: "",
                      ["duration"]: "",
                    };
                  } else {
                    const duration = moment.duration(
                      formattedTimeMoment.diff(startTimeMoment)
                    );
                    const hours = duration.hours();
                    const minutes = duration.minutes();
                    if (minutes > 0) {
                      return {
                        ...paper,
                        error_5: "Minutes Not Allowed to select",
                        [namess]: "",
                        ["duration"]: "",
                      };
                    } else {
                      return {
                        ...paper,
                        [namess]: formattedTime,
                        error_5: "",
                        ["duration"]: hours,
                      };
                    }
                  }
                }
                return paper;
              }
            );
            return { ...q, PaperDetailsSection: updatedPaperDetailsSection };
          }
          return q;
        });
      });
    }
  };

  //exam  details handle change  function
  const examdetailschange = (e, id) => {
    const { name, value, files } = e.target;
    // console.log(name,value,id , examDetail);
    if (name === "syllabus" && files && files.length > 0) {
      const batch_image = files[0];
      if (
        files[0].type == "application/pdf" ||
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("syllabus", batch_image);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setExamDetails((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      syllabus: res.data.path,
                      original_syllabus_url: res.data.originalname,
                      error_6: "",
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          setExamDetails((prevState) => {
            return prevState.map((q) => {
              if (q.id === id) {
                return {
                  ...q,
                  syllabus: "",
                  original_syllabus_url: "",
                  error_6: "File must be less than 2MB",
                };
              }
              return q;
            });
          });
        }
      } else {
        alert("Syllabus must be in pdf and img file");
      }
    } else if (name === "startTime") {
      setExamDetails((prevState) => {
        // Check if name already exists in prevState
        const isNameExists = prevState.some(
          (q) => q.id !== id && q.name === value
        );
        // if (isNameExists) {
        //   alert("This Name already exists");
        //   return prevState;
        // }
        // Update the name if it doesn't already exist
        return prevState.map((q) => {
          if (q.id === id) {
            if (name === "title") {
              return { ...q, error_1: "", [name]: value };
            } else if (name === "date") {
              return { ...q, error_2: "", [name]: value };
            } else if (name === "duration") {
              return { ...q, error_3: "", [name]: value };
            } else if (name === "startTime") {
              return { ...q, error_4: "", [name]: value };
            } else if (name === "endTime") {
              return { ...q, error_5: "", [name]: value };
            } else if (name === "fees") {
              return { ...q, error_6: "", [name]: value };
            }
            // return { ...q, [name]: value };
          }
          return q;
        });
      });
      txtBatchEendTime.current.focus();
    } else if (name === "duration") {
      setExamDetails((prevState) => {
        // Check if name already exists in prevState
        const isNameExists = prevState.some(
          (q) => q.id !== id && q.name === value
        );

        // if (isNameExists) {
        //   alert("This Name already exists");
        //   return prevState;
        // }
        // Update the name if it doesn't already exist
        return prevState.map((q) => {
          if (q.id === id) {
            if (name === "title") {
              return { ...q, error_1: "", [name]: value };
            } else if (name === "date") {
              return { ...q, error_2: "", [name]: value };
            } else if (name === "duration") {
              return { ...q, error_3: "", [name]: value };
            } else if (name === "startTime") {
              return { ...q, error_4: "", [name]: value };
            } else if (name === "endTime") {
              return { ...q, error_5: "", [name]: value };
            } else if (name === "fees") {
              return { ...q, error_6: "", [name]: value };
            }
            // return { ...q, [name]: value };
          }
          return q;
        });
      });
      txtBatchEendTime.current.focus();
    } else if (name === "NPapers") {
      setExamDetails((prevState) => {
        // Create a new state by mapping over the previous state
        return prevState.map((q) => {
          // Check if the current object matches the id
          if (q.id === id) {
            // Update the specific field with the new value
            let updatedObject = { ...q, [name]: value, error_9: "" };
            // If value is positive, add new objects to the PaperDetailsSection
            if (value > 0) {
              let newState = [...q.PaperDetailsSection]; // Copy the existing PaperDetailsSection
              for (let i = 0; i < value; i++) {
                newState.push({
                  id: prevState.length > 0 ? prevState.length + i : 1,
                  title: "",
                  date: "",
                  duration: "",
                  startTime: "",
                  endTime: "",
                  syllabus: null,
                  original_syllabus_url: null,
                  error_1: "",
                  error_2: "",
                  error_3: "",
                  error_4: "",
                  error_5: "",
                  error_6: "",
                  error_7: "",
                });
              }
              updatedObject = {
                ...updatedObject,
                PaperDetailsSection: newState,
              };
            } else {
              updatedObject = { ...updatedObject, PaperDetailsSection: [] };
            }

            // Return the updated object
            return updatedObject;
          }
          // If the id doesn't match, return the original object
          return q;
        });
      });
    } else if (name === "fees") {
      setExamDetails((prevState) => {
        // Check if name already exists in prevState
        if (value > 0) {
          return prevState.map((q) => {
            if (q.id === id) {
              return { ...q, [name]: value, error_7: "" };
            }
            return q;
          });
        } else {
          return prevState.map((q) => {
            if (q.id === id) {
              return {
                ...q,
                [name]: "",
                error_7: "Fees Must be greater than 0",
              };
            }
            return q;
          });
        }
      });
    } else {
      setExamDetails((prevState) => {
        // Check if name already exists in prevState
        const isNameExists = prevState.some(
          (q) => q.id !== id && q.title === value
        );
        // console.log(isNameExists,"isNameExists");
        if (isNameExists) {
          // alert("Thissss Name already exists");
          return prevState.map((q) => {
            if (q.id === id) {
              if (name === "title") {
                return {
                  ...q,
                  error_1: "This Name already exists",
                  [name]: value,
                };
              }
            }
            return q;
          });
        }
        // Update the name if it doesn't already exist
        return prevState.map((q) => {
          if (q.id === id) {
            if (name === "title") {
              return { ...q, error_1: "", [name]: value };
            } else if (name === "date") {
              return { ...q, error_2: "", [name]: value };
            } else if (name === "duration") {
              return { ...q, error_3: "", [name]: value };
            } else if (name === "startTime") {
              return { ...q, error_4: "", [name]: value };
            } else if (name === "endTime") {
              return { ...q, error_5: "", [name]: value };
            } else if (name === "fees") {
              return { ...q, error_6: "", [name]: value };
            }
            // return { ...q, [name]: value };
          }
          return q;
        });
      });
    }
  };

  //exam  details paper  handle change  function
  const examdetailschangePaper = (e, id, parentId) => {
    const { name, value, files } = e.target;
    if (name === "title") {
      setExamDetails((prevState) => {
        // Update the title if the parent and child IDs match
        return prevState.map((q) => {
          if (q.id === parentId) {
            const updatedPaperDetailsSection = q.PaperDetailsSection.map(
              (paper) => {
                if (paper.id === id) {
                  return { ...paper, [name]: value, error_1: "" };
                }
                return paper;
              }
            );

            return { ...q, PaperDetailsSection: updatedPaperDetailsSection };
          }
          return q;
        });
      });
    } else if (name === "duration") {
      setExamDetails((prevState) => {
        // Update the title if the parent and child IDs match
        return prevState.map((q) => {
          if (q.id === parentId) {
            const updatedPaperDetailsSection = q.PaperDetailsSection.map(
              (paper) => {
                if (paper.id === id) {
                  return { ...paper, [name]: value, error_3: "" };
                }
                return paper;
              }
            );

            return { ...q, PaperDetailsSection: updatedPaperDetailsSection };
          }
          return q;
        });
      });
    } else if (name === "syllabus" && files && files.length > 0) {
      const batch_image = files[0];
      if (
        files[0].type == "application/pdf" ||
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("syllabus", batch_image);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setExamDetails((prevState) => {
                // Update the title if the parent and child IDs match
                return prevState.map((q) => {
                  if (q.id === parentId) {
                    const updatedPaperDetailsSection =
                      q.PaperDetailsSection.map((paper) => {
                        if (paper.id === id) {
                          return {
                            ...paper,
                            syllabus: res.data.path,
                            original_syllabus_url: res.data.originalname,
                            error_6: "",
                          };
                        }
                        return paper;
                      });

                    return {
                      ...q,
                      PaperDetailsSection: updatedPaperDetailsSection,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          setExamDetails((prevState) => {
            // Update the title if the parent and child IDs match
            return prevState.map((q) => {
              if (q.id === parentId) {
                const updatedPaperDetailsSection = q.PaperDetailsSection.map(
                  (paper) => {
                    if (paper.id === id) {
                      return {
                        ...paper,
                        syllabus: "",
                        original_syllabus_url: "",
                        error_6: "File must be less than 2MB",
                      };
                    }
                    return paper;
                  }
                );

                return {
                  ...q,
                  PaperDetailsSection: updatedPaperDetailsSection,
                };
              }
              return q;
            });
          });
        }
      } else {
        setExamDetails((prevState) => {
          // Update the title if the parent and child IDs match
          return prevState.map((q) => {
            if (q.id === parentId) {
              const updatedPaperDetailsSection = q.PaperDetailsSection.map(
                (paper) => {
                  if (paper.id === id) {
                    return {
                      ...paper,
                      syllabus: "",
                      original_syllabus_url: "",
                      error_6: "Only Pdf and Img Files Allowed",
                    };
                  }
                  return paper;
                }
              );

              return {
                ...q,
                PaperDetailsSection: updatedPaperDetailsSection,
              };
            }
            return q;
          });
        });
      }
    }
  };

  // end time chnage

  const handleEndTimeChange = (e, id) => {
    const newEndTime = e.target.value;
    setExamDetails((prevState) =>
      prevState.map((q) => {
        if (q.id === id) {
          return { ...q, endTime: newEndTime, error_5: "" }; // Update endTime and clear error
        }
        return q;
      })
    );
  };

  const handleSelectCH = (option, dname) => {
    if (dname === "Subject") {
      setSubMulti([option]);
      setError({
        ...error,
        [dname]: "",
      });
    } else if (dname === "Subject_Multi") {
      setSubMulti(option);
      setError({
        ...error,
        Subject: "",
      });
    } else if (dname === "ExamName") {
      setInitialfields((prevState) => ({
        ...prevState,
        [dname]: option.value,
      }));

      setInitialfields((prevState) => ({
        ...prevState,
        ["ExamName_select"]: option.label,
      }));
      setError({
        ...error,
        [dname]: "",
      });
    } else {
      setInitialfields((prevState) => ({
        ...prevState,
        [dname]: option.course_name,
        ["course_id"]: option.value,
        ["courseNameCategory"]: option.course_name,
        ["courseSubCategory"]: option.subCategory,
      }));
      setError({
        ...error,
        [dname]: "",
      });
    }
  };

  const handleChangeCheckbox = (e, label) => {
    if (e) {
      setSubMulti([]);
      setInitialfields((prevState) => ({
        ...prevState,
        ["class_name"]: e.label,
        ["course_id"]: e.value,
        ["courseNameCategory"]: e.label,
        ["courseSubCategory"]: e.subcategory_code,
      }));
      setSubjectselect(e);
      setError({
        ...error,
        ["class_name"]: "",
      });
    } else {
      setSubMulti([]);
      setInitialfields((prevState) => ({
        ...prevState,
        ["class_name"]: "",
        ["course_id"]: "",
        ["courseNameCategory"]: "",
        ["courseSubCategory"]: "",
      }));
      setSubjectselect(e);
      setError({
        ...error,
        ["class_name"]: "Please select course for respective category",
      });
    }

    //  if ((e.isArr) && (e.course_name !== null)) {
    //   console.log(e,recordID,"recordID");
    //   let index = e.ind;
    //   // setCloseMenu(false);
    //   const specificItem = uniqueCoursedata.find((item) => item.ind === index);
    //   if (specificItem) {
    //     // Get the "subCategory" value from the specific item
    //     const subCategoryCode = specificItem.subCategory;
    //     // Filter the data array to get items with the same "subCategory"
    //     const filteredItems = subcategory.filter((item) => item.subcategory_code === subCategoryCode);
    //     // Create a new array with the desired properties
    //     const result = filteredItems.map((item) => ({
    //       id:item.id,
    //       value:item.course_name,
    //       label:item.course_name,
    //       subCategory:item.subcategory_code,
    //       course_name:item.course_name,
    //       isArr: false
    //     }));

    //     const tempSub = uniqueCoursedata;

    //     // Find the index of the specific item in the subjects array
    //     const specificItemIndex = tempSub.findIndex((item) => item.ind === index);

    //     console.log(specificItemIndex,"Specific index item");
    //     // If the specific item was found in the subjects array, insert the result after it
    //     if (specificItemIndex !== -1) {
    //       const updatedSubjects = [...uniqueCoursedata];
    //       updatedSubjects.splice(specificItemIndex + 1, 0, ...result);
    //       console.log(updatedSubjects,"updatedSubjects");
    //       setSubjects(updatedSubjects);
    //     }
    //   }
    // }
    // else {

    // }
  };

  const handleChangeTime = (TimeDate, Dname) => {
    if (Dname === "startTime") {
      const formattedTime = dayjs(TimeDate).format("hh:mm:ss A");
      // console.log(formattedTime,"formattedTime");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      setInitialfields((prevFields) => ({
        ...prevFields,
        ["startTime_In"]: result,
        ["startTime"]: formattedTime,
      }));
      // Set an error message indicating that end time must be above start time
      setError({
        ...error,
        ["startTime"]: "",
      });
    } else if (Dname === "endTime") {
      const formattedTime = dayjs(TimeDate).format("HH:mm:ss A");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      const start = dayjs(
        `${initialfields.startDate} ` + initialfields.startTime,
        "YYYY-MM-DD hh:mm:ss A"
      );
      const end = dayjs(
        `${initialfields.startDate} ` + formattedTime,
        "YYYY-MM-DD HH:mm:ss A"
      );
      // console.log(result,"result",start,end);
      if (end.isAfter(start)) {
        setInitialfields((prevFields) => ({
          ...prevFields,
          ["endTime_In"]: result,
          ["endTime"]: formattedTime,
        }));
        setError({
          ...error,
          ["endTime"]: "",
        });
      } else {
        setError({
          ...error,
          ["endTime"]: "Please select above given start time ",
        });
      }
    }
  };

  /**
   * Handle change event for password fields.
   * Updates the corresponding field value in state based on the input field's name.
   * @param {object} event - The change event object.
   * @param {string} event.target.name - The name of the input field.
   * @param {string} event.target.value - The value of the input field.
   */
  const handleChange = (event) => {
    const { name, value, id, checked, files } = event.target;
    if (name === "batchImg" && files && files.length > 0) {
      const batchImg = files[0];
      // console.log(batchImg);
      if (
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("batch_image", batchImg);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              // console.log(res.data.path);
              setInitialfields({
                ...initialfields,
                batchImg: res.data.path,
                original_img_url: res.data.originalname,
              });
              setError({
                ...error,
                batchImg: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File must be less than 2MB";
          setError({
            ...error,
            batchImg: err,
          });
        }
      } else {
        let err = "PNG , JPG , JPEG images only allowed";
        setError({
          ...error,
          batchImg: err,
        });
      }
    } else if (name === "testDet" && files && files.length > 0) {
      const testDet = files[0];
      // console.log(testDet);
      if (
        files[0].type == "application/pdf" ||
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("batch_image", testDet);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setInitialfields({
                ...initialfields,
                testDet: res.data.path,
                original_test_img_url: res.data.originalname,
              });
              setError({
                ...error,
                testDet: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setError({
            ...error,
            testDet: err,
          });
        }
      } else {
        let err = "Pdf and Img Files only allowed.";
        setError({
          ...error,
          testDet: err,
        });
      }
    } else if (name === "special_syll" && files && files.length > 0) {
      const special_syll = files[0];

      // console.log(testDet);
      if (
        files[0].type == "application/pdf" ||
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("subject_file", special_syll);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setInitialfields({
                ...initialfields,
                special_syll: res.data.path,
                original_subject_file_path: res.data.originalname,
              });
              setError({
                ...error,
                special_syll: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setError({
            ...error,
            special_syll: err,
          });
        }
      } else {
        let err = "Pdf and Img Files only allowed.";
        setError({
          ...error,
          special_syll: err,
        });
      }
    } else if (name === "class_name") {
      let idvalue = event.target.options[event.target.selectedIndex].id;

      setInitialfields((prevFields) => ({
        ...prevFields,
        course_id: idvalue,
      }));

      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    } else if (name === "endTime") {
      const startTimeStamp = new Date(
        `1970-01-01 ${initialfields.startTime}`
      ).getTime();
      const endTimeStamp = new Date(`1970-01-01 ${value}`).getTime();
      if (startTimeStamp < endTimeStamp) {
        // If end time is after start time
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: value,
        }));
        // set error values into state
        setError({
          ...error,
          [name]: "",
        });
        // Clear the error message for endTime
        setError({
          ...error,
          [name]: "",
        });
      } else {
        // If end time is NOT after start time
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: "",
        }));
        // Set an error message indicating that end time must be above start time
        setError({
          ...error,
          [name]: "End time must be above start time",
        });
      }
    } else if (name === "courseCategory") {
      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setInitialfields((prevFields) => ({
        ...prevFields,
        class_name: "",
        course_id: "",
      }));
      setSubMulti([]);
      setSubject([]);
      setSubjectselect([]);
      setError({
        ...error,
        [name]: "",
      });
    } else if (name === "fees") {
      if (/^[0-9]+$/.test(value)) {
        const valuefees = parseInt(value);
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: valuefees,
        }));
        // set error values into state
        setError({
          ...error,
          [name]: "",
        });
      } else {
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: value,
        }));
        // set error values into state
        setError({
          ...error,
          [name]: "",
        });
      }
    } else if (name === "offerAmount") {
      if (/^[0-9]+$/.test(value)) {
        const valuefees = parseInt(value);
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: valuefees,
        }));
        // set error values into state
        setError({
          ...error,
          [name]: "",
        });
      } else {
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: value,
        }));
        // set error values into state
        setError({
          ...error,
          [name]: "",
        });
      }
    } else if (name === "pPayment") {
      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    } else if (name === "perOne") {
      if (value >= 100) {
        setError({
          ...error,
          [name]: "Please enter less than 100 percentage",
        });
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: "",
        }));
      } else {
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: value,
        }));
        setError({
          ...error,
          [name]: "",
        });
      }
    } else if (name === "perTwo") {
      if (value >= 100) {
        setError({
          ...error,
          [name]: "Please enter less than 100 percentage",
        });
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: "",
        }));
      } else {
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: value,
        }));
        setError({
          ...error,
          [name]: "",
        });
      }
    } else if (name === "perThree") {
      if (value >= 100) {
        setError({
          ...error,
          [name]: "Please enter less than 100 percentage",
        });
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: "",
        }));
      } else {
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: value,
        }));
        setError({
          ...error,
          [name]: "",
        });
      }
    } else if (name === "NModelExam") {
      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
      // setExamDetails((prevState) => {
      //   const laksh = Array.from({ length: value }, (modelExam, i) => i);
      //   // console.log("laksh-prevSt", prevState);
      //   const sri = laksh.flatMap((modelExam, index) =>
      //     prevState[index]
      //       ? prevState[index]
      //       : {
      //           id: index + 1,
      //           title: "",
      //           exam_title_id: "",
      //           pk: "",
      //           date: "",
      //           duration: "",
      //           startTime: "",
      //           endTime: "",
      //           syllabus: null,
      //           original_syllabus_url: null,
      //           LastDateApply: "",
      //           NPapers: "",
      //           PaperDetailsSection: [],
      //           fees: "",
      //           error_1: "",
      //           error_2: "",
      //           error_3: "",
      //           error_4: "",
      //           error_5: "",
      //           error_6: "",
      //           error_7: "",
      //           error_8: "",
      //           error_9: "",
      //         }
      //   );
      //   // console.log("laksh-error", sri);
      //   // console.log("laksh", laksh);
      //   return sri;
      // });
    } else if (name === "Papers") {
      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
        ["NModelExam"]: "",
      }));
      setExamDetails([]);
      // set error values into state
      setError({
        ...error,
        [name]: "",
      });
    } else {
      // set input values into state
      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      // set error values into state
      setError({
        ...error,
        [name]: "",
      });
    }
  };

  const handleIncreaseModel = () => {
    // Increment NModelExam
    setInitialfields((prevFields = { NModelExam: 0 }) => ({
      ...prevFields,
      NModelExam: prevFields.NModelExam > 0 ? prevFields.NModelExam + 1 : 1,
    }));

    // Update ExamDetails based on the new count
    setExamDetails((prevState) => {
      const newExamDetails = Array.from(
        {
          length:
            initialfields.NModelExam > 0 ? initialfields.NModelExam + 1 : 1,
        },
        (_, index) => {
          const existingExamDetail = prevState[index];
          if (existingExamDetail) {
            return existingExamDetail;
          } else {
            return {
              id: index + 1,
              title: "",
              exam_title_id: "",
              pk: "",
              date: "",
              duration: "",
              startTime: "",
              endTime: "",
              syllabus: null,
              original_syllabus_url: null,
              LastDateApply: "",
              NPapers: "",
              PaperDetailsSection: [],
              fees: "",
              error_1: "",
              error_2: "",
              error_3: "",
              error_4: "",
              error_5: "",
              error_6: "",
              error_7: "",
              error_8: "",
              error_9: "",
              // txtBatchDapply : txtBatchDapply,
            };
          }
        }
      );

      return newExamDetails;
    });
    setError({
      ...error,
      ["NModelExam"]: "",
    });
  };
  const handleDecreaseModel = () => {
    setInitialfields((prevFields = { NModelExam: 0 }) => ({
      ...prevFields,
      NModelExam: prevFields.NModelExam > 0 ? prevFields.NModelExam - 1 : 1,
    }));
    setExamDetails((prevState) => {
      const newExamDetails = Array.from(
        {
          length:
            initialfields.NModelExam > 0 ? initialfields.NModelExam - 1 : 1,
        },
        (_, index) => {
          const existingExamDetail = prevState[index];
          if (existingExamDetail) {
            return existingExamDetail;
          } else {
            return {
              id: index + 1,
              title: "",
              exam_title_id: "",
              pk: "",
              date: "",
              duration: "",
              startTime: "",
              endTime: "",
              syllabus: null,
              original_syllabus_url: null,
              LastDateApply: "",
              NPapers: "",
              PaperDetailsSection: [],
              fees: "",
              error_1: "",
              error_2: "",
              error_3: "",
              error_4: "",
              error_5: "",
              error_6: "",
              error_7: "",
              error_8: "",
              error_9: "",
            };
          }
        }
      );

      return newExamDetails;
    });
    setError({
      ...error,
      ["NModelExam"]: "",
    });
  };

  const handleIncreasePaperModel = (e, examDetailId) => {
    setExamDetails((prevState) => {
      return prevState.map((q) => {
        if (q.id === examDetailId) {
          // Update NPapers count and clear error
          const updatedNPapers = q.NPapers > 0 ? q.NPapers + 1 : 1;
          const updatedObject = {
            ...q,
            NPapers: updatedNPapers,
            error_9: "",
          };

          // Add one new object to PaperDetailsSection
          const newPaperDetails = {
            id: q.PaperDetailsSection.length + 1,
            title: "",
            date: "",
            duration: "",
            startTime: "",
            endTime: "",
            syllabus: null,
            original_syllabus_url: null,
            error_1: "",
            error_2: "",
            error_3: "",
            error_4: "",
            error_5: "",
            error_6: "",
            error_7: "",
            // txtBatchPEdate : txtBatchPEdate,
          };
          const updatedPaperDetailsSection = [
            ...q.PaperDetailsSection,
            newPaperDetails,
          ];
          // Update the object with the new PaperDetailsSection
          return {
            ...updatedObject,
            PaperDetailsSection: updatedPaperDetailsSection,
          };
        }
        return q;
      });
    });
  };

  const handleDecreasePaperModel = (e, examDetailId) => {
    setExamDetails((prevState) => {
      return prevState.map((q) => {
        if (q.id === examDetailId) {
          // Update NPapers count and clear error
          const updatedNPapers = q.NPapers > 0 ? q.NPapers - 1 : 1;
          const updatedObject = {
            ...q,
            NPapers: updatedNPapers,
            error_9: "",
          };

          // Remove the last object from PaperDetailsSection
          const updatedPaperDetailsSection = q.PaperDetailsSection.slice(0, -1);

          // Update the object with the updated PaperDetailsSection
          return {
            ...updatedObject,
            PaperDetailsSection: updatedPaperDetailsSection,
          };
        }
        return q;
      });
    });
  };

  const handleChangeCuDate = (dates, name) => {
    const dateSam = moment(dates).format("YYYY-MM-DD");

    if (name === "perTwoDate") {
      setInitialfields((prevState) => ({
        ...prevState,
        [name]: dateSam,
      }));
      setError({
        ...error,
        [name]: "",
      });
    } else if (name === "perThreeDate") {
      setInitialfields((prevState) => ({
        ...prevState,
        [name]: dateSam,
      }));
      setError({
        ...error,
        [name]: "",
      });
    } else {
      setInitialfields((prevState) => ({
        ...prevState,
        [name]: dateSam,
      }));
      setError({
        ...error,
        [name]: "",
      });
    }
  };

  /**
   * Handles submission of the form when form submits.
   * Saves the updated form values to local storage.
   * @param {Object} e - The form submission event.
   */

  const handleSubmit = (e) => {
    const errors = validate(
      initialfields,
      selectedOptions,
      selectedMem,
      selectedStaffMem,
      selectedDistrict,
      examDetail,
      mockPanel,
      subMulti,
      mExamSF,
      districtWise
    );

    const isErrorsOldEmpty =
      oldBatch.length > 0 &&
      oldBatch.every(
        (detail) =>
          (detail.OldDPercentage !== "" &&
            detail.BatchRFrom !== "" &&
            detail.BatchRTo !== "" &&
            detail.selectedOptions.length !== 0) ||
          detail.OldDPercentage === "0" ||
          detail.OldDPercentage === 0
      );

    // console.log(isErrorsOldEmpty, "isErrorsOldEmpty", oldBatch);
    // || detail.NoStudentBatch === "1" && detail.SOfferDis !==""

    // const isErrorsSME =
    //   mExamSF.length > 0 &&
    //   mExamSF.every((detail) => (detail.NoStudentBatch !== "" && detail.NoStudentBatch !== "3" && detail.NoStudentBatch !== "1" && detail.NoStudentBatch !== "2") || detail.NoStudentBatch === "3" && detail.StudentBatchCount !=="" || detail.NoStudentBatch === "1" && detail.SOfferDis !== "" || detail.NoStudentBatch === "2" && detail.selectedOptions.length !== 0 );

    const isErrorsSME =
      mExamSF.length > 0 &&
      mExamSF.every(
        (detail) =>
          detail.NoStudentBatch !== "" &&
          ((detail.NoStudentBatch === "3" &&
            detail.StudentBatchCount !== "" &&
            detail.SOfferDis !== "") ||
            (detail.NoStudentBatch === "1" && detail.SOfferDis !== "") ||
            (detail.NoStudentBatch === "2" &&
              detail.SOfferDis !== "" &&
              detail.selectedOptions.length !== 0) ||
            detail.NoStudentBatch === "4")
      );

    const isErrorsDW =
      districtWise.length > 0 &&
      districtWise.every(
        (detail) =>
          detail.error_1 === "" &&
          detail.error_2 === "" &&
          detail.districtCode !== ""
      );

    const isErrorsDWEdit =
      districtWise.length > 0 &&
      id &&
      districtWise.every((detail) =>
        detail.examCenter.every(
          (center) =>
            center.districtCode !== "" &&
            center.error_3 === "" &&
            center.error_4 === "" &&
            center.error_5 === ""
        )
      );

    const isErrorsEmpty =
      examDetail.length > 0 &&
      initialfields.Papers === "0" &&
      examDetail.every(
        (detail) =>
          detail.title !== "" &&
          detail.date !== "" &&
          detail.duration !== "" &&
          detail.startTime !== "" &&
          detail.endTime !== "" &&
          detail.syllabus !== null &&
          detail.fees !== "" &&
          detail.error_8 === "" &&
          detail.LastDateApply !== ""
      );

    const isErrorsEmpty_cP =
      examDetail.length > 0 &&
      initialfields.Papers === "1" &&
      examDetail.every(
        (detail) =>
          detail.title !== "" &&
          detail.NPapers !== "" &&
          detail.error_8 === "" &&
          detail.LastDateApply !== ""
      );

    const isPaperDetailsValid =
      examDetail.length > 0 &&
      initialfields.Papers === "1" &&
      examDetail.every(
        (detail) =>
          Array.isArray(detail.PaperDetailsSection) &&
          detail.PaperDetailsSection.length > 0 &&
          detail.PaperDetailsSection.every(
            (paper) =>
              paper.title !== "" &&
              paper.date !== "" &&
              paper.duration !== "" &&
              paper.startTime !== "" &&
              paper.endTime !== "" &&
              paper.syllabus !== null &&
              paper.original_syllabus_url !== null
          )
      );

    const isErrorsEmpty_Mockpanel =
      mockPanel.length > 0 &&
      mockPanel.every(
        (detail) =>
          detail.panel_name !== "" &&
          detail.available_dates.length !== 0 &&
          detail.panelMem.length !== 0 &&
          detail.staffMem.length !== 0 &&
          detail.fees !== ""
      );
    // console.log(errors, isErrorsEmpty_Mockpanel);
    const isErrorsEmpty_Counselling =
      cPanel.length > 0 &&
      cPanel.every(
        (detail) =>
          detail.panel_name !== "" &&
          detail.date !== "" &&
          detail.StartTime !== "" &&
          detail.EndTime !== "" &&
          detail.department.length !== 0 &&
          detail.CPaneMem.length !== 0 &&
          detail.CStaffM.length !== 0 &&
          detail.fees !== ""
      );

    if (
      Object.keys(errors).length === 0 &&
      isErrorsOldEmpty &&
      (Type === "0" ||
        Type === "1" ||
        Type === "3" ||
        (Type === "5" && isErrorsEmpty_Mockpanel) ||
        (Type === "2" && isErrorsEmpty_Counselling) ||
        (Type === "4" &&
          (isErrorsEmpty || (isErrorsEmpty_cP && isPaperDetailsValid)) &&
          isErrorsSME &&
          (isErrorsDW || isErrorsDWEdit)))
    ) {
      try {
        if (id) {
          let updateBatch = {};

          var oldStudentBatchArrays = oldBatch.map((item) => ({
            old_stud_disc:
              item.OldDPercentage !== "" ? parseInt(item.OldDPercentage) : 0,
            applicable_batches: item.selectedOptions.map(
              (option) => option.cateOp
            ),
            applicable_bid: item.selectedOptions.map((option) => option.id),
            batch_start: item.BatchRFrom,
            batch_end: item.BatchRTo,
          }));
          // If an id is provided, update the data
          const TD = {
            id: parseInt(id),
            type: parseInt(Type),
            name: initialfields.name,

            subcategory: initialfields.courseSubCategory,
            category: initialfields.courseCategory,
            category_class: initialfields.class_name,
            category_course_id: initialfields.course_id,
            img_url: initialfields.batchImg,
            original_img_url: initialfields.original_img_url,
            discounted_fees: initialfields.fees - initialfields.offerAmount,
            old_stud: oldBatch.every(
              (e) =>
                e.OldDPercentage === "" &&
                e.BatchRFrom === "" &&
                e.BatchRTo === ""
            )
              ? []
              : oldStudentBatchArrays,
            message: initialfields.message,
            team_id: initialfields.team_id,
            updated_by: userid,
          };
          if (Type !== "4") {
            TD.section = initialfields.section;
            TD.partial_payment = initialfields.pPayment === "Yes" ? 1 : 0;
            TD.location = parseInt(initialfields.location);
            TD.pay_1_perc =
              initialfields.pPayment === "Yes"
                ? parseInt(initialfields.perOne)
                : null;
            TD.pay_2_perc =
              initialfields.pPayment === "Yes"
                ? parseInt(initialfields.perTwo)
                : null;
            TD.pay_3_perc =
              initialfields.pPayment === "Yes"
                ? parseInt(initialfields.perThree)
                : null;
            TD.pay_2_duedate =
              initialfields.pPayment === "Yes"
                ? initialfields.perTwoDate
                : null;
            TD.pay_3_duedate =
              initialfields.pPayment === "Yes"
                ? initialfields.perThreeDate
                : null;
          }
          if (Type !== "4" && Type !== "5" && Type !== "2") {
            TD.no_of_students = initialfields.NoStudents;
            TD.fees = initialfields.fees;
            TD.discount =
              initialfields.offerAmount !== "" ? initialfields.offerAmount : 0;
          }

          if (Type === "0") {
            TD.subject =
              subMulti.length > 0 ? subMulti.map((el) => el.value) : [];
            TD.subject_pk =
              subMulti.length > 0 ? subMulti.map((el) => el.pk) : [];
          }

          if (Type === "1") {
            TD.no_of_test = initialfields.NoTest;
            TD.subject =
              subMulti.length > 0 ? subMulti.map((el) => el.value) : [];
            TD.subject_pk =
              subMulti.length > 0 ? subMulti.map((el) => el.pk) : [];
            TD.test_img_url = initialfields.testDet;
            TD.original_test_img_url = initialfields.original_test_img_url;
          }
          if (Type === "3") {
            TD.subject =
              subMulti.length > 0 ? subMulti.map((el) => el.value) : [];
            TD.subject_pk =
              subMulti.length > 0 ? subMulti.map((el) => el.pk) : [];
            TD.subject_file_path = initialfields.special_syll;
            TD.original_subject_file_path =
              initialfields.original_subject_file_path;
          }
          if (Type === "4" && initialfields.Papers === "0") {
            var examCenterArray = districtWise.flatMap((item) => {
              // let Kan = null;
              // if (item.district.label === "Kancheepuram") {
              //   Kan =
              //     "Sri Sankara Arts & Science College, Enathur, Kanchipuram, 631561 Contact Number : 7200407013";
              // } else if (item.district.label === "Vellore") {
              //   Kan =
              //     "TTD Sri Venkateswara Higher Secondary School, Infantry Road, Opposite Central Library, Vellore-632001 Contact Number : 7845952126";
              // } else if (item.district.label === "Chennai") {
              //   Kan =
              //     "Valluvar Gurukulam Higher Secondary School, 220, Grand Southern Trunk Road, Tambaram, Chennai, Tamil Nadu 600045. Contact Number : 7200623306";
              // }
              return item.examCenter.map((center) => ({
                exam_center_district: item.district.label,
                district_id: item.district.district_id,
                max_count: center.MaxCount,
                exam_district_code: item.districtCode,
                location_url: center.locationUrl,
                id: item.pk ? item.pk : 0,
                exam_center_id: item.id ? item.id : null,
                // exam_center_name:
                //   Kan !== null
                //     ? Kan
                //     : center.exam_center
                //     ? center.exam_center
                //     : "",
                exam_center_name:
                  center.exam_center !== null ? center.exam_center : "",
              }));
            });
            const examDetailArray = examDetail.map(function (item, index) {
              const laksh = guessDateFormat(item.LastDateApply);
              console.log("lalitha", laksh);
              return {
                exam_title_id: item.id,
                exam_title: item.title,
                started_dt: item.date ? item.date : null,
                // start_time:
                //   item.startTime !== null && item.startTime !== ""
                //     ? moment(item.startTime, "hh:mm:ss a").format("HH:mm:ss")
                //     : null,
                // end_time:
                //   item.endTime !== null && item.endTime !== ""
                //     ? moment(item.endTime, "HH:mm:ss a").format("HH:mm:ss")
                //     : null,
                start_time: moment(item.startTime).format("HH:mm:ss"),
                end_time: moment(item.endTime).format("HH:mm:ss"),
                duration: parseInt(item.duration),
                syllabus_url: item.syllabus,
                original_syllabus_url: item.original_syllabus_url,
                last_dt_apply: item.LastDateApply
                  ? moment(laksh).format("YYYY-MM-DD")
                  : "",
                // last_dt_apply: item.LastDateApply
                //   ? moment(item.LastDateApply).format("YYYY-MM-DD")
                //   : "",
                // last_dt_apply: item.LastDateApply
                // ? moment("2024-04-23").format("YYYY-MM-DD")
                // : "",
                exam_count: index + 1,
                no_of_paper: item.NPapers ? parseInt(item.NPapers) : 0,
              };
            });
            const examFeesArray = examDetail.map(function (item, index) {
              return {
                fees_count: index + 1,
                fees: Number(item.fees),
              };
            });
            const spc_offerArray = mExamSF.map(function (item, index) {
              let NsT = "";
              if (item.NoStudentBatch === "1") {
                NsT = 0;
              } else if (item.NoStudentBatch === "2") {
                NsT = 1;
              } else if (item.NoStudentBatch === "3") {
                NsT = 2;
              } else if (item.NoStudentBatch === "4") {
                NsT = 3;
              }
              return {
                discount_type: NsT,
                offer_perc: item.SOfferDis ? item.SOfferDis : 0,
                applicable_batch_pk:
                  item.selectedOptions.length > 0
                    ? item.selectedOptions.map((el) => el.id)
                    : [],
                stud_count: item.StudentBatchCount ? item.StudentBatchCount : 0,
              };
            });
            const EventDataArray = examDetail.map(function (item, index) {
              return {
                start_date: item.date,
                start_time:
                  item.startTime !== null
                    ? moment(item.startTime, "hh:mm:ss a").format("HH:mm:ss")
                    : "",
                end_time:
                  item.endTime !== null
                    ? moment(item.endTime, "HH:mm:ss a").format("HH:mm:ss")
                    : "",
                method_of_test: 0, // default as static
                teams_url:
                  "https://teams.microsoft.com/l/meetup-join/19%3ameeting_NTk4YmM4NWItMjY5NC00MmI0LWFmNjMtNjJhMzI0YWE4ODMx%40thread.v2/0?context=%7b%22Tid%22%3a%220169b6fb-efb9-4926-be5d-62d4f1d97902%22%2c%22Oid%22%3a%22070eace1-d02e-4276-a3bf-ca6d1a204784%22%7d", // static dummy url
              };
            });
            TD.exam_fees = examFeesArray;
            TD.exam_center = examCenterArray;
            TD.exam_details = examDetailArray;
            TD.spc_offer = spc_offerArray;
            TD.event_type = 1; // static for model exam
            TD.event_data = EventDataArray;
            TD.no_of_modelexam = initialfields.NModelExam
              ? parseInt(initialfields.NModelExam)
              : "";
            TD.paper_type = initialfields.Papers === "1" ? 1 : 0;
            TD.location = 2;
          }

          if (Type === "4" && initialfields.Papers === "1") {
            var examCenterArray = districtWise.flatMap((item) => {
              // let Kan = null;
              // if (item.district.label === "Kancheepuram") {
              //   Kan =
              //     "Sri Sankara Arts & Science College, Enathur, Kanchipuram, 631561 Contact Number : 7200407013";
              // } else if (item.district.label === "Vellore") {
              //   Kan =
              //     "TTD Sri Venkateswara Higher Secondary School, Infantry Road, Opposite Central Library, Vellore-632001 Contact Number : 7845952126";
              // } else if (item.district.label === "Chennai") {
              //   Kan =
              //     "Valluvar Gurukulam Higher Secondary School, 220, Grand Southern Trunk Road, Tambaram, Chennai, Tamil Nadu 600045. Contact Number : 7200623306";
              // }
              return item.examCenter.map((center) => ({
                exam_center_district: item.district.label,
                district_id: item.district.district_id,
                max_count: center.MaxCount,
                exam_district_code: item.districtCode,
                location_url: center.locationUrl,
                id: item.pk ? item.pk : 0,
                exam_center_id: item.id ? item.id : null,
                exam_center_name:
                  center.exam_center !== null ? center.exam_center : "",
              }));
            });
            const examDetailArray = examDetail.map(function (item, index) {
              const lakshs = guessDateFormat(item.LastDateApply);
              console.log("lalitha", lakshs);
              return {
                exam_title_id: item.id,
                exam_title: item.title,
                started_dt: item.date ? item.date : null,

                start_time:
                  item.startTime !== null && item.startTime !== ""
                    ? moment(item.startTime, "hh:mm:ss a").format("HH:mm:ss")
                    : null,
                end_time:
                  item.endTime !== null && item.endTime !== ""
                    ? moment(item.endTime, "HH:mm:ss a").format("HH:mm:ss")
                    : null,

                // duration: parseInt(item.duration),
                duration: item.duration ? parseInt(item.duration) : null,
                syllabus_url: item.syllabus,
                original_syllabus_url: item.original_syllabus_url,
                // last_dt_apply: item.LastDateApply
                // ? moment(item.LastDateApply).format("YYYY-MM-DD")
                // : "",
                last_dt_apply: item.LastDateApply
                  ? moment(lakshs).format("YYYY-MM-DD")
                  : "",
                exam_count: index + 1,
                no_of_paper: item.NPapers ? item.NPapers : 0,
                pap_details: item.PaperDetailsSection.map((paper, index) => ({
                  paper_id: paper.id,
                  paper_title: paper.title,
                  started_dt: paper.date,
                  // start_time: moment(paper.startTime, "hh:mm:ss a").format(
                  //   "HH:mm:ss"
                  // ),
                  // end_time: moment(paper.endTime, "hh:mm:ss a").format(
                  //   "HH:mm:ss"
                  // ),
                  start_time: moment(paper.startTime).format("HH:mm:ss"),
                  end_time: moment(paper.endTime).format("HH:mm:ss"),
                  duration: parseInt(paper.duration),
                  syllabus_url: paper.syllabus,
                  original_syllabus_url: paper.original_syllabus_url,
                  paper_count: index + 1, // Assuming each paper has a count of 1
                })),
              };
            });

            const examFeesArray = examDetail.map(function (item, index) {
              return {
                fees_count: index + 1,
                fees: Number(item.fees),
              };
            });
            const spc_offerArray = mExamSF.map(function (item, index) {
              let NsT = "";
              if (item.NoStudentBatch === "1") {
                NsT = 0;
              } else if (item.NoStudentBatch === "2") {
                NsT = 1;
              } else if (item.NoStudentBatch === "3") {
                NsT = 2;
              } else if (item.NoStudentBatch === "4") {
                NsT = 3;
              }
              return {
                discount_type: NsT,
                offer_perc: item.SOfferDis ? item.SOfferDis : 0,
                applicable_batch_pk:
                  item.selectedOptions.length > 0
                    ? item.selectedOptions.map((el) => el.id)
                    : [],
                stud_count: item.StudentBatchCount ? item.StudentBatchCount : 0,
              };
            });
            const EventDataArray = examDetail.flatMap(function (item, index) {
              return item.PaperDetailsSection.map((paper) => ({
                start_date: paper.date,
                start_time: moment(paper.startTime, "hh:mm:ss a").format(
                  "HH:mm:ss"
                ),
                end_time: moment(paper.endTime, "hh:mm:ss a").format(
                  "HH:mm:ss"
                ),
                method_of_test: 0, // default as static
                teams_url:
                  "https://teams.microsoft.com/l/meetup-join/19%3ameeting_NTk4YmM4NWItMjY5NC00MmI0LWFmNjMtNjJhMzI0YWE4ODMx%40thread.v2/0?context=%7b%22Tid%22%3a%220169b6fb-efb9-4926-be5d-62d4f1d97902%22%2c%22Oid%22%3a%22070eace1-d02e-4276-a3bf-ca6d1a204784%22%7d", // static dummy url
              }));
            });
            TD.exam_fees = examFeesArray;
            TD.exam_center = examCenterArray;
            TD.exam_details = examDetailArray;
            TD.spc_offer = spc_offerArray;
            TD.event_data = EventDataArray;
            TD.event_type = 1; // static for model exam
            TD.no_of_modelexam = initialfields.NModelExam
              ? parseInt(initialfields.NModelExam)
              : "";
            TD.paper_type = initialfields.Papers === "1" ? 1 : 0;
            TD.location = 2;
          }

          if (Type === "5") {
            // console.log(mockPanel, "mockPanel.mockPanel");
            // Merging panelMem and staffMem arrays
            // Merging panelMem and staffMem arrays
            const mergedData = mockPanel.map((item) => ({
              ...item,
              panel_member: [...item.panelMem, ...item.staffMem],
            }));
            const eventDetails_mock = [];
            mergedData.forEach((item) => {
              item.available_dates.forEach((date) => {
                item.panel_member.map((el) => {
                  eventDetails_mock.push({
                    start_date: date,
                    start_time: "10:00:00",
                    end_time: "20:00:00",
                    staff_id: el.staff_id,
                    email: el.email,
                    name: el.name,
                  });
                });
              });
            });

            const panel_data = mergedData.map((panel) => {
              return {
                available_dates: panel.available_dates,
                panel_name: panel.panel_name,
                panel_staff: panel.panel_member,
              };
            });

            const examFeesOutput = mockPanel.map((el, index) => ({
              fees_count: index + 1,
              fees: parseInt(el.fees) || 0,
            }));
            TD.panel_data = panel_data;
            TD.event_data = eventDetails_mock;
            TD.exam_fees = examFeesOutput;
            TD.passed_exam_name = parseInt(initialfields.ExamName);
            TD.started_dt = initialfields.startDate;
            TD.end_dt = initialfields.mockInterview_endDate;
            TD.no_of_students = initialfields.NoStudents;
          }
          if (Type === "2") {
            const mergedData_cop = cPanel.map((item) => ({
              ...item,
              panel_member: [...item.CPaneMem, ...item.CStaffM],
            }));
            // Create an "eventDetails" object
            const eventDetails = [];
            mergedData_cop.forEach((item) => {
              item.panel_member.forEach((date) => {
                eventDetails.push({
                  start_date: item.date,
                  start_time: item.StartTime,
                  end_time: item.EndTime,
                  staff_id: date.staff_id,
                  email: date.email,
                  name: date.name,
                });
              });
            });

            const CexamFeesOutput = cPanel.map((el, index) => ({
              fees_count: index + 1,
              fees: parseInt(el.fees) || 0,
            }));
            const PanelCounData = mergedData_cop.map((item) => ({
              available_dates: item.date,
              start_time:
                item.StartTime !== null
                  ? moment(item.StartTime, "hh:mm:ss a").format("HH:mm:ss")
                  : "",
              end_time:
                item.EndTime !== null
                  ? moment(item.EndTime, "HH:mm:ss a").format("HH:mm:ss")
                  : "",
              panel_name: item.panel_name,
              panel_staff: item.panel_member,
            }));
            TD.panel_data = PanelCounData;
            TD.event_data = eventDetails;
            TD.exam_fees = CexamFeesOutput;
            TD.passed_exam_name = parseInt(initialfields.ExamName);
            TD.no_of_students = initialfields.NoStudents;
          }
          // if (Type !== "5" && Type !== "4" && Type !== "2") {
          //   TD.subject = subMulti.length > 0 ? subMulti.map((el) => el.value) : [];
          //   TD.subject_pk = subMulti.length > 0 ? subMulti.map((el) => el.pk) : [];
          // }
          if (Type === "2") {
            TD.event_type = 0;
          } else {
            TD.started_dt = initialfields.startDate;
          }
          if (Type === "5") {
            TD.event_type = 0;
          }
          // Type !== "2" &&
          if (Type !== "5" && Type !== "4") {
            TD.duration = initialfields.duration;
          }
          if (Type !== "2" && Type !== "5" && Type !== "4") {
            TD.class_mode = initialfields.classMode;
            TD.medium = initialfields.medium;
            TD.start_time =
              initialfields.startTime !== null
                ? moment(initialfields.startTime, "hh:mm:ss a").format(
                    "HH:mm:ss"
                  )
                : "";
            TD.end_time =
              initialfields.endTime !== null
                ? moment(initialfields.endTime, "HH:mm:ss a").format("HH:mm:ss")
                : "";
            TD.batch_type = initialfields.batch;
          }
          if (Type === "2" || Type === "5" || Type === "4") {
            TD.class_mode = initialfields.classMode;
            TD.medium = initialfields.medium;
            // TD.batch_type = "regular";
            TD.batch_type = null;
          }
          updateBatch = TD;

          // return  console.log(updateBatch, "updateBatch");

          AxiosInstance.post(`api/batch/edit`, updateBatch)
            .then((res) => {
              if (res.data.status === true) {
                Swal.fire({
                  title: "Success",
                  text: res.data.message,
                  icon: "success",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    if (result.isConfirmed) {
                      if (Type === "0") {
                        navigate(`/ViewCourseBatch/${1}`);
                      } else if (Type === "1") {
                        navigate(`/ViewCourseBatch/${2}`);
                      } else if (Type === "3") {
                        navigate(`/ViewCourseBatch/${3}`);
                      } else if (Type === "4") {
                        navigate(`/ViewCourseBatch/${5}`);
                      } else if (Type === "5") {
                        navigate(`/ViewCourseBatch/${6}`);
                      } else if (Type === "2") {
                        navigate(`/ViewCourseBatch/${4}`);
                      } else {
                        navigate(`/ViewCourseBatch`);
                      }
                    }
                  }
                });
              } else {
                Swal.fire({
                  title: "Warning",
                  text: res.data.message,
                  icon: "Warning",
                  confirmButtonText: "Ok",
                });
              }
            })
            .catch((error) => {
              return error;
            });
        } else {
          // console.log(oldBatch, "oldBatcholdBatch");
          let createBatch = {};
          var oldStudentBatchArray = oldBatch.map((item) => ({
            old_stud_disc:
              item.OldDPercentage !== "" ? parseInt(item.OldDPercentage) : 0,
            applicable_batches: item.selectedOptions.map(
              (option) => option.cateOp
            ),
            applicable_bid: item.selectedOptions.map((option) => option.id),
            batch_start: item.BatchRFrom,
            batch_end: item.BatchRTo,
          }));
          // console.log(oldStudentBatchArray);
          if (Type === "0") {
            const CD = {
              name: initialfields.name,
              type: 0,
              section: initialfields.section,
              subcategory: initialfields.courseSubCategory,
              category: initialfields.courseCategory,
              category_class: initialfields.class_name,
              category_course_id: initialfields.course_id,
              img_url: initialfields.batchImg,
              original_img_url: initialfields.original_img_url,
              class_mode: initialfields.classMode,
              partial_payment: initialfields.pPayment === "Yes" ? 1 : 0,
              pay_1_perc:
                initialfields.pPayment === "Yes"
                  ? parseInt(initialfields.perOne)
                  : null,
              pay_2_perc:
                initialfields.pPayment === "Yes"
                  ? parseInt(initialfields.perTwo)
                  : null,
              pay_3_perc:
                initialfields.pPayment === "Yes"
                  ? parseInt(initialfields.perThree)
                  : null,
              pay_2_duedate:
                initialfields.pPayment === "Yes"
                  ? initialfields.perTwoDate
                  : null,
              pay_3_duedate:
                initialfields.pPayment === "Yes"
                  ? initialfields.perThreeDate
                  : null,
              medium: initialfields.medium,
              fees: initialfields.fees,
              subject:
                subMulti.length > 0 ? subMulti.map((el) => el.value) : [],
              subject_pk:
                subMulti.length > 0 ? subMulti.map((el) => el.pk) : [],
              discount:
                initialfields.offerAmount !== ""
                  ? initialfields.offerAmount
                  : 0,
              discounted_fees: initialfields.fees - initialfields.offerAmount,
              duration: initialfields.duration,
              batch_type: initialfields.batch,
              started_dt: initialfields.startDate,

              start_time:
                initialfields.startTime !== null
                  ? moment(initialfields.startTime, "hh:mm:ss a").format(
                      "HH:mm:ss"
                    )
                  : "",
              end_time:
                initialfields.endTime !== null
                  ? moment(initialfields.endTime, "HH:mm:ss a").format(
                      "HH:mm:ss"
                    )
                  : "",
              no_of_students: initialfields.NoStudents,
              location: initialfields.location,

              old_stud: oldBatch.every(
                (e) =>
                  e.OldDPercentage === "" &&
                  e.BatchRFrom === "" &&
                  e.BatchRTo === ""
              )
                ? []
                : oldStudentBatchArray,
              message: initialfields.message,
              team_id: null,
              created_by: userid,
            };
            // console.log(CD, "createBatch coures ");
            createBatch = CD;
          } else {
            var oldStudentBatchArray = oldBatch.map((item) => ({
              old_stud_disc:
                item.OldDPercentage !== "" ? parseInt(item.OldDPercentage) : 0,
              applicable_batches: item.selectedOptions.map(
                (option) => option.cateOp
              ),
              applicable_bid: item.selectedOptions.map((option) => option.id),
              batch_start: item.BatchRFrom,
              batch_end: item.BatchRTo,
            }));

            const BD = {
              name: initialfields.name,
              type: parseInt(Type),
              event_type: 1, // static
              subcategory: initialfields.courseSubCategory,
              category: initialfields.courseCategory,
              category_class: initialfields.class_name,
              category_course_id: initialfields.course_id,
              img_url: initialfields.batchImg,
              original_img_url: initialfields.original_img_url,
              old_stud: oldBatch.every(
                (e) =>
                  e.OldDPercentage === "" &&
                  e.BatchRFrom === "" &&
                  e.BatchRTo === ""
              )
                ? []
                : oldStudentBatchArray,
              message: initialfields.message,
              team_id: null,
              created_by: userid,
            };

            if (Type !== "4") {
              BD.location = parseInt(initialfields.location);
              BD.section = initialfields.section ? initialfields.section : "";
              BD.partial_payment = initialfields.pPayment === "Yes" ? 1 : 0;
              BD.pay_1_perc =
                initialfields.pPayment === "Yes"
                  ? parseInt(initialfields.perOne)
                  : null;
              BD.pay_2_perc =
                initialfields.pPayment === "Yes"
                  ? parseInt(initialfields.perTwo)
                  : null;
              BD.pay_3_perc =
                initialfields.pPayment === "Yes"
                  ? parseInt(initialfields.perThree)
                  : null;
              BD.pay_2_duedate =
                initialfields.pPayment === "Yes"
                  ? initialfields.perTwoDate
                  : null;
              BD.pay_3_duedate =
                initialfields.pPayment === "Yes"
                  ? initialfields.perThreeDate
                  : null;
            }
            // Type !== "2" &&
            if (Type !== "5" && Type !== "2" && Type !== "4") {
              BD.duration = initialfields.duration;
            }
            if (Type !== "2" && Type !== "5" && Type !== "4") {
              BD.class_mode = initialfields.classMode;
              BD.medium = initialfields.medium;
              BD.start_time =
                initialfields.startTime !== null
                  ? moment(initialfields.startTime, "hh:mm:ss a").format(
                      "HH:mm:ss"
                    )
                  : "";
              BD.end_time =
                initialfields.endTime !== null
                  ? moment(initialfields.endTime, "HH:mm:ss a").format(
                      "HH:mm:ss"
                    )
                  : "";
              BD.batch_type = initialfields.batch;
            }
            if (Type === "2" || Type === "5" || Type === "4") {
              BD.class_mode = initialfields.classMode;
              BD.medium = initialfields.medium;
              // BD.batch_type = "regular";
              BD.batch_type = null;
            }
            if (Type !== "4" && Type !== "5" && Type !== "2") {
              BD.no_of_students = initialfields.NoStudents;
              BD.fees = initialfields.fees;
              BD.discounted_fees =
                initialfields.fees - initialfields.offerAmount;
              BD.discount =
                initialfields.offerAmount !== ""
                  ? initialfields.offerAmount
                  : 0;
            }
            if (Type === "0") {
              BD.subject =
                subMulti.length > 0 ? subMulti.map((el) => el.value) : [];
              BD.subject_pk =
                subMulti.length > 0 ? subMulti.map((el) => el.pk) : [];
            }
            if (Type === "3") {
              BD.subject =
                subMulti.length > 0 ? subMulti.map((el) => el.value) : [];
              BD.subject_pk =
                subMulti.length > 0 ? subMulti.map((el) => el.pk) : [];
              BD.subject_file_path = initialfields.special_syll;
              BD.original_subject_file_path =
                initialfields.original_subject_file_path;
            }
            if (Type === "2") {
              BD.event_type = 0;
            }
            if (Type !== "4") {
              BD.started_dt = initialfields.startDate;
              // BD.started_dt = initialfields.CDate;
            }
            if (Type === "5") {
              BD.event_type = 0;
            }
            if (Type === "1") {
              BD.no_of_test = initialfields.NoTest;
              BD.test_img_url = initialfields.testDet;
              BD.original_test_img_url = initialfields.original_test_img_url;
              BD.subject =
                subMulti.length > 0 ? subMulti.map((el) => el.value) : [];
              BD.subject_pk =
                subMulti.length > 0 ? subMulti.map((el) => el.pk) : [];
            }
            if (Type === "4" && initialfields.Papers === "0") {
              var examCenterArray = districtWise.flatMap((item) => {
                // let Kan = null;
                // if (item.district.label === "Kancheepuram") {
                //   Kan =
                //     "Sri Sankara Arts & Science College, Enathur, Kanchipuram, 631561 Contact Number : 7200407013";
                // } else if (item.district.label === "Vellore") {
                //   Kan =
                //     "TTD Sri Venkateswara Higher Secondary School, Infantry Road, Opposite Central Library, Vellore-632001 Contact Number : 7845952126";
                // } else if (item.district.label === "Chennai") {
                //   Kan =
                //     "Valluvar Gurukulam Higher Secondary School, 220, Grand Southern Trunk Road, Tambaram, Chennai, Tamil Nadu 600045. Contact Number : 7200623306";
                // }
                return item.examCenter.map((center) => ({
                  exam_center_district: item.district.label,
                  district_id: item.district.district_id,
                  max_count: center.MaxCount,
                  exam_district_code: item.districtCode,
                  location_url: center.locationUrl,
                  exam_center_id: item.id ? item.id : null,
                  exam_center_name:
                    center.exam_center !== null ? center.exam_center : "",
                }));
              });

              const examDetailArray = examDetail.map(function (item, index) {
                return {
                  exam_title_id: item.id,
                  exam_title: item.title,
                  started_dt: item.date,

                  start_time:
                    item.startTime !== null
                      ? moment(item.startTime, "hh:mm:ss a").format("HH:mm:ss")
                      : "",
                  end_time:
                    item.endTime !== null
                      ? moment(item.endTime, "HH:mm:ss a").format("HH:mm:ss")
                      : "",
                  duration: parseInt(item.duration),
                  syllabus_url: item.syllabus,
                  original_syllabus_url: item.original_syllabus_url,
                  last_dt_apply: item.LastDateApply
                    ? moment(item.LastDateApply).format("YYYY-MM-DD")
                    : "",
                  exam_count: index + 1,
                  no_of_paper: item.NPapers ? parseInt(item.NPapers) : 0,
                };
              });
              const examFeesArray = examDetail.map(function (item, index) {
                return {
                  fees_count: index + 1,
                  fees: Number(item.fees),
                };
              });
              const spc_offerArray = mExamSF.map(function (item, index) {
                let NsT = "";
                if (item.NoStudentBatch === "1") {
                  NsT = 0;
                } else if (item.NoStudentBatch === "2") {
                  NsT = 1;
                } else if (item.NoStudentBatch === "3") {
                  NsT = 2;
                } else if (item.NoStudentBatch === "4") {
                  NsT = 3;
                }
                return {
                  discount_type: NsT,
                  offer_perc: item.SOfferDis ? item.SOfferDis : 0,
                  applicable_batch_pk:
                    item.selectedOptions.length > 0
                      ? item.selectedOptions.map((el) => el.id)
                      : [],
                  stud_count: item.StudentBatchCount
                    ? item.StudentBatchCount
                    : 0,
                };
              });
              const EventDataArray = examDetail.map(function (item, index) {
                return {
                  start_date: item.date,
                  start_time:
                    item.startTime !== null
                      ? moment(item.startTime, "hh:mm:ss a").format("HH:mm:ss")
                      : "",
                  end_time:
                    item.endTime !== null
                      ? moment(item.endTime, "HH:mm:ss a").format("HH:mm:ss")
                      : "",
                  method_of_test: 0, // default as static
                  teams_url:
                    "https://teams.microsoft.com/l/meetup-join/19%3ameeting_NTk4YmM4NWItMjY5NC00MmI0LWFmNjMtNjJhMzI0YWE4ODMx%40thread.v2/0?context=%7b%22Tid%22%3a%220169b6fb-efb9-4926-be5d-62d4f1d97902%22%2c%22Oid%22%3a%22070eace1-d02e-4276-a3bf-ca6d1a204784%22%7d", // static dummy url
                };
              });
              BD.exam_fees = examFeesArray;
              BD.exam_center = examCenterArray;
              BD.exam_details = examDetailArray;
              BD.spc_offer = spc_offerArray;
              BD.event_data = EventDataArray;
              BD.no_of_modelexam = initialfields.NModelExam
                ? parseInt(initialfields.NModelExam)
                : "";
              BD.paper_type = initialfields.Papers === "1" ? 1 : 0;
              BD.location = 2;
            }

            if (Type === "4" && initialfields.Papers === "1") {
              var examCenterArray = districtWise.flatMap((item) => {
                // let Kan = null;
                // if (item.district.label === "Kancheepuram") {
                //   Kan =
                //     "Sri Sankara Arts & Science College, Enathur, Kanchipuram, 631561 Contact Number : 7200407013";
                // } else if (item.district.label === "Vellore") {
                //   Kan =
                //     "TTD Sri Venkateswara Higher Secondary School, Infantry Road, Opposite Central Library, Vellore-632001 Contact Number : 7845952126";
                // } else if (item.district.label === "Chennai") {
                //   Kan =
                //     "Valluvar Gurukulam Higher Secondary School, 220, Grand Southern Trunk Road, Tambaram, Chennai, Tamil Nadu 600045. Contact Number : 7200623306";
                // }
                return item.examCenter.map((center) => ({
                  exam_center_district: item.district.label,
                  district_id: item.district.district_id,
                  max_count: center.MaxCount,
                  exam_district_code: item.districtCode,
                  location_url: center.locationUrl,
                  exam_center_id: item.id ? item.id : null,
                  exam_center_name:
                    center.exam_center !== null ? center.exam_center : "",
                }));
              });
              const examDetailArray = examDetail.map(function (item, index) {
                return {
                  exam_title_id: item.id,
                  exam_title: item.title,
                  started_dt: item.date ? item.date : null,

                  // start_time:
                  //   item.startTime !== null && item.startTime !== ""
                  //     ? moment(item.startTime, "hh:mm:ss a").format("HH:mm:ss")
                  //     : null,
                  // end_time:
                  //   item.endTime !== null && item.endTime !== ""
                  //     ? moment(item.endTime, "HH:mm:ss a").format("HH:mm:ss")
                  //     : null,
                  // duration: parseInt(item.duration),
                  start_time:
                    item.endTime !== null && item.endTime !== ""
                      ? moment(item.startTime).format("HH:mm:ss")
                      : null,
                  end_time:
                    item.endTime !== null && item.endTime !== ""
                      ? moment(item.endTime).format("HH:mm:ss")
                      : null,
                  duration: item.duration ? parseInt(item.duration) : null,
                  syllabus_url: item.syllabus,
                  original_syllabus_url: item.original_syllabus_url,
                  last_dt_apply: item.LastDateApply
                    ? moment(item.LastDateApply).format("YYYY-MM-DD")
                    : "",
                  exam_count: index + 1,
                  no_of_paper: item.NPapers ? item.NPapers : 0,
                  pap_details: item.PaperDetailsSection.map((paper, index) => ({
                    paper_id: paper.id,
                    paper_title: paper.title,
                    started_dt: paper.date,
                    start_time: moment(paper.startTime, "hh:mm:ss a").format(
                      "HH:mm:ss"
                    ),
                    end_time: moment(paper.endTime, "hh:mm:ss a").format(
                      "HH:mm:ss"
                    ),
                    duration: parseInt(paper.duration),
                    syllabus_url: paper.syllabus,
                    original_syllabus_url: paper.original_syllabus_url,
                    paper_count: index + 1, // Assuming each paper has a count of 1
                  })),
                };
              });

              const examFeesArray = examDetail.map(function (item, index) {
                return {
                  fees_count: index + 1,
                  fees: Number(item.fees),
                };
              });
              const spc_offerArray = mExamSF.map(function (item, index) {
                let NsT = "";
                if (item.NoStudentBatch === "1") {
                  NsT = 0;
                } else if (item.NoStudentBatch === "2") {
                  NsT = 1;
                } else if (item.NoStudentBatch === "3") {
                  NsT = 2;
                } else if (item.NoStudentBatch === "4") {
                  NsT = 3;
                }
                return {
                  discount_type: NsT,
                  offer_perc: item.SOfferDis ? item.SOfferDis : 0,
                  applicable_batch_pk:
                    item.selectedOptions.length > 0
                      ? item.selectedOptions.map((el) => el.batch_id)
                      : [],
                  stud_count: item.StudentBatchCount
                    ? item.StudentBatchCount
                    : 0,
                };
              });
              const EventDataArray = examDetail.flatMap(function (item, index) {
                return item.PaperDetailsSection.map((paper) => ({
                  start_date: paper.date,
                  start_time: moment(paper.startTime, "hh:mm:ss a").format(
                    "HH:mm:ss"
                  ),
                  end_time: moment(paper.endTime, "hh:mm:ss a").format(
                    "HH:mm:ss"
                  ),
                  method_of_test: 0, // default as static
                  teams_url:
                    "https://teams.microsoft.com/l/meetup-join/19%3ameeting_NTk4YmM4NWItMjY5NC00MmI0LWFmNjMtNjJhMzI0YWE4ODMx%40thread.v2/0?context=%7b%22Tid%22%3a%220169b6fb-efb9-4926-be5d-62d4f1d97902%22%2c%22Oid%22%3a%22070eace1-d02e-4276-a3bf-ca6d1a204784%22%7d", // static dummy url
                }));
              });
              BD.exam_fees = examFeesArray;
              BD.exam_center = examCenterArray;
              BD.exam_details = examDetailArray;
              BD.spc_offer = spc_offerArray;
              BD.event_data = EventDataArray;
              BD.no_of_modelexam = initialfields.NModelExam
                ? parseInt(initialfields.NModelExam)
                : "";
              BD.paper_type = initialfields.Papers === "1" ? 1 : 0;
              BD.location = 2;
            }

            if (Type === "5") {
              // console.log(mockPanel, "mockPanel.mockPanel");
              // Merging panelMem and staffMem arrays
              // Merging panelMem and staffMem arrays
              const mergedData = mockPanel.map((item) => ({
                ...item,
                panel_member: [...item.panelMem, ...item.staffMem],
              }));

              // console.log(mergedData, "mergedData");

              const eventDetails_mock = [];
              mergedData.forEach((item) => {
                item.available_dates.forEach((date) => {
                  item.panel_member.map((el) => {
                    eventDetails_mock.push({
                      start_date: date,
                      start_time: "10:00:00",
                      end_time: "20:00:00",
                      staff_id: el.staff_id,
                      email: el.email,
                      name: el.name,
                    });
                  });
                });
              });

              const panel_data = mergedData.map((panel) => {
                return {
                  available_dates: panel.available_dates,
                  panel_name: panel.panel_name,
                  panel_staff: panel.panel_member,
                };
              });

              const examFeesOutput = mockPanel.map((el, index) => ({
                fees_count: index + 1,
                fees: parseInt(el.fees) || 0,
              }));
              BD.panel_data = panel_data;
              BD.event_data = eventDetails_mock;
              BD.exam_fees = examFeesOutput;
              BD.passed_exam_name = parseInt(initialfields.ExamName);
              BD.started_dt = initialfields.startDate;
              BD.end_dt = initialfields.mockInterview_endDate;
              BD.no_of_students = initialfields.NoStudents;
            }
            if (Type === "2") {
              const mergedData_cop = cPanel.map((item) => ({
                ...item,
                panel_member: [...item.CPaneMem, ...item.CStaffM],
              }));
              // Create an "eventDetails" object
              const eventDetails = [];
              mergedData_cop.forEach((item) => {
                item.panel_member.forEach((date) => {
                  eventDetails.push({
                    start_date: item.date,
                    start_time: item.StartTime,
                    end_time: item.EndTime,
                    staff_id: date.staff_id,
                    email: date.email,
                    name: date.name,
                  });
                });
              });
              // const PanelCounData = cPanel.map((item) => ({
              //   department: item.department.map((el) => el.value),
              //   available_dates: item.date,
              //   panel_name: item.panel_name,
              //   panel_member: [...item.CPaneMem, ...item.CStaffM],
              // }));
              const CexamFeesOutput = cPanel.map((el, index) => ({
                fees_count: index + 1,
                fees: parseInt(el.fees) || 0,
              }));
              // const panel_datasss = PanelCounData.map((panel) => {
              //   return {
              //     available_dates: panel.available_dates,
              //     panel_name: panel.panel_name,
              //     panel_staff: [
              //       {
              //         department: panel.department,
              //         staff_members: panel.panel_member,
              //       },
              //     ],
              //   };
              // });
              const PanelCounData = mergedData_cop.map((item) => ({
                available_dates: item.date,
                start_time:
                  item.StartTime !== null
                    ? moment(item.StartTime, "hh:mm:ss a").format("HH:mm:ss")
                    : "",
                end_time:
                  item.EndTime !== null
                    ? moment(item.EndTime, "HH:mm:ss a").format("HH:mm:ss")
                    : "",
                panel_name: item.panel_name,
                panel_staff: item.panel_member,
              }));
              BD.panel_data = PanelCounData;
              BD.event_data = eventDetails;
              BD.exam_fees = CexamFeesOutput;
              BD.passed_exam_name = parseInt(initialfields.ExamName);
              BD.no_of_students = initialfields.NoStudents;
            }
            createBatch = BD;
          }
          // console.log(createBatch, "jiio");
          // return console.log(createBatch, "jiio");
          AxiosInstance.post(`api/batch/add`, createBatch)
            .then((res) => {
              // console.log(res);
              if (res.data.status === true) {
                Swal.fire({
                  title: "Success",
                  text: res.data.message,
                  icon: "success",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    if (Type === "0") {
                      navigate(`/ViewCourseBatch/${1}`);
                    } else if (Type === "1") {
                      navigate(`/ViewCourseBatch/${2}`);
                    } else if (Type === "3") {
                      navigate(`/ViewCourseBatch/${3}`);
                    } else if (Type === "4") {
                      navigate(`/ViewCourseBatch/${5}`);
                    } else if (Type === "5") {
                      navigate(`/ViewCourseBatch/${6}`);
                    } else if (Type === "2") {
                      navigate(`/ViewCourseBatch/${4}`);
                    } else {
                      navigate(`/ViewCourseBatch`);
                    }
                  }
                });
              } else {
                Swal.fire({
                  title: "Success",
                  text: res.data.message,
                  icon: "success",
                  confirmButtonText: "Ok",
                });
              }
            })
            .catch((error) => {
              return error;
            });
        }
      } catch (error) {
        // console.log("catchh");
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  const handleCloseModels_Succ = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleCloseModel = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    if (Type === "0") {
      navigate(`/ViewCourseBatch/${1}`);
    } else if (Type === "1") {
      navigate(`/ViewCourseBatch/${2}`);
    } else if (Type === "3") {
      navigate(`/ViewCourseBatch/${3}`);
    } else if (Type === "4") {
      navigate(`/ViewCourseBatch/${5}`);
    } else if (Type === "5") {
      navigate(`/ViewCourseBatch/${6}`);
    } else if (Type === "2") {
      navigate(`/ViewCourseBatch/${4}`);
    } else {
      navigate(`/ViewCourseBatch`);
    }

    // navigateToPreviousScreen();
  };

  return (
    <div className="BathDet">
      <div className="row">
        <div className="col-md-12">
          {swalMsg.show === true && (
            <ModelComForP
              show={swalMsg.show}
              message={swalMsg.message}
              status={swalMsg.status}
              title={swalMsg.title}
              BottomOkBtn={swalMsg.BottomOkBtn}
              BottomCancelBtn={swalMsg.BottomCancelBtn}
              handleCloseModels={handleCloseModel}
              // handleClose_yes={handleSPayfee}
              BottomOkClose={swalMsg.BottomOkClose}
              handleCloseModels_Succ={handleCloseModels_Succ}
            />
          )}
          <div className="heading  d-flex justify-content-between flex-wrap flex-sm-nowrap">
            {id ? (
              <h4 className={Type === "2" ? "Titless" : "Titles"}>
                Edit {Type === "0" && "Course"} {Type === "1" && "Test"}{" "}
                {Type === "2" && "Counselling Awareness Program"}{" "}
                {Type === "3" && "Special Class"} {Type === "4" && "Model Exam"}{" "}
                {Type === "5" && "mock interview"} Batch
              </h4>
            ) : (
              <h4 className={Type === "2" ? "Titless" : "Titles"}>
                Create New {Type === "0" && "Course"} {Type === "1" && "Test"}{" "}
                {Type === "2" && "Counselling Awareness Program"}{" "}
                {Type === "3" && "Special Class"} {Type === "4" && "Model Exam"}{" "}
                {Type === "5" && "mock interview"} Batch
              </h4>
            )}
            {Type === "0" && (
              <Link to={`/ViewCourseBatch/${1}`} className="CancelButton">
                Back to Batches
              </Link>
            )}

            {Type === "1" && (
              <Link to={`/ViewCourseBatch/${2}`} className="CancelButton">
                Back to Batches
              </Link>
            )}

            {Type === "2" && (
              <Link to={`/ViewCourseBatch/${4}`} className="CancelButton">
                Back to Batches
              </Link>
            )}

            {Type === "3" && (
              <Link to={`/ViewCourseBatch/${3}`} className="CancelButton">
                Back to Batches
              </Link>
            )}

            {Type === "4" && (
              <Link to={`/ViewCourseBatch/${5}`} className="CancelButton">
                Back to Batches
              </Link>
            )}

            {Type === "5" && (
              <Link to={`/ViewCourseBatch/${6}`} className="CancelButton">
                Back to Batches
              </Link>
            )}

            <button
              type="button"
              className={Type === "2" ? "SaveButtons" : "SaveButton"}
              onClick={handleSubmit}
            >
              Save {Type === "0" && "Course"} {Type === "1" && "Test"}{" "}
              {Type === "2" && "Counselling Awareness Program"}{" "}
              {Type === "3" && "Special Class"} {Type === "4" && "Model Exam"}{" "}
              {Type === "5" && "mock interview"} Batch
            </button>
          </div>
          <section className="StepOne ">
            <div className="row box  ">
              <div className="col-md-12">
                <p className="StepTitle">Batch Info</p>
                <div className="row mb-4" style={{ paddingRight: "20px" }}>
                  <div className="col-lg-6 col-md-12">
                    <div className="forms ">
                      <label for="courseCategory">Category:</label>
                      <div
                        className="d-flex flex-lg-row flex-md-row flex-column"
                        id="radiobuttons"
                      >
                        <div className="form-check form-check-inline">
                          <input
                            ref={txtBatchCategory}
                            className="checkbox_radio"
                            type="radio"
                            name="courseCategory"
                            id="UPSC"
                            value="UPSC"
                            onChange={handleChange}
                            checked={
                              initialfields.courseCategory === "UPSC" ||
                              initialfields.courseCategory === "UPSC"
                            }
                          />
                          <label className="checkbox-label" htmlFor="UPSC">
                            UPSC
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="courseCategory"
                            id="TNPSC"
                            value="TNPSC"
                            checked={
                              initialfields.courseCategory === "TNPSC" ||
                              initialfields.courseCategory === "TNPSC"
                            }
                            onChange={handleChange}
                          />
                          <label className="checkbox-label" htmlFor="TNPSC">
                            TNPSC
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="courseCategory"
                            id="TRB"
                            value="TRB"
                            checked={
                              initialfields.courseCategory === "TRB" ||
                              initialfields.courseCategory === "TRB"
                            }
                            onChange={handleChange}
                          />
                          <label className="checkbox-label" htmlFor="TRB">
                            TRB
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="courseCategory"
                            id="TET"
                            value="TET"
                            checked={
                              initialfields.courseCategory === "TET" ||
                              initialfields.courseCategory === "TET"
                            }
                            onChange={handleChange}
                          />
                          <label className="checkbox-label" htmlFor="TET">
                            TET
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="courseCategory"
                            id="TNUSRB"
                            value="TNUSRB"
                            checked={
                              initialfields.courseCategory === "TNUSRB" ||
                              initialfields.courseCategory === "TNUSRB"
                            }
                            onChange={handleChange}
                          />
                          <label className="checkbox-label" htmlFor="TNUSRB">
                            TNUSRB
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="courseCategory"
                            id="RRB"
                            value="RRB"
                            checked={
                              initialfields.courseCategory === "RRB" ||
                              initialfields.courseCategory === "RRB"
                            }
                            onChange={handleChange}
                          />
                          <label className="checkbox-label" htmlFor="RRB">
                            RRB
                          </label>
                        </div>
                      </div>
                      <div
                        className="d-flex flex-lg-row flex-md-row flex-column"
                        id="radiobuttons"
                      >
                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="courseCategory"
                            id="SSC"
                            value="SSC"
                            checked={
                              initialfields.courseCategory === "SSC" ||
                              initialfields.courseCategory === "SSC"
                            }
                            onChange={handleChange}
                          />
                          <label className="checkbox-label" htmlFor="SSC">
                            SSC
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="courseCategory"
                            id="Banking"
                            value="Banking"
                            checked={
                              initialfields.courseCategory === "Banking" ||
                              initialfields.courseCategory === "Banking"
                            }
                            onChange={handleChange}
                          />
                          <label className="checkbox-label" htmlFor="Banking">
                            Banking
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="courseCategory"
                            id="Others"
                            value="Others"
                            checked={
                              initialfields.courseCategory === "Others" ||
                              initialfields.courseCategory === "Others"
                            }
                            onChange={handleChange}
                          />
                          <label className="checkbox-label" htmlFor="Others">
                            Others
                          </label>
                        </div>
                      </div>
                      {error.courseCategory && (
                        <small className="error">{error.courseCategory}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="class_name">Course:</label>

                      {Type === "5" || Type === "2" ? (
                        <Select
                          ref={txtBatchCourse}
                          id="class_name"
                          name="class_name"
                          value={
                            initialfields.class_name !== null &&
                            initialfields.class_name !== ""
                              ? {
                                  value: initialfields.class_name,
                                  label: initialfields.class_name,
                                }
                              : null
                          }
                          onChange={(option) =>
                            handleSelectCH(option, "class_name")
                          }
                          options={
                            subcategory_m
                              .filter((item) => item && item.label) // Filter out null and undefined elements
                              .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                          }
                          isSearchable={true} // Enables search functionality
                          placeholder="Select an option"
                          maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                          filterOption={(option, searchText) =>
                            option.label
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          } // Filter options based on the search text
                          styles={customStyles} // Apply custom styles her
                          // components={{ Option: OptionBB }}
                        />
                      ) : (
                        // <SelectDropdown

                        //   txtAdCourse={txtBatchCourse}
                        //   id="class_name"
                        //   name="class_name"
                        //   items={initialfields.class_name}
                        //   record={subjects}
                        //   handleChangeCheckbox={handleChangeCheckbox}
                        // />

                        <DropdownHeader
                          txtAdCourse={txtBatchCourse}
                          id="class_name"
                          name="class_name"
                          items={initialfields.class_name}
                          record={subjects}
                          handleChangeCheckbox={handleChangeCheckbox}
                        />
                      )}

                      {/* <Select
                        ref={txtBatchCourse}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ OptionBatch }}
                        allowSelectAll={true}
                        id="class_name"
                        name="class_name"
                        value={(initialfields.class_name !== null && initialfields.class_name !== "") ? { value: initialfields.class_name, label: initialfields.class_name } : null}
                        onChange={option => handleSelectCH(option, 'class_name')}
                        options={subcategory
                          .filter((item) => item && item.label) // Filter out null and undefined elements
                          .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                        }
                        isSearchable={true} // Enables search functionality
                        placeholder="Select an option"
                        maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5 
                        filterOption={(option, searchText) =>
                          option.label.toLowerCase().includes(searchText.toLowerCase())
                        } // Filter options based on the search text
                        styles={customStyles} // Apply custom styles her
                      /> */}

                      {/* <select
                        ref={txtBatchCourse}
                        class="Inputs"
                        id="class_name"
                        name="class_name"
                        onChange={handleChange}
                        value={initialfields.class_name}
                      >
                        <option value="" disabled selected>
                          Select Class
                        </option>
                        {subcategory}
                      </select> */}
                      {/* <input type="text" class="Inputs" id="class_name" name="class_name" placeholder="Enter Class name" value={initialfields.class_name} onChange={handleChange} /> */}
                      {error.class_name && (
                        <small className="error">{error.class_name}</small>
                      )}
                    </div>
                  </div>
                  {Type !== "5" && Type !== "4" && Type !== "2" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="Subject">Subject:</label>
                        {/* {validateOptionSubjects.includes(initialfields.course_id) ?  true : false} */}
                        {/* validateOptionSubjects.includes(initialfields.course_id) ?  : option => handleSelectCH(option, 'Subject') */}
                        <Select
                          ref={txtBatchSubject}
                          id="Subject"
                          name="Subject"
                          isMulti
                          value={subMulti}
                          onChange={(option) =>
                            handleSelectCH(option, "Subject_Multi")
                          }
                          options={
                            subject
                              .filter((item) => item && item.label) // Filter out null and undefined elements
                              .sort((a, b) =>
                                a.label.trim().localeCompare(b.label.trim())
                              ) // Sort options alphabetically by label
                          }
                          components={
                            validateOptionSubjects.includes(
                              initialfields.course_id
                            )
                              ? {
                                  Option: CustomCOption, // Use the custom Option component
                                }
                              : {
                                  Option: CustomCOption, // Use the custom Option component
                                }
                          }
                          // components =  {
                          //     Option: CustomCOption, // Use the custom Option component
                          //   }
                          isSearchable={true} // Enables search functionality
                          placeholder="Select an option"
                          maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                          filterOption={(option, searchText) =>
                            option.label
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          } // Filter options based on the search text
                          styles={customStyles} // Apply custom styles her
                        />
                        {error.Subject && (
                          <small className="error">{error.Subject}</small>
                        )}
                      </div>
                    </div>
                  )}

                  {/* model exam new change  */}

                  {Type === "4" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="Papers">
                          Single Papers / Combined Papers?
                        </label>
                        <div
                          className="d-flex   flex-lg-row flex-md-row flex-column  col-md-12"
                          id="radiobuttons"
                        >
                          <div className="form-check form-check-inline">
                            <input
                              ref={txtBatchPP}
                              className="checkbox_radio"
                              disabled={initialfields.Papers !== "0" && id}
                              type="radio"
                              name="Papers"
                              id="SignlePaper"
                              value="0"
                              checked={initialfields.Papers === "0"}
                              onChange={handleChange}
                            />
                            <label
                              className="checkbox-label"
                              htmlFor="SignlePaper"
                            >
                              Single Papers
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox_radio"
                              type="radio"
                              name="Papers"
                              disabled={initialfields.Papers !== "1" && id}
                              id="CombinedPaper"
                              value="1"
                              checked={initialfields.Papers === "1"}
                              onChange={handleChange}
                            />
                            <label
                              className="checkbox-label"
                              htmlFor="CombinedPaper"
                            >
                              Combined Papers
                            </label>
                          </div>
                        </div>

                        {error.Papers && (
                          <small className="error">{error.Papers}</small>
                        )}
                      </div>
                    </div>
                  )}

                  {Type === "5" && initialfields.classMode === "ClassRoom" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="location">Interview Location</label>
                        <select
                          ref={txtBatchLocation}
                          id="location"
                          name="location"
                          className="Inputs"
                          onChange={handleChange}
                          value={initialfields.location}
                          required
                        >
                          <option value="" disabled selected>
                            Please select
                          </option>
                          {locations}
                        </select>
                        {error.location && (
                          <small className="error">{error.location}</small>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="name">Name:</label>
                      <input
                        type="text"
                        class="Inputs"
                        ref={txtBatchName}
                        id="name"
                        name="name"
                        maxLength={50}
                        placeholder="Enter Name"
                        value={initialfields.name}
                        onChange={handleChange}
                      />
                      {error.name && (
                        <small className="error">{error.name}</small>
                      )}
                    </div>
                  </div>
                  {Type !== "2" && Type !== "5" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="classMode">
                          {Type === "1"
                            ? "Test Mode"
                            : Type === "4"
                            ? "Mode of Exam"
                            : "Class Mode"}
                        </label>

                        <div
                          className="d-flex  flex-lg-row flex-md-row flex-column col-md-12"
                          id="radiobuttons"
                        >
                          <div className="form-check form-check-inline">
                            <input
                              ref={txtBatchClassMode}
                              className="checkbox_radio"
                              type="radio"
                              name="classMode"
                              id="Online"
                              value="Online"
                              onChange={handleChange}
                              checked={initialfields.classMode === "Online"}
                            />
                            <label className="checkbox-label" htmlFor="Online">
                              Online
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox_radio"
                              type="radio"
                              name="classMode"
                              id="ClassRoom"
                              value="ClassRoom"
                              checked={
                                initialfields.classMode === "ClassRoom" ||
                                initialfields.classMode === "Classroom"
                              }
                              onChange={handleChange}
                            />
                            <label
                              className="checkbox-label"
                              htmlFor="ClassRoom"
                            >
                              {Type === "4" ? "Offline" : "ClassRoom"}
                            </label>
                          </div>
                        </div>
                        {error.classMode && (
                          <small className="error">{error.classMode}</small>
                        )}
                      </div>
                    </div>
                  )}

                  {/* AIASA-000189 issues solved by lalitha and indhumathi */}

                  {Type !== "4" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="section">
                          {Type === "5" || Type === "2" ? "Panel " : "Section"}
                        </label>
                        <select
                          ref={txtBatchSec}
                          class="Inputs"
                          id="section"
                          name="section"
                          onChange={handleChange}
                          value={initialfields.section}
                        >
                          <option value="" disabled selected>
                            {" "}
                            Select{" "}
                            {Type === "5" || Type === "2"
                              ? "Panel "
                              : "Section"}{" "}
                          </option>
                          {alphabet.map((letter) => (
                            <option key={letter} value={letter}>
                              {letter}
                            </option>
                          ))}
                        </select>
                        {error.section && (
                          <small className="error">{error.section}</small>
                        )}
                      </div>
                    </div>
                  )}

                  {(Type === "2" || Type === "5") && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="ExamName">
                          Name of the Exam {Type === "2" && "for Counselling"}{" "}
                          {Type === "5" && "for Mock Interview"}
                        </label>
                        <Select
                          ref={txtBatchExamName}
                          id="ExamName"
                          name="ExamName"
                          value={
                            initialfields.ExamName_select !== null &&
                            initialfields.ExamName_select !== ""
                              ? {
                                  value: initialfields.ExamName,
                                  label: initialfields.ExamName_select,
                                }
                              : null
                          }
                          onChange={(option) =>
                            handleSelectCH(option, "ExamName")
                          }
                          options={
                            examination
                              .filter((item) => item && item.label) // Filter out null and undefined elements
                              .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                          }
                          isSearchable={true} // Enables search functionality
                          placeholder="Select an option"
                          maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                          filterOption={(option, searchText) =>
                            option.label
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          } // Filter options based on the search text
                          styles={customStyles} // Apply custom styles her
                        />
                        {/* <select
                          ref={txtBatchExamName}
                          id="ExamName"
                          name="ExamName"
                          className="Inputs"
                          onChange={handleChange}
                          value={initialfields.ExamName}
                          required
                        >
                          <option value="" disabled selected>
                            Please select
                          </option>
                          {examination}
                        </select> */}
                        {error.ExamName && (
                          <small className="error">{error.ExamName}</small>
                        )}
                      </div>
                    </div>
                  )}
                  {Type !== "4" &&
                    Type !== "5" &&
                    initialfields.classMode === "ClassRoom" && (
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="location">Branch Location</label>
                          <select
                            ref={txtBatchLocation}
                            id="location"
                            name="location"
                            className="Inputs"
                            onChange={handleChange}
                            value={initialfields.location}
                            required
                          >
                            <option value="" disabled selected>
                              Please select
                            </option>
                            {locations}
                          </select>
                          {error.location && (
                            <small className="error">{error.location}</small>
                          )}
                        </div>
                      </div>
                    )}
                  {Type !== "2" && Type !== "5" && (
                    <div className="col-md-6">
                      <div className="forms">
                        <label for="medium">
                          {Type === "1"
                            ? "Medium of Examination"
                            : Type === "4"
                            ? "Medium of Exam"
                            : "Medium of Instruction"}
                        </label>
                        <div
                          className="d-flex  flex-lg-row flex-md-row flex-column col-md-12"
                          id="radiobuttons"
                        >
                          <div className="form-check form-check-inline">
                            <input
                              ref={txtBatchMedium}
                              className="checkbox_radio"
                              type="radio"
                              name="medium"
                              id="Tamil"
                              value="Tamil"
                              checked={
                                initialfields.medium === "Tamil" ||
                                initialfields.medium === "TM"
                              }
                              onChange={handleChange}
                            />
                            <label className="checkbox-label" htmlFor="Tamil">
                              Tamil
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox_radio"
                              type="radio"
                              name="medium"
                              id="English"
                              value="English"
                              checked={initialfields.medium === "English"}
                              onChange={handleChange}
                            />
                            <label className="checkbox-label" htmlFor="English">
                              English
                            </label>
                          </div>
                          {/* {Type !== "4" && ( */}
                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox_radio"
                              type="radio"
                              name="medium"
                              disabled={id ? true : false}
                              id="Both"
                              value="Both"
                              checked={initialfields.medium === "Both"}
                              onChange={handleChange}
                            />
                            <label className="checkbox-label" htmlFor="Both">
                              Both English and Tamil
                            </label>
                          </div>
                          {/* )} */}

                          {/* AIASA-000140 v1 issues solved by lalitha */}
                          {/* {Type !== "1" && (
                          
                          )} */}
                        </div>
                        {error.medium && (
                          <small className="error">{error.medium}</small>
                        )}
                      </div>
                    </div>
                  )}
                  {Type === "1" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="NoTest">Number of Tests:</label>
                        <input
                          ref={txtBatchNTest}
                          type="number"
                          class="Inputs"
                          id="NoTest"
                          value={
                            initialfields.NoTest < 0 ? 0 : initialfields.NoTest
                          }
                          name="NoTest"
                          placeholder="Total Number of Test"
                          onChange={handleChange}
                          onKeyDown={handleKeyDown}
                        />
                        {error.NoTest && (
                          <small className="error">{error.NoTest}</small>
                        )}
                      </div>
                    </div>
                  )}
                  {Type !== "4" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="NoStudents">
                          Total Number of Students:
                        </label>
                        <input
                          ref={txtBatchNoS}
                          type="number"
                          class="Inputs"
                          id="NoStudents"
                          value={
                            initialfields.NoStudents < 0
                              ? 0
                              : initialfields.NoStudents
                          }
                          name="NoStudents"
                          placeholder="Total Number of Students"
                          onChange={handleChange}
                          onKeyDown={handleKeyDown}
                        />
                        {error.NoStudents && (
                          <small className="error">{error.NoStudents}</small>
                        )}
                      </div>
                    </div>
                  )}
                  {Type === "1" && (
                    <div className="col-lg-6 col-md-12">
                      <div className="forms ">
                        <label for="testDet">
                          Upload Test Details (in PDF):
                          {initialfields.testDet !== "" &&
                            initialfields.testDet !== null && (
                              <a
                                target="_blank"
                                href={`${ImageUrlPrefix}/${initialfields.testDet}`}
                              >
                                <Download className="download_icon" />
                              </a>
                            )}
                        </label>
                        <div className="row">
                          <div
                            className={
                              initialfields.testDet !== "" &&
                              initialfields.testDet !== null
                                ? "col-md-3 "
                                : "col-md-12"
                            }
                          >
                            <input
                              ref={txtBatchTestIMg}
                              type="file"
                              className={
                                initialfields.testDet !== "" &&
                                initialfields.testDet !== null
                                  ? "InputsFile nn_upload_width"
                                  : "InputsFile  vv_upload_width"
                              }
                              id="testDet"
                              name="testDet"
                              onChange={handleChange}
                            />
                          </div>
                          {initialfields.testDet !== "" &&
                            initialfields.testDet !== null && (
                              <div
                                className={
                                  "col-md-9 d-flex align-content-center p-0"
                                }
                              >
                                <span className="nn_upload_file">
                                  {initialfields.testDet !== "" &&
                                  initialfields.testDet !== null
                                    ? initialfields.original_test_img_url
                                    : ""}
                                </span>
                              </div>
                            )}
                        </div>

                        {error.testDet && (
                          <small className="error">{error.testDet}</small>
                        )}
                      </div>
                    </div>
                  )}
                  {Type === "3" && (
                    <div className="col-lg-6 col-md-12">
                      <div className="forms ">
                        <label for="testDet">
                          Upload Syllabus (in PDF):
                          {initialfields.special_syll !== "" &&
                            initialfields.special_syll !== null && (
                              <a
                                target="_blank"
                                href={`${ImageUrlPrefix}/${initialfields.special_syll}`}
                              >
                                <Download className="download_icon" />
                              </a>
                            )}
                        </label>
                        <div className="row">
                          <div
                            className={
                              initialfields.special_syll !== "" &&
                              initialfields.special_syll !== null
                                ? "col-md-3 "
                                : "col-md-12"
                            }
                          >
                            <input
                              ref={txtBatchSpSyll}
                              type="file"
                              className={
                                initialfields.special_syll !== "" &&
                                initialfields.special_syll !== null
                                  ? "InputsFile nn_upload_width"
                                  : "InputsFile  vv_upload_width"
                              }
                              id="special_syll"
                              name="special_syll"
                              onChange={handleChange}
                            />
                          </div>
                          {initialfields.special_syll !== "" &&
                            initialfields.special_syll !== null && (
                              <div
                                className={
                                  "col-md-9 d-flex align-content-center p-0"
                                }
                              >
                                <span className="nn_upload_file">
                                  {initialfields.special_syll !== "" &&
                                  initialfields.special_syll !== null
                                    ? initialfields.original_subject_file_path
                                    : ""}
                                </span>
                              </div>
                            )}
                        </div>

                        {error.special_syll && (
                          <small className="error">{error.special_syll}</small>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="col-lg-6 col-md-12">
                    <div className="forms ">
                      <label for="batchImg">
                        Upload Batch Image:
                        {initialfields.batchImg !== "" &&
                          initialfields.batchImg !== null && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${initialfields.batchImg}`}
                            >
                              <Download className="download_icon" />
                            </a>
                          )}
                      </label>
                      <div className="row">
                        <div
                          className={
                            initialfields.batchImg !== "" &&
                            initialfields.batchImg !== null
                              ? "col-md-3 "
                              : "col-md-12"
                          }
                        >
                          <input
                            ref={txtBatch_batchImg}
                            type="file"
                            className={
                              initialfields.batchImg !== "" &&
                              initialfields.batchImg !== null
                                ? "InputsFile nn_upload_width"
                                : "InputsFile  vv_upload_width"
                            }
                            id="batchImg"
                            name="batchImg"
                            onChange={handleChange}
                          />
                        </div>
                        {initialfields.batchImg !== "" &&
                          initialfields.batchImg !== null && (
                            <div
                              className={
                                "col-md-9 d-flex align-content-center p-0"
                              }
                            >
                              <span className="nn_upload_file">
                                {initialfields.batchImg !== "" &&
                                initialfields.batchImg !== null
                                  ? initialfields.original_img_url
                                  : ""}
                              </span>
                            </div>
                          )}
                      </div>

                      {error.batchImg && (
                        <small className="error">{error.batchImg}</small>
                      )}
                    </div>
                  </div>
                  {Type === "4" && id && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="District">Exam Districts:</label>
                        <Select
                          ref={txtBatchDisEdit}
                          id="District"
                          name="District"
                          options={district}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          onChange={handleChangeMutipleDistrict_Edit}
                          value={selectedDistrict.map((value) => ({
                            value: value.value,
                            district_id: value.district_id,
                            label: value.value,
                            district_code: value.district_code,
                          }))}
                          components={{
                            Option: CustomCOption, // Use the custom Option component
                          }}
                          styles={multiCusutomStyle}
                        />

                        {error.District && (
                          <small className="error">{error.District}</small>
                        )}
                      </div>
                    </div>
                  )}
                  {Type === "4" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="NModelExam">Number of Model Exams:</label>
                        <div className="d-flex align-items-center justify-content-start">
                          <input
                            style={{ width: "100%" }}
                            ref={txtBatchNModelExam}
                            type="number"
                            readOnly
                            class="Inputs"
                            id="NModelExam"
                            value={
                              initialfields.NModelExam &&
                              /^\d*$/.test(initialfields.NModelExam)
                                ? initialfields.NModelExam > 0
                                  ? initialfields.NModelExam
                                  : ""
                                : ""
                            }
                            name="NModelExam"
                            placeholder="Number of Model Exams:"
                            onChange={handleChange}
                          />
                          {!id && (
                            <>
                              <span
                                className="material-icons Increasecricle"
                                onClick={() => handleIncreaseModel()}
                              >
                                add_circle
                              </span>
                              <span
                                className="material-icons Increasecricle"
                                onClick={() => handleDecreaseModel()}
                              >
                                remove_circle
                              </span>
                            </>
                          )}
                        </div>

                        {error.NModelExam && (
                          <small className="error">{error.NModelExam}</small>
                        )}
                      </div>
                    </div>
                  )}
                  {Type === "5" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="startDate">Start Date:</label>

                        <DatePickerSample
                          txtAdMDob={txtBatchSdate}
                          name={"startDate"}
                          handleChange={handleChangeCuDate}
                          fieldInput={initialfields.startDate}
                        />
                        {error.startDate && (
                          <small className="error">{error.startDate}</small>
                        )}
                      </div>
                    </div>
                  )}
                  {Type === "5" && (
                    <div className="col-md-6">
                      <div className="forms">
                        <label for="mockInterview_endDate">End Date:</label>

                        <DatePickerSample
                          minDate={
                            initialfields.startDate !== null
                              ? initialfields.startDate
                              : ""
                          }
                          txtAdMDob={txtBatchEsdate}
                          name={"mockInterview_endDate"}
                          handleChange={handleChangeCuDate}
                          fieldInput={initialfields.mockInterview_endDate}
                        />
                        {error.mockInterview_endDate && (
                          <small className="error">
                            {error.mockInterview_endDate}
                          </small>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* {Type === "4" && initialfields.LastDateApply === true && ( */}
          {Type === "4" && (
            <section className="StepOne ">
              {districtWise.map((item, index) => (
                <DistrictExam
                  key={index}
                  isedit={false}
                  txtBatchDis={txtBatchDis}
                  txtBatchDisCode={txtBatchDisCode}
                  txtBatchDEX={txtBatchDEX}
                  txtBatchDisMC={txtBatchDisMC}
                  record={item}
                  length={districtWise.length}
                  district={district}
                  handleChangeMutipleDistrict={handleChangeMutipleDistrict}
                  handleChangedrodown={handleChangedrodown}
                  handleExamChnageDropdown={handleExamChnageDropdown}
                  handleChangeExamcenter={handleChangeExamcenter}
                  addDistrictWise={addDistrictWise}
                  deleteDistrictWise={deleteDistrictWise}
                />
              ))}
            </section>
          )}

          {/* {Type === "4" && id && (
            <section className="StepOne ">
              {districtWise.map((item, index) => (
                <EditViewDistrict
                  key={index}
                  isedit={false}
                  txtBatchDis={txtBatchDis}
                  txtBatchDisCode={txtBatchDisCode}
                  txtBatchDEX={txtBatchDEX}
                  txtBatchDisMC={txtBatchDisMC}
                  record={item}
                  handleExamChnageDropdown={handleExamChnageDropdown}
                  length={districtWise.length}
                  district={district}
                  handleChangeMutipleDistrict={handleChangeMutipleDistrict}
                  handleChangedrodown={handleChangedrodown}
                  handleChangeExamcenter={handleChangeExamcenter}
                  addExamCenter={addExamCenter}
                  deleteDistrictWise={deleteDistrictWise}
                />
              ))}
            </section>
          )} */}
          {/* on-creation */}
          {Type === "4" && (
            <section className="StepOne ">
              {examDetail.map((item, index, arr) => {
                const tempExamDate = arr[index - 1]
                  ? new Date(arr[index - 1]["date"])
                  : new Date();
                const finalTempExamDate = new Date(
                  tempExamDate.getFullYear(),
                  tempExamDate.getMonth(),
                  tempExamDate.getDate() + 1,
                  0,
                  0,
                  0
                );
                const Today = new Date();
                const finalTempLastDate = arr[index - 1]
                  ? new Date(arr[index - 1]["date"])
                  : new Date(
                      Today.getFullYear(),
                      Today.getMonth(),
                      Today.getDate() + 1
                    );
                // const finalTempLastDate=new Date()
                //combined paper
                const tempLastDateCombined = new Date(item.LastDateApply);
                const finalLastDateCombined = new Date(
                  tempLastDateCombined.getFullYear(),
                  tempLastDateCombined.getMonth(),
                  tempLastDateCombined.getDate() + 1
                );

                return (
                  <>
                    <ExamDetail
                      papersDetails={initialfields.Papers}
                      key={item.id}
                      txtBatchETitle={txtBatchETitle}
                      txtBatchEendTime={txtBatchEendTime}
                      txtBatchEstartTime={txtBatchEstartTime}
                      txtBatchEduration={txtBatchEduration}
                      txtBatchNPapers={txtBatchNPapers}
                      txtBatchEdate={txtBatchEdate}
                      txtBatchDapply={txtBatchDapply}
                      txtBatchEsyllabus={txtBatchEsyllabus}
                      handleIncreasePaperModel={handleIncreasePaperModel}
                      handleDecreasePaperModel={handleDecreasePaperModel}
                      length={examDetail.length}
                      examdetailschange={examdetailschange}
                      examdetailschangeTime={examdetailschangeTime}
                      onHandleExamTimeChange={handleExamTimeChange}
                      examdetailschange_date={examdetailschange_date}
                      handleEndTimeChange={handleEndTimeChange}
                      AddExamDetails={AddExamDetails}
                      DeleteExamDetails={DeleteExamDetails}
                      DeleteExamDetailsPaper={DeleteExamDetailsPaper}
                      record={item}
                      minExamDate={finalTempExamDate}
                      minLastDate={finalTempLastDate}
                    />
                    {/* {item.PaperDetailsSection.length > 0 &&
                      item.PaperDetailsSection.map((paperItem, paperIndex) => (
                        <ExamPaperDetails
                          key={paperItem.id}
                          record={paperItem}
                          parentId={item.id}
                          LastDateApply={item.LastDateApply}
                          length={paperItem.length}
                          txtBatchPETitle={txtBatchPETitle}
                          txtBatchPEstartTime={txtBatchPEstartTime}
                          txtBatchPEdate={txtBatchPEdate}
                          txtBatchPEduration={txtBatchPEduration}
                          examdetailschangePaper={examdetailschangePaper}
                          examdetailschangeTimePaper={
                            examdetailschangeTimePaper
                          }
                          examdetailschange_datePaper={
                            examdetailschange_datePaper
                          }
                          txtBatchPEendTime={txtBatchPEendTime}
                          txtBatchPEsyllabus={txtBatchPEsyllabus}
                        />
                      ))} */}
                    {item.PaperDetailsSection.length > 0 &&
                      item.PaperDetailsSection.map(
                        (paperItem, paperIndex, arrpaper) => {
                          let lakshExamDate = finalLastDateCombined;
                          if (
                            arr[index - 1]?.["PaperDetailsSection"]?.slice(-1)
                          ) {
                            const templakshExamDate = new Date(
                              arr[index - 1]?.["PaperDetailsSection"]?.slice(
                                -1
                              )?.[0]?.["date"]
                            );
                            lakshExamDate = new Date(
                              templakshExamDate.getFullYear(),
                              templakshExamDate.getMonth(),
                              templakshExamDate.getDate() + 1
                            );
                          }
                          const tempExamDateCombined = arrpaper[
                            paperIndex - 1
                          ]?.["date"]
                            ? new Date(arrpaper[paperIndex - 1]?.["date"])
                            : finalLastDateCombined;
                          // const tempExamDateCombined = finalLastDateCombined;
                          // console.log("laksh-examPaer-1",tempExamDateCombined);

                          // : new Date(
                          //     Today.getFullYear(),
                          //     Today.getMonth(),
                          //     Today.getDate()
                          //   );
                          // const addedDate = paperIndex === 0 ? 0 : 1;
                          const addedDate = 0;
                          let finalExamDateCombined = new Date(
                            tempExamDateCombined.getFullYear(),
                            tempExamDateCombined.getMonth(),
                            tempExamDateCombined.getDate() + addedDate
                          );
                          if (index !== 0) {
                            // console.log("laksh-lak", paperItem);
                            // finalExamDateCombined = new Date(2024, 4, 15);
                            finalExamDateCombined = lakshExamDate;
                            // tempExamDateCombined.getFullYear(),
                            // tempExamDateCombined.getMonth(),
                            // tempExamDateCombined.getDate() + addedDate
                          }
                          // const tempExamDateCombined = arrpaper[
                          //   paperIndex - 1
                          // ]?.["date"]
                          //   ? new Date(arrpaper[paperIndex - 1]?.["date"])
                          //   : finalLastDateCombined;
                          // // : new Date(
                          // //     Today.getFullYear(),
                          // //     Today.getMonth(),
                          // //     Today.getDate()
                          // //   );
                          // const addedDate = paperIndex === 0 ? 0 : 1;
                          // const finalExamDateCombined = new Date(
                          //   tempExamDateCombined.getFullYear(),
                          //   tempExamDateCombined.getMonth(),
                          //   tempExamDateCombined.getDate() + addedDate
                          // );

                          return (
                            <ExamPaperDetails
                              key={paperItem.id}
                              record={paperItem}
                              parentId={item.id}
                              LastDateApply={item.LastDateApply}
                              length={item.PaperDetailsSection.length}
                              txtBatchPETitle={txtBatchPETitle}
                              txtBatchPEstartTime={txtBatchPEstartTime}
                              txtBatchPEdate={txtBatchPEdate}
                              txtBatchPEduration={txtBatchPEduration}
                              examdetailschangePaper={examdetailschangePaper}
                              DeleteExamDetailsPaper={DeleteExamDetailsPaper}
                              AddExamDetailsPaper={AddExamDetailsPaper}
                              examdetailschangeTimePaper={
                                examdetailschangeTimePaper
                              }
                              onHandleExamTimeChangePaper={
                                handleExamTimeChangePaper
                              }
                              examdetailschange_datePaper={
                                examdetailschange_datePaper
                              }
                              txtBatchPEendTime={txtBatchPEendTime}
                              txtBatchPEsyllabus={txtBatchPEsyllabus}
                              minExamDateCombined={finalExamDateCombined}
                            />
                          );
                        }
                      )}
                  </>
                );
              })}
            </section>
          )}

          {Type === "4" && examDetail.length > 0 && (
            <section className="StepOne ">
              <div className="row box ">
                <div className="col-md-12">
                  <p className="StepTitle">Fees</p>
                  <div className="row mb-4" style={{ paddingRight: "20px" }}>
                    {examDetail.map((item, index) => (
                      <ExamFees
                        papersDetails={initialfields.Papers}
                        txtBatchEFees={txtBatchEFees}
                        key={item.id}
                        examdetailschange={examdetailschange}
                        length={examDetail.length}
                        record={item}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </section>
          )}
          {Type === "5" &&
            mockPanel.map((item, index) => (
              <AddpanelMemberMocksec
                key={item.id}
                txtBatchMPanelName={txtBatchMPanelName}
                txtBatchMADate={txtBatchMADate}
                txtBatchMPM={txtBatchMPM}
                txtBatchMSM={txtBatchMSM}
                handleDateChangeMock={handleDateChangeMock}
                length={mockPanel.length}
                handleDeleteMock={handleDeleteMock}
                selectedDatesMock={selectedDatesMock}
                staffMem={staffMem}
                startDate={initialfields.startDate}
                endDate={initialfields.mockInterview_endDate}
                selectedStaffMem={selectedStaffMem}
                handleChangeMutiplestaffMem={handleChangeMutiplestaffMem}
                handleChangeMutiplePanleMme={handleChangeMutiplePanleMme}
                AddMockPanel={AddMockPanel}
                DeleteMockPanel={DeleteMockPanel}
                panelMem={panelMem}
                selectedMem={selectedMem}
                mockPanelHandlechange={mockPanelHandlechange}
                record={item}
              />
            ))}

          {Type === "2" &&
            cPanel.map((el, index) => (
              <section className="StepOne ">
                <AddCounsellingPanel
                  key={el.id}
                  txtBatchCPanelName={txtBatchCPanelName}
                  txtBatchCStartTime={txtBatchCStartTime}
                  txtBatchCCDate={txtBatchCCDate}
                  txtBatchCEndTime={txtBatchCEndTime}
                  txtBatchCdept={txtBatchCdept}
                  txtBatchCPM={txtBatchCPM}
                  txtBatchCSM={txtBatchCSM}
                  dPart={dPart}
                  cStaffMem={staffMem}
                  handleChangeCPanel={handleChangeCPanel}
                  handleChangeCPanelTime={handleChangeCPanelTime}
                  handleChangeCPanel_date={handleChangeCPanel_date}
                  length={cPanel.length}
                  record={el}
                  handleChangeDepartmentCPanel={handleChangeDepartmentCPanel}
                  handleChangePMCPanel={handleChangePMCPanel}
                  handleChangeTMCPanel={handleChangeTMCPanel}
                  AddCPanel={AddCPanel}
                  DeleteCPanel={DeleteCPanel}
                />
              </section>
            ))}

          {Type === "5" && (
            <section className=" StepOne ">
              <div className="row box ">
                <div className="col-md-12">
                  <p className="StepTitle">MockInterview Fees </p>
                  <div className="row mb-4">
                    {mockPanel.map((obj, index) => (
                      <MockInterviewFees
                        key={obj.id}
                        mockPanelHandlechange={mockPanelHandlechange}
                        txtBatchMFees={txtBatchMFees}
                        length={mockPanel.length}
                        record={obj}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </section>
          )}

          {Type === "2" && (
            <section className="StepOne ">
              <div className="row box ">
                <div className="col-md-12">
                  <p className="StepTitle">Counselling Fees Fees</p>
                  <div className="row mb-4">
                    {cPanel.map((obj, index) => (
                      <Counsellingfees
                        key={obj.id}
                        handleChangeCPanel={handleChangeCPanel}
                        txtBatchCFees={txtBatchCFees}
                        length={cPanel.length}
                        record={obj}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </section>
          )}

          {Type !== "4" && Type !== "5" && Type !== "2" && (
            <section className="StepOne ">
              <div className="row box ">
                <div className="col-md-12">
                  <p className="StepTitle">Schedule</p>
                  <div className="row mb-4">
                    {Type !== "2" && Type !== "4" && Type !== "5" && (
                      <div className="col-md-6">
                        <div className="forms ">
                          <label for="batch">Schedule Preference:</label>

                          <div
                            className="d-flex   flex-lg-row flex-md-row flex-column  col-md-12"
                            id="radiobuttons"
                          >
                            <div className="form-check form-check-inline">
                              <input
                                ref={txtBatchSper}
                                className="checkbox_radio"
                                type="radio"
                                name="batch"
                                id="Weekend"
                                value="Weekend"
                                checked={initialfields.batch === "Weekend"}
                                onChange={handleChange}
                              />
                              <label
                                className="checkbox-label"
                                htmlFor="Weekend"
                              >
                                Weekend
                              </label>
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="checkbox_radio"
                                type="radio"
                                name="batch"
                                id="Regular"
                                value="Regular"
                                checked={initialfields.batch === "Regular"}
                                onChange={handleChange}
                              />
                              <label
                                className="checkbox-label"
                                htmlFor="Regular"
                              >
                                Regular
                              </label>
                            </div>
                          </div>
                          {error.batch && (
                            <small className="error">{error.batch}</small>
                          )}
                        </div>
                      </div>
                    )}

                    {Type !== "2" && Type !== "4" && (
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="startDate">Start Date:</label>
                          <DatePickerSample
                            txtAdMDob={txtBatchSdate}
                            name={"startDate"}
                            handleChange={handleChangeCuDate}
                            fieldInput={initialfields.startDate}
                          />
                          {error.startDate && (
                            <small className="error">{error.startDate}</small>
                          )}
                        </div>
                      </div>
                    )}
                    {(Type === "2" || Type === "4") && (
                      <div className="col-md-6">
                        <div className="forms ">
                          <label for="CDate">Date:</label>
                          {/* <input
                            ref={txtBatchCDate}
                            type="Date"
                            class="Inputs"
                            id="CDate"
                            name="CDate"
                            placeholder="Enter Date"
                            value={initialfields.CDate}
                            onChange={handleChange}
                          /> */}
                          <DatePickerSample
                            txtAdMDob={txtBatchCDate}
                            name={"CDate"}
                            handleChange={handleChangeCuDate}
                            fieldInput={initialfields.CDate}
                          />
                          {error.CDate && (
                            <small className="error">{error.CDate}</small>
                          )}
                        </div>
                      </div>
                    )}
                    {/* Type !== "2" &&   AIASA-000163 v1 - issues solved by lalitha */}
                    {Type !== "5" && (
                      <div className="col-md-6">
                        <div className="forms ">
                          <label for="courseCategory">
                            Duration (in Months)
                          </label>
                          <select
                            ref={txtBatchDuration}
                            class="Inputs"
                            id="duration"
                            name="duration"
                            value={initialfields.duration}
                            onChange={handleChange}
                          >
                            <option value="" disabled selected>
                              Duration (in Months)
                            </option>
                            {/* AIASA-000144  v1 issues solved by lalitha */}
                            <option value="1">1 </option>
                            <option value="2">2 </option>
                            <option value="3">3 </option>
                            <option value="4">4 </option>
                            <option value="5">5 </option>
                            <option value="6">6 </option>
                            <option value="7">7 </option>
                            <option value="8">8 </option>
                            <option value="9">9 </option>
                            <option value="10">10 </option>
                            <option value="11">11 </option>
                            <option value="12">12 </option>
                          </select>
                          {error.duration && (
                            <small className="error">{error.duration}</small>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="startTime">Start Time:</label>
                        {/* <input
                          ref={txtBatchStime}
                          type="time"
                          class="Inputs"
                          id="startTime"
                          step="1"
                          name="startTime"
                          placeholder="Enter startTime"
                          value={initialfields.startTime}
                          onChange={handleChange}
                        /> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            ref={txtBatchStime}
                            id="startTime"
                            step={1}
                            name="startTime"
                            placeholder="Enter startTime"
                            title={initialfields.startTime}
                            value={
                              initialfields.startTime_In !== ""
                                ? dayjs(initialfields.startTime_In)
                                : ""
                            }
                            //  maxTime={  initialfields.endTime !==null  && initialfields.endTime !=="null" &&  dayjs(initialfields.endTime, "HH:mm:ss") }

                            format="hh:mm:ss A"
                            onChange={(data) =>
                              handleChangeTime(data, "startTime")
                            }
                          />
                        </LocalizationProvider>
                        {error.startTime && (
                          <small className="error">{error.startTime}</small>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="endTime">End Time:</label>
                        {/* <input
                          ref={txtBatchEtime}
                          type="time"
                          class="Inputs"
                          id="endTime"
                          step="1"
                          name="endTime"
                          placeholder="Enter endTime"
                          value={initialfields.endTime}
                          onChange={handleChange}
                        /> */}
                        {/* dayjs(initialfields.startTime, "hh:mm:ss") */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            ref={txtBatchEtime}
                            id="endTime"
                            step={1}
                            name="endTime"
                            placeholder="Enter endTime"
                            // minTime={initialfields.startTime !== null && initialfields.startTime !== "" && (initialfields.startTime !== "00:00:00" || initialfields.startTime !== "12:00:00 PM") ? dayjs(initialfields.startTime, "hh:mm:ss A") : ""}
                            title={initialfields.startTime}
                            value={
                              initialfields.endTime !== ""
                                ? dayjs(initialfields.endTime_In)
                                : ""
                            }
                            format="hh:mm:ss A" // Use 'HH' for 24-hour format
                            onChange={(data) =>
                              handleChangeTime(data, "endTime")
                            }
                          />
                        </LocalizationProvider>
                        {error.endTime && (
                          <small className="error">{error.endTime}</small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {Type !== "4" && (
            <section className="StepOne ">
              <div className="row box ">
                <div className="col-md-12">
                  <p className="StepTitle">Partial Payment</p>
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <div className="forms ">
                        <label for="batch">Eligible for Partial Payment?</label>

                        <div
                          className="d-flex   flex-lg-row flex-md-row flex-column  col-md-12"
                          id="radiobuttons"
                        >
                          <div className="form-check form-check-inline">
                            <input
                              ref={txtBatchPP}
                              className="checkbox_radio"
                              type="radio"
                              name="pPayment"
                              id="YesP"
                              value="Yes"
                              checked={initialfields.pPayment === "Yes"}
                              onChange={handleChange}
                            />
                            <label className="checkbox-label" htmlFor="YesP">
                              Yes
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox_radio"
                              type="radio"
                              name="pPayment"
                              id="NoP"
                              value="NO"
                              checked={initialfields.pPayment === "NO"}
                              onChange={handleChange}
                            />
                            <label className="checkbox-label" htmlFor="NoP">
                              NO
                            </label>
                          </div>
                        </div>
                        {error.pPayment && (
                          <small className="error">{error.pPayment}</small>
                        )}
                      </div>
                    </div>

                    {initialfields.pPayment === "Yes" && (
                      <>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label for="perOne">1st Payment Percentage:</label>
                            <input
                              ref={txtBatchperOne}
                              type="text"
                              class="Inputs"
                              id="perOne"
                              name="perOne"
                              placeholder="1st Payment Percentage"
                              value={initialfields.perOne}
                              onChange={handleChange}
                            />

                            {error.perOne && (
                              <small className="error">{error.perOne}</small>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="forms ">
                            <label for="perOneDate">1st Payment Date</label>
                            <span className="admi_date">
                              {" "}
                              Student's Admission Date
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="forms ">
                            <label for="perTwo">2nd Payment Percentage:</label>
                            <input
                              ref={txtBatchperTwo}
                              type="text"
                              class="Inputs"
                              id="perTwo"
                              name="perTwo"
                              placeholder="2nd Payment Percentage"
                              value={initialfields.perTwo}
                              onChange={handleChange}
                            />
                            {error.perTwo && (
                              <small className="error">{error.perTwo}</small>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="forms ">
                            <label for="perTwoDate">2nd Payment Date:</label>

                            <DatePickerSample
                              minDate={
                                initialfields.startDate !== null
                                  ? initialfields.startDate
                                  : ""
                              }
                              txtAdMDob={txtBatchperTwoDate}
                              name={"perTwoDate"}
                              handleChange={handleChangeCuDate}
                              fieldInput={initialfields.perTwoDate}
                            />
                            {error.perTwoDate && (
                              <small className="error">
                                {error.perTwoDate}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="forms ">
                            <label for="perThree">
                              3rd Payment Percentage:
                            </label>
                            <input
                              ref={txtBatchperThree}
                              type="text"
                              class="Inputs"
                              id="perThree"
                              name="perThree"
                              placeholder="3rd Payment Percentage"
                              value={initialfields.perThree}
                              onChange={handleChange}
                            />
                            {error.perThree && (
                              <small className="error">{error.perThree}</small>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="forms ">
                            <label for="perThreeDate">3rd Payment Date:</label>
                            <DatePickerSample
                              minDate={
                                initialfields.perTwoDate !== null
                                  ? initialfields.perTwoDate
                                  : ""
                              }
                              txtAdMDob={txtBatchperThreeDate}
                              name={"perThreeDate"}
                              handleChange={handleChangeCuDate}
                              fieldInput={initialfields.perThreeDate}
                            />
                            {error.perThreeDate && (
                              <small className="error">
                                {error.perThreeDate}
                              </small>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}

          {Type !== "4" && Type !== "5" && Type !== "2" && (
            <section className=" StepOne ">
              <div className="row box ">
                <div className="col-md-12">
                  <p className="StepTitle">Fees and Discounts</p>
                  <div className="row mb-4">
                    <div className="col-lg-6 col-md-12">
                      <div className="forms ">
                        <label for="fees">Fees:</label>
                        <div className="input-group">
                          <div className="input-group-texts">₹</div>
                          <input
                            ref={txtBatchFees}
                            type="number"
                            className="Inputsss"
                            id="fees"
                            name="fees"
                            placeholder="Enter Fees"
                            value={
                              initialfields.fees < 0 ? 0 : initialfields.fees
                            }
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                        {error.fees && (
                          <small className="error">{error.fees}</small>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="forms ">
                        <label for="offerAmount">Special Offer (if any)</label>
                        <div className="input-group">
                          <div className="input-group-texts">₹</div>
                          <input
                            ref={txtBatchSamount}
                            type="number"
                            class="Inputsss"
                            id="offerAmount"
                            maxLength={4}
                            name="offerAmount"
                            placeholder="Discount (if any)"
                            value={
                              initialfields.offerAmount < 0
                                ? 0
                                : initialfields.offerAmount
                            }
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                          />
                        </div>

                        {error.offerAmount && (
                          <small className="error">{error.offerAmount}</small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className="StepOne ">
            {oldBatch.map((item, index) => (
              <OldStudentBatch
                error={error.oldBatch}
                key={item.id}
                length={oldBatch.length}
                handleChangeMutiple={handleChangeMutiple}
                // batchlist={batchlist}
                batchChange={batchChange}
                batchChange_date={batchChange_date}
                addoldbatch={addoldbatch}
                deleteOldBatch={deleteOldBatch}
                record={item}
                txtBatchOStudentAMount={txtBatchOStudentAMount}
                txtBatchOBatchFrom={txtBatchOBatchFrom}
                txtBatchOBatchTo={txtBatchOBatchTo}
                txtBatchOApplicableBatch={txtBatchOApplicableBatch}
              />
            ))}
          </section>

          {Type === "4" && (
            <section className="StepOne ">
              {mExamSF.map((item, index) => (
                <ModelExamDiscount
                  keysss={item.id}
                  // ref={el => itemsRef.current[index] = el}
                  index={index + 1}
                  length={mExamSF.length}
                  oNGoingBatch={oNGoingBatch}
                  addMESF={addMESF}
                  deleteMESF={deleteMESF}
                  record={item}
                  ModelSFExam={ModelSFExam}
                  ModelSFExamClickFunction={ModelSFExamClickFunction}
                  handleChangeMutipleMESF={handleChangeMutipleMESF}
                  txtBatchMESFN={txtBatchMESFN}
                  txtBatchMESFNSF={txtBatchMESFNSF}
                  txtBatchMESFNSC={txtBatchMESFNSC}
                  txtBatchMESFNAB={txtBatchMESFNAB}
                />
              ))}
            </section>
          )}

          <section className="StepOne ">
            <div className="row box ">
              <div className="col-md-12">
                <p className="StepTitle">Welcome Message</p>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="forms ">
                      <label for="message">Message:</label>
                      <textarea
                        ref={txtBatchMsg}
                        class="InputsTextarea"
                        value={initialfields.message}
                        id="message"
                        rows="4"
                        name="message"
                        placeholder="Welcome Message"
                        onChange={handleChange}
                      ></textarea>
                      {error.message && (
                        <small className="error">{error.message}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="StepOne ">
            <div className="heading me-xl-4 mb-4 mt-3 dflex ">
              {Type === "0" && (
                <Link to={`/ViewCourseBatch/${1}`} className="CancelButton">
                  Back to Batches
                </Link>
              )}

              {Type === "1" && (
                <Link to={`/ViewCourseBatch/${2}`} className="CancelButton">
                  Back to Batches
                </Link>
              )}

              {Type === "2" && (
                <Link to={`/ViewCourseBatch/${4}`} className="CancelButton">
                  Back to Batches
                </Link>
              )}

              {Type === "3" && (
                <Link to={`/ViewCourseBatch/${3}`} className="CancelButton">
                  Back to Batches
                </Link>
              )}

              {Type === "4" && (
                <Link to={`/ViewCourseBatch/${5}`} className="CancelButton">
                  Back to Batches
                </Link>
              )}

              {Type === "5" && (
                <Link to={`/ViewCourseBatch/${6}`} className="CancelButton">
                  Back to Batches
                </Link>
              )}

              <button
                type="button"
                className={Type === "2" ? "SaveButtons" : "SaveButton"}
                onClick={handleSubmit}
              >
                Save {Type === "0" && "Course"} {Type === "1" && "Test"}{" "}
                {Type === "2" && "Counselling Awareness Program"}{" "}
                {Type === "3" && "Special Class"} {Type === "4" && "Model Exam"}{" "}
                {Type === "5" && "mock interview"} Batch
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default CreateCourseBatch;
