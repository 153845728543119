import React from 'react'
import { ImageUrlPrefix } from '../../labels';

 function SpecialPrefrence(props) {
  return (
   
                             <div className="row ">
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="name">
                                    Special Preferences
                                  </label>
                                  <select
                                    id="name"
                                    name="name"
                                    className="Inputs"
                                    onChange={e =>  props.specialHandleChange(e, props.record.id,props.record.name)}
                                    value={props.record.name}
                                    required
                                  >
                                    <option value="" disabled >
                                      Please select Special Preferences
                                    </option>
                                    {/* <option value="None">
                                     None
                                    </option> */}
                                   {props.sPrefrence}
                                  </select>

                                  {/* {props.record.errorName &&  <span className='errors'>Please enter the name</span>} */}
                                 
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="forms ">
                                  <label htmlFor="file">
                                    Upload Certificate (In pdf format)
                                   {
                                    (props.record.file !="" && props.record.file !=null ) && (
                                      <a href={`${ImageUrlPrefix}/${props.record.file}`} target="_blank" download><span className="material-icons align-middle download_icon" >file_download</span></a>
                                    )
                                   }
                                  </label>
                                  <input
                                    id="file"
                                    type="file"
                                    name="file"
                                    onChange={e => props.specialHandleChange(e, props.record.id,props.record.name)}
                                    className="InputsFile nonefield"
                                    placeholder="Upload Certificate"
                                    autoComplete="file"
                                    required
                                  />
                                </div>
                              </div>
                              {/* {props.record.errorFile &&  <span className='errors'>Please choose the File</span>} */}
                              
                              <div className='col-md-1 d-flex align-items-end'>
                            {props.record.id === 1 ? "" : 
                            (
                              <small className='material-icons text-danger' onClick={e =>  props.Deletespre(props.record.id,props.record.name)}>remove_circle</small>
                            )}
 
                              </div>
                            </div>
   
  )
}
export default SpecialPrefrence