import React, { useState } from "react";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import TestPerformance from "./TestPerformance";
import { AxiosInstance } from "../../../../axios";

function TestPerformanceTablist() {

  const {active_tab, batchIds } = useParams();

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
 
  useEffect(() => {
    // Update the activeTab state when the component mounts and whenever active_tab changes in the URL
    setActiveTab(Number(active_tab) || 1);
    setBatchId(batchIds);
    // setActiveTab(1);
  }, [active_tab]);
 
  const handleTabClick = (index,batch_id) => {
    setActiveTab(index);
    setBatchId(batch_id);
    navigate(`/TestPerformanceListView/${index}/${batch_id}`);
  };

const userJSON = localStorage.getItem("userDetails");
let userid = null;

if (userJSON) {
  const user = JSON.parse(userJSON);
  if (user && user.user_id) {
    userid = user.user_id;
  }
} else {
  console.log("User Id cannot avaliable in localstorge");
}


const [tPer, setTPer] = useState([]);
const [batchId, setBatchId] = useState(null);

// get batch for user

useEffect(() => {
  AxiosInstance.post("api/batch/list/byUser", {
    user_id: userid,
  })
    .then((res) => {
      if (res.data.status === true) {
        // console.log(res);
        // .filter((el)=>el.batch_type === 1)
        let df = res.data.data;
        setTPer(df);
      }
    })
    .catch((error) => {
      return error;
    });
}, []);


  return (
    <div className="BathDet TestPerformance ">
     <h6 className="Title">Test Performance</h6>
      <div className="row mt-3">
        <div className={tPer.length < 3 ? "col-md-12" :"col-md-12" }>
        {/* nav-tabs */}
          <ul  
            className="nav  nav-fill mb-3"
            id="listViewTabs"
            role="tablist"
          >
            {tPer.length > 0 ? (tPer.map((el,index) => (

        <li className="nav-item" role="presentation">
        <a
          className={activeTab === index + 1 ? "nav-link active" : "nav-link"}
          // id="ex2-tab-1"
          data-mdb-toggle="tab"
          href="javascript:void(0)"
          role="tab"
          aria-controls="ex2-tabs-1"
          aria-selected={activeTab === index + 1 }
          onClick={() => handleTabClick(index + 1 ,el.batch_id)}
       >
          {el.batch_name}
        </a>
      </li>


    ))  ) : (

       <li className="nav-item" role="presentation">
        <a
          className={activeTab === 1 ? "nav-link active" : "nav-link"}
          // id="ex2-tab-1"
          data-mdb-toggle="tab"
          href="javascript:void(0)"
          role="tab"
          aria-controls="ex2-tabs-1"
          aria-selected={activeTab === 1 }
        //   onClick={() => handleTabClick(1)}
       >
          No batch found 
        </a>
      </li>

    )} </ul>
        </div>
    
      </div>
      <TestPerformance activeTab={activeTab} batchId={batchId} />

    </div>
  );
}

export default TestPerformanceTablist;
