import React from "react";
import "./Question.css";

//display type 2 means - multiple option with name of parent -  mcq design
// sample design
// consider the following pairs:
// port           // wel known
// 1)kamarajar port : first major port in india
// 2)mundra port : largest privately owned port in india
// 3)vishapatinam port : largest container port in india
// how many of above pair is currently matched
//  (A) only one pair   //  (B) only two pairs
//  (C) All three pairs   //  (D) None of above

function QtypeThree(props) {
  const { index } = props;

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  return (
    <div className=" Question_temp">
      {props.list.en_question !==null && props.list.en_question !=="" && (
        <div className="row">
          <div className="col-md-12">
            <span className="question_labels">
              {EsplitLines.length > 0 ? EsplitLines[0] : ""}
            </span>
          </div>
          {EsplitLines.length > 1 && (

<div className="col-md-4">
<div className="row">
  {EsplitLines.map((line, index) => {
    if (index !== 0 && (index === 1 || index === 2)) {
      return (
        <div className="col-md-6 text-center">
          <span className="question_labels">{line}</span>
        </div>
      );
    }
  })}{" "}
</div>
</div>)}
         
          {EsplitLines.map((line, index) => {
            if (
              line.trim() !== "" &&
              index !== 0 &&
              index != 1 &&
              index != 2 &&
              index !== splitLines.length - 1
            ) {
              return (
                <div className="col-md-12 mt-2">
                  <span className="question_labels">{line}</span>
                </div>
              );
            }
          })}
          <div className="col-md-12 mt-2">
            <div className="row">
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_1)}
                    name={`q_answer_${index}`}
                    value={props.list.options_en_1}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(A) {props.list.options_en_1} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_2)}
                    value={props.list.options_en_2}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(B) {props.list.options_en_2} </span>
                </div>
              </div>
           
            </div>

            <div className="row mt-3">
            <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_3)}
                    value={props.list.options_en_3}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(C) {props.list.options_en_3} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_4)}
                    name={`q_answer_${index}`}
                    value={props.list.options_en_4}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(D) {props.list.options_en_4} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.list.tm_question !==null && props.list.tm_question !=="" && (
        <div className="row tamilQues">
          <div className="col-md-12">
          <span className="question_labels">
              {splitLines.length > 0 ? splitLines[0] : ""}
            </span>
            {/* <h5>
              {props.list.en_question !==null && props.list.en_question !==""
                ? ""
                : index + 1}
              {splitLines[0]}
            </h5> */}
          </div>
          {splitLines.length > 1 && (
            <div className="col-md-12">
            <div className="row">
            {splitLines.map((line, index) => {
    if (index !== 0 && (index === 1 || index === 2)) {
      return (
        <div className="col-md-6 text-center">
          <span className="question_labels">{line}</span>
        </div>
      );
    }
  })}
            </div>
          </div>
          )}
          
          {splitLines.map((line, index) => {
            if (line.trim() !== "" &&
            index !== 0 &&
            index != 1 &&
            index != 2 &&
            index !== splitLines.length - 1) {
              return (
                <div className="col-md-12 mt-2">
                 <span className="question_labels">{line}</span>
                </div>
              );
            }
          })}

          <div className="col-md-12 mt-2">
            <div className="row">
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_1)}
                    name={`q_answer_${index}`}
                    value={props.list.options_tm_1}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(A) {props.list.options_tm_1} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_2)}
                    value={props.list.options_tm_2}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(B) {props.list.options_tm_2} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_3)}
                    name={`q_answer_${index}`}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    value={props.list.options_tm_3}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(C) {props.list.options_tm_3} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_4)}
                    className="radioInput"
                    name={`q_answer_${index}`}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    value={props.list.options_tm_4}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(D) {props.list.options_tm_4} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QtypeThree;
