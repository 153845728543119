import React, { useState, useEffect } from "react";
// import MyCalendar from "../CourseBatch/CalendarView";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix } from "../../../../labels";
import ViewCalendar from "../Purchase/Calendar";
import Swal from "sweetalert2";

export default function ViewTestDetail() {
  const { event_id, batch_id, status, event_type } = useParams();
  const currentDate = moment();
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [event_id]);

  const [updateStatus, setUpdateStatus] = useState(status);

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot available in localstorge");
  }

  useEffect(() => {

    AxiosInstance.post("api/test/view/byUser", {
      event_id: parseInt(event_id),
      // status:parseInt(status),
      batch_id: parseInt(batch_id),
      user_id: userid,
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          let el = res.data.data;
          setFdata((prevFdata) => ({
            ...prevFdata,
            title: el.event_name,
            sDate:
              el.start_date !==null
                ? moment(el.start_date).format("YYYY-MM-DD")
                : "",
            startTime: el.start_time,
            endTime: el.end_time,
            subject: el.subject,
            obtained_marks: el.marks_obtained,
            rank: el.rank,
            total_answer: el.no_of_answered,
            teams_url: el.teams_url,
            batch_id: el.batch_id,
            event_id: el.id,
            test_num: el.test_no,
            test_topic: el.test_topic,
            tol_mark: el.tot_marks,
            no_ques: el.no_of_questions,
          }));
        }
      })
      .catch((error) => {
        return error;
      });

  }, []);






  const [fdata, setFdata] = useState({
    title: "",
    sDate: "",
    startTime: "",
    endTime: "",
    subject: "",
    obtained_marks: "",
    rank: "",
    total_answer: "",
    teams_url: "",
    batch_id: "",
    event_id: "",
    test_num: "",
    test_topic: "",
    tol_mark: "",
    no_ques: "",
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const currentTime = moment();
  const startTime = moment(fdata.startTime, 'HH:mm:ss');
  const endTime = moment(fdata.endTime, 'HH:mm:ss');
  // console.log(currentTime,"currentTime");
  useEffect(() => {
    const interval = setInterval(() => {
      const updatedButtonDisabled = moment().isBefore(startTime) || moment().isAfter(endTime);
      setIsButtonDisabled(updatedButtonDisabled);
    }, 1000); // Update the disabled state every second

    return () => clearInterval(interval); // Clean up the interval when the component unmounts

  }, [startTime, endTime]);


  useEffect(() => {
    const startTime = moment(fdata.startTime, 'HH:mm:ss');
    const endTime = moment(fdata.endTime, 'HH:mm:ss');
    if (
      moment(fdata.sDate).isAfter(currentDate, "day") ||
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(startTime, "HH:mm:ss").isAfter(currentDate, "minute") &&
        moment(endTime, "HH:mm:ss").isAfter(currentDate, "minute"))
    ) {
      // console.log(endTime,"endTime")
      // Event is in the future
      setUpdateStatus("2");
      // navigate(`/ViewTestDetail/${props.batch_id}/${id}/2/${event_type}`);
    } else if (
      (moment(currentDate).isSame(fdata.sDate, "day") && 
       moment(startTime, "HH:mm:ss").isSame(currentDate, "minute"))  || 
       (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(startTime, "HH:mm:ss").isBefore(currentDate, "minute") &&
        moment(endTime, "HH:mm:ss").isAfter(currentDate, "minute"))
    ) {
      // console.log(endTime,"sds")
      // Event is ongoing
      setUpdateStatus("1");
      // navigate(`/ViewTestDetail/${props.batch_id}/${id}/1/${event_type}`);
    }else if (
      (moment(currentDate).isSame(fdata.sDate, "day") && 
       moment(endTime, "HH:mm:ss").isSame(currentDate, "minute"))  || 
       (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(startTime, "HH:mm:ss").isBefore(currentDate, "minute") &&
        moment(endTime, "HH:mm:ss").isAfter(currentDate, "minute"))
    ) {
      // console.log(endTime,"sdsdf")
      // Event is in the past
      setUpdateStatus("0");
    } else if (
      moment(fdata.sDate).isBefore(currentDate, "day") ||
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(endTime, "HH:mm:ss").isBefore(currentDate, "minute"))
    ) {
      // console.log(endTime,"nmnm")
      // Event is in the past
      setUpdateStatus("0");
    } else{
      setUpdateStatus("0");
    }
     
  }, [startTime, endTime])
  

  const handleReviewSubmission = () => {
    navigate(`/ReviewSubmission/${batch_id}/${event_id}`)
  }

  const handleQuiz = () => {
    let dt = {
      batch_id: parseInt(batch_id),
      event_id: parseInt(event_id),
      user_id: userid,
    };
    // console.log(dt,"dt");
    AxiosInstance.post("api/test/start", dt)
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          let startTime = res.data.data[0].start_time !==null ? res.data.data[0].start_time : "";
          let endTime = res.data.data[0].end_time !==null ? res.data.data[0].end_time : "";
          let batch_id = res.data.data[0].batch_id;
          let event_id = res.data.data[0].event_id;
          let no_ques = res.data.data[0].no_of_questions;
          let test_topic = res.data.data[0].test_topic;
          let tol_mark = res.data.data[0].tot_marks;
          navigate(res.data.data[0].teams_url, {
            state: {
              startTime,
              endTime,
              batch_id,
              event_id,
              no_ques,
              tol_mark,
              test_topic,
            },
          });
        } else {
          Swal.fire({
            title: "failure",
            text: res.data.message,
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  // console.log(updateStatus,"updateStatus");

  return (
    <div className="BathDet">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading  mt-3 d-flex justify-content-between">
              {updateStatus === "0" && (
                <h6 className="Tit ">
                  View Test
                </h6>
              )}
              {updateStatus === "1" && <h6 className="Tits_sss"> View Test</h6>}

              {updateStatus === "2" && <h6 className="ssTits_sss"> View Test</h6>}

              <Link
                to="javascript:void(0)"
                onClick={navigateToPreviousScreen}
                className="CancelButton"
              >
                Back to Schedule
              </Link>

              {/* && fdata.obtained_marks !=""  */}
              {updateStatus === "0" 
              // &&
              //   (moment(fdata.sDate).isBefore(currentDate, "day") ||
              //     (moment(currentDate).isSame(fdata.sDate, "day") &&
              //       moment(fdata.endTime, "HH:mm:ss").isBefore(currentDate, "minute")))
                && (
                  <button
                    type="button"
                    className="EditEventButtonss"
                    onClick={
                      fdata.obtained_marks !==null ? handleReviewSubmission : ""
                    }
                  >
                    {" "}
                    <span class="material-icons me-1">visibility</span> Review
                    Submission
                  </button>
                )}

              {/* {status === "1" && (
                <button type="button" className="EditEventButtons" disabled={isButtonDisabled ? "disabled" : ""} onClick={handleQuiz}>
                  {" "}
                  <span class="material-icons me-2">event_available</span> Join
                  Test
                </button>

              )} */}

              {updateStatus === "1" 
              // &&
              //   ((moment(currentDate).isSame(fdata.sDate, "day") &&
              //     moment(fdata.startTime, "HH:mm:ss").isSame(
              //       currentDate,
              //       "minute"
              //     )) ||
              //     (moment(currentDate).isSame(fdata.sDate, "day") &&
              //       moment(fdata.startTime, "HH:mm:ss").isBefore(
              //         currentDate,
              //         "minute"
              //       ) &&
              //       moment(fdata.endTime, "HH:mm:ss").isAfter(
              //         currentDate,
              //         "minute"
              //       ))) &&
              //   ((moment(currentDate).isSame(fdata.sDate, "day") &&
              //     moment(fdata.endTime, "HH:mm:ss").isSame(
              //       currentDate,
              //       "minute"
              //     )) ||
              //     (moment(currentDate).isSame(fdata.sDate, "day") &&
              //       moment(fdata.startTime, "HH:mm:ss").isBefore(
              //         currentDate,
              //         "minute"
              //       ) &&
              //       moment(fdata.endTime, "HH:mm:ss").isAfter(
              //         currentDate,
              //         "minute"
              //       )))
                     && (
                  <button
                    type="button"
                    className="EditEventButtons"
                    disabled={isButtonDisabled ? "disabled" : ""}
                    onClick={handleQuiz}
                  >
                    {" "}
                    <span className="material-icons me-2">
                      event_available
                    </span>{" "}
                    <a href="javascript:void(0)">Join Test</a>
                  </button>
                )}
            </div>
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Test Details</p>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Event Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.title !==null && fdata.title !==""
                          ? fdata.title
                          : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Test Number
                      </label>
                      <span className="ReviewLabel">
                        {fdata.test_num !==null && fdata.test_num !==""
                          ? fdata.test_num
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Subject
                      </label>
                      <span className="ReviewLabel">
                        {fdata.subject !==null && fdata.subject !==""
                          ? fdata.subject
                          : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Test Topic
                      </label>
                      <span className="ReviewLabel">
                        {fdata.test_topic !==null && fdata.test_topic !==""
                          ? fdata.test_topic
                          : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Date
                      </label>
                      <span className="ReviewLabel">
                        {fdata.sDate !==null && fdata.sDate !==""
                          ? fdata.sDate
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Start Timing
                      </label>
                      <span className="ReviewLabel">
                        {fdata.startTime !==null && fdata.startTime !==""
                          ? moment(fdata.startTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        End Timing
                      </label>
                      <span className="ReviewLabel">
                        {fdata.endTime !==null && fdata.endTime !==""
                          ? moment(fdata.endTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Total Number of Questions
                      </label>
                      <span className="ReviewLabel">
                        {fdata.no_ques !==null && fdata.no_ques !==""
                          ? fdata.no_ques
                          : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Total Marks
                      </label>
                      <span className="ReviewLabel">
                        {fdata.tol_mark !==null && fdata.tol_mark !==""
                          ? fdata.tol_mark
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  {updateStatus === "0" && (
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Number of Questions Answered
                        </label>
                        <span className="ReviewLabel">
                          {fdata.total_answer !==null &&
                            fdata.total_answer !==""
                            ? fdata.total_answer
                            : "⎯"}
                        </span>
                      </div>
                    </div>
                  )}

                  {updateStatus === "0" && (
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Obtained Marks
                        </label>
                        <span className="ReviewLabel">
                          {fdata.obtained_marks !==null
                            ? fdata.obtained_marks
                            : "⎯"}
                        </span>
                      </div>
                    </div>
                  )}

                  {updateStatus === "0" && (
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Rank
                        </label>
                        <span className="ReviewLabel">
                          {fdata.rank !==null && fdata.rank !==""
                            ? fdata.rank
                            : "⎯"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
