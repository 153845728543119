import React from 'react'
import logo from '../../../img/AIASA.png'
import IconLogo from '../../../img/IconLogo.png'
import { Link } from 'react-router-dom';
import Image from "../../../img/img1.png";
import Video from "../../../img/india.mp4";
import './Resetpassword.css'
function Resetpassword() {
  return (
    <>
     <section className="container-fluid ForgetForm videoWrapper"  id="wrapper_Forget">
        <video poster={Image} autoPlay muted loop>
          <source src={Video} type="video/mp4" />
        </video>

        <div className="row">
        <div className="col-md-12 box" id='boxs'>
       
            <img src={IconLogo} id="img" alt="Logo" />
            <h1 className="">Password Reset Successful</h1>
            <p>Back in business.<br/>Stay secure and stay connected.</p>
            <div className="BackLogin"><Link to="/login"><i class="fa fa-long-arrow-left" id="MIcons"></i>Back to Log in</Link></div>
          <div className="copyRights">© 2023 All Rights Reserved</div>
        </div>
      </div>
        </section>

    </>
  );
}

export default Resetpassword;