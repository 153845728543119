import "./Faculty.css";

import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState  , useRef  } from "react";

import { AxiosInstance } from "../../../../axios";
import CategorySubject from "./CategorySubject";
import Swal from "sweetalert2";
import moment from "moment";
import { validatePanelMember } from "./validation";
import AddJobInfo from "./AddJobInfo";
import intlTelInput from 'intl-tel-input';

function AddPanelMember() {

  
  const txtPanelFName = useRef(null);
  const txtPanelLName = useRef(null);
  const txtPanelPno = useRef(null);
  const txtPanelEmail = useRef(null);
  const txtPanelStatus = useRef(null);
  const txtPanelDes= useRef(null);
  const txtPanelDept = useRef(null);



  
  const { userId} = useParams();
  const navigate = useNavigate();
  const [fData, setFormData] = useState({
    fname: "",
    lname: "",
    phoneNumber: "",
    phone_code:"+91",
    email: "",
    status:"",
    department:"",
    designation: "",

  });
  const [category, setCategory] = useState([]);
  const [addJob, setAddJob] = useState([
    { 
        id : 1,
        department : "",
        exp_designation:""

    }
  ]);
  const [error, setError] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [cate, setCate] = useState([]);
const [department, setDepartment] = useState([]);
  const [subject, setSubject] = useState([]);

 
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  useEffect(() => {
    AxiosInstance.get("api/all/getDesignation/3")
    .then((res) => {
      // const designation = res.data.data.sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
      //   <option key={item.name} value={item.name}>
      //     {item.name}
      //   </option>
      // ));
      const designation = res.data.data
      .sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => ({
        value: item.name,
        label: item.name,
      }));
      setDesignation(designation);
    })
    .catch((error) => {
      return error;
    });

  AxiosInstance.get("api/all/getDepartment/3")
    .then((res) => {
      // const department = res.data.data.map((item) => (
      //   <option key={item.name} value={item.name}>
      //     {item.name}
      //   </option>
      // ));
      // setDepartment(department);
      // const sortedDepartments = res.data.data
      // .sort((a, b) => a.name.localeCompare(b.name)) // Sort array of objects by name
      // .map((item) => (
      //     <option key={item.name} value={item.name}>
      //         {item.name}
      //     </option>
      // ));
      const sortedDepartments = res.data.data
      .sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => ({
        value: item.name,
        label: item.name,
      }));
     setDepartment(sortedDepartments);
    })
    .catch((error) => {
      return error;
    });

       // Initialize intl-tel-input on component mount
       const iti = intlTelInput(txtPanelPno.current, {
        // Add options as needed, refer to the intl-tel-input documentation
        initialCountry: 'in',
        separateDialCode: true,
      });
  
      // You can listen for changes if needed
      txtPanelPno.current.addEventListener('countrychange', () => {
        // console.log('Country changed:', iti.getSelectedCountryData());
        const selectedCountryData = iti.getSelectedCountryData();
        setFormData((prevFields) => ({
          ...prevFields,
          phone_code: `+${selectedCountryData.dialCode}`, // Update phone number with the new country code
        }));
      });
  
      // Clean up on component unmount
      return () => {
        iti.destroy();
      };


  }, []);




  useEffect(() => {
    AxiosInstance.post("api/staff/view", { user_id: userId })
      .then((res) => {
        // console.log(res, "findbyid");
        if (res.data.status === true) {
          setFormData({
            fname: res.data.data.first_name,
            lname: res.data.data.last_name,
            phoneNumber: res.data.data.mobile_no,
            phone_code: res.data.data.country_code,
            email: res.data.data.email,
            status : res.data.data.status,
          });
          const addj = res.data.data.subject.map((item, index) => ({
                id: index + 1,
                department: item.department,
                exp_designation: item.exp_designation,
              }));
              // Updating the state with the modified data
              setAddJob(addj);
        }
      })
      .catch((error) => {
        return error;
      });
  }, [userId]);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  
  const DeleteAddJobinfo = (id, name) => {
    // const userJSON = localStorage.getItem("userDetails");
    // if (userJSON) {
    //   const user = JSON.parse(userJSON);
    //   if (user && user.user_id) {
    //     const userid = user.user_id;
    //     AxiosInstance.post("api/course/admission/delete/scp", {
    //       user_id: userid,
    //       name: name,
    //       id: id,
    //     })
    //       .then((res) => {
    //         if (res.status === 200) {
             
    //           // Swal.fire({
    //           //   title: "success",
    //           //   text: "Deleted Successfully",
    //           //   icon: "success",
    //           //   confirmButtonText: "Ok",
    //           // }).then((result) => {
    //           //   if (result.isConfirmed) {

    //           //   }
    //           // });
    //         }
    //       })
    //       .catch((error) => {
    //         return error;
    //       });
    //   }
    // } else {
    //   console.log("User Id cannot avaliable in localstorge");
    // }

    setAddJob((prevState) =>
    prevState
      .filter((item) => item.id !== id)
      .map((item, index) => ({ ...item, id: index + 1 }))
  );

  };

  const handleAddJobinfo = () => {
    setAddJob((prevState) => [
      ...prevState,
      {
        id: prevState.length > 0 ? prevState.length + 1 : 1,
        department: "",
        exp_designation: "",
      },
    ]);
  }

  const handleChangeAjob = (e,id) => {
    const { name, value } = e.target;
    setAddJob((prevState) => {
      return prevState.map((q) => {
        if (q.id === id) {
          return { ...q, [name]: value };
        }
        return q;
      });
    });
  }

  const handleChangeAjobs = (option,id,name) => {
    // const { name, value } = e.target;
    setAddJob((prevState) => {
      return prevState.map((q) => {
        if (q.id === id) {
          return { ...q, [name]: option.value };
        }
        return q;
      });
    });
  }

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "category") {
      if (checked) {
        setCategory([...category, value]);
      } else {
        setCategory(category.filter((item) => item !== value));
      }
      setError({
        ...error,
        [name]: "",
      });
    }
    else if(name === "phoneNumber"){

      if (value.length === 10 || value.length === 0) {
        setFormData((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setError({
          ...error,
          ['phoneNumber']: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setFormData((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setError({
          ...error,
          ['phoneNumber']: "Please Enter valid 10 digit number",
        });
        return;
      }

    } 
    else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    }
  };
  const handleSubmit = (e) => {
    // e.preventDefault(); // Prevent the default form submission behavior
    const useref_panelmembers = {
      firstName: txtPanelFName.current,
      lastName: txtPanelLName.current,
      phoneNumber: txtPanelPno.current,
      email: txtPanelEmail.current,
      status: txtPanelStatus.current,
    };
    // console.log(addJob);
    const errors = validatePanelMember(fData,useref_panelmembers,addJob);
    if (Object.keys(errors).length === 0) {
      try {
        if (userId) {
          const mappedDatas = addJob.map((item) => ({
            "department": item.department,
            "exp_designation": item.exp_designation
          }));
            
          const updateStaff = {
            user_id:userId,
            first_name: fData.fname,
            last_name: fData.lname,
            mobile_no: fData.phoneNumber,
            country_code: fData.phone_code,
            email: fData.email,
            staff_type: 3,
            job_info : mappedDatas,
            // exp_designation: fData.designation,
            status:parseInt(fData.status),
            // department:fData.department,
            created_by: userid,
            updated_by:userid
          }

          // console.log(updateStaff,"updateStaff");
         
          AxiosInstance.post("api/staff/register/edit", updateStaff)
          .then((res) => {
           if (res.data.status === true) {
            Swal.fire({
              title: 'Success',
              text: res.data.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                navigateToPreviousScreen();
              }
            });
           }
          })
          .catch((error) => {
            return error;
          });
        } else {

          const firstItem = addJob[0];
          if (firstItem.department !== "" && firstItem.exp_designation !== "") {
            const mappedData = addJob.map((item) => ({
              "department": item.department,
              "exp_designation": item.exp_designation
            }));
            const createStaff = {
              first_name: fData.fname,
              last_name: fData.lname,
              mobile_no: fData.phoneNumber,
              country_code: fData.phone_code,
              email: fData.email,
              staff_type: 3,
              job_info :mappedData,
              // exp_designation: fData.designation,
              status:parseInt(fData.status),
              // department:fData.department,
              created_by: userid,
            };
            // console.log(createStaff, "createStaff");
            AxiosInstance.post("api/user/register/staff", createStaff)
            .then((res) => {
             if (res.data.status === true) {
              Swal.fire({
                title: 'Success',
                text: res.data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                  navigateToPreviousScreen();
                }
              });
             }else{
              Swal.fire({
                title: 'warning',
                text: res.data.message,
                icon: 'warning',
                confirmButtonText: 'Ok'
              })
             }
            })
            .catch((error) => {
              return error;
            });
            
          } else {
            alert("Please select job info");
          }
         
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };
  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  // console.log(addJob);
  return (
    <div className="Faculty">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne ">
            <div className="heading  mt-3 dflexbetww">
            <div className="dbetw">
            {userId ? (
                <h4 className="Titles">Edit Panel Member</h4>
              ) : (
                <h4 className="Titles">Add New Panel Member</h4>
              )}
              <Link to="javascript:void(0)" onClick={navigateToPreviousScreen} className="CancelButton MobileShow">
                Back to Faculties
              </Link>
            </div>

            <div className="d-flex smButton  mobiledown ">
            <Link to="javascript:void(0)" onClick={navigateToPreviousScreen} className="CancelButton MobileHide">
                Back to Faculties
              </Link>
              <button
                type="button"
                className="SaveButton"
                onClick={handleSubmit}
              >
                <a href="javascript:void(0)"> {userId ? "Edit Faculty Profile" : "Save Faculty Profile"}  </a>
              </button>
            </div>
            </div>
            <div className="row box ">
              <div className="col-md-12">
                <p className="StepTitle">Basic Details</p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="fname">First Name:</label>
                      <input
                        ref={txtPanelFName}
                        type="text"
                        class="Inputs"
                        id="fname"
                        name="fname"
                        value={fData.fname}
                        onChange={handleChange}
                        placeholder="First Name"
                        required
                      />
                      {error.fname && (
                        <small className="error">{error.fname}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="lname">Last Name:</label>
                      <input
                          ref={txtPanelLName}
                        type="text"
                        class="Inputs"
                        id="lname"
                        name="lname"
                        value={fData.lname}
                        onChange={handleChange}
                        placeholder="Last Name"
                        required
                      />
                      {error.lname && (
                        <small className="error">{error.lname}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="phoneNumber">Phone Number:</label>
                      <input
                          ref={txtPanelPno}
                        type="tel"
                        class="Inputs_Phone"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={fData.phoneNumber}
                        onChange={handleChange}
                        placeholder="Phone Number"
                        required
                        minLength={10}
                        maxLength={10}
                        inputMode="tel"
                      />
                      {error.phoneNumber && (
                        <small className="error">{error.phoneNumber}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="email">Email ID:</label>
                      <input
                         ref={txtPanelEmail}
                        type="email"
                        class="Inputs"
                        id="email"
                        name="email"
                        disabled={userId ? true : false}
                        value={fData.email}
                        onChange={handleChange}
                        placeholder="Email ID"
                        required
                      />
                      {error.email && (
                        <small className="error">{error.email}</small>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="status">Status:</label>
                      <select
                         ref={txtPanelStatus}
                        class="Inputs"
                        id="status"
                        name="status"
                        value={fData.status}
                        onChange={handleChange}
                      >
                        <option value="" disabled>Please select status</option>
                        <option value="4">Active</option>
                        <option value="3">Inactive</option>
                      </select>
                      {error.status && (
                        <small className="error">{error.status}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="StepOne ">
            {/* <div className="row box ">
              <div className="col-md-12">
                <p className="StepTitle">Job Info</p>
                <div className="row  mb-4">
                <div className="col-md-6">
                    <div className="forms ">
                      <label for="department">Department:</label>
                      <select
                       ref={txtPanelDept}
                        class="Inputs"
                        id="department"
                        name="department"
                        value={fData.department}
                        onChange={handleChange}
                      >
                        <option value="" disabled>Please select department</option>
                        {department}
                      </select>

                      {error.department && (
                        <small className="error">{error.department}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="designation">Designation:</label>
                      <select
                       ref={txtPanelDes}
                        class="Inputs"
                        id="designation"
                        name="designation"
                        value={fData.designation}
                        onChange={handleChange}
                      >
                        <option value="" disabled>Please select designation</option>
                        {designation}
                      </select>

                      {error.designation && (
                        <small className="error">{error.designation}</small>
                      )}
                    </div>
                  </div>
                </div>
               
              </div>
            </div> */}

                    {addJob.map((item, index) => (
                        <AddJobInfo
                          key={index.toString()}
                          record={item}
                          // error = {error}
                          length={addJob.length}
                          handleChangeAjob={handleChangeAjob}
                          handleChangeAjobs={handleChangeAjobs}
                          department={department}
                          designation = {designation}
                          DeleteAddJobinfo={DeleteAddJobinfo}
                          handleAddJobinfo={handleAddJobinfo}
                        />
                      ))}

            <div className="heading me-4 mt-3 dflex">
              <Link to="javascript:void()" onClick={navigateToPreviousScreen} className="CancelButton">
                Back to Faculties
              </Link>
              <button
                type="button"
                className="SaveButton mobileTop"
                onClick={handleSubmit}
              >
                <a href="javascript:void(0)"> {userId ? "Edit Faculty Profile" : "Save Faculty Profile"}  </a>
               
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
export default AddPanelMember;


