import React, { useState,useEffect } from "react";

export const UserContext = React.createContext();


const UserDetailsContextProvider = (props) => {

const [user, setUser] = useState([]);

  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      setUser(JSON.parse(userDetails));
    } else {
      setUser("");
    }
  }, []);

  //login user details

  const loginUser = (arg, callback) => {
    localStorage.setItem("userDetails", JSON.stringify(arg));
    setUser(arg);
    callback();
  } 
 //logout user details
  const logout = () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("user_id");
    localStorage.removeItem('role_id');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('rzp_checkout_anon_id');
    setUser("");
    sessionStorage.removeItem('stateRefreshs', true);
    sessionStorage.removeItem('stateRefreshs_test', true);
    sessionStorage.removeItem('stateRefreshs_spl', true);
    sessionStorage.removeItem('stateRefreshs_model', true);
    sessionStorage.removeItem('stateRefreshs_mock', true);
    sessionStorage.removeItem('stateRefreshs_coun', true);
    sessionStorage.removeItem("userDetails", true);
  }

  //children props or redirect url navigate here 


return <UserContext.Provider value={{user:user,login:loginUser,logout:logout}}>
    
        {props.children}

     </UserContext.Provider>

}

export default UserDetailsContextProvider;
