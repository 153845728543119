import React, { useState, useEffect } from "react";
// import MyCalendar from "../CourseBatch/CalendarView";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix } from "../../../../labels";
import TestCalendar from "./TestCalendar";


export default function TestViewSchedule() {
  const { batch_id , batch_type } = useParams();
  const [batch, setBatch] = useState([
    {
      batch_id: "",
      category:"",
      img_url: "",
      course_name: "",
      course_id: "",
      batch_details: "",
      start_time:"",
      end_time:"",
      batch_type:"",
      class_mode: "",
      start_on: "",
      medium:"",
      duration: "",
      act_c_fee: "",
      batch_name : "",
    },
  ]);

  const [mockPanel, setMockPanel] = useState([
    {
      id: 1,
      panel_name: "",
      available_dates: [],
      panelMem: [],
      staffMem: [],
      fees: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
      error_5: "",
    },
  ]);

  // panel details for Counselling

  const [cPanel, setCPanel] = useState([
    {
      id: 1,
      panel_name: "",
      date: "",
      StartTime: "",
      EndTime: "",
      department: [],
      CPaneMem: [],
      CStaffM: [],
      fees: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
      error_5: "",
      error_6: "",
      error_7: "",
      error_8: "",
    },
  ]);


  const [eventFields, setEventFields] = useState([]);
  // load data 
  useEffect(() => {
    AxiosInstance.post("/api/batch/findById", {
      id: parseInt(batch_id),
      start_date: moment().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
            if (Object.keys(res.data.data.batch_info).length > 0) {
              const item = res.data.data.batch_info;
              const batch ={
                batch_id: item.id,
                category:item.category,
                batch_name : item.name,
                img_url: item.img_url,
                course_name: item.category_class,
                course_id: item.category_course_id,
                batch_details: item.batch_details,
                medium:item.medium,
                start_time:item.start_time ,
                end_time:item.end_time,
                batch_type:item.batch_type,
               class_mode: item.class_mode,
              start_on: item.started_dt,
              duration: item.duration,
              act_c_fee: item.fees,
             
              };
              setBatch([batch]);
                // res.data.data.batch_info.map((item) => {
            
                // }
               
                // );
              }
              if (res.data.data.event_list.length > 0) {
                const parsedEvents = res.data.data.event_list.map((event) => {
                  const startDateStr = `${event.start_date.split("T")[0]}T${
                    event.start_time
                  }`;
                  const endDateStr = `${event.start_date.split("T")[0]}T${
                    event.end_time
                  }`;
                  const startDate = moment(startDateStr).toDate();
                  const endDate = moment(endDateStr).toDate();
                  return {
                    id: event.id,
                    type:event.event_type,
                    title: event.name,
                    start: startDate,
                    end: endDate,
                    url : event.teams_url,
                    subject : event.subject,
                    staff_name : event.staff_name,
                    startTime :event.start_time,
                    endTime : event.end_time,
                    description : event.description,
                    syllabus_filepath : event.syllabus_filepath,
                  };
                });
                setEventFields(parsedEvents);
              }

              if (Object.keys(res.data.data.batch_panels).length > 0) {
                if(res.data.data.batch_panels.panel_data.length > 0){
                  const updatedMockPanel = [...mockPanel];
                  const updatedCPanel = [...cPanel];
                 res.data.data.batch_panels.panel_data.forEach((el,index) => {
                  let panelMem = [];
                  let staffMem = [];
                  let department = [];
                  let CPaneMem = [];
                  let CStaffM = [];
                   if(el.panel_mebers_list.length > 0){
                           el.panel_mebers_list.map((ko) => {
                              if(ko.type === 3){
                                // panelMem
                                panelMem.push({
                                  staff_id: ko.panel_member_id,
                                  name: ko.panel_member_name,
                                  type: ko.type,
                                  department:ko.department !== "" ? ko.department.split(',') : [],
                                });
                                CPaneMem.push({
                                  staff_id: ko.panel_member_id,
                                  name: ko.panel_member_name,
                                  type: ko.type,
                                  department: ko.department !== "" ? ko.department.split(',') : [],
                                });
                                if(ko.department !== "" ){
                                  const bbd = ko.department.split(',');
                                  // console.log(bbd,"bbd");
                                  department.push(bbd);
                                 }
                              }
                           else if(ko.type === 0){
                          //  StaffMem
                             staffMem.push({
                             staff_id: ko.panel_member_id,
                             name: ko.panel_member_name,
                             type: ko.type,
                             department:"",
                          });
                          CStaffM.push({
                            staff_id: ko.panel_member_id,
                            name: ko.panel_member_name,
                            type: ko.type,
                            department:"",
                         });
                        
                        } 
                      
                      })  }
                   updatedMockPanel[index] = {
                    ...updatedMockPanel[index],
                    id:index+1,
                    panel_name: el.panel_name, // Update other properties as needed
                    available_dates: el.available_dates, 
                    panelMem,
                    staffMem,
                    error_1: "",
                    error_2: "",
                    error_3: "",
                    error_4: "",
                    error_5: "",
                  };
      
                  updatedCPanel[index] = {
                    ...updatedCPanel[index],
                    id:index+1,
                    panel_name: el.panel_name, // Update other properties as needed
                    date: el.available_dates, 
                    department,
                    StartTime: el.start_time,
                    EndTime: el.end_time,
                    CPaneMem,
                    CStaffM,
                    error_1: "",
                    error_2: "",
                    error_3: "",
                    error_4: "",
                    error_5: "",
                    error_6: "",
                    error_7: "",
                    error_8: "",
                  };
              });
              setMockPanel(updatedMockPanel);
              setCPanel(updatedCPanel);
                }
             
              }
  
              if (Object.keys(res.data.data.batch_panels).length > 0) {
                if(res.data.data.batch_panels.exam_fees.length > 0 ){
                  res.data.data.batch_panels.exam_fees.map(
                    (el, index) => {
                     setMockPanel((prevState) =>
                        prevState.map((q) => {
                          if (q.id === el.fees_count  ) {
                            return { ...q, fees: el.fees }; // Update the fees property
                          }
                          return q;
                        })
                      );
                      setCPanel((prevState) =>
                      prevState.map((q) => {
                        if (q.id === el.fees_count  ) {
                          return { ...q, fees: el.fees }; // Update the fees property
                        }
                        return q;
                      })
                    );
                    }
                  );
                }
         
              }
        }
      })
      .catch((error) => {
        return error;
      });
  }, [batch_id]);
  return (
    <div className="Purchase">
      <div className="row">
        <div className="col-md-12">
          <section className=" sectionSix">
            <div className="heading d-flex justify-content-between ">
              <div className="dbetw">
                <h4 className="Title_ll mt-4 ">View Schedule</h4>
                <Link to="/"  className="CancelButton MobileShow">
                  Back to Dashboard
                </Link>
              </div>
              <div className="d-flex smButton  mt-3">
                <Link to="/"  className="CancelButton MobileHide">
                  Back to Dashboard
                </Link>
              </div>
            </div>
            <div className="row box ms-0 me-0">
              <div className="col-md-12">
                <p className="StepTitle">Batch Details</p>
                {batch_type !=="4" && batch_type !=="5" && batch_type !=="2" && (

batch.map((el, index) => (
  <div className="row" key={index}>
    <div className="col-md-12 mt-3 mb-3">
      <div className="card  text-dark">
        <div className="card-body BatchImage">
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-12">
              <img
                src={`${ImageUrlPrefix}/${el.img_url}`}
                id={el.batch_id}
                alt="Imge"
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column ImageText  justify-content-center">
              <p className="title m-0 ">{el.course_name}{"-"}{el.batch_name}</p>
              <p className="subtitle mt-1 fw-bold"> {el.batch_type} Batch - {moment(el.start_time, "HH:mm:ss").format("h:mm A")} to {moment(el.end_time, "HH:mm:ss").format("h:mm A")}</p>
            </div>
            <div className="col-lg-7 col-md-10 col-sm-12 col-xl-7">
              <div className="row mt-4">
                <div className="col-md-12 col-sm-12 ">
                  <div className="row">
                  <div className="col-md-6">
                       <li className="IconsImage">
                         <span className="material-icons">
                           laptop_chromebook
                         </span>
                         <div className="d-flex flex-column">
                           <p className="title ms-1">
                             ClassMode
                           </p>
                           <p className="subtitle fw-bold ms-2">
                             {el.class_mode !=null && el.class_mode !="" ? el.class_mode : "⎯"}
                           </p>
                         </div>
                       </li>
                     </div>
                    <div className="col-md-6">
                      <li className="IconsImage">
                        <span className="material-icons">
                        g_translate
                        </span>
                        <div className="d-flex flex-column">
                          <p className="title ms-1">Medium of Instruction</p>
                          <p className="subtitle  fw-bold ms-2">{el.medium !=null && el.medium !="" ? el.medium :"⎯" } </p>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
))) }
           
           {batch_type ==="2" && (
batch.map((el, index) => (
  <div className="row" key={index}>
    <div className="col-md-12 mt-3 mb-3">
      <div className="card  text-dark">
        <div className="card-body BatchImage">
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-12">
              <img
                src={`${ImageUrlPrefix}/${el.img_url}`}
                id={el.batch_id}
                alt="Imge"
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column ImageText  justify-content-center">
              <p className="title m-0 ">{el.course_name}{"-"}{el.batch_name}</p>
              {cPanel.map((el, index) => (
                 <p className="subtitle mt-1 fw-bold"  key={index}>
               {el.panel_name} - {el.StartTime}  {el.EndTime}
               </p>

  ))}
             
            </div>
            <div className="col-lg-7 col-md-10 col-sm-12 col-xl-7">
              <div className="row mt-4">
                <div className="col-md-12 col-sm-12 ">
                  <div className="row">
                  <div className="col-md-6">
                       <li className="IconsImage">
                         <span className="material-icons">
                           laptop_chromebook
                         </span>
                         <div className="d-flex flex-column">
                           <p className="title ms-1">
                             ClassMode
                           </p>
                           <p className="subtitle fw-bold ms-2">
                             {el.class_mode !=null && el.class_mode !="" ? el.class_mode : "⎯"}
                           </p>
                         </div>
                       </li>
                     </div>
                    <div className="col-md-6">
                      <li className="IconsImage">
                        <span className="material-icons">
                        g_translate
                        </span>
                        <div className="d-flex flex-column">
                          <p className="title ms-1">Medium of Instruction</p>
                          <p className="subtitle  fw-bold ms-2">{el.medium !=null && el.medium !="" ? el.medium :"⎯" } </p>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
))) }
           
                    
           {batch_type ==="5" && (
            batch.map((el, index) => (
  <div className="row" key={index}>
    <div className="col-md-12 mt-3 mb-3">
      <div className="card  text-dark">
        <div className="card-body BatchImage">
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-12">
              <img
                src={`${ImageUrlPrefix}/${el.img_url}`}
                id={el.batch_id}
                alt="Imge"
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column ImageText  justify-content-center">
              <p className="title m-0 ">{el.course_name}{"-"}{el.batch_name}</p>
              {cPanel.map((el, index) => (
                 <p className="subtitle mt-1 fw-bold"  key={index}>
               {el.panel_name}  
               </p>

  ))}
             
            </div>
            <div className="col-lg-7 col-md-10 col-sm-12 col-xl-7">
              <div className="row mt-4">
                <div className="col-md-12 col-sm-12 ">
                  <div className="row">
                  <div className="col-md-6">
                       <li className="IconsImage">
                         <span className="material-icons">
                           laptop_chromebook
                         </span>
                         <div className="d-flex flex-column">
                           <p className="title ms-1">
                             ClassMode
                           </p>
                           <p className="subtitle fw-bold ms-2">
                             {el.class_mode !=null && el.class_mode !="" ? el.class_mode : "⎯"}
                           </p>
                         </div>
                       </li>
                     </div>
                    <div className="col-md-6">
                      <li className="IconsImage">
                        <span className="material-icons">
                        g_translate
                        </span>
                        <div className="d-flex flex-column">
                          <p className="title ms-1">Medium of Instruction</p>
                          <p className="subtitle  fw-bold ms-2">{el.medium !=null && el.medium !="" ? el.medium :"⎯" } </p>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
))) }

{batch_type ==="4" && (
            batch.map((el, index) => (
  <div className="row" key={index}>
    <div className="col-md-12 mt-3 mb-3">
      <div className="card  text-dark">
        <div className="card-body BatchImage">
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-12">
              <img
                src={`${ImageUrlPrefix}/${el.img_url}`}
                id={el.batch_id}
                alt="Imge"
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column ImageText  justify-content-center">
              <p className="title m-0 ">{el.course_name}{"-"}{el.batch_name}</p>
            </div>
            <div className="col-lg-7 col-md-10 col-sm-12 col-xl-7">
              <div className="row mt-4">
                <div className="col-md-12 col-sm-12 ">
                  <div className="row">
                  <div className="col-md-6">
                       <li className="IconsImage">
                         <span className="material-icons">
                           laptop_chromebook
                         </span>
                         <div className="d-flex flex-column">
                           <p className="title ms-1">
                             ClassMode
                           </p>
                           <p className="subtitle fw-bold ms-2">
                             {el.class_mode !=null && el.class_mode !="" ? el.class_mode : "⎯"}
                           </p>
                         </div>
                       </li>
                     </div>
                    <div className="col-md-6">
                      <li className="IconsImage">
                        <span className="material-icons">
                        g_translate
                        </span>
                        <div className="d-flex flex-column">
                          <p className="title ms-1">Medium of Instruction</p>
                          <p className="subtitle  fw-bold ms-2">{el.medium !=null && el.medium !="" ? el.medium :"⎯" } </p>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
))) }
           
              </div>
            </div>
          </section>

          <section className="StepOne">
            <div className="row box ms-0 me-0">
              <div className="col-md-12">
                <p className="StepTitle">Schedule </p>
                <div className="row mt-4 mb-5">
                  <div className="col-md-12">
                    <TestCalendar batch_id={batch_id}  type={(batch_type != "1" || batch_type != 1) ? 0 : 1} category={batch.map((el)=>{ return el.category})} eventFields={eventFields} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}


