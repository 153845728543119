import "../../../../pages/AdmissionForm.css";
import Razorpay from "react-razorpay";
import React, { useState, useEffect, useRef } from "react";
import {
  Alarm,
  CurrencyRupee,
  LaptopChromebook,
  Today,
} from "@mui/icons-material";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import moment from "moment";
import Swal from "sweetalert2";
// import { toWords } from "number-to-words";
import { ToWords } from "to-words";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, PaymentSKey } from "../../../../labels";

function CashPayFee() {
  const location = useLocation();
  const navigate = useNavigate();
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });
  const { CourseDetail, PayDue, PayTab } = location.state;
  //   const { CourseDetail } = location.state;
  const txtAdPPayment = useRef(null);

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("UserId Not available");
  }

  const [dis, setDis] = useState(null);

  useEffect(() => {
    if (CourseDetail.type != "4") {
      AxiosInstance.post("api/purchase/view", {
        user_id: CourseDetail.user_id,
        admission_id: CourseDetail.adm_pk,
        type: CourseDetail.type,
      })
        .then((res) => {
          const userinfo = res.data.data;

          // console.log(userinfo,"userinfo");

          const PersonDetails = {
            FirstName: userinfo.first_name,
            LastName: userinfo.last_name,
            father_name: userinfo.father_name,
            mobile: userinfo.phone_no,
            email: userinfo.email,
            Profilephoto:
              userinfo.profile_path !== null ? userinfo.profile_path : null,
          };

          const PartialPayment = userinfo.Admission_details[0]
            ? userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].partial_payment !== null
              ? userinfo.Admission_details[0].partial_payment === 1
                ? "1"
                : "0"
              : "0"
            : userinfo.Admission_details &&
              userinfo.Admission_details.partial_payment !== null
            ? userinfo.Admission_details.partial_payment === 1
              ? "1"
              : "0"
            : "0";

          const student_id = userinfo.Admission_details[0]
            ? userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].student_id !== null
              ? userinfo.Admission_details[0].student_id
              : null
            : userinfo.Admission_details &&
              userinfo.Admission_details.student_id !== null
            ? userinfo.Admission_details[0].student_id
            : null;

          const applied_on_dty = userinfo.Admission_details[0]
            ? userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].applied_on !== null
              ? userinfo.Admission_details[0].applied_on
              : null
            : userinfo.Admission_details &&
              userinfo.Admission_details.applied_on !== null
            ? userinfo.Admission_details[0].applied_on
            : null;

          const coupon_amount_jk =
            userinfo.Admission_details[0] &&
            userinfo.Admission_details[0].is_coupon !== 0
              ? userinfo.Admission_details[0] &&
                userinfo.Admission_details[0].coupon_amount !== null
                ? userinfo.Admission_details[0].coupon_amount
                : null
              : userinfo.Admission_details &&
                userinfo.Admission_details.coupon_amount !== null
              ? userinfo.Admission_details[0].coupon_amount
              : null;

          PersonDetails.PPayment = PartialPayment;
          PersonDetails.student_id = student_id;
          PersonDetails.applied_on = applied_on_dty;
          PersonDetails.coupon_amount = coupon_amount_jk;
          setList(PersonDetails);

          const admissiondetails =
            userinfo.Admission_details.length > 0
              ? userinfo.Admission_details[0]
              : {};

          setAdmissionDetails(admissiondetails);

          userinfo.Courses.batch_details !== null
            ? getDisocount(userinfo.Courses.batch_details.batch_id)
            : getDisocount(null);

          const batchDe =
            userinfo.Courses.batch_details !== null
              ? {
                  batch_id: userinfo.Courses.batch_details.batch_id,
                  id: userinfo.Courses.batch_details.batch_id,
                  img_url: userinfo.Courses.batch_details.img_url,
                  course_name: userinfo.Courses.batch_details.course_name,
                  batch_name: userinfo.Courses.batch_details.batch_name,
                  course_id: userinfo.Courses.batch_details.course_id,
                  batch_details: userinfo.Courses.batch_details.batch_details,
                  class_mode: userinfo.Courses.batch_details.class_mode,
                  start_on: userinfo.Courses.batch_details.start_on
                    ? dobCon(userinfo.Courses.batch_details.start_on)
                    : "",
                  duration: userinfo.Courses.batch_details.duration,
                  act_c_fee: userinfo.Courses.batch_details.act_c_fee,
                  offer_spl: userinfo.Courses.batch_details.offer_spl,
                  dis_amt: userinfo.Courses.batch_details.dis_amt,
                  medium: userinfo.Courses.batch_details.medium,
                  is_selected: userinfo.Courses.batch_details.is_selected,
                  tot_old_stud_amt:
                    userinfo.Courses.batch_details.tot_old_stud_amt,
                  old_stud_disc_applied:
                    userinfo.Courses.batch_details.old_stud_disc_applied,
                  tot_old_stud_amt:
                    userinfo.Courses.batch_details.tot_old_stud_amt,
                  old_stud_disc_applied:
                    userinfo.Courses.batch_details.old_stud_disc_applied,
                  partial_payment:
                    userinfo.Courses.batch_details.partial_payment,
                  pay_1_perc: userinfo.Courses.batch_details.pay_1_perc,
                  pay_2_perc: userinfo.Courses.batch_details.pay_2_perc,
                  pay_3_perc: userinfo.Courses.batch_details.pay_3_perc,
                  pay_2_duedate: userinfo.Courses.batch_details.pay_2_duedate,
                  pay_3_duedate: userinfo.Courses.batch_details.pay_3_duedate,
                  exam_details:
                    userinfo.Courses.batch_details.exam_details &&
                    userinfo.Courses.batch_details.exam_details.exam_details &&
                    userinfo.Courses.batch_details.exam_details.exam_details
                      .length > 0
                      ? userinfo.Courses.batch_details.exam_details.exam_details
                      : [],
                  panel_details:
                    Object.keys(userinfo.Courses.batch_details.panel_details)
                      .length > 0
                      ? userinfo.Courses.batch_details.panel_details.panel_list
                      : [],
                  exam_fees:
                    Object.keys(userinfo.Courses.batch_details.exam_details)
                      .length > 0 &&
                    userinfo.Courses.batch_details.exam_details.exam_fees
                      .length > 0
                      ? userinfo.Courses.batch_details.exam_details.exam_fees
                      : Object.keys(
                          userinfo.Courses.batch_details.panel_details
                        ).length > 0
                      ? userinfo.Courses.batch_details.panel_details.exam_fees
                      : [],
                }
              : null;
          let ExamObj = [];
          batchDe.exam_details.map((el) => {
            ExamObj.push({
              pk: el.exam_detail_id,
              batch_id: el.batch_id,
              exam_title: el.exam_title,
              exam_title_id: el.exam_detail_id,
              started_dt: el.started_dt,
              batch_time: el.batch_time,
              duration_hrs: el.duration,
              syllabus_url: el.syllabus_url,
              is_selected_exam: el.is_selected_exam === 1 ? true : false,
            });
          });
          batchDe.exam_details = ExamObj;
          let PaneldetailsObj = [];
          batchDe.panel_details.map((el) => {
            PaneldetailsObj.push({
              pk: el.panel_pk,
              batch_id: el.batch_id,
              batch_type: el.batch_type,
              panel_name: el.panel_name,
              available_dates: el.available_dates,
              event_time: el.event_time,
              is_selected_panel: el.is_selected_panel == 1 ? true : false,
            });
          });
          batchDe.panel_details =
            PaneldetailsObj.length > 0 ? PaneldetailsObj : {};
          setBatchDetails([batchDe]);
        })
        .catch((error) => {
          return error;
        });
    } else {
      AxiosInstance.post("api/modelexam/view", {
        user_id: CourseDetail.user_id,
        admission_id: CourseDetail.adm_pk,
        type: CourseDetail.type,
      })
        .then((res) => {
          if (res.data.status === true) {
            const userinfo = res.data.data;
            const PersonDetails = {
              FirstName: userinfo.first_name,
              LastName: userinfo.last_name,
              father_name: userinfo.father_name,
              mobile: userinfo.phone_no,
              email: userinfo.email,
              Profilephoto:
                userinfo.profile_path !== null ? userinfo.profile_path : null,
            };

            const PartialPayment = userinfo.Admission_details[0]
              ? userinfo.Admission_details[0] &&
                userinfo.Admission_details[0].partial_payment !== null
                ? userinfo.Admission_details[0].partial_payment === 1
                  ? "1"
                  : "0"
                : "0"
              : userinfo.Admission_details &&
                userinfo.Admission_details.partial_payment !== null
              ? userinfo.Admission_details.partial_payment === 1
                ? "1"
                : "0"
              : "0";

            const student_id = userinfo.Admission_details[0]
              ? userinfo.Admission_details[0] &&
                userinfo.Admission_details[0].student_id !== null
                ? userinfo.Admission_details[0].student_id
                : null
              : userinfo.Admission_details &&
                userinfo.Admission_details.student_id !== null
              ? userinfo.Admission_details[0].student_id
              : null;

            const applied_on_dty = userinfo.Admission_details[0]
              ? userinfo.Admission_details[0] &&
                userinfo.Admission_details[0].applied_on !== null
                ? userinfo.Admission_details[0].applied_on
                : null
              : userinfo.Admission_details &&
                userinfo.Admission_details.applied_on !== null
              ? userinfo.Admission_details[0].applied_on
              : null;

            const coupon_amount_jk =
              userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].is_coupon !== 0
                ? userinfo.Admission_details[0] &&
                  userinfo.Admission_details[0].coupon_amount !== null
                  ? userinfo.Admission_details[0].coupon_amount
                  : null
                : userinfo.Admission_details &&
                  userinfo.Admission_details.coupon_amount !== null
                ? userinfo.Admission_details[0].coupon_amount
                : null;

            const special_offer_amount = userinfo.Admission_details[0]
              ? userinfo.Admission_details[0] &&
                userinfo.Admission_details[0].special_offer !== null
                ? userinfo.Admission_details[0].special_offer
                : null
              : userinfo.Admission_details &&
                userinfo.Admission_details.special_offer !== null
              ? userinfo.Admission_details[0].special_offer
              : null;

            const old_Student_discount_Amt = userinfo.Admission_details[0]
              ? userinfo.Admission_details[0] &&
                userinfo.Admission_details[0].old_stud_disc !== null
                ? userinfo.Admission_details[0].old_stud_disc
                : null
              : userinfo.Admission_details &&
                userinfo.Admission_details.old_stud_disc !== null
              ? userinfo.Admission_details[0].old_stud_disc
              : null;

            PersonDetails.PPayment = PartialPayment;
            PersonDetails.student_id = student_id;
            PersonDetails.applied_on = applied_on_dty;
            PersonDetails.coupon_amount = coupon_amount_jk;
            PersonDetails.special_offer = special_offer_amount;
            PersonDetails.old_stud_disc = old_Student_discount_Amt;

            setList(PersonDetails);

            const admissiondetails =
              userinfo.Admission_details.length > 0
                ? userinfo.Admission_details[0]
                : {};

            setAdmissionDetails(admissiondetails);

            const batchDe =
              userinfo.Courses.batch_details !== null
                ? {
                    batch_id: userinfo.Courses.batch_details.batch_id,
                    id: userinfo.Courses.batch_details.batch_id,
                    paper_type: userinfo.Courses.batch_details.paper_type,
                    img_url: userinfo.Courses.batch_details.img_url,
                    course_name: userinfo.Courses.batch_details.course_name,
                    batch_name: userinfo.Courses.batch_details.name,
                    course_id: userinfo.Courses.batch_details.course_id,
                    batch_details: userinfo.Courses.batch_details.batch_details,
                    class_mode: userinfo.Courses.batch_details.class_mode,
                    start_on: userinfo.Courses.batch_details.start_on
                      ? dobCon(userinfo.Courses.batch_details.start_on)
                      : "",
                    duration: userinfo.Courses.batch_details.duration,
                    act_c_fee: userinfo.Courses.batch_details.act_c_fee,
                    offer_spl: userinfo.Courses.batch_details.offer_spl,
                    dis_amt: userinfo.Courses.batch_details.dis_amt,
                    medium: userinfo.Courses.batch_details.batch_medium,
                    is_selected: userinfo.Courses.batch_details.is_selected,
                    tot_old_stud_amt:
                      userinfo.Courses.batch_details.tot_old_stud_amt,
                    old_stud_disc_applied:
                      userinfo.Courses.batch_details.old_stud_disc_applied,
                    exam_details:
                      userinfo.Courses.batch_details.exam_details &&
                      userinfo.Courses.batch_details.exam_details
                        .exam_details &&
                      userinfo.Courses.batch_details.exam_details.exam_details
                        .length > 0
                        ? userinfo.Courses.batch_details.exam_details
                            .exam_details
                        : [],
                    // panel_details:
                    //   Object.keys(userinfo.Courses.batch_details.panel_details)
                    //     .length > 0
                    //     ? userinfo.Courses.batch_details.panel_details.panel_list
                    //     : [],
                    exam_fees:
                      Object.keys(userinfo.Courses.batch_details.exam_details)
                        .length > 0 &&
                      userinfo.Courses.batch_details.exam_details.exam_fees
                        .length > 0
                        ? userinfo.Courses.batch_details.exam_details.exam_fees
                        : Object.keys(
                            userinfo.Courses.batch_details.panel_details
                          ).length > 0
                        ? userinfo.Courses.batch_details.panel_details.exam_fees
                        : [],
                  }
                : null;
            let ExamObj = [];
            batchDe.exam_details.map((el) => {
              ExamObj.push({
                pk: el.exam_detail_id,
                batch_id: el.batch_id,
                exam_title: el.exam_title,
                exam_title_id: el.exam_detail_id,
                started_dt: el.started_dt,
                batch_time: el.batch_time,
                duration_hrs: el.duration,
                syllabus_url: el.syllabus_url,
                is_selected_exam: el.is_selected_exam === 1 ? true : false,
                syllabus_url: el.syllabus_url,
                last_dt_apply: el.last_dt_apply,
                no_of_paper: el.no_of_paper,
                papers: el.papers.length > 0 ? el.papers : [],
              });
            });
            batchDe.exam_details = ExamObj;
            // let PaneldetailsObj = [];
            // batchDe.panel_details.map((el) => {
            //   PaneldetailsObj.push({
            //     pk: el.panel_pk,
            //     batch_id: el.batch_id,
            //     batch_type: el.batch_type,
            //     panel_name: el.panel_name,
            //     available_dates: el.available_dates,
            //     event_time: el.event_time,
            //     is_selected_panel: el.is_selected_panel == 1 ? true : false,
            //   });
            // });
            // batchDe.panel_details =
            //   PaneldetailsObj.length > 0 ? PaneldetailsObj : {};
            setBatchDetails([batchDe]);
          } else {
            Swal.fire({
              title: "Warning",
              text: res.data.message,
              icon: "Warning",
              confirmButtonText: "Ok",
            });
            navigateToPreviousScreen();
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, []);

  const [list, setList] = useState({
    PPayment: "",
    FirstName: "",
    LastName: "",
    father_name: "",
    email: "",
    mobile: "",
    Profilephoto: null,
    student_id: null,
    applied_on: "",
    coupon_amount: "",
    old_stud_disc: 0,
    special_offer: 0,
  });
  const [listErrors, setListErrors] = useState({});
  const [batchDetails, setBatchDetails] = useState([]);

  const [admissionDetails, setAdmissionDetails] = useState({});

  const dobCon = (userdate) => {
    if (!userdate) {
      return ""; // Handle empty input
    }
    const date = new Date(userdate);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Handle invalid date
    }
    // Extract the year, month, and day components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const validateFieldsPayments = (data) => {
    console.log(data, "data");
    const errors = {};
    if (!data.PPayment) {
      errors.PPayment = "please select Payment Type";
    }
    validateFieldsPaymentF(data);
    return errors;
  };

  const validateFieldsPaymentF = (data) => {
    if (!data.PPayment) {
      txtAdPPayment.current.focus();
    }
  };

  const handleChange = (event) => {
    console.log(event);
    const { name, value, files, checked } = event.target;
    if (checked) {
      setList((prevList) => ({
        ...prevList,
        [name]: value,
      }));
      setListErrors({
        ...listErrors,
        ["PPayment"]: "",
      });
    } else {
      setList((prevList) => ({
        ...prevList,
        [name]: "",
      }));
    }
  };

  const getDisocount = (batchId) => {
    const df = {
      user_id: userid,
      batch_id: batchId,
    };
    AxiosInstance.post("api/all/getOldStudDisc", df)
      .then((res) => {
        // console.log(res, "discount batch");
        if (res.data.status === true) {
          let discount = res.data.old_stud_disc;
          // console.log(discount, "discount");
          setDis(discount);
        } else {
          setDis(null); // or set a default value for no discount
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSPayfee = (details, el, admissionDtt, Payamount) => {
    const errorss = validateFieldsPayments(list);
    if (Object.keys(errorss).length === 0) {
      const courseAmo = Payamount;
      // console.log(typeof Payamount, Payamount, "PayamountPayamountPayamount");
      // alert(Payamount);
      if (Payamount > 0) {
        AxiosInstance.post("api/course/admission/cashPay", {
          order_id: details.transaction_order_id,
          user_id: details.user_id,
          created_by: userid,
          updated_by: userid,
          type: parseInt(details.type),
          payment_type: 0,
          installment_type:
            PayDue !== "1"
              ? list.PPayment === "1"
                ? details.instment_pay[0].payment_status !== "1"
                  ? [2]
                  : [3]
                : [2, 3]
              : list.PPayment === "1"
              ? [1]
              : [1, 2, 3],
          amount: courseAmo,
          adm_pk: details.adm_pk,
          adm_his_id: details.adm_his_id,
          discountAmount: el.dis_amt,
          gst: details.gst_percent,
          SpecialOffer: el.offer_spl,
          old_stud_disc_perc: admissionDtt.old_stud_disc_perc
            ? admissionDtt.old_stud_disc_perc
            : 0,
          old_stud_disc: admissionDtt.old_stud_disc,
          iscoupon: admissionDtt.is_coupon,
          couponAmount: admissionDtt.coupon_amount,
          couponcode: admissionDtt.coupon_code,
          Netamount: courseAmo,
          status: 4,
          created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
          old_stud_disc_applied:
            list.PPayment === "1"
              ? admissionDtt.old_stud_disc_applied
              : admissionDtt.old_stud_disc !== 0
              ? 1
              : 0,
          tot_amt:
            PayDue === "1"
              ? admissionDtt.old_stud_disc !== 0 && list.PPayment === "0"
                ? el.dis_amt -
                  list.coupon_amount +
                  0.18 * (el.dis_amt - list.coupon_amount) -
                  admissionDtt.old_stud_disc
                : el.dis_amt -
                  list.coupon_amount +
                  0.18 * (el.dis_amt - list.coupon_amount)
              : details.instment_pay[0].payment_status !== "1"
              ? admissionDtt.old_stud_disc !== 0
                ? el.dis_amt -
                  list.coupon_amount +
                  0.18 * (el.dis_amt - list.coupon_amount) -
                  admissionDtt.old_stud_disc
                : el.dis_amt -
                  list.coupon_amount +
                  0.18 * (el.dis_amt - list.coupon_amount)
              : el.dis_amt -
                list.coupon_amount +
                0.18 * (el.dis_amt - list.coupon_amount) -
                admissionDtt.old_stud_disc,
          gst_amt: details.gst_amt,
          partial_payment: list.PPayment === "1" ? "1" : "0",
          spc_offer_type: admissionDtt.spc_offer_type,
        })
          .then((res) => {
            if (res.data.status === true) {
              navigate(`/CashPayment/${PayTab}`);
            } else {
              Swal.fire({
                title: "warning",
                text: res.data.message,
                icon: "warning",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: error,
              icon: "warning",
              confirmButtonText: "Ok",
            });
            return error;
          });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Your fee should be a minimum of 1 rupee. Kindly reach out to the administrator for assistance with this matter.",
          icon: "Warning",
          confirmButtonText: "Ok",
        });
      }
    } else {
      setListErrors(errorss);
    }
  };

  const handleSpayeeModelExam = (
    details,
    el,
    admissionDtt,
    Payamount,
    discount_amt,
    gstAmt
  ) => {
    const errorss = validateFieldsPayments(list);
    if (Object.keys(errorss).length === 0) {
      const courseAmo = Payamount;
      if (Payamount > 0) {
        AxiosInstance.post("api/course/admission/cashPay", {
          order_id: details.transaction_order_id,
          user_id: details.user_id,
          created_by: userid,
          updated_by: userid,
          type: parseInt(details.type),
          payment_type: 0,
          installment_type: [1, 2, 3],
          amount: courseAmo,
          adm_pk: details.adm_pk,
          adm_his_id: details.adm_his_id,
          discountAmount: discount_amt,
          gst: admissionDtt.gst_percent,
          SpecialOffer:
            admissionDtt.old_stud_disc && admissionDtt.special_offer
              ? admissionDtt.old_stud_disc < admissionDtt.special_offer
                ? admissionDtt.special_offer
                : admissionDtt.old_stud_disc > admissionDtt.special_offer
                ? 0
                : 0
              : admissionDtt.special_offer
              ? admissionDtt.special_offer
              : 0,
          old_stud_disc_perc: 0,
          old_stud_disc:
            admissionDtt.old_stud_disc && admissionDtt.special_offer
              ? admissionDtt.old_stud_disc < admissionDtt.special_offer
                ? 0
                : admissionDtt.old_stud_disc > admissionDtt.special_offer
                ? admissionDtt.old_stud_disc
                : 0
              : admissionDtt.old_stud_disc
              ? admissionDtt.old_stud_disc
              : 0,
          iscoupon: admissionDtt.is_coupon,
          couponAmount: admissionDtt.coupon_amount,
          couponcode: admissionDtt.coupon_code,
          Netamount: courseAmo,
          status: 4,
          created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
          old_stud_disc_applied: admissionDtt.old_stud_disc_applied,
          tot_amt: courseAmo,
          gst_amt: gstAmt,
          partial_payment: 0,
          spc_offer_type: admissionDtt.spc_offer_type,
        })
          .then((res) => {
            if (res.data.status === true) {
              navigate(`/CashPayment/${PayTab}`);
            } else {
              Swal.fire({
                title: "warning",
                text: res.data.message,
                icon: "warning",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: error,
              icon: "warning",
              confirmButtonText: "Ok",
            });
            return error;
          });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Your fee should be a minimum of 1 rupee. Kindly reach out to the administrator for assistance with this matter.",
          icon: "Warning",
          confirmButtonText: "Ok",
        });
      }
    } else {
      setListErrors(errorss);
    }
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  console.log(batchDetails, "xzczc");

  return (
    <div className="AdmissionForm AdmissionForm_section">
      {batchDetails.length > 0 &&
        batchDetails.map((el, index) => (
          <form>
            <div className="sectionSix">
              <>
                <div className="heading mb-4 dflexbetww align-items-center">
                  <h4 className="Title">Pay Fee</h4>
                  <div className="d-flex smButton mobiledown">
                    <Link to="/CashPayment" className="CancelButton">
                      Back to Transaction History
                    </Link>
                    <button
                      type="button"
                      className="SaveButton_cash"
                      onClick={
                        CourseDetail.type === 4
                          ? () =>
                              handleSpayeeModelExam(
                                CourseDetail,
                                el,
                                admissionDetails,
                                el.exam_fees
                                  .filter(
                                    (fee) =>
                                      fee.fees_count ===
                                      el.exam_details.filter(
                                        (el) => el.is_selected_exam
                                      ).length
                                  )
                                  .map((fee) => fee.fees) -
                                  (list.coupon_amount +
                                    (list.old_stud_disc && list.special_offer
                                      ? list.old_stud_disc < list.special_offer
                                        ? list.special_offer
                                        : list.old_stud_disc >
                                          list.special_offer
                                        ? 0
                                        : 0
                                      : list.special_offer
                                      ? list.special_offer
                                      : 0) +
                                    (list.old_stud_disc && list.special_offer
                                      ? list.old_stud_disc < list.special_offer
                                        ? 0
                                        : list.old_stud_disc >
                                          list.special_offer
                                        ? list.old_stud_disc
                                        : 0
                                      : list.old_stud_disc
                                      ? list.old_stud_disc
                                      : 0)) +
                                  0.18 *
                                    (el.exam_fees
                                      .filter(
                                        (fee) =>
                                          fee.fees_count ===
                                          el.exam_details.filter(
                                            (el) => el.is_selected_exam
                                          ).length
                                      )
                                      .map((fee) => fee.fees) -
                                      (list.coupon_amount +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? list.special_offer
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? 0
                                            : 0
                                          : list.special_offer
                                          ? list.special_offer
                                          : 0) +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? 0
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? list.old_stud_disc
                                            : 0
                                          : list.old_stud_disc
                                          ? list.old_stud_disc
                                          : 0))),
                                parseInt(
                                  list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? list.special_offer
                                      : list.old_stud_disc > list.special_offer
                                      ? 0
                                      : 0
                                    : list.special_offer
                                    ? list.special_offer
                                    : 0
                                ) +
                                  parseInt(list.coupon_amount) +
                                  parseInt(
                                    list.old_stud_disc && list.special_offer
                                      ? list.old_stud_disc < list.special_offer
                                        ? 0
                                        : list.old_stud_disc >
                                          list.special_offer
                                        ? list.old_stud_disc
                                        : 0
                                      : list.old_stud_disc
                                      ? list.old_stud_disc
                                      : 0
                                  ),
                                (el.exam_fees
                                  .filter(
                                    (fee) =>
                                      fee.fees_count ===
                                      el.exam_details.filter(
                                        (el) => el.is_selected_exam
                                      ).length
                                  )
                                  .map((fee) => fee.fees) -
                                  (list.coupon_amount +
                                    (list.old_stud_disc && list.special_offer
                                      ? list.old_stud_disc < list.special_offer
                                        ? list.special_offer
                                        : list.old_stud_disc >
                                          list.special_offer
                                        ? 0
                                        : 0
                                      : list.special_offer
                                      ? list.special_offer
                                      : 0) +
                                    (list.old_stud_disc && list.special_offer
                                      ? list.old_stud_disc < list.special_offer
                                        ? 0
                                        : list.old_stud_disc >
                                          list.special_offer
                                        ? list.old_stud_disc
                                        : 0
                                      : list.old_stud_disc
                                      ? list.old_stud_disc
                                      : 0))) *
                                  0.18
                              )
                          : () =>
                              handleSPayfee(
                                CourseDetail,
                                el,
                                admissionDetails,
                                PayDue === "1"
                                  ? list.PPayment === "1"
                                    ? PayDue === "1"
                                      ? CourseDetail.balance_amt
                                      : CourseDetail.paid_amount
                                    : el.dis_amt -
                                      list.coupon_amount +
                                      0.18 * (el.dis_amt - list.coupon_amount)
                                  : CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay[0]
                                      .payment_status !== "1"
                                  ? list.PPayment === "1"
                                    ? CourseDetail.instment_pay.length > 0 &&
                                      CourseDetail.instment_pay.length > 0 &&
                                      CourseDetail.instment_pay.length > 0 &&
                                      CourseDetail.instment_pay[0].due_amount
                                    : Number(
                                        CourseDetail.instment_pay[0].due_amount
                                      ) +
                                      Number(
                                        CourseDetail.instment_pay[1].due_amount
                                      )
                                  : CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay[1].due_amount
                              )
                      }
                    >
                      <a href="javascript:void(0)">
                        Amount Collected and Submitted
                      </a>
                    </button>
                  </div>
                </div>
                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Basic Details</p>

                      <div className="row">
                        <div className="col-md-8 col-lg-8">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  First Name
                                </label>
                                <span className="ReviewLabel">
                                  {list.FirstName ? list.FirstName : "_"}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Last Name
                                </label>
                                <span className="ReviewLabel">
                                  {list.LastName ? list.LastName : "_"}
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-12">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Father's Name/Guardian's Name
                                </label>
                                <span className="ReviewLabel">
                                  {list.father_name ? list.father_name : "_"}
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Email
                                </label>
                                <span className="ReviewLabel">
                                  {" "}
                                  {list.email ? list.email : "_"}
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Phone Number
                                </label>
                                <span className="ReviewLabel">
                                  {list.mobile ? list.mobile : "_"}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Applied On
                                </label>
                                <span className="ReviewLabel">
                                  {list.applied_on ? list.applied_on : "_"}
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Payment Mode
                                </label>
                                <span className="ReviewLabel">Cash</span>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Order ID
                                </label>
                                <span className="ReviewLabel">
                                  {CourseDetail.transaction_order_id}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4 d-flex justify-content-center">
                          <div className="forms mb-2  ">
                            <div id="profilephotos">
                              <img
                                src={`${ImageUrlPrefix}/${list.Profilephoto}`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="row">
                      {CourseDetail.type !== 4 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="card h-100">
                            <div className="row">
                              <div className="col-md-6 d-flex flex-column  justify-content-center align-center mt-4">
                                <img
                                  title={el.img_url}
                                  src={`${ImageUrlPrefix}/${el.img_url}`}
                                  id={el.id}
                                  alt="Imge"
                                  style={{
                                    width: "130px",
                                    height: "130px",
                                    alignSelf: "center",
                                  }}
                                />
                                <h5 className="card-title payment_course_name">
                                  {el.batch_name}
                                </h5>
                                <p className=" ms-3 text-center payment_batch_name">
                                  {el.batch_details}
                                </p>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex mt-4 flex-column align-items-start payment">
                                  <div className="">
                                    <LaptopChromebook className="payment_icon" />
                                    <div className="innerflex_payment">
                                      <h6 className="payment flex_title">
                                        Class Mode
                                      </h6>
                                      <h6 className="payment flex_subtitle">
                                        {el.class_mode}
                                      </h6>
                                    </div>
                                  </div>

                                  <div className="">
                                    <Today className="payment_icon" />
                                    <div className="innerflex_payment">
                                      <h6 className="payment flex_title">
                                        Starts On
                                      </h6>
                                      <h6 className="payment flex_subtitle">
                                        {el.start_on
                                          ? moment
                                              .utc(el.start_on)
                                              .format("YYYY-MM-DD")
                                          : ""}
                                      </h6>
                                    </div>
                                  </div>
                                  {CourseDetail.type !== 4 && (
                                    <div className="">
                                      <Alarm className="payment_icon" />
                                      <div className="innerflex_payment">
                                        <h6 className="payment flex_title">
                                          Duration
                                        </h6>
                                        <h6 className="payment flex_subtitle">
                                          {el.duration}
                                        </h6>
                                      </div>
                                    </div>
                                  )}

                                  <div className="">
                                    <span class="material-icons payment_icon">
                                      g_translate
                                    </span>
                                    <div className="innerflex_payment">
                                      <h6 className="payment flex_title">
                                        Medium of Instruction
                                      </h6>
                                      <h6 className="payment flex_subtitle">
                                        {el.medium}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {CourseDetail.type === 4 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="card h-100">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 d-flex flex-column  justify-content-center align-center mt-4">
                                <img
                                  className="payment"
                                  src={`${ImageUrlPrefix}/${el.img_url}`}
                                  alt="course_book"
                                  style={{
                                    width: "130px",
                                    height: "130px",
                                    alignSelf: "center",
                                  }}
                                />
                                <h5 className="card-title payment_course_name">
                                  {el.course_name || ""} {"-"}{" "}
                                  {el.batch_name || ""}
                                </h5>
                              </div>

                              <div className="col-lg-6 col-md-6 col-sm-6  d-flex flex-column  justify-content-center align-center mt-4 ">
                                <li className="IconsImage">
                                  <span className="material-icons">
                                    format_shapes
                                  </span>
                                  <div className="d-flex flex-column">
                                    <p className="title ms-1">Mode of Exam</p>
                                    <p className="subtitle ms-1">
                                      {el.class_mode}
                                    </p>
                                  </div>
                                </li>
                              </div>

                              <div className="col-lg-12 mb-4">
                                {el.paper_type === 0 &&
                                  el.exam_details.length > 0 &&
                                  el.exam_details
                                    .filter(
                                      (ell) => ell.is_selected_exam === true
                                    )
                                    ?.sort(
                                      (a, b) =>
                                        new Date(a.started_dt) -
                                        new Date(b.started_dt)
                                    )
                                    ?.map((oj, index) => (
                                      <div className="row">
                                        <div className="col-md-12 col-sm-12 mx-2">
                                          <div className="row">
                                            <div className="col-md-4 mt-2">
                                              <li className="IconsImage">
                                                <span className="material-icons">
                                                  format_shapes
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="st_confirm_label ms-1">
                                                    Exam Title
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {oj.exam_title}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                            <div className="col-md-4 mt-2">
                                              <li className="IconsImage">
                                                <span className="material-icons-outlined">
                                                  timer
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="st_confirm_label ms-1">
                                                    Date
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {oj.started_dt
                                                      ? moment(
                                                          oj.started_dt
                                                        ).format("Do MMM YYYY")
                                                      : "_"}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                            <div className="col-md-4 mt-2">
                                              <li className="IconsImage">
                                                <span className="material-icons-outlined">
                                                  timer
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="st_confirm_label ms-1">
                                                    Time
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {oj.batch_time}{" "}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                            <div className="col-md-4 mt-2">
                                              <li className="IconsImage">
                                                <span className="material-icons">
                                                  event
                                                </span>
                                                <div className="d-flex flex-column ">
                                                  <p className="st_confirm_label ms-1">
                                                    Last Date to Apply
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {moment
                                                      .utc(oj.last_dt_apply)
                                                      .format("Do MMM YYYY")}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                        {el.exam_details.length > 1 &&
                                          el.exam_details.map(
                                            (el) => el.is_selected_exam
                                          ).length > 1 &&
                                          index <
                                            el.exam_details.length - 1 && (
                                            <span className="border-exam-short my-2"></span>
                                          )}
                                      </div>
                                    ))}

                                {el.paper_type === 1 &&
                                  el.exam_details.length > 0 &&
                                  el.exam_details
                                    .filter(
                                      (ell) => ell.is_selected_exam === true
                                    )
                                    ?.sort(
                                      (a, b) =>
                                        a.exam_title_id - b.exam_title_id
                                    )
                                    ?.map((oj, index) => (
                                      <div className="row">
                                        <div className="col-md-12 mt-3 col-sm-12 ">
                                          <div className="row">
                                            <div className="col-md-4 dflex justify-content-center">
                                              <li className="IconsImage">
                                                <span className="material-icons">
                                                  format_shapes
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="st_confirm_label ms-1">
                                                    Model Exam Title
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {oj.exam_title}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                            <div className="col-md-4 dflex justify-content-center">
                                              <li className="IconsImage">
                                                <span className="material-icons-outlined">
                                                  timer
                                                </span>
                                                <div className="d-flex flex-column ">
                                                  <p className="st_confirm_label ms-1">
                                                    Number of Papers
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {oj.no_of_paper}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                            <div className="col-md-4 dflex justify-content-center">
                                              <li className="IconsImage">
                                                <span className="material-icons">
                                                  event
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="st_confirm_label ms-1">
                                                    Last Date to Apply
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {moment
                                                      .utc(oj.last_dt_apply)
                                                      .format("Do MMM YYYY")}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 mx-2">
                                          <div
                                            className="card"
                                            style={{ width: "97%" }}
                                          >
                                            {oj.papers
                                              ?.sort(
                                                (a, b) =>
                                                  new Date(a.start_dt) -
                                                  new Date(b.start_dt)
                                              )
                                              ?.map((io, ind) => (
                                                <div className="row ">
                                                  <div className="col-md-4 dflex justify-content-center mt-2">
                                                    <li className="IconsImage">
                                                      <span className="material-icons">
                                                        format_shapes
                                                      </span>
                                                      <div className="d-flex flex-column">
                                                        <p className="st_confirm_label  ms-1">
                                                          Paper Title
                                                        </p>
                                                        <p className="st_confirm_value ms-1">
                                                          {io.paper_title}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </div>
                                                  {/* New added */}

                                                  <div className="col-md-3 dflex justify-content-center mt-2">
                                                    <li className="IconsImage">
                                                      <span className="material-icons">
                                                        event
                                                      </span>
                                                      <div className="d-flex flex-column">
                                                        <p className="st_confirm_label  ms-1">
                                                          Date
                                                        </p>
                                                        <p className="st_confirm_value ms-1">
                                                          {io.start_dt
                                                            ? moment(
                                                                io.start_dt
                                                              ).format(
                                                                "Do MMM YYYY"
                                                              )
                                                            : "_"}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </div>
                                                  <div className="col-md-4 dflex justify-content-center mt-2">
                                                    <li className="IconsImage">
                                                      <span className="material-icons-outlined">
                                                        timer
                                                      </span>
                                                      <div className="d-flex flex-column">
                                                        <p className="st_confirm_label  ms-1">
                                                          Time
                                                        </p>
                                                        <p className="st_confirm_value ms-1">
                                                          {io.test_time}{" "}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </div>
                                                  {/* <div className="col-md-4 dflex justify-content-center ms-4 mt-2">
                                                <li className="IconsImage">
                                                  <span className="material-icons">
                                                    event
                                                  </span>
                                                  <div className="d-flex flex-column ">
                                                    <p className="st_confirm_label  ms-1">
                                                      Last Date to Apply
                                                    </p>
                                                    <p className="st_confirm_value ms-1">
                                                      {moment
                                                        .utc(io.last_dt_apply)
                                                        .format("Do MMM YYYY")}
                                                    </p>
                                                  </div>
                                                </li>
                                              </div> */}

                                                  {oj.papers.length > 1 &&
                                                    ind <
                                                      oj.papers.length - 1 && (
                                                      <span className="border-exam-short_stepssA my-2"></span>
                                                    )}
                                                </div>
                                              ))}
                                          </div>
                                        </div>

                                        {el.exam_details.length > 1 &&
                                          el.exam_details.filter(
                                            (opp) =>
                                              opp.is_selected_exam === true
                                          ).length > 1 &&
                                          index <
                                            el.exam_details.length - 1 && (
                                            <span className="border-exam-short_stepss my-2 "></span>
                                          )}
                                      </div>
                                    ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {CourseDetail.type !== 4 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="card h-100">
                            <div className="row mt-4">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfo">
                                  {CourseDetail.type === 4
                                    ? "Actual Exam Fee"
                                    : "Actual Course Fee"}
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfos">
                                  <CurrencyRupee className="payment_currency" />
                                  {el.act_c_fee}
                                </h6>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name">
                                  Special Offer
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  {el.offer_spl}
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name PymentCoun">
                                  Coupon Code
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  {list.coupon_amount}
                                </h6>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6
                                  className="Payment_labelInfo"
                                  style={{ paddingTop: "9px" }}
                                >
                                  Total Discounted Fee
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfos pt-2">
                                  <CurrencyRupee className="payment_currency" />
                                  <span>
                                    {" "}
                                    {parseInt(el.offer_spl) +
                                      parseInt(list.coupon_amount)}
                                  </span>
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name">GST(18%)</h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees ">
                                  <CurrencyRupee className="payment_currency" />
                                  <span>
                                    {" "}
                                    {(
                                      (el.dis_amt - list.coupon_amount) *
                                      0.18
                                    ).toFixed(2)}
                                  </span>
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfo">
                                  Net Amount To Pay
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  <span className="">
                                    {" "}
                                    {el.dis_amt -
                                      list.coupon_amount +
                                      0.18 * (el.dis_amt - list.coupon_amount)}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {CourseDetail.type === 4 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="card h-100">
                            <div className="row mt-4">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfo">
                                  Actual Exam Fee
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfos">
                                  <CurrencyRupee className="payment_currency" />
                                  {el.exam_fees
                                    .filter(
                                      (fee) =>
                                        fee.fees_count ===
                                        el.exam_details.filter(
                                          (esl) => esl.is_selected_exam
                                        ).length
                                    )
                                    .map((fee) => fee.fees)}
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name">
                                  Old Student Discount
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  {list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? 0
                                      : list.old_stud_disc > list.special_offer
                                      ? list.old_stud_disc
                                      : 0
                                    : list.old_stud_disc
                                    ? list.old_stud_disc
                                    : 0}
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name">
                                  Special Offer
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  {list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? list.special_offer
                                      : list.old_stud_disc > list.special_offer
                                      ? 0
                                      : 0
                                    : list.special_offer
                                    ? list.special_offer
                                    : 0}
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name PymentCoun">
                                  Coupon Code
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  {list.coupon_amount}
                                </h6>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6
                                  className="Payment_labelInfo"
                                  style={{ paddingTop: "9px" }}
                                >
                                  Total Discounted Fee
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfos pt-2">
                                  <CurrencyRupee className="payment_currency" />
                                  <span>
                                    {" "}
                                    {parseInt(
                                      list.old_stud_disc && list.special_offer
                                        ? list.old_stud_disc <
                                          list.special_offer
                                          ? list.special_offer
                                          : list.old_stud_disc >
                                            list.special_offer
                                          ? 0
                                          : 0
                                        : list.special_offer
                                        ? list.special_offer
                                        : 0
                                    ) +
                                      parseInt(list.coupon_amount) +
                                      parseInt(
                                        list.old_stud_disc && list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? 0
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? list.old_stud_disc
                                            : 0
                                          : list.old_stud_disc
                                          ? list.old_stud_disc
                                          : 0
                                      )}
                                  </span>
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name">
                                  Amount Payable
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees ">
                                  <CurrencyRupee className="payment_currency" />
                                  <span>
                                    {" "}
                                    {el.exam_fees
                                      .filter(
                                        (fee) =>
                                          fee.fees_count ===
                                          el.exam_details.filter(
                                            (el) => el.is_selected_exam
                                          ).length
                                      )
                                      .map((fee) => fee.fees) -
                                      (list.coupon_amount +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? list.special_offer
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? 0
                                            : 0
                                          : list.special_offer
                                          ? list.special_offer
                                          : 0) +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? 0
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? list.old_stud_disc
                                            : 0
                                          : list.old_stud_disc
                                          ? list.old_stud_disc
                                          : 0)) >
                                    0
                                      ? el.exam_fees
                                          .filter(
                                            (fee) =>
                                              fee.fees_count ===
                                              el.exam_details.filter(
                                                (el) => el.is_selected_exam
                                              ).length
                                          )
                                          .map((fee) => fee.fees) -
                                        (list.coupon_amount +
                                          (list.old_stud_disc &&
                                          list.special_offer
                                            ? list.old_stud_disc <
                                              list.special_offer
                                              ? list.special_offer
                                              : list.old_stud_disc >
                                                list.special_offer
                                              ? 0
                                              : 0
                                            : list.special_offer
                                            ? list.special_offer
                                            : 0) +
                                          (list.old_stud_disc &&
                                          list.special_offer
                                            ? list.old_stud_disc <
                                              list.special_offer
                                              ? 0
                                              : list.old_stud_disc >
                                                list.special_offer
                                              ? list.old_stud_disc
                                              : 0
                                            : list.old_stud_disc
                                            ? list.old_stud_disc
                                            : 0))
                                      : 0}
                                  </span>
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name">GST(18%)</h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees ">
                                  <CurrencyRupee className="payment_currency" />
                                  <span>
                                    {" "}
                                    {(el.exam_fees
                                      .filter(
                                        (fee) =>
                                          fee.fees_count ===
                                          el.exam_details.filter(
                                            (el) => el.is_selected_exam
                                          ).length
                                      )
                                      .map((fee) => fee.fees) -
                                      (list.coupon_amount +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? list.special_offer
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? 0
                                            : 0
                                          : list.special_offer
                                          ? list.special_offer
                                          : 0) +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? 0
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? list.old_stud_disc
                                            : 0
                                          : list.old_stud_disc
                                          ? list.old_stud_disc
                                          : 0))) *
                                      0.18 >
                                    0
                                      ? (
                                          (el.exam_fees
                                            .filter(
                                              (fee) =>
                                                fee.fees_count ===
                                                el.exam_details.filter(
                                                  (el) => el.is_selected_exam
                                                ).length
                                            )
                                            .map((fee) => fee.fees) -
                                            (list.coupon_amount +
                                              (list.old_stud_disc &&
                                              list.special_offer
                                                ? list.old_stud_disc <
                                                  list.special_offer
                                                  ? list.special_offer
                                                  : list.old_stud_disc >
                                                    list.special_offer
                                                  ? 0
                                                  : 0
                                                : list.special_offer
                                                ? list.special_offer
                                                : 0) +
                                              (list.old_stud_disc &&
                                              list.special_offer
                                                ? list.old_stud_disc <
                                                  list.special_offer
                                                  ? 0
                                                  : list.old_stud_disc >
                                                    list.special_offer
                                                  ? list.old_stud_disc
                                                  : 0
                                                : list.old_stud_disc
                                                ? list.old_stud_disc
                                                : 0))) *
                                          0.18
                                        ).toFixed(2)
                                      : 0}
                                  </span>
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfo">
                                  Net Amount To Pay
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  <span className="">
                                    {" "}
                                    {el.exam_fees
                                      .filter(
                                        (fee) =>
                                          fee.fees_count ===
                                          el.exam_details.filter(
                                            (el) => el.is_selected_exam
                                          ).length
                                      )
                                      .map((fee) => fee.fees) -
                                      (list.coupon_amount +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? list.special_offer
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? 0
                                            : 0
                                          : list.special_offer
                                          ? list.special_offer
                                          : 0) +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? 0
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? list.old_stud_disc
                                            : 0
                                          : list.old_stud_disc
                                          ? list.old_stud_disc
                                          : 0)) +
                                      0.18 *
                                        (el.exam_fees
                                          .filter(
                                            (fee) =>
                                              fee.fees_count ===
                                              el.exam_details.filter(
                                                (el) => el.is_selected_exam
                                              ).length
                                          )
                                          .map((fee) => fee.fees) -
                                          (list.coupon_amount +
                                            (list.old_stud_disc &&
                                            list.special_offer
                                              ? list.old_stud_disc <
                                                list.special_offer
                                                ? list.special_offer
                                                : list.old_stud_disc >
                                                  list.special_offer
                                                ? 0
                                                : 0
                                              : list.special_offer
                                              ? list.special_offer
                                              : 0) +
                                            (list.old_stud_disc &&
                                            list.special_offer
                                              ? list.old_stud_disc <
                                                list.special_offer
                                                ? 0
                                                : list.old_stud_disc >
                                                  list.special_offer
                                                ? list.old_stud_disc
                                                : 0
                                              : list.old_stud_disc
                                              ? list.old_stud_disc
                                              : 0))) >
                                    0
                                      ? (
                                          el.exam_fees
                                            .filter(
                                              (fee) =>
                                                fee.fees_count ===
                                                el.exam_details.filter(
                                                  (el) => el.is_selected_exam
                                                ).length
                                            )
                                            .map((fee) => fee.fees) -
                                          (list.coupon_amount +
                                            (list.old_stud_disc &&
                                            list.special_offer
                                              ? list.old_stud_disc <
                                                list.special_offer
                                                ? list.special_offer
                                                : list.old_stud_disc >
                                                  list.special_offer
                                                ? 0
                                                : 0
                                              : list.special_offer
                                              ? list.special_offer
                                              : 0) +
                                            (list.old_stud_disc &&
                                            list.special_offer
                                              ? list.old_stud_disc <
                                                list.special_offer
                                                ? 0
                                                : list.old_stud_disc >
                                                  list.special_offer
                                                ? list.old_stud_disc
                                                : 0
                                              : list.old_stud_disc
                                              ? list.old_stud_disc
                                              : 0)) +
                                          0.18 *
                                            (el.exam_fees
                                              .filter(
                                                (fee) =>
                                                  fee.fees_count ===
                                                  el.exam_details.filter(
                                                    (el) => el.is_selected_exam
                                                  ).length
                                              )
                                              .map((fee) => fee.fees) -
                                              (list.coupon_amount +
                                                (list.old_stud_disc &&
                                                list.special_offer
                                                  ? list.old_stud_disc <
                                                    list.special_offer
                                                    ? list.special_offer
                                                    : list.old_stud_disc >
                                                      list.special_offer
                                                    ? 0
                                                    : 0
                                                  : list.special_offer
                                                  ? list.special_offer
                                                  : 0) +
                                                (list.old_stud_disc &&
                                                list.special_offer
                                                  ? list.old_stud_disc <
                                                    list.special_offer
                                                    ? 0
                                                    : list.old_stud_disc >
                                                      list.special_offer
                                                    ? list.old_stud_disc
                                                    : 0
                                                  : list.old_stud_disc
                                                  ? list.old_stud_disc
                                                  : 0)))
                                        ).toFixed(2)
                                      : 0}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            </div>

            {CourseDetail.type !== 4 &&
              admissionDetails.old_stud_disc !== 0 && (
                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Old Student Discount</p>

                      <div className="row mb-3">
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Old Student Discount
                            </label>
                            <span className="ReviewLabel ms-3">
                              <CurrencyRupee className="payment_currency" />
                              {admissionDetails.old_stud_disc
                                ? admissionDetails.old_stud_disc
                                : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-lg-12 col-md-12">
                          <div className="forms">
                            <span className="ReviewLabel_note alert_info">
                              <strong> Note :</strong> The Old Student Discount
                              will be applied to the final installment only if
                              you select the Partial Payment option.
                              Alternatively, if you choose the Full Payment
                              option, the Old Student Discount amount will be
                              deducted from the payment immediately.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

            {CourseDetail.type !== 4 && (
              <section className="sectionSix">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Payment Option</p>

                    <div className="row mb-3">
                      <div className="col-lg-7 ol-md-7">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted"
                          >
                            Payment Option
                          </label>
                          {CourseDetail.instment_pay &&
                          CourseDetail.instment_pay.length > 0 &&
                          CourseDetail.instment_pay[0].payment_status ===
                            "0" ? (
                            <div
                              className="d-flex  col-md-12"
                              id="radiobuttons"
                            >
                              <div className="form-check form-check-inline">
                                <input
                                  ref={txtAdPPayment}
                                  className="checkbox_radio"
                                  type="radio"
                                  name="PPayment"
                                  id="full"
                                  checked={list.PPayment === "0" ? true : false}
                                  value="0"
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label mt-1"
                                  htmlFor="full"
                                >
                                  Full Balance Payment{" "}
                                  {PayDue === "1"
                                    ? "(1st Payment)"
                                    : "(2nd + 3rd Payment)"}
                                </label>
                              </div>
                              <div className="form-check form-check-inline p-sm-0 me-sm-0">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="PPayment"
                                  id="Partial"
                                  value="1"
                                  checked={list.PPayment === "1" ? true : false}
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label mt-1"
                                  htmlFor="Partial"
                                >
                                  Partial Payment{" "}
                                  {PayDue === "1"
                                    ? "(1st Payment)"
                                    : "(2nd Payment)"}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="d-flex  col-md-12"
                              id="radiobuttons"
                            >
                              <div className="form-check form-check-inline">
                                <input
                                  ref={txtAdPPayment}
                                  className="checkbox_radio"
                                  type="radio"
                                  name="PPayment"
                                  id="Partial"
                                  value="0"
                                  checked={
                                    list.PPayment === "0" ||
                                    (list.PPayment === "1" &&
                                      CourseDetail.instment_pay.length > 0 &&
                                      CourseDetail.instment_pay[0]
                                        .payment_status === "1")
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                />
                                <label
                                  className="checkbox-label mt-1"
                                  htmlFor="full"
                                >
                                  Final Payment
                                </label>
                              </div>
                            </div>
                          )}

                          {listErrors.PPayment && (
                            <p className="errors">{listErrors.PPayment}</p>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-5 col-md-5">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Amount To Pay{" "}
                            {PayDue === "1"
                              ? list.PPayment === "1"
                                ? `(${el.pay_1_perc} % Final Course Fee)`
                                : `(100 % Final Course Fee)`
                              : CourseDetail.instment_pay.length > 0 &&
                                CourseDetail.instment_pay[0].payment_status !==
                                  "1"
                              ? admissionDetails.old_stud_disc !== 0
                                ? list.PPayment === "1"
                                  ? `(${
                                      CourseDetail.instment_pay.length > 0 &&
                                      CourseDetail.instment_pay[0].due_perc
                                    }% of Final Course Fee)`
                                  : `(${
                                      Number(
                                        CourseDetail.instment_pay[0].due_perc
                                      ) +
                                      Number(
                                        CourseDetail.instment_pay[1].due_perc
                                      )
                                    }% of Final Course Fee - Old Student
                    Discount)`
                                : list.PPayment === "1"
                                ? `(${
                                    CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay[0].due_perc
                                  }% of Final Course Fee )`
                                : `(${
                                    Number(
                                      CourseDetail.instment_pay[0].due_perc
                                    ) +
                                    Number(
                                      CourseDetail.instment_pay[1].due_perc
                                    )
                                  }% of Final Course Fee )`
                              : admissionDetails.old_stud_disc !== 0
                              ? list.PPayment === "1"
                                ? `(${
                                    CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay[1].due_perc
                                  }% of Final Course Fee - Old Student
                    Discount)`
                                : `(${
                                    CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay[1].due_perc
                                  }% of Final Course Fee - Old Student
                      Discount)`
                              : list.PPayment === "1"
                              ? `(${
                                  CourseDetail.instment_pay.length > 0 &&
                                  CourseDetail.instment_pay[1].due_perc
                                }% of Final Course Fee)`
                              : `(${
                                  CourseDetail.instment_pay.length > 0 &&
                                  CourseDetail.instment_pay[1].due_perc
                                }% of Final Course Fee)`}
                          </label>
                          <span className="ReviewLabel mt-md-1 mt-sm-4">
                            <CurrencyRupee className="payment_currency" />
                            {PayDue === "1"
                              ? list.PPayment === "1"
                                ? PayDue === "1"
                                  ? CourseDetail.balance_amt
                                  : CourseDetail.paid_amount
                                : el.dis_amt -
                                  list.coupon_amount +
                                  0.18 * (el.dis_amt - list.coupon_amount) -
                                  admissionDetails.old_stud_disc
                              : CourseDetail.instment_pay.length > 0 &&
                                CourseDetail.instment_pay[0].payment_status !==
                                  "1"
                              ? list.PPayment === "1"
                                ? CourseDetail.instment_pay.length > 0 &&
                                  CourseDetail.instment_pay[0].due_amount
                                : Number(
                                    CourseDetail.instment_pay[0].due_amount
                                  ) +
                                  Number(
                                    CourseDetail.instment_pay[1].due_amount
                                  )
                              : CourseDetail.instment_pay.length > 0 &&
                                CourseDetail.instment_pay[1].due_amount}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {CourseDetail.type === 4 &&
              list.PPayment === "1" &&
              CourseDetail.instment_pay.length > 0 &&
              CourseDetail.instment_pay[0].payment_status !== "1" && (
                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Partial Payment Schedule Details
                      </p>

                      <div className="row">
                        <div className="col-lg-6 ol-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              {" "}
                              2nd Payment Date (On or Before)
                            </label>
                            <span className="ReviewLabel">
                              {CourseDetail.instment_pay[0].due_date
                                ? moment(
                                    CourseDetail.instment_pay[0].due_date,
                                    "DD/MM/YYYY",
                                    true
                                  ).isValid()
                                  ? moment(
                                      CourseDetail.instment_pay[0].due_date,
                                      "DD/MM/YYYY"
                                    ).format("YYYY-MM-DD")
                                  : moment(
                                      CourseDetail.instment_pay[0].due_date
                                    ).format("YYYY-MM-DD")
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              2nd Payment Amount{" "}
                              {`(${CourseDetail.instment_pay[0].due_perc}% of Final Course Fee)`}
                            </label>
                            <span className="ReviewLabel  mt-md-1 mt-sm-4">
                              <CurrencyRupee className="payment_currency" />
                              {CourseDetail.instment_pay[0].due_amount}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-lg-6 ol-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              3rd Payment Date (On or Before)
                            </label>
                            <span className="ReviewLabel">
                              {CourseDetail.instment_pay.length > 0 &&
                              CourseDetail.instment_pay[1].due_date
                                ? moment(
                                    CourseDetail.instment_pay[1].due_date,
                                    "DD/MM/YYYY",
                                    true
                                  ).isValid()
                                  ? moment(
                                      CourseDetail.instment_pay[1].due_date,
                                      "DD/MM/YYYY"
                                    ).format("YYYY-MM-DD")
                                  : moment(
                                      CourseDetail.instment_pay[1].due_date
                                    ).format("YYYY-MM-DD")
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              3rd Payment Amount{" "}
                              {admissionDetails.old_stud_disc !== 0
                                ? `(${
                                    CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay[1].due_perc
                                  }% of Final Course Fee -
                   Old Student Discount)`
                                : `(${
                                    CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay[1].due_perc
                                  }% of Final Course Fee)`}
                            </label>
                            <span className="ReviewLabel mt-md-1 mt-sm-4">
                              <CurrencyRupee className="payment_currency" />
                              {CourseDetail.instment_pay.length > 0 &&
                                CourseDetail.instment_pay[1].due_amount}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                          <div className="forms ">
                            <span className="ReviewLabel_warning alert_warning ">
                              <div className="blink">
                                <span class="material-icons ">warning</span>
                                <strong className="waning_message">
                                  WARNING
                                </strong>
                                <span className="">
                                  : Please pay your fees by the given date to
                                  keep your schedule active. If you don't pay on
                                  time, you will not be allowed to attend
                                  classes until the fees are paid.
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

            <div className="sectionSix">
              <div className="heading mb-4 dflexbetwwend align-items-center">
                <div className="d-flex smButton mobiledown">
                  <Link to="/CashPayment" className="CancelButton">
                    Back to Cash Payments
                  </Link>
                  <button
                    type="button"
                    className="SaveButton_cash"
                    onClick={
                      CourseDetail.type === 4
                        ? () =>
                            handleSpayeeModelExam(
                              CourseDetail,
                              el,
                              admissionDetails,
                              el.exam_fees
                                .filter(
                                  (fee) =>
                                    fee.fees_count ===
                                    el.exam_details.filter(
                                      (el) => el.is_selected_exam
                                    ).length
                                )
                                .map((fee) => fee.fees) -
                                (list.coupon_amount +
                                  (list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? list.special_offer
                                      : list.old_stud_disc > list.special_offer
                                      ? 0
                                      : 0
                                    : list.special_offer
                                    ? list.special_offer
                                    : 0) +
                                  (list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? 0
                                      : list.old_stud_disc > list.special_offer
                                      ? list.old_stud_disc
                                      : 0
                                    : list.old_stud_disc
                                    ? list.old_stud_disc
                                    : 0)) +
                                0.18 *
                                  (el.exam_fees
                                    .filter(
                                      (fee) =>
                                        fee.fees_count ===
                                        el.exam_details.filter(
                                          (el) => el.is_selected_exam
                                        ).length
                                    )
                                    .map((fee) => fee.fees) -
                                    (list.coupon_amount +
                                      (list.old_stud_disc && list.special_offer
                                        ? list.old_stud_disc <
                                          list.special_offer
                                          ? list.special_offer
                                          : list.old_stud_disc >
                                            list.special_offer
                                          ? 0
                                          : 0
                                        : list.special_offer
                                        ? list.special_offer
                                        : 0) +
                                      (list.old_stud_disc && list.special_offer
                                        ? list.old_stud_disc <
                                          list.special_offer
                                          ? 0
                                          : list.old_stud_disc >
                                            list.special_offer
                                          ? list.old_stud_disc
                                          : 0
                                        : list.old_stud_disc
                                        ? list.old_stud_disc
                                        : 0))),
                              parseInt(
                                list.old_stud_disc && list.special_offer
                                  ? list.old_stud_disc < list.special_offer
                                    ? list.special_offer
                                    : list.old_stud_disc > list.special_offer
                                    ? 0
                                    : 0
                                  : list.special_offer
                                  ? list.special_offer
                                  : 0
                              ) +
                                parseInt(list.coupon_amount) +
                                parseInt(
                                  list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? 0
                                      : list.old_stud_disc > list.special_offer
                                      ? list.old_stud_disc
                                      : 0
                                    : list.old_stud_disc
                                    ? list.old_stud_disc
                                    : 0
                                ),
                              (el.exam_fees
                                .filter(
                                  (fee) =>
                                    fee.fees_count ===
                                    el.exam_details.filter(
                                      (el) => el.is_selected_exam
                                    ).length
                                )
                                .map((fee) => fee.fees) -
                                (list.coupon_amount +
                                  (list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? list.special_offer
                                      : list.old_stud_disc > list.special_offer
                                      ? 0
                                      : 0
                                    : list.special_offer
                                    ? list.special_offer
                                    : 0) +
                                  (list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? 0
                                      : list.old_stud_disc > list.special_offer
                                      ? list.old_stud_disc
                                      : 0
                                    : list.old_stud_disc
                                    ? list.old_stud_disc
                                    : 0))) *
                                0.18
                            )
                        : () =>
                            handleSPayfee(
                              CourseDetail,
                              el,
                              admissionDetails,
                              PayDue === "1"
                                ? list.PPayment === "1"
                                  ? PayDue === "1"
                                    ? CourseDetail.balance_amt
                                    : CourseDetail.paid_amount
                                  : el.dis_amt -
                                    list.coupon_amount +
                                    0.18 * (el.dis_amt - list.coupon_amount)
                                : CourseDetail.instment_pay[0]
                                    .payment_status !== "1"
                                ? list.PPayment === "1"
                                  ? CourseDetail.instment_pay[0].due_amount
                                  : Number(
                                      CourseDetail.instment_pay[0].due_amount
                                    ) +
                                    Number(
                                      CourseDetail.instment_pay[1].due_amount
                                    )
                                : CourseDetail.instment_pay[1].due_amount
                            )
                    }
                  >
                    <a href="javascript:void(0)">
                      Amount Collected and Submitted
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </form>
        ))}
    </div>
  );
}
export default CashPayFee;
