import React, { useEffect, useState } from 'react'
import './Gallery.css';
import { Error } from '../../../../Themes/themes';
import { AxiosInstance } from '../../../../axios/index';
import { useParams } from 'react-router-dom';
import {  Link } from 'react-router-dom';


function GalleryEdit() {

const {id} = useParams();

const[editcourse,setEditcourse]=useState([]);

// setting error value for all input fields
const [error, setError] = useState({});
const [imageFile, setImageFile] = useState([]);
const [videoFile, setVideoFile] = useState([]);
// const [imageFile, setImageFile] = useState({});


// /**fetch the data for the id from the url */
//   useEffect(() => {
//      const coursestoredItem = localStorage.getItem('gallerydata');
//   if (coursestoredItem) {
//     const courses = JSON.parse(coursestoredItem);
//     const selectedCourse = courses.filter((course, i) => i == id);
//     setEditcourse(selectedCourse);
//   }
//   }, []);



  /**
   * is saved function
   */
  function save() {
    alert('Gallery details Saved Successfully');
  }


/**assign the edit datas to the form in all fields */
  // useEffect(() => {
  //   if (editcourse.length) {
  //     setInitialfields({
  //       ...initialfields,
  //       courseCategory: editcourse[0].courseCategory,
  //       testMode: editcourse[0].testMode,
  //       fees: editcourse[0].fees,
  //       duration: editcourse[0].duration,
  //       startDate: editcourse[0].startDate,
  //     });
  //   }
  // }, [editcourse]);


// validate form fields and setting error
    const errors = {};

    const validate = (data) => {
      // const currentDate = new Date();
      // const selectedDate = new Date(data.startDate);
  
      // if(data.courseCategory==""){
      //      errors.courseCategory = 'Please select course Category.';
      // }
      // else if(data.testMode==""){
      //     errors.testMode = 'Please select Test Mode.';
      // }
      // else if(data.fees==""){
      //     errors.fees = 'Please Enter Fees Amount.';
      // }
      // else if(data.fees.length >= 10){
      //     errors.fees = 'Fees amount length exists';
      // }
      // else if(data.fees <= 0){
      //     errors.fees = 'Fees should be greater than 0.';
      // }
      // else if(data.duration==""){
      //     errors.duration = 'Please Select Duration.';
      // }
      // else if(data.startDate==""){
      //     errors.startDate = 'Please Select start Date.';
      // }
      // else if (selectedDate.toDateString() === currentDate.toDateString()) {
      // errors.startDate = "Start date cannot be today's date";
      // }
      // else if(selectedDate < currentDate){
      //     errors.startDate = 'Start Date cannot be in the past.';
      // }
      return errors;
    };

    /**
 * Handle change event for password fields.
 * Updates the corresponding field value in state based on the input field's name.
 * @param {object} event - The change event object.
 * @param {string} event.target.name - The name of the input field.
 * @param {string} event.target.value - The value of the input field.
 */
  //   const handleChange = (event) => {
  //     // console.log(event.target.name,event.target.value,"...handlechange...");
  //     const { name, value, id, checked } = event.target;
  //     console.log(event.target.id,event.target.value,"...handlechange...");

  //     // set input values into state
  //       setInitialfields((prevFields) => ({
  //         ...prevFields,
  //         [name]: value,
  //       }));
      

  // // set error values into state
  //     setError({
  //       ...error,
  //       [name]: ''
  //     });
  //   };

const handleChange = (event) => {
  const file = event.target.files[0];
  const name = event.target.name;
  if (file) {
    if (name === "imagefile") {
      setImageFile(file.name);
      // const existingData = localStorage.getItem("imageFile")|| "[]";
      // const parsedData = JSON.parse(existingData);
      // parsedData.push(file.name);
      // parsedData.push("https://i0.wp.com/aiasabook.com/wp-content/uploads/2021/11/bs_gr-ii_em_07.jpg?resize=600%2C600&ssl=1");
      // localStorage.setItem("imageFile", JSON.stringify(parsedData));
    } else if (name === "videofile") {
      setVideoFile(file.name);
      //  const existingData = localStorage.getItem("videoFile")|| "[]";
      // const parsedData = JSON.parse(existingData);
      // // parsedData.push(file.name);
      // parsedData.push("https://player.vimeo.com/external/406187011.sd.mp4?s=0d901d18f0f53de29354038b966b02223f682fca&profile_id=164&oauth2_token_id=57447761");
      // localStorage.setItem("videoFile", JSON.stringify(parsedData));
    }
  }
};



    /**
 * Handles submission of the form when form submits.
 * Saves the updated form values to local storage.
 * @param {Object} e - The form submission event.
 */

const handleSubmit = async (e) => {
  e.preventDefault();
  // const errors = validate(initialfields);

  if (Object.keys(errors).length === 0) {
    try {
      const existingimageData = localStorage.getItem("imageFile")|| "[]";
      const parsedimageData = JSON.parse(existingimageData);
      const existingvideoData = localStorage.getItem("videoFile")|| "[]";
      const parsedvideoData = JSON.parse(existingvideoData);
      // parsedimageData.push(file.name);
      parsedimageData.push("https://i0.wp.com/aiasabook.com/wp-content/uploads/2021/11/bs_gr-ii_em_07.jpg?resize=600%2C600&ssl=1");
      localStorage.setItem("imageFile", JSON.stringify(parsedimageData));
      // parsedvideoData.push(file.name);

      parsedvideoData.push("https://player.vimeo.com/external/406187011.sd.mp4?s=0d901d18f0f53de29354038b966b02223f682fca&profile_id=164&oauth2_token_id=57447761");
      localStorage.setItem("videoFile", JSON.stringify(parsedvideoData));
        // If no id is provided, add the new data to the end of the array
        // parsedData.push(initialfields);
          // try {
        //   const response = await AxiosInstance.post("/api/user/register",{batch_id:selectedIds});
        //   console.log(response.data);
        //   if(response.status="true")
        //   {
        //     save();
        //     window.location.href = '/batch';
        //   }
        // } catch (error) {
        //   console.log(error);
        // }
    
        save();
        window.location.href = '/gallery';

      // localStorage.setItem("gallerydata", JSON.stringify(parsedData));
    } catch (error) {
      console.log(error);
    }
  } else {
    setError(errors);
  }
};

  return <div>

<form onSubmit={handleSubmit} >
 {id? (
 <div>
  <h4>Edit Gallery Details</h4>
  <div class="form-row">
<div class="form-group col-md-6">
  <div class="mb-3">
 <label for="imagefile" class="form-label">Upload image</label>
  <input class="form-control" type="file" id="imagefile" name='imagefile' onChange={handleChange}/>
  <img  style={{height:'50px',width:'50px'}} src={imageFile} alt="image"></img>
</div>
 </div>
 <Error>{error.imagefile && <small className="error">{error.imagefile}</small>}</Error>
 </div>
     
<div class="form-row">
 <div class="form-group col-md-6">
 <div class="mb-3">
   <label for="videofile" class="form-label">Upload Video</label>
   <input class="form-control" type="file" id="videofile" name='videofile' onChange={handleChange} />
<video width="320" height="240" controls>
  <source src="https://vod-progressive.akamaized.net/exp=1680210073~acl=%2Fvimeo-prod-skyfire-std-us%2F01%2F1237%2F16%2F406187011%2F1739831788.mp4~hmac=622544577c49465771f435df79ca4f0d9a4f1b9e22ebb191e2933b2a8a615ee2/vimeo-prod-skyfire-std-us/01/1237/16/406187011/1739831788.mp4" type="video/mp4"/>
  Your browser does not support the video tag.
</video>
 </div>
 </div>
 <Error>{error.videofile && <small className="error">{error.videofile}</small>}</Error>
</div> 
<div class="col-sm-4 mt-3">
 <button type="submit" class="btn btn-primary col-sm-4 ">Save</button>
   <button type="button" class="btn btn-secondary col-sm-4 ms-5"><Link to="/gallery"  className='gallery_cancel'>Cancel</Link></button>
 </div>  
</div>
 
 ):(
 <div>
 <h4>Create Gallery Details</h4>
  <div class="form-row">
<div class="form-group col-md-6">
  <div class="mb-3">
 <label for="imagefile" class="form-label">Upload image</label>
  <input class="form-control" type="file" id="imagefile" name='imagefile' onChange={handleChange} />
  {/* <img src={initialfields.imagefile} alt="image"></img> */}
</div>
 </div>
 <Error>{error.imagefile && <small className="error">{error.imagefile}</small>}</Error>
 </div>
     
<div class="form-row">
 <div class="form-group col-md-6">
 <div class="mb-3">
   <label for="videofile" class="form-label">Upload Video</label>
   <input class="form-control" type="file" id="videofile" name='videofile' onChange={handleChange} />
 </div>
 </div>
 <Error>{error.videofile && <small className="error">{error.videofile}</small>}</Error>
</div> 
<div class="col-sm-4 mt-3">
 <button type="submit" class="btn btn-primary col-sm-4 ">Save</button>
   <button type="button" class="btn btn-secondary col-sm-4 ms-5"><Link to="/gallery"  className='gallery_cancel'>Cancel</Link></button>
 </div>  
 
</div>
)
}
</form>
  </div>;
}

export default GalleryEdit
