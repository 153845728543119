import React from "react";
import "./Question.css";
import lo from  './images/img1.png'
//display type 6 means - mcq with image 
// sample design 


function Qtypeseven(props) {
  const { index } = props;


  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

const splitLines = props.list.tm_question.split("\n");
const EsplitLines = props.list.en_question.split("\n");
const image =  props.list.en_question !="" && props.list.en_question !=null  && EsplitLines.length > 1 ? EsplitLines[1].split(" - ") : [];
const image_tm = props.list.tm_question !="" && props.list.tm_question !=null  && splitLines.length > 1 ? splitLines[1].split(" - ") : [];
const imgTag = image[0] === "image" ? image[1] : "";
const imgTag_tm = image_tm[0] === "image" ? image_tm[1] : "";

  return (
    <div className=" Question_temp">
      {props.list.en_question !==null && props.list.en_question !=="" && (
        <div className="row">
          <div className="col-md-12">
          <span className="question_labels">
              {EsplitLines.length > 0 ? EsplitLines[0] : ""}
            </span>
          </div>
          <div className="col-md-12">
          <img src={imgTag} className="imgtn"/>
          </div>
          {EsplitLines.map((line, index) => {
  if (line.startsWith('imgtext - ')) {
    return (
      <div className="col-md-12" key={index}>
        <span className="question_labels">{line.substring(10)}</span>
      </div>
    );
  }
})}<div className="col-md-12 mt-2">
            <div className="row">
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_1)}
                    value={props.list.options_en_1}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(A) {props.list.options_en_1} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_2)}
                    name={`q_answer_${index}`}
                    value={props.list.options_en_2}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(B) {props.list.options_en_2} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_3)}
                    value={props.list.options_en_3}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(C) {props.list.options_en_3} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_4)}
                    name={`q_answer_${index}`}
                    value={props.list.options_en_4}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(D) {props.list.options_en_4} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.list.tm_question !==null && props.list.tm_question !=="" && splitLines[0]!="" &&(
        <div className="row tamilQues">
          <div className="col-md-12">
          <span className="question_labels">
              {splitLines.length > 0 ? splitLines[0] : ""}
            </span>
          </div>
          <div className="col-md-12">
           <img src={imgTag_tm} className="imgtn"/>
          </div>
          {splitLines.map((line, index) => {
  if (line.startsWith('imgtext - ')) {
    return (
      <div className="col-md-12" key={index}>
        <span  className="question_labels">{line.substring(10)}</span>
      </div>
    );
  }
})}
          <div className="col-md-12 mt-2">
            <div className="row">
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    className="radioInput"
                    type="radio"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_1)}
                    value={props.list.options_tm_1}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(A) {props.list.options_tm_1} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    className="radioInput"
                    type="radio"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_2)}
                    value={props.list.options_tm_2}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(B) {props.list.options_tm_2} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    className="radioInput"
                    type="radio"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_3)}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    value={props.list.options_tm_3}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(C) {props.list.options_tm_3} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_4)}
                    className="radioInput"
                    name={`q_answer_${index}`}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    value={props.list.options_tm_4}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(D) {props.list.options_tm_4} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Qtypeseven;

