import React from "react";
import "../question/Question.css";

//display type 10 and para_que 2   means - para question  mcq design

function RtypeParaOption(props) {
  // console.log(props);
  const { index } = props;
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  return (
    <div className=" Question_temp ">
      {props.list.en_question !==null && props.list.en_question !=="" && (
        <div className="row">
          <div className="col-md-12">
            <span className="question_labels">{props.list.en_question}</span>
          </div>
          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-md-6">
                <div className="forms_input">
                  {(props.type != "1" && props.list.para_que === 2) && (
                    <input
                      type="radio"
                      className="radioInput"
                      name={`q_answer_${index}`}
                      checked={props.list.test_lang === 1 &&  props.list.para_que === 2 && props.list.user_answer!=null && props.list.user_answer_column!=null && props.list.user_answer === props.list.options_en_1}
                      value={props.list.options_en_1}
                      // disabled = {props.formData[0].lang === 0 ? true : false}
                    />
                  )}{" "}
                  <span
                    className={
                      props.type != "1" ? "answer_label" : "answer_labela"
                    }
                  >
                    (A) {props.list.options_en_1}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="forms_input">
                  {(props.type != "1" && props.list.para_que === 2) && (
                    <input
                      type="radio"
                      className="radioInput"
                      name={`q_answer_${index}`}
                      value={props.list.options_en_2}
                      checked={props.list.test_lang === 1 && props.list.para_que === 2 && props.list.user_answer!=null && props.list.user_answer_column!=null && props.list.user_answer === props.list.options_en_2}
                      // disabled = {props.formData[0].lang === 0 ? true : false}
                    />
                  )}{" "}
                 <span
                    className={
                      props.type != "1" ? "answer_label" : "answer_labela"
                    }
                  >
                    (B) {props.list.options_en_2}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="forms_input">
                  {(props.type != "1" && props.list.para_que === 2)&& (
                    <input
                      type="radio"
                      className="radioInput"
                      name={`q_answer_${index}`}
                      value={props.list.options_en_3}
                      checked={props.list.test_lang === 1 && props.list.para_que === 2 && props.list.user_answer!=null && props.list.user_answer_column!=null && props.list.user_answer === props.list.options_en_3}
                      // disabled = {props.formData[0].lang === 0 ? true : false}
                    />
                  )}{" "}
                <span
                    className={
                      props.type != "1" ? "answer_label" : "answer_labela"
                    }
                  >
                    (C) {props.list.options_en_3}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="forms_input">
                  {(props.type != "1" && props.list.para_que === 2) && (
                    <input
                      type="radio"
                      className="radioInput"
                      name={`q_answer_${index}`}
                      checked={props.list.test_lang === 1 && props.list.para_que === 2 && props.list.user_answer!=null && props.list.user_answer_column!=null && props.list.user_answer === props.list.options_en_4}
                      value={props.list.options_en_4}
                      // disabled = {props.formData[0].lang === 0 ? true : false}
                    />
                  )}{" "}
                   <span
                    className={
                      props.type != "1" ? "answer_label" : "answer_labela"
                    }
                  >
                    (D) {props.list.options_en_4}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* {props.type != "1" && (
            <div className="col-md-12 mt-3">
              <h6 className="user_answer">Your Answer</h6>
              <span className="user_label_answer">
              {props.type != "1"  ? 
  (props.list.user_answer_column === "options_en_1" || props.list.user_answer_column === "options_tm_1" ? "A" :
  props.list.user_answer_column === "options_en_2" || props.list.user_answer_column === "options_tm_2" ? "B" :
  props.list.user_answer_column === "options_en_3" || props.list.user_answer_column === "options_tm_3" ? "C" :
  props.list.user_answer_column === "options_en_4" || props.list.user_answer_column === "options_tm_4" ? "D" :
  "_") 
  : props.list.answer_en }
              </span>
            </div>
          )}
          <div className="col-md-12 mt-3">
            <h6 className="user_answer">Correct Answer</h6>
            <span className="user_label_answer">
            {props.type != "1" ? 
  (props.list.correct_answer_column === "options_en_1" || props.list.correct_answer_column === "options_tm_1" ? "A" :
  props.list.correct_answer_column === "options_en_2" || props.list.correct_answer_column === "options_tm_2" ? "B" :
  props.list.correct_answer_column === "options_en_3" || props.list.correct_answer_column === "options_tm_3" ? "C" :
  props.list.correct_answer_column === "options_en_4" || props.list.correct_answer_column === "options_tm_4" ? "D" :
  "_") 
  : props.list.answer_en }
            </span>
          </div> */}
        </div>
      )}
      {props.list.tm_question !==null && props.list.tm_question !=="" && (
        <div className="row tamilQues">
          <div className="col-md-12">
          <span className="question_labels">
              { props.list.tm_question}
            </span>
          </div>
          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-md-6">
                <div className="forms_input">
                  {(props.type != "1" && props.list.para_que === 2) && (
                    <input
                      type="radio"
                      className="radioInput"
                      name={`q_answer_${index}`}
                      checked={props.list.test_lang === 0 && props.list.para_que === 2 && props.list.user_answer!=null && props.list.user_answer_column!=null && props.list.user_answer === props.list.options_tm_1}
                      value={props.list.options_tm_1}
                      // disabled = {props.formData[0].lang === 1 ? true : false}
                    />
                  )}{" "}
                   <span
                    className={
                      props.type != "1" ? "answer_label" : "answer_labela"
                    }
                  >
                    (A) {props.list.options_tm_1}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="forms_input">
                  {(props.type != "1" && props.list.para_que === 2) && (
                    <input
                      type="radio"
                      className="radioInput"
                      name={`q_answer_${index}`}
                      checked={props.list.test_lang === 0 && props.list.para_que === 2 && props.list.user_answer!=null && props.list.user_answer_column!=null && props.list.user_answer === props.list.options_tm_2}
                      value={props.list.options_tm_2}
                      // disabled = {props.formData[0].lang === 1 ? true : false}
                    />
                  )}{" "}
                    <span
                    className={
                      props.type != "1" ? "answer_label" : "answer_labela"
                    }
                  >
                    (B) {props.list.options_tm_2}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="forms_input">
                  {(props.type != "1" && props.list.para_que === 2) && (
                    <input
                      type="radio"
                      className="radioInput"
                      checked={props.list.test_lang === 0 && props.list.para_que === 2 && props.list.user_answer!=null && props.list.user_answer_column!=null && props.list.user_answer === props.list.options_tm_3}
                      name={`q_answer_${index}`}
                      title={props.list.user_answer}
                      // disabled = {props.formData[0].lang === 1 ? true : false}
                      value={props.list.options_tm_3}
                    />
                  )}{" "}
                    <span
                    className={
                      props.type != "1" ? "answer_label" : "answer_labela"
                    }
                  >
                    (C) {props.list.options_tm_3}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="forms_input">
                  {(props.type != "1" && props.list.para_que === 2)  && (
                    <input
                      type="radio"
                      className="radioInput"
                      name={`q_answer_${index}`}
                      checked={props.list.test_lang === 0 && props.list.para_que === 2 && props.list.user_answer!=null && props.list.user_answer_column!=null && props.list.user_answer === props.list.options_tm_4}
                      // disabled = {props.formData[0].lang === 1 ? true : false}
                      value={props.list.options_tm_4}
                    />
                  )}{" "}
                  <span
                    className={
                      props.type != "1" ? "answer_label" : "answer_labela"
                    }
                  >
                    (D) {props.list.options_tm_4}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* {props.type != "1" && (
            <div className="col-md-12 mt-3">
              <h6 className="user_answer">உங்கள் விடை</h6>
              <span className="user_label_answer">
                {props.type !== "1"
                  ? props.list.user_answer_column === "options_en_1" ||
                    props.list.user_answer_column === "options_tm_1"
                    ? "A"
                    : props.list.user_answer_column === "options_en_2" ||
                      props.list.user_answer_column === "options_tm_2"
                    ? "B"
                    : props.list.user_answer_column === "options_en_3" ||
                      props.list.user_answer_column === "options_tm_3"
                    ? "C"
                    : props.list.user_answer_column === "options_en_4" ||
                      props.list.user_answer_column === "options_tm_4"
                    ? "D"
                    :"_"
                  : props.list.answer_tm}
              </span>
            </div>
          )}
          <div className="col-md-12 mt-3">
            <h6 className="user_answer">சரியான விடை</h6>
            <span className="user_label_answer">
              {props.type != "1"
                ? props.list.correct_answer_column === "options_en_1" ||
                  props.list.correct_answer_column === "options_tm_1"
                  ? "A"
                  : props.list.correct_answer_column === "options_en_2" ||
                    props.list.correct_answer_column === "options_tm_2"
                  ? "B"
                  : props.list.correct_answer_column === "options_en_3" ||
                    props.list.correct_answer_column === "options_tm_3"
                  ? "C"
                  : props.list.correct_answer_column === "options_en_4" ||
                    props.list.correct_answer_column === "options_tm_4"
                  ? "D"
                  : "_"
                : props.list.answer_tm}
            </span>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default RtypeParaOption;
