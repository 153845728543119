import { configureStore } from "@reduxjs/toolkit";
import ChartReducer from "./ChartSlice";
// const { configureStore } = require("@reduxjs/toolkit");

const store = configureStore({
  reducer: {
    chart: ChartReducer,
  },
});

export default store;
