import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { Remove } from '@mui/icons-material'
import { Delete } from '@mui/icons-material'
function AddToCardTemplate() {
  // console.log(props,"....ptp");

  // const [cart, setCart] = useState(localStorage.getItem("cart"));

  // useEffect(() => {
  //   const cartValue = localStorage.getItem("cart");
  //   if (cartValue !== cart) {
  //     setCart(cartValue);
  //   }
  // }, []);

  // console.log(cart,"....cartval....");

  const [carts, setCarts] = useState([]);
  const [cartitemcount, setCartitemcount] = useState([0]);


  useEffect(() => {
    const cartdata =localStorage.getItem("cart");

    if (cartdata) {
      // Get an array of all items in the cart
      try {
      const items = Object.values(JSON.parse(cartdata));
      
      // Use reduce to sum the quantity of all items in the cart
      const totalQuantity = items.reduce((a, currentValue) => {
        return a + Number(currentValue.quantity);
      }, 0);
      
      // console.log(`Total quantity in cart: ${totalQuantity}`);
      setCartitemcount([totalQuantity]);
} catch (error) {
  console.error("Error parsing cart data:", error);
}
    }
    if (cartdata) {
      setCarts(JSON.parse(cartdata));
    }
  }, []);

  const cartItems = JSON.parse(localStorage.getItem("cart")) || [];

  const subtotal = cartItems.reduce((accumulator, currentItem) => {
    return accumulator + Number(currentItem.quantity * currentItem.discountedPrice);
  }, 0);
  
  // const shippingcharges = cartItems.reduce((accumulator, currentItem) => {
  //   return accumulator + Number(currentItem.shipping_charges);
  // }, 0);

  const shippingcharges = 40;
  
  const totalprice = (Number(subtotal) + Number(shippingcharges)).toFixed(2); 


  function incrementquantity(id)
  {
    const cartlist = JSON.parse(localStorage.getItem('cart')) || {};
    const filteredcartid = cartlist.filter(item => item.id === id);
    const quantity = filteredcartid.reduce((acc, item) => {
      return item;
    }, 0);
    
    if (quantity.id  && quantity.quantity < 5 ) {
      quantity.quantity =  Number(quantity.quantity)+ 1; 
    }
    localStorage.setItem('cart', JSON.stringify(cartlist));
    window.location.reload();

  }

  function decrementquantity(id)
  {
    const cartlist = JSON.parse(localStorage.getItem('cart')) || {};
    const filteredcartid = cartlist.filter(item => item.id === id);
    const quantity = filteredcartid.reduce((acc, item) => {
      return item;
    }, 0);
    
    if (quantity.id  && quantity.quantity > 1) {
      quantity.quantity =  Number(quantity.quantity) - 1; 
    }
    localStorage.setItem('cart', JSON.stringify(cartlist));
    window.location.reload();

  }

function deletecart(id)
{
  const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
  const newCartItems = cartItems.filter((item) => item.id !== id);
  localStorage.setItem("cart", JSON.stringify(newCartItems));
  window.location.reload();
}

  return (
    <>
 
<div>
{/* {carts == 0 ? ( */}
    <section className="h-100 h-custom" style={{backgroundColor:'#fcf7eb'}}>
  <div className="container py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-12">
        <div className="card card-registration card-registration-2" style={{borderRadius:'15px'}}>
          <div className="card-body p-0">
            <div className="row g-0">
              <div className="col-lg-8">
                <div className="p-5">
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    <h1 className="fw-bold mb-0 text-black">Shopping Cart</h1>
                    <h6 className="mb-0 text-muted"></h6>
                  </div>
                  <hr className="my-4"/>
                  {carts.length != 0 ? (
                    <div>
                  {carts.map((cart)=> 

                <div>
                  
                                  {/* <h5> quantity {cart.quantity}</h5>  */}

                  <div className="row mb-4 d-flex justify-content-between align-items-center">
                    <div className="col-md-2 col-lg-2 col-xl-2">
                      <img
                        src={cart.images}
                        className="img-fluid rounded-3" alt="Cotton T-shirt"/>
                    </div>
                    <div className="col-md-2 col-lg-3 col-xl-3">
                      <h6 className="text-muted">{cart.title}</h6>
                      <h6 className="text-black mb-0">{cart.name}</h6>
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-2 d-flex">
                    <button className="btn btn-danger px-3 me-2 h-25" onClick={() => decrementquantity(cart.id)}
                    // onClick={"this.parentNode.querySelector('input[type=number]').stepDown()"}
                    >
                   <Remove/>
                  </button>
                  <div className="form-outline">
                    <input id="form1" min="0" name="quantity" value={cart.quantity} type="number" className="form-control" />
                    <label className="form-label" htmlFor="form1">Quantity</label>
                  </div>

                  <button className="btn btn-danger px-3 ms-2 h-25" onClick={() => incrementquantity(cart.id)}
                                            // onClick={"this.parentNode.querySelector('input[type=number]').stepUp()"}
                                            >

                   <Add/>
                  </button>
                    </div>

                    <div className="col-md-4 col-lg-2 col-xl-2 offset-lg-1">
                      <h6 className="mb-3 mt-0 me-0 ms-2">Price: ₹{cart.discountedPrice.toFixed(2)}</h6>
                    </div>
                    <div className="mb-4 col-md-1 col-lg-1 col-xl-1 ">
                    <Delete color="error" onClick={()=>deletecart(cart.id)}/>
                    </div>

                    {/* <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                      <a href="#!" className="text-muted">ff<i className="fas fa-times"></i></a>
                    </div> */}
                  </div>

                  <hr className="my-4"/>
</div>
)} 
</div>
 ):(
  <div className='alert alert-danger mx-3 my-5'>Cart is empty</div>
)} 
                  <div className="pt-3">
                    <h6 className="mb-0">
                        <i
                          className="fas fa-long-arrow-alt-left me-2"></i><Link  style={{textDecoration:'none',color:'black'}} to='/'>Back to shop</Link></h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-4" style={{backgroundColor:'lightgrey'}}>
                <div className="p-5">
                  <h3 className="fw-bold mb-5 mt-2 pt-1">Order Summary</h3>
                  <hr className="my-4"/>

                  {/* <div className="d-flex flex-column align-items-start"> */}
                  <div className="d-flex flex-row justify-content-between">    

                    <h5 className="text-capitalize">items</h5>
                    <h5 className="text-uppercase">{cartitemcount}</h5>
                    </div>          

                                        {/* <div className="d-flex flex-row justify-content-between">     */}
                                        {carts.length != 0 ? (
                    <div>
                  {carts.map((cart)=> 
                
  <div className="d-flex flex-row justify-content-between">   
                     <small className='text-start'>
          {cart.quantity} x {cart.title +'-'+ cart.name}:
        </small>
        <small>
           ₹{(cart.quantity * cart.discountedPrice).toFixed(2)}
        </small>
      </div>
                  
                  )} 
</div>
 ):('')}
                    {/* </div> */}
                    <div className="d-flex flex-row justify-content-between">    
                    <h5>Subtotal:</h5>
                    <h5> ₹{subtotal.toFixed(2)}</h5>
                    </div>
                    <div className="d-flex flex-row justify-content-between">    
                    <h5>Shipping charges:</h5>
                    <h5> ₹{shippingcharges.toFixed(2)}</h5>
                    </div>
                    {/* <div className="d-flex flex-row justify-content-between">    
                    <h5>Total(Incl. taxes):</h5>
                    <h5> ₹{totalprice}</h5>
                    </div><br/> */}
                    <div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label"></label>
  <h4>Shipping Address</h4>

  <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"  placeholder="Enter your address to proceed" ></textarea>
</div>
                  <h5 className="text-uppercase mb-3">Apply Coupon</h5>

                  <div className="mb-2">
                    <div className="form-outline">
                      <input type="text" id="form3Examplea2" className="form-control form-control-lg" placeholder="Enter your code" />
                      <label className="form-label" htmlFor="form3Examplea2"></label>
                    </div>
                  </div>
                  <hr className="my-3"/>
                  <div className="d-flex justify-content-between mb-5">
                    <h5 className="text-capitalize">Total price</h5>
                    <h5> ₹{totalprice}</h5>
                    
                  </div>
                  <div className="d-flex flex-row justify-content-between">  
                  <button type="button" className="btn btn-danger btn-block "
                    data-mdb-ripple-color="dark">Place order</button>
                  <button type="button" className="btn btn-danger btn-block "
                    data-mdb-ripple-color="dark"><Link  style={{textDecoration:'none',color:'white'}} to='/'>Cancel</Link></button>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</div>

  </>
  )
}

export default AddToCardTemplate
