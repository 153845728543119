import React, { useState, useRef, useEffect } from "react";

import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import { CloseOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { styled } from "@mui/material/styles";
import StatusCheck from "./StatusCheck";
import DatePickerSample from "../../DatePicker";
function TestBatchView(props) {
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);

  // List data load table

  // use state
  const currentDate = moment();
  const startOfDay = moment(currentDate).startOf("day");
  const tenAM = startOfDay.clone().hour(10);
  let page = R_PER_PAGE;

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [csvListData, setCsvListData] = useState([]);
  const [isOpen, setIsOpen] = useState({
    status: false,
    time: false,
    startDate: false,
    schedule: false,
    category: false,
    // course:false,
    medium: false,
    classMode: false,
    location: false,
  });
  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });
  const [appData_on, setAppData_on] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });
  const [timedata, setTimedate] = useState({
    STime: "",
    TTime: "",
    name: "",
  });

  const [updateChipData, setUpdateChipData] = useState([]);
  const [location, setLocation] = useState([]);
  const [status, setStatus] = useState([]);
  const [appOn, setAppOn] = useState([]);
  const [appOn_on, setAppOn_on] = useState([]);
  const [typeOn, setTypeOn] = useState([]);
  const [time, setTime] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [classModes, setClassModes] = useState([]);
  const [mediums, setMedium] = useState([]);
  let filterStatus = [];
  let filterType = [];
  let filterTime = [];
  let filterSchedule = [];
  let filterClassMode = [];
  let filterMedium = [];
  let filterApplyed = [];
  let filterSearch = [];
  let filterApplyed_on = [];
  let filterLocation = [];
  useEffect(() => {
    loadChipdata();
  }, []);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    filterStatus = status;
  }, [status]);
  useEffect(() => {
    filterClassMode = classModes;
  }, [classModes]);
  useEffect(() => {
    filterSchedule = schedule;
  }, [schedule]);
  useEffect(() => {
    filterLocation = location;
  }, [location]);
  useEffect(() => {
    filterMedium = mediums;
  }, [mediums]);
  useEffect(() => {
    filterApplyed_on = appOn_on;
  }, [appOn_on]);
  useEffect(() => {
    filterType = typeOn;
  }, [typeOn]);

  useEffect(() => {
    filterApplyed = appOn;
  }, [appData]);

  useEffect(() => {
    filterTime = time;
  }, [time]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    if (props.activeTab === 2) {
      loadListdata();
    }
  }, [props.activeTab]);

  useEffect(() => {
    if (props.activeTab === 2) {
      loadListdata();
    }
  }, [updateChipData]);
  // useEffect(() => {
  //   console.log(" updateListData : ", updateListData);
  //   currentData = updateListData.slice(start, end);
  //   // total table data  with pages
  //   totalPages = Math.ceil(updateListData.length / rowsPerPage);
  // }, [updateListData]);

  function updateStateData() {
    filterStatus = status;
    filterType = typeOn;
    filterApplyed = appOn;
    filterLocation = location;
    filterApplyed_on = appOn_on;
    filterTime = time;
    filterSchedule = schedule;
    filterClassMode = classModes;
    filterMedium = mediums;
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  const handleCheckboxChange = (event, names, typename) => {
    // console.log(names, " : ", status);
    const { value, checked, name } = event.target;
    if (name === "Upcoming" || name === "Ongoing" || name === "Completed") {
      if (checked) {
        // setStatus([...status, value]);
        setStatus([...status, { id: value, name: name, type: "status" }]);
      } else {
        setStatus(status.filter((item) => item.id !== value));
      }
    } else if (names === "location") {
      if (checked) {
        // setStatus([...status, value]);
        setLocation([...location, { id: value, name: name, type: "location" }]);
      } else {
        setLocation(location.filter((item) => item.id !== value));
      }
    } else if (typename === "type") {
      if (checked) {
        setTypeOn([...typeOn, { name: value, type: "type" }]);
      } else {
        setTypeOn(typeOn.filter((item) => item.name !== value));
      }
    } else if (name === "Weekend" || name === "Regular") {
      if (checked) {
        setSchedule([...schedule, { id: value, name: name, type: "schedule" }]);
      } else {
        setSchedule(schedule.filter((item) => item.id !== value));
      }
    } else if (name === "Classroom" || name === "Online") {
      if (checked) {
        setClassModes([
          ...classModes,
          { id: value, name: name, type: "classMode" },
        ]);
      } else {
        setClassModes(classModes.filter((item) => item.id !== value));
      }
    } else if (name === "English" || name === "Tamil") {
      if (checked) {
        setMedium([...mediums, { id: value, name: name, type: "medium" }]);
      } else {
        setMedium(mediums.filter((item) => item.id !== value));
      }
    }
  };

  // handle search function
  const handleSearch = (event) => {
    // console.log(event);
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setStatus([]);
    setSchedule([]);
    setTime([]);
    setLocation([]);
    setAppOn_on([]);
    setClassModes([]);
    setUpdateChipData([]);
    setAppOn([]);
    setMedium([]);
    setTypeOn([]);
    setAppData({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setAppData_on({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setUpdateListData(listData);
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        status: false,
      });
    } else if (name === "appliedOn") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "appliedOn_on") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        created_on: false,
      });
    } else if (name === "location") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        location: false,
      });
    } else if (name === "type") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "time") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        time: false,
      });
    } else if (name === "schedule") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        schedule: false,
      });
    } else if (name === "classMode") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        classMode: false,
      });
    } else if (name === "medium") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        medium: false,
      });
    }
  };

  function loadListdata() {
    let tempstatus = [];
    let temptypeon = [];
    let tempsche = [];
    let tempclassMode = [];
    let tempLocanum = [];
    let templocation = [];
    let tempMedium = [];
    let tempstatusnum = [];
    status.map((obj) => {
      tempstatus.push(obj.id);
    });

    classModes.map((obj) => {
      tempclassMode.push(obj.name);
    });

    mediums.map((obj) => {
      tempMedium.push(obj.name);
    });
    location.map((obj) => {
      templocation.push(obj.id);
    });
    schedule.map((obj) => {
      tempsche.push(obj.name);
    });

    typeOn.map((obj) => {
      temptypeon.push(obj.name);
    });
    tempLocanum = templocation.map(Number);
    tempstatusnum = tempstatus.map(Number);
    let statusFilter = {
      category: typeOn.length > 0 ? temptypeon : "",
      status: status.length > 0 ? tempstatusnum : "",
      batch_type: schedule.length > 0 ? tempsche : "",
      medium: mediums.length > 0 ? tempMedium : "",
      class_mode: classModes.length > 0 ? tempclassMode : "",
      start_date: appOn.length > 0 ? appOn[0].fdate + " 00:00:00" : "",
      end_date: appOn.length > 0 ? appOn[0].edate + " 23:59:00" : "",
      start_time: time.length > 0 ? [time[0].STime] : "",
      c_start_date: appOn_on.length > 0 ? appOn_on[0].fdate : "",
      c_end_date: appOn_on.length > 0 ? appOn_on[0].edate : "",
      end_time: time.length > 0 ? [time[0].TTime] : "",
      search: searchTerm.length > 0 ? searchTerm : "",
      type: "1",
      location: location.length > 0 ? tempLocanum : "",
    };
    // console.log(statusFilter, "statusFilter");

    AxiosInstance.post("api/batch/list", statusFilter)
      .then((res) => {
        // console.log("editadasd ", res);
        if (res.data.status === true) {
          const fiatas = res.data.data;
          const fiata = fiatas.sort(
            (a, b) => new Date(b.created_on) - new Date(a.created_on)
          );
          const serialNumberAddedData = fiata.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
          // setUpdateListData(fiata);
          setUpdateListData(serialNumberAddedData);
          const csvData = fiata.map((el) => {
            return {
              name: el.name,
              category: el.category,
              category_class: el.category_class,
              class_mode: el.class_mode,
              medium: el.medium,
              batch_type: el.batch_type,
              started_dt: el.started_dt,
              location_name: el.location_name,
              created_on: el.created_on,
            };
          });
          setCsvListData(csvData);
        } else if (res.data.status === false) {
          setUpdateListData([]);
        }
      })
      .catch((error) => {
        // console.log("Error res: ", error.response.data);
        setUpdateListData([]);
        return error;
      });
  }

  function loadChipdata() {
    //setUpdateChipData([]);
    let tempArr = [];
    // setUpdateChipData(status);
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    filterStatus.map((obj) => {
      tempArr.push(obj);
    });
    filterLocation.map((obj) => {
      tempArr.push(obj);
    });
    filterType.map((obj) => {
      tempArr.push(obj);
    });
    filterTime.map((obj) => {
      tempArr.push(obj);
    });

    filterApplyed_on.map((obj) => {
      tempArr.push(obj);
    });

    filterApplyed.map((obj) => {
      tempArr.push(obj);
    });

    filterSchedule.map((obj) => {
      tempArr.push(obj);
    });

    filterClassMode.map((obj) => {
      tempArr.push(obj);
    });

    filterMedium.map((obj) => {
      tempArr.push(obj);
    });

    // console.log(tempArr, "tempArr");
    setUpdateChipData(tempArr);
  }

  const handleTime = (e) => {
    const { name, value, checked } = e.target;
    if (name === "STime" || name === "TTime") {
      setTimedate((prevState) => ({ ...prevState, [name]: value }));
      // console.log(timedata.STime);
      // console.log(timedata.TTime);
      const from = name === "STime" ? value : timedata.STime;
      const to = name === "TTime" ? value : timedata.TTime;
      const dateRange = `${from} - ${to}`;

      setTime([
        {
          name: dateRange,
          STime: from,
          TTime: to,
          type: "time",
        },
      ]);
    }
  };

  const handleAppliedOn = (e) => {
    const { name, value, checked } = e.target;
    if (name === "Days") {
      if (checked) {
        setAppData((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      }
    }
  };

  const handleAppliedOn_on = (e) => {
    const { name, value, checked } = e.target;
    if (name === "Days") {
      if (checked) {
        setAppData_on((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn_on([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange_on",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn_on([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange_on",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn_on([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange_on",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn_on([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange_on",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn_on([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange_on",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn_on([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange_on",
          },
        ]);
      }
    }
  };

  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "appliedOn") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "appliedOn_on") {
      setAppData_on({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn_on([]);
      filterApplyed_on = [];
      setIsOpen({
        ...isOpen,
        created_on: false,
      });
    } else if (name === "status") {
      // Clear the status array
      setStatus([]);
      filterStatus = [];
      setIsOpen({
        ...isOpen,
        status: false,
      });
      // setUpdateListData(listData);
    } else if (name === "type") {
      setTypeOn([]);
      filterType = [];

      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "time") {
      setTime([]);
      filterTime = [];

      setIsOpen({
        ...isOpen,
        time: false,
      });
    } else if (name === "schedule") {
      setSchedule([]);
      filterSchedule = [];

      setIsOpen({
        ...isOpen,
        schedule: false,
      });
    } else if (name === "classMode") {
      setClassModes([]);
      filterClassMode = [];

      setIsOpen({
        ...isOpen,
        classMode: false,
      });
    } else if (name === "medium") {
      setMedium([]);
      filterMedium = [];

      setIsOpen({
        ...isOpen,
        medium: false,
      });
    } else if (name === "location") {
      // Clear the status array
      setLocation([]);
      filterLocation = [];
      setIsOpen({
        ...isOpen,
        location: false,
      });
      // setUpdateListData(listData);
    }
    loadChipdata();
  };

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    // console.log(chipToDelete,"chipToDelete");
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "status") {
      setStatus(status.filter((item) => item.id !== chipToDelete.id));
      filterStatus = status.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "location") {
      setLocation(location.filter((item) => item.id !== chipToDelete.id));
      filterLocation = location.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "type") {
      setTypeOn(typeOn.filter((item) => item.name !== chipToDelete.name));
      filterType = typeOn.filter((item) => item.name !== chipToDelete.name);
    } else if (chipToDelete.type === "dayRange") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    } else if (chipToDelete.type === "dayRange_on") {
      setAppData_on({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn_on([]);
      filterApplyed_on = [];
    } else if (chipToDelete.type === "time") {
      setTimedate({
        STime: "",
        TTime: "",
        name: "",
      });
      setTime([]);
      filterTime = [];
    } else if (chipToDelete.type === "schedule") {
      setSchedule(schedule.filter((item) => item.id !== chipToDelete.id));
      filterSchedule = schedule.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "classMode") {
      setClassModes(classModes.filter((item) => item.id !== chipToDelete.id));
      filterClassMode = classModes.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else if (chipToDelete.type === "medium") {
      setMedium(mediums.filter((item) => item.id !== chipToDelete.id));
      filterMedium = mediums.filter((item) => item.id !== chipToDelete.id);
    } else {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    }
    loadChipdata();
  };

  const handleChangeDate = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    }
  };

  const handleChangeDate_app = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppData_on((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData_on.FDate;
      const to = name === "TDate" ? dateFilter : appData_on.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn_on([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dayRange_on",
        },
      ]);
    }
  };

  const handleFilter = (e, boxName) => {
    if (boxName === "status") {
      setIsOpen((prevState) => ({
        time: false,
        startDate: false,
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        classMode: false,
        location: false,
        status: !prevState.status,
      }));
    } else if (boxName === "time") {
      setIsOpen((prevState) => ({
        startDate: false,
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: !prevState.time,
      }));
    } else if (boxName === "location") {
      setIsOpen((prevState) => ({
        time: false,
        startDate: false,
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        classMode: false,
        status: false,
        location: !prevState.location,
      }));
    } else if (boxName === "created_on") {
      setIsOpen((prevState) => ({
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        created_on: !prevState.created_on,
      }));
    } else if (boxName === "startDate") {
      setIsOpen((prevState) => ({
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: !prevState.startDate,
      }));
    } else if (boxName === "schedule") {
      setIsOpen((prevState) => ({
        category: false,
        created_on: false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: !prevState.schedule,
      }));
    } else if (boxName === "category") {
      setIsOpen((prevState) => ({
        created_on: false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: false,
        category: !prevState.category,
      }));
    }
    // else if (boxName === "course") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     course: !prevState.course,
    //   }));
    // }
    else if (boxName === "medium") {
      setIsOpen((prevState) => ({
        category: false,
        created_on: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: false,
        medium: !prevState.medium,
      }));
    } else if (boxName === "classMode") {
      setIsOpen((prevState) => ({
        category: false,
        created_on: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: false,
        medium: false,
        classMode: !prevState.classMode,
      }));
    }
  };

  // generate pdf when user click download button
  // const generatePDF = () => {
  //   // Create a new jsPDF instance
  //   const doc = new jsPDF();

  //   // Add a title to the PDF document
  //   doc.text("Table Data", 10, 10);

  //   // Convert the table to a JavaScript array
  //   const tableData = [];

  //   // Add header row to tableData array
  //   const headers = [];
  //   const tableHeader = document.querySelectorAll("#example th");
  //   for (const header of tableHeader) {
  //     headers.push(header.innerText);
  //   }
  //   tableData.push(headers);

  //   // Add data rows to tableData array
  //   const tableRows = document.querySelectorAll("#example tbody tr");
  //   for (const row of tableRows) {
  //     const rowData = [];
  //     const rowCells = row.querySelectorAll("td");
  //     for (const cell of rowCells) {
  //       rowData.push(cell.innerText);
  //     }
  //     tableData.push(rowData);
  //   }

  //   // Add the table to the PDF document
  //   doc.autoTable({
  //     head: [tableData[0]], // Use the first row as the table header
  //     body: tableData.slice(1), // Use the remaining rows as the table body
  //   });
  //   // Save the PDF document
  //   doc.save("AdmissionData.pdf");
  // };
  const generatePDF = () => {
    // Create a new jsPDF instance
    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Landscape orientation
      unit: "in", // Use inches as the unit of measurement
      format: [9.5, 11], // Set the dimensions to a standard letter size (9.5 x 11 inches)
    });

    doc.setFont("arabic"); // Set the font to 'arabic'
    doc.setFontSize(12); // Adjust the desired font size
    // Add a title to the PDF document
    doc.text("Course batch data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    const headers = [
      "name",
      "category",
      "category_class",
      "class_mode",
      "medium",
      "location_name",
      "batch_type",
      "started_dt",
      "created_on",
    ]; // Assuming updateListData is your state variable
    tableData.push(headers);

    updateListData.forEach((item) => {
      const rowData = [];
      headers.forEach((header) => {
        rowData.push(item[header]);
      });
      tableData.push(rowData);
    });

    // Define the column widths for the header columns (adjust as needed)
    // Calculate column widths based on the content of header and tbody cells
    const headerColumnStyles = {};
    const tbodyColumnStyles = {};

    headers.forEach((header, index) => {
      const maxHeaderWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue = row[index] !== null ? row[index].toString() : ""; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 1 + 6; // Adjust the multiplier as needed
          })
      );
      const maxTbodyWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue = row[index] !== null ? row[index].toString() : ""; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 2 + 6; // Adjust the multiplier as needed
          }) // Adjust the multiplier as needed
      );

      headerColumnStyles[index] = { columnWidth: maxHeaderWidth };
      tbodyColumnStyles[index] = { columnWidth: maxTbodyWidth };
    });

    // Add data rows to tableData array
    const tableOptions = {
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
      // columnStyles: { ...headerColumnStyles, ...tbodyColumnStyles }, // Specify dynamic column widths
    };

    // Add the table to the PDF document
    doc.autoTable(tableOptions);

    // Save the PDF document
    doc.save("TestBatchData.pdf");
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;

    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      // setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  const handleReadView = (id, type) => {
    navigate(`/DetailViewBatch/${id}/${type}`);
  };

  const handleEditView = (type, id) => {
    navigate(`/createCourseBatch/${type}/${id}`);
  };
  // className="row tab-content mt-4"
  return (
    <div
      className={
        props.activeTab === 2 ? " tab-content mt-4" : " tab-content mt-4 hide "
      }
      id="ex2-content"
    >
      {/* <div
        className={
          props.activeTab === 2
            ? "tab-pane fade show active p-0 col-md-12"
            : "tab-pane fade col-md-12 "
        }
      > */}
      <div className="row ">
        <div className="col-lg-3 col-md-12">
          <div class="input-group">
            <input
              type="text"
              class="Inputs"
              id="SearchInput"
              name="SearchInput"
              placeholder="Search by Name and course"
              // onChange={handleSearch}
              onKeyDown={handleSearch}
            />
            <span class="input-group-text" id="SearchInput">
              <span className="material-icons">search</span>
            </span>
          </div>
        </div>
        <div className="col-lg-3 col-md-2"></div>
        <div className="col-lg-6  dflex col-md-12 justify-content-md-between justify-content-lg-end">
          <button type="button" className="ListViewBtn me-3">
            <span className="material-icons me-2 fs-4 align-middle text-white">
              file_download
            </span>
            <CSVLink data={csvListData} filename={"TestBatch.csv"}>
              Download as CSV
            </CSVLink>
          </button>
          <button
            type="button"
            className="ListViewBtn me-3"
            onClick={generatePDF}
          >
            <span className="material-icons me-2 fs-4 align-middle text-white">
              file_download
            </span>
            <Link to="javascript:void(0)">Download as PDF</Link>
          </button>
          <button type="button" className="ListViewBtn">
            <Link to="/createCourseBatch/1">
              <span className="material-icons me-2 fs-4 align-middle text-white">
                add_circle_outline
              </span>
            </Link>
            <Link to="/createCourseBatch/1"> Create New Test Batch</Link>
          </button>
        </div>
      </div>
      {updateChipData.length > 0 ? (
        <div className="row mt-3">
          <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {updateChipData.map((data, index) => {
                // console.log(data, " - data - ", index);
                let icon;
                let key = "" + data.name;
                return (
                  <ListItem key={data}>
                    <Chip
                      icon={icon}
                      label={key}
                      onDelete={handleDelete(data, index)}
                    />
                  </ListItem>
                );
              })}

              <ListItem>
                {/* <Chip
                      label="clear all filter"
                      onClick={handleAllClear}
                    /> */}
                <a className="allclear" href="#" onClick={handleAllClear}>
                  clear all filter
                </a>
              </ListItem>
            </Paper>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="row mt-4">
        <div className="col-md-12 table-responsive ">
          <table className="table" id="example">
            <thead>
              <tr>
                {/* AIASA-000127  v1 - issues solved by lalitha */}
                <th id="th">
                  <span className="text_th"> S.No</span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "serialNumber")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "serialNumber")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "name")}
                  >
                    {" "}
                    Batch Name
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "name")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "category")}
                  >
                    Category
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "category")}
                  >
                    {" "}
                    import_export{" "}
                  </span>{" "}
                  <span
                    class={` align-middle material-icons  ${
                      typeOn.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "category")}
                  >
                    filter_list
                  </span>
                  {isOpen.category === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="UPSC"
                              value="UPSC"
                              id="UPSC"
                              checked={typeOn.some((element) => {
                                if (element.name === "UPSC") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "UPSC", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              UPSC
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TNPSC"
                              value="TNPSC"
                              id="TNPSC"
                              checked={typeOn.some((element) => {
                                if (element.name === "TNPSC") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "TNPSC", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              TNPSC
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="RRB"
                              value="RRB"
                              id="RRB"
                              checked={typeOn.some((element) => {
                                if (element.name === "RRB") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "RRB", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              RRB
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TET"
                              value="TET"
                              id="TET"
                              checked={typeOn.some((element) => {
                                if (element.name === "TET") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "TET", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              TET
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TRB"
                              value="TRB"
                              id="TRB"
                              checked={typeOn.some((element) => {
                                if (element.name === "TRB") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "TRB", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              TRB
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TNUSRB"
                              value="TNUSRB"
                              id="TNUSRB"
                              checked={typeOn.some((element) => {
                                if (element.name === "TNUSRB") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "TNUSRB", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              TNUSRB
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="SSC"
                              value="SSC"
                              id="SSC"
                              checked={typeOn.some((element) => {
                                if (element.name === "SSC") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "SSC", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              SSC
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Banking"
                              value="Banking"
                              checked={typeOn.some((element) => {
                                if (element.name === "Banking") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Banking", "type")
                              }
                              id="Banking"
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Banking
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("type")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("type")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "category_class")}
                  >
                    Course
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "category_class")}
                  >
                    import_export
                  </span>
                  {/* <span class="material-icons align-middle" onClick={(e)=>handleFilter(e,"course")}>
                    filter_list
                  </span>
                  {isOpen.course === true && (
                <div className="filterBoxs p-1">
                  <div className="row mt-4">
                    <div className="col-md-12">
                    <div class="form-check p-1">
                            <label
                              htmlFor="course"
                              className="d-flex form-label"
                            >
                               select Course
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            <select   class="FilterInputs"   id="course" name="course"  >
                        <option>Please select</option>
                      </select>
                           
                          </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <span
                        className="clearFilter ms-2 "
                        style={{ cursor: "pointer" }}
                      >
                        Clear
                      </span>
                    </div>
                   <div className="col-md-8  col-8 col-sm-8">
                      <button
                        className="FilterButton"
                      >
                        Apply Filter
                      </button>
                    </div>
                  </div>
                </div>
                 )} */}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "class_mode")}
                  >
                    Test Mode
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "class_mode")}
                  >
                    import_export
                  </span>{" "}
                  <span
                    class={` align-middle material-icons  ${
                      classModes.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "classMode")}
                  >
                    filter_list
                  </span>
                  {isOpen.classMode === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Online"
                              value="1"
                              id="Online"
                              checked={classModes.some((element) => {
                                if (element.name === "Online") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Online")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Online
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Classroom"
                              value="0"
                              id="Classroom"
                              checked={classModes.some((element) => {
                                if (element.name === "Classroom") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Classroom")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Classroom
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("classMode")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("classMode")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "medium")}
                  >
                    Medium
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "medium")}
                  >
                    import_export
                  </span>
                  <span
                    class={` align-middle material-icons  ${
                      mediums.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "medium")}
                  >
                    filter_list
                  </span>
                  {isOpen.medium === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Tamil"
                              value="1"
                              id="Tamil"
                              checked={mediums.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) => handleCheckboxChange(e, "Tamil")}
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Tamil
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="English"
                              value="0"
                              id="English"
                              checked={mediums.some((element) => {
                                if (element.id === "0") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "English")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              English
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("medium")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("medium")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "batch_type")}
                  >
                    Schedule Preference
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "batch_type")}
                  >
                    import_export
                  </span>
                  <span
                    class={` align-middle material-icons  ${
                      schedule.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "schedule")}
                  >
                    filter_list
                  </span>
                  {isOpen.schedule === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Weekend"
                              value="0"
                              id="Weekend"
                              checked={schedule.some((element) => {
                                if (element.name === "Weekend") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Weekend")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Weekend
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Regular"
                              value="1"
                              id="Regular"
                              checked={schedule.some((element) => {
                                if (element.name === "Regular") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Regular")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Regular
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("schedule")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("schedule")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "location")}
                  >
                    Location
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "location")}
                  >
                    import_export
                  </span>
                  <span
                    class={` align-middle material-icons  ${
                      location.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "location")}
                  >
                    filter_list
                  </span>
                  {isOpen.location === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Tambaram"
                              value="1"
                              id="Tambaram"
                              checked={location.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "location")
                              }
                            />
                            <label
                              class="form-check-label PinkText"
                              for="defaultCheck1"
                            >
                              Tambaram
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Kanchipuram"
                              value="2"
                              id="Kanchipuram"
                              checked={location.some((element) => {
                                if (element.id === "2") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "location")
                              }
                            />
                            <label
                              class="form-check-label GreenText"
                              for="defaultCheck1"
                            >
                              Kanchipuram
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Vellore"
                              value="3"
                              id="Vellore"
                              checked={location.some((element) => {
                                if (element.id === "3") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "location")
                              }
                            />
                            <label
                              class="form-check-label GreyText"
                              for="defaultCheck1"
                            >
                              Vellore
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Ch - Anna Nagar"
                              value="4"
                              id="Ch - Anna Nagar"
                              checked={location.some((element) => {
                                if (element.id === "4") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "location")
                              }
                            />
                            <label
                              class="form-check-label GreyText"
                              for="defaultCheck1"
                            >
                              Ch - Anna Nagar
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("location")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("location")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "started_dt")}
                  >
                    Start Date
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "started_dt")}
                  >
                    import_export
                  </span>{" "}
                  <span
                    onClick={(e) => handleFilter(e, "startDate")}
                    class={` align-middle material-icons  ${
                      appOn.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.startDate === true && (
                    <div className="filterBoxOne container p-1">
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <div class="form-check p-1">
                            <label
                              htmlFor="FDate"
                              className="d-flex form-label"
                            >
                              From Date
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            {/* <input
                                id="FDate"
                                type="date"
                                name="FDate"
                                value={appData.FDate}
                                className="FilterInputs"
                                onChange={handleAppliedOn}
                                placeholder="FDate"
                                autoComplete="FDate"
                                required
                              /> */}
                            <DatePickerSample
                              IconInput={"filterIocnBoxInput"}
                              class={"FilterInputs"}
                              name={"FDate"}
                              handleChange={handleChangeDate}
                              fieldInput={appData.FDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <div class="form-check p-1">
                            <label
                              htmlFor="TDate "
                              className="d-flex form-label"
                            >
                              To Date
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            {/* <input
                                id="TDate"
                                type="date"
                                name="TDate"
                                value={appData.TDate}
                                className="FilterInputs"
                                placeholder="TDate"
                                onChange={handleAppliedOn}
                                autoComplete="TDate"
                                required
                              /> */}
                            <DatePickerSample
                              class={"FilterInputs"}
                              IconInput={"filterIocnBoxInput"}
                              name={"TDate"}
                              handleChange={handleChangeDate}
                              fieldInput={appData.TDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              id="Today"
                              value="Today"
                              checked={appData.Days === "Today"}
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Today">
                              Today
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Yesterday"
                              checked={appData.Days === "Yesterday"}
                              id="Yesterday"
                              onChange={handleAppliedOn}
                            />
                            <span
                              class="form-check-label d-flex"
                              for="Yesterday"
                            >
                              Yesterday
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Tweek"
                              checked={appData.Days === "Tweek"}
                              id="Tweek"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Tweek">
                              This week
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Lweek"
                              checked={appData.Days === "Lweek"}
                              id="Lweek"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Lweek">
                              Last week
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Tmonth"
                              checked={appData.Days === "Tmonth"}
                              id="Tmonth"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Tmonth">
                              This month
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              checked={appData.Days === "Lmonth"}
                              value="Lmonth"
                              id="Lmonth"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Lmonth">
                              Last month
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("appliedOn")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("appliedOn")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "created_on")}
                  >
                    Created On
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "created_on")}
                  >
                    import_export
                  </span>{" "}
                  <span
                    onClick={(e) => handleFilter(e, "created_on")}
                    class={` align-middle material-icons  ${
                      appOn_on.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.created_on === true && (
                    <div className="filterBoxOne container p-1">
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <div class="form-check p-1">
                            <label
                              htmlFor="FDate"
                              className="d-flex form-label"
                            >
                              From Date
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            {/* <input
                                id="FDate"
                                type="date"
                                name="FDate"
                                value={appData_on.FDate}
                                className="FilterInputs"
                                onChange={handleAppliedOn_on}
                                placeholder="FDate"
                                autoComplete="FDate"
                                required
                              /> */}
                            <DatePickerSample
                              IconInput={"filterIocnBoxInput"}
                              class={"FilterInputs"}
                              name={"FDate"}
                              handleChange={handleChangeDate_app}
                              fieldInput={appData_on.FDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <div class="form-check p-1">
                            <label
                              htmlFor="TDate "
                              className="d-flex form-label"
                            >
                              To Date
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            {/* <input
                                id="TDate"
                                type="date"
                                name="TDate"
                                value={appData_on.TDate}
                                className="FilterInputs"
                                placeholder="TDate"
                                onChange={handleAppliedOn_on}
                                autoComplete="TDate"
                                required
                              /> */}
                            <DatePickerSample
                              class={"FilterInputs"}
                              IconInput={"filterIocnBoxInput"}
                              name={"TDate"}
                              handleChange={handleChangeDate_app}
                              fieldInput={appData_on.TDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              id="Today"
                              value="Today"
                              checked={appData_on.Days === "Today"}
                              onChange={handleAppliedOn_on}
                            />
                            <span class="form-check-label d-flex" for="Today">
                              Today
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Yesterday"
                              checked={appData_on.Days === "Yesterday"}
                              id="Yesterday"
                              onChange={handleAppliedOn_on}
                            />
                            <span
                              class="form-check-label d-flex"
                              for="Yesterday"
                            >
                              Yesterday
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Tweek"
                              checked={appData_on.Days === "Tweek"}
                              id="Tweek"
                              onChange={handleAppliedOn_on}
                            />
                            <span class="form-check-label d-flex" for="Tweek">
                              This week
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Lweek"
                              checked={appData_on.Days === "Lweek"}
                              id="Lweek"
                              onChange={handleAppliedOn_on}
                            />
                            <span class="form-check-label d-flex" for="Lweek">
                              Last week
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Tmonth"
                              checked={appData_on.Days === "Tmonth"}
                              id="Tmonth"
                              onChange={handleAppliedOn_on}
                            />
                            <span class="form-check-label d-flex" for="Tmonth">
                              This month
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              checked={appData_on.Days === "Lmonth"}
                              value="Lmonth"
                              id="Lmonth"
                              onChange={handleAppliedOn_on}
                            />
                            <span class="form-check-label d-flex" for="Lmonth">
                              Last month
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("appliedOn_on")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("appliedOn_on")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                {/* <th id="th">
                    Time{" "}
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "start_time")}
                    >
                      import_export
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleFilter(e, "time")}
                    >
                      filter_list
                    </span>
                    {isOpen.time === true && (
                      <div className="filterBoxs p-1">
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check p-1">
                              <label
                                htmlFor="startTime"
                                className="d-flex form-label"
                              >
                                Start Time
                                <span
                                  data-required="true"
                                  aria-hidden="true"
                                ></span>
                              </label>
                              <input
                                id="STime"
                                type="time"
                                step="1"
                                name="STime"
                                className="FilterInputs"
                                placeholder="STime"
                                autoComplete="STime"
                                required
                                value={timedata.STime}
                                onChange={handleTime}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check p-1">
                              <label
                                htmlFor="endTime"
                                className="d-flex form-label"
                              >
                                End Time
                                <span
                                  data-required="true"
                                  aria-hidden="true"
                                ></span>
                              </label>
                              <input
                                id="TTime"
                                type="time"
                                step="1"
                                name="TTime"
                                className="FilterInputs"
                                placeholder="TTime"
                                autoComplete="TTime"
                                required
                                value={timedata.TTime}
                                onChange={handleTime}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("time")}
                            >
                              Clear
                            </span>
                          </div>
                         <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("time")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th> */}
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "Status")}
                  >
                    Status
                  </span>{" "}
                  {/* <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "Status")}
                    >
                      import_export
                    </span>{" "} */}
                  <span
                    class={` align-middle material-icons  ${
                      status.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "status")}
                  >
                    filter_list
                  </span>
                  {/* 0 → past, 1→ present, 2→ future */}
                  {isOpen.status === true && (
                    <div className="filterBoxssss p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Upcoming"
                              value="2"
                              id="Upcoming"
                              checked={status.some((element) => {
                                if (element.id === "2") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Upcoming")
                              }
                            />
                            <label
                              class="form-check-label PinkText"
                              for="defaultCheck1"
                            >
                              Upcoming
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Ongoing"
                              value="1"
                              id="Ongoing"
                              checked={status.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Ongoing")
                              }
                            />
                            <label
                              class="form-check-label GreenText"
                              for="defaultCheck1"
                            >
                              Ongoing
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Completed"
                              value="0"
                              id="Completed"
                              checked={status.some((element) => {
                                if (element.id === "0") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Completed")
                              }
                            />
                            <label
                              class="form-check-label GreyText"
                              for="defaultCheck1"
                            >
                              Completed
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("status")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("status")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  {" "}
                  <span className="text_th">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.length === 0 ? (
                <tr>
                  <td
                    colspan="15"
                    style={{
                      textAlign: "center",
                      borderBottom: "1px solid #dee2e6",
                    }}
                  >
                    No record to display
                  </td>
                </tr>
              ) : (
                currentData.map((course, index) => (
                  <tr key={index} title={course.id}>
                    <td id="td">{course.serialNumber}</td>
                    <td id="td">
                      {course.name}({course.section})
                    </td>
                    <td id="td">{course.category}</td>
                    <td id="td"> {course.category_class}</td>
                    <td id="td">{course.class_mode}</td>
                    <td id="td">{course.medium}</td>
                    <td id="td">{course.batch_type}</td>
                    <td id="td">{course.location_name}</td>
                    <td id="td">
                      {moment(course.started_dt).format("YYYY-MM-DD")}
                    </td>
                    <td id="td">
                      <div>
                        {" "}
                        {moment(
                          course.created_on,
                          "DD/MM/YYYY h:mm:ss A"
                        ).format("DD/MM/YYYY")}{" "}
                      </div>
                      <div>
                        {" "}
                        {moment(
                          course.created_on,
                          "DD/MM/YYYY h:mm:ss A"
                        ).format("h:mm:ss A")}
                      </div>
                    </td>
                    {/* {moment(course.started_dt).isBefore(currentDate) &&
                      currentDate.isBefore(
                        moment(course.started_dt).add(1, "day").startOf("day")
                      ) ? (
                        <td id="td">
                          <span className="GreenTexts">OnGoing</span>
                        </td>
                      ) : (moment(course.started_dt).isSame(
                          currentDate,
                          "day"
                        ) &&
                          currentDate.isBefore(tenAM)) ||
                        currentDate.isBetween(
                          tenAM,
                          moment(course.started_dt).add(1, "day").startOf("day")
                        ) ? (
                        <td id="td">
                          <span className="PinkText">Upcoming</span>
                        </td>
                      ) : (
                        <td id="td">
                          <span className="GreyText">completed</span>
                        </td>
                      )} */}
                    <StatusCheck status={course} />

                    <td id="td">
                      <button
                        className="button"
                        onClick={() => handleReadView(course.id, 1)}
                      >
                        <span class="material-icons">visibility</span>
                      </button>
                      {/* <button
                          className="button me-2"
                          onClick={() => handleEditView(course.type,course.id)}
                        >
                          <span class="material-icons">edit</span>
                        </button>{" "} */}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-3 ">
        <div className="col-md-12">
          <div className="pag w-100 dflexcenter">
            <span className="ipage">Items Per Page</span>
            <select
              name="pageCount"
              id="pageCount"
              defaultValue={rowsPerPage}
              onChange={handlePageCount}
              className="ms-2"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="pagenumber ms-2">
              {" "}
              {currentPage * rowsPerPage + 1 - rowsPerPage} -{" "}
              {/* {currentPage * rowsPerPage} of {updateListData.length} */}
              {updateListData.length < currentPage * rowsPerPage
                ? updateListData.length
                : currentPage * rowsPerPage}{" "}
              of {updateListData.length}
            </span>
            <button
              className="prev_button ms-2 me-2"
              onClick={(e) => handlePrev(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <span class="material-icons">chevron_left</span>
            </button>
            <button
              className="prev_button"
              onClick={() => handleNext(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <span class="material-icons">chevron_right</span>
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default TestBatchView;
