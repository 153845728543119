export const multiCusutomStyle = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#2378E9" : provided.borderColor,
    color: state.isFocused ? "white" : provided.color,
    "& svg": {
      fill: "#2378E9",
    },
  }),
  // dropdownIndicator : (provided, state) => ({
  //   ...provided,
  //   backgroundColor: '#2378E9', // Change the indicator color
  // }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#2378E9" : provided.backgroundColor,
    color: state.isFocused ? "white" : provided.color,
    "&:hover": {
      backgroundColor: "#2378E9",
      color: "white ", // Set text color to white on hover (important)
    },
    margin: "4px 0",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#2378E9" : provided.backgroundColor,
    color: state.isFocused ? "white" : provided.color,
  }),
  optionLabel_drop: {
    // Add custom styles for the option label
    color: "inherit", // Inherit color from parent (can change as needed)
    paddingLeft: "20px",
    "&:hover": {
      backgroundColor: "#2378E9",
      color: "white", // Set text color to white on hover (remove the extra space)
    },
  },
  optionLabel: {
    // Add custom styles for the option label
    color: "inherit", // Inherit color from parent (can change as needed)
    width: "450px",
    VerticalAlignCenter: "Center",
    "&:hover": {
      backgroundColor: "#2378E9",
      color: "white", // Set text color to white on hover (remove the extra space)
    },
  },
  optionLabels: {
    // Add custom styles for the option label
    color: "black", // Inherit color from parent (can change as needed)
    "&:hover": {
      backgroundColor: "#2378E9",
      color: "white", // Set text color to white on hover (remove the extra space)
    },
  },
  optionLabelss: {
    // Add custom styles for the option label
    color: "#c1393d", // Inherit color from parent (can change as needed)
  },
  checkboxChecked: {
    // Define styles for the custom checkbox when it's checked
    backgroundColor: "#ffff", // Background color when checked
    borderColor: "#ffff", // Border color when checked
  },

  menu: (provided, state) => ({
    ...provided,
  }),
};
