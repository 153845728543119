import React, { useState } from "react";
import styled from "styled-components";
import { MEDIA_SIZE_LG } from "../../../../../utils/helper";

const instructionEnglish = [
  "Kindly ensure proper internet connectivity",
  "Attending the exam by using laptop or Desktop is highly preferable.",
  "Your mobile or laptop should be fully charged. Make sure the availability of power bank or UPS or inverter for uninterrupted power supply ",
  "Kindly ensure that you have sufficient internet data",
  "Kindly login to our exam portal 10 minutes ahead of starting of exam",
  "Before starting of the exam, please make sure that all the browsers/tabs in your gadget are closed",
  "Once the exam is started, do not switch over to any other window or tab. If doing so, it shall be considered as malpractice and your exam may get terminated.",
  "Kindly avoid answering any phone calls while attending the exam on mobile. If so, it shall be treated as you are switch over to other window.",
  "Kindly make use of Add blocker to avoid unwanted pop-ups appearing during the exam. ",
  "Kindly ensure all the temporary files, browser history and cache memory on your mobile or laptop are deleted",
  "Kindly wait patiently till the end of your exam. ",
];

const instructionTamil = [
  "சரியான இணைய இணைப்பை உறுதி செய்யவும்",
  "லேப்டாப் அல்லது டெஸ்க்டாப் பயன்படுத்தி தேர்வில் கலந்துகொள்வது மிகவும் விரும்பத்தக்கது.",
  "உங்கள் மொபைல் அல்லது லேப்டாப் முழுமையாக சார்ஜ் செய்யப்பட்டிருக்க வேண்டும். மின்சாரம் கிடைப்பதை உறுதி செய்து கொள்ளுங்கள் தடையில்லா மின்சாரம் வழங்க வங்கி அல்லது UPS அல்லது இன்வெர்ட்டர்",
  "உங்களிடம் போதுமான இன்டர்நெட் டேட்டா இருப்பதை உறுதி செய்து கொள்ளவும்",
  "தேர்வு தொடங்குவதற்கு 10 நிமிடங்களுக்கு முன்னதாக எங்கள் தேர்வு போர்ட்டலில் உள்நுழையவும்",
  "தேர்வைத் தொடங்கும் முன், உங்களின் அனைத்து உலாவிகள்/தாவல்கள் உள்ளதா என்பதை உறுதிப்படுத்திக் கொள்ளவும் கேஜெட் மூடப்பட்டுள்ளது",
  "தேர்வு தொடங்கியதும், வேறு எந்த சாளரத்திற்கும் அல்லது தாவலுக்கும் மாற வேண்டாம். அவ்வாறு செய்தால், அது முறைகேடாகக் கருதப்படும் மற்றும் உங்கள் தேர்வு நிறுத்தப்படலாம்.",
  "தேர்வில் கலந்துகொள்ளும் போது அலைபேசியில் எந்த தொலைபேசி அழைப்புகளுக்கும் பதிலளிப்பதைத் தவிர்க்கவும். அப்படிஎன்றால், நீங்கள் மற்ற சாளரத்திற்கு மாறும்போது அது கருதப்படும்.",
  "தேவையற்ற பாப்-அப்கள் தோன்றுவதைத் தவிர்க்க, சேர் பிளாக்கரைப் பயன்படுத்தவும் தேர்வு.",
  "உங்களின் அனைத்து தற்காலிக கோப்புகள், உலாவி வரலாறு மற்றும் கேச் நினைவகம் ஆகியவற்றை தயவுசெய்து உறுதிப்படுத்தவும் மொபைல் அல்லது லேப்டாப் நீக்கப்படும்",
  "உங்கள் தேர்வு முடியும் வரை பொறுமையாக காத்திருங்கள்.",
];

let temp1 = [
  "Arrange for stable Internet connectivity.",
  "Giving examination on Laptop or Desktop is highly recommended.",
  "Make sure mobile/laptop is fully charged. Power bank for mobile or UPS/Inverter for laptop/desktop should be arranged for uninterrupted power supply.",
  "Students should have sufficient data in Fair Usage Policy (FUP) / Internet plan with sufficient data pack of internet service provider.",
  "Login to the portal 10 min before the online examination start time.",
  "Close all browsers/tabs before starting the online examination.",
  "Once the exam starts, do not switch to any other window/tab. On doing so, your attempt may be considered as malpractice and your exam may get terminated.",
  "Do Not Pickup/Receive the Call during the exam if you are giving the exam on mobile. This also will be treated as changing the window.",
  "To avoid unwanted pop-ups, use of Ad Blocker is recommended.",
  "Clear browser cache memory on mobile and laptops. Clear browsing history and also delete temp files.",
];
let temp2 = [
  "நிலையான இணைய இணைப்புக்கு ஏற்பாடு செய்யுங்கள்.",
  "லேப்டாப் அல்லது டெஸ்க்டாப்பில் பரிசோதனை செய்வது மிகவும் பரிந்துரைக்கப்படுகிறது.",
  "மொபைல்/லேப்டாப் முழுவதுமாக சார்ஜ் செய்யப்பட்டிருப்பதை உறுதிசெய்யவும். தடையில்லா மின்சாரம் வழங்க மொபைலுக்கான பவர் பேங்க் அல்லது மடிக்கணினி/டெஸ்க்டாப்பிற்கான யுபிஎஸ்/இன்வெர்ட்டர் ஏற்பாடு செய்யப்பட வேண்டும்.",
  "மாணவர்கள் நியாயமான பயன்பாட்டுக் கொள்கை (FUP) / இணையத் திட்டத்தில் போதுமான தரவுகளை இணைய சேவை வழங்குநரின் போதுமான தரவுப் பொதியுடன் வைத்திருக்க வேண்டும்.",
  "ஆன்லைன் தேர்வு தொடங்கும் நேரத்திற்கு 10 நிமிடங்களுக்கு முன்பு போர்ட்டலில் உள்நுழையவும்.",
  "ஆன்லைன் தேர்வைத் தொடங்கும் முன் அனைத்து உலாவிகள்/தாவல்களை மூடவும்.",
  "தேர்வு தொடங்கியதும், வேறு எந்த விண்டோ/டேப்பிற்கும் மாற வேண்டாம். அவ்வாறு செய்தால், உங்கள் முயற்சி தவறான செயலாகக் கருதப்பட்டு, உங்கள் தேர்வு நிறுத்தப்படலாம்.",
  "மொபைலில் தேர்வு எழுதினால், தேர்வின் போது அழைப்பை எடுக்கவோ/பெறவோ வேண்டாம். இது சாளரத்தை மாற்றுவதாகவும் கருதப்படும்.",
  "தேவையற்ற பாப்-அப்களைத் தவிர்க்க, விளம்பரத் தடுப்பானைப் பயன்படுத்த பரிந்துரைக்கப்படுகிறது.",
  "மொபைல் மற்றும் மடிக்கணினிகளில் உலாவி கேச் நினைவகத்தை அழிக்கவும். உலாவல் வரலாற்றை அழிக்கவும் மற்றும் தற்காலிக கோப்புகளை நீக்கவும்.",
  "தேர்வு தொடங்கியதும், வேறு எந்த சாளரத்திற்கும் அல்லது தாவலுக்கும் மாற வேண்டாம். செய்தால் எனவே, இது முறைகேடாகக் கருதப்படும் மற்றும் உங்கள் தேர்வு நிறுத்தப்படலாம்.",
];

const OPTION_VALUE_ENGLISH = 1;
const OPTION_VALUE_TAMIL = 2;

const StyledList = styled.ul`
  font-size: 14px;
  padding-right: 8px;
`;
const StyledHeader = styled.h6`
  text-transform: uppercase;
  text-decoration: underline;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  text-align: center;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    font-size: 20px;
  }
`;
const StyledSelect = styled.select`
  && {
    /* background: none !important;
    appearance: inherit; */
    font-size: 12px;
    padding: 5px 100px 5px 5px;
    color: var(--color-grey-500);
    max-width: 250px;
    border-radius: var(--border-radius-tiny);
    &::marker {
      color: var(--color-grey-500);
    }
    &:focus,
    :focus-visible {
      outline: none;
      border-color: var(--color-grey-300);
    }
  }
`;

const StyledOption = styled.option`
  && {
    padding: 5px;
    color: var(--color-grey-300);
    &:hover {
      background-color: var(--color-grey-200);
    }
    &:active {
      background-color: var(--color-grey-500);
    }
  }
`;

const options = [
  { label: "View in English", value: OPTION_VALUE_ENGLISH },
  { label: "View in Tamil", value: OPTION_VALUE_TAMIL },
];

export default function InstructionList({ list }) {
  const [selectedlanguage, setSelectedlanguage] =
    useState(OPTION_VALUE_ENGLISH);

  const StyledListItem = styled.li`
    text-align: justify;
    margin-bottom: 16px;
    font-size: 16px;
    color: var(--color-text-primary);
    font-weight: var(--font-weight-normal);
    font-family: ${selectedlanguage === OPTION_VALUE_ENGLISH
      ? "inherit"
      : "Noto-Sans-Tamil-Regular"};
    &::marker {
      color: var(--color-brand-900);
      width: 12px;
      height: 12px;
    }
    @media (min-width: ${MEDIA_SIZE_LG}) {
      font-size: 18px;
    }
  `;
  return (
    <>
      <StyledHeader>Instructions to Candidates</StyledHeader>
      <div className="d-flex justify-content-end">
        <StyledSelect
          className="ml-auto"
          onChange={(e) => setSelectedlanguage(Number(e.target.value))}
          value={selectedlanguage}
        >
          {options.map(({ label, value }) => (
            <StyledOption value={value}>{label}</StyledOption>
          ))}
        </StyledSelect>
      </div>

      <StyledList>
        {selectedlanguage === OPTION_VALUE_ENGLISH
          ? instructionEnglish.map((inst, index) => (
              <StyledListItem key={index}>{inst}</StyledListItem>
            ))
          : instructionTamil.map((inst, index) => (
              <StyledListItem key={index}>{inst}</StyledListItem>
            ))}
      </StyledList>
    </>
  );
}
