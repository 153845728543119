import "./Faculty.css";

import { Link, useLocation, useNavigate, useParams  } from "react-router-dom";
import React, { useEffect, useState , useRef } from "react";

import { AxiosInstance } from "../../../../axios";
import CategorySubject from "./CategorySubject";
import Swal from "sweetalert2";
import moment from "moment";
import { validateFaculty } from "./validation";
import Select, { components } from "react-select";
import { customStyles } from "../../../../pages/customStyles";
import DatePickerSample from "../../DatePicker";
import intlTelInput from 'intl-tel-input';
function NewFaculty() {

  
  const txtFacFName = useRef(null);
  const txtFacLoca = useRef(null);
  const txtFacLName = useRef(null);
  const txtFacPno = useRef(null);
  const txtFacEmail = useRef(null);
  const txtFacDes= useRef(null);
  const txtFacDjoin = useRef(null);
  const txtFacCate = useRef(null);
  const { facId,userId} = useParams();
  const location = useLocation();
  const { upgrade } = location.state ?? {};
  const navigate = useNavigate();
  const [fData, setFormData] = useState({
    fname: "",
    lname: "",
    phoneNumber: "",
    phone_code:"+91",
    email: "",
    TechType:(facId === "0" || facId === 0 ) ? 0 : 1,
    designation: "",
    DJoin: "",
    location:'',
  });
  const [category, setCategory] = useState([]);

  const [error, setError] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [cate, setCate] = useState([]);

  const [subject, setSubject] = useState([]);

  const [locations, setLocations] = useState([]);



  useEffect(() => {
    // Initialize intl-tel-input on component mount
    const iti = intlTelInput(txtFacPno.current, {
      // Add options as needed, refer to the intl-tel-input documentation
      initialCountry: 'in',
      separateDialCode: true,
    });

    // You can listen for changes if needed
    txtFacPno.current.addEventListener('countrychange', () => {
      // console.log('Country changed:', iti.getSelectedCountryData());
      const selectedCountryData = iti.getSelectedCountryData();
      setFormData((prevFields) => ({
        ...prevFields,
        phone_code: `+${selectedCountryData.dialCode}`, // Update phone number with the new country code
      }));
    });

    // Clean up on component unmount
    return () => {
      iti.destroy();
    };
  }, []);

  useEffect(() => {
    AxiosInstance.post("api/branch/list")
    .then((res) => {
      // console.log(res,"locations")
      if (res.data.data.length > 0) {
        let locations = res.data.data.map((item, index) => (
          <option key={index} id={item.name} value={item.id}>
            {item.name}
          </option>
        ));
        setLocations(locations);
      } else {
        let locations = <option value="select"> Please select </option>;
        setLocations(locations);
      }
    })
    .catch((error) => {
      return error;
    });




  }, [])
  


  const handleAddSubject = (name) => {
    // console.log("Add subject", name);
    let subs = [];
    subject.map((obj) => {
      subs.push(obj);
    });
    subs.push({
      id: subject.length + 1,
      category: "" + name,
      subject_name: [],
      optionSelected: null,
    });
    // setSubject(subs);
    getallSubject(subs, name);
  };
  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  const handleDelSubject = (name) => {
    //const { name, value } = e.target;
    // console.log("DEL ", name);
    let subs = subject.filter((item) => item.category !== name);
    subs.map((obj, index) => {
      obj.id = index + 1;
      subs[index] = obj;
    });
    setSubject(subs);
  };

  const handleChangeCuDate = (dates, name) => {
    const dateSam = moment(dates).format('YYYY-MM-DD');
    // console.log(name, "name", dateSam);
      setFormData((prevState) => ({
        ...prevState,
        [name]: dateSam,
      }));
      setError({
        ...error,
        [name]: "",
      });
  }

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const handleCategoryChanges = (e, name) => {
    // const { name, value } = e.target;
    // console.log("radio select ", e.target.value);
    if (e.target.checked) {
      handleAddSubject(e.target.value);
      setCategory([...category, e.target.value]);
      setError({
        ...error,
        category: "",
      });
    } else {
      handleDelSubject(e.target.value);
      setCategory(category.filter((item) => item !== e.target.value));
    }
  };

  const handleSubjectChange = (recordId, subjectValue) => {
    // setSubject((prevState) =>
    //   prevState.map((item) =>
    //     item.id === recordId
    //       ? {
    //           ...item,
    //           subject: item.subject.includes(subjectValue)
    //             ? item.subject.filter((sub) => sub !== subjectValue)
    //             : [...item.subject, subjectValue],
    //         }
    //       : item
    //   )
    // );
  };

  const handleChangeCheckbox = (e, recordID) => {
    let subs = [];
    // console.log(e,"e");
    subject.map((obj, index) => {
      if (obj.id === recordID) {
        obj.optionSelected = e;
      }
      subs.push(obj);
    });
    setSubject(subs);
    setError({
      ...error,
      optionSelected:"",
    })
  };

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot available in localstorge");
  }

  useEffect(() => {
    const teachId = parseInt(facId);
    AxiosInstance.get(`api/all/getDesignation/${teachId}`)
      .then((res) => {
        const designation = res.data.data.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setDesignation(designation);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  useEffect(() => {
    AxiosInstance.post("api/staff/view", { user_id: userId })
      .then((res) => {
        // console.log(res, "findbyid");
        if (res.data.status === true) {
          setFormData({
            fname: res.data.data.first_name,
            lname: res.data.data.last_name,
            phoneNumber: res.data.data.mobile_no,
            phone_code: res.data.data.country_code,
            email: res.data.data.email,
            TechType: res.data.data.staff_type === 0 ? "0" : (res.data.data.staff_type === 3 ? "0" : "1" ),
            designation: res.data.data.designation,
            DJoin: moment(res.data.data.aiasa_doj).format("YYYY-MM-DD"),
            location: res.data.data.staff_loc_id,
          });

          if(res.data.data.category !=="" && res.data.data.category !==null){

            const ca = res.data.data.category
            .split(",")
            .map((category) => category.toUpperCase());
             setCategory(ca);

          }
      
          const originalData = res.data.data.subject !==null && res.data.data.subject !=="" && res.data.data.subject.reduce( (acc, cur) => {
            const categoryIndex = acc.findIndex(
              (item) => item.category === cur.category
            );
            // console.log(categoryIndex,"categoryIndex");
            if (categoryIndex === -1) {
              acc.push({
                id:  acc.length + 1,
                category: cur.category,
                optionSelected: [
                  {
                    value: cur.subject,
                    label: cur.subject,
                    pk: cur.subject_pk,
                  },
                ],
                subject_pk:cur.subject_pk,
                subject_name: [],
              });
            } else {
              acc[categoryIndex].optionSelected.push({
                value: cur.subject,
                label: cur.subject,
                pk: cur.subject_pk,
              });
            }
            return acc;
          }, []);

          // console.log(originalData, "originalData");
         // setSubject(originalData);

          originalData.map((obj)=>
          {
            getallSubjectView(originalData, obj.category, obj.optionSelected);

          });
        }
      })
      .catch((error) => {
        return error;
      });
  }, [userId]);

  useEffect(() => {
    // console.log(subject, "subject");
    //temp_subject = subject;
  }, [subject]);

  const getallSubject = (sub, name) => {
  
    AxiosInstance.post("api/course/subject", { category_name: name ,
       type : 7 // default faculty  
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          // const subjects = res.data.data.map((item, index) => (
          //   <option id={index} key={index} value={item.subject}>
          //     {item.subject}
          //   </option>
          // ));
          const subjects = [];
          res.data.data.map((item, index) => {
            subjects.push({
              value: item.subject + "",
              label: item.subject + "",
              pk:item.id,
            });
          });
          // setCate(subject);
          let subs = [];
          sub.map((obj) => {
            if (obj.category === name) {
              obj.subject_name = subjects;
            }
            subs.push(obj);
          });
          setSubject(subs);
        } else {
          // const option = (
          //   <option key="select" value="select">
          //     Please select
          //   </option>
          // );
          const subjects = [];
          let subs = [];
          sub.map((obj) => {
            if (obj.category === name) {
              obj.subject_name = subjects;
            }
            subs.push(obj);
          });
          setSubject(subs);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const getallSubjectView = (sub, name, options) =>
  {
    AxiosInstance.post("api/course/subject", { category_name: name ,type : parseInt(facId) })
      .then((res) => {
        if (res.data.data.length > 0) {
          // const subjects = res.data.data.map((item, index) => (
          //   <option id={index} key={index} value={item.subject}>
          //     {item.subject}
          //   </option>
          // ));
          const subjects = [];
          res.data.data.map((item, index) => {
            subjects.push({
              value: item.subject + "",
              label: item.subject + "",
              pk : item.id,
            });
          });
          // setCate(subject);
          let subs = [];
          sub.map((obj) => {
            if (obj.category === name) {
              obj.subject_name = subjects;
              obj.optionSelected = options;
            }
            subs.push(obj);
          });
          // console.log(options,"options");
          setSubject(subs);
        } else {
          // const option = (
          //   <option key="select" value="select">
          //     Please select
          //   </option>
          // );
          const subjects = [];
          let subs = [];
          sub.map((obj) => {
            if (obj.category === name) {
              obj.subject_name = subjects;
            }
            subs.push(obj);
          });
          setSubject(subs);
        }
      })
      .catch((error) => {
        return error;
      });
  }; 
  


  const handleSelectCH = (option, dname) => {
    // console.log(option,dname);
    setFormData((prevState) => ({
      ...prevState,
      [dname]: option.value,
    }));
    setError({
      ...error,
      [dname]: "",
    });
  }

  const handleChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === "category") {
      if (checked) {
        setCategory([...category, value]);
      } else {
        setCategory(category.filter((item) => item !== value));
      }
      setError({
        ...error,
        [name]: "",
      });
    }  else if(name === "phoneNumber"){

      if (value.length === 10 || value.length === 0) {
        setFormData((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setError({
          ...error,
          ['phoneNumber']: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setFormData((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setError({
          ...error,
          ['phoneNumber']: "Please Enter valid 10 digit number",
        });
        return;
      }

    } 
    // else if (name === "upsc_subject") {
    //   const options = event.target.options;
    //   const selectedValues = Array.from(options)
    //     .filter((option) => option.selected)
    //     .map((option) => option.value);
    //   setSubject((prevSubject) =>
    //     prevSubject.map((subj) =>
    //       subj.name === "UPSC"
    //         ? { ...subj, upsc_subject: selectedValues }
    //         : subj
    //     )
    //   );
    // }
    else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    }
  };
  const handleSubmit = (e) => {
    
    const useref_validation = {
      firstName: txtFacFName.current,
      lastName: txtFacLName.current,
      phoneNumber: txtFacPno.current,
      email: txtFacEmail.current,
      designation: txtFacDes.current,
      dateOfJoining: txtFacDjoin.current,
      category: txtFacCate.current,
      location : txtFacLoca.current,
    };
    const errors = validateFaculty(fData, 0,category,subject,facId , useref_validation);
    // console.log(errors,"errors");
    if (Object.keys(errors).length === 0) {
      try {
        if (userId) {
          const newData = [];
          const newDataPks = [];
const catses = [];

if (facId === "0" || facId === 0) {
// console.log(subject,"edit");
  subject.forEach((item) => {
    item.optionSelected.forEach((option) => {
      // console.log(option,"option edit");
      newData.push({
        category: item.category,
        subject: option.value.trim(),
        subject_pk : option.pk,
      });
      newDataPks.push(option.pk);
    });
    catses.push(item.category);
  });
}

const updateStaff = {
  user_id: userId,
  first_name: fData.fname,
  last_name: fData.lname,
  mobile_no: fData.phoneNumber,
  country_code: fData.phone_code,
  email: fData.email,
  staff_type: fData.TechType,
  category: (facId === "0" || facId === 0) ? catses : null,
  designation: fData.designation,
  subject: (facId === "0" || facId === 0) ? newData : null,
  subject_pk : newDataPks.length > 0 ? newDataPks : null,
  aiasa_doj: fData.DJoin,
  location : fData.location,
  created_by: userid,
  updated_by:userid,
};

if (upgrade !==null && upgrade !== "") {
  // Add the upgrade property to the updateStaff object
  updateStaff.upgrade = upgrade;
}

// console.log(updateStaff, "updateStaff");
// 
AxiosInstance.post("api/staff/register/edit", updateStaff)
  .then((res) => {
    if (res.data.status === true) {
      Swal.fire({
        title: 'Success',
        text: res.data.message,
        icon: 'success',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          navigateToPreviousScreen();
        }
      });
    }
  })
  .catch((error) => {
    return error;
  });
     } else {
          if (facId === "0" || facId === 0) {
          // console.log(subject,"subject");
            const newData = [];
            const newDataPk = [];
            subject.forEach((item) => {
              item.optionSelected.forEach((option) => {
                newData.push({
                  category: item.category,
                  subject: option.value.trim(),
                  subject_pk : option.pk,
                });
                newDataPk.push(option.pk);
              });
            });
            const cates = subject.map((item) => item.category);
            const createStaff = {
              first_name: fData.fname,
              last_name: fData.lname,
              mobile_no: fData.phoneNumber,
              email: fData.email,
              staff_type: fData.TechType,
              country_code: fData.phone_code,
              category: cates,
              designation: fData.designation,
              subject: newData,
              subject_pk : newDataPk.length > 0 ? newDataPk : null,
              aiasa_doj: fData.DJoin,
              location : fData.location,
              created_by: userid,
            };
            // console.log(createStaff, "createStaff");
            // AIASA-000175 v1 -issues solved by lalitha
            Swal.fire({
              title: "Confirmation",
              text: "Are you sure you want to send the invitation to this faculty?",
              icon: "question",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              // If the user confirms (clicks "Yes")
              if (result.isConfirmed) { 
                AxiosInstance.post("api/user/register/staff", createStaff)
                .then((res) => {
                  // console.log(res,res.data.status,res.data.message);
                  if (res.data.status === true) {
                    Swal.fire({
                      title: 'Success',
                      text: res.data.message,
                      icon: 'success',
                      confirmButtonText: 'Ok'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigateToPreviousScreen();
                      }
                    });
                  } else {
                    Swal.fire({
                      title: 'Warning',
                      text: res.data.message,
                      icon: 'warning',
                      confirmButtonText: 'Ok'
                    });
                  }
                })
                .catch((error) => {
                  return error;
                });

               }
            })
            .catch((error) => {
              // Handle the error here
              return error;
            });
           
            
          } else {
            // alert("sfkjsdf");
            const createStaff = {
              first_name: fData.fname,
              last_name: fData.lname,
              mobile_no: fData.phoneNumber,
              country_code: fData.phone_code,
              email: fData.email,
              staff_type: fData.TechType,
              category: null,
              designation: fData.designation,
              subject: null,
              subject_pk: null,
              aiasa_doj: fData.DJoin,
              location : fData.location,
              created_by: userid,
            };
          
            // console.log(createStaff, "createStaff");
               // AIASA-000175 v1 -issues solved by lalitha
            Swal.fire({
              title: "Confirmation",
              text: "Are you sure you want to send the invitation to this faculty?",
              icon: "question",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              // If the user confirms (clicks "Yes")
              if (result.isConfirmed) { 
                AxiosInstance.post("api/user/register/staff", createStaff)
                .then((res) => {
                  if (res.data.status === true) {
                    Swal.fire({
                      title: 'Success',
                      text: res.data.message,
                      icon: 'success',
                      confirmButtonText: 'Ok'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigateToPreviousScreen();
                      }
                    });
                  } else {
                    Swal.fire({
                      title: 'Warning',
                      text: res.data.message,
                      icon: 'warning',
                      confirmButtonText: 'Ok'
                    });
                  }
                })
                .catch((error) => {
                  return error;
                });

              }
            })
            .catch((error) => {
              // Handle the error here
              return error;
            });
         
           
          }
          
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };
  return (
    <div className=" Faculty ">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne ">
            <div className="heading  dflexbetww ">
              <div className="dbetw">
                {userId ? (
                  <h4 className="Titles">
                    {" "}
                    {facId == 0 ? "Edit Faculty" : "Edit Staff"}{" "}
                  </h4>
                ) : (
                  <h4 className="Titles">
                    {facId == 0 ? "Add New Faculty" : "Add New Staff"}
                  </h4>
                )}
                <Link
                  to={`/Faculties/${facId == 0 ? 1 : 2}`}
                  className="CancelButton MobileShow"
                >
                  Back to Faculties
                </Link>
              </div>
              <div className="d-flex smButton  mobiledown ">
                <Link
                  to={`/Faculties/${facId == 0 ? 1 : 2}`}
                  className="CancelButton MobileHide"
                >
                  Back to Faculties
                </Link>
                <button
                  type="button"
                  className="SaveButton"
                  onClick={handleSubmit}
                >
                  <a href="javascript:void(0)">
                    {" "}
                    {userId ? "Edit Faculties" : "Send Invitation Link"}{" "}
                  </a>
                </button>
              </div>
            </div>
            <div className="row box ">
              <div className="col-md-12">
                <p className="StepTitle">Basic Details</p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="fname">First Name:</label>
                      <input
                        ref={txtFacFName}
                        type="text"
                        class="Inputs"
                        id="fname"
                        name="fname"
                        value={fData.fname}
                        onChange={handleChange}
                        placeholder="First Name"
                        required
                      />
                      {error.fname && (
                        <small className="error">{error.fname}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="lname">Last Name:</label>
                      <input
                        ref={txtFacLName}
                        type="text"
                        class="Inputs"
                        id="lname"
                        name="lname"
                        value={fData.lname}
                        onChange={handleChange}
                        placeholder="Last Name"
                        required
                      />
                      {error.lname && (
                        <small className="error">{error.lname}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="phoneNumber">Phone Number:</label>
                      <input
                        type="tel"
                        ref={txtFacPno}
                        class="Inputs_Phone"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={fData.phoneNumber}
                        onChange={handleChange}
                        placeholder="Phone Number"
                        required
                        minLength={10}
                        maxLength={10}
                        inputMode="tel"
                      />
                      {error.phoneNumber && (
                        <small className="error">{error.phoneNumber}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="email">Email ID:</label>
                      <input
                        ref={txtFacEmail}
                        type="email"
                        class="Inputs"
                        id="email"
                        name="email"
                        disabled={userId ? true : false}
                        value={fData.email}
                        onChange={handleChange}
                        placeholder="Email ID"
                        required
                      />
                      {error.email && (
                        <small className="error">{error.email}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="StepOne ">
            <div className="row box ">
              <div className="col-md-12">
                <p className="StepTitle">Current Job Info</p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="JType">Job Type:</label>
                      <div className="d-flex  col-md-12" id="radiobuttons">
                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="TechType"
                            id="Teaching"
                            value="0"
                            checked={
                              fData.TechType === "0" ||
                              facId === "0" ||
                              facId === 0
                            }
                            disabled={
                              facId === "1" || facId === 1 ? true : false
                            }
                            onChange={handleChange}
                          />
                          <label className="checkbox-label" htmlFor="Teaching">
                            Teaching
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="TechType"
                            id="NonTeaching"
                            disabled={
                              facId === "0" || facId === 0 ? true : false
                            }
                            value="1"
                            checked={
                              fData.TechType === "1" ||
                              facId === "1" ||
                              facId === 1
                            }
                            onChange={handleChange}
                          />
                          <label
                            className="checkbox-label"
                            htmlFor="NonTeaching"
                          >
                            Non teaching
                          </label>
                        </div>
                        {/* <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="TechType"
                            id="Special"
                            value="2"
                            checked={fData.TechType === "2"}
                            onChange={handleChange}
                          />
                          <label
                            className="checkbox-label"
                            htmlFor="Special"
                          >
                            Special
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="TechType"
                            id="honourable"
                            value="3"
                            checked={fData.TechType === "3"}
                            onChange={handleChange}
                          />
                          <label
                            className="checkbox-label"
                            htmlFor="honourable"
                          >
                            Honourable
                          </label>
                        </div> */}
                      </div>
                      {error.TechType && (
                        <small className="error">{error.TechType}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="designation">Designation:</label>
                      <Select
                        ref={txtFacDes}
                        id="designation"
                        name="designation"
                        value={
                          fData.designation !== null && fData.designation !== ""
                            ? {
                                value: fData.designation,
                                label: fData.designation,
                              }
                            : null
                        }
                        onChange={(option) =>
                          handleSelectCH(option, "designation")
                        }
                        options={
                          designation
                            .filter((item) => item && item.label) // Filter out null and undefined elements
                            .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                        }
                        isSearchable={true} // Enables search functionality
                        placeholder="Select an option"
                        maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                        filterOption={(option, searchText) =>
                          option.label
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        } // Filter options based on the search text
                        styles={customStyles} // Apply custom styles her
                      />
                      {/* <select
                         ref={txtFacDes}
                        class="Inputs"
                        id="designation"
                        name="designation"
                        value={fData.designation}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>--- Designation ---</option>
                        {designation}
                      </select> */}

                      {error.designation && (
                        <small className="error">{error.designation}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="DJoin">Date of Joining:</label>
                      {/* <input
                        ref={txtFacDjoin}
                        className="Inputs"
                        type="date"
                        name="DJoin"
                        id="DJoin"
                        value={fData.DJoin}
                        onChange={handleChange}
                      /> */}
                      <DatePickerSample
                        txtAdMDob={txtFacDjoin}
                        name={"DJoin"}
                        handleChange={handleChangeCuDate}
                        fieldInput={fData.DJoin}
                      />
                      {error.DJoin && (
                        <small className="error">{error.DJoin}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label htmlFor="location">Branch Location</label>
                      <select
                        ref={txtFacLoca}
                        id="location"
                        name="location"
                        className="Inputs"
                        onChange={handleChange}
                        value={fData.location}
                        required
                      >
                        <option value="" disabled selected>
                          Please select
                        </option>
                        {locations}
                      </select>

                      {error.location && (
                        <small className="error">{error.location}</small>
                      )}
                    </div>
                  </div>
                  {(facId === "0" || facId === 0) && (
                    <div className="col-xl-6 col-lg-12 col-md-12 ">
                      <div className="forms ">
                        <label for={`category`}>Category</label>
                        <div className="d-flex  col-md-12" id="radiobuttons">
                          <div className="form-check form-check-inline">
                            <input
                              ref={txtFacCate}
                              className="checkbox"
                              type="checkbox"
                              name="category"
                              id={"UPSC"}
                              checked={category.includes("UPSC") ? true : false}
                              value="UPSC"
                              onChange={(e) => handleCategoryChanges(e, "UPSC")}
                            />{" "}
                            <label className="checkbox-label">UPSC</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox"
                              type="checkbox"
                              name="category"
                              id={"TNPSC"}
                              value="TNPSC"
                              checked={
                                category.includes("TNPSC") ? true : false
                              }
                              onChange={(e) =>
                                handleCategoryChanges(e, "TNPSC")
                              }
                            />{" "}
                            <label className="checkbox-label">TNPSC</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox"
                              type="checkbox"
                              name="category"
                              id={"TRB"}
                              value="TRB"
                              checked={category.includes("TRB") ? true : false}
                              onChange={(e) => handleCategoryChanges(e, "TRB")}
                            />{" "}
                            <label className="checkbox-label">TRB</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox"
                              type="checkbox"
                              name="category"
                              id={"TET"}
                              value="TET"
                              checked={category.includes("TET") ? true : false}
                              onChange={(e) => handleCategoryChanges(e, "TET")}
                            />{" "}
                            <label className="checkbox-label">TET</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox"
                              type="checkbox"
                              name="category"
                              id={"TNUSRB"}
                              value="TNUSRB"
                              checked={
                                category.includes("TNUSRB") ? true : false
                              }
                              onChange={(e) =>
                                handleCategoryChanges(e, "TNUSRB")
                              }
                            />{" "}
                            <label className="checkbox-label">TNUSRB</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox"
                              type="checkbox"
                              name="category"
                              id={"RRB"}
                              value="RRB"
                              checked={category.includes("RRB") ? true : false}
                              onChange={(e) => handleCategoryChanges(e, "RRB")}
                            />{" "}
                            <label className="checkbox-label">RRB</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox"
                              type="checkbox"
                              name="category"
                              id={"SSC"}
                              value="SSC"
                              checked={category.includes("SSC") ? true : false}
                              onChange={(e) => handleCategoryChanges(e, "SSC")}
                            />{" "}
                            <label className="checkbox-label">SSC</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox"
                              type="checkbox"
                              name="category"
                              id={"Banking"}
                              checked={
                                category.includes("Banking") ? true : false
                              }
                              value="Banking"
                              onChange={(e) =>
                                handleCategoryChanges(e, "Banking")
                              }
                            />{" "}
                            <label className="checkbox-label">Banking</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox"
                              type="checkbox"
                              name="category"
                              id={"Others"}
                              value="Others"
                              checked={
                                category.includes("Others") ? true : false
                              }
                              onChange={(e) =>
                                handleCategoryChanges(e, "Others")
                              }
                            />{" "}
                            <label className="checkbox-label">Others</label>
                          </div>
                        </div>
                        {error.category && (
                          <small className="error">{error.category}</small>
                        )}
                        {error.optionSelected && (
                          <small className="error">
                            {error.optionSelected}
                          </small>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="StepOne">
            {(facId === "0" || facId === 0) &&
              subject.map((item, index) => (
                <CategorySubject
                  id={"category_" + index}
                  key={"category_key" + index}
                  record={item}
                  handleChangeCheckbox={handleChangeCheckbox}
                />
              ))}
            <div className="heading mt-3 dflex ">
              <div className="d-flex smButton  mobiledown ">
                <Link
                  to={`/Faculties/${facId == 0 ? 1 : 2}`}
                  className="CancelButton "
                >
                  Back to Faculties
                </Link>
                <button
                  type="button"
                  className="SaveButton"
                  onClick={handleSubmit}
                >
                  <a href="javascript:void(0)">
                    {" "}
                    {userId ? "Edit Faculties" : "Send Invitation Link"}{" "}
                  </a>
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
export default NewFaculty;
