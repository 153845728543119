import "./Register.css";

import {
  Alarm,
  CurrencyRupee,
  Download,
  LaptopChromebook,
  Today,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";

function Register() {
  const [formdata, setFormdata] = useState([]);

  const [listdata, setList] = useState([
    {
      FirstName: "",
      LastName: "",
      Fathername: "",
      Passportphoto: "",
      DOB: "",
      age: "",
      gender: "Male",
      aadharNo: "",
      aadharCardphoto: "",
      Mobile: "",
      MobileCode : "",
      WhatsAppNumberCode : "",
      ParentMobileNoCode :"",
      WhatsAppNumber: "",
      ContactNumber: false,
      emailaddress: "",
      BuildingNumberPresent: "",
      StreetNamePresent: "",
      AreaPresent: "",
      CityPresent: "",
      statePresent: "",
      PincodePresent: "",
      BuildingNumberPermanent: "",
      StreetNamePermanent: "",
      AreaPermanent: "",
      CityPermanent: "",
      statePermanent: "",
      PincodePermanent: "",
      PresentAddress: false,
      EducationQualification: "",
      EducationQualificationFile: null,
      EducationQualificationYear: "",
      techQualificationOne: "",
      techQualificationOneFile: null,
      techQualificationOneGrade: "",
      techQualificationOneYear: "",
      techQualificationTwo: "",
      techQualificationTwoFile: null,
      techQualificationTwoGrade: "",
      techQualificationTwoYear: "",
      Cast: "",
      CastFile: "",
      SpecialPre: "",
      SpecialPreFile: "",
      CompetitiveExamination: "Yes",
      CompetitiveExamName: "",
      Institute: "",
      ExamPreviously: "Yes",
      ExamDetailsRes: "",
      workingRes: "Yes",
      Designation: "",
      Department: "",
      AIASAStudent: "Yes",
      Year: "",
      Batch: "",
      IDPhoto: "",
      ProgramType: "Courses",
      CourseCate: "",
      TeachMedium: "",
      BatchDetails: "Yes",
    },
  ]);
  localStorage.setItem("AdmissionFormDetails", JSON.stringify(listdata));
  // console.log("formdetails 1", JSON.stringify(listdata));
  useEffect(() => {
    const formdetails =
      JSON.parse(localStorage.getItem("AdmissionFormDetails")) || [];
    // console.log("formdetails 2", formdetails);
    setFormdata(formdetails);
  }, []);
  return (
    <>
      {formdata.map((data) => (
        <div className="container-fluid">
          <div className="d-flex justify-content-between m-4 register_app">
            <button type="button" className="btn register_app">
              Go to Review Information
            </button>
            <div className="d-inline-block me-3 gap-2">
              <a href="#" className="register_app_cancel">
                Cancel Admission
              </a>
              <button type="button" className="btn register_app ms-3">
                Save and Continue to Payment Gateway
              </button>
            </div>{" "}
          </div>
          <div className="box">
            <h6 className="register_app_sub_title">Basic Details</h6>

            <div className="row">
              <div className="col-6 mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  First Name
                </label>
                <div className="register_app_val mb-3">
                  {data.FirstName || ""}
                </div>
              </div>
              <div className="col-6  mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Last Name
                </label>
                <div className="register_app_val mb-3">
                  {data.LastName || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Father's Name
                </label>
                <div className="register_app_val mb-3">
                  {" "}
                  {data.Fathername || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Upload Passport Size Photograph
                  <Download className="download_icon" />
                </label>
                <div className="register_app_val mb-3">
                  {data.Passportphoto || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Date of Birth
                </label>
                <div className="register_app_val mb-3">{data.DOB || ""}</div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Age
                </label>
                <div className="register_app_val mb-3">{data.age || ""}</div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Gender
                </label>
                <div className="register_app_val mb-3">{data.gender || ""}</div>
              </div>
            </div>
          </div>
          <div className="box">
            <h6 className="register_app_sub_title">ID Proofs</h6>

            <div className="row">
              <div className="col-6 mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  AADHAR Number
                </label>
                <div className="register_app_val mb-3">
                  {data.aadharNo || ""}
                </div>
              </div>
              <div className="col-6  mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Upload AADHAR Card
                  <Download className="download_icon" />
                </label>
                <div className="register_app_val mb-3">
                  {data.aadharCardphoto || ""}
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <h6 className="register_app_sub_title">Contact details</h6>

            <div className="row">
              <div className="col-6 mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Phone Number
                </label>
                <div className="register_app_val mb-3">{data.Mobile || ""}</div>
              </div>
              <div className="col-6  mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  WhatsApp Number
                </label>
                <div className="register_app_val mb-3">
                  {data.WhatsAppNumber || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Email ID
                </label>
                <div className="register_app_val mb-3">
                  {data.emailaddress || ""}
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <h6 className="register_app_sub_title">Present Address</h6>

            <div className="row">
              <div className="col-6 mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  House/Building Number
                </label>
                <div className="register_app_val mb-3">
                  {data.BuildingNumberPresent || ""}
                </div>
              </div>

              <div className="col-6  mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Street Name
                </label>
                <div className="register_app_val mb-3">
                  {" "}
                  {data.StreetNamePresent || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Town/City
                </label>
                <div className="register_app_val mb-3">
                  {" "}
                  {data.CityPresent || ""}{" "}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  District
                </label>
                <div className="register_app_val mb-3">
                  {data.DistrictPresent || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  State/Province
                </label>
                <div className="register_app_val mb-3">
                  {" "}
                  {data.statePermanent || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Pin/Postal Code
                </label>
                <div className="register_app_val mb-3">
                  {" "}
                  {data.PincodePresent || ""}
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <h6 className="register_app_sub_title">Technicnal Qualification</h6>

            <div className="row">
              <div className="col-6 mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Technical Course
                </label>
                <div className="register_app_val mb-3">
                  {data.techQualificationOne || ""}
                </div>
              </div>

              <div className="col-6  mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Technical Course
                </label>
                <div className="register_app_val mb-3">
                  {data.techQualificationTwo || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Upload Certificate
                  <Download className="download_icon" />
                </label>
                <div className="register_app_val mb-3">
                  {" "}
                  {data.techQualificationOneFile || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Year of Passing
                </label>
                <div className="register_app_val mb-3">
                  {data.techQualificationOneYear || ""}
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <h6 className="register_app_sub_title">Community</h6>

            <div className="row">
              <div className="col-6 mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Category
                </label>
                <div className="register_app_val mb-3"> {data.Cast || ""}</div>
              </div>

              <div className="col-6  mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Upload Certificate
                  <Download className="download_icon" />
                </label>
                <div className="register_app_val mb-3">
                  {data.CastFile || ""}
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <h6 className="register_app_sub_title">Special Preferences</h6>

            <div className="row">
              <div className="col-6 mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Category
                </label>
                <div className="register_app_val mb-3">
                  {data.SpecialPre || ""}
                </div>
              </div>

              <div className="col-6  mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Upload Certificate
                  <Download className="download_icon" />
                </label>
                <div className="register_app_val mb-3">
                  {data.SpecialPreFile || ""}
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <h6 className="register_app_sub_title">
              Other Course relevant Informations
            </h6>

            <div className="row">
              <div className="col-12 mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app oneline"
                >
                  Have You Already Studied for Competitive Examination?
                </label>
                <div className="register_app_val mb-3">
                  {data.CompetitiveExamination || ""}
                </div>
              </div>

              <div className="col-6 ">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Name of the Examination
                </label>
                <div className="register_app_val mb-3">
                  {data.CompetitiveExamName || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Name of the Institute
                </label>
                <div className="register_app_val mb-3">
                  {data.Institute || ""}
                </div>
              </div>
              <div className="col-12">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app oneline"
                >
                  Have You Cleared Any Exam Previously?
                </label>
                <div className="register_app_val mb-3">
                  {data.ExamPreviously || ""}
                </div>
              </div>
              <div className="col-12">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app oneline"
                >
                  Exam details
                </label>
                <div className="register_app_val mb-3">
                  {data.ExamDetailsRes || ""}
                </div>
              </div>
              <div className="col-12">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app oneline"
                >
                  Are you currently working?
                </label>
                <div className="register_app_val mb-3">
                  {data.workingRes || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Designation
                </label>
                <div className="register_app_val mb-3">
                  {data.Designation || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Department
                </label>
                <div className="register_app_val mb-3">
                  {data.Department || ""}
                </div>
              </div>
              <div className="col-12">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app oneline"
                >
                  Are You an AIASA Student?
                </label>
                <div className="register_app_val mb-3">
                  {data.AIASAStudent || ""}
                </div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Year
                </label>
                <div className="register_app_val mb-3">{data.Year || ""}</div>
              </div>
              <div className="col-6">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Batch
                </label>
                <div className="register_app_val mb-3">{data.Batch || ""}</div>
              </div>
              <div className="col-12">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app oneline"
                >
                  Upload ID Card
                  <Download className="download_icon" />
                </label>
                <div className="register_app_val mb-3">
                  {data.IDPhoto || ""}
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <h6 className="register_app_sub_title">Course of Interest</h6>

            <div className="row">
              <div className="col-6  mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Course Category
                </label>
                <div className="register_app_val mb-3">
                  {data.CourseCate || ""}
                </div>
              </div>

              <div className="col-6  mt-3">
                <label
                  htmlFor="inputName"
                  className="form-label text-muted register_app"
                >
                  Teaching Medium
                </label>
                <div className="register_app_val mb-3">
                  {data.TeachMedium || ""}
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <h6 className="register_app_sub_title">Batch Details</h6>

            <div className="row">
              <div className="flex4 ">
                <div className="inner_flex4">
                  <div>
                    <img
                      style={{ width: "80px", height: "80px" }}
                      src="https://i1.wp.com/aiasabook.com/wp-content/uploads/2022/03/457287.jpg?w=800&ssl=1"
                      alt="airplane"
                    />
                  </div>
                  <div>
                    <h6 className="register_app_sub_title">
                      {data.TeachMedium || ""}
                    </h6>
                    <small className="register_app_small">{""}</small>
                  </div>
                  <LaptopChromebook className="iconregister_app" />

                  <div>
                    <span className="flex_title_register_app">Class Mode</span>
                    <h6 className="flex_sub_register_app">{""}</h6>
                  </div>
                  <Today className="iconregister_app" />

                  <div>
                    <span className="flex_title_register_app">Starts On</span>
                    <h6 className="flex_sub_register_app">{""}</h6>
                  </div>
                  <CurrencyRupee className="iconregister_app" />

                  <div>
                    <span className="flex_title_register_app">Course Fee</span>
                    <h6 className="flex_sub_register_app">{""}</h6>
                  </div>
                  <Alarm className="iconregister_app" />

                  <div>
                    <span className="flex_title_register_app">Duration</span>
                    <h6 className="flex_sub_register_app">{""}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default Register;
