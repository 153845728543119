import React, { useState, useRef, useEffect } from "react";

import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import { CloseOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
function NFSalaryListView(props) {
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  const modalCloseRef = useRef(null);
  // List data load table

  // use state
  let page = R_PER_PAGE;
  const [error, setError] = useState([]);
  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [csvListData, setCsvListData] = useState([]);

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  useEffect(() => {
    loadListdata();
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  function loadListdata() {
    let statusFilter = {
      staff_id: props.staff_id,
    };
    // console.log(statusFilter, "statusFilter");
    AxiosInstance.post("api/staff/salary/list", statusFilter)
      .then((res) => {
        // console.log("editadasd ", res);
        if (res.data.status === true) {
          const updatedData = res.data.data.list.map((item) => {
            return item;
          });
          // Filter out null items
          const filteredData = updatedData.filter((item) => item !== null);
          // Set the state with the filtered data
          setUpdateListData(filteredData);

          const csvData = filteredData.map((el) => {
            return {
              processed_date: el.processed_date,
              no_of_class: el.no_of_class,
              start_date: el.start_date,
              end_date: el.end_date,
              tot_salary: el.tot_salary,
              deduction: el.deduction,
              takehome_salary: el.takehome_salary,
            };
          });
          setCsvListData(csvData);

          const salary = res.data.data.staff_salary.map((item) => {
            return {
              no_class:
                item.salary_type != null
                  ? item.salary_type === 0
                    ? "0"
                    : "1"
                  : "1",
              per_class_amount: item.salary,
            };
          });

          setFormData((prevFormData) => ({
            ...prevFormData,
            ...salary[0], // Assuming you want to update the form data with the first item in the 'salary' array
          }));
        } else if (res.data.status === false) {
          setUpdateListData([]);
        }
      })
      .catch((error) => {
        // console.log("Error res: ", error.response.data);
        setUpdateListData([]);
        return error;
      });
  }

  // generate pdf when user click download button
  const generatePDF = () => {
    // Create a new jsPDF instance
    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Landscape orientation
      unit: "in", // Use inches as the unit of measurement
      format: [9.5, 11], // Set the dimensions to a standard letter size (9.5 x 11 inches)
    });

    doc.setFont("arabic"); // Set the font to 'arabic'
    doc.setFontSize(12); // Adjust the desired font size
    // Add a title to the PDF document
    doc.text("Course batch data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];
    const headers = [
      "processed_date",
      "month_of",
      "tot_salary",
      "deduction",
      "takehome_salary",
    ]; // Assuming updateListData is your state variable

    tableData.push(headers);

    updateListData.forEach((item) => {
      const rowData = [];
      headers.forEach((header) => {
        rowData.push(item[header]);
      });
      tableData.push(rowData);
    });

    // Define the column widths for the header columns (adjust as needed)
    // Calculate column widths based on the content of header and tbody cells
    const headerColumnStyles = {};
    const tbodyColumnStyles = {};

    headers.forEach((header, index) => {
      const maxHeaderWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue = row[index] !== null ? row[index].toString() : ""; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 1 + 6; // Adjust the multiplier as needed
          })
      );
      const maxTbodyWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue = row[index] !== null ? row[index].toString() : ""; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 2 + 6; // Adjust the multiplier as needed
          }) // Adjust the multiplier as needed
      );

      headerColumnStyles[index] = { columnWidth: maxHeaderWidth };
      tbodyColumnStyles[index] = { columnWidth: maxTbodyWidth };
    });

    // Add data rows to tableData array
    const tableOptions = {
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
      // columnStyles: { ...headerColumnStyles, ...tbodyColumnStyles }, // Specify dynamic column widths
    };

    // Add the table to the PDF document
    doc.autoTable(tableOptions);

    // Save the PDF document
    doc.save("AdmissionData.pdf");
  };

  const [formData, setFormData] = useState({
    no_class: "1",
    per_class_amount: "",
  });

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;

    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      // setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  const handleReadView = (id, staff_id) => {
    navigate(
      `/Faculties/ReadViewSalary/${props.userId}/${id}/${staff_id}/${props.staff_type}`
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError({
      ...error,
      [name]: "",
    });
  };

  const validateSalaryDetails = (data) => {
    const errors = {};

    if (data.no_class === "") {
      errors.no_class = "Please choose class.";
    }
    if (data.per_class_amount === "") {
      errors.per_class_amount = "Please enter amount.";
    }
    return errors;
  };
  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // console.log(formData); // Log the form data to the console for testing purposes
    const errors = validateSalaryDetails(formData);
    if (Object.keys(errors).length === 0) {
      try {
        AxiosInstance.post("api/staff/salary/cost/edit", {
          staff_id: parseInt(props.staff_id),
          staff_type: props.staff_type,
          salary_type: parseInt(formData.no_class),
          salary: parseInt(formData.per_class_amount),
        })
          .then((res) => {
            // console.log(res, "classList");
            if (res.data.status === true) {
              Swal.fire({
                title: "Success",
                text: res.data.message,
                icon: "success",
                confirmButtonText: "Ok",
                willClose: () => {
                  const modal = document.querySelector("#myModal"); // Adjust the selector as needed
                  // const modal_close = document.querySelector('#closeModel');
                  // if (modal) {
                  //   modal_close.click();
                  // }
                  const modal_close = modalCloseRef.current;
                  if (modal && modal_close) {
                    modal_close.click();
                  }
                },
              });
            } else {
              Swal.fire({
                title: "Success",
                text: res.data.message,
                icon: "success",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((error) => {
            return error;
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  //   const handleEditView = (userId) => {
  //     navigate(`/Faculties/addfaculty/${userId}`);
  //   };
  //   // className="row tab-content mt-4"
  return (
    <div
      className={
        props.activeTab === 3
          ? "row tab-content mt-4"
          : "row tab-content mt-4 hide "
      }
      id="ex2-content"
    >
      <div
        className={
          props.activeTab === 3
            ? "tab-pane fade show active col-md-12"
            : "tab-pane fade col-md-12 "
        }
      >
        <section className="StepOne">
          <div className="heading  d-flex justify-content-end ">
            <button
              data-bs-toggle="modal"
              data-bs-target="#myModal"
              type="button"
              className="SaveButton"
            >
              {" "}
              <span className="material-icons me-1 fs-5 align-middle text-white">
                border_color
              </span>
              <a href="javascript:void(0)">Edit Salary Details</a>
            </button>
          </div>
          <div className="row boxs">
            <div className="col-md-12">
              <p className="StepTitle">Salary Details</p>
              <div className="row mb-4">
                <div className="col-lg-6 col-md-12">
                  <div className="forms ">
                    <label className="salary_label" htmlFor="no_class">
                      Salary Based on:
                    </label>
                    {formData.no_class != null && formData.no_class !== "" ? (
                      <span className="salary_text ">
                        {formData.no_class !== "0"
                          ? "Month"
                          : "Number of classes"}
                      </span>
                    ) : (
                      <span className="salary_text">{"⎯"}</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="forms ">
                    <label className="salary_label" htmlFor="MonthOf">
                      {formData.no_class !== "0"
                        ? `Salary Per Month`
                        : "Salary Per Class"}
                      :
                    </label>
                    {formData.per_class_amount != null &&
                    formData.per_class_amount !== "" ? (
                      <span className="salary_text ">
                        {" "}
                        {formData.per_class_amount}
                      </span>
                    ) : (
                      <span className="salary_text">{"⎯"}</span>
                    )}
                  </div>
                </div>
              </div>
              <div class="modal" id="myModal">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="model_title">Edit Salary Details</h4>
                      <button
                        type="button"
                        ref={modalCloseRef}
                        class="btn-close"
                        id="closeModel"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>

                    <div class="modal-body">
                      <div className="row mb-4 model">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label className="model_label" htmlFor="fname">
                              Salary Based on:
                            </label>
                            <select
                              class="Inputs"
                              id="no_class"
                              defaultValue={formData.no_class}
                              onChange={handleChange}
                              name="no_class"
                              required
                            >
                              <option value="0">Number of Classes</option>
                              <option value="1" selected>
                                Month
                              </option>
                            </select>
                            {error.no_class && (
                              <small className="error">{error.no_class}</small>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label
                              className="model_label"
                              htmlFor="per_class_amount"
                            >
                              {formData.no_class !== "0"
                                ? `Salary Per Month`
                                : "Salary Per Class"}
                              ::
                            </label>
                            <input
                              type="number"
                              className="Inputs"
                              id="per_class_amount"
                              name="per_class_amount"
                              value={
                                formData.per_class_amount < 0
                                  ? 0
                                  : formData.per_class_amount
                              }
                              onChange={handleChange}
                              placeholder={
                                formData.no_class !== "0"
                                  ? `Salary Per Month`
                                  : "Salary Per Class"
                              }
                              required
                            />
                            {error.per_class_amount && (
                              <small className="error">
                                {error.per_class_amount}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div className="heading me-4 mt-3 d-flex justify-content-end ">
                        <Link
                          to="javascript:void(0)"
                          className="CancelButton"
                          data-bs-dismiss="modal"
                        >
                          Back to View Faculty Profile
                        </Link>
                        <button
                          type="button"
                          className="SaveButton"
                          onClick={handleSubmit}
                        >
                          Save Salary Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="borderSalary"></div>
        <div className="row ">
          <div className="col-lg-3 col-md-4"></div>
          <div className="col-lg-3 col-md-4"></div>
          <div className="col-lg-6  dflex col-md-12">
            <button type="button" className="ListViewBtnss me-md-3">
              <span className="material-icons me-1 align-middle text-white">
                file_download
              </span>
              <CSVLink data={csvListData} filename={"NonTeachingSalary.csv"}>
                Download as CSV
              </CSVLink>
            </button>
            <button
              type="button"
              className="ListViewBtnss me-md-3"
              onClick={generatePDF}
            >
              <span className="material-icons me-1 align-middle text-white">
                file_download
              </span>
              Download as PDF
            </button>
            <button type="button" className="ListViewBtn">
              <Link
                to={`/Faculties/AddSalary/${props.userId}/${props.staff_id}/${props.staff_type}/${formData.per_class_amount}`}
              >
                <span className="material-icons me-1 align-middle text-white">
                  add_circle_outline
                </span>
              </Link>
              <Link
                to={`/Faculties/AddSalary/${props.userId}/${props.staff_id}/${props.staff_type}/${formData.per_class_amount}`}
              >
                Add New Salary
              </Link>
            </button>
          </div>
        </div>

        <div className="row mt-3 ms-1 me-1">
          <div className="col-md-12 table-responsive p-0">
            <table className="table" id="example">
              <thead>
                <tr>
                  <th id="th" style={{ width: "5%" }}>
                    <span className="text_th">S.No</span>{" "}
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "serialNumber")}
                    >
                      {" "}
                      import_export{" "}
                    </span>
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "processed_date")}
                    >
                      Processed On
                    </span>{" "}
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "processed_date")}
                    >
                      {" "}
                      import_export{" "}
                    </span>
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "month_of")}
                    >
                      For the Month of
                    </span>

                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "month_of")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "tot_salary")}
                    >
                      {" "}
                      Total Salary
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "tot_salary")}
                    >
                      import_export
                    </span>{" "}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "deduction")}
                    >
                      Deduction
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "deduction")}
                    >
                      import_export
                    </span>{" "}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "takehome_salary")}
                    >
                      Take Home Salary
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "takehome_salary")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    {" "}
                    <span className="text_th">Action</span>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentData.length === 0 ? (
                  <tr>
                    <td colspan="10" style={{ textAlign: "center" }}>
                      No record to display
                    </td>
                  </tr>
                ) : (
                  currentData.map((course, index) => (
                    <tr key={index} title={index}>
                      <td id="td">{course.serialNumber}</td>
                      <td id="td">
                        {" "}
                        {moment(course.processed_date).format("YYYY-MM-DD")}
                      </td>
                      <td id="td"> {course.month_of}</td>
                      <td id="td">{course.tot_salary}</td>
                      <td id="td">{course.deduction}</td>
                      <td id="td">{course.takehome_salary}</td>
                      <td id="td">
                        <button
                          className="button"
                          onClick={() =>
                            handleReadView(course.id, course.staff_id)
                          }
                        >
                          <span class="material-icons">visibility</span>
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3 ms-1 me-1">
          <div className="col-md-12">
            <div className="pag w-100 dflexcenter">
              <span className="ipage">Items Per page</span>
              <select
                name="pageCount"
                id="pageCount"
                defaultValue={rowsPerPage}
                onChange={handlePageCount}
                className="ms-2"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="pagenumber ms-2">
                {" "}
                {currentPage * rowsPerPage + 1 - rowsPerPage} -{" "}
                {/* {currentPage * rowsPerPage} of {updateListData.length} */}
                {updateListData.length < currentPage * rowsPerPage
                  ? updateListData.length
                  : currentPage * rowsPerPage}{" "}
                of {updateListData.length}
              </span>
              <button
                className="prev_button ms-2 me-2"
                onClick={(e) => handlePrev(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span class="material-icons">chevron_left</span>
              </button>
              <button
                className="prev_button"
                onClick={() => handleNext(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <span class="material-icons">chevron_right</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NFSalaryListView;
