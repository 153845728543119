import React from "react";
import "../question/Question.css";

export default function Rtypepara(props) {
  const { index } = props;

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  return (
    <div className=" Question_temp ">
      {props.list.en_question !==null && props.list.en_question !=="" && (
        <div className="row mt-4">
          <div className="col-md-12">
            <span className="question_labels ">
              {EsplitLines.length > 0 ? EsplitLines[0] : ""}
            </span>
          </div>
          {EsplitLines.map((line, index) => {
            if (line.startsWith("p1 - ")) {
              return (
                <div className="col-md-12" key={index}>
                  <p className="question_labels">{line.substring(5)}</p>
                  {/* <span >{line}</span> */}
                </div>
              );
            }
          })}
        </div>
      )}

      {props.list.tm_question !==null && props.list.tm_question !=="" && (
        <div className="row tamilQues">
          <div className="col-md-12">
            <span className="question_labels">
              {splitLines.length > 0 ? splitLines[0] : ""}
            </span>
          </div>
          {splitLines.map((line, index) => {
            if (line.startsWith("p1-")) {
              return (
                <div className="col-md-12" key={index}>
                  <p className="question_labels">{line.substring(4)}</p>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
}
