import React from "react";
import { Link } from "react-router-dom";

function Subnav(props) {
  // console.log(props);
  const wordRegex = /^[A-Za-z]+$/;
  return (
    <div className="AdmissionForm">
      <div className="row subnav">
    
        <div className="col-md-4 col-sm-12">
        <ul>
    {props.record.pathname !=="" && props.record.pathname !==null && props.record.pathname.split("/").map((item, index) => {
      const isItemWord = wordRegex.test(item);
      if (index === 0) {
        return (
          <>
            <li key={index} className="custom-item ">
              <Link to={`/${item}`}>Home</Link>
            </li>
          </>
        );
      } else if (index !== 0 && isItemWord) {
        // check if the item is not a number
        return (
          <>
            <li key={index + "_arrow"} className="custom-items">
              <span className="material-icons ms-4">
                keyboard_arrow_right
              </span>
            </li>
            <li key={index + "_text"} className="custom-itemss">
              <Link to={`/${item}`}>{item}</Link>
            </li>
          </>
        );
      } else {
        return null; // skip rendering when the item is a number
      }
    })}
  </ul>
         </div>
       
<div className="col-md-8 d-flex" id="mobileview">
  <div className="commanInfo">
    <small id="borderss_ss" className="text-center ms-3"></small>
    <div className="rows d-flex">
      <li id="number">
        <span
          className="material-icons align-text-top ms-0 fw-bold text-danger"
          id="icon"
        >
          phone
        </span>{" "}
        <small className="ms-0"> Kanchipuram </small>
        <small id="tex" ><a href="tel:7845952124">7845952124</a></small>
        <small id="border"></small>
      </li>
      <li id="number">
        <small className="ms-1"> Vellore </small>
        <small id="tex"  ><a href="tel:7845952126">7845952126</a></small>
        <small id="border"></small>
      </li>
      <li id="number">
        <small className="ms-1"> Tambaram </small>
        <small id="tex"  ><a href="tel:7200623306">7200623306</a></small>
        <small id="border"></small>
      </li>
      <li id="number">
        <small className="ms-1"> Ch - Anna Nagar </small>
        <small id="tex" ><a href="tel:9500054478">9500054478</a></small>
        <small id="border"></small>
      </li>
      <li id="number">
        <span
          className="material-icons align-text-top ms-1 fw-bold text-danger"
          id="icon"
        >
          email
        </span>{" "}
        <small className=" tcttc" ><a href="mailto:enquiry@annaiasacademy.com">enquiry@annaiasacademy.com</a></small>
      </li>
    </div>
  </div>
</div>

        <div className="col-md-8 d-flex" id="mobileview_Screen">
          <div className="commanInfo">
            <div className="rows d-flex">
              <li id="number">
              <marquee behavior="scroll" direction="left" scrollamount="4">
    <span
      className="material-icons align-text-top ms-2 fw-bold text-danger"
      id="icon"
    >
      phone
    </span>{" "}
    <small className="ms-1"> Kanchipuram </small>
    <small id="tex" ><a href="tel:7845952124">7845952124</a></small>
    <span id="border"></span>
    <small className="ms-3"> Tambaram </small>
    <small id="tex"  ><a href="tel:7200623306">7200623306</a></small>
    <span id="border"></span>
    <small className="ms-2"> Vellore </small>
        <small id="tex"  ><a href="tel:7845952126">7845952126</a></small>
        <span id="border"></span>
    <small className="ms-3"> Ch - Anna Nagar </small>
    <small id="tex" ><a href="tel:9500054478">9500054478</a></small>
    <span id="border"></span>
    <span
      className="material-icons align-text-top ms-2 fw-bold text-danger"
      id="icon"
    >
      email
    </span>{" "}
    <small className="ms-1 tcttc"><a href="mailto:enquiry@annaiasacademy.com">enquiry@annaiasacademy.com</a></small>
  </marquee>
              </li>

            </div>
          </div>
        </div>

      </div>
  
      </div>

  );
}

export default Subnav;
