export const validateTfcous = (
  data,
  certificates,
  edu,
  step,
  category,
  subject,
  AdmissionRef_validation
) => {
  const errors = {};
  if (step === 1 && !data.FirstName) {
    AdmissionRef_validation.firstName.current.focus();
  } else if (step === 1 && !data.LastName) {
    AdmissionRef_validation.lastName.current.focus();
  } else if (step === 1 && !data.Fathername) {
    AdmissionRef_validation.father.current.focus();
  } else if (step === 1 && !data.DOB) {
    AdmissionRef_validation.mdob.current.setFocus();
  } else if (step === 1 && !data.age) {
    AdmissionRef_validation.age.current.focus();
  } else if (step === 1 && !data.PlaceBirth) {
    AdmissionRef_validation.place_of_birth.current.focus();
  } else if (step === 1 && !data.gender) {
    AdmissionRef_validation.gender.current.focus();
  } else if (step === 1 && !data.MaritalStatus) {
    AdmissionRef_validation.mStatus.current.focus();
  } else if (step === 1 && data.MaritalStatus === "Married" && !data.Spouse) {
    AdmissionRef_validation.spouse_name.current.focus();
  } else if (
    step === 1 &&
    data.MaritalStatus === "Married" &&
    !data.Occupation
  ) {
    AdmissionRef_validation.Occupation.current.focus();
  } else if (step === 1 && !data.Mobile) {
    AdmissionRef_validation.pno.current.focus();
  } else if (step === 1 && data.Mobile && data.Mobile.length < 10) {
    AdmissionRef_validation.pno.current.focus();
  } else if (step === 1 && data.Mobile && !/^\d+$/.test(data.Mobile)) {
    AdmissionRef_validation.pno.current.focus();
  } else if (
    step === 1 &&
    (!data.WhatsAppNumber || !/^\d+$/.test(data.WhatsAppNumber)) &&
    !data.ContactNumber
  ) {
    AdmissionRef_validation.wno.current.focus();
  } else if (
    step === 1 &&
    data.WhatsAppNumber &&
    data.WhatsAppNumber.length < 10 &&
    !data.ContactNumber
  ) {
    AdmissionRef_validation.wno.current.focus();
  } else if (
    step === 1 &&
    data.WhatsAppNumber &&
    !/^\d+$/.test(data.WhatsAppNumber) &&
    !data.ContactNumber
  ) {
    AdmissionRef_validation.wno.current.focus();
  } else if (step === 1 && !data.emailaddress) {
    AdmissionRef_validation.email.current.focus();
  } else if (
    step === 1 &&
    data.emailaddress &&
    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.emailaddress)
  ) {
    AdmissionRef_validation.email.current.focus();
  } else if (step === 1 && !data.Profilephoto) {
    AdmissionRef_validation.pSize.current.focus();
  } else if (step === 1 && !data.aadharNo) {
    AdmissionRef_validation.ano.current.focus();
  } else if (step === 1 && data.aadharNo && data.aadharNo.length < 12) {
    AdmissionRef_validation.ano.current.focus();
  } else if (step === 1 && data.aadharNo && data.aadharNo.length > 12) {
    AdmissionRef_validation.ano.current.focus();
  }
  // else if (step === 1 && data.aadharNo && !/^[2-9][0-9]{11}$/.test(data.aadharNo)) {
  //   AdmissionRef_validation.ano.current.focus();
  // }
  else if (step === 1 && !data.aadharCardphoto) {
    AdmissionRef_validation.apath.current.focus();
  } else if (step === 1 && !data.panNo) {
    AdmissionRef_validation.panno.current.focus();
  } else if (step === 1 && data.panNo && data.panNo.length < 10) {
    AdmissionRef_validation.panno.current.focus();
  } else if (step === 1 && data.panNo && data.panNo.length > 10) {
    AdmissionRef_validation.panno.current.focus();
  }
  // The length of a PAN (Permanent Account Number) card in India is 10 characters.
  // AIASA-000117  v1 issues solved by lalitha  //ABCDE1234F - sample format for pan no
  else if (
    step === 1 &&
    data.panNo &&
    !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(data.panNo)
  ) {
    AdmissionRef_validation.panno.current.focus();
  } else if (step === 1 && !data.panPhoto) {
    AdmissionRef_validation.Ppath.current.focus();
  } else if (step === 2 && !data.BuildingNumberPresent) {
    AdmissionRef_validation.phno.current.focus();
  } else if (step === 2 && !data.StreetNamePresent) {
    AdmissionRef_validation.psname.current.focus();
  } else if (step === 2 && !data.TalukPresent) {
    AdmissionRef_validation.ptaluk.current.focus();
  } else if (step === 2 && !data.PostPresent) {
    AdmissionRef_validation.ppost.current.focus();
  } else if (step === 2 && !data.AreaPresent) {
    AdmissionRef_validation.parea.current.focus();
  } else if (step === 2 && !data.PincodePresent) {
    AdmissionRef_validation.ppincode.current.focus();
  } else if (step === 2 && !/^[1-9][0-9]{5}$/.test(data.PincodePresent)) {
    AdmissionRef_validation.ppincode.current.focus();
  } else if (step === 2 && !data.CityPresent) {
    AdmissionRef_validation.pcity.current.focus();
  } else if (
    step === 2 &&
    !data.BuildingNumberPermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.perhno.current.focus();
  } else if (
    step === 2 &&
    !data.StreetNamePermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.persname.current.focus();
  } else if (
    step === 2 &&
    !data.TalukPermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.pertaluk.current.focus();
  } else if (
    step === 2 &&
    !data.PostPermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.pererpost.current.focus();
  } else if (
    step === 2 &&
    !data.AreaPermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.perarea.current.focus();
  } else if (
    step === 2 &&
    !data.PincodePermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.perpincode.current.focus();
  } else if (
    step === 2 &&
    !/^[1-9][0-9]{5}$/.test(data.PincodePermanent) &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.perpincode.current.focus();
  } else if (
    step === 2 &&
    !data.CityPermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.percity.current.focus();
  } else if (step === 4 && !data.prevTeachexp) {
    AdmissionRef_validation.workingexp.current.focus();
  } else if (step === 4 && !data.workingexp && data.prevTeachexp === "Yes") {
    AdmissionRef_validation.workingexp.current.focus();
  } else if (step === 4 && !data.teachexp && data.prevTeachexp === "Yes") {
    AdmissionRef_validation.teachingexp.current.focus();
  }
  //  else if (step === 4 && !data.working_ex_file && data.prevTeachexp === "Yes") {
  //     AdmissionRef_validation.working_ex_file.current.focus();
  // }else if (step === 4 && !data.Teaching_ex_file && data.prevTeachexp === "Yes") {
  //     AdmissionRef_validation.Teaching_ex_file.current.focus();
  // }
  else if (step === 4 && !data.teachType) {
  } else if (step === 4 && !data.designation) {
    AdmissionRef_validation.des.current.focus();
  } else if (step === 4 && !data.DJoin) {
    AdmissionRef_validation.djoin.current.focus();
  } else if (step === 4 && !data.location) {
    AdmissionRef_validation.location.current.focus();
  } else if (step === 4 && !data.VoiceOpinion) {
    AdmissionRef_validation.VoiceOpinion.current.focus();
  } else if (step === 3 && edu.length === 0) {
    AdmissionRef_validation.education.current.focus();
  } else if (step === 3 && !data.spq) {
    AdmissionRef_validation.spc.current.focus();
  }
  // AIASA-000119  v1 issues solved by lalitha
  for (let i = 0; i < certificates.length; i++) {
    const cert = certificates[i];

    if (step === 3 && edu.includes("UG")) {
      if (!cert.UGDegree && cert.name === "UG") {
        AdmissionRef_validation.eduUdeg.current.focus();
      } else if (!cert.UGMajor && cert.name === "UG") {
        AdmissionRef_validation.eduUMaj.current.focus();
      } else if (
        cert.UGMajor &&
        cert.UGMajor === "Others" &&
        !cert.UGMajor_Other &&
        cert.name === "UG"
      ) {
        AdmissionRef_validation.eduUMajOther.current.focus();
      } else if (!cert.UGYearpassing && cert.name === "UG") {
        AdmissionRef_validation.eduUpass.current.setFocus();
      } else if (!cert.UGCertificateNumber && cert.name === "UG") {
        AdmissionRef_validation.eduUCer.current.focus();
      } else if (
        cert.UGCertificateNumber &&
        !cert.UGCertificateNumber.trim() &&
        cert.name === "UG"
      ) {
        AdmissionRef_validation.eduUCer.current.focus();
      } else if (
        cert.UGCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.UGCertificateNumber) &&
        cert.name === "UG"
      ) {
        AdmissionRef_validation.eduUCer.current.focus();
      } else if (!cert.UGMarksPercentage && cert.name === "UG") {
        AdmissionRef_validation.eduUmarks.current.focus();
      } else if (
        cert.UGMarksPercentage &&
        typeof cert.UGMarksPercentage == "string" &&
        parseInt(cert.UGMarksPercentage) > 100 &&
        cert.name === "UG"
      ) {
        AdmissionRef_validation.eduUmarks.current.focus();
      } else if (!cert.UGClassName && cert.name === "UG") {
        AdmissionRef_validation.eduUclass.current.focus();
      }
      //  else if (cert.UGClassName && !/^[A-Za-z\s]*$/.test(cert.UGClassName) && cert.name === "UG") {
      //   AdmissionRef_validation.eduUclass.current.focus();
      // }
      else if (!cert.UGNameInstitute && cert.name === "UG") {
        AdmissionRef_validation.eduUins.current.focus();
      } else if (
        cert.UGNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.UGNameInstitute) &&
        cert.name === "UG"
      ) {
        AdmissionRef_validation.eduUins.current.focus();
      }
      //   else if (!cert.UG_Path && cert.name === "UG") {
      //   AdmissionRef_validation.eduUfile.current.focus();
      // }
    } else if (step === 3 && edu.includes("PG")) {
      if (cert.PGDegree == "" && cert.name === "PG") {
        AdmissionRef_validation.eduPdeg.current.focus();
      } else if (!cert.PGMajor && cert.name === "PG") {
        AdmissionRef_validation.eduPMaj.current.focus();
      } else if (
        cert.PGMajor &&
        cert.PGMajor === "Others" &&
        !cert.PGMajor_Other &&
        cert.name === "PG"
      ) {
        AdmissionRef_validation.eduPMajOther.current.focus();
      } else if (!cert.PGYearpassing && cert.name === "PG") {
        AdmissionRef_validation.eduPpass.current.setFocus();
      } else if (!cert.PGCertificateNumber && cert.name === "PG") {
        AdmissionRef_validation.eduPCer.current.focus();
      } else if (
        cert.PGCertificateNumber &&
        !cert.PGCertificateNumber.trim() &&
        cert.name === "PG"
      ) {
        AdmissionRef_validation.eduPCer.current.focus();
      } else if (
        cert.PGCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.PGCertificateNumber) &&
        cert.name === "PG"
      ) {
        AdmissionRef_validation.eduPCer.current.focus();
      } else if (!cert.PGMarksPercentage && cert.name === "PG") {
        AdmissionRef_validation.eduPmarks.current.focus();
      } else if (
        cert.PGMarksPercentage &&
        typeof cert.PGMarksPercentage === "string" &&
        parseInt(cert.PGMarksPercentage) > 100 &&
        cert.name === "PG"
      ) {
        AdmissionRef_validation.eduPmarks.current.focus();
      } else if (!cert.PGClassName && cert.name === "PG") {
        AdmissionRef_validation.eduPclass.current.focus();
      }
      // else if (cert.PGClassName && !/^[A-Za-z\s]*$/.test(cert.PGClassName) && cert.name === "PG") {
      //   AdmissionRef_validation.eduPclass.current.focus();
      // }
      else if (!cert.PGNameInstitute && cert.name === "PG") {
        AdmissionRef_validation.eduPins.current.focus();
      } else if (
        cert.PGNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.PGNameInstitute) &&
        cert.name === "PG"
      ) {
        AdmissionRef_validation.eduPins.current.focus();
      }
      //  else if (!cert.PG_Path && cert.name === "PG") {
      //   AdmissionRef_validation.eduPfile.current.focus();
      // }
    } else if (step === 3 && edu.includes("Integrated PG")) {
      if (!cert.IPDegree && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduIdeg.current.focus();
      } else if (!cert.IPMajor && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduIMaj.current.focus();
      } else if (
        cert.IPMajor &&
        cert.IPMajor === "Others" &&
        !cert.IPMajor_Other &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eduIMajOther.current.focus();
      } else if (!cert.IPYearpassing && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduIpass.current.setFocus();
      } else if (!cert.IPCertificateNumber && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduICer.current.focus();
      } else if (
        cert.IPCertificateNumber &&
        !cert.IPCertificateNumber.trim() &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eduICer.current.focus();
      } else if (
        cert.IPCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.IPCertificateNumber) &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eduICer.current.focus();
      } else if (!cert.IPMarksPercentage && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduImarks.current.focus();
      } else if (
        cert.IPMarksPercentage &&
        typeof cert.IPMarksPercentage === "string" &&
        parseInt(cert.IPMarksPercentage) > 100 &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eduImarks.current.focus();
      } else if (!cert.IPClassName && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduIclass.current.focus();
      }
      //  else if (cert.IPClassName && !/^[A-Za-z\s]*$/.test(cert.IPClassName) && cert.name === "Integrated PG") {
      //   AdmissionRef_validation.eduIclass.current.focus();
      // }
      else if (!cert.IPNameInstitute && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduIins.current.focus();
      } else if (
        cert.IPNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.IPNameInstitute) &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eduIins.current.focus();
      }
      // else if (!cert.IP_Path && cert.name === "Integrated PG") {
      //   AdmissionRef_validation.eduIfile.current.focus();
      // }
    } else if (step === 3 && edu.includes("phd")) {
      if (!cert.PHDDegree && cert.name === "phd") {
        AdmissionRef_validation.eduPhddeg.current.focus();
      } else if (!cert.PHDMajor && cert.name === "phd") {
        AdmissionRef_validation.eduPhdMaj.current.focus();
      } else if (
        cert.PHDMajor &&
        cert.PHDMajor === "Others" &&
        !cert.PHDMajor_Other &&
        cert.name === "phd"
      ) {
        AdmissionRef_validation.eduPhdMajOther.current.focus();
      } else if (!cert.PHDYearpassing && cert.name === "phd") {
        AdmissionRef_validation.eduPhdpass.current.setFocus();
      } else if (!cert.PHDCertificateNumber && cert.name === "phd") {
        AdmissionRef_validation.eduPhdCer.current.focus();
      } else if (
        cert.PHDCertificateNumber &&
        !cert.PHDCertificateNumber.trim() &&
        cert.name === "phd"
      ) {
        AdmissionRef_validation.eduPhdCer.current.focus();
      } else if (
        cert.PHDCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.PHDCertificateNumber) &&
        cert.name === "phd"
      ) {
        AdmissionRef_validation.eduPhdCer.current.focus();
      } else if (!cert.PHDMarksPercentage && cert.name === "phd") {
        AdmissionRef_validation.eduPhdmarks.current.focus();
      } else if (
        cert.PHDMarksPercentage &&
        typeof cert.PHDMarksPercentage === "string" &&
        cert.PHDMarksPercentage.length > 5 &&
        cert.name === "phd"
      ) {
        AdmissionRef_validation.eduPhdmarks.current.focus();
      } else if (!cert.PHDClassName && cert.name === "phd") {
        AdmissionRef_validation.eduPhdclass.current.focus();
      }
      //  else if (cert.PHDClassName && !/^[A-Za-z\s]*$/.test(cert.PHDClassName) && cert.name === "phd") {
      //   AdmissionRef_validation.eduPhdclass.current.focus();
      // }
      else if (!cert.PHDNameInstitute && cert.name === "phd") {
        AdmissionRef_validation.eduPhdins.current.focus();
      } else if (
        cert.PHDNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.PHDNameInstitute) &&
        cert.name === "phd"
      ) {
        AdmissionRef_validation.eduPhdins.current.focus();
      }
      // else if (!cert.PHD_Path && cert.name === "phd") {
      //   AdmissionRef_validation.eduPhdfile.current.focus();
      // }
    } else if (step === 3 && edu.includes("Diploma")) {
      if (!cert.DDegree && cert.name === "Diploma") {
        AdmissionRef_validation.eduDdeg.current.focus();
      } else if (!cert.DMajor && cert.name === "Diploma") {
        AdmissionRef_validation.eduDMaj.current.focus();
      } else if (
        cert.DMajor &&
        cert.DMajor === "Others" &&
        !cert.DMajor_Other &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eduDMajOther.current.focus();
      } else if (!cert.DYearpassing && cert.name === "Diploma") {
        AdmissionRef_validation.eduDpass.current.setFocus();
      } else if (!cert.DCertificateNumber && cert.name === "Diploma") {
        AdmissionRef_validation.eduDCer.current.focus();
      } else if (
        cert.DCertificateNumber &&
        !cert.DCertificateNumber.trim() &&
        cert.name === "Diploma"
      ) {
        AdmissionRef_validation.eduDCer.current.focus();
      } else if (
        cert.DCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.DCertificateNumber) &&
        cert.name === "Diploma"
      ) {
        AdmissionRef_validation.eduDCer.current.focus();
      } else if (!cert.DMarksPercentage && cert.name === "Diploma") {
        AdmissionRef_validation.eduDmarks.current.focus();
      } else if (
        cert.DMarksPercentage &&
        typeof cert.DMarksPercentage === "string" &&
        parseInt(cert.DMarksPercentage) > 100 &&
        cert.name === "Diploma"
      ) {
        AdmissionRef_validation.eduDmarks.current.focus();
      } else if (!cert.DClassName && cert.name === "Diploma") {
        AdmissionRef_validation.eduDclass.current.focus();
      } else if (!cert.DNameInstitute && cert.name === "Diploma") {
        AdmissionRef_validation.eduDins.current.focus();
      } else if (
        cert.DNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.DNameInstitute) &&
        cert.name === "Diploma"
      ) {
        AdmissionRef_validation.eduDins.current.focus();
      }
      //  else if (!cert.D_Path && cert.name === "Diploma") {
      //   AdmissionRef_validation.eduDfile.current.focus();
      // }
    } else if (step === 3 && edu.includes("Professional Courses")) {
      if (!cert.PCDegree && cert.name === "Professional Courses") {
        AdmissionRef_validation.eduCdeg.current.focus();
      } else if (!cert.PCMajor && cert.name === "Professional Courses") {
        AdmissionRef_validation.eduCMaj.current.focus();
      } else if (
        cert.PCMajor &&
        cert.PCMajor === "Others" &&
        !cert.PCMajor_Other &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eeduCMajOther.current.focus();
      } else if (!cert.PCYearpassing && cert.name === "Professional Courses") {
        AdmissionRef_validation.eduCpass.current.setFocus();
      } else if (
        !cert.PCCertificateNumber &&
        cert.name === "Professional Courses"
      ) {
        AdmissionRef_validation.eduCCer.current.focus();
      } else if (
        cert.PCCertificateNumber &&
        !cert.PCCertificateNumber.trim() &&
        cert.name === "Professional Courses"
      ) {
        AdmissionRef_validation.eduCCer.current.focus();
      } else if (
        cert.PCCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.PCCertificateNumber) &&
        cert.name === "Professional Courses"
      ) {
        AdmissionRef_validation.eduCCer.current.focus();
      } else if (
        !cert.PCMarksPercentage &&
        cert.name === "Professional Courses"
      ) {
        AdmissionRef_validation.eduCmarks.current.focus();
      } else if (
        cert.PCMarksPercentage &&
        typeof cert.PCMarksPercentage === "string" &&
        parseInt(cert.PCMarksPercentage) > 100 &&
        cert.name === "Professional Courses"
      ) {
        AdmissionRef_validation.eduCmarks.current.focus();
      } else if (!cert.PCClassName && cert.name === "Professional Courses") {
        AdmissionRef_validation.eduCclass.current.focus();
      }
      //  else if (cert.PCClassName && !/^[A-Za-z\s]*$/.test(cert.PCClassName) && cert.name === "Professional Courses") {
      //   AdmissionRef_validation.eduCclass.current.focus();
      // }
      else if (!cert.PCNameInstitute && cert.name === "Professional Courses") {
        AdmissionRef_validation.eduCins.current.focus();
      } else if (
        cert.PCNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.PCNameInstitute) &&
        cert.name === "Professional Courses"
      ) {
        AdmissionRef_validation.eduCins.current.focus();
      }
      // else if (!cert.PC_Path && cert.name === "Professional Courses") {
      //   AdmissionRef_validation.eduCfile.current.focus();
      // }
    } else if (step === 3 && edu.includes("Others")) {
      if (!cert.OtDegree && cert.name === "Others") {
        AdmissionRef_validation.eduOdeg.current.focus();
      } else if (!cert.OtMajor && cert.name === "Others") {
        AdmissionRef_validation.eduOMaj.current.focus();
      } else if (!cert.OtYearpassing && cert.name === "Others") {
        AdmissionRef_validation.eduOpass.current.setFocus();
      } else if (!cert.OtCertificateNumber && cert.name === "Others") {
        AdmissionRef_validation.eduOCer.current.focus();
      } else if (
        cert.OtCertificateNumber &&
        !cert.OtCertificateNumber.trim() &&
        cert.name === "Others"
      ) {
        AdmissionRef_validation.eduOCer.current.focus();
      } else if (
        cert.OtCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.OtCertificateNumber) &&
        cert.name === "Others"
      ) {
        AdmissionRef_validation.eduOCer.current.focus();
      } else if (!cert.OtMarksPercentage && cert.name === "Others") {
        AdmissionRef_validation.eduOmarks.current.focus();
      } else if (
        cert.OtMarksPercentage &&
        typeof cert.OtMarksPercentage === "string" &&
        parseInt(cert.OtMarksPercentage) > 100 &&
        cert.name === "Others"
      ) {
        AdmissionRef_validation.eduOmarks.current.focus();
      } else if (!cert.OtClassName && cert.name === "Others") {
        AdmissionRef_validation.eduOclass.current.focus();
      }
      //  else if (cert.OtClassName && !/^[A-Za-z\s]*$/.test(cert.OtClassName) && cert.name === "Others") {
      //   AdmissionRef_validation.eduOclass.current.focus();
      // }
      else if (!cert.OtNameInstitute && cert.name === "Others") {
        AdmissionRef_validation.eduOins.current.focus();
      } else if (
        cert.OtNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.OtNameInstitute) &&
        cert.name === "Others"
      ) {
        AdmissionRef_validation.eduOins.current.focus();
      }
      //  else if (!cert.Ot_Path && cert.name === "Others") {
      //   AdmissionRef_validation.eduOfile.current.focus();
      // }
    }
  }

  for (let i = 0; i < subject.length; i++) {
    const cert = subject[i].optionSelected;
    // console.log(cert, "subject validation");

    if (cert === null) {
    }

    // Add additional validation rules for SSLC/HSC_Path if necessary
  }

  return errors;
};
