import React from 'react';
import './table.css';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Papa from 'papaparse';
import { PublicClientApplication } from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';


const Atag = styled.a`
text-decoration: none;
background: #afc343;
color: #0e0e0e;
padding: 6px;
font-size: 12px;
font-weight: bold;

`;

function ViewMeetingDetails() {

  const msalConfig = {
    auth: {
        // clientId: '6ceb56aa-dd8c-4012-ac01-09797ac3e06f',
                clientId: 'c69ced55-6428-4e48-b48a-68ceb5395632',
        redirectUri: 'https://myapps.annaiasacademy.com/' // Replace with your redirect URI
    }
};

const msalInstance = new PublicClientApplication(msalConfig);

  const [meetings, setMeetings] = useState([]);
  const [error, setError] = useState(null);

  const [attend, setAttend] = useState([]);
  const [reportUrl, setReportUrl] = useState(null);


    useEffect(() => {
      const mee = localStorage.getItem('TeamDetails');
      if (mee) {
        const meetingsString = mee;
        const parsedMeetings = JSON.parse(`[${meetingsString}]`);
        setMeetings(parsedMeetings);
       
      }
    }, []);

    const handleClick = (arg) => {

      // console.log(arg);

      msalInstance.loginPopup()
      .then(response => {
          // console.log(response);
          const accessToken = response.accessToken;
          // Use the access token to call the Microsoft Graph API
    
          const client = Client.init({
            authProvider: (done) => {
                done(null, accessToken);
            }
          });

          
          
  
          client.api(`me/onlineMeetings/${arg}/attendanceReports?$expand=attendanceRecords`)
          .get()
          .then((response) => {
            // console.log(response);
            if (response.value.length > 0) {
              setReportUrl(response.value[0].downloadUrl);
            } else {
              setError('Attendance report not available for this meeting.');
            }
          })
          .catch((error) => {
            console.log(error);
            setError('Error downloading attendance report.');
          });

      })
      .catch(error => {
          console.log(error);
      });
  
  
    }
  

    // const attendeeList = [
    //   { email: 'jeevapriya.v@bharatclouds.com', role: 'attendee' },
    //   { email: 'user@example.com', role: 'attendee' }
    // ];
  
    


    // const  handleJoinMeetingClick = (url)  => {

     
    //   // const userPrincipalName = "pakeeratha.p@bharatclouds.com";
    //   const userPrincipalName = "user@example.com";
    //   console.log(userPrincipalName);
  
    //   // Check if the user's email is in the attendee list
    //   const isAuthorized = attendeeList.some((attendee) => attendee.email === userPrincipalName);
  
    //   if (isAuthorized) {
    //     // Redirect the user to the meeting page
    //     window.location.href = url;
    //   } else {
    //     setError('You are not authorized to join this meeting.');
    //   }
    // }
  
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const downloadAttendanceReport = async (meetingId) => {
      msalInstance.loginPopup()
      .then(response => {
          // console.log(response);
          const accessToken = response.accessToken;
          // Use the access token to call the Microsoft Graph API
    
          const client = Client.init({
            authProvider: (done) => {
                done(null, accessToken);
            }
          });


          try {
         


          //   client.api(`me/onlineMeetings/${meetingId}/attendanceReports?$expand=attendanceRecords`).get().then((res)=>{

          //     const response = res;

          //     // Extract attendance report data
          //     const attendanceReport = response.value[0];
          //     const id = attendanceReport.id;
          //     const totalParticipantCount = attendanceReport.totalParticipantCount;
          //     const meetingStartDateTime = attendanceReport.meetingStartDateTime;
          //     const meetingEndDateTime = attendanceReport.meetingEndDateTime;
              
          //     // Format attendance report data as CSV string
          //     const headers = "id,totalParticipantCount,meetingStartDateTime,meetingEndDateTime\n";
          //     const csvData = `${id},${totalParticipantCount},${meetingStartDateTime},${meetingEndDateTime}`;
          //     const csvString = headers + csvData;
              
          //     // Create and download the CSV file
          //     const blob = new Blob([csvString], { type: "text/csv" });
          //     const url = window.URL.createObjectURL(blob);
          //     const a = document.createElement("a");
          //     a.href = url;
          //     a.download = "attendance_report.csv";
          //     document.body.appendChild(a);
          //     a.click();
          //     document.body.removeChild(a);

              
          // });

          client.api(`me/onlineMeetings/${meetingId}/attendanceReports`).get().then((res) => {

            
            const reports = res.value; // assuming `response` is the attendance reports response object
            for (const report of reports) {
              const reportId = report.id;
              client.api(`me/onlineMeetings/${meetingId}/attendanceReports/${reportId}?$expand=attendanceRecords`).get().then((res)=>{
                // console.log(res,"report")
                const attendees = res.attendanceRecords.map(record => (
                  {email: record.emailAddress, id: record.identity.id , teantId : record.identity.tenantId }));
                const csvData = Papa.unparse(attendees);
                const blob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "attendance_report.csv";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
              
            }

            
          });
           
          } catch (error) {
            console.error(error);
          }
      })
      .catch(error => {
          console.log(error);
      });
     
    };
   
    

 
     
    return (

  <>
  <div className='container'>
    <div className='row'>
      <div className='col-md-12'>
      {error && <p className=' mt-5 alert alert-danger'>{error}</p>}
      


      </div>
    </div>
    <div className='row'>
      <div className='col-md-12'>
      <table class="table table-striped" style={{width:"90%"}}>
  <thead>
    <tr>
    
      <th scope="col">MeetingCode</th>
      <th scope="col">CreatedTime</th>
      <th scope="col">JoinUrl</th>
      <th scope="col">Attendance</th>
      <th scope="col">Subject</th>
    </tr>
  </thead>
  <tbody>
       
        {meetings.map((meeting , index) => (

             <tr style={{textTransform: "lowercase"}} key={index.toString()}>
             <td style={{textTransform: "lowercase"}}>{meeting.Meetingcode}</td>
             <td>{new Date(meeting.CreatedTime).toLocaleString()}{ meeting.CreatedTime}</td>
             <td><Atag  href={meeting.joinWebUrl} target='_blank' onContextMenu=    {handleContextMenu}>join Team</Atag></td>
              {/* <td> <button onClick={() => handleClick(meeting.meetingID)}>Download Report</button></td> */}
              <td>
              <button className='btn btn-danger' style={{fontSize: "9px",color:"white"}} onClick={() => downloadAttendanceReport(meeting.meetingID)}>Download Attendance Report</button>

              </td>
            
             <td style={{textTransform: "lowercase"}}>{meeting.Subject}</td>
            </tr>
           
          ))}
          
          </tbody>
</table>
      </div>
    </div>

   

  </div>



</>
      );
}



export default ViewMeetingDetails
