import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import { useState } from "react";
import QtypeOne from "./QtypeOne";
import moment from "moment";
import QtypeTwo from "./QtypeTwo";
import QtypeThree from "./QtypeThree";
import Swal from "sweetalert2";
import QtypeFour from "./QtypeFour";
import QtypeFive from "./QtypeFive";
import Qtypesix from "./Qtypesix";
import Qtypeseven from "./Qtypeseven";
import Qtypeeight from "./Qtypeeight";
import Qtypenine from "./Qtypenine";
import QparaType from "./QparaType";
import QtypeTen from "./QtypeTen";
import Qquesparatype from "./Qquesparatype";

export default function TestQuestion() {
 
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location);
  const { batch_id, event_id, startTime, endTime } = location.state;
  const [formData, setFormData] = useState([{
    pk:"",
    user_answer:"",
    lang:""
  }]);
  const navigateToPreviousScreen = () => {
    navigate(-2); // Navigate back by one step in the history
  };
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  const [listData, setListData] = useState([]);
 
  useEffect(() => {
    const startMoment = moment(startTime, "HH:mm:ss");
    const endMoment = moment(endTime, "HH:mm:ss");
  
    const durationMs = endMoment.diff(startMoment);
    const duration = moment.duration(durationMs);
  
    const timer = setInterval(() => {
      setTime(prevDuration => moment.duration(prevDuration.asMilliseconds() + 1000));
    }, 1000);
  
    setTimeout(() => {
      clearInterval(timer);
      // Perform API binding here
      performApiBinding();
    }, durationMs);
  
    return () => {
      clearInterval(timer);
    };
  }, []); 

  const [time, setTime] = useState(moment.duration(0));

  useEffect(() => {
    AxiosInstance.post("api/test/questionlist", {
      batch_id: parseInt(batch_id),
      event_id: parseInt(event_id),
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          let ut = res.data.data;
          setListData(ut);
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);

  const handleOptionChange = (event , pk, batch_id , event_pk , user_id  , lang) => {
    const {value , name , files } = event.target;

    let dat = {
        user_answer : value,
        question_pk:pk,
        batch_id : batch_id,
        event_id : event_pk,
        language: lang,
        user_id : user_id ,
        created_by:user_id 
    }
    // console.log(dat,"dat");
    AxiosInstance.post("api/test/answer", dat)
        .then((res) => {
          // console.log(res);
          if (res.data.status === true) {

            let dat = {
              user_answer : value,
              question_pk:pk,
              batch_id : batch_id,
              event_id : event_pk,
              language: lang,
              user_id : user_id ,
              created_by:user_id 
          }

            setFormData(prevState => ({
                ...prevState,
                pk:res.data.question_pk,
                user_answer:res.data.user_answer,
                lang:res.data.language
              }));
          }
        })
        .catch((error) => {
          return error;
        });
    }
 const performApiBinding = () => {
   // same as handlesubmit event 
// console.log('sdjfhsjd');
   let tim = moment.utc(time.asMilliseconds()).format("HH:mm:ss");

   let datb ={
    "batch_id":batch_id,
    "event_id":event_id,
    "user_id":userid,
    "duration_completed":tim,
    "created_by":userid
    }
    AxiosInstance.post("api/test/report/add", datb)
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          Swal.fire({
            title: 'Success',
            text: `Total correct mark : ${res.data.data.marks_obtained} 
                   Total Time you take : ${res.data.data.duration_completed}
                   Test Result : ${res.data.data.result_status === 0 ? "fail":"pass"} `,
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
              }
              navigateToPreviousScreen();
            }
          });
          
        }else{
          Swal.fire({
            title: 'warning',
            text:res.data.message,
            icon: 'warning',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              navigateToPreviousScreen()
            }
          });
        }
      })
      .catch((error) => {
        return error;
      });

  
  };

  const handleSubmitTest = (time) => {
    // console.log('sdjfhsjd');
    let datb ={
      "batch_id":batch_id,
      "event_id":event_id,
      "user_id":userid,
      "duration_completed":time,
      "created_by":userid
      }
      // console.log(datb);
      AxiosInstance.post("api/test/report/add", datb)
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          Swal.fire({
            title: 'Success',
            text: `Total correct mark : ${res.data.data.marks_obtained}
                   Total Time you take : ${res.data.data.duration_completed}
                   Test Result : ${res.data.data.result_status === 0 ? "fail":"pass"} `,
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              navigateToPreviousScreen()
            }
          });
          
        }else{
          Swal.fire({
            title: 'warning',
            text:res.data.message,
            icon: 'warning',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
              }
              navigateToPreviousScreen();
            }
          });
        }
      })
      .catch((error) => {
        return error;
      });

  }


  return (
    <div className=" Question">
      <div className="row">
        <div className="col-md-12 mt-5 mb-5 d-flex justify-content-center">
          <div className="TextBox shadow-sm p-3 mb-5 bg-body rounded">
            <h1 className="text-center mt-3">Test Question  -  Time start ({moment.utc(time.asMilliseconds()).format("HH:mm:ss")})</h1>
            {listData.length > 0 &&
              listData.map((el, index) => {
                if(el.display_type === 0 ){
                   return  <QtypeOne 
                //    formData={formData} 
                   handleOptionChange={handleOptionChange} index={index} list={el}/>
                }else if(el.display_type === 1 ){
                  return  <QtypeTwo handleOptionChange={handleOptionChange}  index={index} list={el}/>
               }
               else if(el.display_type === 2 ){
                return  <QtypeThree handleOptionChange={handleOptionChange}  index={index} list={el}/>
               }
               else if(el.display_type === 3 ){
                return  <QtypeFour handleOptionChange={handleOptionChange}  index={index} list={el}/>
               }
               else if(el.display_type === 4 ){
                return  <QtypeFive handleOptionChange={handleOptionChange}  index={index} list={el}/>
               }
               else if(el.display_type === 5 ){
                return  <Qtypesix handleOptionChange={handleOptionChange}  index={index} list={el}/>
               }
               else if(el.display_type === 6 ){
                return  <Qtypeseven handleOptionChange={handleOptionChange}  index={index} list={el}/>
               }
               else if(el.display_type === 7 ){
                return  <Qtypeeight handleOptionChange={handleOptionChange}  index={index} list={el}/>
               }
                else if(el.display_type === 8 ){
                return  <Qtypenine handleOptionChange={handleOptionChange}  index={index} list={el}/>
               }
               else if(el.display_type === 9 ){
                return  <QtypeTen handleOptionChange={handleOptionChange}  index={index} list={el}/>
               }
               else if(el.display_type === 10 && el.para_que === 1 ){
                return  <QparaType handleOptionChange={handleOptionChange}  index={index} list={el}/>
               }
               else if(el.display_type === 10 && el.para_que === 2 ){
                return  <Qquesparatype handleOptionChange={handleOptionChange}  index={index} list={el}/>
               }
              })}
          </div>
        </div>
      </div>
      <div className="row mb-4">
            <div className="col-md-12 d-flex justify-content-center">
            <button className="btn btn-danger" onClick={() => handleSubmitTest(moment.utc(time.asMilliseconds()).format("HH:mm:ss"))}>
  Submit Test
</button>            </div>
          </div>
    </div>
  );
}
