import React from "react";
import "./Question.css";

//display type 3 means - multiple option with statement -  mcq design
// sample design
// consider the following pairs:
// statement I :
// Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
// statement II :
// Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
// which one this following correct format
//  (A) both statement 1 and statement 2 are correct and statement 11 is expatiation of statement 1
//  (B) In publishing and graphic design, Lorem ipsum is a placeholder text commonly used
//  (C) In publishing and graphic design, Lorem ipsum is a placeholder text commonly used
//  (D) In publishing and graphic design, Lorem ipsum is a placeholder text commonly used

function QtypeThree(props) {
  const { index } = props;

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  return (
    <div className="Question_temp">
      {props.list.en_question !==null && props.list.en_question !=="" && (
        <div className="row">
           <div className="col-md-12">
            <span className="question_labels">
              {EsplitLines.length > 0 ? EsplitLines[0] : ""}
            </span>
          </div>
          {EsplitLines.length > 1 && (
<div className="col-md-12">
<div className="row">
  {EsplitLines.map((line, index) => {
    if (index === 1 && line.trim() !== "") {
      return (
        <div className="col-md-12">
          <span className="question_labels">{line}</span>
        </div>
      );
    }
  })}{" "}

{EsplitLines.map((line, index) => {
            if (index != 0 && index != 1 && line.trim() !== "") {
              return (
                <div className="col-md-12">
                  <span className="question_labels">{line}</span>
                </div>
              );
            }
          })}
</div>
</div>
)}
        
          
          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-12">
              <div className="forms_input">
                  <input
                    type="radio"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_1)}
                    className="radioInput"
                    name={`q_answer_${index}`}
                    value={props.list.options_en_1}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(A) {props.list.options_en_1} </span>
                </div>
              </div>
              <div className="col-md-12">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_2)}
                    name={`q_answer_${index}`}
                    value={props.list.options_en_2}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(B) {props.list.options_en_2} </span>
                </div>
              </div>
              <div className="col-md-12">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_3)}
                    value={props.list.options_en_3}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(C) {props.list.options_en_3} </span>
                </div>
              </div>
              <div className="col-md-12">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_4)}
                    name={`q_answer_${index}`}
                    value={props.list.options_en_4}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(D) {props.list.options_en_4} </span>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      )}
      {props.list.tm_question !==null && props.list.tm_question !=="" && (
        <div className="row tamilQues">
          <div className="col-md-12">
          <span className="question_labels">
              {splitLines.length > 0 ? splitLines[0] : ""}
            </span>
          </div>
          {splitLines.length > 1 && (
<div className="col-md-12">
<div className="row">
  {splitLines.map((line, index) => {
    if (index === 1 && line.trim() !== "") {
      return (
        <div className="col-md-12">
          <span className="question_labels">{line}</span>
        </div>
      );
    }
  })}{" "}

{splitLines.map((line, index) => {
            if (index != 0 && index != 1 && line.trim() !== "") {
              return (
                <div className="col-md-12">
                  <span className="question_labels">{line}</span>
                </div>
              );
            }
          })}
</div>
</div>
)}

          <div className="col-md-12  mt-3">
            <div className="row">
              <div className="col-md-12">
              <div className="forms_input">
                  <input
                    type="radio"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_1)}
                    className="radioInput"
                    name={`q_answer_${index}`}
                    value={props.list.options_tm_1}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(A) {props.list.options_tm_1} </span>
                </div>
              </div>
              <div className="col-md-12">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_2)}
                    name={`q_answer_${index}`}
                    value={props.list.options_tm_2}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(B) {props.list.options_tm_2} </span>
                </div>
              </div>
              <div className="col-md-12">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_3)}
                    value={props.list.options_tm_3}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(C) {props.list.options_tm_3} </span>
                </div>
              </div>
              <div className="col-md-12">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_4)}
                    value={props.list.options_tm_4}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(D) {props.list.options_tm_4} </span>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      )}
    </div>
  );
}

export default QtypeThree;
