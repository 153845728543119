import React from "react";

export default function ReviewAnswerAll(props) {
  return (
    <div className="row Question_temp">
      {props.type != "1" && (
        <div className="col-md-12 mt-3">
          <h6 className="user_answer_en">Your Answer</h6>
          <span className="user_label_answer">
            {props.type != "1"
              ? props.list.user_answer_column === "options_en_1" ||
                props.list.user_answer_column === "options_tm_1"
                ? "A"
                : props.list.user_answer_column === "options_en_2" ||
                  props.list.user_answer_column === "options_tm_2"
                ? "B"
                : props.list.user_answer_column === "options_en_3" ||
                  props.list.user_answer_column === "options_tm_3"
                ? "C"
                : props.list.user_answer_column === "options_en_4" ||
                  props.list.user_answer_column === "options_tm_4"
                ? "D"
                : "_"
              : props.list.answer_en}
          </span>
        </div>
      )}
      <div className="col-md-12 mt-3">
        <h6 className="user_answer_en">Correct Answer</h6>
        <span className="user_label_answer">
          {props.type != "1"
            ? props.list.correct_answer_column === "options_en_1" ||
              props.list.correct_answer_column === "options_tm_1"
              ? "A"
              : props.list.correct_answer_column === "options_en_2" ||
                props.list.correct_answer_column === "options_tm_2"
              ? "B"
              : props.list.correct_answer_column === "options_en_3" ||
                props.list.correct_answer_column === "options_tm_3"
              ? "C"
              : props.list.correct_answer_column === "options_en_4" ||
                props.list.correct_answer_column === "options_tm_4"
              ? "D"
              : "_"
            : props.list.answer_en}
        </span>
      </div>
      {props.type != "1" && (
        <div className="col-md-12 mt-3">
          <h6 className="user_answer">உங்கள் விடை</h6>
          <span className="user_label_answer">
            {props.type != "1"
              ? props.list.user_answer_column === "options_en_1" ||
                props.list.user_answer_column === "options_tm_1"
                ? "A"
                : props.list.user_answer_column === "options_en_2" ||
                  props.list.user_answer_column === "options_tm_2"
                ? "B"
                : props.list.user_answer_column === "options_en_3" ||
                  props.list.user_answer_column === "options_tm_3"
                ? "C"
                : props.list.user_answer_column === "options_en_4" ||
                  props.list.user_answer_column === "options_tm_4"
                ? "D"
                : "_"
              : props.list.answer_tm}
          </span>
        </div>
      )}

      <div className="col-md-12 mt-3">
        <h6 className="user_answer">சரியான விடை</h6>
        <span className="user_label_answer">
          {props.type != "1"
            ? props.list.correct_answer_column === "options_en_1" ||
              props.list.correct_answer_column === "options_tm_1"
              ? "A"
              : props.list.correct_answer_column === "options_en_2" ||
                props.list.correct_answer_column === "options_tm_2"
              ? "B"
              : props.list.correct_answer_column === "options_en_3" ||
                props.list.correct_answer_column === "options_tm_3"
              ? "C"
              : props.list.correct_answer_column === "options_en_4" ||
                props.list.correct_answer_column === "options_tm_4"
              ? "D"
              : "_"
            : props.list.answer_tm}
        </span>
      </div>
    </div>
  );
}
