import React from "react";
import "./Question.css";

//display type 1 means - multiple option  mcq design
// sample design
// .பபாருத்ைமான இழணழயத் பைர்க .
// a)அணங்கு - பைய்வம்
// b)ஓவு - ஓவியம்
// c)நியமம் - மதில்
// d)மாகால் - கைல்
// பபாருத்ைமான இழணழயத் பைர்க
//  (A) (a) மற்றும் (b) மட்டும் ரி  //  (B) (b) மற்றும் (c) மட்டும் ரி
//  (C) (c )மற்றும் (d) மட்டும் ரி  //  (D) அழனத்தும் ரி

function QtypeTwo(props) {
  const { index } = props;

  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in localstorge");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  return (
    <div className=" Question_temp">
      {props.list.en_question !==null && props.list.en_question !=="" && (
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-12">
              <span className="question_labels">
                {EsplitLines.length > 0 ? EsplitLines[0] : ""}
              </span>
            </div>
          </div>

          {EsplitLines.map((line, index) => {
  if (line.trim() !== "" && index !== 0 && index !== splitLines.length - 1) {
    return (
      <div className="col-md-12 mt-2" key={index}>
        <span className="question_labels">{line}</span>
      </div>
    );
  } else {
    return null;
  }
})}
     
          <div className="col-md-12 mt-2">
            <div className="row">
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_1)}
                    value={props.list.options_en_1}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(A) {props.list.options_en_1} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_2)}
                    value={props.list.options_en_2}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(B) {props.list.options_en_2} </span>
                </div>
              </div>
             
            </div>
            <div className="row mt-3">
            <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_3)}
                    value={props.list.options_en_3}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(C) {props.list.options_en_3} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_en_4)}
                    name={`q_answer_${index}`}
                    value={props.list.options_en_4}
                    // disabled = {props.formData[0].lang === 0 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(D) {props.list.options_en_4} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {props.list.tm_question !==null && props.list.tm_question !=="" && (
        <div className="row tamilQues">
          <div className="col-md-12">
          <span className="question_labels">
                {splitLines.length > 0 ? splitLines[0] : ""}
              </span>
          </div>
          {splitLines.map((line, index) => {
  if (line.trim() !== "" && index !== 0 && index !== splitLines.length - 1) {
    return (
      <div className="col-md-12 mt-2" key={index}>
        <span className="question_labels">{line}</span>
      </div>
    );
  } else {
    return null;
  }
})}
          <div className="col-md-12 mt-2">
            <div className="row">
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    className="radioInput"
                    type="radio"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_1)}
                    value={props.list.options_tm_1}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(A) {props.list.options_tm_1} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_2)}
                    name={`q_answer_${index}`}
                    value={props.list.options_tm_2}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(B) {props.list.options_tm_2} </span>
                </div>
              </div>
             
            </div>
            <div className="row mt-3">
            <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    name={`q_answer_${index}`}
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_3)}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    value={props.list.options_tm_3}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                  <span className="answer_label">(C) {props.list.options_tm_3} </span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="forms_input">
                  <input
                    type="radio"
                    className="radioInput"
                    checked={props.userInput.some(item => item.pk === props.list.pk && item.userOption === props.list.options_tm_4)}
                    name={`q_answer_${index}`}
                    // disabled = {props.formData[0].lang === 1 ? true : false}
                    value={props.list.options_tm_4}
                    onChange={(e) =>
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0
                      )
                    } // related to parent
                  />{" "}
                 <span className="answer_label">(D) {props.list.options_tm_4} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QtypeTwo;
