import React, { useEffect, useState } from 'react'
import './BranchLocation';
import { Error } from '../../../../Themes/themes';
import { AxiosInstance } from '../../../../axios/index';
import { useParams } from 'react-router-dom';
import {  Link } from 'react-router-dom';


function BranchLocationEdit() {

const {id} = useParams();

const[editdata,setEditdata]=useState([]);

// setting error value for all input fields
const [error, setError] = useState({});
const [initialfields, setInitialfields] = useState({
branch_name:'',
address:'',
email:'',
mobile_no:'',
city:'',
district:'',
state:'',
zipcode:'',
});

/**fetch the data for the id from the url */
  useEffect(() => {
     const storedItem = localStorage.getItem('branchdata');
  if (storedItem) {
    const items = JSON.parse(storedItem);
    const selecteditem = items.filter((item, i) => i == id);
    setEditdata(selecteditem);
  }
  }, []);



  /**
   * is saved function
   */
  function save() {
    alert('Branch details Saved Successfully');
  }


/**assign the edit datas to the form in all fields */
  useEffect(() => {
    if (editdata.length) {
      setInitialfields({
        ...initialfields,
        branch_name:editdata[0].branch_name,
        address:editdata[0].address,
        email:editdata[0].email,
        mobile_no:editdata[0].mobile_no,
        city:editdata[0].city,
        district:editdata[0].district,
        state:editdata[0].state,
        zipcode:editdata[0].zipcode,
      });
    }
  }, [editdata]);


  // email validation
  function isValidEmail(email) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }
  
  // mobile no validation
  function isValidMobileNumber(mobileNumber) {
    if (mobileNumber.length >= 10) {
      return true; // the mobile number is  valid
    } else {
      return false; // the mobile number is not valid
    }
  }

  //validate zipcode 
 function isValidZipCode(zipCode) {
  const zipCodePattern = /^\d{6}$/;
  return zipCodePattern.test(zipCode);
};


// validate form fields and setting error
    const errors = {};

    const validate = (data) => {
  
      if(data.branch_name==""){
           errors.branch_name = 'Please Enter Branch Name.';
      }
      else if(data.address==""){
          errors.address = 'Please Fill the Address.';
      }
      else if(data.email==""){
          errors.email = 'Please Enter Email Address.';
      }
      else if(!isValidEmail(data.email)){
          errors.email = 'Please Enter Valid Email Address.';
      }
      else if(data.mobile_no==""){
          errors.mobile_no = 'Please Enter Mobile Number.';
      }
      else if(!isValidMobileNumber(data.mobile_no)){
          errors.mobile_no = 'Please Enter Valid Mobile Number.';
      }
      else if(data.city==""){
          errors.city = 'Please Enter City.';
      }
      else if(data.district==""){
          errors.district = 'Please Enter District.';
      }
      else if(data.state==""){
          errors.state = 'Please Enter State.';
      }      
      else if(data.zipcode==""){
          errors.zipcode = 'Please Enter Zipcode.';
      }
      else if(!isValidZipCode(data.zipcode)){
          errors.zipcode = 'Please Enter Valid Zipcode.';
      }
      return errors;
    };

    /**
 * Handle change event for password fields.
 * Updates the corresponding field value in state based on the input field's name.
 * @param {object} event - The change event object.
 * @param {string} event.target.name - The name of the input field.
 * @param {string} event.target.value - The value of the input field.
 */
    const handleChange = (event) => {
      // console.log(event.target.name,event.target.value,"...handlechange...");
      const { name, value, id, checked } = event.target;
      // console.log(event.target.id,event.target.value,"...handlechange...");

      // set input values into state
        setInitialfields((prevFields) => ({
          ...prevFields,
          [name]: value,
        }));
      

  // set error values into state
      setError({
        ...error,
        [name]: ''
      });
    };



    /**
 * Handles submission of the form when form submits.
 * Saves the updated form values to local storage.
 * @param {Object} e - The form submission event.
 */

const handleSubmit = async (e) => {
  e.preventDefault();
  const errors = validate(initialfields);

  if (Object.keys(errors).length === 0) {
    try {
      const existingData = localStorage.getItem("branchdata") || "[]";
      const parsedData = JSON.parse(existingData);

      if (id) {
        // If an id is provided, update the data at the specified index
        parsedData[id] = initialfields;
          // try {
        //   const response = await AxiosInstance.post("/api/user/register",{batch_id:selectedIds});
        //   console.log(response.data);
        //   if(response.status="true")
        //   {
        //     save();
        //     window.location.href = '/batch';
        //   }
        // } catch (error) {
        //   console.log(error);
        // }
    
        save();
        window.location.href = '/branch';

      } else {
        // If no id is provided, add the new data to the end of the array
        parsedData.push(initialfields);
          // try {
        //   const response = await AxiosInstance.post("/api/user/register",{batch_id:selectedIds});
        //   console.log(response.data);
        //   if(response.status="true")
        //   {
        //     save();
        //     window.location.href = '/batch';
        //   }
        // } catch (error) {
        //   console.log(error);
        // }
    
        save();
        window.location.href = '/branch';
      }

      localStorage.setItem("branchdata", JSON.stringify(parsedData));
    } catch (error) {
      console.log(error);
    }
  } else {
    setError(errors);
  }
};

  return <div>

<form onSubmit={handleSubmit} >

<div>
  <div>
 {id? (<h4>Edit Branch Details</h4>):(<h4>Create Branch Details</h4>)}

        
  <div class="form-row">
    <div class="form-group col-md-6">
    <label for="branch_name">Branch Name:</label>
    <input type="text" class="form-control" id="branch_name" name="branch_name" placeholder="Enter Branch name" value={initialfields.branch_name}   onChange={handleChange} max={100} min={1} />
    </div>
<Error>{error.branch_name && <small className="error">{error.branch_name}</small>}</Error>
</div>

  <div class="form-row">
    <div class="form-group col-md-6">
    <label for="address">Branch Address:</label>
    <input type="textarea" class="form-control" id="address" name="address" placeholder="Enter Branch Address" value={initialfields.address}   onChange={handleChange} />
    </div>
<Error>{error.address && <small className="error">{error.address}</small>}</Error>
</div>

  <div class="form-row">
    <div class="form-group col-md-6">
    <label for="email">Email Address:</label>
    <input type="email" class="form-control" id="email" name="email" placeholder="Enter Email Address" value={initialfields.email}   onChange={handleChange} />
    </div>
<Error>{error.email && <small className="error">{error.email}</small>}</Error>
</div>

  <div class="form-row">
    <div class="form-group col-md-6">
    <label for="mobile_no">Mobile number:</label>
    <input type="number" class="form-control" id="mobile_no" name="mobile_no" placeholder="Enter Mobile number" value={initialfields.mobile_no}   onChange={handleChange} />
    </div>
<Error>{error.mobile_no && <small className="error">{error.mobile_no}</small>}</Error>
</div>
        
  <div class="form-row">
    <div class="form-group col-md-6">
    <label for="city">City:</label>
    <input type="text" class="form-control" id="city" name="city" placeholder="Enter Branch City" value={initialfields.city}   onChange={handleChange} />
    </div>
<Error>{error.city && <small className="error">{error.city}</small>}</Error>
</div>

  <div class="form-row">
    <div class="form-group col-md-6">
    <label for="district">District:</label>
    <input type="text" class="form-control" id="district" name="district" placeholder="Enter Branch district" value={initialfields.district}   onChange={handleChange} />
    </div>
<Error>{error.district && <small className="error">{error.district}</small>}</Error>
</div>

  <div class="form-row">
    <div class="form-group col-md-6">
    <label for="state">State:</label>
    <input type="text" class="form-control" id="state" name="state" placeholder="Enter Branch State" value={initialfields.state}   onChange={handleChange} />
    </div>
<Error>{error.state && <small className="error">{error.state}</small>}</Error>
</div>

  <div class="form-row">
    <div class="form-group col-md-6">
    <label for="zipcode">Zipcode:</label>
    <input type="text" class="form-control" id="zipcode" name="zipcode" placeholder="Enter Branch Zipcode" value={initialfields.zipcode}   onChange={handleChange} />
    </div>
<Error>{error.zipcode && <small className="error">{error.zipcode}</small>}</Error>
</div>

        
<div class="col-sm-4 mt-3">
  <button type="submit" class="btn btn-primary col-sm-4 ">Save</button>
  <button type="button" class="btn btn-secondary col-sm-4 ms-5"><Link to="/branch"  className='course_cancel'>Cancel</Link></button>
</div>   
   </div>      
      </div>
</form>
  </div>;
}

export default BranchLocationEdit
