import React, { useEffect, useState } from "react";
import { ImageUrlPrefix } from "../../labels";

import Select, { components, useStateManager } from "react-select";
import { customStyles } from "../customStyles";

function SpecialPre(props) {
  const [spcList, setSpcList] = useState(null);

  const handleSpc = (option, special_name, name) => {};

  // console.log(props);

  return (
    <div className="row box">
      <div className="col-md-12">
        <p className="StepTitle">
          Special Category Preferences {props.record.id > 1 ? "-" : ""}{" "}
          {props.record.id > 1 ? props.record.id : ""}
        </p>
        <div className="row ">
          {props.record.id === 1 && (
            <div className="col-md-12">
              <div className="forms row ">
                <label htmlFor="Category">Category</label>
                <div
                  className="d-flex flex-lg-row flex-md-row flex-column"
                  id="radiobuttons"
                >
                  <div className="form-check form-check-inline">
                    <input
                      ref={props.txtAdSpc}
                      className="checkbox_radio"
                      type="radio"
                      disabled={props.admission_status === 1 ? true : false}
                      name="SPCcategory"
                      id="UPSC"
                      value="UPSC"
                      onChange={props.handleChange}
                      checked={props.list === "UPSC"}
                    />
                    <label className="checkbox-label" htmlFor="UPSC">
                      UPSC
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="checkbox_radio"
                      type="radio"
                      disabled={props.admission_status === 1 ? true : false}
                      name="SPCcategory"
                      id="TNPSC"
                      value="TNPSC"
                      checked={props.list === "TNPSC"}
                      onChange={props.handleChange}
                    />
                    <label className="checkbox-label" htmlFor="TNPSC">
                      TNPSC
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="checkbox_radio"
                      type="radio"
                      disabled={props.admission_status === 1 ? true : false}
                      name="SPCcategory"
                      id="TRB"
                      value="TRB"
                      checked={props.list === "TRB"}
                      onChange={props.handleChange}
                    />
                    <label className="checkbox-label" htmlFor="TRB">
                      TRB
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="checkbox_radio"
                      type="radio"
                      disabled={props.admission_status === 1 ? true : false}
                      name="SPCcategory"
                      id="TET"
                      value="TET"
                      checked={props.list === "TET"}
                      onChange={props.handleChange}
                    />
                    <label className="checkbox-label" htmlFor="TET">
                      TET
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="checkbox_radio"
                      type="radio"
                      disabled={props.admission_status === 1 ? true : false}
                      name="SPCcategory"
                      id="TNUSRB"
                      value="TNUSRB"
                      checked={props.list === "TNUSRB"}
                      onChange={props.handleChange}
                    />
                    <label className="checkbox-label" htmlFor="TNUSRB">
                      TNUSRB
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="checkbox_radio"
                      type="radio"
                      disabled={props.admission_status === 1 ? true : false}
                      name="SPCcategory"
                      id="RRB"
                      value="RRB"
                      checked={props.list === "RRB"}
                      onChange={props.handleChange}
                    />
                    <label className="checkbox-label" htmlFor="RRB">
                      RRB
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="checkbox_radio"
                      type="radio"
                      disabled={props.admission_status === 1 ? true : false}
                      name="SPCcategory"
                      id="SSC"
                      value="SSC"
                      checked={props.list === "SSC"}
                      onChange={props.handleChange}
                    />
                    <label className="checkbox-label" htmlFor="SSC">
                      SSC
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="checkbox_radio"
                      type="radio"
                      disabled={props.admission_status === 1 ? true : false}
                      name="SPCcategory"
                      id="Banking"
                      value="Banking"
                      checked={props.list === "Banking"}
                      onChange={props.handleChange}
                    />
                    <label className="checkbox-label" htmlFor="Banking">
                      Banking
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="checkbox_radio"
                      type="radio"
                      disabled={props.admission_status === 1 ? true : false}
                      name="SPCcategory"
                      id="Others"
                      value="Others"
                      checked={props.list === "Others"}
                      onChange={props.handleChange}
                    />
                    <label className="checkbox-label" htmlFor="Others">
                      Others
                    </label>
                  </div>
                </div>
              </div>
              {props.listErrors && (
                <small className="errors ">{props.listErrors}</small>
              )}
            </div>
          )}

          <div className="col-md-6">
            <div className="forms">
              <label htmlFor="name">Special Preferences</label>
              <Select
                id="name"
                name="name"
                // isDisabled={props.admission_status === 1 ? true : false}

                value={
                  props.record.name !== null && props.record.name !== ""
                    ? { value: props.record.name, label: props.record.name }
                    : null
                }
                onChange={(option) =>
                  props.specialAdmissionHandleChanges(
                    option,
                    props.record.id,
                    props.record.name
                  )
                }
                options={props.sPrefrence} // Sort options alphabetically by label
                // options={props.sPrefrence.sort((a, b) => a.label.localeCompare(b.label))} // Sort options alphabetically by label
                isSearchable={true} // Enables search functionality
                placeholder="Select an option"
                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                filterOption={(option, searchText) =>
                  option.label.toLowerCase().includes(searchText.toLowerCase())
                } // Filter options based on the search text
                styles={customStyles} // Apply custom styles her
              />
              {props.record.error_name && (
                <small className="errors ">{props.record.error_name}</small>
              )}
            </div>
          </div>

          {props.record.name === "Tamil Medium (PSTM)" ? (
            <>
              <div
                className=" d-flex mt-lg-5 flex-row flex-sm-row align-items-center col-lg-6 col-md-12"
                id="radiobuttons"
              >
                <div className="form-check form-check-inline scpCheckLine">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="scp1"
                    value="0"
                    name="pstm_type"
                    checked={props.record.pstm_type.includes(0) ? true : false}
                    onChange={(e) =>
                      props.specialHandleChange(e, props.record.id, "pstm_type")
                    }
                  />
                  <span className="form-check-label" htmlFor="scp1">
                    SSLC
                  </span>
                </div>

                <div className="form-check form-check-inline scpCheckLine">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="Escp12"
                    value="1"
                    name="pstm_type"
                    checked={props.record.pstm_type.includes(1) ? true : false}
                    onChange={(e) =>
                      props.specialHandleChange(e, props.record.id, "pstm_type")
                    }
                  />
                  <span className="form-check-label" htmlFor="Escp12">
                    HSC
                  </span>
                </div>
                <div className="form-check form-check-inline scpCheckLine">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="Escp13"
                    name="pstm_type"
                    value="2"
                    checked={props.record.pstm_type.includes(2) ? true : false}
                    onChange={(e) =>
                      props.specialHandleChange(e, props.record.id, "pstm_type")
                    }
                  />
                  <span className="form-check-label" htmlFor="Escp13">
                    UG
                  </span>
                </div>

                <div className="form-check form-check-inline scpCheckLine">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="Escp14"
                    value="3"
                    name="pstm_type"
                    checked={props.record.pstm_type.includes(3) ? true : false}
                    onChange={(e) =>
                      props.specialHandleChange(e, props.record.id, "pstm_type")
                    }
                  />
                  <span className="form-check-label" htmlFor="Escp14">
                    PG
                  </span>
                </div>
                {props.record.error_path_sslc && (
                  <small className="errors error_large_screen">
                    {props.record.error_path_sslc}
                  </small>
                )}
              </div>
              {props.record.error_path_sslc && (
                <small className="errors error_small_screen">
                  {props.record.error_path_sslc}
                </small>
              )}

              <div className="col-md-6">
                <div className="forms ">
                  <label htmlFor="path_sslc">
                    Upload Certificate For SSLC (In pdf/Img format){" "}
                    <span className="optionFields">(Optional)</span>
                    {props.record.path_sslc !== "" &&
                      props.record.path_sslc !== null && (
                        <a
                          href={`${ImageUrlPrefix}/${props.record.path_sslc}`}
                          target="_blank"
                          download
                        >
                          <span className="material-icons align-middle download_icon">
                            file_download
                          </span>
                        </a>
                      )}
                  </label>
                  <div className="row">
                    <div
                      className={
                        props.record.path_sslc !== "" &&
                        props.record.path_sslc !== null
                          ? "col-lg-3 col-md-12 "
                          : "col-md-12"
                      }
                    >
                      <input
                        id="path_sslc"
                        type="file"
                        name="path_sslc"
                        // disabled={props.admission_status === 1 ? true : false}
                        onChange={(e) =>
                          props.specialHandleChange(
                            e,
                            props.record.id,
                            props.record.path_sslc
                          )
                        }
                        className={
                          props.record.path_sslc !== "" &&
                          props.record.path_sslc !== null
                            ? "InputsFile nn_upload_width nonefield"
                            : "InputsFile nonefield vv_upload_width"
                        }
                        placeholder="Upload Certificate"
                        autoComplete="path_sslc"
                        required
                      />
                    </div>
                    {props.record.path_sslc !== "" &&
                      props.record.path_sslc !== null && (
                        <div
                          className={"col-md-9 d-flex align-content-center p-0"}
                        >
                          <span className="nn_upload_file">
                            {props.record.path_sslc !== "" &&
                            props.record.path_sslc !== null
                              ? props.record.original_path_sslc
                              : ""}
                          </span>
                          <span className="material-icons align-middle text-danger verified_upload">
                            verified
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="forms ">
                  <label htmlFor="path_hsc">
                    Upload Certificate For HSC (In pdf/Img format){" "}
                    <span className="optionFields">(Optional)</span>
                    {props.record.path_hsc !== "" &&
                      props.record.path_hsc !== null && (
                        <a
                          href={`${ImageUrlPrefix}/${props.record.path_hsc}`}
                          target="_blank"
                          download
                        >
                          <span className="material-icons align-middle download_icon">
                            file_download
                          </span>
                        </a>
                      )}
                  </label>
                  <div className="row">
                    <div
                      className={
                        props.record.path_hsc !== "" &&
                        props.record.path_hsc !== null
                          ? "col-lg-3 col-md-12 "
                          : "col-md-12"
                      }
                    >
                      <input
                        id="path_hsc"
                        type="file"
                        name="path_hsc"
                        // disabled={props.admission_status === 1 ? true : false}
                        onChange={(e) =>
                          props.specialHandleChange(
                            e,
                            props.record.id,
                            props.record.path_hsc
                          )
                        }
                        className={
                          props.record.path_hsc !== "" &&
                          props.record.path_hsc !== null
                            ? "InputsFile nn_upload_width nonefield"
                            : "InputsFile nonefield vv_upload_width"
                        }
                        placeholder="Upload Certificate"
                        autoComplete="path_hsc"
                        required
                      />
                    </div>
                    {props.record.path_hsc !== "" &&
                      props.record.path_hsc !== null && (
                        <div
                          className={"col-md-9 d-flex align-content-center p-0"}
                        >
                          <span className="nn_upload_file">
                            {props.record.path_hsc !== "" &&
                            props.record.path_hsc !== null
                              ? props.record.original_path_hsc
                              : ""}
                          </span>
                          <span className="material-icons align-middle text-danger verified_upload">
                            verified
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="forms ">
                  <label htmlFor="path_ug">
                    Upload Certificate For UG (In pdf/Img format){" "}
                    <span className="optionFields">(Optional)</span>
                    {props.record.path_ug !== "" &&
                      props.record.path_ug !== null && (
                        <a
                          href={`${ImageUrlPrefix}/${props.record.path_ug}`}
                          target="_blank"
                          download
                        >
                          <span className="material-icons align-middle download_icon">
                            file_download
                          </span>
                        </a>
                      )}
                  </label>
                  <div className="row">
                    <div
                      className={
                        props.record.path_ug !== "" &&
                        props.record.path_ug !== null
                          ? "col-lg-3 col-md-12 "
                          : "col-md-12"
                      }
                    >
                      <input
                        id="path_ug"
                        type="file"
                        name="path_ug"
                        // disabled={props.admission_status === 1 ? true : false}
                        onChange={(e) =>
                          props.specialHandleChange(
                            e,
                            props.record.id,
                            props.record.path_ug
                          )
                        }
                        className={
                          props.record.path_ug !== "" &&
                          props.record.path_ug !== null
                            ? "InputsFile nn_upload_width nonefield"
                            : "InputsFile nonefield vv_upload_width"
                        }
                        placeholder="Upload Certificate"
                        autoComplete="path_ug"
                        required
                      />
                    </div>
                    {props.record.path_ug !== "" &&
                      props.record.path_ug !== null && (
                        <div
                          className={"col-md-9 d-flex align-content-center p-0"}
                        >
                          <span className="nn_upload_file">
                            {props.record.path_ug !== "" &&
                            props.record.path_ug !== null
                              ? props.record.original_path_ug
                              : ""}
                          </span>
                          <span className="material-icons align-middle text-danger verified_upload">
                            verified
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="forms ">
                  <label htmlFor="path_pg">
                    Upload Certificate For PG (In pdf/Img format){" "}
                    <span className="optionFields">(Optional)</span>
                    {props.record.path_pg !== "" &&
                      props.record.path_pg !== null && (
                        <a
                          href={`${ImageUrlPrefix}/${props.record.path_pg}`}
                          target="_blank"
                          download
                        >
                          <span className="material-icons align-middle download_icon">
                            file_download
                          </span>
                        </a>
                      )}
                  </label>
                  <div className="row">
                    <div
                      className={
                        props.record.path_pg !== "" &&
                        props.record.path_pg !== null
                          ? "col-lg-3 col-md-12 "
                          : "col-md-12"
                      }
                    >
                      <input
                        id="path_pg"
                        type="file"
                        name="path_pg"
                        // disabled={props.admission_status === 1 ? true : false}
                        onChange={(e) =>
                          props.specialHandleChange(
                            e,
                            props.record.id,
                            props.record.path_pg
                          )
                        }
                        className={
                          props.record.path_pg !== "" &&
                          props.record.path_pg !== null
                            ? "InputsFile nn_upload_width nonefield"
                            : "InputsFile nonefield vv_upload_width"
                        }
                        placeholder="Upload Certificate"
                        autoComplete="path_pg"
                        required
                      />
                    </div>
                    {props.record.path_pg !== "" &&
                      props.record.path_pg !== null && (
                        <div
                          className={"col-md-9 d-flex align-content-center p-0"}
                        >
                          <span className="nn_upload_file">
                            {props.record.path_pg !== "" &&
                            props.record.path_pg !== null
                              ? props.record.original_path_pg
                              : ""}
                          </span>
                          <span className="material-icons align-middle text-danger verified_upload">
                            verified
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="forms ">
                <label htmlFor="file">
                  Upload Certificate (In pdf/Img format){" "}
                  <span className="optionFields">(Optional)</span>
                  {props.record.file !== "" && props.record.file !== null && (
                    <a
                      href={`${ImageUrlPrefix}/${props.record.file}`}
                      target="_blank"
                      download
                    >
                      <span className="material-icons align-middle download_icon">
                        file_download
                      </span>
                    </a>
                  )}
                </label>
                <div className="row">
                  <div
                    className={
                      props.record.file !== "" && props.record.file !== null
                        ? "col-md-3 "
                        : "col-md-12"
                    }
                  >
                    <input
                      id="file"
                      type="file"
                      name="file"
                      // disabled={props.admission_status === 1 ? true : false}
                      onChange={(e) =>
                        props.specialHandleChange(
                          e,
                          props.record.id,
                          props.record.name
                        )
                      }
                      className={
                        props.record.file !== "" && props.record.file !== null
                          ? "InputsFile nn_upload_width nonefield"
                          : "InputsFile  nonefield vv_upload_width"
                      }
                      placeholder="Upload Certificate"
                      autoComplete="file"
                      required
                    />
                  </div>
                  {props.record.file !== "" && props.record.file !== null && (
                    <div className={"col-md-9 d-flex align-content-center p-0"}>
                      <span className="nn_upload_file">
                        {props.record.file !== "" && props.record.file !== null
                          ? props.record.original_pref_path
                          : ""}
                      </span>
                      <span className="material-icons align-middle text-danger verified_upload">
                        verified
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row mb-4">
          <div className="col-md-12 d-flex justify-content-end">
            {props.record.id != 1 && props.record.id === props.length && (
              <a
                // disabled={props.admission_status === 1 ? true : false}
                className="AddMore me-2"
                onClick={() =>
                  props.Deletespre(
                    props.record.id,
                    props.record.name,
                    props.record.scp_pk
                  )
                }
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                <span>{props.record.length}</span>
                Delete Special Category Preferences
              </a>
            )}

            {props.record.id === props.length ? (
              <a
                className="AddMore"
                // disabled={props.admission_status === 1 ? true : false}
                onClick={props.AddPrefrence}
              >
                <span className="material-icons download_icon align-middle">
                  add_circle
                </span>
                Add More Special Category Preferences
              </a>
            ) : (
              /* For all other items, show the delete button */
              <a
                // disabled={props.admission_status === 1 ? true : false}
                className="AddMore me-2"
                onClick={() =>
                  props.Deletespre(
                    props.record.id,
                    props.record.name,
                    props.record.scp_pk
                  )
                }
              >
                <span className="material-icons  download_icon align-middle">
                  remove_circle
                </span>
                <span>{props.record.length}</span>
                Delete Special Category Preferences
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default SpecialPre;
