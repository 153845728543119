
import React, { useEffect, useState } from "react";

import AdmissionPurchase from "./AdmissionPurchase";
import BookPurchase from "./BookPurchase";
import PendingAdmission from "./PendingAmision";
import { useNavigate, useParams } from "react-router-dom";



function PurchaseListview() {
  const navigate = useNavigate();
  const { active_tab } = useParams();
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    // Update the activeTab state when the component mounts and whenever active_tab changes in the URL
    setActiveTab(Number(active_tab) || 1);

  }, [active_tab]);

// tab active change function 
  const handleTabClick = (index) => {
    setActiveTab(index);
    navigate(`/TransactionHistory/${index}`);
  };


  return (
    <div className=" BathDet ">
    <h6 className="Title">Transaction History</h6>
      <div className="row mt-3">
        <div className="col-md-12">
        {/* nav-tabs */}
          <ul  
            className="nav  nav-fill mb-3"
            id="listViewTabs"
            role="tablist"
          >
             <li className="nav-item" role="presentation">
              <a
                className={activeTab === 1 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-1"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-1"
                aria-selected={activeTab === 1}
                onClick={() => handleTabClick(1)}
              >
               Pending Admission
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 2 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-1"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-2"
                aria-selected={activeTab === 2}
                onClick={() => handleTabClick(2)}
              >
               Admission
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 3 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-3"
                aria-selected={activeTab === 3}
                onClick={() => handleTabClick(3)}
              >
              Books
              </a>
            </li>
         
          </ul>
        </div>
        {/* <div className="borderActive"></div> */}
      </div>
      <PendingAdmission activeTab={activeTab} />
      <AdmissionPurchase activeTab={activeTab} />
      <BookPurchase activeTab={activeTab} />
    </div>
  );
} 

export default PurchaseListview;



