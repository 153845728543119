import axios from "axios";

//baseurl for node js

export const AxiosInstance = axios.create({
  // baseURL : "http://aiasadevstore.bharatclouds.com",
  // baseURL : "http://192.168.0.101:8081",
  // baseURL : "https://annaiasacademy.com/api",
  baseURL: "https://annaiasacademy.com/api",
});

// export const ReactBaseUrl = "https://annaiasacademy.com";

export const ReactBaseUrl = "https://annaiasacademy.com";
