import React, { useEffect, useState } from 'react'
import { json, Link } from 'react-router-dom';

export default function TestBatchUpdate() {

    const [data, setData] = useState([]);

const handleEdit=(index)=>
{
  window.location.href = '/testbatchedit';
}

const handleDelete=(index)=>
{
  // console.log(index,"...delete index");
  const Item = localStorage.getItem('testbatchdetails');
  if (Item) {
    const items = JSON.parse(Item);
    const Deletedata = items.filter((item, i) => i !== index);
    // console.log(DeleteCourse,"....selectedCourse");
    localStorage.setItem('testbatchdetails',JSON.stringify(Deletedata));
    window.location.reload();

  }
}

  useEffect(() => {
    const storedItem = localStorage.getItem('testbatchdetails');
    if (storedItem) {
      // const storedObject = JSON.parse(storedItem);
      // const arrayFromObject = Object.entries(storedObject).map(([key, value]) => ({ key, value }));
      setData( JSON.parse(storedItem));
    }
  }, []);
  // console.log(data);
  return (
    <>
    <div className='p-5 '>
    <button className='btn btn-success Test'><Link to="/testbatchedit" className='Test_link'>Create Test Batch</Link>
</button>
    </div>
  
    <div className='p-5'>
      <h1>Test Batch List</h1>
      {data.length > 0 ? (
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Course Category</th>
              <th>Test Mode</th>
              <th>Fees</th>
              <th>Medium</th>
              <th>Duration</th>
              <th>Start on</th>
              <th>Batch timing</th>
              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.courseCategory}</td>
                <td>{item.classMode}</td>
                <td>{item.fees}</td>
                <td>{item.medium}</td>
                <td>{item.duration}</td>
                <td>{item.startson}</td>
                <td>{item.batch_timing}</td>
                <td>
                  <button className='btn btn-primary me-2' onClick={()=>handleEdit(index)}>Edit</button>
                  <button className='btn btn-danger' onClick={()=>handleDelete(index)}>Delete</button>
                </td>
              </tr>
            ))}
              </tbody>
        </table>
      ) : (
        <div class="alert alert-danger" role="alert">
  No Test batch data found.
</div>
      )}
    </div>    
    </>
  )
}
