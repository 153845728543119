export const capitalizeFirstLetter = (text) => {
  // guard class
  if (!text) return;
  const str = String(text); //covert to string
  const firstLetter = String(str.slice(0, 1)).toUpperCase();
  const remainingLetter = str.slice(1);
  const finalStr = firstLetter + remainingLetter;
  return finalStr;
};

export const MEDIA_SIZE_XS = "320px";
export const MEDIA_SIZE_SM = "480px";
export const MEDIA_SIZE_MD = "640px";
export const MEDIA_SIZE_FLIP = "768px";
export const MEDIA_SIZE_LG = "991px";
export const MEDIA_SIZE_XL = "1024px";
export const MEDIA_SIZE_XXL = "1280px";

// bg colors for chart sections bar,donut
export const BACKGROUNDCOLOR = [
  "rgba(255, 99, 132, 0.6)",
  "rgba(54, 162, 235, 0.6)",
  "rgba(255, 206, 86, 0.6)",
  "rgba(75, 192, 192, 0.6)",
  "rgba(153, 102, 255, 0.6)",
  "rgba(255, 159, 64, 0.6)",
  "rgba(255, 99, 132, 0.6)",
  "rgba(54, 162, 235, 0.6)",
  "rgba(255, 206, 86, 0.6)",
  "rgba(75, 192, 192, 0.6)",
  "rgba(153, 102, 255, 0.6)",
  "rgba(255, 180, 123, 0.6)",
  "rgba(255, 150, 132, 0.6)",
];

export const DONUTBACKGROUNDCOLOR = [
  "rgba(178,0,0,1)",
  "rgba(0,178,178,1)",
  "rgba(255,159,64,1)",
  "rgba(59,178,0,1)",
  "rgba(255,205,86,1)",
  "rgba(253,98,131,1)",
];

// ### test-types master perimary key
export const TESTTYPE_DAILY = 1;
export const TESTTYPE_WEEK = 2;
export const TESTTYPE_MONTH = 3;
export const TESTTYPE_REVISION = 4;
export const TESTTYPE_TOPIC_WISE = 5;
export const TESTTYPE_UNIT_WISE = 6;
export const TESTTYPE_SPOT = 7;
export const TESTTYPE_MODEL = 17;

// 0—> easy , 1—> very easy, 2—>hard , 3 —> very_hard , 4 → medium
export const QUESTION_TYPE_EASY = 0;
export const QUESTION_TYPE_VERY_EASY = 1;
export const QUESTION_TYPE_HARD = 2;
export const QUESTION_TYPE_VERY_HARD = 3;
export const QUESTION_TYPE_MEDIUM = 4;

// chart api test-type master primary key
export const CHART_PERFOMANCE = 4;
export const CHART_QUESTION_TYPE = 3;
export const CHART_OVERALL = 2;
export const CHART_PROFICIENCY_SUBJECTS = 1;
export const CHART_SUBJECT_TIME_TAKEN = 0;

export const ALLOWED_MINUTES = 15;
// USER_MASTER
export const USER_ADMIN = 1;
export const USER_STUDENT = 2;
